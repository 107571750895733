import { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";

import { RedshiftConnectionAuthMethod } from "src/api/connectApi/types";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { SimpleDropDown } from "src/base-components/SimpleDropDown";
import { RedshiftAwsIamAuthFieldset } from "src/connections/config/database/redshift/RedshiftAwsIamAuthFieldset";
import { RedshiftConnectionSslConfigFields } from "src/connections/config/database/redshift/RedshiftConnectionSslConfig";
import { RedshiftUsernamePasswordAuthFieldset } from "src/connections/config/database/redshift/RedshiftUsernamePasswordAuthFieldset";
import { SSHTunnelFieldSet } from "src/connections/config/database/sshTunnel/SSHTunnelFieldSet";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { TestInvocationToggle } from "src/connections/config/shared/TestInvocationToggle";
import { errorProps } from "src/connections/config/shared/errorProps";
import {
  RedshiftConnectionConfigInputsT,
  Environment,
} from "src/connections/types";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";

type RedshiftAuthTypeSelectElement = {
  key: RedshiftConnectionAuthMethod;
  value: string;
};

const authMethods: RedshiftAuthTypeSelectElement[] = [
  { key: "username_password", value: "Username + Password" },
  { key: "aws_iam", value: "AWS IAM authentication" },
];

const renderAuthFields = (
  authMethod: RedshiftConnectionAuthMethod,
  environment: Environment,
) => {
  switch (authMethod) {
    case "username_password": {
      return <RedshiftUsernamePasswordAuthFieldset environment={environment} />;
    }
    case "aws_iam": {
      return <RedshiftAwsIamAuthFieldset environment={environment} />;
    }
  }
};

type EnvironmentConfigPropsT = {
  environment: Environment;
  labelsPrefix: string;
};

export const RedshiftEnvironmentConfig: React.FC<EnvironmentConfigPropsT> = ({
  environment,
  labelsPrefix,
}) => {
  const {
    formState: { errors },
    register,
    watch,
    setValue,
    control,
  } = useFormContext<RedshiftConnectionConfigInputsT>();
  const envPrefix = `${environment}Config` as const;
  const envErrors = errors[envPrefix];
  const enableNonProdConfigs = watch("enableNonProdConfigs");
  const authMethod = watch(`${envPrefix}.authMethod`);

  useEffect(() => {
    // If the auth method is AWS IAM, then SSL is required
    if (authMethod === "aws_iam") {
      setValue(`${envPrefix}.hasSslEnabled`, true);
    }
  }, [authMethod, envPrefix, setValue]);

  return (
    <>
      <FormItem
        gap="sm"
        helpTooltipBody={
          <div>
            The name of the Redshift database to connect to. When in doubt, ask
            your organization's database administrator.
          </div>
        }
        label={labelsPrefix ? `${labelsPrefix} database name` : "Database name"}
      >
        {isFieldErrored(envErrors, "databaseName") && (
          <ErrorHint>{envErrors?.databaseName?.message}</ErrorHint>
        )}
        <Input
          data-loc={`${environment}-redshift-connection-database-name`}
          placeholder="e.g. redshift"
          fullWidth
          {...errorProps(isFieldErrored(envErrors, "databaseName"))}
          {...register(`${envPrefix}.databaseName`)}
        />
      </FormItem>
      <FormItem
        description="The authentication mechanism required for the database"
        gap="sm"
        label="Select authentication type"
        isRequired
      >
        <Controller
          control={control}
          name={`${envPrefix}.authMethod`}
          render={(props) => (
            <SimpleDropDown
              buttonClassName="pl-3"
              buttonDataLoc={`${environment}-redshift-connection-auth-type`}
              className="h-8 w-full"
              elements={authMethods}
              itemsClassNames="w-full"
              itemsWidth="w-full"
              placeholder="Select authentication type"
              placement="bottomLeft"
              selectedKey={String(props.field.value)}
              onSelect={(value) => {
                props.field.onChange(value);
              }}
            />
          )}
        />
      </FormItem>
      {renderAuthFields(authMethod, environment)}
      {authMethod === "username_password" && (
        <>
          <FormItem
            gap="sm"
            helpTooltipBody={
              <div>
                The hostname of the Amazon Redshift cluster. When in doubt, ask
                your organization's database administrator.
              </div>
            }
            label={labelsPrefix ? `${labelsPrefix} host` : "Host"}
            isRequired
          >
            {isFieldErrored(envErrors, "host") && (
              <ErrorHint>{envErrors?.host?.message}</ErrorHint>
            )}
            <Input
              data-loc={`${environment}-redshift-connection-host`}
              placeholder="e.g. mycluster.cluster-123456789012.us-east-1.redshift.amazonaws.com"
              fullWidth
              {...errorProps(isFieldErrored(envErrors, "host"))}
              {...register(`${envPrefix}.host`, {
                required: "Database host is required",
              })}
            />
          </FormItem>
          <FormItem
            gap="sm"
            helpTooltipBody={
              <div>
                The port number through which the Database Connector will
                communicate with the Redshift database. If left blank, this
                setting defaults to 5439.
              </div>
            }
            label={labelsPrefix ? `${labelsPrefix} port` : "Port"}
          >
            {isFieldErrored(envErrors, "port") && (
              <ErrorHint>{envErrors?.port?.message}</ErrorHint>
            )}
            <Input
              data-loc={`${environment}-redshift-connection-port`}
              placeholder="e.g. 5439"
              type="number"
              fullWidth
              {...errorProps(isFieldErrored(envErrors, "port"))}
              {...register(`${envPrefix}.port`)}
            />
          </FormItem>
        </>
      )}
      {authMethod === "aws_iam" && (
        <>
          <FormItem
            gap="sm"
            helpTooltipBody={
              <div>
                The identifier of the Amazon Redshift cluster. When in doubt,
                ask your organization's database administrator.
              </div>
            }
            label={
              labelsPrefix
                ? `${labelsPrefix} cluster identifier`
                : "Cluster identifier"
            }
            isRequired
          >
            {isFieldErrored(envErrors, "clusterIdentifier") && (
              <ErrorHint>{envErrors?.clusterIdentifier?.message}</ErrorHint>
            )}
            <Input
              data-loc={`${environment}-redshift-connection-cluster-identifier`}
              placeholder="e.g. example-cluster"
              fullWidth
              {...errorProps(isFieldErrored(envErrors, "clusterIdentifier"))}
              {...register(`${envPrefix}.clusterIdentifier`, {
                required: "Cluster identifier is required",
              })}
            />
          </FormItem>
          <FormItem
            gap="sm"
            helpTooltipBody={
              <div>
                The AWS region where the Amazon Redshift cluster is located.
                When in doubt, ask your organization's database administrator.
              </div>
            }
            label={labelsPrefix ? `${labelsPrefix} AWS region` : "AWS region"}
            isRequired
          >
            {isFieldErrored(envErrors, "awsRegion") && (
              <ErrorHint>{envErrors?.awsRegion?.message}</ErrorHint>
            )}
            <Input
              data-loc={`${environment}-redshift-connection-aws-region`}
              placeholder="e.g. us-east-1"
              fullWidth
              {...errorProps(isFieldErrored(envErrors, "awsRegion"))}
              {...register(`${envPrefix}.awsRegion`, {
                required: "AWS region is required",
              })}
            />
          </FormItem>
          <FormItem
            gap="sm"
            helpTooltipBody={
              <div>
                The Amazon Resource Name (ARN) of the role that the caller is
                assuming
              </div>
            }
            label={labelsPrefix ? `${labelsPrefix} role ARN` : "Role ARN"}
          >
            {isFieldErrored(envErrors, "roleArn") && (
              <ErrorHint>{envErrors?.roleArn?.message}</ErrorHint>
            )}
            <Input
              data-loc={`${environment}-redshift-connection-role-arn`}
              placeholder="e.g. arn:aws:iam::123456789012:role/MyRedshiftRole"
              fullWidth
              {...errorProps(isFieldErrored(envErrors, "roleArn"))}
              {...register(`${envPrefix}.roleArn`)}
            />
          </FormItem>
        </>
      )}
      {isFeatureFlagEnabled(FEATURE_FLAGS.sshTunnel) && (
        <SSHTunnelFieldSet environment={environment} />
      )}
      <RedshiftConnectionSslConfigFields
        authMethod={authMethod}
        environment={environment}
      />
      {environment === "production" && (
        <TestInvocationToggle<RedshiftConnectionConfigInputsT>
          control={control}
          enableNonProdConfigs={enableNonProdConfigs}
          type="database"
        />
      )}
    </>
  );
};
