import React, { useEffect } from "react";

import { useRemoteIcon } from "src/connections/queries";
import { DashboardPageParamsT } from "src/router/urls";
import { logger } from "src/utils/logger";
import { useParamsDecode } from "src/utils/useParamsDecode";

interface CustomIconProps {
  mediaKey: string | null;
  className?: string;
}

export const CustomIcon: React.FC<CustomIconProps> = ({
  mediaKey,
  className,
}) => {
  const { wsId } = useParamsDecode<DashboardPageParamsT>();
  const {
    data: iconSrc,
    isError,
    isLoading,
    error,
  } = useRemoteIcon(mediaKey, wsId);

  useEffect(() => {
    if (isError) {
      logger.warn(
        "There was an error fetching the icon image",
        JSON.stringify(error),
      );
    }
  }, [isError, error]);

  // TODO: in case of error we should show the default icon.
  if (isLoading || !iconSrc || isError) {
    return <div />;
  }

  return (
    <img
      alt="Custom Icon"
      className={className}
      data-loc="custom-icon"
      src={iconSrc}
    />
  );
};
