import * as ObservablePlot from "@observablehq/plot";
import * as d3 from "d3";
import { capitalize, defaults } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import "./styles.css";
import {
  ObservableChartSpec,
  getTickFormat,
  tickFormat,
} from "src/analytics/utils";
import { GenericObjectT } from "src/api/flowTypes";
import { COLORS } from "src/utils/constants";
import { logger } from "src/utils/logger";

type UsePlot = {
  data: GenericObjectT[];
  spec: ObservableChartSpec;
};

type UsePlotSpec = {
  data: GenericObjectT[];
  spec: ObservableChartSpec;
};

export const useAnalyticsPlotSpec = ({ data, spec }: UsePlotSpec) => {
  let plotSpec = null;

  try {
    plotSpec = ObservablePlot.autoSpec(data, spec);
  } catch (e) {
    if (!(e instanceof Error)) {
      logger.error("Something went wrong while auto spec:", e);
    }
  }

  return {
    xReducer:
      typeof plotSpec?.x?.reduce === "string" ? plotSpec?.x?.reduce : undefined,
    yReducer:
      typeof plotSpec?.y?.reduce === "string" ? plotSpec?.y?.reduce : undefined,
  };
};

const DEFAULT_CHART_ELEMENT_LIMIT = 5000;

type ChartPlottingError = {
  headline: string;
  description: string;
};

type PlotConfiguration = {
  width: number;
  height: number;
  marginBottom: number;
  marginRight?: number;
  x?: {
    labelOffset: number;
    tickRotate: number;
  };
};

/**
 * Get the height of a bounding rectangle for a rotated rectangle
 * @param w width of the rectangle
 * @param h height of the rectangle
 * @param angle rotation angle in degrees
 * @returns height of the bounding rectangle
 */
const getBoundingRectHeight = (w: number, h: number, angle: number) => {
  const angleInRadians = (Math.PI * angle) / 180;
  return (
    Math.abs(w * Math.sin(angleInRadians)) +
    Math.abs(h * Math.cos(angleInRadians))
  );
};

type Plot = ReturnType<typeof ObservablePlot.plot>;

// Try to get a value from the data to determine the tick format
const getFirstExistingValue = (
  data: GenericObjectT[],
  valueGetter: ObservableChartSpec["x"]["value"],
) => {
  if (!valueGetter) return undefined;

  const row = data.find((row) => valueGetter(row));

  return row ? valueGetter(row) : undefined;
};

const plot = (
  data: GenericObjectT[],
  spec: ObservableChartSpec,
  configuration: PlotConfiguration,
) => {
  const config = defaults(configuration, {
    width: configuration.width,
    height: configuration.width / 2,
    marginBottom: 30,
    x: {},
  });

  return ObservablePlot.plot({
    width: config.width,
    height: config.height,
    className: "analytics-plot",
    marginBottom: config.marginBottom + 22,
    marginRight: config.marginRight,
    marks: [
      ObservablePlot.auto(data, spec),
      // Y axis left Labels
      ObservablePlot.axisY({
        anchor: "left",
        labelAnchor: "center",
        ticks: [],
      }),
    ],
    color: {
      range: COLORS,
      legend: true,
      label: typeof spec.color !== "string" ? spec.color?.label : undefined,
    },
    y: {
      grid: true,
      axis: "right",
      labelAnchor: "top",
      label: spec.y.label,
      tickFormat: getTickFormat(
        spec.y,
        getFirstExistingValue(data, spec.y.value),
      ),
    },
    x: {
      labelAnchor: "center",
      label: spec.x.label,
      tickFormat: getTickFormat(
        spec.x,
        getFirstExistingValue(data, spec.x.value),
      ),
      ...config.x,
    },
    style: {
      color: "#6B6F80",
      fontSize: "12px",
    },
    fx: {
      label: null,
      tickFormat: tickFormat,
    },
  });
};

const insertPlotToDOM = (
  container: React.RefObject<HTMLDivElement>,
  plot: Plot,
) => {
  if (!container.current) return;

  if (container.current.firstChild) {
    container.current.firstChild.replaceWith(plot);
  } else {
    container.current.appendChild(plot);
  }
};

const DEFAULT_CHART_CONFIG: PlotConfiguration = {
  width: 600,
  height: 300,
  marginBottom: 40,
};

export const useAnalyticsPlot = ({
  data,
  spec,
  options = {},
}: UsePlot & { options?: { fitContainer?: boolean } }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const plotSVG = useRef<ReturnType<typeof ObservablePlot.plot> | undefined>();
  const [error, setError] = useState<ChartPlottingError | null>(null);

  const [queryParams] = useSearchParams();
  const chartElementLimitQueryParam = queryParams.get("chart-element-limit");

  const chartElementLimit = chartElementLimitQueryParam
    ? parseInt(chartElementLimitQueryParam)
    : DEFAULT_CHART_ELEMENT_LIMIT;

  useEffect(() => {
    try {
      if (containerRef.current) {
        const config = {
          ...DEFAULT_CHART_CONFIG,
          width: containerRef.current.clientWidth,
          height: options.fitContainer
            ? containerRef.current.clientHeight
            : containerRef.current.clientWidth / 2,
        };
        plotSVG.current = plot(data, spec, config);

        // Check if we still can handle the plot
        // Exit early if we can't
        const plotElementsCount = plotSVG.current.querySelectorAll("*").length;
        const plotElementsCountLimitReached =
          plotElementsCount > chartElementLimit;
        if (plotElementsCountLimitReached) {
          setError({
            headline: "Chart not displayed for performance reasons",
            description: `This chart displays too many elements to maintain a smooth browsing experience. Consider selecting an alternative configuration or reducing the dataset size.`,
          });
          return;
        }

        setError(null);
        insertPlotToDOM(containerRef, plotSVG.current);

        // Refine plot configuration and plot again
        // 1. Update marginRight if labels do not fit
        const yAxisGroup = d3
          .select(plotSVG.current)
          .select<SVGGElement>(
            'g[aria-label*="y-axis"][aria-label*="tick"][aria-label*="label"]',
          );

        const yAxisWidth = yAxisGroup.node()?.getBBox().width;
        config.marginRight = yAxisWidth ? yAxisWidth + 16 : undefined;

        // 2. Check if X labels overlap, if so, rotate and increase marginBottom
        const xAxisLabels = d3
          .select(plotSVG.current)
          .select<SVGGElement>(
            'g[aria-label^="x-axis"][aria-label*="tick"][aria-label*="label"]',
          )
          .selectAll<SVGTextElement, SVGGElement>("text");

        const isOverlap = areXLabelsOverlapping(xAxisLabels.nodes());

        if (isOverlap) {
          const widestLabel = xAxisLabels
            .nodes()
            .map((label) => {
              const bbox = (label as SVGTextElement).getBoundingClientRect();
              return { width: bbox.width, height: bbox.height };
            })
            .reduce(
              (widest, current) => {
                return current.width > widest.width ? current : widest;
              },
              { width: 0, height: 0 },
            );

          const xLabelsHeight = getBoundingRectHeight(
            widestLabel.width,
            widestLabel.height,
            30,
          );

          // 22 and 16 are magic numbers that were found manually until the labels were aligned nicely
          config.marginBottom = xLabelsHeight + 22;
          config.x = { labelOffset: xLabelsHeight + 16, tickRotate: -30 };
        }

        // 3. Plot again with updated configuration
        plotSVG.current = plot(data, spec, config);
        insertPlotToDOM(containerRef, plotSVG.current);
      }
    } catch (e) {
      if (e instanceof Error) {
        setError({
          headline: "Cannot plot chart",
          description: capitalize(e.message),
        });
      }
      if (!isExpectedPlottingError(e)) {
        logger.log(spec);
        logger.error("Error while plotting:", e);
      }
    }
  }, [chartElementLimit, data, options.fitContainer, spec]);

  useEffect(() => {
    return () => plotSVG.current?.remove();
  }, []);

  return { containerRef, error };
};

const areXLabelsOverlapping = (labels: SVGTextElement[]) => {
  for (let i = 0; i < labels.length - 1; i++) {
    const currentLabel = (labels[i] as SVGTextElement).getBoundingClientRect();
    const nextLabel = (
      labels[i + 1] as SVGTextElement
    )?.getBoundingClientRect();

    if (currentLabel.right > nextLabel?.left) {
      return true;
    }
  }
  return false;
};

const isExpectedPlottingError = (e: unknown) => {
  return e instanceof Error && e.message.includes("must specify x or y");
};
