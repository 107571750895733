import {
  faBoxesStacked,
  faCube,
  faDatabase,
  faFile,
  faGear,
  faSpinner,
} from "@fortawesome/pro-regular-svg-icons";
import ReactDOM from "react-dom";

import { DecisionEnvironment } from "src/api/types";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { TextConfirmationModal } from "src/base-components/TextConfirmationModal";
import { Callout } from "src/design-system/Callout";
import { useFlowVersions } from "src/jobs/api/queries";
import { useUsedVersions } from "src/jobs/common/hooks";
import { getLiveIntegrationNodes } from "src/jobs/jobUtils";
import { Job } from "src/jobs/types";
import { useFlowContext } from "src/router/routerContextHooks";
import { pluralize } from "src/utils/stringUtils";

const JobConfigurationSummary: React.FC<{
  job: Job;
  mode: "live" | "sandbox";
}> = ({ job, mode }) => {
  const { flow } = useFlowContext();
  const usedVersions = useUsedVersions(job);
  return (
    <div className="mb-4 space-y-1.5">
      <div className="flex items-center">
        <Icon
          color="text-gray-500"
          icon={
            job.active_source?.configuration.type === "sql"
              ? faDatabase
              : job.active_source?.configuration.type === "s3_bucket_dataset"
                ? faBoxesStacked
                : faFile
          }
          size="2xs"
        />
        <p className="mx-0.5 max-w-40 truncate text-gray-800 font-inter-normal-13px">
          {job.active_source?.name}
        </p>
        {job.active_source?.configuration.type === "sql" && (
          <Pill size="sm" variant="gray">
            <Pill.Text>
              {mode === "live" ||
              job.active_source.configuration.credentials_for_sandbox_run ===
                "live_credentials"
                ? "Live Connection"
                : "Sandbox Connection"}
            </Pill.Text>
          </Pill>
        )}
      </div>
      <div className="flex items-center gap-x-1">
        <Icon color="text-gray-500" icon={faCube} size="2xs" />
        <span className="text-gray-800 font-inter-normal-13px">
          {flow.name}
        </span>
        <Pill size="sm" variant="gray">
          {usedVersions.length === 1 ? (
            <Pill.Text>{usedVersions[0].name}</Pill.Text>
          ) : (
            <Pill.Text>Multiple versions</Pill.Text>
          )}
        </Pill>
      </div>
      <div className="flex items-center">
        <Icon color="text-gray-500" icon={faGear} size="2xs" />
        <p className="ml-0.5 text-gray-800 font-inter-normal-13px">
          {mode === "live" ? "Live " : "Sandbox "}environment
        </p>
      </div>
    </div>
  );
};

export const RunJobModal: React.FC<{
  afterLeave: () => void;
  isOpen: boolean;
  onConfirm: (env: DecisionEnvironment) => Promise<void>;
  closeModal: () => void;
  job: Job;
  env: DecisionEnvironment;
}> = ({ afterLeave, closeModal, isOpen, job, onConfirm, env }) => {
  const usedVersions = useUsedVersions(job);
  const usedVersionsWithGraph = useFlowVersions(
    usedVersions.map((version) => version.id),
  );

  const versionsWithIntegrationNodes = usedVersionsWithGraph.data
    ? usedVersionsWithGraph.data.filter(
        (version) => getLiveIntegrationNodes(version).length !== 0,
      )
    : [];

  const { flow } = useFlowContext();

  return ReactDOM.createPortal(
    <>
      {env === DecisionEnvironment.LIVE && (
        <TextConfirmationModal
          additionalContent={
            <>
              <JobConfigurationSummary job={job} mode="live" />
              <Divider spacing="my-3" />
            </>
          }
          afterLeave={afterLeave}
          challengeText={job.name}
          confirmButtonText="Run Job"
          description="Are you sure you want to manually run this Job? The configuration used for this run will be:"
          label="Please enter the Job's name to confirm"
          open={isOpen}
          title="Are you sure?"
          variant="default"
          onClose={closeModal}
          onConfirm={() => onConfirm(DecisionEnvironment.LIVE)}
        />
      )}
      {env === DecisionEnvironment.SANDBOX && (
        <ConfirmationModal
          afterLeave={afterLeave}
          confirmationButtonText="Run Job in Sandbox mode"
          open={isOpen}
          title="Are you sure?"
          onClose={closeModal}
          onConfirm={() => onConfirm(DecisionEnvironment.SANDBOX)}
        >
          <p className="mb-2.5 text-gray-500 font-inter-normal-13px">
            Are you sure you want to run this Job in Sandbox mode? The
            configuration used for this run will be:
          </p>
          <JobConfigurationSummary job={job} mode="sandbox" />
          <p className="mt-1 text-gray-500 font-inter-normal-13px">
            Please confirm if you wish to proceed.
          </p>
          <div className="mt-4 space-y-2">
            {usedVersionsWithGraph.data ? (
              versionsWithIntegrationNodes.map((version) => (
                <Callout type="warning">
                  <div>
                    {flow.name} {version.name} has{" "}
                    {pluralize(getLiveIntegrationNodes(version).length, "node")}{" "}
                    which would make Live requests in Sandbox mode:
                    <ul className="list-inside list-disc">
                      {getLiveIntegrationNodes(version).map((nodeName) => (
                        <li>{nodeName}</li>
                      ))}
                    </ul>
                  </div>
                </Callout>
              ))
            ) : (
              <p className="flex items-center gap-x-1 text-gray-500 font-inter-normal-12px">
                {/* For the pill above we need some version details */}
                <Icon icon={faSpinner} size="2xs" spin />
                Loading version details
              </p>
            )}
          </div>
        </ConfirmationModal>
      )}
    </>,
    document.body,
  );
};
