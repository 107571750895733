import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Pill } from "src/base-components/Pill";
import {
  OPERATORS_WITH_IS_ANY,
  OPERATORS,
  EQUALITY_OPERATORS,
} from "src/constants/operators";
import { Tooltip } from "src/design-system/Tooltip";

export type OperatorDropdownOperators = keyof typeof OPERATORS_WITH_IS_ANY;
export type OperatorValues =
  (typeof OPERATORS_WITH_IS_ANY)[OperatorDropdownOperators];

type OperatorDropdownItemProps = {
  value: OperatorValues;
  dataLoc: string;
};

const OperatorDropdownItem: React.FC<OperatorDropdownItemProps> = ({
  value,
  dataLoc,
}) => (
  <div className="flex w-66 cursor-pointer items-center justify-between gap-x-2 px-4 py-2 font-inter-normal-13px ui-active:bg-gray-50">
    {value.description}
    <Pill dataLoc={dataLoc} size="sm" variant="gray">
      <Pill.Text fontType="code">{value.code}</Pill.Text>
    </Pill>
  </div>
);

type Option = {
  key: string;
  value: OperatorValues;
};

type OperatorDropdownProps = {
  dataLoc?: string;
  disabled?: boolean;
  value: OperatorDropdownOperators;
  options?: Option[];
  onChange: (operator: string) => void;
};

export const ALL_OPERATOR_OPTIONS = Object.entries(OPERATORS_WITH_IS_ANY).map(
  ([key, value]) => ({
    key,
    value,
  }),
);

export const DEFAULT_OPERATOR_OPTIONS = Object.entries(OPERATORS).map(
  ([key, value]) => ({
    key,
    value,
  }),
);

export const EQUALITY_OPERATOR_OPTIONS = Object.entries(EQUALITY_OPERATORS).map(
  ([key, value]) => ({
    key,
    value,
  }),
);

export const OperatorDropdown: React.FC<OperatorDropdownProps> = React.memo(
  ({
    disabled = false,
    value,
    onChange,
    options = DEFAULT_OPERATOR_OPTIONS,
    dataLoc,
  }) => {
    return (
      <FixedPositionedDropdown
        buttonClassName="border-0 ring-0 p-0.5 ui-open:border-0 ui-open:ring-0 focus:border-0 focus:ring-0"
        className="inline-block"
        dataLoc={dataLoc ?? "operator-dropdown"}
        disabled={disabled}
        elements={options}
        itemsClassNames="overflow-y-auto min-h-[6rem] max-h-80 decideScrollbar"
        placement="bottomLeft"
        renderButtonValue={(value) => (
          <Tooltip
            disabled={
              !Object.values(EQUALITY_OPERATORS).some(
                (operator) => operator === value,
              )
            }
            placement="top"
            title={value?.description}
            asChild
          >
            <Pill size="sm" variant="gray">
              <Pill.Text fontType="code">{value?.code}</Pill.Text>
              {!disabled && <Pill.Icon icon={faChevronDown} size="sm" />}
            </Pill>
          </Tooltip>
        )}
        renderValue={(props) => (
          <OperatorDropdownItem
            dataLoc={`select-${props.value?.code}`}
            value={props.value}
          />
        )}
        selected={value}
        onSelect={onChange}
      />
    );
  },
);
