import React from "react";
import { twJoin } from "tailwind-merge";

import { useConnection } from "src/api/connectApi/queries";
import { DecisionHistoryIntegrationDuration } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import {
  BeMappedNode,
  IntegrationLatencyData,
  IntegrationNode,
} from "src/constants/NodeDataTypes";
import { getDisplayedTitle } from "src/nodeEditor/SidePaneHeader";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useGraphStore } from "src/store/StoreProvider";

const getDisplayDuration = (ms: number): string => {
  if (ms < 1000) {
    return `${ms}ms`;
  } else {
    const seconds = (ms / 1000).toFixed(1).replace(".0", "");
    return `${seconds}s`;
  }
};
interface integrationLatencyProps {
  integrationLatencyData: IntegrationLatencyData;
}

interface InspectDataTimeoutPillProps {
  durations: DecisionHistoryIntegrationDuration[];
  errorMessage: string;
}
const IntegrationNodeItem: React.FC<{
  node: BeMappedNode;
  duration: DecisionHistoryIntegrationDuration;
}> = ({ node, duration }) => {
  const { workspace } = useAuthoringContext();
  const connection = useConnection({
    baseUrl: workspace.base_url,
    id: (node as IntegrationNode).data?.connectionId,
  }).data;
  const nodeTitle = getDisplayedTitle(node, connection);
  let durationText: string | undefined = undefined;
  if (duration?.provider_response_status === "cache") {
    durationText = "Cached response used";
  } else if (!duration?.provider_response_time) {
    durationText = `No duration data found`;
  } else {
    durationText = getDisplayDuration(duration.provider_response_time);
  }
  return (
    <li key={node.id}>
      <span className="font-medium">{nodeTitle}</span> —
      <span> {node.data.label} -&gt; </span>
      <span> {durationText}</span>
    </li>
  );
};

export const InspectDataTimeoutPill: React.FC<InspectDataTimeoutPillProps> = ({
  durations,
  errorMessage,
}) => {
  const { integrationNodes } = useGraphStore();

  if (durations.length === 0) {
    return errorMessage;
  }

  return (
    <div className="flex flex-col gap-2">
      <span>
        Decision timed out. The following response times were observed on the
        executed integration nodes:
      </span>
      <ul className="list-disc pl-5">
        {durations.map((duration) => (
          <IntegrationNodeItem
            key={duration.node_id}
            duration={duration}
            node={integrationNodes.find((n) => n.id === duration.node_id)!}
          />
        ))}
      </ul>
    </div>
  );
};

export const getIntegrationLatencyTooltipText = (
  integrationStatus: string,
): string | undefined => {
  switch (integrationStatus) {
    case "timeout":
      return "This node exceeded the timeout limit";
    case "cache":
      return "Cached response was used for this integration";
    case "mock_data":
      return "Mock data was used for this integration";
    case "empty_response":
      return "An empty sample response was used for this integration";
    default:
      return undefined;
  }
};

export const IntegrationLatency: React.FC<integrationLatencyProps> = ({
  integrationLatencyData,
}) => {
  if (integrationLatencyData?.duration === 0) return null;

  return (
    <div
      className={twJoin(
        "flex h-4 items-center font-inter-semibold-13px",
        integrationLatencyData.isErrored ? "text-red-600" : "text-green-600",
      )}
    >
      {getDisplayDuration(integrationLatencyData.duration)}
    </div>
  );
};
