/**
 * This is experimental component, can change in the future.
 *
 * Usage: Pass the component to "as" property of headlessui components
 *
 * Example:
 * <Listbox as={Dropdown}>
 *   <ListboxOption as={DropdownItem}>
 *     ...
 *   </ListboxOption>
 * </Listbox>
 */
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";

export const Dropdown = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={twMerge(
        "w-75 relative z-10 min-h-[156px] origin-top overflow-hidden rounded-lg bg-white py-2 shadow-lg transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0",
        props.className,
      )}
    >
      {children}
    </div>
  );
});

export const DropdownItem = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={twMerge(
        "flex h-12 min-w-[17rem] cursor-pointer items-center justify-between px-4 py-2.5",
        "ui-active:bg-gray-50",
        "text-gray-800 font-inter-normal-13px",
        "group",
        props.className,
      )}
    >
      {children}
      <div className="hidden group-data-[selected]:block">
        <Icon color="text-indigo-500" icon={faCheck} size="sm" />
      </div>
    </div>
  );
});
