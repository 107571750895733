import { makeConnectionTemplate } from "src/connections/types";

export const BKRTemplate = makeConnectionTemplate({
  provider: "bkr",
  secrets: [
    { key: "client_id", name: "Client ID", required: true },
    {
      key: "certificate",
      name: "Certificate",
      required: true,
      type: "multiline",
      hint: 'Please include the "BEGIN CERTIFICATE" and "END CERTIFICATE" lines',
    },
    {
      key: "private_key",
      name: "Private Key",
      required: true,
      type: "multiline",
      hint: 'Please include the "BEGIN RSA PRIVATE KEY" and "END RSA PRIVATE KEY" lines',
    },
  ],
  resources: {
    bkr_cki: {
      name: "CKI Toetsing (Central Credit Information Check)",
      configKeys: [
        {
          key: "customer_number",
          required: true,
          name: "Customer number",
          type: "text",
          hint: "Your 7 digit customer number (participant number). If accessing the testing environment, prepend your 5 digit customer code with two zeros (e.g. 12345 -> 0012345).",
        },
      ],
    },
  },
});
