import { faCheck, faFilter } from "@fortawesome/pro-regular-svg-icons";
import { faFilter as faFilterSolid } from "@fortawesome/pro-solid-svg-icons";
import { twJoin } from "tailwind-merge";

import {
  FixedPositionedDropdown,
  FixedPositionedDropdownPropsT,
} from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { StatusFilterKey } from "src/dataTable/TableUtils";
import { Tooltip } from "src/design-system/Tooltip";

type PropsT = {
  isFiltering: boolean;
  selected: StatusFilterKey;
  onSelect: (keys: StatusFilterKey) => void;
} & Pick<FixedPositionedDropdownPropsT<string, StatusFilterKey>, "elements">;

export const IntermediateResultsTableFilter: React.FC<PropsT> = ({
  isFiltering,
  ...props
}) => {
  return (
    <span className="ml-2 inline-block cursor-pointer">
      <FixedPositionedDropdown<string, StatusFilterKey>
        buttonClassName="border-0"
        renderButtonValue={() => (
          <Tooltip placement="top" title="Filter by status" asChild>
            {/*To capture the tooltip, it can't be a button here */}
            <div>
              <Icon
                color={
                  isFiltering
                    ? "text-indigo-500"
                    : "text-gray-500 hover:text-gray-700"
                }
                icon={isFiltering ? faFilterSolid : faFilter}
                size="2xs"
              />
            </div>
          </Tooltip>
        )}
        renderHeader={() => (
          <div className="flex w-[240px] cursor-default items-center justify-between px-5 py-3.5">
            <h3 className="font-bold">Filter by status:</h3>
          </div>
        )}
        renderValue={({ value, selected, disabled }) => {
          return (
            <div
              className={twJoin(
                "flex w-[240px] items-center justify-between py-2 pl-5 pr-6",
                disabled && "cursor-default",
              )}
            >
              <span
                className={twJoin("inline-block", disabled && "text-gray-300")}
              >
                {value}
              </span>
              {selected && (
                <div className="inline-block">
                  <Icon color="text-indigo-600" icon={faCheck} />
                </div>
              )}
            </div>
          );
        }}
        {...props}
        placement="bottomLeft"
        onSelect={props.onSelect}
      />
    </span>
  );
};
