import { UseInfiniteQueryResult } from "@tanstack/react-query";
import { Row, Table, Table as TableT } from "@tanstack/react-table";
import { CanceledError } from "axios";
import { useRef, useState } from "react";
import { twJoin } from "tailwind-merge";

import {
  DecisionHistoryPage,
  DecisionHistoryRecordV2,
  getHistoryDecision,
  SingleDecisionQueryErrorReason,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { Webhook } from "src/clients/flow-api";
import { toastActions } from "src/design-system/Toast/utils";
import { getHistoryEmptyStateWording } from "src/flow/decisionHistory/SharedEmptyStateWording";
import { useFlowContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";
import { TableComp } from "src/versionDecisionHistory/BaseTable";
import { DecisionHistoryEmpty } from "src/versionDecisionHistory/Empty";
import { HistoryDetailModal } from "src/webhooks/HistoryDetailModal";
import { getVersionDecisionHistoryColumns } from "src/webhooks/HistorySidebar/columns";
import { webhookResponse } from "src/webhooks/HistorySidebar/webhookUtils";
import {
  OutgoingWebhookResponse,
  useRetryOutgoingWebhook,
} from "src/webhooks/queries";

const renderEmptyState = (
  singleDecisionError: SingleDecisionQueryErrorReason | undefined,
  emptyData: boolean,
) => {
  if (singleDecisionError || emptyData) {
    const wording = getHistoryEmptyStateWording(
      singleDecisionError ?? "emptyData",
    );
    return <DecisionHistoryEmpty {...wording} padded={false} />;
  }
};

const getRecordDataLog = (
  record: DecisionHistoryRecordV2<OutgoingWebhookResponse>,
) => {
  const response = webhookResponse(record);
  if (!response) return "webhook-log-row-internal-error";
  return `webhook-log-row-${response.status || "internal-error"}-id:${
    response?.request_body?.["event"]?.["decision_id"]
  }`;
};

type PropsT = {
  searchTerm: string;
  // Pass in a method for setting the search term so that the
  // menu can control the table filtering (e.g. view all decision with the same ID)
  setSearchTerm: (searchTerm: string) => void;
  decisionHistory: Pick<
    UseInfiniteQueryResult<DecisionHistoryPage<OutgoingWebhookResponse>, Error>,
    "isFetching" | "data" | "hasNextPage" | "fetchNextPage"
  >;
  webhook: Webhook;
};

export const DecisionHistoryTable: React.FC<PropsT> = ({
  searchTerm,
  setSearchTerm,
  decisionHistory,
  webhook,
}) => {
  const tableRef =
    useRef<Table<DecisionHistoryRecordV2<OutgoingWebhookResponse>>>(null);
  const { workspace } = useFlowContext();
  const [displayedDecisionResponseData, setDisplayedDecisionResponseData] =
    useState<DecisionHistoryRecordV2<OutgoingWebhookResponse>>();
  const retryWebhook = useRetryOutgoingWebhook();

  const [selectionStatus, setSelectionStatus] = useState<
    | { tag: "ready" | "active" }
    | { tag: "loading"; abortController: AbortController }
  >({ tag: "ready" });

  const handleRowClick = (
    row: Row<DecisionHistoryRecordV2<OutgoingWebhookResponse>>,
    table: TableT<DecisionHistoryRecordV2<OutgoingWebhookResponse>>,
  ) => {
    if (selectionStatus.tag === "loading") {
      selectionStatus.abortController.abort();
    }
    const wasAlreadySelected = row.getIsSelected();
    if (wasAlreadySelected) {
      row.toggleSelected(false);
      setSelectionStatus({ tag: "ready" });
    } else {
      table
        .getSelectedRowModel()
        .flatRows.forEach((row) => row.toggleSelected(false));
      row.toggleSelected(true);

      const abortController = new AbortController();
      setSelectionStatus({ tag: "loading", abortController });
      handleRowSelection(row.original, abortController.signal);
    }
  };

  const handleRowSelection = async (
    row: DecisionHistoryRecordV2<OutgoingWebhookResponse>,
    signal: AbortSignal,
  ) => {
    try {
      const decision = (
        await getHistoryDecision<OutgoingWebhookResponse>({
          baseUrl: workspace.base_url!,
          decisionId: row.id,
          signal,
        })
      ).data;
      if (decision === null || !webhookResponse(decision)) {
        toastActions.failure({
          title: "Unable to load log details",
        });
      } else {
        setSelectionStatus({ tag: "active" });
        setDisplayedDecisionResponseData(decision);
      }
    } catch (err) {
      if (!(err instanceof CanceledError)) {
        logger.error(err);
      }
    }
  };

  const displayedData = decisionHistory.data?.pages?.flatMap(
    (page) => page.data,
  );

  const emptyData = Boolean(displayedData && displayedData.length === 0);
  const showingGenericNotFound = emptyData && !decisionHistory.isFetching;

  if (showingGenericNotFound && searchTerm === "") {
    return (
      <DecisionHistoryEmpty
        description="A record of requests made by this particular webhook will show up here once the webhook is triggered."
        headline="No logs yet."
        padded={false}
      />
    );
  }
  const retryWebhookFn = async (original: OutgoingWebhookResponse) => {
    if (!original.request_body)
      throw new Error("Cannot retry webhook without request body");
    try {
      await retryWebhook.mutateAsync({
        baseUrl: workspace.base_url,
        webhookKey: webhook.key,
        workspaceSlug: workspace.slug,
        original,
      });
      toastActions.success({
        title: "Webhook delivery successful",
      });
    } catch (err) {
      // It's expected user decisions might throw
      // (especially those being manually retried)
      toastActions.failure({
        title: "Webhook delivery failed",
      });
    }
  };
  return (
    <div className="flex min-h-0 flex-1 flex-col pl-6 pr-2">
      <div className={twJoin(!showingGenericNotFound && "min-h-0 flex-1")}>
        <TableComp
          canFetchNextPage={Boolean(
            !decisionHistory.isFetching && decisionHistory.hasNextPage,
          )}
          columns={getVersionDecisionHistoryColumns({
            selectedRowIsLoading: selectionStatus.tag === "loading",
            retryWebhook: retryWebhookFn,
            filterByDecisionId: setSearchTerm,
          })}
          data={displayedData ?? []}
          dataLoc="webhook-logs-table"
          fetchNextPage={decisionHistory.fetchNextPage}
          frameClassName={twJoin("overflow-auto", emptyData && "h-16")}
          handleRowClick={handleRowClick}
          isFetching={decisionHistory.isFetching}
          rowPropsGetter={(row, table) => {
            const isSelected = row.getIsSelected();
            return {
              "data-loc": getRecordDataLog(row.original),
              onClick: () => {
                if (webhookResponse(row.original)) {
                  handleRowClick(row, table);
                }
              },
              classNameOverrides: twJoin(
                "group/row border-r-4",
                webhookResponse(row.original) && "cursor-pointer",
                isSelected
                  ? "group/row-selected border border-r-indigo-500 bg-gray-50"
                  : "border-r-transparent",
              ),
            };
          }}
          skeletonRowsClassnames={[
            "w-[30px] pl-1.5",
            "w-[105px]",
            "w-[100px]",
            "w-[50px]",
          ]}
          tableRef={tableRef}
        />
      </div>
      {!decisionHistory.isFetching && renderEmptyState(undefined, emptyData)}
      <HistoryDetailModal
        attempt_time={
          displayedDecisionResponseData?.start_time
            ? new Date(displayedDecisionResponseData?.start_time)
            : undefined
        }
        isOpen={displayedDecisionResponseData !== undefined}
        response={webhookResponse(displayedDecisionResponseData)}
        retryWebhook={retryWebhookFn}
        setSearchTerm={setSearchTerm}
        onClose={() => {
          tableRef.current?.resetRowSelection();
          setSelectionStatus({ tag: "ready" });
          setDisplayedDecisionResponseData(undefined);
        }}
      />
    </div>
  );
};
