import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FieldErrorsT } from "src/api/types";
import { Button } from "src/base-components/Button";
import { Card } from "src/base-components/Card";
import { MonospacedInput } from "src/base-components/MonospacedInput";
import { ReorderHandle } from "src/base-components/ReorderHandle";
import { ClauseBlock, ClauseFormT } from "src/clauseBlock/ClauseBlock";
import { SplitBranch } from "src/clients/flow-api";
import { SplitNodeV2Form } from "src/splitNodeV2Editor/SplitNodeV2Editor";
import { useGraphStore } from "src/store/StoreProvider";
import { logger } from "src/utils/logger";

type PropsT = {
  immutable: boolean;
  onDeleteRequest: () => void;
  index: number;
  branch: SplitBranch;
  fieldErrors: FieldErrorsT | undefined;
};

export const BranchCard: React.FC<PropsT> = ({
  immutable,
  onDeleteRequest,
  index,
  branch,
  fieldErrors,
}) => {
  const { updateNode, getNodeToIncomingEdge } = useGraphStore();
  const { setValue, control } = useFormContext<SplitNodeV2Form>();

  const { listeners, setNodeRef, transform, transition } = useSortable({
    id: branch.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleChange = (value: ClauseFormT) => {
    setValue(`branches.${index}.clauses`, value.clauses);
    setValue(`branches.${index}.junction`, value.junction);
  };

  const branchName = getNodeToIncomingEdge(branch.edge_id)?.data.label;

  const onChangeBranchName: React.FormEventHandler<HTMLInputElement> = (e) => {
    const branchNodeToRename = getNodeToIncomingEdge(branch.edge_id);
    if (branchNodeToRename) {
      updateNode({
        newName: e.currentTarget.value,
        nodeId: branchNodeToRename.id,
      });
    }
  };

  const onDeleteBranch = async () => {
    try {
      await onDeleteRequest();
    } catch {
      logger.error("Error deleting branch");
    }
  };

  return (
    <Card
      ref={setNodeRef}
      className="my-5 space-y-3"
      data-loc={`branch-${index}`}
      style={style}
    >
      <Card.Header>
        <div className="flex flex-row items-center gap-x-1">
          <ReorderHandle
            className="-ml-2"
            immutable={immutable}
            listeners={listeners}
          />
          <Card.Title title={`Branch ${index + 1}`} />
        </div>
        <Button
          dataLoc={`delete-split-branch-${index}`}
          disabled={immutable}
          iconLeft={faTrashAlt}
          size="sm"
          variant="secondary"
          onClick={onDeleteBranch}
        >
          Delete branch
        </Button>
      </Card.Header>
      <Card.Content>
        <Controller
          control={control}
          name={`branches.${index}`}
          render={({ field }) => (
            <ClauseBlock
              clauses={field.value.clauses}
              fieldErrors={fieldErrors}
              immutable={immutable}
              junction={field.value.junction}
              onChange={handleChange}
            />
          )}
        />
        <div className="flex flex-row items-center border-t border-gray-200 pt-3">
          <div className="mr-2 w-24 text-gray-700 font-inter-medium-12px">
            Use branch
          </div>
          <MonospacedInput
            containerClassName="flex-grow"
            defaultValue={branchName}
            disabled={immutable}
            inputDataLoc={`branch-${index}-name-input`}
            onChange={onChangeBranchName}
          />
        </div>
      </Card.Content>
    </Card>
  );
};
