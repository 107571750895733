import { pickBy } from "lodash";

import { NO_FOLDER_FILTER } from "src/api";
import { FlowT } from "src/api/flowTypes";
import { baseUrl } from "src/api/hosts";
import { pathJoin } from "src/api/paths";
import { DecisionEnvironment, ReviewCase } from "src/api/types";
import {
  DecisionHistoryKeys,
  LeftPaneOptions,
  SchemaOptions,
  URLKeys,
} from "src/router/SearchParams";
import { appendCurrentFlags } from "src/router/featureFlags";
import { SettingsSubpages } from "src/settings/SettingsPage";

export enum WsDashboardPageCode {
  Dashboard = "dashboard",
  Flows = "flows",
  Entities = "entities",
  Events = "events",
  Features = "features",
}

export const parseUrl = (url: string) => {
  const regex =
    /\/decide\/org\/(?<orgId>[0-9a-fA-F-]+)(\/ws\/(?<wsId>[0-9a-fA-F-]+))?(\/flows\/(?<flowId>[0-9a-fA-F-]+))?(\/version\/(?<versionId>[0-9a-fA-F-]+))?/;

  const match = url.match(regex);
  return ((match && match.groups) || {}) as {
    orgId: string;
    wsId?: string;
    flowId?: string;
    versionId?: string;
  };
};

export type DashboardPageParamsT = {
  wsId: string;
  orgId: string;
};

export type FlowPageParamsT = {
  flow_id: string;
} & DashboardPageParamsT;

export type AuthorPageParamsT = FlowPageParamsT & { version_id: string };

export type RevisionPageParamsT = AuthorPageParamsT & { revision_etag: string };

export type ManualReviewPageParamsT = FlowPageParamsT & { case_id: string };
export type JobPageParamsT = FlowPageParamsT & { job_id: string };

export const getBaseUrl = () =>
  pathJoin(window.location.origin ?? "", baseUrl() ?? "", "/decide");

const getOrgUrl = (orgId: string) => {
  return `/org/${orgId}/`;
};

export const getWsUrl = (orgId: string, wsId: string) => {
  return `${getOrgUrl(orgId)}ws/${wsId}/`;
};

export const getUrlToWsDashboard = (
  {
    orgId,
    wsId,
    page = WsDashboardPageCode.Flows,
    folderId,
  }: {
    orgId: string;
    wsId: string;
    page?: WsDashboardPageCode;
    // null sets folder-id param to no-folder,
    // and undefined omits the folder-id param
    folderId?: string | null;
  },
  options: {
    keepFlags?: boolean;
  } = { keepFlags: true },
) => {
  let wsAndPageUrl = page
    ? `${getWsUrl(orgId, wsId)}${page}`
    : getWsUrl(orgId, wsId);

  if (page === "flows" && folderId !== undefined) {
    wsAndPageUrl = `${wsAndPageUrl}?${URLKeys.FolderId}=${folderId ?? NO_FOLDER_FILTER}`;
  }
  // TODO this would be much simpler if we worked with a URL object instead of a string
  return options?.keepFlags
    ? appendCurrentFlags(`${wsAndPageUrl}`)
    : wsAndPageUrl;
};

export const getUrlToSettingsPage = (
  orgId: string,
  wsId: string,
  page?: SettingsSubpages,
  options: {
    keepFlags?: boolean;
  } = { keepFlags: true },
) => {
  const wsAndPageUrl = page
    ? `${getWsUrl(orgId, wsId)}/settings/${page}`
    : `${getWsUrl(orgId, wsId)}/settings`;
  return options?.keepFlags
    ? appendCurrentFlags(`${wsAndPageUrl}`)
    : wsAndPageUrl;
};

export const getUrlToDecisionsOverview = (
  orgId: string,
  wsId: string,
  options?: { entityId?: string; decisionId?: string },
) => {
  const wsUrl = getWsUrl(orgId, wsId);
  const entityId = options?.entityId
    ? `${URLKeys.EntityId}=${encodeURIComponent(options?.entityId)}`
    : null;
  const decisionId = options?.decisionId
    ? `${URLKeys.DecisionId}=${options?.decisionId}`
    : null;

  return appendCurrentFlags(
    `${wsUrl}decisions?${options ? (decisionId ?? entityId) : ""}`,
  );
};

export const getRetoolUrl = (
  orgId: string,
  wsId: string,
  retoolId: string,
  options: {
    keepFlags?: boolean;
  } = { keepFlags: true },
) => {
  const wsUrl = getWsUrl(orgId, wsId);
  const retoolUrl = `${wsUrl}retool/${retoolId}`;
  return options?.keepFlags ? appendCurrentFlags(retoolUrl) : retoolUrl;
};

export const getFlowVersionsUrl = (
  orgId: string,
  wsId: string,
  flowId: string,
  options: {
    keepFlags?: boolean;
  } = { keepFlags: true },
) => {
  const wsUrl = getWsUrl(orgId, wsId);
  const relativePath = `${wsUrl}flows/${flowId}/`;

  return options.keepFlags ? appendCurrentFlags(relativePath) : relativePath;
};

export const getFlowSubpageUrl = (
  orgId: string,
  wsId: string,
  flowId: string,
  page: string,
  options: {
    keepFlags?: boolean;
  } = { keepFlags: true },
) => {
  const baseUrl = getFlowVersionsUrl(orgId, wsId, flowId, { keepFlags: false });

  return options.keepFlags
    ? appendCurrentFlags(`${baseUrl}${page}/`)
    : `${baseUrl}${page}`;
};

export type UrlToAuthoringPageOptions = Partial<{
  leftPane: LeftPaneOptions;
  schemaOptions: SchemaOptions;
  selectedNodeId: string;
  decisionId: string;
}>;

export const getUrlToAuthoringPage = (
  orgId: string,
  wsId: string,
  flowId: string,
  versionId: string,
  options?: UrlToAuthoringPageOptions,
) => {
  const wsUrl = getWsUrl(orgId, wsId);
  const params = {
    ...(options?.leftPane && { [URLKeys.LeftPane]: options?.leftPane }),
    ...(options?.schemaOptions && { [URLKeys.Schema]: options?.schemaOptions }),
    ...(options?.selectedNodeId && {
      [URLKeys.SelectedNodeId]: options?.selectedNodeId,
    }),
    ...(options?.decisionId && { [URLKeys.DecisionId]: options?.decisionId }),
  };

  return appendCurrentFlags(
    `${wsUrl}flow/${flowId}/version/${versionId}`,
    params,
  );
};

/**
 * Only use this when opening up a new window.
 */
export const getUrlToRevisionPage = (
  orgId: string,
  wsId: string,
  flowId: string,
  versionId: string,
  etag: string,
) => {
  const wsUrl = getWsUrl(orgId, wsId);
  return appendCurrentFlags(
    `/decide${wsUrl}flow/${flowId}/version/${versionId}/revision/${etag}`,
  );
};

export const getUrlToHistoricDecisionTrace = (params: {
  orgId: string;
  wsId: string;
  flowId: string;
  versionId: string;
  decisionId: string;
  decisionEnv: DecisionEnvironment;
  from?: string;
  to?: string;
}) => {
  const wsUrl = getWsUrl(params.orgId, params.wsId);
  const urlParams = pickBy(
    {
      [URLKeys.LeftPane]: LeftPaneOptions.DecisionHistory,
      [DecisionHistoryKeys.DecisionHistoryView]: params.decisionEnv,
      [URLKeys.DecisionId]: params.decisionId,
      [DecisionHistoryKeys.From]: params.from,
      [DecisionHistoryKeys.To]: params.to,
    },
    ((value) => Boolean(value)) as ExcludesUndefined,
  );

  const paramsString = new URLSearchParams(urlParams).toString();
  const searchParams = paramsString.length ? `?${paramsString}` : "";

  return appendCurrentFlags(
    `/decide${wsUrl}flow/${params.flowId}/version/${params.versionId}${searchParams}`,
  );
};

/**
 * AUTH-6206: Always reload the page when navigating to the api docs.
 * There is a bug in Swagger-UI where the schemas do not update otherwise.
 */
export const getApiDocsUrl = (
  orgId: string,
  wsId: string,
  flow_id: string,
  version_id: string,
): string => {
  const wsUrl = getWsUrl(orgId, wsId);
  return appendCurrentFlags(
    `${wsUrl}flow/${flow_id}/version/${version_id}/docs`,
  );
};

export const getLinkToApiDocsPage = (
  orgId: string,
  wsId: string,
  flow: FlowT,
): string => {
  if (flow.versions.length > 0) {
    return getApiDocsUrl(orgId, wsId, flow.id, flow.versions[0].id);
  }
  throw new Error("Could get a link to the docs of the flow");
};

export const getWelcomeUrl = (orgId: string) => {
  return `${getOrgUrl(orgId)}/welcome`;
};

export const getSignUpUrlWithNext = (): string => {
  // Preserving the orignal path, query params and anchor when redirecting to the sign-up/login page.
  // Here we additionally encode the anchor/hast to make sure it's processed as a part of the `next` query parameter
  // and not a part of the `/sign-up` page URL.
  const original_path =
    window.location.pathname +
    window.location.search +
    encodeURIComponent(window.location.hash);
  const base = baseUrl();

  if (base && original_path) {
    const path = original_path.replace(base, "");
    return pathJoin(base, `/sign-up?next=${path}`);
  } else {
    return `/sign-up?next=${original_path}`;
  }
};

export const getUrlToHistoricDecisionFromCase = (
  orgId: string,
  wsId: string,
  reviewCase: ReviewCase,
) => {
  const wsUrl = getWsUrl(orgId, wsId);
  const historyViewOption = reviewCase.decision.is_sandbox
    ? DecisionEnvironment.SANDBOX
    : DecisionEnvironment.LIVE;
  return appendCurrentFlags(
    `${wsUrl}flow/${reviewCase.flow.id}/version/${reviewCase.flow.version_id}?${URLKeys.LeftPane}=${LeftPaneOptions.DecisionHistory}&${DecisionHistoryKeys.DecisionHistoryView}=${historyViewOption}&${URLKeys.DecisionId}=${reviewCase.decision.id}`,
  );
};

export const getUrlToReviewCase = (
  orgId: string,
  wsId: string,
  flowId: string,
  caseId: string,
  search?: string,
) => {
  const wsUrl = getWsUrl(orgId, wsId);
  return appendCurrentFlags(
    `${wsUrl}flows/${flowId}/review-queue/${caseId}`,
    search,
  );
};

export const getUrlToReviewCasePreview = (
  orgId: string,
  wsId: string,
  flowId: string,
  search?: string,
) => {
  const wsUrl = getWsUrl(orgId, wsId);
  return appendCurrentFlags(
    `${wsUrl}flows/${flowId}/review-queue/preview`,
    search,
  );
};

type JobFilterUrlParams =
  | {
      jobId: string;
      jobRunId: string;
    }
  | {
      jobId?: never;
      jobRunId?: never;
    };

type DecisionHistoryUrlParams = {
  decisionId?: string;
  env?: DecisionEnvironment;
  from?: string;
  to?: string;
} & JobFilterUrlParams;

type ExcludesUndefined = <T>(x: T | undefined) => x is T;

export const getUrlFlowDecisionHistory = (
  orgId: string,
  wsId: string,
  flowId: string,
  params: DecisionHistoryUrlParams = {},
) => {
  const wsUrl = getWsUrl(orgId, wsId);

  const urlParams = pickBy(
    {
      [DecisionHistoryKeys.DecisionId]: params.decisionId,
      [DecisionHistoryKeys.JobId]: params.jobId,
      [DecisionHistoryKeys.JobRunId]: params.jobRunId,
      [DecisionHistoryKeys.DecisionHistoryView]: params.env,
      [DecisionHistoryKeys.From]: params.from,
      [DecisionHistoryKeys.To]: params.to,
    },
    ((value) => Boolean(value)) as ExcludesUndefined,
  );

  const paramsString = new URLSearchParams(urlParams).toString();
  const searchParams = paramsString.length ? `?${paramsString}` : "";
  return appendCurrentFlags(`${wsUrl}flows/${flowId}/history${searchParams}`);
};

export const getUrlToReviewQueue = (
  orgId: string,
  wsId: string,
  flowId: string,
  search?: string,
) => {
  const wsUrl = getWsUrl(orgId, wsId);
  return appendCurrentFlags(`${wsUrl}flows/${flowId}/review-queue`, search);
};

export const getUrlToJobsPage = (
  orgId: string,
  wsId: string,
  flowId: string,
  search?: string,
) => {
  const wsUrl = getWsUrl(orgId, wsId);
  return appendCurrentFlags(`${wsUrl}flows/${flowId}/jobs`, search);
};

export const getUrlToJobPage = (
  orgId: string,
  wsId: string,
  flowId: string,
  jobId: string,
  environment: DecisionEnvironment = DecisionEnvironment.LIVE,
) => {
  const wsUrl = getWsUrl(orgId, wsId);
  return appendCurrentFlags(`${wsUrl}flows/${flowId}/jobs/${jobId}`, {
    environment,
  });
};

export const adminPanelPath = "/admin-panel";

export const getUrlToRetoolPage = (
  orgId: string,
  wsId: string,
  retoolId: string | undefined,
) => {
  if (!retoolId) {
    return null;
  }
  const wsUrl = getWsUrl(orgId, wsId);
  return appendCurrentFlags(`${wsUrl}retool/${retoolId}`);
};
