import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FormItem } from "src/base-components/FormItem";
import { Switch } from "src/base-components/Switch";
import { CustomConnectionForm } from "src/customConnectionNode/types";

type RetryConfigurationPropsT = {
  immutable: boolean;
};

export const RetryConfiguration: React.FC<RetryConfigurationPropsT> = ({
  immutable,
}) => {
  const { control } = useFormContext<CustomConnectionForm>();
  return (
    <div className="my-6 flex items-center justify-between">
      <div>
        <h3 className="text-gray-800 font-inter-medium-12px">
          Retry in case of an error
          <FormItem.Help
            action={{
              text: "Read more",
              onClick: () =>
                window.open(
                  "https://docs.taktile.com/third-party-connections/custom-connection-node/using-the-custom-connection-in-your-decision-flow#id-1.2-setup-the-custom-connection-node",
                  "_blank",
                ),
            }}
            body={
              <span>
                Retry the request in case the response returns one of the
                following status codes:{" "}
                <span className="font-fira-code">
                  408, 409, 425, 429, 500, 502, 503, or 504
                </span>
                . If retries are enabled, the "Do not error" settings configured
                below will be applied after the retries.
              </span>
            }
            className="ml-1 align-middle"
          />
        </h3>
      </div>
      <Controller
        control={control}
        name="config.retry_config.active"
        render={({ field }) => {
          return (
            <Switch
              dataLoc="custom-connection-node-retry-active"
              disabled={immutable}
              enabled={field.value}
              onChange={() => {
                field.onChange(!field.value);
              }}
            />
          );
        }}
      />
    </div>
  );
};
