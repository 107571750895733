export const SSH_TUNNEL_DEFAULTS = {
  host: "",
  port: 22,
  user: "",
  privateKey: {
    key: "ssh_private_key",
    value: "",
    secret: false,
    environment: null,
    required: true,
  },
};
