import Quill from "quill";
import { MutableRefObject, RefObject, useEffect, useRef } from "react";

import { BeMappedNode } from "src/constants/NodeDataTypes";
import { renderDropdownItem } from "src/copilot/PromptBox";
import {
  filterQuillValues,
  MentionItemT,
  quillOnSelect,
} from "src/copilot/quillUtils";

type Params = {
  editorRef: React.RefObject<HTMLDivElement>;
  nodes: BeMappedNode[];
  submitRef: MutableRefObject<(quill: Nullable<Quill>) => void>;
  value: string;
  onChange: (text: string) => void;
  minified: boolean;
};

const useSetQuillDefaultValue = (quill: RefObject<Quill>, value: string) => {
  useEffect(() => {
    if (quill.current && quill.current.getText() !== value) {
      quill.current.setText(value);
    }
  }, [quill, value]);
};

const useTextChange = (
  quill: RefObject<Quill>,
  onChange: (text: string) => void,
) => {
  useEffect(() => {
    const currentQuill = quill.current;

    if (!currentQuill) return;
    const textChangeHandler = () => {
      const content = currentQuill?.getText();
      if (content) {
        onChange(content);
        //setting cursor to the end of the content instead of start
        currentQuill.setSelection(content.length);
      }
    };

    currentQuill.on("text-change", textChangeHandler);

    return () => {
      currentQuill.off("text-change", textChangeHandler);
    };
  }, [quill, onChange]);
};

const useSetTextAreaHeight = (quill: RefObject<Quill>) => {
  useEffect(() => {
    if (!quill.current) return;
    const textArea = quill.current.root;
    if (textArea) {
      textArea.style.maxHeight = "60px";
    }
  }, [quill]);
};

export const useInitQuill = ({
  editorRef,
  nodes,
  minified,
  submitRef,
  value,
  onChange,
}: Params) => {
  const quill = useRef<Nullable<Quill>>(null);
  const nodesRef = useRef(nodes);

  nodesRef.current = nodes;

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }

    if (!quill.current) {
      quill.current = new Quill(editorRef.current, {
        formats: ["mention"],
        placeholder: minified ? "Reply to Copilot..." : "Message Copilot...",
        modules: {
          keyboard: {
            bindings: {
              enter: {
                key: "Enter",
                handler: () => {
                  submitRef.current(quill.current);
                  setTimeout(() => {
                    quill.current?.focus();
                  });
                },
              },
            },
          },
          mention: {
            source: (
              searchTerm: string,
              renderList: (values: BeMappedNode[], searchTerm: string) => void,
            ) => filterQuillValues(searchTerm, renderList, nodesRef.current),
            onSelect: (
              item: MentionItemT,
              insertItem: (item: MentionItemT) => void,
            ) => quillOnSelect(item, insertItem, nodesRef.current),
            renderItem: renderDropdownItem,
            mentionContainerClass:
              "shadow-sm z-10 bg-white max-h-[300px] overflow-y-auto",
            allowedChars: /^[A-Za-z\s]*$/,
            mentionListClass: "ql-mention-list [&_.selected]:bg-gray-100",
          },
        },
      });
    }
  }, [editorRef, minified, submitRef]);

  useEffect(() => {
    if (!quill.current) return;

    quill.current.root.setAttribute(
      "data-placeholder",
      minified ? "Reply to Copilot..." : "Message Copilot...",
    );
  }, [minified]);

  useSetQuillDefaultValue(quill, value);
  useTextChange(quill, onChange);
  useSetTextAreaHeight(quill);

  return quill;
};
