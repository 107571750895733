import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  InputMappingGroupT,
  InputMappingT,
  InputMappingsT,
  IntegrationResourceT,
  OutputMappingT,
} from "src/integrationNode/types";

const getDefaultRegistry = (): InputMappingGroupT => ({
  getDefaultElements: () => ({
    type: {
      id: uuidV4(),
      type: "text",
      displayName: "Type",
      assignedTo: "",
      rules: {
        required: true,
      },
      hint: `Type of registry. Possible values are "GNR", "HRA", "HRB", "SSR", "VR" and "PR".`,
    },
    registry_number: {
      id: uuidV4(),
      type: "text",
      displayName: "Registry number",
      assignedTo: "",
      rules: {
        required: true,
      },
    },
    postal_code: {
      id: uuidV4(),
      type: "text",
      displayName: "Postal code",
      assignedTo: "",
      hint: "Postal code of the registry office.",
    },
    city: {
      id: uuidV4(),
      type: "text",
      displayName: "City",
      assignedTo: "",
      hint: "City of the registry office.",
    },
  }),
});

const locationElements = (): { [key: string]: InputMappingT } => ({
  address_type: {
    id: uuidV4(),
    type: "text",
    displayName: "Address type",
    assignedTo: "",
    hint: `Possible values are "MAIN_ADDRESS", "LEGAL_OFFICE", "REG_DATA_HEAD_OFFICE", "COMPANY_HEADQUARTERS"`,
  },
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
  },
  house: {
    id: uuidV4(),
    type: "text",
    displayName: "House",
    assignedTo: "",
    hint: "House number (possibly with suffixes)",
  },
  house_extension: {
    id: uuidV4(),
    type: "text",
    displayName: "House extension",
    assignedTo: "",
    hint: "Extension number, e.g. flat number",
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
  },
  country: {
    id: uuidV4(),
    type: "text",
    displayName: "Country",
    assignedTo: "",
    hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g, "DE" for Germany).`,
  },
});

const getDefaultUserReference = (): InputMappingGroupT => ({
  getDefaultElements: () => ({
    customer_id: {
      id: uuidV4(),
      type: "text",
      displayName: "Customer ID",
      assignedTo: "",
    },
    inquiry_reference: {
      id: uuidV4(),
      type: "text",
      displayName: "Inquiry reference",
      assignedTo: "",
      hint: "Client's internal reference for inquiry",
    },
    cost_center: {
      id: uuidV4(),
      type: "text",
      displayName: "Cost center",
      assignedTo: "",
    },
  }),
  displayName: "User reference",
});

const getCRIFB2BCommonInput = (): InputMappingsT => ({
  ungrouped: {
    identifier: {
      id: uuidV4(),
      type: "text",
      displayName: "Identifier",
      assignedTo: "",
      hint: "This string uniquely identifies the company in CRIF's systems.",
    },
  },
  grouped: {
    registry: getDefaultRegistry(),
    user_reference: getDefaultUserReference(),
  },
  lists: {},
});

const baseCRIFB2BInsights = (): { [key: string]: OutputMappingT } => ({
  solvency_rating: {
    ...getNamedOutputMapping("Solvency rating"),
    hint: "Solvency rating of the company, according to the German school mark. Values are between 1.0 and 6.0. 0 is returned if it cannot be determined.",
  },
  solvency_rating_description: {
    ...getNamedOutputMapping("Solvency rating description"),
    hint: "Descriptive text of the solvency rating.",
  },
  payment_experience: {
    ...getNamedOutputMapping("Payment experience"),
    hint: `Gives you a descriptor of how this company is doing with respect to payments. Some possible values are: "slow", "could not be obtained", "partly with delays in payment", "cash only".`,
  },
  payment_experience_code: {
    ...getNamedOutputMapping("Payment experience code"),
    hint: `Gives you the associated code to the "payment experience" text value.`,
  },
  default_probability: {
    ...getNamedOutputMapping("Default probability"),
    hint: `The probability that the company will default on its debt obligations. Value is between 0 and 1.`,
  },
  company_legal_form: {
    ...getNamedOutputMapping("Company legal form"),
    hint: `Legal entity type of the company. Some possible values are: "Trade Union", "Sole proprietorship", "General proprietorship", "Public limited company".`,
  },
  total_employees: {
    ...getNamedOutputMapping("Total employees"),
    hint: `The total number of employees in the company.`,
  },
  negative_information: {
    ...getNamedOutputMapping("Negative information"),
    hint: `Indicates whether there is negative information about the company.`,
  },
});

export const getDefaultCRIFB2BFullReport = (): IntegrationResourceT => ({
  providerResource: { provider: "crif_b2b", resource: "full_report" },
  connectionId: "",
  resourceConfigId: "",
  input: getCRIFB2BCommonInput(),
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(),
      ...baseCRIFB2BInsights(),
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultCRIFB2BCreditReport = (): IntegrationResourceT => ({
  providerResource: { provider: "crif_b2b", resource: "credit_report" },
  connectionId: "",
  resourceConfigId: "",
  input: getCRIFB2BCommonInput(),
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(),
      ...baseCRIFB2BInsights(),
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultCRIFB2BSearchReport = (): IntegrationResourceT => ({
  providerResource: { provider: "crif_b2b", resource: "search_report" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      company_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Company name",
        assignedTo: "",
        rules: {
          required: true,
        },
      },
      max_results: {
        id: uuidV4(),
        type: "text",
        displayName: "Max results",
        assignedTo: "",
      },
    },
    grouped: {
      location: {
        getDefaultElements: locationElements,
        elements: locationElements(),
        rules: {
          required: true,
        },
      },
      user_reference: getDefaultUserReference(),
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(CommonOutputMappingKey.RETRIEVAL_DATETIME),
      highest_similarity_score: {
        ...getNamedOutputMapping("Highest similarity score"),
        hint: "The highest similarity score of the search results.",
      },
      highest_similarity_onr: {
        ...getNamedOutputMapping("Highest similarity ONR"),
        hint: "The ONR of the search result with the highest similarity score.",
      },
      highest_similarity_company_name: {
        ...getNamedOutputMapping("Highest similarity company name"),
        hint: "The company name of the search result with the highest similarity score.",
      },
    },
  },
  config: defaultResourceConfig,
});
