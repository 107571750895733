import { useCallback } from "react";

import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowT } from "src/api/flowTypes";
import { useChangeHistories } from "src/changeHistory/queries";
import { HistoricalDecisionV3 } from "src/clients/history-v3";
import {
  DecisionDisabledReason,
  getDecisionDisabledReason,
} from "src/versionDecisionHistory/Table";

type DecisionTraceEnabled = {
  isDisabled: boolean;
  disabledReason?: DecisionDisabledReason;
};

export const useDecisionTracingDisabledCb = (flow: FlowT) => {
  const historyChanges = useChangeHistories(
    flow.versions.map((version) => ({
      versionId: version.id,
      etag: version.etag ?? "00000000",
    })),
  );

  return useCallback(
    (
      decision: DecisionHistoryRecordV2 | HistoricalDecisionV3,
    ): DecisionTraceEnabled => {
      const versionId =
        "version_id" in decision.flow
          ? decision.flow.version_id
          : decision.flow.version?.id;

      const change = historyChanges.find(
        (change) => change.data?.flow_version_id === versionId,
      );

      const disabledReason = getDecisionDisabledReason(
        decision,
        change?.data?.etag,
        change?.data?.created_at,
      );

      return {
        isDisabled: change?.isLoading ? true : Boolean(disabledReason),
        disabledReason: disabledReason ? disabledReason : undefined,
      };
    },
    [historyChanges],
  );
};
