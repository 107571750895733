import { useEffect, useState } from "react";

import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { Modal } from "src/design-system/Modal";
import { SelectFlowAndVersion } from "src/nodeEditor/SelectFlowPane/SelectFlowPane";

export const FlowSelectionModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  handleSubmit: (version: FlowVersionT, flow: FlowT) => void;
}> = ({ open, handleClose, handleSubmit }) => {
  const [selectedFlow, setSelectedFlow] = useState<FlowT | null>(null);
  const [selectedVersion, setSelectedVersion] = useState<FlowVersionT | null>(
    null,
  );

  // Reset the modal state upon reopening
  useEffect(() => {
    if (open) {
      setSelectedFlow(null);
      setSelectedVersion(null);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Modal.Header>Select a Decision Flow and version</Modal.Header>
      <Modal.Content>
        <div className="flex h-[540px] flex-col">
          <SelectFlowAndVersion
            selectedFlow={selectedFlow}
            selectedVersionId={selectedVersion?.id}
            onFlowSelect={setSelectedFlow}
            onVersionSelect={setSelectedVersion}
          />
        </div>
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="flow-selection-submit"
            disabled={selectedFlow === null || selectedVersion === null}
            onClick={() => {
              if (selectedVersion && selectedFlow) {
                handleSubmit(selectedVersion, selectedFlow);
                handleClose();
              }
            }}
          >
            Select a Decision Flow and version
          </Button>
        }
      ></Modal.Footer>
    </Modal>
  );
};
