import { faAdd, faPercentage } from "@fortawesome/pro-regular-svg-icons";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { m, AnimatePresence } from "framer-motion";
import { cloneDeep } from "lodash";
import React, { useImperativeHandle, useRef } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useOutletContext } from "react-router";
import useMeasure from "react-use-measure";
import { twJoin } from "tailwind-merge";

import { FlowVersionT } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { Pill } from "src/base-components/Pill";
import { Select } from "src/base-components/Select";
import { FlowVersionStatus } from "src/clients/flow-api";
import { Callout } from "src/design-system/Callout";
import { Modal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { FlowPageContext } from "src/flow/FlowPage";
import { VersionStatusPill } from "src/flow/Pills";
import { useCreateTrafficPolicy } from "src/jobs/api/queries";
import { DELETED_VERSION_NAME } from "src/jobs/common/constants";
import { Job, JobTrafficPolicy } from "src/jobs/types";

export type Props = {
  open: boolean;
  onClose: () => void;
  job: Job;
};

type TrafficPolicyFormData = Pick<
  JobTrafficPolicy,
  "policy" | "stable_routing"
>;

const getDeletedVersionFallback = (id: string) => ({
  id,
  name: DELETED_VERSION_NAME,
  status: FlowVersionStatus.ARCHIVED,
});

const getInitialTrafficPolicy = (job: Job): TrafficPolicyFormData => {
  if (job.active_traffic_policy) {
    // Don't modify react query's copy
    const { policy, stable_routing } = job.active_traffic_policy;
    return cloneDeep({ policy, stable_routing });
  } else if (job.flow_version_id) {
    return {
      policy: {
        type: "probabilistic",
        flow_versions: [
          {
            flow_version_id: job.flow_version_id,
            percentage: 100,
          },
        ],
      },
      stable_routing: false,
    };
  } else {
    return {
      policy: {
        type: "probabilistic",
        flow_versions: [],
      },
      stable_routing: false,
    };
  }
};
const SmoothResizeWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // The height of this component is animated as
  // additional versions are added or removed
  const [ref, { height }] = useMeasure({
    offsetSize: true,
  });
  // Avoids scrollbar flickering
  const EXTRA_HEIGHT = 20;
  return height !== 0 ? (
    <m.div
      animate={{
        height: height + EXTRA_HEIGHT,
      }}
      initial={false}
    >
      <div ref={ref}>{children}</div>
    </m.div>
  ) : (
    <div ref={ref}>{children}</div>
  );
};

export const TrafficPolicyModal: React.FC<Props> = ({ open, onClose, job }) => {
  const { flow, workspace } = useOutletContext<FlowPageContext>();
  const { control, handleSubmit, watch, formState, register, reset } =
    useForm<TrafficPolicyFormData>({
      defaultValues: getInitialTrafficPolicy(job),
      reValidateMode: "onSubmit",
      resolver: (submittedPolicy) => {
        if (submittedPolicy.policy.flow_versions.length === 0) {
          return {
            errors: {
              policy: {
                flow_versions: {
                  message:
                    "There should be at least one version to route traffic towards",
                },
              },
            },
            values: {},
          };
        }
        const percentageTotal = submittedPolicy.policy.flow_versions.reduce(
          (prev, version) => prev + version.percentage,
          0,
        );
        if (percentageTotal !== 100) {
          return {
            errors: {
              policy: {
                flow_versions: {
                  message: "Traffic percentages should add up to 100%",
                },
              },
            },
            values: {},
          };
        } else {
          return { values: submittedPolicy, errors: {} };
        }
      },
    });
  const {
    fields: flowVersionFields,
    append: appendVersion,
    remove: removeVersion,
  } = useFieldArray({
    control,
    name: "policy.flow_versions",
  });

  const currentVersionsIds = watch("policy.flow_versions").map(
    (version) => version.flow_version_id,
  );

  const availableVersions = flow.versions.filter(
    (v) => v.status !== FlowVersionStatus.ARCHIVED,
  );

  const versionsWithoutTraffic = availableVersions.filter(
    (version) => !currentVersionsIds.includes(version.id),
  );
  const onAddVersion = () => {
    const versionToAppend = versionsWithoutTraffic.at(0);

    if (versionToAppend) {
      const versionToAppendIndex = currentVersionsIds.length;
      appendVersion(
        { flow_version_id: versionToAppend.id, percentage: 0 },
        {
          focusName: `flow_versions.${versionToAppendIndex}.percentage`,
          shouldFocus: true,
        },
      );
    }
  };

  const createTrafficPolicy = useCreateTrafficPolicy(
    workspace.base_url!,
    job.id,
    job.flow_id,
  );
  const onSubmit = async (submittedPolicy: TrafficPolicyFormData) => {
    try {
      await createTrafficPolicy.mutateAsync(submittedPolicy);
      onClose();
      reset(submittedPolicy);
    } catch {
      toastActions.failure({
        title: `Something went wrong applying the new Traffic Policy to this Job`,
        description: TAKTILE_TEAM_NOTIFIED,
      });
    }
  };

  const thereIsOnlyOneVersion = currentVersionsIds.length === 1;

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header description="Link a Decision Flow to enable automated decision-making for your Job.">
        Connect Decision Flow
      </Modal.Header>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <SmoothResizeWrapper>
            <div className="flex flex-col gap-y-6">
              <m.div transition={{ type: "tween", ease: "easeOut" }} layout>
                <Label required>Decision Flow</Label>
                <Input value={flow.name} disabled fullWidth />
              </m.div>
              <div>
                <m.div transition={{ type: "tween", ease: "easeOut" }} layout>
                  <Label required>Version</Label>
                </m.div>
                <div className="space-y-2">
                  <AnimatePresence initial={false}>
                    {flowVersionFields.map((flowVersionField, i) => (
                      <m.div
                        key={flowVersionField.id}
                        animate={{
                          opacity: 1,
                          transition: {
                            type: "tween",
                            ease: "easeOut",
                            delay: 0.15,
                          },
                        }}
                        className="flex items-center"
                        initial={{ opacity: 0 }}
                        transition={{ type: "tween", ease: "easeOut" }}
                        layout
                      >
                        <Controller
                          control={control}
                          name={`policy.flow_versions.${i}.flow_version_id`}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <FlowVersionSelector
                                selectedVersion={
                                  availableVersions.find(
                                    (version) => version.id === value,
                                  ) ?? getDeletedVersionFallback(value)
                                }
                                versionsWithoutTraffic={versionsWithoutTraffic}
                                onChange={onChange}
                              />
                            );
                          }}
                        />
                        <Controller
                          control={control}
                          name={`policy.flow_versions.${i}.percentage`}
                          render={({ field: { onChange, value, ref } }) => (
                            <PercentageInput
                              ref={ref}
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                        <div
                          className={twJoin(
                            "ml-2",
                            thereIsOnlyOneVersion && "opacity-0",
                          )}
                        >
                          <Icon
                            color="text-gray-500 hover:text-gray-600 active:text-gray-700"
                            icon={faTrashCan}
                            size="xs"
                            onClick={() => removeVersion(i)}
                          />
                        </div>
                      </m.div>
                    ))}
                    <m.div
                      key="add-version"
                      className="mt-2"
                      transition={{ type: "tween", ease: "easeOut" }}
                      layout
                    >
                      <Button
                        dataLoc="policy-add-version-button"
                        disabled={versionsWithoutTraffic.length === 0}
                        iconLeft={faAdd}
                        variant="secondary"
                        onClick={onAddVersion}
                      >
                        Add version
                      </Button>
                    </m.div>
                    <m.div
                      key="consistent-routing"
                      className="pt-4"
                      transition={{ type: "tween", ease: "easeOut" }}
                      layout
                    >
                      <div className="flex items-center gap-x-2">
                        <Checkbox {...register("stable_routing")} />
                        <Label mb="mb-0">Use consistent routing</Label>
                      </div>
                      <div className="pl-5 text-gray-500 font-inter-normal-12px">
                        <p>
                          Route the same{" "}
                          <Pill size="sm" variant="gray">
                            <Pill.Text fontType="code">entity_id</Pill.Text>
                          </Pill>{" "}
                          to the same Decision Flow version.
                        </p>
                        <p>This enables reliable A/B testing.</p>
                      </div>
                      <div className="mt-4">
                        <Callout type="warning">
                          Updating Decision Flow or routing details would reset
                          the entity ID mapping
                        </Callout>
                      </div>
                    </m.div>
                  </AnimatePresence>
                </div>
              </div>
            </div>
            {formState.errors.policy && (
              <m.div
                className="mt-4"
                transition={{ type: "tween", ease: "easeOut" }}
                layout
              >
                <Callout type="error">
                  {formState.errors.policy.flow_versions?.message}
                </Callout>
              </m.div>
            )}
          </SmoothResizeWrapper>
        </Modal.Content>
        <m.div transition={{ type: "tween", ease: "easeOut" }} layout>
          <Modal.Footer
            primaryButton={
              <Button
                dataLoc="policy-save-button"
                disabled={!formState.isDirty}
                htmlType="submit"
                loading={formState.isSubmitting}
                variant="primary"
                fullWidth
              >
                Add Decision Flow
              </Button>
            }
          ></Modal.Footer>
        </m.div>
      </form>
    </Modal>
  );
};

const PercentageInput = React.forwardRef<
  HTMLInputElement,
  {
    value: number;
    onChange: (value: number) => void;
  }
>(({ value, onChange }, ref) => {
  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => innerRef.current!);
  return (
    <div className="relative w-max">
      <div
        className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 font-inter-normal-13px"
        onClick={() => {
          innerRef.current?.focus();
        }}
      >
        <Icon cursorType="text" icon={faPercentage} size="xs" />
      </div>
      <input
        ref={innerRef}
        className="h-8 w-[90px] rounded-lg border border-gray-300 pl-4 pr-8 text-gray-800 outline-1 -outline-offset-1 outline-indigo-500 font-inter-normal-13px [appearance:textfield] focus:outline [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
        data-loc="percentage-input"
        max="100"
        min="0"
        type="number"
        value={value}
        onChange={(e) => onChange(e.target.valueAsNumber)}
      />
    </div>
  );
});

const FlowVersionSelector: React.FC<{
  selectedVersion: Pick<FlowVersionT, "id" | "name" | "status">;
  onChange: (id: string) => void;
  versionsWithoutTraffic: FlowVersionT[];
}> = ({ selectedVersion, onChange, versionsWithoutTraffic }) => {
  const versionToOption = (
    version: typeof selectedVersion,
    isSelected = false,
  ) => {
    return (
      <div
        className={twJoin(
          "flex min-w-0 flex-1 items-center justify-between",
          isSelected ? "mr-3" : "mr-8",
        )}
      >
        <div className="mr-3 min-w-0 flex-1 truncate">{version.name}</div>
        <div className="h-5.5 flex-shrink-0 -translate-y-px">
          <VersionStatusPill status={version.status} />
        </div>
      </div>
    );
  };

  const options = versionsWithoutTraffic
    .map((v) => ({ key: v.id, value: versionToOption(v) }))
    .concat([
      {
        key: selectedVersion.id,
        value: versionToOption(selectedVersion, true),
      },
    ]);
  return (
    <div className="relative mr-4 min-w-0 flex-1">
      <Select
        options={options}
        value={selectedVersion.id}
        listMatchesButtonWidth
        onChange={onChange}
      />
    </div>
  );
};
