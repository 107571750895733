export const HEADER_HEIGHT = "3.25rem" as const;

/**
 * For detail views without breadcrumps, e.g. manual review cases or jobs
 */
export const DetailHeader: React.FC<{
  main: React.ReactNode;
  actions: React.ReactNode;
}> = ({ main, actions }) => {
  return (
    <div
      className="relative flex flex-row items-center justify-between gap-x-4 bg-white px-5 py-2 font-inter-semibold-14px"
      style={{ height: HEADER_HEIGHT }}
    >
      <div className="flex items-center">{main}</div>
      {actions}
    </div>
  );
};
