import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { omit } from "lodash";
import React, { useImperativeHandle, useRef } from "react";
import { twJoin, twMerge } from "tailwind-merge";

type IconProps = {
  icon: IconProp;
  variant?: "danger";
  onClick?: () => void;
};
type Props = Omit<
  React.ComponentPropsWithoutRef<"input">,
  "style" | "className"
> & {
  errored?: boolean;
  prefixIcon?: IconProps;
  suffixIcon?: IconProps;
  fullWidth?: boolean;
} & ({ monospaced: true; prefixText?: string } | { monospaced?: false });

export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      disabled = false,
      errored,
      prefixIcon,
      suffixIcon,
      fullWidth,
      ...restOfProps
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => innerRef.current!);

    return (
      <div
        className={twMerge(
          "flex h-8 w-50 items-center overflow-hidden rounded-lg border font-inter-normal-12px",
          !disabled && "cursor-text bg-white",
          disabled && "bg-gray-50 text-gray-500",
          !errored &&
            "border-gray-200 focus-within:border-indigo-600 focus-within:ring-2 focus-within:ring-indigo-600/25",
          errored && "border-red-600 ring-2 ring-red-400/25",
          restOfProps.monospaced && "font-mono",
          !restOfProps.monospaced && !disabled && "text-gray-800",
          restOfProps.monospaced &&
            !disabled &&
            !restOfProps.prefixText &&
            "text-indigo-700",
          restOfProps.monospaced &&
            !disabled &&
            restOfProps.prefixText &&
            "text-green-700",
          fullWidth ? "w-full" : "w-50",
        )}
        onClick={() => innerRef.current?.focus()}
      >
        {prefixIcon && (!restOfProps.monospaced || !restOfProps.prefixText) && (
          <FontAwesomeIcon
            className={twJoin(
              "flex-shrink-0 pl-4",
              prefixIcon.variant === "danger" && "text-red-400",
              !prefixIcon.variant &&
                (errored ? "text-red-400" : "text-gray-500"),
            )}
            icon={prefixIcon.icon}
            width={12}
          />
        )}
        {restOfProps.monospaced && restOfProps.prefixText && (
          <div className="flex h-full items-center border-r border-gray-200 bg-gray-100 pl-3 pr-2 text-indigo-500">
            {restOfProps.prefixText}
          </div>
        )}
        <input
          ref={innerRef}
          autoComplete="off"
          className={twJoin(
            "min-w-0 flex-1 py-[5px] pr-2 outline-none placeholder:text-gray-500",
            restOfProps.monospaced
              ? "font-mono text-xs placeholder:font-mono placeholder:text-xs"
              : "font-inter-normal-12px placeholder:font-inter-normal-12px",
            !prefixIcon && (!restOfProps.monospaced || !restOfProps.prefixText)
              ? "pl-3"
              : "pl-2",
          )}
          disabled={disabled}
          {...omit(restOfProps, "monospaced", "prefixText")}
        />
        {suffixIcon && (
          <FontAwesomeIcon
            className={twJoin(
              "flex-shrink-0 pr-3",
              suffixIcon.variant === "danger" && "text-red-400",
              !suffixIcon.variant &&
                (errored ? "text-red-400" : "text-gray-500"),
              suffixIcon.onClick && "cursor-pointer",
            )}
            icon={suffixIcon.icon}
            width={12}
            onClick={suffixIcon.onClick}
          />
        )}
      </div>
    );
  },
);
