import {
  faEmptySet,
  faFilter,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import { Menu } from "@headlessui/react";
import { Column, Table } from "@tanstack/react-table";
import { AnimatePresence, m } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";

import { Button } from "src/base-components/Button";
import { Input } from "src/base-components/Input";
import {
  ALWAYS_VISIBLE_COLUMNS,
  AUX_DATA_COLUMN_PREFIX,
  EXPECTED_DATA_COLUMN_PREFIX,
  INTERMEDIATE_TABLE_PREFIXES,
} from "src/dataTable/TablePrefixes";
import { ResultDataAndAuxRowV2 } from "src/dataTable/types";
import { EmptyState } from "src/design-system/EmptyState";
import { ResultAllColumnsSelectorRow } from "src/nodeEditor/ResultAllColumnsSelectorRow";
import { ResultColumnSelectorRow } from "src/nodeEditor/ResultColumnSelectorRow";
import { useFuseSearch } from "src/utils/useFuseSearch";

const isAlwaysVisibleColumn = (column: Column<ResultDataAndAuxRowV2>) =>
  (ALWAYS_VISIBLE_COLUMNS as readonly string[]).includes(column.id);

type SearchableSelectProps = {
  tableInstance: Table<ResultDataAndAuxRowV2>;
};

const COLUMNS_SUBHEADERS: Record<string, string> = {
  [AUX_DATA_COLUMN_PREFIX]: "Additional Fields",
  [EXPECTED_DATA_COLUMN_PREFIX]: "Expected Output Fields",
} as const;

export const IntermediateTableColumnPicker = ({
  tableInstance,
}: SearchableSelectProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  // Set focus only when the input is rendered
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(inputRef.current)]);

  const options = tableInstance
    .getAllColumns()
    .filter((col) => !isAlwaysVisibleColumn(col))
    .map((column) => {
      const type = INTERMEDIATE_TABLE_PREFIXES.find((prefix) =>
        column.id.startsWith(prefix),
      );
      return {
        type,
        name: column.id.slice(type?.length),
        column,
      };
    })
    .sort(
      (a, b) =>
        INTERMEDIATE_TABLE_PREFIXES.indexOf(a.type!) -
        INTERMEDIATE_TABLE_PREFIXES.indexOf(b.type!),
    );

  const [query, setQuery] = useState("");

  const referenceElement = useRef<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement.current,
    popperElement,
    {
      strategy: "fixed",
      placement: "bottom-start",
      modifiers: [{ name: "offset", options: { offset: [0, 8] } }],
    },
  );

  const search = useFuseSearch(options, {
    shouldSort: false,
    keys: [`name`],
    threshold: 0,
    ignoreLocation: true,
  });

  const results = search(query);
  const columnIds = results.map((item) => item.column.id);

  return (
    <Menu>
      {({ open }) => (
        <>
          <Menu.Button
            ref={referenceElement}
            as="div"
            className="flex"
            data-loc="column-picker-popover"
          >
            <Button
              dataLoc="column-picker-button"
              fullWidth={true}
              iconLeft={faFilter}
              size="base"
              variant="secondary"
            >
              View options
            </Button>
          </Menu.Button>
          <AnimatePresence initial={false}>
            {open && (
              <div
                ref={setPopperElement}
                style={popperStyles.popper}
                {...popperAttributes.popper}
                className="fixed z-50"
              >
                <Menu.Items
                  animate="visible"
                  as={m.div}
                  className="min-h-[288px] w-70 rounded-lg bg-white py-2 shadow-lg"
                  data-loc="column-picker-popover-options"
                  exit="hidden"
                  initial="hidden"
                  transition={{
                    type: "tween",
                    ease: "easeOut",
                    duration: 0.15,
                  }}
                  variants={{
                    visible: {
                      opacity: 1,
                      scale: 1,
                    },
                    hidden: {
                      opacity: 0,
                      scale: 0.95,
                    },
                  }}
                  static
                  onAnimationComplete={(variant: "visible" | "hidden") =>
                    variant === "hidden" && setQuery("")
                  }
                >
                  <div className="px-4 py-2">
                    <Input
                      ref={inputRef}
                      data-loc="column-picker-search"
                      placeholder="Search for a field"
                      prefixIcon={{ icon: faSearch }}
                      value={query}
                      fullWidth
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </div>
                  <div className="decideScrollbar max-h-60 overflow-y-auto">
                    {results.length > 0 ? (
                      <ul>
                        <ResultAllColumnsSelectorRow
                          resultsColumns={columnIds}
                          toggleColumnsVisibility={
                            tableInstance.setColumnVisibility
                          }
                          visibleColumnsCount={
                            tableInstance
                              .getVisibleFlatColumns()
                              .filter((col) => columnIds.includes(col.id))
                              .length
                          }
                        />
                        {results.flatMap((item, index, array) => {
                          const items = [];

                          const previousItem = array[index - 1];
                          if (
                            item.type &&
                            COLUMNS_SUBHEADERS[item.type] &&
                            (!previousItem || item.type !== previousItem.type)
                          ) {
                            items.push(
                              <Menu.Item key={`heading${item.type}`}>
                                <div className="flex h-12 items-center border-t border-gray-100 px-4 py-2.5 text-gray-800 font-inter-medium-12px">
                                  {COLUMNS_SUBHEADERS[item.type]}
                                </div>
                              </Menu.Item>,
                            );
                          }

                          items.push(
                            <Menu.Item key={item.column.id}>
                              <ResultColumnSelectorRow
                                key={item.column.id}
                                column={item.column}
                                query={query}
                              />
                            </Menu.Item>,
                          );

                          return items;
                        })}
                      </ul>
                    ) : (
                      <EmptyState
                        description="Change your search query to try again"
                        headline="No items found"
                        icon={faEmptySet}
                      />
                    )}
                  </div>
                </Menu.Items>
              </div>
            )}
          </AnimatePresence>
        </>
      )}
    </Menu>
  );
};
