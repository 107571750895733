import { ReactNode } from "react";

import { EnumOptionsBET } from "src/api/flowTypes";
import {
  ARRAY_ERROR_MESSAGE,
  BOOLEAN_ERROR_MESSAGE,
  DATETIME_ERROR_MESSAGE,
  DATE_ERROR_MESSAGE,
  INTEGER_ERROR_MESSAGE,
  NUMBER_ERROR_MESSAGE,
  OBJECT_ERROR_MESSAGE,
  STRING_ERROR_MESSAGE,
} from "src/datasets/DatasetTable/validationErrorMessages";

export type ValidationResult =
  | undefined
  | {
      message: string;
      description?: ReactNode;
      applyFix?: (value: string) => string;
    };

type ValidatorFunc = (value: string) => ValidationResult;

export const validateBoolean: ValidatorFunc = (value) => {
  try {
    if (typeof JSON.parse(value) !== "boolean") {
      return BOOLEAN_ERROR_MESSAGE;
    }
  } catch (e) {
    return BOOLEAN_ERROR_MESSAGE;
  }
};

export const validateString: ValidatorFunc = (value) => {
  try {
    if (typeof JSON.parse(value) !== "string") {
      return STRING_ERROR_MESSAGE;
    }
  } catch (e) {
    return {
      message: STRING_ERROR_MESSAGE.message,
      description: (
        <>
          Ensure that the string value is enclosed within quotes. Were you
          intending to use{" "}
          <code className="font-fira-code inline-block rounded bg-gray-700 px-1 py-0.5">
            "{value}"
          </code>
          ?
        </>
      ),
      applyFix: (value) => `"${value}"`,
    };
  }
};

// YYYY-DDD or YYYYDDD
const ISO_ORDINAL_DATE_REGEXP = /^\d{4}-?\d{3}$/;
const ISO_8601_REGEX =
  /^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([.,]\d+(?!:))?)?(\17[0-5]\d([.,]\d+)?)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

export const validateDate: ValidatorFunc = (value) => {
  try {
    const date = JSON.parse(value);
    if (
      typeof date !== "string" ||
      // the same check as in python datetime library
      ![7, 8, 10].includes(date.length) ||
      !(
        ISO_8601_REGEX.test(date) &&
        // Is valid ISO 8601 date but not ISO 8601 ordinal date
        !ISO_ORDINAL_DATE_REGEXP.test(date)
      )
    ) {
      return DATE_ERROR_MESSAGE;
    }
  } catch (e) {
    return DATE_ERROR_MESSAGE;
  }
};

export const validateDatetime: ValidatorFunc = (value) => {
  try {
    const date = JSON.parse(value);
    if (
      typeof date !== "string" ||
      !(
        ISO_8601_REGEX.test(date) &&
        // Is valid ISO 8601 date but not ISO 8601 ordinal date
        !ISO_ORDINAL_DATE_REGEXP.test(date)
      )
    ) {
      return DATETIME_ERROR_MESSAGE;
    }
  } catch (e) {
    return DATETIME_ERROR_MESSAGE;
  }
};

export const validateAny: ValidatorFunc = (value: string) => {
  try {
    // if we can parse it, it's valid
    JSON.parse(value);
  } catch (e) {
    return {
      message: "Invalid value",
      description: "Must be a valid value",
    };
  }
};

export const validateArray: ValidatorFunc = (value) => {
  try {
    if (!Array.isArray(JSON.parse(value))) {
      return ARRAY_ERROR_MESSAGE;
    }
  } catch (e) {
    return ARRAY_ERROR_MESSAGE;
  }
};

export const validateObject: ValidatorFunc = (value) => {
  try {
    const parsed = JSON.parse(value);
    if (
      typeof parsed !== "object" ||
      Array.isArray(parsed) ||
      parsed === null
    ) {
      return OBJECT_ERROR_MESSAGE;
    }
  } catch (e) {
    return OBJECT_ERROR_MESSAGE;
  }
};

export const validateInteger: ValidatorFunc = (value) => {
  try {
    if (!Number.isInteger(JSON.parse(value))) {
      return INTEGER_ERROR_MESSAGE;
    }
  } catch (e) {
    return INTEGER_ERROR_MESSAGE;
  }
};

export const validateNumber: ValidatorFunc = (value) => {
  try {
    const parsedValue = JSON.parse(value);
    if (typeof parsedValue !== "number" || Number.isNaN(parsedValue)) {
      return NUMBER_ERROR_MESSAGE;
    }
  } catch (e) {
    return NUMBER_ERROR_MESSAGE;
  }
};

export const validateEnum = (
  value: EnumOptionsBET[number],
  enumValues?: EnumOptionsBET | null,
) => {
  if (!enumValues?.includes(value)) {
    return {
      message: "Invalid value",
      description: enumValues
        ? `Must be one of: ${enumValues.join(", ")}`
        : "Add an enum field to your schema to validate this value",
    };
  }
};
