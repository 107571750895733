import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useReactFlow } from "reactflow";

import { useFlowVersion } from "src/api/flowVersionQueries";
import { ParentNode } from "src/constants/NodeDataTypes";
import { useSchemas } from "src/datasets/utils";
import { toastActions } from "src/design-system/Toast/utils";
import { validateFlowNodeMapping } from "src/parentFlowNodes/utils";
import { URLKeys } from "src/router/SearchParams";
import { useNodeValidations } from "src/store/NodeValidations";
import { useGraphStore } from "src/store/StoreProvider";
import { isParentNode } from "src/utils/predicates";

const DispatchNotificationBase: React.FC<{
  node: ParentNode;
}> = ({ node }) => {
  const { setSelectedNode, nodesArray } = useGraphStore();
  const childVersion = useFlowVersion(node.data.child_flow_version_id ?? "");
  const notificationRef = useRef<string>("");
  const { setCenter } = useReactFlow();
  const addInvalidNode = useNodeValidations((state) => state.addInvalidNode);
  const removeInvalidNode = useNodeValidations(
    (state) => state.removeInvalidNode,
  );
  const childSchemas = useSchemas(childVersion.data);
  const [params] = useSearchParams();
  const selectedNodeId = params.get(URLKeys.SelectedNodeId);

  // adds to invalid nodes list if any errors with child flow
  useEffect(() => {
    if (
      node.data.is_stale &&
      validateFlowNodeMapping(childSchemas, node.data).length > 0
    ) {
      addInvalidNode(node.id);
    } else {
      removeInvalidNode(node.id);
    }
  }, [childSchemas, node.data, node.id, addInvalidNode, removeInvalidNode]);

  // for displaying notifications
  useEffect(() => {
    if (
      childVersion.data &&
      selectedNodeId !== node.id &&
      !notificationRef.current &&
      node.data.child_flow_version_known_etag !== childVersion.data.etag
    ) {
      const errors = validateFlowNodeMapping(childSchemas, node.data);
      if (errors.length > 0) {
        notificationRef.current = toastActions.default({
          id: node.id,
          title: `Update to ${node.data.label}`,
          description:
            "Review changes and remap fields to complete updated configuration",
          actionText: "View",
          duration: Infinity,
          onActionClick: () => {
            setSelectedNode(node.id);
            toastActions.dismiss(notificationRef.current);
            // since opening sidebar changes width of the canvas setTimeout is required
            setTimeout(() => {
              const nodeFE = nodesArray.find((n) => n.id === node.id);
              if (nodeFE) {
                setCenter(nodeFE.position.x, nodeFE.position.y, {
                  zoom: 1,
                });
              }
            });
          },
        });
      }
    }
  }, [
    node,
    childVersion.data,
    selectedNodeId,
    nodesArray,
    childSchemas,
    addInvalidNode,
    setCenter,
    setSelectedNode,
  ]);

  // child flow deleted
  useEffect(() => {
    if (
      !notificationRef.current &&
      childVersion.failureReason?.response?.status === 403
    ) {
      notificationRef.current = toastActions.default({
        id: node.id,
        title: `Action required on Decision Flow Node`,
        description: `${node.data.label} Decision Flow has been deleted. Select a new Decision Flow.`,
        actionText: "View",
        duration: Infinity,
        onActionClick: () => {
          setSelectedNode(node.id);
          toastActions.dismiss(notificationRef.current);
          // since opening sidebar changes width of the canvas setTimeout is required
          setTimeout(() => {
            const nodeFE = nodesArray.find((n) => n.id === node.id);
            if (nodeFE) {
              setCenter(nodeFE.position.x, nodeFE.position.y, {
                zoom: 1,
              });
            }
          });
        },
      });
    }
  }, [node, childVersion, nodesArray, setSelectedNode, setCenter]);

  return null;
};

const DispatchNotification = observer(DispatchNotificationBase);

export const ChildVersionChangeNotifications: React.FC = () => {
  const { nodesArray } = useGraphStore();

  return (
    <>
      {nodesArray.map((node) => {
        if (isParentNode(node)) {
          if (node.data.child_flow_version_id) {
            return <DispatchNotification key={node.id} node={node} />;
          }
          return null;
        }
        return null;
      })}
    </>
  );
};
