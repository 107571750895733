import { defaults, difference } from "lodash";
import { useCallback, useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";

import { FlowT } from "src/api/flowTypes";
import { useEditFlow } from "src/api/queries";
import {
  transformOutcomeTypesToOptions,
  transformSchemaToOptions,
} from "src/decisionsOverview/utils";
import { toastActions } from "src/design-system/Toast/utils";
import { DecisionHistoryColumns } from "src/flow/decisionHistory/Columns";
import { useOutcomeTypes } from "src/outcomes/queries";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { logger } from "src/utils/logger";
import { toastError } from "src/utils/toastError";

export const decisionFieldsPrefix = "decision.";
export const inputFieldsPrefix = "input.";
export const outputFieldsPrefix = "output.";
export const outcomeFieldsPrefix = "outcome.";

export const decisionFieldItems = [
  { value: DecisionHistoryColumns.DecisionId, label: "Decision ID" },
  { value: DecisionHistoryColumns.EntityId, label: "Entity ID" },
  { value: DecisionHistoryColumns.Datetime, label: "Time" },
  { value: DecisionHistoryColumns.Status, label: "Status" },
  { value: DecisionHistoryColumns.Origin, label: "Origin" },
  { value: DecisionHistoryColumns.Version, label: "Version" },
  { value: DecisionHistoryColumns.Duration, label: "Duration" },
] as const;

const decisionFields = decisionFieldItems.map((field) => field.value);
const maxFields = 10;
const minFields = 3;

const partitionValue = (value: string[]) => {
  return [
    value.filter((s) => s.startsWith(decisionFieldsPrefix)),
    value.filter((s) => s.startsWith(inputFieldsPrefix)),
    value.filter((s) => s.startsWith(outputFieldsPrefix)),
    value.filter((s) => s.startsWith(outcomeFieldsPrefix)),
  ];
};

const buildStorageKey = (flowId: string, type: string) =>
  `viewOptions:${flowId}:${type}`;

type Args = {
  flow: FlowT | undefined;
};
export const useViewOptions = ({ flow }: Args) => {
  const {
    workspace_id,
    id: flowId,
    name: flowName,
    ui_default_decision_fields_exclude,
    ui_default_inputs_include,
    ui_default_outputs_include,
    ui_default_outcomes_include,
  } = defaults(flow, {
    workspace_id: "dummy-ws",
    id: "dummy-flow",
    ui_default_decision_fields_exclude: [],
    ui_default_inputs_include: [],
    ui_default_outputs_include: [],
    ui_default_outcomes_include: [],
  });

  const { mutateAsync: updateFlow, isLoading } = useEditFlow(workspace_id);

  const [inputFields, outputFields] = useMemo(() => {
    const { input, output } = transformSchemaToOptions(flow?.versions ?? []);
    return [
      input.map((field) => field.value),
      output.map((field) => field.value),
    ];
  }, [flow?.versions]);

  const { data: outcomeTypes } = useOutcomeTypes({
    flowId,
    enabled: isFeatureFlagEnabled(FEATURE_FLAGS.outcomes),
  });

  const outcomeFields = useMemo(() => {
    return transformOutcomeTypesToOptions(outcomeTypes ?? []).map(
      (field) => field.value,
    );
  }, [outcomeTypes]);

  const [
    decisionFieldsExclude,
    setDecisionFieldsExclude,
    removeDecisionFieldsExclude,
  ] = useLocalStorage<string[]>(
    buildStorageKey(flowId, "decisionFieldsExclude"),
    ui_default_decision_fields_exclude,
  );

  const [visibleInputFields, setVisibleInputFields, removeVisibleInputFields] =
    useLocalStorage<string[]>(
      buildStorageKey(flowId, "inputFields"),
      ui_default_inputs_include,
    );

  const [
    visibleOutputFields,
    setVisibleOutputFields,
    removeVisibleOutputFields,
  ] = useLocalStorage<string[]>(
    buildStorageKey(flowId, "outputFields"),
    ui_default_outputs_include,
  );

  const [
    visibleOutcomeFields,
    setVisibleOutcomeFields,
    removeVisibleOutcomeFields,
  ] = useLocalStorage<string[]>(
    buildStorageKey(flowId, "outcomeFields"),
    ui_default_outcomes_include,
  );

  const onReset = useCallback(() => {
    removeDecisionFieldsExclude();
    removeVisibleInputFields();
    removeVisibleOutputFields();
    removeVisibleOutcomeFields();
  }, [
    removeDecisionFieldsExclude,
    removeVisibleInputFields,
    removeVisibleOutputFields,
    removeVisibleOutcomeFields,
  ]);

  const onChange = useCallback(
    (value: string[]) => {
      if (value.length < minFields || value.length > maxFields) {
        return;
      }
      const [
        newDecisionFields,
        newInputFields,
        newOutputFields,
        newOutcomeFields,
      ] = partitionValue(value);

      setVisibleInputFields(newInputFields);
      setVisibleOutputFields(newOutputFields);
      setVisibleOutcomeFields(newOutcomeFields);
      setDecisionFieldsExclude(difference(decisionFields, newDecisionFields));
    },
    [
      setVisibleInputFields,
      setVisibleOutputFields,
      setVisibleOutcomeFields,
      setDecisionFieldsExclude,
    ],
  );

  const onSave = useCallback(async () => {
    try {
      await updateFlow({
        flowId: flowId,
        ui_default_decision_fields_exclude: decisionFieldsExclude,
        ui_default_inputs_include: visibleInputFields,
        ui_default_outputs_include: visibleOutputFields,
        ui_default_outcomes_include: visibleOutcomeFields,
      });
      toastActions.success({
        title: "Saved Successfully",
        description: `The new view for ${flowName} Decision Flow has been saved as the default for all team members.`,
      });
    } catch (e) {
      toastError({
        title: "Error",
        description: "Failed to save view options",
      });
      logger.error(e);
    }
  }, [
    decisionFieldsExclude,
    visibleInputFields,
    visibleOutputFields,
    visibleOutcomeFields,
    flowId,
    flowName,
    updateFlow,
  ]);

  const computedValue = useMemo(() => {
    return [
      ...difference(decisionFields, decisionFieldsExclude),
      ...(visibleInputFields ?? []),
      ...(visibleOutputFields ?? []),
      ...(visibleOutcomeFields ?? []),
    ];
  }, [
    decisionFieldsExclude,
    visibleInputFields,
    visibleOutputFields,
    visibleOutcomeFields,
  ]);

  const columnVisibility = useMemo(() => {
    const buildReducer =
      (list: string[], flip: boolean = false) =>
      (acc: Record<string, boolean>, value: string) => {
        return {
          ...acc,
          [value]: flip ? !list.includes(value) : list.includes(value),
        };
      };

    return {
      ...decisionFields.reduce(buildReducer(decisionFieldsExclude, true), {}),
      ...inputFields.reduce(buildReducer(visibleInputFields), {}),
      ...outputFields.reduce(buildReducer(visibleOutputFields), {}),
      ...outcomeFields.reduce(buildReducer(visibleOutcomeFields), {}),
    };
  }, [
    decisionFieldsExclude,
    inputFields,
    outputFields,
    outcomeFields,
    visibleInputFields,
    visibleOutputFields,
    visibleOutcomeFields,
  ]);

  const visibleColumns = useMemo(() => {
    return Object.keys(columnVisibility).filter((key) => columnVisibility[key]);
  }, [columnVisibility]);

  const fieldsMask = useMemo(() => {
    const inputMasks = visibleColumns
      .filter((col) => col.startsWith(inputFieldsPrefix))
      .map((col) => col.replace(inputFieldsPrefix, "input|"));
    const outputMasks = visibleColumns
      .filter((col) => col.startsWith(outputFieldsPrefix))
      .map((col) => col.replace(outputFieldsPrefix, "output|"));
    const list = [
      "end_time",
      "entity_id",
      "environment",
      "flow",
      "id",
      "origin",
      "start_time",
      "status",
      "status_code",
      inputMasks.length > 0 ? "input" : "",
      outputMasks.length > 0 ? "output" : "",
      ...inputMasks,
      ...outputMasks,
    ];
    return list.filter(Boolean).join(",");
  }, [visibleColumns]);

  return {
    value: computedValue,
    isSaving: isLoading,
    canSelect: (nItems: number) => computedValue.length + nItems <= maxFields,
    canDeselect: (nItems: number) => computedValue.length - nItems >= minFields,
    columnVisibility,
    fieldsMask,
    onReset,
    onSave,
    onChange,
  };
};
