import { useLocalStorage } from "usehooks-ts";

type GetLatestSeenVersionEtagArgs = {
  userId: string;
  versionId: string;
};

const getLatestSeenLocalStorageKey = ({
  userId,
  versionId,
}: GetLatestSeenVersionEtagArgs): string => {
  return ["latestSeenVEtag", userId, versionId].toString();
};

const getLatestReviewSeenLocalStorageKey = ({
  userId,
  versionId,
}: GetLatestSeenVersionEtagArgs): string => {
  return ["latestSeenREtag", userId, versionId].toString();
};

/**
 * Provides access to the latest seen version etag stored in local storage.
 * @returns The latest seen etag, or -1 if non has been stored.
 */
export const useLatestSeenVersionEtag = ({
  userId,
  versionId,
}: GetLatestSeenVersionEtagArgs) => {
  const [latestSeenEtag, saveLatestSeenEtag] = useLocalStorage<string>(
    getLatestSeenLocalStorageKey({ userId, versionId }),
    ".",
  );

  return [latestSeenEtag, saveLatestSeenEtag] as const;
};

/**
 * Provides access to the latest seen version etag stored in local storage.
 * @returns The latest seen etag, or -1 if non has been stored.
 */
export const useLatestSeenReviewEtag = ({
  userId,
  versionId,
}: GetLatestSeenVersionEtagArgs) => {
  const [latestSeenEtag, saveLatestSeenEtag] = useLocalStorage<string>(
    getLatestReviewSeenLocalStorageKey({ userId, versionId }),
    ".",
  );

  return [latestSeenEtag, saveLatestSeenEtag] as const;
};
