import React from "react";

import { TextConfirmationModal } from "src/base-components/TextConfirmationModal";
import { toastActions } from "src/design-system/Toast/utils";
import { useClearOutcomeReports } from "src/outcomes/queries";
import { OutcomeType } from "src/outcomes/types";
import { logger } from "src/utils/logger";

type ClearOutcomeReportsModalProps = {
  afterLeave: () => void;
  isOpen: boolean;
  onClose: () => void;
  outcomeType: OutcomeType | undefined;
  onClearingOutcomeReport: (key: string) => void;
};

const MIN_REPORTS_COUNT_TO_SHOW_TOAST = 500;

export const ClearOutcomeReportsModal: React.FC<
  ClearOutcomeReportsModalProps
> = ({ afterLeave, isOpen, onClose, outcomeType, onClearingOutcomeReport }) => {
  const { mutateAsync: clearOutcomeReports } = useClearOutcomeReports();

  const handleDelete = async () => {
    if (!outcomeType) {
      return;
    }
    try {
      onClearingOutcomeReport(outcomeType.key);
      await clearOutcomeReports({
        id: outcomeType.id,
        flow_id: outcomeType.flow_id,
      });

      if (
        outcomeType.count &&
        outcomeType.count > MIN_REPORTS_COUNT_TO_SHOW_TOAST
      ) {
        toastActions.success({
          title: "Outcome reports deletion has started",
          description: `${outcomeType.name} reports deletion has been started. It might take a while to complete.`,
        });
      }

      onClose();
    } catch (e) {
      toastActions.failure({
        title: "Failed to delete Outcome reports",
        description: "Please try again.",
      });
      logger.error(e);
    }
  };

  return (
    <TextConfirmationModal
      afterLeave={afterLeave}
      challengeText={outcomeType?.name}
      confirmButtonText="Clear all reported Outcomes"
      description="Are you sure you want to clear all reported Outcomes? This action cannot be undone."
      label="Please enter the Outcome name to confirm"
      open={isOpen}
      title="Are you sure?"
      onClose={onClose}
      onConfirm={handleDelete}
    />
  );
};
