import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FieldError, useFormContext } from "react-hook-form";

import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Icon } from "src/base-components/Icon";
import { Textarea } from "src/base-components/Textarea";
import { Tooltip } from "src/design-system/Tooltip";

type SecretFieldPropsT = {
  fieldError?: FieldError;
  displayName: string;
  placeholder: string;
  name: string;
  helpTitle: string;
  isRequired?: boolean;
};

// TODO(INT-3612): Reuse this component in other forms that ask for certificates.
export const SSHSecret: React.FC<SecretFieldPropsT> = ({
  fieldError,
  displayName,
  placeholder,
  name,
  helpTitle,
  isRequired,
}) => {
  const { register, setValue, watch } = useFormContext();
  const secretRecord = watch(name);
  const enableSecret = () => {
    setValue(name, { ...secretRecord, secret: false, value: null });
  };
  return (
    <FormItem
      description={helpTitle}
      gap="xxs"
      isRequired={isRequired}
      label={displayName}
    >
      {Boolean(fieldError) && <ErrorHint>{fieldError?.message}</ErrorHint>}
      <div className="flex w-full items-start gap-x-2">
        <div className="flex-1">
          <Textarea
            disabled={secretRecord?.secret}
            errored={Boolean(fieldError)}
            {...register(`${name}.value`, {
              required: isRequired ? `${displayName} is required` : false,
            })}
            placeholder={placeholder}
            fullWidth
          />
        </div>
        {secretRecord?.secret && (
          <Tooltip
            placement="right"
            title={`Edit ${displayName.toLowerCase()}`}
            asChild
          >
            <Icon
              color="text-gray-500"
              icon={faEdit}
              size="xs"
              onClick={enableSecret}
            />
          </Tooltip>
        )}
      </div>
    </FormItem>
  );
};
