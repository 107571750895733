import {
  faCalendar,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { endOfToday, format, subMonths } from "date-fns";
import {
  DayPicker,
  DateRange,
  SelectRangeEventHandler,
} from "react-day-picker";
import { twJoin, twMerge } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { CustomPopover as Popover } from "src/base-components/Popover";

export type { DateRange } from "react-day-picker";

const NUMBER_OF_MONTHS = 2;

export type DateRangePickerResetable =
  | {
      resetable?: false;
      onReset?: never;
    }
  | {
      resetable: true;
      onReset: () => void;
    };

export type DateRangePickerProps = {
  value: DateRange | undefined;
  onChange: SelectRangeEventHandler;
  placeholder?: string;
  rangeLimitInDays?: number;
  borderless?: boolean;
  suffixIcon?: boolean;
  disabled?: boolean;
  buttonRenderer?: (props: {
    value: DateRange | undefined;
    open: boolean;
  }) => React.ReactElement;
  footerRenderer?: () => React.ReactElement;
} & DateRangePickerResetable;

export const DateRangePicker = ({
  value,
  onChange,
  borderless = false,
  disabled = false,
  suffixIcon = false,
  placeholder = "Pick a date range",
  resetable = false,
  onReset,
  rangeLimitInDays,
  buttonRenderer,
  footerRenderer,
}: DateRangePickerProps) => {
  return (
    <div className={twJoin("w-full", disabled && "pointer-events-none")}>
      <Popover
        button={
          buttonRenderer ? (
            (props: { open: boolean }) => buttonRenderer({ value, ...props })
          ) : (
            <div
              className={twJoin(
                "flex h-8 w-full items-center gap-x-1 whitespace-nowrap rounded-lg px-2 py-1 text-left text-xs text-gray-800 focus:border-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500/25",
                !borderless && "border border-gray-200",
              )}
              tabIndex={disabled ? -1 : 0}
            >
              <Icon
                color={disabled ? "text-gray-400" : "text-gray-500"}
                icon={faCalendar}
                size="xs"
              />
              <div className={twJoin(disabled && "text-gray-400")}>
                {value?.from ? (
                  value.to ? (
                    <>
                      {format(value.from, "LLL dd, y")} -{" "}
                      {format(value.to, "LLL dd, y")}
                    </>
                  ) : (
                    format(value.from, "LLL dd, y")
                  )
                ) : (
                  <span>{placeholder}</span>
                )}
              </div>
              {(suffixIcon || resetable) && (
                <div className="ml-auto flex items-center">
                  {!disabled && resetable && value && (
                    <Icon
                      color="text-gray-500"
                      icon={faXmark}
                      size="xs"
                      onClick={(e) => {
                        e.stopPropagation();
                        onReset?.();
                      }}
                    />
                  )}
                  {suffixIcon && (
                    <Icon
                      color="text-gray-500"
                      icon={faChevronDown}
                      size="xs"
                    />
                  )}
                </div>
              )}
            </div>
          )
        }
        dataLoc="date-range-picker"
        placement="bottom"
      >
        <Calendar
          defaultMonth={subMonths(
            value?.to || new Date(),
            NUMBER_OF_MONTHS - 1,
          )}
          disabled={{ after: endOfToday() }}
          max={rangeLimitInDays}
          mode="range"
          numberOfMonths={NUMBER_OF_MONTHS}
          selected={value}
          initialFocus
          onSelect={onChange}
        />
        {footerRenderer && footerRenderer()}
      </Popover>
    </div>
  );
};

type CalendarProps = React.ComponentProps<typeof DayPicker>;
const Calendar = ({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) => {
  return (
    <DayPicker
      className={twMerge("p-3", className)}
      classNames={{
        months: "flex space-x-4 space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "font-inter-medium-13px text-gray-700 select-none",
        nav: "space-x-1 flex items-center",
        nav_button: "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell:
          "rounded-md w-9 font-inter-semibold-13px text-gray-700 select-none",
        row: "flex w-full mt-2 rounded-md overflow-hidden",
        cell: "text-center font-inter-normal-13px p-0 relative focus-within:relative focus-within:z-20",
        day: "[&:hover:not([aria-selected])]:enabled:bg-indigo-50 [&:hover:not([aria-selected])]:enabled:text-indigo-500 [&:hover:not([aria-selected])]:enabled:rounded-md h-9 w-9 p-0 enabled:[&:not([aria-selected])]:text-gray-700 aria-selected:opacity-100",
        day_selected:
          "bg-indigo-500 text-white hover:bg-indigo-500 hover:text-white focus:bg-indigo-500 focus:text-white",
        day_range_start: "rounded-l-md",
        day_range_end: "rounded-r-md",
        day_outside: "enabled:[&:not([aria-selected])]:!text-gray-400",
        day_range_middle: "!bg-indigo-50 !text-indigo-500",
        day_disabled: "text-gray-300",
        ...classNames,
      }}
      components={{
        IconLeft: () => <Icon icon={faChevronLeft} size="xs" />,
        IconRight: () => <Icon icon={faChevronRight} size="xs" />,
      }}
      showOutsideDays={showOutsideDays}
      {...props}
    />
  );
};
