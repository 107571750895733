import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { useMemo } from "react";

import { Icon } from "src/base-components/Icon";
import { assertUnreachable } from "src/utils/typeUtils";

const loadingTexts = [
  "Analyzing your request...",
  "Crafting a response...",
  "Thinking it through...",
  "Processing data...",
  "Generating insights...",
  "Preparing assistance...",
  "Computing solution...",
  "Decoding your query...",
  "Synthesizing answer...",
  "Formulating response...",
] as const;

const retryingTexts = [
  "Retrying...",
  "Giving it another shot...",
  "Attempting again...",
  "Second try in progress...",
  "Reattempting solution...",
  "Taking another crack at it...",
  "Recalculating response...",
  "Round two, here we go...",
  "Regrouping and retrying...",
  "One more attempt...",
  "Refining and retrying...",
  "Another go at it...",
  "Reconfiguring and retrying...",
  "Back to the drawing board...",
  "Persistence mode: engaged...",
] as const;

const regeneratingText = "Regenerating response...";

export const LoadingMessageItem: React.FC<{
  mode: "loading" | "retrying" | "regenerating";
}> = ({ mode }) => {
  const loadingIndex = useMemo(
    () => Math.floor(Math.random() * loadingTexts.length),
    [],
  );

  const retryingIndex = useMemo(
    () => Math.floor(Math.random() * retryingTexts.length),
    [],
  );

  const text = (() => {
    switch (mode) {
      case "loading":
        return loadingTexts[loadingIndex];
      case "retrying":
        return retryingTexts[retryingIndex];
      case "regenerating":
        return regeneratingText;
      default:
        assertUnreachable(mode);
    }
  })();

  return (
    <li className="flex flex-col gap-y-2">
      <p className="text-gray-500 font-inter-normal-12px">Copilot</p>
      <div className="flex">
        <Icon color="text-gray-500" icon={faSpinner} size="xs" spin />
        <span className="text-gray-800 font-inter-normal-13px">{text}</span>
      </div>
    </li>
  );
};
