import { faRightFromBracket } from "@fortawesome/pro-solid-svg-icons";
import React from "react";

import { Card } from "src/base-components/Card";
import { OrgLogo } from "src/base-components/OrgLogo";
import { Avatar } from "src/design-system/Avatar";
import { OverviewHeader } from "src/flowsOverview/OverviewHeader";
import { DashboardContent } from "src/layout/DashboardContent";
import { PickWorkspace } from "src/router/PickWorkspace";
import { getSignUpUrlWithNext } from "src/router/urls";
import { useAuthActions, useUserData } from "src/store/AuthStore";
import { useCurrentWorkspace } from "src/utils/useCurrentWorkspace";

export const WelcomePage: React.FC = () => {
  const { signed_in_full_name, signed_in_username, signed_in_avatar_url } =
    useUserData();
  const { resetAll } = useAuthActions();
  const { workspaces, org } = useCurrentWorkspace();

  const handleLogout = () => {
    resetAll();
    window.location.assign(getSignUpUrlWithNext());
  };

  const title = (
    <div className="flex items-center gap-x-2 p-2">
      {org && <OrgLogo org={org} size="medium" />}
      <span>{org?.name}</span>
    </div>
  );

  if (workspaces?.[0]) {
    return <PickWorkspace />;
  }

  return (
    <DashboardContent
      Header={
        <OverviewHeader
          buttonProps={{
            dataLoc: "user-logout",
            title: "Logout",
            variant: "secondary",
            icon: faRightFromBracket,
            onClick: handleLogout,
          }}
          title={title}
        />
      }
    >
      <div className="w-100 p-16">
        <Card
          className="w-100 mx-auto max-w-194 space-y-3 px-4 !pb-10 !pt-10"
          variant="inverted"
        >
          <Card.Content className="px-32 text-center">
            <Avatar
              size="6xl"
              user={{
                username: signed_in_username,
                full_name: signed_in_full_name,
                avatar_url: signed_in_avatar_url,
              }}
            />
            <h3 className="text-gray-800 font-inter-semibold-16px">
              Welcome to Taktile, {signed_in_full_name}
            </h3>
            <p className="mx-auto text-gray-600 font-inter-normal-12px">
              In order to start building and explore the flows created within
              your organization, please ask an administrator to assign you the
              correct permissions and then refresh the page.
            </p>
          </Card.Content>
        </Card>
      </div>
    </DashboardContent>
  );
};
