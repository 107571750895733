/**
 * Extracts the JSON content from a connect error message and formats it.
 * @param msg The connect error message. Expected format: "... content={...}" or "... Details: {...}".
 * @returns The connect error message with the JSON content formatted. If the JSON content cannot be
 * parsed, the original message is returned.
 */
export const formatConnectErrorMessage = (msg: string) => {
  // - (content=|Details: ) - Match either "content=" or "Details: ".
  // - (b?['"]?) - Match an optional "b" followed by an optional single or double quote.
  // - ({[\s\S]+}) - Match a JSON object.
  // - (['"]?)$ - Match an optional single or double quote at the end of the string.
  const regex = /(content=|Details: )(b?['"]?)({[\s\S]+})(['"]?)$/;

  const match = msg.match(regex);
  if (!match) {
    return msg;
  }

  // There are at least 5 match groups: the full match and the 4 groups in the regex.
  if (match.length < 5) {
    return msg;
  }

  const jsonContent = match[3];
  try {
    const jsonObject = JSON.parse(jsonContent);
    const formattedJson = JSON.stringify(jsonObject, null, 2);
    // Replace the original content (full matched string - match[0]) with the formatted JSON
    // preceded by the original content prefix (match[1]).
    return msg.replace(match[0], `${match[1]}${formattedJson}`);
  } catch (error) {
    return msg;
  }
};
