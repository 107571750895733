import { faFile, faTag } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import * as uuid from "uuid";

import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { Select } from "src/base-components/Select";
import { useDatasets, usePostRows } from "src/datasets/api/queries";
import { Modal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";

type Props = {
  decisionId: string | null;
  onClose: () => void;
};

export const TestCaseModal: React.FC<Props> = ({ decisionId, onClose }) => {
  const { flow, workspace } = useAuthoringContext();

  const datasetsQuery = useDatasets({
    flowId: flow.id,
    baseUrl: workspace.base_url,
  });

  const testRunDatasets = datasetsQuery.data
    ? datasetsQuery.data.filter((d) => !d.purpose || d.purpose === "test_run")
    : undefined;

  const [selectedDatasetId, setSelectedDatasetId] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (!selectedDatasetId && testRunDatasets?.at(0)) {
      setSelectedDatasetId(testRunDatasets[0].id);
    }
  }, [testRunDatasets, selectedDatasetId]);

  const postRows = usePostRows(
    selectedDatasetId ?? "",
    flow.id,
    workspace.base_url,
  );

  const handleSubmit = async () => {
    if (!decisionId) return;

    try {
      await postRows.mutateAsync({
        source: "decision_id",
        decision_id: decisionId,
        new_row_ids: [uuid.v4()],
      });
      toastActions.success({ title: "Test case successfully added" });

      onClose();
    } catch (e) {
      toastActions.failure({
        title: "Failed to add test case",
        description: TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(e);
    }
  };

  return (
    <Modal open={!!decisionId} onClose={onClose}>
      <Modal.Header description="Add this test case to an existing test dataset">
        Select a test dataset
      </Modal.Header>
      <Modal.Content>
        {testRunDatasets && selectedDatasetId ? (
          <>
            <Select
              options={testRunDatasets.map((dataset) => ({
                key: dataset.id,
                value: (
                  <div className="flex items-center gap-x-2">
                    <Icon
                      color="text-indigo-500"
                      icon={dataset.source === "file" ? faFile : faTag}
                      padding={false}
                      size="xs"
                    />
                    {dataset.name}
                  </div>
                ),
              }))}
              value={selectedDatasetId}
              onChange={setSelectedDatasetId}
            />
          </>
        ) : (
          <p className="text-gray-500 font-inter-normal-13px">
            There are no datasets available for this flow
          </p>
        )}
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="save"
            disabled={!selectedDatasetId}
            loading={postRows.isLoading}
            variant="primary"
            onClick={handleSubmit}
          >
            Add to dataset
          </Button>
        }
      />
    </Modal>
  );
};
