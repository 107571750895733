import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";

import { OutcomeTypeStatusEnum } from "src/clients/flow-api";
import { useModal } from "src/design-system/Modal";
import { SubHeader } from "src/flow/SubHeader";
import { useCapabilities } from "src/hooks/useCapabilities";
import { WhitePane } from "src/jobs/common/WhitePane";
import { ClearOutcomeReportsModal } from "src/outcomes/ClearOutcomeReportsModal";
import { DeleteOutcomeTypeModal } from "src/outcomes/DeleteOutcomeTypeModal";
import { ManageOutcomeTypeModal } from "src/outcomes/ManageOutcomeTypeModal";
import { OutcomeTypesTable } from "src/outcomes/OutcomeTypesTable";
import { useOutcomeTypes } from "src/outcomes/queries";
import { OutcomeType } from "src/outcomes/types";
import { useFlowContext } from "src/router/routerContextHooks";

const MAX_OUTCOME_TYPES = 5;

export const OutcomesContent: React.FC = () => {
  const { flow } = useFlowContext();
  // Contains the keys of the outcome types that are being cleared
  const [clearingOutcomeReportsForTypes, setClearingOutcomeReportsForTypes] =
    useState<string[]>([]);

  // If any outcome type is being deleted or cleared, we should poll the outcome types
  const shouldPoll = (data: OutcomeType[]) => {
    const isAnyOutcomeBeingDeleted = data?.some(
      (outcome) => outcome.status === OutcomeTypeStatusEnum.DELETING,
    );

    const isAnyOutcomeBeingCleared =
      clearingOutcomeReportsForTypes.length !== 0;

    return isAnyOutcomeBeingDeleted || isAnyOutcomeBeingCleared;
  };

  const {
    data: outcomeTypes,
    isLoading,
    refetch,
  } = useOutcomeTypes({
    flowId: flow.id,
    shouldPoll,
  });
  const { outcomes } = useCapabilities();
  const {
    isOpen: isManageModalOpen,
    openModal: openManageModal,
    closeModal: closeManageModal,
    afterLeave: afterManageModalLeave,
    data: outcomeTypeToEdit,
  } = useModal<OutcomeType>();
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
    afterLeave: closeDeleteModalAfterLeave,
    data: outcomeTypeToDelete,
  } = useModal<OutcomeType>();
  const {
    isOpen: isClearModalOpen,
    openModal: openClearModal,
    closeModal: closeClearModal,
    afterLeave: closeClearModalAfterLeave,
    data: outcomeTypeToClear,
  } = useModal<OutcomeType>();

  const isMaxOutcomeTypesReached =
    (outcomeTypes ?? []).filter(
      (type) => type.status === OutcomeTypeStatusEnum.ACTIVE,
    ).length >= MAX_OUTCOME_TYPES;

  // Because there's no explicit "clearing" status we need to
  // watch the count of the types that are being cleared
  // so we can remove them from the clearingOutcomeReportsForTypes array
  // once the count goes back to 0
  const thereAreClearingTypes = clearingOutcomeReportsForTypes.length !== 0;
  useEffect(() => {
    if (thereAreClearingTypes) {
      const typesWithNoReports = (outcomeTypes ?? [])
        .filter((type) => {
          return type.count === 0;
        })
        .map((type) => type.key);

      setClearingOutcomeReportsForTypes((prev) =>
        prev.filter((type) => !typesWithNoReports.includes(type)),
      );
    }
  }, [thereAreClearingTypes, outcomeTypes, refetch]);

  return (
    <>
      <SubHeader title="Outcomes" paddedParent>
        {outcomes.canCreate && (
          <SubHeader.Button
            dataLoc="create-outcome-button"
            disabled={isMaxOutcomeTypesReached}
            icon={faPlus}
            tooltip={
              isMaxOutcomeTypesReached
                ? "You can create up to 5 Outcomes for this Flow"
                : "Create Outcome"
            }
            onClick={() => openManageModal()}
          />
        )}
      </SubHeader>
      <div
        className="max-w m-auto flex w-full max-w-[1800px] flex-col gap-y-5.5"
        data-loc="outcomes-content"
      >
        <WhitePane>
          <OutcomeTypesTable
            clearingOutcomeReportsForTypes={clearingOutcomeReportsForTypes}
            data={outcomeTypes}
            isLoading={isLoading}
            onOutcomeReportsClear={openClearModal}
            onOutcomeTypeDelete={openDeleteModal}
            onOutcomeTypeEdit={openManageModal}
          />
        </WhitePane>
      </div>
      <ManageOutcomeTypeModal
        afterLeave={afterManageModalLeave}
        isOpen={isManageModalOpen}
        outcomeType={outcomeTypeToEdit}
        onClose={closeManageModal}
      />
      <DeleteOutcomeTypeModal
        afterLeave={closeDeleteModalAfterLeave}
        isOpen={isDeleteModalOpen}
        outcomeType={outcomeTypeToDelete}
        onClose={closeDeleteModal}
      />
      <ClearOutcomeReportsModal
        afterLeave={closeClearModalAfterLeave}
        isOpen={isClearModalOpen}
        outcomeType={outcomeTypeToClear}
        onClearingOutcomeReport={(key) =>
          setClearingOutcomeReportsForTypes((prev) => [...prev, key])
        }
        onClose={closeClearModal}
      />
    </>
  );
};
