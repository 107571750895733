import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeftLong,
  faCog,
  faFileMagnifyingGlass,
  faKey,
  faPlug,
  faUserCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { ApiKeyOverview } from "src/apiKeys/ApiKeysOverview";
import { AuditLogsPage } from "src/auditLogs/AuditLogsPage";
import { Icon } from "src/base-components/Icon";
import { ConnectionOverview } from "src/connections/ConnectionOverview";
import { useCapabilities } from "src/hooks/useCapabilities";
import { WorkspaceFormModal } from "src/layout/WorkspaceFormModal/WorkspaceFormModal";
import {
  DashboardPageParamsT,
  getUrlToSettingsPage,
  getUrlToWsDashboard,
} from "src/router/urls";
import { UsersPermissionsPage } from "src/userManagement/UsersPermissionsPage";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const HeaderBackLink: React.FC<{ to: string; dataLoc?: string }> = ({
  to,
  dataLoc,
}) => (
  <NavLink data-loc={dataLoc} to={to}>
    <Icon
      color="text-gray-500 hover:text-gray-700"
      dataLoc="back-icon"
      icon={faArrowLeftLong}
      size="xs"
    />
  </NavLink>
);

const SettingsHeader: React.FC = () => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  return (
    <div className="w-full border-b border-gray-200 bg-white shadow-sm">
      <div className="flex h-12 items-center pl-5 text-gray-800 font-inter-semibold-14px">
        <div className="ml-0.5 mr-3">
          <HeaderBackLink
            dataLoc="back-to-versions"
            to={getUrlToWsDashboard({ orgId, wsId })}
          />
        </div>
        Settings
      </div>
    </div>
  );
};

const NavItem: React.FC<{
  selected: boolean;
  page: SettingsSubpages;
  icon: IconProp;
  title: string;
}> = ({ selected, page, icon, title }) => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  return (
    <Link
      className={twJoin(linkClassName, selected && "bg-gray-50")}
      to={getUrlToSettingsPage(orgId, wsId, page, {
        keepFlags: true,
      })}
    >
      <Icon color="text-gray-500" icon={icon} size="xs" />
      <p className="text-gray-800 font-inter-medium-13px">{title}</p>
    </Link>
  );
};

export enum SettingsSubpages {
  Connections = "connections",
  ApiKeys = "api-keys",
  Users = "users",
  AuditLogs = "audit-logs",
}

const linkClassName =
  "flex flex-row items-center gap-x-2 rounded-lg px-2 py-1.5 hover:bg-gray-100";

export const SettingsPage: React.FC<{ subpage: SettingsSubpages }> = ({
  subpage: currrentSubpage,
}) => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const capabilities = useCapabilities();

  const renderPage = () => {
    switch (currrentSubpage) {
      case SettingsSubpages.Connections:
        return <ConnectionOverview workspaceId={wsId} />;
      case SettingsSubpages.ApiKeys:
        return <ApiKeyOverview organizationId={orgId} workspaceId={wsId} />;
      case SettingsSubpages.Users:
        return <UsersPermissionsPage />;
      case SettingsSubpages.AuditLogs:
        return <AuditLogsPage />;
    }
  };

  return (
    <div className="flex h-screen w-screen flex-col">
      <SettingsHeader />
      <div className="flex min-h-0 flex-grow flex-row">
        <div className="flex h-full w-52 shrink-0 flex-col gap-y-2 bg-white px-2.5 py-4 shadow-base">
          {capabilities.connections.canAccess && (
            <NavItem
              icon={faPlug}
              page={SettingsSubpages.Connections}
              selected={SettingsSubpages.Connections === currrentSubpage}
              title="Connections"
            />
          )}
          {capabilities.apiKeys.canAccess && (
            <NavItem
              icon={faKey}
              page={SettingsSubpages.ApiKeys}
              selected={SettingsSubpages.ApiKeys === currrentSubpage}
              title="API keys"
            />
          )}
          {capabilities.workspaces.canEdit && (
            <button
              className={linkClassName}
              type="button"
              onClick={() => setIsModalOpen(true)}
            >
              <Icon color="text-gray-500" icon={faCog} size="xs" />
              <span className="text-gray-800 font-inter-medium-13px">
                Workspace settings
              </span>
            </button>
          )}
          {capabilities.usersPermissions.canAccess && (
            <NavItem
              icon={faUserCircle}
              page={SettingsSubpages.Users}
              selected={SettingsSubpages.Users === currrentSubpage}
              title="User permissions"
            />
          )}
          {capabilities.auditLogs.canAccess && (
            <NavItem
              icon={faFileMagnifyingGlass}
              page={SettingsSubpages.AuditLogs}
              selected={SettingsSubpages.AuditLogs === currrentSubpage}
              title="Audit log"
            />
          )}
        </div>
        <div className="decideScrollbar h-full flex-grow overflow-auto">
          {renderPage()}
        </div>
      </div>
      <WorkspaceFormModal
        isOpen={isModalOpen}
        orgId={orgId}
        variant="Edit"
        wsId={wsId}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};
