import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  InputMappingGroupT,
  InputMappingT,
  IntegrationResourceT,
} from "src/integrationNode/types";

const getDefaultPersonFields = (): { [key: string]: InputMappingT } => ({
  first_name: {
    id: uuidV4(),
    type: "text",
    displayName: "First name",
    assignedTo: "",
    hint: `Up to 15 characters`,
    rules: {
      required: true,
    },
  },
  middle_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Middle name",
    assignedTo: "",
    hint: `Up to 15 characters`,
  },
  last_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Last name",
    assignedTo: "",
    hint: `Up to 25 characters`,
    rules: {
      required: true,
    },
  },
  social_insurance_number: {
    id: uuidV4(),
    type: "text",
    displayName: "SIN",
    assignedTo: "",
    hint: `9-digit Social Insurance Number`,
  },
  date_of_birth: {
    id: uuidV4(),
    type: "text",
    displayName: "Date of birth",
    assignedTo: "",
    hint: `Date of birth, formatted according to ISO: 'YYYY-MM-DD'`,
  },
  account_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Account number",
    assignedTo: "",
    hint: `Account number reference`,
  },
});

const getDefaultPerson = (): InputMappingGroupT => ({
  displayName: "Person",
  getDefaultElements: getDefaultPersonFields,
  elements: getDefaultPersonFields(),
  rules: {
    required: true,
  },
});

const getDefaultEmploymentFields = (): { [key: string]: InputMappingT } => ({
  employer: {
    id: uuidV4(),
    type: "text",
    displayName: "Employer",
    assignedTo: "",
    hint: `The consumer's employer name. Up to 34 characters.`,
  },
  occupation: {
    id: uuidV4(),
    type: "text",
    displayName: "Occupation",
    assignedTo: "",
    hint: `The consumer's occupation. Up to 25 characters.`,
  },
});

const getDefaultEmployment = (): InputMappingGroupT => ({
  displayName: "Employment",
  getDefaultElements: getDefaultEmploymentFields,
});

const getDefaultCurrentAddressFields = (): {
  [key: string]: InputMappingT;
} => ({
  civic_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Civic number",
    assignedTo: "",
    hint: `Official number assigned to an address by the municipality. Civic Number Suffixes (including single alpha and fractional) are also placed in this field. Up to 10 characters.`,
  },
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street name",
    assignedTo: "",
    hint: `Official name of the street recognized by each municipality. The street name can contain the actual name of the street along with the type, direction and unit designator (Ex: apt, unit, suite) and number for urban addresses. Up to 25 characters.`,
    rules: {
      required: true,
    },
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    hint: `Official municipality name, abbreviation or alternate name as provided by the municipality. City names are not translated. Up to 20 characters.`,
    rules: {
      required: true,
    },
  },
  province: {
    id: uuidV4(),
    type: "text",
    displayName: "Province",
    assignedTo: "",
    hint: `2-character code of the province or territory where the address is located`,
    rules: {
      required: true,
    },
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    hint: `6-character code postal code of the address in the format of "A1A1A1"`,
  },
});

const getDefaultCurrentAddress = (): InputMappingGroupT => ({
  displayName: "Current address",
  getDefaultElements: getDefaultCurrentAddressFields,
  elements: getDefaultCurrentAddressFields(),
  rules: {
    required: true,
  },
});

const getDefaultPreviousAddress = (): InputMappingGroupT => ({
  displayName: "Previous address",
  getDefaultElements: getDefaultCurrentAddressFields,
});

export const getDefaultEquifaxCanadaConsumerCreditFile =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "equifax_canada",
      resource: "equifax_canada_consumer_credit_file",
    },
    groupedGroupsAndLists: true,
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        customer_reference: {
          id: uuidV4(),
          type: "text",
          displayName: "Customer reference",
          assignedTo: "",
          hint: `Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.`,
        },
      },
      grouped: {
        person: getDefaultPerson(),
        current_address: getDefaultCurrentAddress(),
        previous_address: getDefaultPreviousAddress(),
        employment: getDefaultEmployment(),
      },
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(
          CommonOutputMappingKey.PROVIDER_REQUEST_ID,
          CommonOutputMappingKey.PROVIDER_ENTITY_ID,
          CommonOutputMappingKey.RETRIEVAL_DATETIME,
        ),
        hit_code: {
          ...getNamedOutputMapping("Hit code"),
          hint: "A single digit code that indicates the result of the search. 1 = Hit, 2 = No hit, 3 = Manual File, 4 = Consumer Declaration, 5 = Referred File, 7 = Credit Lock.",
        },
        ers_2_score: {
          ...getNamedOutputMapping("ERS 2.0 score"),
          hint: "The ERS 2.0 score is a credit score that ranges from 300 to 900 and is used to assess the delinquency risk for the individual consumer. Consumers with high ERS 2.0 credit scores are less likely to have serious delinquencies than consumers with low scores.",
        },
        on_file_since_date: {
          ...getNamedOutputMapping("Credit file creation date"),
          hint: "The date the consumer's credit file was created",
        },
        number_of_tradelines: {
          ...getNamedOutputMapping("Number of tradelines"),
          hint: "The number of tradelines on the consumer's credit file",
        },
        age_of_the_oldest_trade_in_months: {
          ...getNamedOutputMapping("Age of the oldest trade (months)"),
          hint: "The age of the oldest trade on the consumer's credit file in months",
        },
        latest_bankruptcy_date: {
          ...getNamedOutputMapping("Latest bankruptcy date"),
          hint: "The date of the latest bankruptcy on the consumer's credit file",
        },
      },
    },
    config: defaultResourceConfig,
  });
