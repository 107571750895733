import {
  faBolt,
  faClock,
  faCube,
  faDatabase,
} from "@fortawesome/pro-regular-svg-icons";
import { faArrowDownRight } from "@fortawesome/pro-solid-svg-icons";
import cronstrue from "cronstrue";

import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { FlowVersionStatus } from "src/clients/flow-api";
import { Modal } from "src/design-system/Modal";
import { getConfigurationString } from "src/jobs/JobContent";
import { useActivateJob } from "src/jobs/api/queries";
import { UNTITLED_DESTINATION } from "src/jobs/common/DestinationDropdown";
import { useUsedVersions } from "src/jobs/common/hooks";
import { getFutureMatches, isJobRunnable } from "src/jobs/jobUtils";
import { Job } from "src/jobs/types";
import { useFlowContext } from "src/router/routerContextHooks";
import { formatDate } from "src/utils/datetime";
import { logger } from "src/utils/logger";

type ActivateJobModalProps = {
  isOpen: boolean;
  onClose: () => void;
  job?: Job;
};

export const ActivateJobModal: React.FC<ActivateJobModalProps> = ({
  isOpen,
  onClose,
  job,
}) => {
  const { workspace, flow } = useFlowContext();
  const usedVersions = useUsedVersions(job);
  const jobActivateMutation = useActivateJob(workspace.base_url!);

  const activateJob = async () => {
    try {
      if (!job) return;
      await jobActivateMutation.mutateAsync(job);
      onClose();
    } catch (error) {
      logger.error(error);
    }
  };

  const notAllVersionsArePublished = usedVersions.some(
    (v) => v.status !== FlowVersionStatus.PUBLISHED,
  );

  if (
    usedVersions.length === 0 ||
    notAllVersionsArePublished ||
    !isJobRunnable(job)
  ) {
    return (
      <Modal open={isOpen} onClose={onClose}>
        <Modal.Header>Cannot activate Job</Modal.Header>
        <Modal.Content>
          {!isJobRunnable(job) ? (
            <div className="flex flex-col gap-y-2.5 text-gray-500 font-inter-normal-13px">
              <p>The Job is missing the following details.</p>
              <ul className="ml-4 list-disc text-gray-700 font-inter-medium-13px">
                {usedVersions.length === 0 && <li>Decision Flow</li>}
                {!job?.active_source && <li>Data Source</li>}
              </ul>
              <p>Please add the details to the Job before activating it.</p>
            </div>
          ) : (
            <div
              className="flex flex-col gap-y-2.5 text-gray-500 font-inter-normal-13px"
              data-loc="activate-job-modal-invalid-version"
            >
              <p>
                An active Job cannot be run with{" "}
                {usedVersions.some((v) => v.status === FlowVersionStatus.DRAFT)
                  ? "a draft"
                  : usedVersions.some(
                        (v) => v.status === FlowVersionStatus.ARCHIVED,
                      )
                    ? "an archived"
                    : ""}{" "}
                Decision Flow.
              </p>
              <p>
                This Job is configured to run{" "}
                <b className="text-gray-800 font-inter-medium-13px">
                  {flow.name}
                </b>{" "}
                Flow with versions{" "}
                <b className="text-gray-800 font-inter-medium-13px">
                  {new Intl.ListFormat("en", {
                    style: "long",
                    type: "conjunction",
                  }).format(usedVersions.map((v) => v.name))}
                </b>
                .
              </p>
              <p>
                Please publish all the Decision Flow versions before activating
                this job.
              </p>
            </div>
          )}
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="activate-job"
              htmlType="submit"
              variant="primary"
              disabled
            >
              Activate Job
            </Button>
          }
        />
      </Modal>
    );
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>Are you sure?</Modal.Header>
      <Modal.Content>
        <div className="flex flex-col gap-y-2.5 text-gray-500 font-inter-normal-13px">
          <p>
            Are you sure you want to make this Job active? This configuration
            will be used for the Job.
          </p>{" "}
          <div className="flex flex-col gap-y-2.5">
            <div className="text-gray-00 flex items-center gap-x-0.5 text-gray-800">
              <Icon color="text-gray-500" icon={faDatabase} size="2xs" />{" "}
              <p className="max-w-40 truncate">{job?.active_source?.name}</p>
              <Pill size="sm" variant="gray">
                <Pill.Text>Live Connection</Pill.Text>
              </Pill>
            </div>
            {job?.active_destination && (
              <div className="text-gray-00 flex items-center gap-x-0.5 text-gray-800">
                <Icon
                  color="text-gray-500"
                  icon={faArrowDownRight}
                  size="2xs"
                />{" "}
                <p className="max-w-40 truncate">
                  {job.active_destination.name || UNTITLED_DESTINATION}
                </p>
                <Pill size="sm" variant="gray">
                  <Pill.Text>Live Connection</Pill.Text>
                </Pill>
              </div>
            )}
            <div className="flex items-center gap-x-1 text-gray-800">
              <Icon color="text-gray-500" icon={faCube} size="2xs" />{" "}
              {flow.name}
              <Pill size="sm" variant="gray">
                {usedVersions.length === 1 ? (
                  <Pill.Text>{usedVersions[0].name}</Pill.Text>
                ) : (
                  <Pill.Text>Multiple versions</Pill.Text>
                )}
              </Pill>
            </div>
            <div className="flex items-center gap-x-0.5 text-gray-800">
              <Icon color="text-gray-500" icon={faClock} size="2xs" />
              {job?.schedule
                ? cronstrue.toString(job?.schedule, { verbose: true })
                : "No schedule set"}
            </div>
            <div className="flex items-center gap-x-0.5 text-gray-800">
              <Icon color="text-gray-500" icon={faBolt} size="2xs" />
              {getConfigurationString(job?.configuration)}
            </div>
          </div>
          <p>
            You cannot make any changes to the Job when it is in an active
            state. You can always deactivate it later.
          </p>
          {job?.schedule && job.schedule_timezone && (
            <p>
              This Job is scheduled to run at{" "}
              <span className="text-gray-800">
                {formatDate(
                  getFutureMatches(job.schedule, job.schedule_timezone),
                  "iiii, d MMMM h:mmaaa",
                )}
              </span>
            </p>
          )}
          Please confirm if you wish to proceed.
        </div>
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="activate-job"
            htmlType="submit"
            loading={jobActivateMutation.isLoading}
            variant="primary"
            onClick={activateJob}
          >
            Activate Job
          </Button>
        }
      />
    </Modal>
  );
};
