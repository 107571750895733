import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { twJoin } from "tailwind-merge";

import { FlowVersionFlowChild } from "src/api/flowTypes";
import { Icon } from "src/base-components/Icon";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import { MENU_DIVIDER } from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { Skeleton } from "src/base-components/Skeleton";
import { Tooltip } from "src/design-system/Tooltip";
import { DescriptionRow } from "src/flow/DescriptionRow";
import {
  ProductionDefaultPill,
  RelatedFlowsPill,
  SandboxDefaultPill,
} from "src/flow/Pills";
import { VersionNameExpandable } from "src/flow/VersionName";
import { JobsPill } from "src/flow/VersionsTable/JobsPill";
import { LegacyVersionMetaUser } from "src/flow/VersionsTable/LegacyVersionMetaUser";
import { Cell, Row, Table } from "src/flow/VersionsTable/VersionsTable";
import { VersionsTableColumn } from "src/flow/VersionsTable/types";
import { ExcludesFalse } from "src/flow/types";
import { useCapabilities } from "src/hooks/useCapabilities";
import { useJobs } from "src/jobs/api/queries";
import { useFlowContext } from "src/router/routerContextHooks";
import { getUrlToAuthoringPage } from "src/router/urls";
import { formatDate } from "src/utils/datetime";

type PublishedVersionRowProps = {
  version: FlowVersionFlowChild;
  isDefaultSandbox: boolean;
  isDefault: boolean;
  onSetDefaultSandbox: () => void;
  onDuplicate: () => void;
  onDuplicateToFlow: () => void;
  onArchive: () => void;
  onManageTraffic: () => void;
  trafficAllocation: string;
  usingTrafficPolicy: boolean;
  showManageTrafficOption: boolean;
};

export const PublishedVersionRow: React.FC<PublishedVersionRowProps> = ({
  version,
  isDefaultSandbox,
  isDefault,
  onSetDefaultSandbox,
  onDuplicate,
  onDuplicateToFlow,
  onArchive,
  onManageTraffic,
  trafficAllocation,
  usingTrafficPolicy,
  showManageTrafficOption,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const { orgId, workspace, flow } = useFlowContext();

  const { flowVersions } = useCapabilities();

  const options = [
    flowVersions.canCreate && { key: "Duplicate", action: onDuplicate },
    flowVersions.canSetSandboxDefault &&
      !isDefaultSandbox && {
        key: "Set Sandbox Default",
        action: onSetDefaultSandbox,
      },
    showManageTrafficOption && {
      key: "Manage traffic",
      action: onManageTraffic,
    },
    flowVersions.canCreate && MENU_DIVIDER,
    flowVersions.canCreate && {
      key: "Duplicate to another Decision Flow",
      action: onDuplicateToFlow,
    },
    flowVersions.canArchive && MENU_DIVIDER,
    flowVersions.canArchive && { key: "Archive", action: onArchive },
  ].filter(Boolean as any as ExcludesFalse);

  const jobs = useJobs(workspace.base_url!, flow.id);

  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);
  const onClickExpand = () => setExpanded(!expanded);

  return (
    <>
      <Row
        className={twJoin("cursor-pointer", isHover && "!bg-gray-50")}
        to={getUrlToAuthoringPage(orgId, workspace.id, flow.id, version.id)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Cell data-loc="version-name" font="inter-medium-13px">
          <VersionNameExpandable
            expanded={expanded}
            version={version}
            onClickExpand={onClickExpand}
          />
        </Cell>
        <Cell>
          <LegacyVersionMetaUser
            status={version.status}
            versionMeta={version.meta}
          />
        </Cell>
        <Cell>
          <div className="whitespace-nowrap">
            {version.meta.published_at && formatDate(version.meta.published_at)}
          </div>
        </Cell>
        <Cell>{trafficAllocation}</Cell>
        <Cell>
          <JobsPill
            jobs={jobs.data?.filter(
              (job) => job.flow_version_id === version.id,
            )}
          />
        </Cell>
        <Cell>
          <div className="inline-flex items-center gap-2">
            {Boolean(version.child_flows?.length) && (
              <RelatedFlowsPill
                flows={version.child_flows!}
                includeVersions
                isParent
              />
            )}
            {Boolean(version.parent_flows?.length) && (
              <RelatedFlowsPill flows={version.parent_flows!} includeVersions />
            )}
          </div>
        </Cell>
        <Cell align="right">
          <div className="inline-flex items-center gap-2">
            {isDefaultSandbox && <SandboxDefaultPill />}
            {!usingTrafficPolicy && isDefault && <ProductionDefaultPill />}
          </div>
        </Cell>
        <Cell align="right">
          {options.length > 0 ? (
            <EllipsisOptionsDropdown
              buttonClassName="text-gray-300 w-5"
              buttonDataLoc={`${version.name}-options`}
              elements={options}
              ellipsisDirection="vertical"
              menuClassName="inline-block"
              timeoutDuration={200}
            />
          ) : (
            // To keep the width the same as in the draft table if no options are available
            <div className="w-8" />
          )}
        </Cell>
      </Row>
      {expanded && (
        <tr
          className={twJoin(
            "group border-t-transparent transition-colors duration-150",
            isHover && "bg-gray-50",
          )}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <DescriptionRow description={version.meta.release_note} />
        </tr>
      )}
    </>
  );
};

export const PublishedVersionRowSkeleton: React.FC = () => (
  <tr>
    <Cell data-loc="version-name" font="inter-medium-13px">
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell align="right">
      <Skeleton />
    </Cell>
    <Cell align="right">
      <Skeleton />
    </Cell>
  </tr>
);

type PublishedVersionsTableProps = {
  children: React.ReactNode;
  animate: boolean;
};

export const PublishedVersionsTable: React.FC<PublishedVersionsTableProps> = ({
  children,
  animate,
}) => {
  const PUBLISHED_TABLE_COLUMNS: VersionsTableColumn[] = [
    { id: "version", name: "Published", width: "w-1/5" },
    { id: "published_by", name: "Published by", width: "w-1/6" },
    { id: "published_on", name: "Published on", width: "w-1/6" },
    {
      id: "traffic_allocation",
      name: (
        <div className="flex items-center">
          Traffic allocation
          <Tooltip
            body="Published versions without an allocated traffic percentage may still receive traffic in case it is specified in the API request"
            placement="top"
          >
            <Icon
              color="text-gray-500 hover:text-gray-700"
              icon={faCircleInfo}
              size="xs"
            />
          </Tooltip>
        </div>
      ),
      width: "w-1/6",
    },
    {
      id: "jobs",
      name: "Jobs",
      width: "w-1/8",
    },
    { id: "decision_flows", name: "Decision Flows", width: "w-1/8" },
    { id: "pills", name: "" },
    { id: "options", name: "", width: "w-16" },
  ].filter(Boolean as any as ExcludesFalse);

  return (
    <Table
      animate={animate}
      columns={PUBLISHED_TABLE_COLUMNS}
      dataLoc="published-versions-table"
    >
      {children}
    </Table>
  );
};
