import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faFlagAlt,
  faCube,
  faUserCircle,
  faKey,
  faPlug,
  faFolder,
  faSparkles,
} from "@fortawesome/pro-regular-svg-icons";
import { capitalize } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { useDebounceCallback, useEventListener } from "usehooks-ts";

import { getHistoryDecision } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { ExporterMetricsEndpoint } from "src/api/endpoints";
import { useFlows, useUserOrganizations } from "src/api/queries";
import { Icon } from "src/base-components/Icon";
import { TAKTILE_ORG_ID } from "src/constants/OrgIds";
import { invalidEntityIdsPattern } from "src/decisionsOverview/utils";
import { ExcludesFalse } from "src/flow/types";
import { useFolders } from "src/flowsOverview/v2/folderQueries";
import { useCapabilities } from "src/hooks/useCapabilities";
import { getJobsByRunId } from "src/jobs/api/queries";
import { Job } from "src/jobs/types";
import { OmniboxBase } from "src/omnibox/OmniboxBase";
import { MenuItem } from "src/omnibox/OmniboxBase";
import {
  useOmniboxActions,
  useIsOmniboxVisible,
} from "src/omnibox/OmniboxStore";
import { FEATURE_FLAGS } from "src/router/featureFlags";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import {
  DashboardPageParamsT,
  getFlowVersionsUrl,
  getUrlToDecisionsOverview,
  getUrlToJobPage,
  getUrlToSettingsPage,
  getUrlToWsDashboard,
} from "src/router/urls";
import { SettingsSubpages } from "src/settings/SettingsPage";
import { logger } from "src/utils/logger";
import { useParamsDecode } from "src/utils/useParamsDecode";

const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

enum ItemType {
  Action = "Action",
  FeatureFlag = "Feature Flag",
  DecisionFlow = "Decision Flow",
  Folder = "Folder",
}

const ItemIcon: React.FC<{
  color: string;
  bgColor: string;
  icon: IconProp;
  padded?: boolean;
}> = ({ color, bgColor, icon, padded }) => (
  <div
    className={twJoin(
      "h-6 w-6 items-center justify-center rounded-md bg-opacity-50",
      bgColor,
      padded && "pl-px pt-0.5",
    )}
  >
    <Icon color={color} icon={icon} size="xs" />
  </div>
);

type IdType = "entity" | "decision" | "jobrun";

type IdDetails =
  | { type: "entity" }
  | { type: "decision" }
  | { type: "invalid" }
  | { type: "jobrun"; job: Job };

export const OmniboxOther = () => {
  const { workspace } = useWorkspaceContext();
  const isOmniboxVisible = useIsOmniboxVisible();
  const { showOmnibox, hideOmnibox } = useOmniboxActions();
  const [_, setSearchParams] = useSearchParams();
  const capabilities = useCapabilities();
  const [userInput, setUserInput] = useState<string>("");
  const [knownIdType, setKnownIdType] = useState<IdDetails | null>(null);

  const setDebouncedUserInput = useDebounceCallback(setUserInput, 50);

  const idActionsState = {
    isDecision: knownIdType?.type === "decision",
    isEntity: knownIdType?.type === "entity",
    isJobRun: knownIdType?.type === "jobrun",
    isInvalid: knownIdType?.type === "invalid",
    isKnownId: knownIdType !== null,
  };

  useEffect(() => {
    const abortController = new AbortController();

    setKnownIdType(null);
    let newKnownIdType: IdDetails | null = null;
    const promises = [];

    if (userInput) {
      const summaryPromise = ExporterMetricsEndpoint.getDecisionSummary(
        workspace.base_url!,
        {
          entity_id: userInput,
          groupby: "flow.id",
          aggregate: "count",
        },
        abortController.signal,
      )
        .then((response) => {
          if (response.groups.length > 0) {
            newKnownIdType = { type: "entity" };
          }
        })
        .catch(() => {});

      promises.push(summaryPromise);
    }

    if (uuidRegex.test(userInput)) {
      const decisionPromise = getHistoryDecision({
        baseUrl: workspace.base_url!,
        decisionId: userInput,
        includeNodeResultsData: false,
        includeLinkedDecisions: false,
        signal: abortController.signal,
      })
        .then((response) => {
          if (response && response.data) {
            newKnownIdType = { type: "decision" };
          }
        })
        .catch(() => {});
      promises.push(decisionPromise);

      const jobsPromise = getJobsByRunId(
        workspace.base_url!,
        userInput,
        abortController.signal,
      )
        .then((jobsPage) => {
          if (jobsPage.jobs.length > 0) {
            newKnownIdType = { type: "jobrun", job: jobsPage.jobs[0] };
          }
        })
        .catch(() => {});
      promises.push(jobsPromise);
    }

    Promise.all(promises)
      .then(() => {
        if (newKnownIdType === null) {
          setKnownIdType({ type: "invalid" });
        } else {
          setKnownIdType(newKnownIdType);
        }
      })
      .catch(() => {});

    return () => {
      abortController.abort();
    };
  }, [userInput, workspace.base_url]);

  const orgs = useUserOrganizations();
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const isTaktileUser =
    orgs.data?.some((org) => org.id === TAKTILE_ORG_ID) ?? false;

  const document = useRef(window.document);
  const navigate = useNavigate();

  const handleClose = () => {
    hideOmnibox();
    setUserInput("");
  };

  const handleKeydown = (e: KeyboardEvent) => {
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyK") {
      showOmnibox();
    }
    if (isOmniboxVisible && e.key === "Escape") {
      handleClose();
    }
  };
  useEventListener("keydown", handleKeydown, document);

  // Fetch the relevant data for the omnibox items: list of actions & feature flags
  const folders = useFolders({ workspaceId: wsId });
  const flows = useFlows({ workspaceId: wsId });
  const actionOptions = [
    capabilities.connections.canAccess && {
      key: "connections",
      text: "Go to Connections",
      icon: faPlug,
    },
    capabilities.apiKeys.canAccess && {
      key: "api-keys",
      text: "Go to API keys",
      icon: faKey,
    },
    capabilities.usersPermissions.canAccess && {
      key: "users",
      text: "Go to Users & permissions",
      icon: faUserCircle,
    },
  ].filter(Boolean as unknown as ExcludesFalse);

  // Create omnibox items list for actions, feature flags and decision flows
  const folderItems: MenuItem[] =
    folders.data?.folders.map(({ name, id }) => ({
      label: name,
      icon: (
        <ItemIcon bgColor="bg-gray-200" color="text-gray-500" icon={faFolder} />
      ),
      type: "Folder",
      details: "",
      value: id,
      onSelect: () => selectItem(id, ItemType.Folder),
    })) ?? [];
  const decisionFlowItems: MenuItem[] =
    flows.data?.map(({ name, id }) => ({
      label: name,
      icon: (
        <ItemIcon
          bgColor="bg-indigo-200"
          color="text-indigo-500"
          icon={faCube}
          padded
        />
      ),
      type: "Decision Flow",
      details: "",
      value: id,
      onSelect: () => selectItem(id, ItemType.DecisionFlow),
    })) ?? [];
  const actionItems: MenuItem[] = actionOptions.map(({ key, icon, text }) => ({
    label: text,
    icon: (
      <ItemIcon
        bgColor="bg-gray-200"
        color="text-gray-500"
        icon={icon}
        padded
      />
    ),
    type: "Action",
    details: "",
    value: key,
    onSelect: () => selectItem(key, ItemType.Action),
  }));
  const featureFlagItems: MenuItem[] = Object.entries(FEATURE_FLAGS).map(
    ([key, value]) => ({
      label: capitalize(value).replace("-", " "),
      icon: (
        <ItemIcon
          bgColor="bg-gray-200"
          color="text-gray-500"
          icon={faFlagAlt}
        />
      ),
      type: "Feature Flag",
      details: "",
      value: key,
      onSelect: () => selectItem(value, ItemType.FeatureFlag),
    }),
  );

  const selectItem = (value: string, type: ItemType, query: string = "") => {
    try {
      switch (type) {
        case ItemType.FeatureFlag:
          setSearchParams((params) => {
            if (params.has(value)) {
              params.delete(value);
            } else {
              params.append(value, "true");
            }
            return params;
          });
          break;
        case ItemType.DecisionFlow:
          navigate(getFlowVersionsUrl(orgId, wsId, value));
          break;
        case ItemType.Action:
          switch (value as IdType) {
            case "decision":
              query &&
                navigate(
                  getUrlToDecisionsOverview(orgId, wsId, { decisionId: query }),
                );
              break;
            case "entity":
              query &&
                navigate(
                  getUrlToDecisionsOverview(orgId, wsId, { entityId: query }),
                );
              break;
            case "jobrun":
              query &&
                knownIdType?.type === "jobrun" &&
                navigate(
                  getUrlToJobPage(
                    orgId,
                    wsId,
                    knownIdType.job.flow_id,
                    knownIdType.job.id,
                  ),
                );
              break;
            default:
              navigate(
                getUrlToSettingsPage(orgId, wsId, value as SettingsSubpages),
              );
              break;
          }
          break;
        case ItemType.Folder:
          navigate(getUrlToWsDashboard({ orgId, wsId, folderId: value }));
          break;
      }
    } catch (error) {
      logger.error("Error selecting item:", error);
    }
  };

  const entityItems = [
    {
      label: "Search for an Entity ID",
      icon: (
        <ItemIcon
          bgColor="bg-gray-200"
          color="text-gray-500"
          icon={faSparkles}
        />
      ),
      type: ItemType.Action,
      subType: "Entity ID",
      details: "",
      value: "entity",
      onSelect: (query: string) => selectItem("entity", ItemType.Action, query),
      pattern: invalidEntityIdsPattern,
      disabled:
        !userInput || (idActionsState.isKnownId && !idActionsState.isEntity),
      dataLoc: "omnibox-entity-search",
    },
    {
      label: "Search for a Decision ID",
      icon: (
        <ItemIcon
          bgColor="bg-gray-200"
          color="text-gray-500"
          icon={faSparkles}
        />
      ),
      type: ItemType.Action,
      subType: "Decision ID",
      details: "",
      value: "decision",
      onSelect: (query: string) =>
        selectItem("decision", ItemType.Action, query),
      pattern: uuidRegex,
      disabled:
        !userInput || (idActionsState.isKnownId && !idActionsState.isDecision),
      dataLoc: "omnibox-decision-search",
    },

    {
      label: "Search for a Job run ID",
      icon: (
        <ItemIcon
          bgColor="bg-gray-200"
          color="text-gray-500"
          icon={faSparkles}
        />
      ),
      type: ItemType.Action,
      subType: "Job run ID",
      details: "",
      value: "jobrun",
      onSelect: (query: string) => selectItem("jobrun", ItemType.Action, query),
      pattern: uuidRegex,
      disabled: !userInput || !idActionsState.isJobRun,
    },
  ];

  const menuItems = isTaktileUser
    ? [
        ...folderItems,
        ...decisionFlowItems,
        ...actionItems,
        ...entityItems,
        ...featureFlagItems,
      ]
    : [...folderItems, ...decisionFlowItems, ...actionItems, ...entityItems];

  const currentUrl = window.location.href;
  const versionRegex = /\/version\//;
  const isVersionPage = versionRegex.test(currentUrl);

  return (
    !isVersionPage && (
      <OmniboxBase
        data-loc="omnibox-canvas"
        menuItems={menuItems}
        open={isOmniboxVisible}
        placeholder="Search for a Decision ID, Entity ID, Decision Flows, or actions"
        onClose={handleClose}
        onQuery={setDebouncedUserInput}
      />
    )
  );
};
