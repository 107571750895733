import { EditorView } from "codemirror";
import CodeMirrorMerge from "react-codemirror-merge";

import { MergeViewTheme } from "src/base-components/CodeInput/Themes";

const Original = CodeMirrorMerge.Original;
const Modified = CodeMirrorMerge.Modified;

const MergeViewBasicSetup = {
  highlightActiveLine: false,
  highlightActiveLineGutter: false,
} as const;

const theme = EditorView.theme(MergeViewTheme);

export const MergeView: React.FC<{ original: string; modified: string }> = ({
  original,
  modified,
}) => (
  <CodeMirrorMerge
    className="decideScrollbar h-full overflow-auto bg-gray-50"
    theme={theme}
  >
    <Original basicSetup={MergeViewBasicSetup} value={original} readOnly />
    <Modified basicSetup={MergeViewBasicSetup} value={modified} readOnly />
  </CodeMirrorMerge>
);
