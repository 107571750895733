import {
  faCheck,
  faRefresh,
  faInfoCircle,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { faBoltLightning } from "@fortawesome/pro-solid-svg-icons";
import { Menu } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { OrgLogo } from "src/base-components/OrgLogo";
import { Pill } from "src/base-components/Pill";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { Avatar } from "src/design-system/Avatar";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { OrgWithWorkspaces } from "src/layout/types";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import {
  adminPanelPath,
  getSignUpUrlWithNext,
  getUrlToWsDashboard,
} from "src/router/urls";
import { useAuthActions, useUserData } from "src/store/AuthStore";
import { copyTextToClipboard } from "src/utils/clipboard";
import { useCurrentWorkspace } from "src/utils/useCurrentWorkspace";

const Divider = <hr className="border-gray-100" />;

const UserInformationDisplay = () => {
  const {
    signed_in_full_name,
    signed_in_username,
    signed_in_avatar_url,
    signed_in_email,
  } = useUserData();
  return (
    <div className="flex items-center gap-x-2 px-4 pb-3.5 pt-2.5">
      <Avatar
        size="3xl"
        user={{
          full_name: signed_in_full_name,
          avatar_url: signed_in_avatar_url,
          username: signed_in_username,
        }}
      />
      <div>
        <div className="text-gray-800 font-inter-semibold-13px">
          {signed_in_full_name ?? signed_in_username}
        </div>
        <div className="text-gray-600 font-inter-normal-12px">
          {signed_in_email ?? signed_in_username}
        </div>
      </div>
    </div>
  );
};

const ActiveOrgItem: React.FC<{
  icon: React.ReactNode;
  name: string;
  principal: string;
  children: React.ReactNode;
}> = ({ icon, name, principal, children }) => {
  return (
    <>
      <Menu.Item disabled>
        <div className="flex items-center gap-x-2 px-4 py-2.5">
          {icon}
          <span className="mr-auto overflow-hidden break-words text-gray-800 font-inter-medium-13px">
            {name}
          </span>
          <Tooltip
            body={
              <div>
                Organization ID:{" "}
                <Pill size="sm" variant="gray">
                  <Pill.Text>{principal}</Pill.Text>
                </Pill>
              </div>
            }
            placement="top"
            title={undefined}
            asChild
          >
            <Icon
              color="text-gray-500"
              cursorType="default"
              icon={faInfoCircle}
              size="2xs"
            />
          </Tooltip>
        </div>
      </Menu.Item>
      <div className="ml-7.5 flex flex-row">
        <span className="my-2 w-0.5 bg-gray-200" />
        <div className="w-full flex-grow overflow-hidden">{children}</div>
      </div>
      <div className="h-3" />
    </>
  );
};

const WorkspaceItem: React.FC<{
  name: string;
  selected?: boolean;
  truncate?: boolean;
  onClick?: () => void;
}> = ({ name, selected, truncate, onClick }) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <div
          className={twJoin(
            "flex items-center gap-x-1.5 overflow-hidden px-4 py-3.5",
            active && "bg-gray-50",
            Boolean(onClick) ? "cursor-copy" : "cursor-pointer",
          )}
          onClick={onClick}
        >
          <span
            className={twJoin(
              "mr-auto overflow-hidden text-gray-800 font-inter-normal-13px",
              Boolean(truncate) ? "truncate" : "break-words",
            )}
          >
            {name}
          </span>
          {selected && (
            <Icon color="text-indigo-600" icon={faCheck} size="2xs" />
          )}
        </div>
      )}
    </Menu.Item>
  );
};

const Powertools: React.FC = () => {
  const { signed_in_user_id, api_key } = useUserData();

  const handleClick = (value: string | undefined, message: string) => {
    if (value) {
      copyTextToClipboard(value);
      toastActions.success({ title: message });
    }
  };

  return (
    <>
      {Divider}
      <ActiveOrgItem
        icon={<Icon color="text-yellow-200" icon={faBoltLightning} />}
        name="Powertools"
        principal="Powertools"
      >
        <WorkspaceItem
          name={`📝 gitsha ${window.environment.GITHUB_SHA ?? "NOT SET"}`}
          truncate
          onClick={() =>
            handleClick(
              window.environment.GITHUB_SHA,
              "Copied GITHUB_SHA to clipboard!",
            )
          }
        />
        <WorkspaceItem name={`🌄 env ${window.environment.ENV}`} truncate />
        <WorkspaceItem
          name={`👤 ${signed_in_user_id}`}
          truncate
          onClick={() =>
            handleClick(signed_in_user_id, "Copied user_id to clipboard!")
          }
        />
        <WorkspaceItem
          name="🔑 xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          truncate
          onClick={() => handleClick(api_key, "Copied api_key to clipboard!")}
        />
      </ActiveOrgItem>
    </>
  );
};

const LinkToAdminPanel: React.FC = () => {
  const { roles } = useUserData();

  const isFWDOrAdmin = roles?.some(
    (r) => r.name === "taktile_d_admin" || r.name === "fwd_d",
  );

  if (!isFWDOrAdmin) {
    return null;
  }

  return (
    <Menu.Item>
      <Link
        key="link-to-admin-panel"
        className="flex items-center gap-x-1.5 px-4 py-3.5 text-gray-800 font-inter-normal-13px hover:cursor-pointer hover:bg-gray-50"
        to={adminPanelPath}
      >
        Admin panel
      </Link>
    </Menu.Item>
  );
};

export const UserMenu: React.FC = () => {
  const { org, workspace, orgs } = useCurrentWorkspace();
  const [menuRef, setMenuRef] = useState<Nullable<HTMLElement>>(null);
  const [itemsRef, setItemsRef] = useState<Nullable<HTMLDivElement>>(null);
  const [mode, setMode] = useState<"org" | "ws">("ws");
  const { signed_in_full_name, signed_in_username, signed_in_avatar_url } =
    useUserData();

  const { resetAll } = useAuthActions();

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    menuRef,
    itemsRef,
    {
      strategy: "fixed",
      placement: "bottom",
      // Keep a minimum of 16px padding from the viewport edge
      modifiers: [
        {
          name: "preventOverflow",
          options: {
            padding: 16,
          },
        },
      ],
    },
  );

  useEffect(() => {
    if (!itemsRef) {
      setMode("ws");
    }
  }, [itemsRef]);

  const logout = () => {
    resetAll();
    window.location.assign(getSignUpUrlWithNext());
  };

  if (!org || !workspace) {
    return undefined;
  }

  return (
    <Menu ref={setMenuRef} as="div" className="h-8">
      {({ close }) => (
        <>
          <Menu.Button>
            <Avatar
              size="3xl"
              user={{
                full_name: signed_in_full_name,
                avatar_url: signed_in_avatar_url,
                username: signed_in_username,
              }}
            />
          </Menu.Button>
          <Menu.Items
            ref={setItemsRef}
            className="z-50 flex w-[248px] flex-col overflow-y-auto rounded-lg bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none"
            {...popperAttributes.popper}
            style={{
              ...popperStyles.popper,
              minWidth: menuRef?.scrollWidth,
              maxHeight: menuRef ? `calc(100vh - 52px)` : undefined,
            }}
          >
            {mode === "org" && (
              <OrgPicker createOnClick={() => () => {}} orgs={orgs} />
            )}
            {mode === "ws" && (
              <>
                <UserInformationDisplay />
                {Divider}
                <div className="min-h-[48px] flex-shrink overflow-auto">
                  <ActiveOrgItem
                    icon={<OrgLogo org={org} size="medium" />}
                    name={org.name}
                    principal={org.principal}
                  >
                    {org.workspaces.map((ws) => (
                      <Link
                        key={ws.id}
                        to={getUrlToWsDashboard({
                          orgId: ws.organization_id,
                          wsId: ws.id,
                        })}
                        onClick={close}
                      >
                        <WorkspaceItem
                          name={ws.name}
                          selected={ws.id === workspace.id}
                        />
                      </Link>
                    ))}
                  </ActiveOrgItem>

                  {isFeatureFlagEnabled(FEATURE_FLAGS.powertools) && (
                    <Powertools />
                  )}
                </div>

                {Divider}

                {orgs.length > 1 && (
                  <>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={twJoin(
                            "flex h-12 items-center gap-x-1.5 px-4 py-3",
                            active && "cursor-pointer bg-gray-50",
                          )}
                          onClick={(e) => {
                            e.preventDefault();
                            setMode("org");
                          }}
                        >
                          <Icon
                            color="text-gray-500"
                            icon={faRefresh}
                            size="xs"
                          />
                          <span className="text-gray-800 font-inter-normal-13px">
                            Switch organization
                          </span>
                        </div>
                      )}
                    </Menu.Item>
                    {Divider}
                  </>
                )}

                <LinkToAdminPanel />

                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={twJoin(
                        "flex items-center gap-x-1.5 px-4 py-3.5 text-gray-800 font-inter-normal-13px",
                        active && "cursor-pointer bg-gray-50",
                      )}
                      onClick={logout}
                    >
                      Sign out
                    </div>
                  )}
                </Menu.Item>
              </>
            )}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

const OrgPicker: React.FC<{
  orgs: OrgWithWorkspaces[];
  createOnClick: (
    org: OrgWithWorkspaces,
  ) => React.MouseEventHandler<HTMLDivElement>;
}> = ({ orgs, createOnClick }) => {
  return orgs.map((org) => {
    const firstWorkspace: WorkspaceDataplane | undefined = org.workspaces[0];
    const item = (
      <DropdownItem
        key={org.id}
        label={org.name}
        logo={<OrgLogo org={org} size="small" />}
        link
        onClick={firstWorkspace ? undefined : createOnClick(org)}
      />
    );

    return firstWorkspace ? (
      <Link
        key={org.id}
        to={getUrlToWsDashboard({ orgId: org.id, wsId: firstWorkspace.id })}
      >
        {item}
      </Link>
    ) : (
      item
    );
  });
};

type Props = {
  logo?: React.ReactNode;
  label: string;
  selected?: boolean;
  link?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const DropdownItem: React.FC<Props> = ({
  logo,
  label,
  disabled = false,
  selected = false,
  link = false,
  onClick,
}) => (
  <Menu.Item disabled={disabled || selected}>
    {({ active }) => (
      <div
        className={twJoin(
          "flex items-center gap-x-1.5 px-4 py-2",
          ((active && link) || (!logo && active)) &&
            "cursor-pointer bg-gray-50",
        )}
        onClick={onClick}
      >
        {logo}
        <span className="mr-auto overflow-hidden break-words text-gray-800 font-inter-semibold-13px">
          {label}
        </span>
        {selected && <Icon color="text-indigo-600" icon={faCheck} size="2xs" />}
        {link && (
          <Icon
            color="text-gray-500 hover:text-gray-700"
            icon={faChevronRight}
            size="2xs"
          />
        )}
      </div>
    )}
  </Menu.Item>
);
