import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons";
import { Menu } from "@headlessui/react";
import { saveAs } from "file-saver";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { RouterPresignedUrls } from "src/api/endpoints";
import { NodeTestRunResult } from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { Spinner } from "src/base-components/Spinner";
import { TransitionWrapper } from "src/base-components/TransitionWrapper";
import { toastActions } from "src/design-system/Toast/utils";
import { logger } from "src/utils/logger";

type PropsT = {
  results: NodeTestRunResult;
  nodeName: string;
  workspaceUrl: string;
};

export const DownloadDataButton: React.FC<PropsT> = observer(
  ({ results, nodeName, workspaceUrl }) => {
    const [downloadInProgress, setDownloadInProgress] = useState(false);

    const handleLinkClick = async (format: "json" | "csv") => {
      setDownloadInProgress(true);
      const filename = `${nodeName}.${format}`;

      try {
        let fileUrl: string | null = null;
        let retries = 0;
        while (!fileUrl) {
          if (retries === 10) {
            throw Error("Number of retries was exceeded");
          }
          try {
            fileUrl = (
              await RouterPresignedUrls.post(
                workspaceUrl,
                results.test_run_id,
                {
                  type: "node_results",
                  format,
                  node_id: results.node_id,
                  run_id: results.test_run_id,
                  version_id: results.version_id,
                },
              )
            ).data.url;
          } catch (err) {
            retries++;
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        }
        saveAs(fileUrl, filename);
      } catch (err) {
        logger.error("Failed to download intermediate results", err);
        toastActions.failure({
          title:
            "Something went wrong when downloading the intermediate results, please try again",
        });
      } finally {
        setDownloadInProgress(false);
      }
    };

    return (
      <Menu as="div" className="relative">
        {({ close }) => (
          <>
            <Menu.Button className="ml-1">
              <Icon
                color="text-gray-500 hover:text-gray-700"
                cursorType="pointer"
                dataLoc="download-intermediate-data-icon"
                icon={faArrowDownToLine}
                size="xs"
              />
            </Menu.Button>
            <TransitionWrapper>
              <Menu.Items
                as="ul"
                className="absolute z-10 w-66 rounded-b-lg border border-gray-300 bg-white shadow-lg font-inter-semibold-14px"
              >
                {downloadInProgress ? (
                  <Menu.Item
                    as="li"
                    className="flex h-20 items-center justify-center p-5"
                  >
                    <Spinner />
                  </Menu.Item>
                ) : (
                  <>
                    <Menu.Item
                      as="li"
                      className="cursor-pointer bg-white p-5 hover:bg-gray-50"
                      data-loc="json-download"
                      onClick={async (e) => {
                        e.preventDefault();
                        await handleLinkClick("json");

                        close();
                      }}
                    >
                      Download as JSON
                    </Menu.Item>
                    <Menu.Item
                      as="li"
                      className="cursor-pointer bg-white p-5 hover:bg-gray-50"
                      data-loc="csv-download"
                      onClick={async (e) => {
                        e.preventDefault();
                        await handleLinkClick("csv");
                        close();
                      }}
                    >
                      Download as CSV
                    </Menu.Item>
                  </>
                )}
              </Menu.Items>
            </TransitionWrapper>
          </>
        )}
      </Menu>
    );
  },
);
