import { faWavePulse } from "@fortawesome/pro-regular-svg-icons";
import { twJoin } from "tailwind-merge";

import { EmptyState, EmptyProps } from "src/design-system/EmptyState";

type PropsT = Pick<EmptyProps, "headline" | "description"> & {
  padded?: boolean;
};

export const DecisionHistoryEmpty: React.FC<PropsT> = ({
  headline,
  description,
  padded = true,
}) => {
  return (
    <div
      className={twJoin("w-full flex-1", padded && "px-6 pb-8 pt-4")}
      data-loc="decision-history-empty"
    >
      <div className="flex h-full w-full grow flex-col items-center justify-center rounded-xl border border-gray-200">
        <EmptyState
          description={description}
          headline={headline}
          icon={faWavePulse}
        />
      </div>
    </div>
  );
};
