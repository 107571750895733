/* tslint:disable */
/* eslint-disable */
/**
 * Copilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AssignmentNodeDefinition
 */
export interface AssignmentNodeDefinition {
    /**
     * 
     * @type {string}
     * @memberof AssignmentNodeDefinition
     */
    'name'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof AssignmentNodeDefinition
     */
    'type': any;
    /**
     * 
     * @type {object}
     * @memberof AssignmentNodeDefinition
     */
    'meta': object;
}
/**
 * 
 * @export
 * @interface CodeNodeDefinition
 */
export interface CodeNodeDefinition {
    /**
     * 
     * @type {string}
     * @memberof CodeNodeDefinition
     */
    'name'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof CodeNodeDefinition
     */
    'type': any;
    /**
     * 
     * @type {object}
     * @memberof CodeNodeDefinition
     */
    'meta': object;
}
/**
 * 
 * @export
 * @interface ConversationMessage
 */
export interface ConversationMessage {
    /**
     * 
     * @type {string}
     * @memberof ConversationMessage
     */
    'role': ConversationMessageRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ConversationMessage
     */
    'content': string;
}

/**
    * @export
    * @enum {string}
    */
export enum ConversationMessageRoleEnum {
    USER = 'user',
    COPILOT = 'copilot'
}

/**
 * 
 * @export
 * @interface ConverseRequest
 */
export interface ConverseRequest {
    /**
     * 
     * @type {string}
     * @memberof ConverseRequest
     */
    'conversation_id': string;
    /**
     * 
     * @type {string}
     * @memberof ConverseRequest
     */
    'completion_id'?: string | null;
    /**
     * 
     * @type {NodeConversationContext}
     * @memberof ConverseRequest
     */
    'context': NodeConversationContext;
    /**
     * 
     * @type {Array<ConversationMessage>}
     * @memberof ConverseRequest
     */
    'messages': Array<ConversationMessage>;
}
/**
 * 
 * @export
 * @interface ConverseResponse
 */
export interface ConverseResponse {
    /**
     * 
     * @type {string}
     * @memberof ConverseResponse
     */
    'completion_id': string;
    /**
     * 
     * @type {string}
     * @memberof ConverseResponse
     */
    'conversation_id': string;
    /**
     * 
     * @type {Array<ConversationMessage>}
     * @memberof ConverseResponse
     */
    'messages': Array<ConversationMessage>;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface NodeConversationContext
 */
export interface NodeConversationContext {
    /**
     * 
     * @type {string}
     * @memberof NodeConversationContext
     */
    'context_type': NodeConversationContextContextTypeEnum;
    /**
     * 
     * @type {NodeDefinition}
     * @memberof NodeConversationContext
     */
    'node_definition': NodeDefinition;
    /**
     * 
     * @type {object}
     * @memberof NodeConversationContext
     */
    'data_typing'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof NodeConversationContext
     */
    'data_sample'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof NodeConversationContext
     */
    'parameters'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof NodeConversationContext
     */
    'input_schema'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof NodeConversationContext
     */
    'output_schema'?: object | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof NodeConversationContext
     */
    'mentioned_nodes'?: Array<object>;
}

/**
    * @export
    * @enum {string}
    */
export enum NodeConversationContextContextTypeEnum {
    NODE = 'node'
}

/**
 * 
 * @export
 * @interface NodeDefinition
 */
export interface NodeDefinition {
    /**
     * 
     * @type {string}
     * @memberof NodeDefinition
     */
    'name'?: string;
    /**
     * 
     * @type {any}
     * @memberof NodeDefinition
     */
    'type': any;
    /**
     * 
     * @type {object}
     * @memberof NodeDefinition
     */
    'meta': object;
}
/**
 * 
 * @export
 * @interface RuleNodeDefinition
 */
export interface RuleNodeDefinition {
    /**
     * 
     * @type {string}
     * @memberof RuleNodeDefinition
     */
    'name'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof RuleNodeDefinition
     */
    'type': any;
    /**
     * 
     * @type {object}
     * @memberof RuleNodeDefinition
     */
    'meta': object;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Converse with the Copilot
         * @param {ConverseRequest} converseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        converseCopilotApiV1ConversePost: async (converseRequest: ConverseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'converseRequest' is not null or undefined
            assertParamExists('converseCopilotApiV1ConversePost', 'converseRequest', converseRequest)
            const localVarPath = `/copilot/api/v1/converse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(converseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the completion response
         * @param {string} completionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationOutputCopilotApiV1ConverseCompletionIdGet: async (completionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'completionId' is not null or undefined
            assertParamExists('getConversationOutputCopilotApiV1ConverseCompletionIdGet', 'completionId', completionId)
            const localVarPath = `/copilot/api/v1/converse/{completion_id}`
                .replace(`{${"completion_id"}}`, encodeURIComponent(String(completionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Converse with the Copilot
         * @param {ConverseRequest} converseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async converseCopilotApiV1ConversePost(converseRequest: ConverseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConverseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.converseCopilotApiV1ConversePost(converseRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.converseCopilotApiV1ConversePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the completion response
         * @param {string} completionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationOutputCopilotApiV1ConverseCompletionIdGet(completionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConverseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationOutputCopilotApiV1ConverseCompletionIdGet(completionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getConversationOutputCopilotApiV1ConverseCompletionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Converse with the Copilot
         * @param {DefaultApiConverseCopilotApiV1ConversePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        converseCopilotApiV1ConversePost(requestParameters: DefaultApiConverseCopilotApiV1ConversePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ConverseResponse> {
            return localVarFp.converseCopilotApiV1ConversePost(requestParameters.converseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the completion response
         * @param {DefaultApiGetConversationOutputCopilotApiV1ConverseCompletionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationOutputCopilotApiV1ConverseCompletionIdGet(requestParameters: DefaultApiGetConversationOutputCopilotApiV1ConverseCompletionIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ConverseResponse> {
            return localVarFp.getConversationOutputCopilotApiV1ConverseCompletionIdGet(requestParameters.completionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for converseCopilotApiV1ConversePost operation in DefaultApi.
 * @export
 * @interface DefaultApiConverseCopilotApiV1ConversePostRequest
 */
export interface DefaultApiConverseCopilotApiV1ConversePostRequest {
    /**
     * 
     * @type {ConverseRequest}
     * @memberof DefaultApiConverseCopilotApiV1ConversePost
     */
    readonly converseRequest: ConverseRequest
}

/**
 * Request parameters for getConversationOutputCopilotApiV1ConverseCompletionIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiGetConversationOutputCopilotApiV1ConverseCompletionIdGetRequest
 */
export interface DefaultApiGetConversationOutputCopilotApiV1ConverseCompletionIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetConversationOutputCopilotApiV1ConverseCompletionIdGet
     */
    readonly completionId: string
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Converse with the Copilot
     * @param {DefaultApiConverseCopilotApiV1ConversePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public converseCopilotApiV1ConversePost(requestParameters: DefaultApiConverseCopilotApiV1ConversePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).converseCopilotApiV1ConversePost(requestParameters.converseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the completion response
     * @param {DefaultApiGetConversationOutputCopilotApiV1ConverseCompletionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConversationOutputCopilotApiV1ConverseCompletionIdGet(requestParameters: DefaultApiGetConversationOutputCopilotApiV1ConverseCompletionIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConversationOutputCopilotApiV1ConverseCompletionIdGet(requestParameters.completionId, options).then((request) => request(this.axios, this.basePath));
    }
}



