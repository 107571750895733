import {
  faUserCircle,
  faArrowDown,
  faFilter,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCheck,
  faFilter as faFilterSolid,
} from "@fortawesome/pro-solid-svg-icons";
import {
  ColumnDef,
  HeaderGroup,
  Row,
  getCoreRowModel,
  useReactTable,
  flexRender,
} from "@tanstack/react-table";
import { times } from "lodash";
import React, { useRef } from "react";
import { VirtualItem, useVirtual } from "react-virtual";
import { twJoin } from "tailwind-merge";

import { ReviewCase } from "src/api/types";
import {
  FixedPositionedDropdown,
  FixedPositionedDropdownPropsT,
} from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { OrganizationUser } from "src/clients/taktile-api";
import { Avatar } from "src/design-system/Avatar";
import { Tooltip } from "src/design-system/Tooltip";
import { useIsContentOverflows } from "src/utils/useIsContentOverflows";

type PropsT = {
  data: ReviewCase[];
  columns: ColumnDef<ReviewCase, any>[];
  frameClassName?: string;
  rowOnClick: (
    row: Row<ReviewCase>,
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => void;
  canFetchNextPage: boolean;
  fetchNextPage: () => void;
  isLoading: boolean;
  isFetchingNextPage?: boolean;
};

const renderRow = (
  row: Row<ReviewCase>,
  virtualRow: VirtualItem,
  rowOnClick: PropsT["rowOnClick"],
) => (
  <tr
    key={row.id}
    ref={virtualRow.measureRef}
    className="group/row group cursor-pointer text-gray-800 font-inter-normal-12px hover:bg-gray-50 [&:not(:last-child)]:border-y"
    data-loc={`review-row-${row.original.status}`}
    onClick={(e) => rowOnClick(row, e)}
  >
    {row.getVisibleCells().map((cell) => (
      <td
        key={cell.id}
        className="py-2.5"
        style={{
          minWidth: cell.column.columnDef.minSize,
          maxWidth: cell.column.columnDef.maxSize,
          width: cell.column.columnDef.size ? cell.column.getSize() : undefined,
        }}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    ))}
  </tr>
);

const renderHeaderGroup = (headerGroup: HeaderGroup<ReviewCase>) => (
  <tr key={headerGroup.id} className="sticky top-0 bg-white bg-opacity-100">
    {headerGroup.headers.map((column) => {
      const ctx = column.getContext();

      return (
        <th
          key={column.id}
          className="truncate text-left text-gray-800 font-inter-medium-12px"
          style={{
            minWidth: ctx.column.columnDef.minSize,
            maxWidth: ctx.column.columnDef.maxSize,
            width: ctx.column.columnDef.size ? column.getSize() : undefined,
          }}
        >
          {flexRender(column.column.columnDef.header, column.getContext())}
        </th>
      );
    })}
  </tr>
);

export const Table: React.FC<PropsT> = ({
  data,
  columns,
  frameClassName,
  rowOnClick,
  canFetchNextPage,
  fetchNextPage,
  isLoading,
  isFetchingNextPage,
}) => {
  const fetchMoreOnCloseToBottom = (
    containerRefElement?: HTMLDivElement | null,
  ) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      //once the user has scrolled within 1000px of the bottom of the table, fetch more data if there is any
      if (scrollHeight - scrollTop - clientHeight < 1000 && canFetchNextPage) {
        fetchNextPage();
      }
    }
  };
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const tableInstance = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: { size: undefined },
  });
  const { getHeaderGroups } = tableInstance;

  const headerGroups = getHeaderGroups();
  const rows = tableInstance.getRowModel().rows;

  const nextPageSkeleton = times(isLoading ? 20 : 3, (index) => (
    <tr key={index} className="border-y border-gray-200">
      {columns.map((_, index) => (
        <td key={index}>
          <div
            className={twJoin(
              "flex h-11 items-center py-1.5",
              index === 0 && "w-[25px]",
              index === 1 && "w-[85px]",
              index === 2 && "w-[120px]",
              index === 3 && "w-[115px]",
              index === 4 && "w-[75px]",
              index === 5 && "w-[65px]",
              index === 6 && "w-[160px]",
            )}
          >
            <div className="relative h-5 w-full overflow-hidden rounded bg-gray-100">
              <div
                className="absolute inset-0 -translate-x-full transform bg-gradient-to-r from-gray-100 via-gray-50 to-gray-100"
                style={{ animation: "shimmer 1s infinite" }}
              />
            </div>
          </div>
        </td>
      ))}
    </tr>
  ));

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize: totalRowSize } = rowVirtualizer;
  // Dynamic top and botton padding is added to the table content to maintain the correct scroll bar size and position
  const paddingTop = virtualRows?.[0]?.start || 0;
  const paddingBottom =
    totalRowSize - (virtualRows?.[virtualRows.length - 1]?.end || 0);

  return (
    <div
      ref={tableContainerRef}
      className={twJoin("decideScrollbar h-full overflow-auto", frameClassName)}
      onScroll={(e) => fetchMoreOnCloseToBottom(e.target as HTMLDivElement)}
    >
      <table className="w-full table-auto border-collapse border-spacing-0">
        <thead>{headerGroups.map(renderHeaderGroup)}</thead>
        <tbody>
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {!isLoading &&
            virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];
              return renderRow(row, virtualRow, rowOnClick);
            })}

          {(isFetchingNextPage || isLoading) && nextPageSkeleton}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export const SortIcon: React.FC<{ isActive: boolean; onClick: () => void }> = ({
  isActive,
  onClick,
}) => (
  <Tooltip placement="top" title="Sort" asChild>
    <Icon
      color={isActive ? "text-indigo-600" : "text-gray-500 hover:text-gray-700"}
      icon={faArrowDown}
      size="2xs"
      onClick={onClick}
    />
  </Tooltip>
);

export const BaseCell: React.FC<{ value: string }> = ({ value }) => {
  const cell = useRef<HTMLDivElement>(null);
  const isOverflowing = useIsContentOverflows(cell);

  return (
    <Tooltip disabled={!isOverflowing} placement="top" title={value} asChild>
      <div ref={cell} className="max-w-full truncate pr-2">
        {value}
      </div>
    </Tooltip>
  );
};

type FiltersDropdownProps = {
  isFiltering: boolean;
  onResetRequest: () => void;
  description: string;
  selected?: string[];
  onSelect?: (keys: string[]) => void;
  renderValue?: FixedPositionedDropdownPropsT<string, string>["renderValue"];
} & Omit<
  FixedPositionedDropdownPropsT<string, string>,
  | "renderValue"
  | "renderButtonValue"
  | "buttonClassName"
  | "selected"
  | "onSelect"
>;

const defaultRenderValue: FiltersDropdownProps["renderValue"] = ({
  value,
  selected,
}) => (
  <div className="flex w-[240px] items-center justify-between px-4 py-2.5">
    <span className="inline-block">{value}</span>
    {selected && <Icon color="text-indigo-500" icon={faCheck} size="xs" />}
  </div>
);

export const FiltersDropdown = ({
  description,
  isFiltering,
  onResetRequest,
  renderValue = defaultRenderValue,
  ...props
}: FiltersDropdownProps) => (
  <FixedPositionedDropdown
    buttonClassName="border-0"
    elements={props.elements}
    multiple={true}
    renderButtonValue={() => (
      <Icon
        color={
          isFiltering ? "text-indigo-500" : "text-gray-500 hover:text-gray-700"
        }
        icon={isFiltering ? faFilterSolid : faFilter}
        size="2xs"
      />
    )}
    renderHeader={() => (
      <div className="flex w-[240px] cursor-default items-center justify-between px-4 py-2.5 font-inter-semibold-13px">
        <span>{description}</span>
        {isFiltering && (
          <button className="text-indigo-500" onClick={onResetRequest}>
            Reset
          </button>
        )}
      </div>
    )}
    renderValue={renderValue}
    selected={props.selected}
    onSelect={props.onSelect}
  />
);

type AssigneeFilterDropdownProps = {
  isFiltering: boolean;
  onResetRequest: () => void;
  selected?: string[];
  onSelect?: (keys: string[]) => void;
} & Omit<
  FixedPositionedDropdownPropsT<OrganizationUser | string, string>,
  | "renderValue"
  | "renderButtonValue"
  | "buttonClassName"
  | "selected"
  | "onSelect"
>;
export const AssigneeFilterDropdown = ({
  isFiltering,
  onResetRequest,
  ...props
}: AssigneeFilterDropdownProps) => (
  <FixedPositionedDropdown
    buttonClassName="border-0"
    elements={[{ key: "no_assignee", value: "No assignee" }, ...props.elements]}
    itemsClassNames="max-h-[240px] overflow-y-auto decideScrollbar"
    multiple={true}
    renderButtonValue={() => (
      <Icon
        color={
          isFiltering ? "text-indigo-500" : "text-gray-500 hover:text-gray-700"
        }
        icon={isFiltering ? faFilterSolid : faFilter}
        size="2xs"
      />
    )}
    renderHeader={() => (
      <div className="sticky top-0 -mb-2 flex w-[240px] -translate-y-2 cursor-default items-center justify-between bg-white px-4 pb-2.5 pt-4.5 font-inter-semibold-13px">
        <span>Filter by assignee:</span>
        {isFiltering && (
          <button className="text-indigo-500" onClick={onResetRequest}>
            Reset
          </button>
        )}
      </div>
    )}
    renderValue={({ value, selected, disabled }) => (
      <div
        className={twJoin(
          "flex w-[240px] items-center gap-x-2 px-4 py-2.5",
          disabled && "cursor-default",
        )}
      >
        {typeof value === "string" ? (
          <>
            <div className="px-px">
              <Icon
                color="text-gray-400"
                icon={faUserCircle}
                padding={false}
                size="sm"
              />
            </div>
            <span
              className={twJoin(
                "inline-block grow",
                disabled && "text-gray-300",
              )}
            >
              {value}
            </span>
          </>
        ) : (
          <>
            <Avatar user={value} />
            <span
              className={twJoin(
                "inline-block grow",
                disabled && "text-gray-300",
              )}
            >
              {value.full_name ?? value.username ?? value.email}
            </span>
          </>
        )}
        {selected && <Icon color="text-indigo-500" icon={faCheck} size="xs" />}
      </div>
    )}
    selected={props.selected}
    onSelect={props.onSelect}
  />
);
