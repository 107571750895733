import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { isArray } from "lodash";
import { useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";

import { useWorkspaceUsers } from "src/api/taktile/queries";
import { LOCK_NODE_EDITOR_CLASSNAME } from "src/authoringMultiplayerLock/constants";
import { Button } from "src/base-components/Button";
import { Card } from "src/base-components/Card";
import { Pill } from "src/base-components/Pill";
import { ReorderHandle } from "src/base-components/ReorderHandle";
import { Select, SELECT_DIVIDER } from "src/base-components/Select";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import { ClauseBlock } from "src/clauseBlock/ClauseBlock";
import {
  AssignRuleBranchJunctionEnum,
  AssignStrategy,
  AssignStrategyAnyTypeEnum,
  AssignStrategyGroupTypeEnum,
  AssignStrategyReviewerOnlyTypeEnum,
  AssignStrategyRuleTypeEnum,
  AssignStrategyTypeEnum,
  Operators,
} from "src/clients/flow-api";
import { OrganizationUser } from "src/clients/taktile-api";
import { Avatar } from "src/design-system/Avatar";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { canUserBeAssignee, getUserName } from "src/utils/user";

export const ReviewerAssignConfiguration: React.FC<{
  immutable: boolean;
}> = ({ immutable }) => {
  const { orgId, workspace } = useWorkspaceContext();
  const { data: workspaceUsers } = useWorkspaceUsers(orgId, workspace.id, {
    include_deactivated: false,
  });
  const users = workspaceUsers?.filter((user) =>
    canUserBeAssignee(workspace.id, user),
  );
  const { getValues } = useFormContext();

  return (
    <div className="mb-6">
      <Controller
        name="reviewer_assign_strategy_v2.type"
        render={(props) => (
          <SimpleRadioGroup
            disabled={immutable}
            orientation="vertical"
            value={props.field.value as string}
            onValueChange={props.field.onChange}
          >
            <SimpleRadioGroup.Item
              dataLoc="manual-review-random-any-role"
              label={
                <span>
                  Randomly assign to a user with an{" "}
                  <Pill size="sm" variant="gray">
                    <Pill.Text>admin</Pill.Text>
                  </Pill>{" "}
                  <Pill size="sm" variant="gray">
                    <Pill.Text>editor</Pill.Text>
                  </Pill>{" "}
                  or{" "}
                  <Pill size="sm" variant="gray">
                    <Pill.Text>reviewer</Pill.Text>
                  </Pill>{" "}
                  role
                </span>
              }
              labelClassName="pl-2"
              value={AssignStrategyAnyTypeEnum.ANY}
            />
            <SimpleRadioGroup.Item
              dataLoc="manual-review-random-reviewer-only"
              label={
                <span>
                  Randomly assign to a user with a{" "}
                  <Pill size="sm" variant="gray">
                    <Pill.Text>reviewer</Pill.Text>
                  </Pill>{" "}
                  role
                </span>
              }
              labelClassName="pl-2"
              value={AssignStrategyReviewerOnlyTypeEnum.REVIEWER_ONLY}
            />
            <SimpleRadioGroup.Item
              dataLoc="manual-review-random-group"
              label="Randomly assign from a group of users"
              labelClassName="pl-2"
              value={AssignStrategyGroupTypeEnum.GROUP}
            />
            {getValues("reviewer_assign_strategy_v2.type") ===
              AssignStrategyGroupTypeEnum.GROUP && (
              <AssignStrategyUsersSelect immutable={immutable} users={users} />
            )}
            <SimpleRadioGroup.Item
              dataLoc="manual-review-assignee-rule"
              label="Assign to user based on rules"
              labelClassName="pl-2"
              value={AssignStrategyRuleTypeEnum.RULE}
            />
            {getValues("reviewer_assign_strategy_v2.type") ===
              AssignStrategyRuleTypeEnum.RULE && (
              <AssignStrategyRule immutable={immutable} users={users} />
            )}
          </SimpleRadioGroup>
        )}
      />
    </div>
  );
};

const AssignStrategyUsersSelect: React.FC<{
  users: OrganizationUser[] | undefined;
  immutable: boolean;
}> = ({ users, immutable }) => {
  const usersOptions =
    users?.map((user) => ({
      key: user.id,
      searchText: getUserName(user),
      value: (
        <span className="flex items-center gap-x-2">
          <Avatar user={user} />
          {getUserName(user)}
        </span>
      ),
    })) ?? [];
  return (
    <Card className="ml-6">
      <div className="flex items-center gap-x-2">
        <p className="text-gray-700 font-inter-normal-12px">
          Select review users
        </p>
        <div className="flex-1">
          <Controller
            defaultValue={[]}
            name="reviewer_assign_strategy_v2.assignees"
            render={(props) => (
              <Select
                dataLoc="manual-review-assignee-select"
                disabled={immutable}
                dropdownClassName={LOCK_NODE_EDITOR_CLASSNAME}
                options={usersOptions}
                searchPlaceholder="Search user..."
                value={props.field.value}
                multiple
                searchable
                onChange={props.field.onChange}
              />
            )}
            shouldUnregister
          />
        </div>
      </div>
    </Card>
  );
};

const AssignStrategyRule: React.FC<{
  users: OrganizationUser[] | undefined;
  immutable: boolean;
}> = ({ users, immutable }) => {
  const { fields, append, remove, move } = useFieldArray({
    name: "reviewer_assign_strategy_v2.branches",
    shouldUnregister: true,
  });

  useEffect(() => {
    if (!fields.length) {
      append({
        id: uuid(),
        junction: AssignRuleBranchJunctionEnum.AND,
        clauses: [
          {
            id_left: uuid(),
            id_right: uuid(),
            left: "",
            right: "",
            operator: Operators.EQ,
          },
        ],
        assign_strategy: {
          type: AssignStrategyTypeEnum.GROUP,
          assignees: [],
        },
      });
    }
  }, [append, fields.length]);

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const fieldToMove = fields.findIndex((field) => field.id === active.id);
      const fieldToInsert = fields.findIndex((field) => field.id === over.id);
      if (fieldToMove !== -1 && fieldToInsert !== -1) {
        move(fieldToMove, fieldToInsert);
      }
    }
  };

  return (
    <div className="ml-6 flex flex-col gap-y-3">
      <DndContext onDragEnd={handleDragEnd}>
        <SortableContext
          disabled={immutable}
          items={fields.map((rule) => rule.id)}
        >
          {fields.map((field, index) => (
            <RuleCard
              key={field.id}
              id={field.id}
              immutable={immutable}
              index={index}
              isLast={fields.length === 1}
              users={users}
              onDelete={() => remove(index)}
            />
          ))}
        </SortableContext>
      </DndContext>
      <Card>
        <Card.Header>
          <Card.Title title="Default" />
        </Card.Header>
        <div className="my-2.5 h-px rounded-full bg-gray-200" />
        <Card.Content>
          <div className="flex items-center gap-x-2">
            <p className="text-gray-700 font-inter-normal-12px">
              Then assign case to
            </p>
            <div className="flex-1">
              <Controller
                defaultValue={{
                  type: AssignStrategyTypeEnum.GROUP,
                  assignees: [],
                }}
                name="reviewer_assign_strategy_v2.default_assign_strategy"
                render={(props) => (
                  <AssignStrategySelect
                    disabled={immutable}
                    users={users}
                    value={props.field.value}
                    onChange={props.field.onChange}
                  />
                )}
                shouldUnregister
              />
            </div>
          </div>
        </Card.Content>
      </Card>
      <div>
        <Button
          disabled={immutable}
          iconLeft={faPlus}
          variant="secondary"
          onClick={() =>
            append({
              id: uuid(),
              junction: AssignRuleBranchJunctionEnum.AND,
              clauses: [
                {
                  id_left: uuid(),
                  id_right: uuid(),
                  left: "",
                  right: "",
                  operator: Operators.EQ,
                },
              ],
              assign_strategy: {
                type: AssignStrategyTypeEnum.GROUP,
                assignees: [],
              },
            })
          }
        >
          Add Rule
        </Button>
      </div>
    </div>
  );
};

enum AssignStrategySpecialCase {
  UNASSIGNED = "UNASSIGNED",
}

const AssignStrategySelect: React.FC<{
  users: OrganizationUser[] | undefined;
  value: AssignStrategy;
  onChange: (value: AssignStrategy) => void;
  disabled: boolean;
}> = ({ users, value: _value, onChange: _onChange, disabled }) => {
  const usersOptions =
    users?.map((user) => ({
      key: user.id,
      searchText: getUserName(user),
      value: (
        <span className="flex items-center gap-x-2">
          <Avatar user={user} />
          {getUserName(user)}
        </span>
      ),
    })) ?? [];

  const usersWithAdditionalOptions = [
    ...usersOptions,
    SELECT_DIVIDER,
    {
      key: AssignStrategySpecialCase.UNASSIGNED,
      searchText: "Unassigned",
      value: "Unassigned",
    },
    {
      key: AssignStrategyTypeEnum.REVIEWER_ONLY,
      searchText: "Random user with a reviewer role",
      value: (
        <>
          Random user with a{" "}
          <Pill size="sm" variant="gray">
            <Pill.Text>reviewer</Pill.Text>
          </Pill>{" "}
          role
        </>
      ),
    },
    {
      key: AssignStrategyTypeEnum.ANY,
      searchText: "Random user with an admin editor or reviewer role",
      value: (
        <>
          Random user with an{" "}
          <Pill size="sm" variant="gray">
            <Pill.Text>admin</Pill.Text>
          </Pill>{" "}
          <Pill size="sm" variant="gray">
            <Pill.Text>editor</Pill.Text>
          </Pill>{" "}
          or{" "}
          <Pill size="sm" variant="gray">
            <Pill.Text>reviewer</Pill.Text>
          </Pill>{" "}
          role
        </>
      ),
    },
  ];

  const isSingleValue =
    _value.type !== AssignStrategyTypeEnum.GROUP ||
    _value.assignees.length === 0;

  const onChange = (newValue: Nullable<string> | string[]) => {
    const value = isArray(newValue) ? newValue[newValue.length - 1] : newValue;

    if (!value || value === AssignStrategySpecialCase.UNASSIGNED) {
      _onChange({
        type: AssignStrategyTypeEnum.GROUP,
        assignees: [],
      });
      return;
    }

    if (value === AssignStrategyTypeEnum.ANY) {
      _onChange({
        type: AssignStrategyTypeEnum.ANY,
        assignees: undefined,
      });
    } else if (value === AssignStrategyTypeEnum.REVIEWER_ONLY) {
      _onChange({
        type: AssignStrategyTypeEnum.REVIEWER_ONLY,
        assignees: undefined,
      });
    } else {
      _onChange({
        type: AssignStrategyTypeEnum.GROUP,
        assignees: isArray(newValue) ? newValue : [newValue],
      });
    }
  };

  if (isSingleValue) {
    return (
      <Select
        dataLoc="manual-review-strategy-assignee-select"
        disabled={disabled}
        dropdownClassName={LOCK_NODE_EDITOR_CLASSNAME}
        options={usersWithAdditionalOptions ?? []}
        searchPlaceholder="Search user..."
        value={
          _value.type === AssignStrategyTypeEnum.GROUP
            ? AssignStrategySpecialCase.UNASSIGNED
            : _value.type
        }
        searchable
        onChange={onChange}
      />
    );
  }

  return (
    <Select
      dataLoc="manual-review-strategy-assignee-select"
      disabled={disabled}
      dropdownClassName={LOCK_NODE_EDITOR_CLASSNAME}
      options={usersWithAdditionalOptions ?? []}
      searchPlaceholder="Search user..."
      value={_value.assignees}
      multiple
      searchable
      onChange={onChange}
    />
  );
};

const RuleCard: React.FC<{
  index: number;
  immutable: boolean;
  users: OrganizationUser[] | undefined;
  onDelete: () => void;
  isLast: boolean;
  id: string;
}> = ({ index, immutable, users, onDelete, isLast, id }) => {
  const { listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Card
      ref={setNodeRef}
      dataLoc={`manual-review-assignee-rule-${index}`}
      style={style}
    >
      <Card.Header>
        <div className="flex flex-row items-center gap-x-1">
          <ReorderHandle
            className="-ml-2"
            immutable={immutable}
            listeners={listeners}
          />
          <Card.Title title={`Condition ${index + 1}`} />
        </div>
        <Button
          disabled={immutable || isLast}
          iconLeft={faTrashAlt}
          size="sm"
          variant="secondary"
          onClick={onDelete}
        >
          Delete condition
        </Button>
      </Card.Header>
      <Card.Content>
        <Controller
          name={`reviewer_assign_strategy_v2.branches.${index}`}
          render={(props) => {
            return (
              <ClauseBlock
                clauses={props.field.value.clauses}
                immutable={immutable}
                junction={props.field.value.junction}
                onChange={(value) =>
                  props.field.onChange({
                    ...props.field.value,
                    ...value,
                  })
                }
              />
            );
          }}
        />
        <div className="my-2.5 h-px rounded-full bg-gray-200" />
        <div className="flex items-center gap-x-2">
          <p className="text-gray-700 font-inter-normal-12px">
            Then assign case to
          </p>
          <div className="flex-1">
            <Controller
              name={`reviewer_assign_strategy_v2.branches.${index}`}
              render={(props) => (
                <AssignStrategySelect
                  disabled={immutable}
                  users={users}
                  value={props.field.value.assign_strategy}
                  onChange={(value) => {
                    return props.field.onChange({
                      ...props.field.value,
                      assign_strategy: value,
                    });
                  }}
                />
              )}
              shouldUnregister
            />
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};
