import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import { m } from "framer-motion";

import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/design-system/Tooltip";
import { useSetEditDatasetId } from "src/router/SearchParams";

type IncompatibleLabelProps = {
  datasetId: string;
  onOpenTooltip: (open: boolean) => void;
};

export const IncompatibleLabel: React.FC<IncompatibleLabelProps> = ({
  onOpenTooltip,
  datasetId,
}) => {
  const setEditDatasetId = useSetEditDatasetId();

  const handleEditDataset = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setEditDatasetId(datasetId);
  };

  return (
    <m.div
      key="pill"
      animate={{
        opacity: 1,
        transition: {
          type: "tween",
          ease: "easeOut",
          delay: 0.15,
        },
      }}
      initial={{ opacity: 0 }}
      transition={{ type: "tween", ease: "easeOut" }}
      layout
    >
      <Tooltip
        body="Your test dataset needs to be adjusted to work with this flow version."
        footerAction={{
          onClick: handleEditDataset,
          text: "Edit dataset",
        }}
        placement="top"
        title="Incompatible test dataset"
        onOpenChange={onOpenTooltip}
      >
        <Pill variant="yellow" onClick={handleEditDataset}>
          <Pill.Icon icon={faWarning} />
          <Pill.Text>Incompatible</Pill.Text>
        </Pill>
      </Tooltip>
    </m.div>
  );
};
