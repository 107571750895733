import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { useFieldArray } from "react-hook-form";
import { v4 } from "uuid";

import { FieldErrorsT } from "src/api/types";
import { Button } from "src/base-components/Button";
import { Junctions, Operators } from "src/clients/flow-api";
import { Branch } from "src/ruleNodeV2Editor/Branch";
import { RuleNodeV2FormT } from "src/ruleNodeV2Editor/types";

type PropsT = {
  immutable: boolean;
  fieldErrors: FieldErrorsT | undefined;
  hideActionButtons?: boolean;
};

export const Branches: React.FC<PropsT> = ({
  immutable,
  fieldErrors,
  hideActionButtons,
}) => {
  const {
    fields: branches,
    append,
    remove,
    move,
  } = useFieldArray<RuleNodeV2FormT>({
    name: "branches",
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const fieldToMove = branches.findIndex((field) => field.id === active.id);
      const fieldToInsert = branches.findIndex((field) => field.id === over.id);
      if (fieldToMove !== -1 && fieldToInsert !== -1) {
        move(fieldToMove, fieldToInsert);
      }
    }
  };

  return (
    <>
      <DndContext onDragEnd={handleDragEnd}>
        <SortableContext
          disabled={immutable}
          items={branches.map((param) => param.id)}
        >
          {branches.map((branch, index) => (
            <Branch
              key={branch.id}
              fieldErrors={fieldErrors}
              hideActionButtons={hideActionButtons}
              id={branch.id}
              immutable={immutable}
              index={index}
              onRemove={() => remove(index)}
            />
          ))}
        </SortableContext>
      </DndContext>
      {!hideActionButtons && (
        <Button
          dataLoc="rule-node-v2-add-rule"
          disabled={immutable}
          iconLeft={faPlus}
          size="sm"
          variant="secondary"
          onClick={() =>
            append({
              id: v4(),
              effects: [],
              junction: Junctions.AND,
              clauses: [
                {
                  id_left: v4(),
                  id_right: v4(),
                  left: "",
                  right: "",
                  operator: Operators.EQ,
                },
              ],
            })
          }
        >
          Add rule
        </Button>
      )}
    </>
  );
};
