import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { v4 as uuidV4 } from "uuid";

import { DecisionsOutcomeFilter } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import {
  informPreparingDownload,
  pollAndNotifyDownloadSuccess,
} from "src/api/downloadUtils";
import { ExporterDatasetJobsEndpoint } from "src/api/endpoints";
import { SplitButton } from "src/base-components/SplitButton";
import { toastActions } from "src/design-system/Toast/utils";
import { useFlowContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";

type PossibleValues = "csv" | "json";

type Props = {
  downloadDetails?: {
    flowSlug: string;
    flowVersionIds: string[];
    timeWindow: [string, string];
    statusCodes: string[];
    trafficPolicyId?: string;
    subSamplingSize?: number;
    outcomeFilters?: DecisionsOutcomeFilter[];
  };
  disabled: boolean;
};

const elements: { key: PossibleValues; value: string }[] = [
  { key: "csv", value: "Download CSV" },
  { key: "json", value: "Download JSON" },
];

export const DownloadButton: React.FC<Props> = ({
  disabled,
  downloadDetails,
}) => {
  const [downloadFormat, setDownloadFormat] = useState<PossibleValues>("csv");
  const [isDownloading, setIsDownloading] = useState(false);
  const { flow, workspace } = useFlowContext();

  const onClick = async () => {
    if (downloadDetails) {
      const toastId = uuidV4();
      try {
        setIsDownloading(true);
        informPreparingDownload(toastId);
        const downloadJob =
          await ExporterDatasetJobsEndpoint.createExportDecisionsDatasetJob(
            workspace.base_url!,
            {
              flow_id: flow.id,
              request: {
                flow_slug: flow.slug,
                flow_versions: downloadDetails.flowVersionIds,
                start_date: downloadDetails.timeWindow[0],
                end_date: downloadDetails.timeWindow[1],
                status_codes: downloadDetails.statusCodes,
                traffic_policy_id: downloadDetails.trafficPolicyId,
                format: downloadFormat,
                sub_sampling_size: downloadDetails.subSamplingSize,
                outcome_filters: downloadDetails.outcomeFilters,
              },
            },
          );
        await pollAndNotifyDownloadSuccess(
          downloadJob,
          workspace.base_url!,
          toastId,
          ExporterDatasetJobsEndpoint.getDatasetJob,
        );
      } catch (err) {
        logger.error("Failed to create download dataset job", err);
        toastActions.failure({
          id: toastId,
          title: `Something went wrong when downloading the run results`,
        });
      } finally {
        setIsDownloading(false);
      }
    }
  };
  return (
    <SplitButton
      disabled={disabled}
      elements={elements}
      loading={isDownloading}
      prefixIcon={faArrowDownToLine}
      selected={downloadFormat}
      onClick={onClick}
      onSelect={(key) => setDownloadFormat(key as PossibleValues)}
    />
  );
};
