import {
  faChevronDown,
  faChevronRight,
  faEdit,
} from "@fortawesome/pro-regular-svg-icons";
import { faUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";

import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { useWorkspaceUsers } from "src/api/taktile/queries";
import { Button } from "src/base-components/Button";
import { Card } from "src/base-components/Card";
import { ExternalLink } from "src/base-components/ExternalLink";
import { Icon } from "src/base-components/Icon";
import { Avatar } from "src/design-system/Avatar";
import { Tooltip } from "src/design-system/Tooltip";
import { useGetChildVersionLink } from "src/parentFlowNodes/useGetChildVersionLink";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { formatDate } from "src/utils/datetime";
import { VersionStatusDotIndicator } from "src/utils/flowVersion";

const LastEdited: React.FC<{ version: FlowVersionT }> = ({ version }) => {
  const { orgId, workspace } = useAuthoringContext();
  const users = useWorkspaceUsers(orgId, workspace.id, {
    include_deactivated: true,
  });
  const lastEditedByUser =
    users.data && users.data.find((u) => u.id === version.meta.updated_by_id);

  if (!lastEditedByUser) {
    return <></>;
  }
  return (
    <div className="flex flex-row items-center gap-x-1">
      <p className="text-gray-600 font-inter-normal-12px">Edited by</p>
      <div className="flex items-center gap-x-1">
        <Avatar size="lg" user={lastEditedByUser} />
        <p className="text-gray-800 font-inter-medium-12px">
          {lastEditedByUser.full_name}
        </p>
      </div>
      <p className="text-gray-600 font-inter-normal-12px">on</p>
      <p className="text-gray-800 font-inter-medium-12px">
        {formatDate(version.updated_at)}
      </p>
    </div>
  );
};

export const SelectedVersionDisplay: React.FC<{
  childFlow: FlowT;
  childFlowVersionId: string | undefined;
  immutable: boolean;
  handleChangeDecisionFlow: () => void;
}> = ({
  childFlow,
  childFlowVersionId,
  handleChangeDecisionFlow,
  immutable,
}) => {
  const [expanded, setExpanded] = useState(false);
  const version = childFlow.versions.find((v) => v.id === childFlowVersionId);
  const { orgId, workspace } = useAuthoringContext();
  const getChildVersionLink = useGetChildVersionLink();

  if (!version) {
    return <></>;
  }

  return (
    <Card>
      <Card.Header>
        <div className="flex flex-row items-center gap-x-1">
          <Icon
            color="text-gray-500"
            icon={expanded ? faChevronDown : faChevronRight}
            size="xs"
            onClick={() => setExpanded(!expanded)}
          />
          <VersionStatusDotIndicator size="2xs" version={version} />
          <p
            className="text-gray-800 font-inter-semibold-14px"
            data-loc="loop-selected-version-display-header"
          >
            {childFlow.name}
            {", "}
            {version?.name}
          </p>
        </div>

        <Tooltip placement="bottom-end" title="Open in new tab" asChild>
          <div>
            <ExternalLink
              className="text-gray-500"
              href={getChildVersionLink({
                orgId,
                workspaceId: workspace.id,
                flowId: childFlow.id,
                versionId: version.id,
              })}
            >
              <Icon icon={faUpRightFromSquare} size="xs" />
            </ExternalLink>
          </div>
        </Tooltip>
      </Card.Header>
      {expanded && (
        <div className="space-y-1 pt-1">
          <p className="text-gray-500 font-inter-normal-12px">
            {!!childFlow.meta.description
              ? childFlow.meta.description
              : "No description"}
          </p>
          <LastEdited version={version} />
        </div>
      )}
      <div className="mt-3">
        <Button
          disabled={immutable}
          iconLeft={faEdit}
          size="sm"
          variant="secondary"
          onClick={handleChangeDecisionFlow}
        >
          Change Decision Flow
        </Button>
      </div>
    </Card>
  );
};
