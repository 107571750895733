import { observer } from "mobx-react-lite";

import { FlowChartModal } from "src/analytics/ChartModal/FlowChartModal";
import { NodeChartModal } from "src/analytics/ChartModal/NodeChartModal";
import {
  useChartModalActions,
  useChartModalChart,
  useChartModalNodeId,
  useIsChartModalOpen,
} from "src/analytics/ChartModal/store";
import {
  useComparativeAnalyticsData,
  useNodeAnalyticsData,
} from "src/analytics/api";
import { useGraphStore } from "src/store/StoreProvider";

export const ChartModal: React.FC = () => {
  return (
    <>
      <NodeChartModalWrapper />
      <FlowChartModalWrapper />
    </>
  );
};

const NodeChartModalWrapper: React.FC = observer(() => {
  const isOpen = useIsChartModalOpen("node");
  const { close, afterLeave } = useChartModalActions();

  const { nodes } = useGraphStore();
  const nodeId = useChartModalNodeId() ?? "";
  const node = nodes.get(nodeId);

  const { data: analytics } = useNodeAnalyticsData(nodeId);
  const chart = useChartModalChart();

  return (
    <NodeChartModal
      afterLeave={afterLeave}
      analytics={analytics}
      chart={chart}
      isOpen={isOpen && Boolean(node)}
      nodeId={nodeId}
      nodeName={node?.data.label ?? ""}
      onClose={close}
    />
  );
});

const FlowChartModalWrapper: React.FC<{}> = observer(() => {
  const isOpen = useIsChartModalOpen("flow");
  const { close, afterLeave } = useChartModalActions();

  const { data: analytics } = useComparativeAnalyticsData();
  const chart = useChartModalChart();

  return (
    <FlowChartModal
      afterLeave={afterLeave}
      analytics={analytics}
      chart={chart}
      isOpen={isOpen}
      onClose={close}
    />
  );
});
