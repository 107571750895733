import { getNamedOutputMapping } from "src/integrationNode/integrationResources/DefaultResourceConfig";

export enum CommonOutputMappingKey {
  PROVIDER_REQUEST_ID = "provider_request_id",
  PROVIDER_ENTITY_ID = "provider_entity_id",
  RETRIEVAL_DATETIME = "retrieval_datetime",
  PROVIDER_RESPONSE_TIME = "provider_response_time",
}
const commonOutputMappings = {
  [CommonOutputMappingKey.PROVIDER_REQUEST_ID]: () =>
    getNamedOutputMapping(
      "Provider request ID",
      "A unique, provider-generated identifier for the request which can be used for troubleshooting",
    ),
  [CommonOutputMappingKey.PROVIDER_ENTITY_ID]: () =>
    getNamedOutputMapping("Provider entity ID"),
  [CommonOutputMappingKey.RETRIEVAL_DATETIME]: () =>
    getNamedOutputMapping("Retrieval date-time (UTC)"),
};

const providerLatencyMapping = {
  [CommonOutputMappingKey.PROVIDER_RESPONSE_TIME]: () =>
    getNamedOutputMapping(
      "Provider latency",
      "The time taken by the provider to respond to the request",
    ),
};

export const getCommonOutputMappings = (
  ...keys: CommonOutputMappingKey[]
): {
  [key in CommonOutputMappingKey]?: ReturnType<typeof getNamedOutputMapping>;
} => {
  const result: {
    [key in CommonOutputMappingKey]?: ReturnType<typeof getNamedOutputMapping>;
  } = {};
  result[CommonOutputMappingKey.PROVIDER_RESPONSE_TIME] =
    providerLatencyMapping[CommonOutputMappingKey.PROVIDER_RESPONSE_TIME]();

  keys.forEach((key) => {
    if (key in commonOutputMappings) {
      result[key] =
        commonOutputMappings[key as keyof typeof commonOutputMappings]();
    }
  });

  return result;
};
