import {
  faRefresh,
  faSave,
  faArrowDownToLine,
  faSpinner,
} from "@fortawesome/pro-regular-svg-icons";
import { SelectRangeEventHandler } from "react-day-picker";

import { Button } from "src/base-components/Button";
import {
  DateRange,
  DateRangePicker,
} from "src/base-components/DateRangePicker";
import { Icon } from "src/base-components/Icon";
import { SearchBox } from "src/base-components/SearchBox";
import { Tooltip } from "src/design-system/Tooltip";

type PropsT = {
  onTermChange: (term: string) => void;
  onDateRangePickerChange: SelectRangeEventHandler;
  onClickRefresh: () => void;
  isRefreshing: boolean;
  onClickSave: () => void;
  isSaving: boolean;
  disableSave?: "unavailable" | "unsaveable_dataset";
  onClickDownload: () => void;
  isDownloading: boolean;
  disableDownload: boolean;
  selectedTab: "sandbox" | "live";
  initialSearchTerm: string;
  dateRangePickerValue?: DateRange;
};

export const Filters: React.FC<PropsT> = ({
  onTermChange,
  onClickRefresh,
  isRefreshing,
  isSaving,
  isDownloading,
  onClickSave,
  onClickDownload,
  disableDownload,
  disableSave,
  selectedTab,
  initialSearchTerm,
  onDateRangePickerChange,
  dateRangePickerValue,
}) => {
  return (
    <div className="px-6">
      <div className="mb-4 flex flex-row items-center gap-2">
        <Tooltip
          placement="top"
          title="Search by Decision ID or Entity ID"
          asChild
        >
          <div className="w-full cursor-default">
            <SearchBox
              defaultValue={initialSearchTerm}
              placeholder="Search..."
              onChange={onTermChange}
            />
          </div>
        </Tooltip>
        <div className="w-[206px] flex-shrink-0">
          <DateRangePicker
            value={dateRangePickerValue}
            onChange={onDateRangePickerChange}
          />
        </div>
        <Tooltip placement="top" title="Refresh" asChild>
          <span className="inline-block h-8 w-8">
            <Button
              dataLoc="refresh-button"
              disabled={isRefreshing}
              variant="secondary"
              fullWidth
              onClick={() => onClickRefresh()}
            >
              <Icon icon={faRefresh} size="2xs" />
            </Button>
          </span>
        </Tooltip>
        {selectedTab === "live" && (
          <>
            <Tooltip placement="top" title="Download as CSV dataset" asChild>
              <span className="inline-block h-8 w-8">
                <Button
                  disabled={disableDownload}
                  variant="secondary"
                  fullWidth
                  onClick={() => onClickDownload()}
                >
                  <Icon
                    icon={isDownloading ? faSpinner : faArrowDownToLine}
                    size="2xs"
                    spin={isDownloading}
                  />
                </Button>
              </span>
            </Tooltip>
            <Tooltip
              placement="right"
              title={
                disableSave === "unsaveable_dataset"
                  ? "Please try adjusting the date filter to be able to save a dataset. Data from before 1 Apr 2023 cannot be used in a dataset"
                  : "Save all as test dataset"
              }
              asChild
            >
              <span className="inline-block h-8 w-8">
                <Button
                  disabled={!!disableSave}
                  variant="secondary"
                  fullWidth
                  onClick={() => onClickSave()}
                >
                  <Icon
                    icon={isSaving ? faSpinner : faSave}
                    size="2xs"
                    spin={isSaving}
                  />
                </Button>
              </span>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};
