import { noop } from "lodash";
import mergeRefs from "merge-refs";
import React, { ChangeEvent, useEffect, useRef } from "react";
import { twJoin } from "tailwind-merge";

type InputPropsT = React.HTMLProps<HTMLInputElement> & {
  id?: string;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  dataLoc?: string;

  /**
   * Indeterminate state takes visual precedence over the checked state
   * This means that if both `checked` and `indeterminate` are true,
   * the checkbox will be in indeterminate state.
   */
  indeterminate?: boolean;
};

export const Checkbox = React.forwardRef<HTMLInputElement, InputPropsT>(
  (
    {
      id,
      checked,
      onChange,
      className,
      dataLoc,
      indeterminate = false,
      ...props
    },
    ref,
  ) => {
    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (!checkboxRef.current) return;
      checkboxRef.current.indeterminate = indeterminate;
    }, [indeterminate]);

    return (
      <input
        ref={mergeRefs(ref, checkboxRef)}
        checked={checked}
        className={twJoin(
          "h-3 w-3 rounded-sm border border-gray-400 text-gray-0 accent-indigo-600",
          props.disabled && "cursor-not-allowed",
          className,
        )}
        data-loc={dataLoc}
        id={id}
        type="checkbox"
        onChange={props.readOnly ? noop : onChange}
        {...props}
      />
    );
  },
);
