import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  InputMappingGroupT,
  InputMappingListT,
  InputMappingT,
  IntegrationResourceT,
} from "src/integrationNode/types";

const getDefaultExperianGermanyBusinessInformationGatewayAddress = (): {
  [key: string]: InputMappingT;
} => ({
  is_current: {
    id: uuidV4(),
    type: "text",
    displayName: "Is current?",
    hint: `True/False. If multiple addresses are provided, this field must be used to indicate which is the current address.`,
    assignedTo: "",
    rules: {
      required: false,
    },
  },
  house_number: {
    id: uuidV4(),
    type: "text",
    displayName: "House number",
    assignedTo: "",
    rules: {
      required: false,
      maxLength: 30,
    },
  },
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
    rules: {
      required: true,
      maxLength: 100,
    },
  },
  address_supplement: {
    id: uuidV4(),
    type: "text",
    displayName: "Address supplement",
    assignedTo: "",
    rules: {
      required: false,
      maxLength: 30,
    },
    hint: `Further address supplements such as c/o or building information`,
  },
  zip_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Zip code",
    assignedTo: "",
    rules: {
      required: true,
      maxLength: 10,
    },
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: {
      required: true,
      maxLength: 50,
    },
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    rules: {
      required: false,
      maxLength: 50,
    },
  },
  region: {
    id: uuidV4(),
    type: "text",
    displayName: "Region",
    assignedTo: "",
    rules: {
      required: false,
      maxLength: 50,
    },
  },
});

const getDefaultExperianGermanyBusinessInformationGatewayAddressList =
  (): InputMappingListT => ({
    displayName: "Address",
    getDefaultElement:
      getDefaultExperianGermanyBusinessInformationGatewayAddress,
    elements: [],
  });

const getDefaultExperianGermanyBusinessInformationGatewayPhoneNumber =
  (): InputMappingGroupT => ({
    getDefaultElements: () => ({
      phone_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Phone number",
        assignedTo: "",
        rules: {
          required: false,
        },
      },
      phone_type: {
        id: uuidV4(),
        type: "text",
        displayName: "Phone type",
        assignedTo: "",
        hint: "One of: 'Phone', 'Mobile', or 'Fax'",
        rules: {
          required: false,
        },
      },
    }),
    displayName: "Phone number",
    rules: {
      required: false,
    },
  });

const getDefaultExperianGermanyBusinessInformationGatewayBankAccount =
  (): InputMappingGroupT => ({
    getDefaultElements: () => ({
      bank_code: {
        id: uuidV4(),
        type: "text",
        displayName: "Bank code",
        assignedTo: "",
        hint: `The bank code (BLZ) of the bank account. Please provide either Bank Code and Account Number or IBAN and BIC (optional). Do not provide both sets of information.`,
        rules: {
          required: false,
          maxLength: 8,
        },
      },
      bank_account: {
        id: uuidV4(),
        type: "text",
        displayName: "Account number",
        assignedTo: "",
        hint: `The account number of the bank account. Please provide either Bank Code and Account Number or IBAN and BIC (optional). Do not provide both sets of information.`,
        rules: {
          required: false,
          maxLength: 10,
        },
      },
      iban: {
        id: uuidV4(),
        type: "text",
        displayName: "IBAN",
        assignedTo: "",
        hint: `The IBAN of the bank account. Please provide either Bank Code and Account Number or IBAN and BIC (optional). Do not provide both sets of information.`,
        rules: {
          required: false,
          maxLength: 42,
        },
      },
      bic: {
        id: uuidV4(),
        type: "text",
        displayName: "BIC",
        hint: `The BIC of the bank account. Please provide either Bank Code and Account Number or IBAN and BIC (optional). Do not provide both sets of information.`,
        assignedTo: "",
        rules: {
          required: false,
          maxLength: 11,
        },
      },
    }),
    displayName: "Bank account",
    rules: {
      required: false,
    },
  });

export const getDefaultExperianGermanyBusinessInformationGateway =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "experian_germany",
      resource: "experian_germany_business_information_gateway",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        customer_reference: {
          id: uuidV4(),
          type: "text",
          displayName: "Customer reference",
          assignedTo: "",
          rules: {
            required: false,
            maxLength: 50,
          },
          hint: `Anything you input here will be present in the API response from this provider. This can help you match a request to a response.`,
        },
        title: {
          id: uuidV4(),
          type: "text",
          displayName: "Title",
          assignedTo: "",
          rules: {
            required: false,
            maxLength: 30,
          },
          hint: `Title of nobility and/or academic title of the person inquired about`,
        },
        salutation: {
          id: uuidV4(),
          type: "text",
          displayName: "Salutation",
          assignedTo: "",
          rules: {
            required: false,
          },
          hint: "One of: 'MR', 'MS', 'DIVERSE', 'COMPANY', 'UNKNOWN'",
        },
        first_name: {
          id: uuidV4(),
          type: "text",
          displayName: "First name",
          assignedTo: "",
          hint: `For requests with Salutation = 'COMPANY' this field should be empty`,
          rules: {
            required: false,
            maxLength: 50,
          },
        },
        last_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Last name",
          assignedTo: "",
          hint: `The individual's last name. Any name affixes (von, van, Mc, etc.) are likewise stated in this field. For requests with Salutation = 'COMPANY', the entire company name is stated in this field.`,
          rules: {
            required: true,
            maxLength: 50,
          },
        },
        date_of_birth: {
          id: uuidV4(),
          type: "text",
          displayName: "Date of birth",
          assignedTo: "",
          hint: `The date of birth in format "YYYY-MM-DD"`,
          rules: {
            required: false,
          },
        },
        customer_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Customer ID",
          assignedTo: "",
          rules: {
            required: false,
          },
        },
        email: {
          id: uuidV4(),
          type: "text",
          displayName: "Email address",
          assignedTo: "",
          rules: {
            required: false,
          },
        },
      },
      grouped: {
        bank_account:
          getDefaultExperianGermanyBusinessInformationGatewayBankAccount(),
        phone_number:
          getDefaultExperianGermanyBusinessInformationGatewayPhoneNumber(),
      },
      lists: {
        addresses:
          getDefaultExperianGermanyBusinessInformationGatewayAddressList(),
      },
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(
          CommonOutputMappingKey.PROVIDER_REQUEST_ID,
          CommonOutputMappingKey.RETRIEVAL_DATETIME,
        ),
        delphi_score: {
          ...getNamedOutputMapping("Delphi score"),
          hint: `The Consumer Delphi score is a score that predicts the likelihood of a consumer defaulting on a payment. Scores greater than 475 indicate a probability of default of less than 4%. See our docs for more information.`,
        },
        credit_feature_codes: {
          ...getNamedOutputMapping("Credit feature codes"),
          hint: `A list of credit feature codes that describe any potential negative features in the consumer's credit history. See our docs for more information.`,
        },
      },
    },
    config: defaultResourceConfig,
  });
