import { isAxiosError } from "axios";

import { toastActions } from "src/design-system/Toast/utils";
import { errorMessage } from "src/utils/stringUtils";

export const toastError = (
  err: unknown,
  options?: {
    title?: string;
    id?: string;
    dismissable?: boolean;
  },
) => {
  // Toast errors
  // Errors found here from toast
  toastActions.failure({
    title: options?.title || "Error",
    description: errorMessage(err),
    id: options?.id,
    dismissible: options?.dismissable,
  });
};

/**
 * Higher order function that calls toastError if the
 * supplied function raises an error during execution.
 */
export const wrapWithErrorHandler = <
  Fn extends (...args: any[]) => Promise<any>,
>(
  fn: Fn,
): Fn => {
  const wrappedFun = ((...args: any[]) => {
    return fn(...args).catch((err) => {
      toastError(err);
      throw err;
    });
  }) as Fn;

  // Copy additional properties from fn to the wrapped function
  for (const prop in fn) {
    if (Object.hasOwn(fn, prop)) {
      wrappedFun[prop] = fn[prop];
    }
  }

  return wrappedFun;
};

/**
 * Higher order function that calls toastError if the
 * supplied function raises an error during execution
 * while giving details in case the error is an AxiosError.
 */
export const wrapWithAxiosResponseErrorHandler = <
  Fn extends (...args: any[]) => Promise<any>,
>(
  fn: Fn,
): Fn => {
  const wrappedFun = ((...args: any[]) => {
    return fn(...args).catch((err) => {
      if (isAxiosError(err) && err.response?.status !== undefined) {
        toastError(errorMessage(err), {
          title: `Error ${err.response.status}`,
        });
        throw Error(err.message);
      }
      toastError(errorMessage(err));
      throw err;
    });
  }) as Fn;

  // Copy additional properties from fn to the wrapped function
  for (const prop in fn) {
    if (Object.hasOwn(fn, prop)) {
      wrappedFun[prop] = fn[prop];
    }
  }

  return wrappedFun;
};
