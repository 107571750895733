import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  InputMappingGroupT,
  InputMappingListT,
  InputMappingT,
  InputMappingsT,
  IntegrationResourceT,
} from "src/integrationNode/types";

// Simple Requests

const getDefaultCreditreformSimpleInputs = (): InputMappingsT => ({
  ungrouped: {
    customer_reference: {
      id: uuidV4(),
      type: "text",
      displayName: "Customer reference",
      assignedTo: "",
      hint: `Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.`,
    },
    creditreform_company_id: {
      id: uuidV4(),
      type: "text",
      displayName: "Company ID",
      assignedTo: "",
      rules: {
        required: true,
      },
      hint: `Every company in the Creditreform database has a unique identifier. This ID is used in many reports to fetch a company's credit information (instead of company details).`,
    },
  },
  grouped: {},
  lists: {},
});

export const getDefaultCreditreformCompactReport =
  (): IntegrationResourceT => ({
    providerResource: { provider: "creditreform", resource: "compact_report" },
    connectionId: "",
    resourceConfigId: "",
    input: getDefaultCreditreformSimpleInputs(),
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(
          CommonOutputMappingKey.PROVIDER_REQUEST_ID,
          CommonOutputMappingKey.PROVIDER_ENTITY_ID,
          CommonOutputMappingKey.RETRIEVAL_DATETIME,
        ),
        probability_of_default: {
          ...getNamedOutputMapping("Probability of default"),
          hint: `Shows the assessment of the probability that a debtor defaults within one year according to Basel II criteria. Applied to Creditreform, the solvency indices 500 and 600 are considered as default.`,
        },
        probability_of_default_average: {
          ...getNamedOutputMapping("Probability of default average"),
          hint: `The average probability-of-default value of all companies in the request company's country.`,
        },
        solvency_index: {
          ...getNamedOutputMapping("Solvency index"),
          hint: `An integer score between 100 (excellent solvency) and 600 (default) that maps to a company's probability of default. Please refer to our docs for a detailed explanation.`,
        },
        date_of_assessment: {
          ...getNamedOutputMapping("Date of assessment"),
          hint: `The date on which the probability of default was assessed.`,
        },
        pdf_report: {
          ...getNamedOutputMapping("PDF report"),
          hint: `The PDF representation of this report as a base64 encoded string. To retrieve the pdf file, decode the string and save it as a .pdf file.`,
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultCreditreformTrafficLightReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "creditreform",
      resource: "traffic_light_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: getDefaultCreditreformSimpleInputs(),
    output: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.PROVIDER_ENTITY_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      default: getDefaultOutputMapping(),
      insights: {
        traffic_light_solvency: {
          ...getNamedOutputMapping("Traffic light solvency"),
          hint: `The traffic light gives a simple Red (probability of default > 6.7% next 12 months), Yellow (0.6% - 6.7%), and Green (<0.6%). Any other response indicates that a probability of default was impossible to calculate for this company.`,
        },
        solvency_index_range: getNamedOutputMapping("Solvency index range"),
        solvency_index_range_meaning: getNamedOutputMapping(
          "Solvency index range meaning",
        ),
        pdf_report: {
          ...getNamedOutputMapping("PDF report"),
          hint: `The PDF representation of this report as a base64 encoded string. To retrieve the pdf file, decode the string and save it as a .pdf file.`,
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultCreditreformBriefReport = (): IntegrationResourceT => ({
  providerResource: {
    provider: "creditreform",
    resource: "brief_report",
  },
  connectionId: "",
  resourceConfigId: "",
  input: getDefaultCreditreformSimpleInputs(),
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.PROVIDER_ENTITY_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      traffic_light_solvency: {
        ...getNamedOutputMapping("Traffic light solvency"),
        hint: `The traffic light gives a simple Red (probability of default > 6.7% next 12 months), Yellow (0.6% - 6.7%), and Green (<0.6%). Any other response indicates that a probability of default was impossible to calculate for this company.`,
      },
      credit_limit: {
        ...getNamedOutputMapping("Credit limit"),
        hint: `A suggested maximum credit limit to grant this company, based on their probability of default and other factors.`,
      },
      credit_limit_currency: getNamedOutputMapping("Credit limit currency"),
      solvency_class: {
        ...getNamedOutputMapping("Solvency class"),
        hint: `Each solvency class pertains to a range of probability of defaults, with class 1 being the best and 6 indicating a very high risk. Refer to our docs for more details, or use the "solvency class meaning" insight.`,
      },
      solvency_class_meaning: {
        ...getNamedOutputMapping("Solvency class meaning"),
        hint: `An explanation of the solvency class given for this company. See our docs for more information.`,
      },
      solvency_class_explanation: getNamedOutputMapping(
        "Solvency class explanation",
      ),
      probability_of_default_range: {
        ...getNamedOutputMapping("Probability of default range"),
        hint: `A fine-grained range into which the probability of default for this company falls (e.g., "0 % - 0,3 %”).`,
      },
      pdf_report: {
        ...getNamedOutputMapping("PDF report"),
        hint: `The PDF representation of this report as a base64 encoded string. To retrieve the pdf file, decode the string and save it as a .pdf file.`,
      },
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultCreditreformCommercialReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "creditreform",
      resource: "commercial_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: getDefaultCreditreformSimpleInputs(),
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(
          CommonOutputMappingKey.PROVIDER_REQUEST_ID,
          CommonOutputMappingKey.PROVIDER_ENTITY_ID,
          CommonOutputMappingKey.RETRIEVAL_DATETIME,
        ),
        traffic_light_solvency: {
          ...getNamedOutputMapping("Traffic light solvency"),
          hint: `The traffic light gives a simple Red (probability of default > 6.7% next 12 months), Yellow (0.6% - 6.7%), and Green (<0.6%). Any other response indicates that a probability of default was impossible to calculate for this company.`,
        },
        credit_limit: {
          ...getNamedOutputMapping("Credit limit"),
          hint: `A suggested maximum credit limit to grant this company, based on their probability of default and other factors.`,
        },
        credit_limit_currency: getNamedOutputMapping("Credit limit currency"),
        solvency_class: {
          ...getNamedOutputMapping("Solvency class"),
          hint: `Each solvency class pertains to a range of probability of defaults, with class 1 being the best and 6 indicating a very high risk. Refer to our docs for more details, or use the "Solvency class meaning" insight.`,
        },
        solvency_class_meaning: {
          ...getNamedOutputMapping("Solvency class meaning"),
          hint: `An explanation of the solvency class given for this company. See our docs for more information.`,
        },
        solvency_class_explanation: getNamedOutputMapping(
          "Solvency class explanation",
        ),
        probability_of_default_range: {
          ...getNamedOutputMapping("Probability of default range"),
          hint: `A fine-grained range into which the probability of default for this company falls (e.g., "0 % - 0,3 %”).`,
        },
        pdf_report: {
          ...getNamedOutputMapping("PDF report"),
          hint: `The PDF representation of this report as a base64 encoded string. To retrieve the pdf file, decode the string and save it as a .pdf file.`,
        },
      },
    },
    config: defaultResourceConfig,
  });

// address reports

const getDefaultCreditreformAddress = (): { [key: string]: InputMappingT } => ({
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
  },
  house_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Number",
    assignedTo: "",
  },
  affix: {
    id: uuidV4(),
    type: "text",
    displayName: "Affix",
    assignedTo: "",
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
  },
  country: {
    id: uuidV4(),
    type: "text",
    displayName: "Country code",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g, "DE" for Germany)`,
  },
});

const getDefaultCreditreformAddressList = (): InputMappingListT => ({
  displayName: "Address",
  getDefaultElement: getDefaultCreditreformAddress,
  elements: [getDefaultCreditreformAddress()],
});

const getDefaultCreditreformPhone = (): InputMappingGroupT => ({
  getDefaultElements: () => ({
    number: {
      id: uuidV4(),
      type: "text",
      displayName: "Number",
      assignedTo: "",
    },
    dialling_code: {
      id: uuidV4(),
      type: "text",
      displayName: "Dialling code",
      assignedTo: "",
      hint: `A prefix to the phone number that connects to a geographical or logical location (in German: "Vorwahl"), e.g., "030".`,
    },
  }),
});

export const getDefaultCreditreformRiskCheckReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "creditreform",
      resource: "risk_check_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        customer_reference: {
          id: uuidV4(),
          type: "text",
          displayName: "Customer reference",
          assignedTo: "",
          hint: `Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.`,
        },
        company_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Company name",
          assignedTo: "",
          rules: {
            required: true,
          },
          hint: `For best results, use the full legal name with legal name including legal form.`,
        },
        creditreform_company_register_type: {
          id: uuidV4(),
          type: "text",
          displayName: "Creditreform company register type",
          assignedTo: "",
          hint: `A Creditreform acronym describing the type of company register where the underlying legal entity has been registered. Possible values are: “RETY-DE-1” (”Handelsregister Abteilung A”), “RETY-DE-2” (”Handelsregister Abteilung B”), “RETY-DE-3” (”Partnerschaftsregister”), “RETY-DE-4” (”Genossenschaftsregister”), and “RETY-DE-5” (”Vereinsregister”).`,
        },
        company_register_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Company register ID",
          assignedTo: "",
          hint: `Identification number that the company register uses to uniquely identify the underlying legal entity.`,
        },
        creditreform_legal_form: {
          id: uuidV4(),
          type: "text",
          displayName: "Creditreform legal form",
          assignedTo: "",
          hint: `A Creditreform acronym for the company's legal form.`,
        },
        website_url: {
          id: uuidV4(),
          type: "text",
          displayName: "Website URL",
          assignedTo: "",
          hint: `For best results include “www.” and the proper domain suffix, e.g. ".com", ".io"`,
        },
        vat_id: {
          id: uuidV4(),
          type: "text",
          displayName: "VAT ID",
          assignedTo: "",
        },
      },
      grouped: { phone: getDefaultCreditreformPhone() },
      lists: { addresses: getDefaultCreditreformAddressList() },
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(
          CommonOutputMappingKey.PROVIDER_REQUEST_ID,
          CommonOutputMappingKey.PROVIDER_ENTITY_ID,
          CommonOutputMappingKey.RETRIEVAL_DATETIME,
        ),
        creditreform_company_id: {
          ...getNamedOutputMapping("Creditreform company ID"),
          hint: `Every company in the Creditreform database has a unique identifier. This ID is used in many reports to fetch a company's credit information (instead of company details).`,
        },
        black_and_white_solvency: {
          ...getNamedOutputMapping("Black-and-white solvency"),
          hint: `A very coarse categorisation of a company's probability of default in the next twelve months: A 'black' response means that there is a PD > 8%. a 'white' response indicates a PD < 8%. This report can be used to quickly disqualify very risky credit applicants, and can then be used in conjunction with more expensive reports to more closely understand their PD.`,
        },
        pdf_report: {
          ...getNamedOutputMapping("PDF report"),
          hint: `The PDF representation of this report as a base64 encoded string. To retrieve the pdf file, decode the string and save it as a .pdf file.`,
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultCreditreformSearchRequest =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "creditreform",
      resource: "search_request",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        creditreform_search_type: {
          id: uuidV4(),
          type: "dropDown",
          displayName: "Creditreform search type",
          elements: [
            { key: `"SETY-1"`, value: "SETY-1" },
            { key: `"SETY-2"`, value: "SETY-2" },
          ],
          assignedTo: `"SETY-1"`,
          rules: {
            required: true,
          },
          hint: `Search requests can be made using two different search types: SETY-1 = broad match, SETY-2 = close match`,
        },
        creditreform_company_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Company ID",
          assignedTo: "",
          hint: `Every company in the Creditreform database has a unique identifier. This ID is used in many reports to fetch a company's credit information (instead of company details).`,
        },
        company_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Company name",
          assignedTo: "",
          hint: `For best results, use the full legal name with legal name including legal form.`,
        },
        creditreform_company_register_type: {
          id: uuidV4(),
          type: "text",
          displayName: "Creditreform company register type",
          assignedTo: "",
          hint: `A Creditreform acronym describing the type of company register where the underlying legal entity has been registered. Possible values are: “RETY-DE-1” (”Handelsregister Abteilung A”), “RETY-DE-2” (”Handelsregister Abteilung B”), “RETY-DE-3” (”Partnerschaftsregister”), “RETY-DE-4” (”Genossenschaftsregister”), and “RETY-DE-5” (”Vereinsregister”).`,
        },
        company_register_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Company register ID",
          assignedTo: "",
          hint: `Identification number that the company register uses to uniquely identify the underlying legal entity.`,
        },
        creditreform_legal_form: {
          id: uuidV4(),
          type: "text",
          displayName: "Creditreform legal form",
          assignedTo: "",
          hint: `A Creditreform acronym for the company's legal form.`,
        },
        website_url: {
          id: uuidV4(),
          type: "text",
          displayName: "Website URL",
          assignedTo: "",
          hint: `For best results include “www.” and the proper domain suffix, e.g. ".com", ".io"`,
        },
        email: {
          id: uuidV4(),
          type: "text",
          displayName: "Email",
          assignedTo: "",
          hint: `The central email address for the company. Often a "info@xx.com" style address.`,
        },
        vat_id: {
          id: uuidV4(),
          type: "text",
          displayName: "VAT ID",
          assignedTo: "",
        },
      },
      grouped: { phone: getDefaultCreditreformPhone() },
      lists: { addresses: getDefaultCreditreformAddressList() },
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(
          CommonOutputMappingKey.PROVIDER_REQUEST_ID,
          CommonOutputMappingKey.RETRIEVAL_DATETIME,
        ),
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultCreditreformIdentificationReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "creditreform",
      resource: "identification_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        customer_reference: {
          id: uuidV4(),
          type: "text",
          displayName: "Customer reference",
          assignedTo: "",
          hint: `Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.`,
        },
        company_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Company name",
          assignedTo: "",
          rules: {
            required: true,
          },
          hint: `For best results, use the full legal name with legal name including legal form.`,
        },
        creditreform_company_register_type: {
          id: uuidV4(),
          type: "text",
          displayName: "Creditreform company register type",
          assignedTo: "",
          hint: `A Creditreform acronym describing the type of company register where the underlying legal entity has been registered. Possible values are: “RETY-DE-1” (”Handelsregister Abteilung A”), “RETY-DE-2” (”Handelsregister Abteilung B”), “RETY-DE-3” (”Partnerschaftsregister”), “RETY-DE-4” (”Genossenschaftsregister”), and “RETY-DE-5” (”Vereinsregister”).`,
        },
        company_register_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Company register ID",
          assignedTo: "",
          hint: `Identification number that the company register uses to uniquely identify the underlying legal entity.`,
        },
        creditreform_legal_form: {
          id: uuidV4(),
          type: "text",
          displayName: "Creditreform legal form",
          assignedTo: "",
          hint: `A Creditreform acronym for the company's legal form.`,
        },
        website_url: {
          id: uuidV4(),
          type: "text",
          displayName: "Website URL",
          assignedTo: "",
          hint: `For best results include “www.” and the proper domain suffix, e.g. ".com", ".io"`,
        },
        vat_id: {
          id: uuidV4(),
          type: "text",
          displayName: "VAT ID",
          assignedTo: "",
        },
      },
      grouped: { phone: getDefaultCreditreformPhone() },
      lists: { addresses: getDefaultCreditreformAddressList() },
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(
          CommonOutputMappingKey.PROVIDER_REQUEST_ID,
          CommonOutputMappingKey.PROVIDER_ENTITY_ID,
          CommonOutputMappingKey.RETRIEVAL_DATETIME,
        ),
        creditreform_company_id: {
          ...getNamedOutputMapping("Creditreform company ID"),
          hint: `Every company in the Creditreform database has a unique identifier. This ID is used in many reports to fetch a company's credit information (instead of company details).`,
        },
        pdf_report: {
          ...getNamedOutputMapping("PDF report"),
          hint: `The PDF representation of this report as a base64 encoded string. To retrieve the pdf file, decode the string and save it as a .pdf file.`,
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultCreditreformEcrefoReport = (): IntegrationResourceT => ({
  providerResource: {
    provider: "creditreform",
    resource: "ecrefo_report",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      customer_reference: {
        id: uuidV4(),
        type: "text",
        displayName: "Customer reference",
        assignedTo: "",
        hint: `Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.`,
      },
      company_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Company name",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: `For best results, use the full legal name with legal name including legal form.`,
      },
      creditreform_company_register_type: {
        id: uuidV4(),
        type: "text",
        displayName: "Creditreform company register type",
        assignedTo: "",
        hint: `A Creditreform acronym describing the type of company register where the underlying legal entity has been registered. Possible values are: “RETY-DE-1” (”Handelsregister Abteilung A”), “RETY-DE-2” (”Handelsregister Abteilung B”), “RETY-DE-3” (”Partnerschaftsregister”), “RETY-DE-4” (”Genossenschaftsregister”), and “RETY-DE-5” (”Vereinsregister”).`,
      },
      company_register_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Company register ID",
        assignedTo: "",
        hint: `Identification number that the company register uses to uniquely identify the underlying legal entity.`,
      },
      creditreform_legal_form: {
        id: uuidV4(),
        type: "text",
        displayName: "Creditreform legal form",
        assignedTo: "",
        hint: `A Creditreform acronym for the company's legal form.`,
      },
      website_url: {
        id: uuidV4(),
        type: "text",
        displayName: "Website URL",
        assignedTo: "",
        hint: `For best results include “www.” and the proper domain suffix, e.g. ".com", ".io"`,
      },
      vat_id: {
        id: uuidV4(),
        type: "text",
        displayName: "VAT ID",
        assignedTo: "",
      },
      solvency_index_threshold: {
        id: uuidV4(),
        type: "text",
        displayName: "Solvency index threshold",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: `Allows you to set the threshold based on which solvency indices are divided into "good" vs. "bad" (in Creditreform's terminology: "white" vs. "black"). Possible values range between 100 and 500.`,
      },
    },
    grouped: { phone: getDefaultCreditreformPhone() },
    lists: { addresses: getDefaultCreditreformAddressList() },
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.PROVIDER_ENTITY_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      creditreform_company_id: {
        ...getNamedOutputMapping("Creditreform company ID"),
        hint: `Every company in the Creditreform database has a unique identifier. This ID is used in many reports to fetch a company's credit information (instead of company details).`,
      },
      black_and_white_solvency: {
        ...getNamedOutputMapping("Black-and-white solvency"),
        hint: `A very coarse categorisation of a company's probability of default in the next twelve months: A 'black' response means that there is a PD > 8%. a 'white' response indicates a PD < 8%. This report can be used to quickly disqualify very risky credit applicants, and can then be used in conjunction with more expensive reports to more closely understand their PD.`,
      },
      pdf_report: {
        ...getNamedOutputMapping("PDF report"),
        hint: `The PDF representation of this report as a base64 encoded string. To retrieve the pdf file, decode the string and save it as a .pdf file.`,
      },
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultCreditreformMailboxDirectory =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "creditreform",
      resource: "creditreform_mailbox_directory",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        creditreform_company_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Company ID",
          assignedTo: "",
          hint: "Include a company's unique identifier to limit the response to only include entries from a given company",
        },
        page_reference: {
          id: uuidV4(),
          type: "text",
          displayName: "Page reference",
          assignedTo: "",
          hint: `A string of integers to reference a page of a report. This value can be obtained from the "next_page_reference" insight in the response.`,
        },
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(
          CommonOutputMappingKey.PROVIDER_REQUEST_ID,
          CommonOutputMappingKey.RETRIEVAL_DATETIME,
        ),
        number_of_entries: getNamedOutputMapping("Number of entries"),
        has_omitted_entries: getNamedOutputMapping("Has omitted entries"),
        next_page_reference: getNamedOutputMapping("Next page reference"),
        first_mailbox_entry_number: getNamedOutputMapping(
          "First mailbox entry number",
        ),
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultCreditreformMailboxEntry = (): IntegrationResourceT => ({
  providerResource: {
    provider: "creditreform",
    resource: "creditreform_mailbox_entry",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      mailbox_entry_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Mailbox entry number",
        assignedTo: "",
        hint: "The mailbox entry number references a single mailbox entry from the mailbox directory",
        rules: {
          required: true,
        },
      },
    },
    grouped: {},
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      delivery_type: getNamedOutputMapping("Delivery type"),
    },
  },
  config: defaultResourceConfig,
});
