import {
  faAdd,
  faBracketsCurly,
  faWandMagicSparkles,
} from "@fortawesome/pro-regular-svg-icons";
import { capitalize } from "lodash";

import { Button } from "src/base-components/Button";
import { DatasetSelector } from "src/datasets/DatasetSelector";
import { useDatasets } from "src/datasets/api/queries";
import { EmptyState } from "src/design-system/EmptyState";
import { Tooltip } from "src/design-system/Tooltip";
import { SchemaOptions } from "src/router/SearchParams";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useAuthoringContext } from "src/router/routerContextHooks";

type Props = {
  type: SchemaOptions;
  handleAddField: () => void;
  isReadonly: boolean;
};

const SchemaEmptyStateActions: React.FC<Props> = ({
  type,
  handleAddField,
  isReadonly,
}) => {
  const { workspace, flow } = useAuthoringContext();
  const datasets = useDatasets({
    flowId: flow.id,
    baseUrl: workspace.base_url,
  });
  const outputSchemaTypingInformation = false;
  return (
    <div className="flex flex-col gap-y-2">
      <div className="w-60">
        <Button
          dataLoc="add-schema-field"
          disabled={isReadonly}
          iconLeft={faAdd}
          variant="secondary"
          fullWidth
          onClick={handleAddField}
        >
          Add field
        </Button>
      </div>
      {isFeatureFlagEnabled(FEATURE_FLAGS.learnSchema) && (
        <>
          <p className="text-gray-500 font-inter-medium-12px">or</p>
          {type === SchemaOptions.Input ? (
            <DatasetSelector
              datasets={datasets.data || []}
              isLoading={datasets.isLoading}
              selectedDatasetId={null}
              onSelectData={() => {
                // TODO: open modal to generate and tweak generated schema
              }}
            />
          ) : (
            <Tooltip
              disabled={outputSchemaTypingInformation}
              placement="bottom"
              title="Test run required to generate Output Schema fields"
            >
              <Button
                dataLoc="add-schema-field"
                disabled={isReadonly || !outputSchemaTypingInformation}
                iconLeft={faWandMagicSparkles}
                variant="secondary"
                fullWidth
              >
                Generate Output Schema
              </Button>
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

export const SchemaEmptyState: React.FC<Props> = (props) => {
  const description = `No fields added for the ${capitalize(
    props.type,
  )} Schema yet. Click below to add a field${
    isFeatureFlagEnabled(FEATURE_FLAGS.learnSchema)
      ? " or select a dataset to generate Schema"
      : ""
  }`;

  return (
    <EmptyState
      action={<SchemaEmptyStateActions {...props} />}
      description={description}
      headline="No field added yet"
      icon={faBracketsCurly}
    />
  );
};
