import { useLocalStorage } from "usehooks-ts";

type GetLatestViewedFolderArgs = {
  userId: string;
  workspaceId: string;
};

const getLatestViewedLocalStorageKey = ({
  userId,
  workspaceId,
}: GetLatestViewedFolderArgs): string => {
  return ["latestViewedFolder", userId, workspaceId].toString();
};

export const useLatestViewedFolder = ({
  userId,
  workspaceId,
}: GetLatestViewedFolderArgs) => {
  const [latestViewedFolder, saveLatestViewedFolder] = useLocalStorage<
    string | undefined
  >(getLatestViewedLocalStorageKey({ userId, workspaceId }), undefined);

  return [latestViewedFolder, saveLatestViewedFolder] as const;
};
