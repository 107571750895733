export const assertUnreachable = (_x: never): never => {
  throw new Error(`Didn't expect to get here, value: ${JSON.stringify(_x)}`);
};

export const checkType = <T>(value: T) => value;

/**
 * Recursively marks all sub keys of T required.
 * Useful when working with api generated classes where often a lot of fields are optional.
 */
export type DeepRequired<T> = {
  [P in keyof T]-?: DeepRequired<T[P]>;
};

export type NonUndefined<T> = T extends undefined ? never : T;
export type ItemsNonUndefined<T> = T extends (infer U)[]
  ? NonUndefined<U>[]
  : never;
