import React from "react";
import hotToast from "react-hot-toast";
import reactToText from "react-to-text";

import { Toast } from "src/design-system/Toast/Toast";
import {
  DEFAULT_TOAST_DURATION_MS,
  NotificationVariant,
  SIDEBAR_MARGIN_CLASS,
  TOAST_VARIANTS,
  ToastParams,
  TrackNotificationArgs,
} from "src/design-system/Toast/constants";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const useTrackNotification = ({
  variant,
  title,
  description,
}: TrackNotificationArgs) => {
  const { orgId } = useParamsDecode<{ orgId?: string }>();

  React.useEffect(() => {
    tracker.emit(
      trackingEvents.authoringPopupNotification({
        organization_id: orgId ? orgId : "unknown-org",
        type: variant,
        message: reactToText(description),
        title: title,
      }),
    );
  }, [orgId, variant, description, title]);
};

export const createToast =
  (variant: NotificationVariant) =>
  ({ id, duration, withSidebar, ...params }: ToastParams): string => {
    const toastDuration =
      duration === null || params.dismissible === false
        ? Infinity
        : (duration ?? DEFAULT_TOAST_DURATION_MS);
    return hotToast.custom(
      (t) => <Toast toastInstance={t} variant={variant} {...params} />,
      {
        id,
        duration: toastDuration,
        position: "bottom-left",
        className: withSidebar ? SIDEBAR_MARGIN_CLASS : "",
      },
    );
  };

export const toastActions = {
  success: createToast(TOAST_VARIANTS.SUCCESS),
  failure: createToast(TOAST_VARIANTS.ERROR),
  loading: createToast(TOAST_VARIANTS.LOADING),
  default: createToast(TOAST_VARIANTS.DEFAULT),
  dismiss: (id: string) => hotToast.dismiss(id),
};
