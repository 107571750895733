import { v4 as uuidV4 } from "uuid";

import { SchufaResourceT } from "src/api/connectApi/types";
import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  InputMappingListT,
  InputMappingT,
  IntegrationResourceT,
} from "src/integrationNode/types";

const B2C_ADDRESS_TYPES = [
  { key: `"PRIMARY"`, value: "primary" },
  { key: `"PREVIOUS"`, value: "previous" },
];

const B2B_ADDRESS_TYPES = [
  {
    key: `"BUSINESS"`,
    value: "business",
  },
  {
    key: `"MAILING"`,
    value: "mailing",
  },
  {
    key: `"REGISTRATION"`,
    value: "registration",
  },
  {
    key: `"PLANT"`,
    value: "plant",
  },
  {
    key: `"BRANCH"`,
    value: "branch",
  },
  {
    key: `"PRIVATE"`,
    value: "private",
  },
  {
    key: `"PO"`,
    value: "po",
  },
];

const getDefaultSchufaAddress = (
  type: SchufaResourceT,
): { [key: string]: InputMappingT } => ({
  address_type: {
    id: uuidV4(),
    type: "dropDown",
    displayName: "Type",
    elements:
      type === "b2b_report_cascading" ? B2B_ADDRESS_TYPES : B2C_ADDRESS_TYPES,
    assignedTo: type === "b2b_report_cascading" ? `"BUSINESS"` : `"PRIMARY"`,
    rules: { required: true },
  },
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
    rules: { required: true },
  },
  house_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Number",
    assignedTo: "",
  },
  affix: {
    id: uuidV4(),
    type: "text",
    displayName: "Affix",
    assignedTo: "",
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    rules: { required: true },
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: { required: true },
  },
  country: {
    id: uuidV4(),
    type: "text",
    displayName: "Country code",
    assignedTo: "",
    hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g, "DE" for Germany)`,
  },
});

const getDefaultSchufaAddressList = (
  type: SchufaResourceT,
  withInitialElement: boolean,
): InputMappingListT => ({
  displayName: "address",
  getDefaultElement: () => getDefaultSchufaAddress(type),
  elements: withInitialElement ? [getDefaultSchufaAddress(type)] : [],
});

const getDefaultSchufaRepresentative = (): {
  [key: string]: InputMappingT;
} => ({
  first_name: {
    id: uuidV4(),
    type: "text",
    displayName: "First name",
    assignedTo: "",
    rules: { required: true },
  },
  last_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Last name",
    assignedTo: "",
    rules: { required: true },
  },
  date_of_birth: {
    id: uuidV4(),
    type: "text",
    displayName: "Date of birth",
    assignedTo: "",
    hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20".`,
  },
  gender: {
    id: uuidV4(),
    type: "text",
    displayName: "Gender",
    assignedTo: "",
    hint: "Options are: “MALE”, “FEMALE”, “UNKNOWN”",
  },
});

const getDefaultSchufaRepresentativeList = (): InputMappingListT => ({
  displayName: "representative",
  getDefaultElement: getDefaultSchufaRepresentative,
  elements: [],
});

export const getDefaultSchufaB2BReport = (): IntegrationResourceT => ({
  providerResource: { provider: "schufa", resource: "b2b_report_cascading" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      schufa_company_id: {
        id: uuidV4(),
        type: "text",
        displayName: "SCHUFA company ID",
        assignedTo: "",
        hint: "SCHUFA assigns each company in its database a unique ID. This ID is returned alongside any business report and can be used to accurately and easily fetch information about that company in the future.",
      },
      company_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Company name",
        assignedTo: "",
      },
      email: {
        id: uuidV4(),
        type: "text",
        displayName: "Email",
        assignedTo: "",
      },
      website_url: {
        id: uuidV4(),
        type: "text",
        displayName: "Website URL",
        assignedTo: "",
      },
      vat_id: {
        id: uuidV4(),
        type: "text",
        displayName: "VAT ID",
        assignedTo: "",
      },
      tax_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Tax ID",
        assignedTo: "",
      },
    },
    grouped: {
      phone: {
        getDefaultElements: () => ({
          number: {
            id: uuidV4(),
            type: "text",
            displayName: "Number",
            assignedTo: "",
            rules: { required: true },
            hint: `Phone number without country and dialling code, e.g., "0612345678".`,
          },
          country: {
            id: uuidV4(),
            type: "text",
            displayName: "Country",
            assignedTo: "",
            hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g, "DE" for Germany)`,
          },
          dialling_code: {
            id: uuidV4(),
            type: "text",
            displayName: "Dialling Code",
            assignedTo: "",
            hint: `A prefix to the phone number that connects to a geographical or logical location (in German: "Vorwahl"), e.g., "030".`,
          },
        }),
      },
    },
    lists: {
      addresses: getDefaultSchufaAddressList("b2b_report_cascading", false),
      representatives: getDefaultSchufaRepresentativeList(),
    },
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.PROVIDER_ENTITY_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      schufa_b2b_score: {
        ...getNamedOutputMapping("SCHUFA B2B Score"),
        hint: `The score (called “Bonitätsindex”) ranges from 100 to 600, with 100 being the best possible score. 600 translates to 99.99% chance of default in the next year.`,
      },
      schufa_b2b_range: {
        ...getNamedOutputMapping("SCHUFA B2B Range"),
        hint: `A string that assigns a broader range of the SCHUFA B2B Score (e.g., “100-249”). We recommend using the B2B Score for a more granular assessment.`,
      },
      schufa_b2b_probability_of_default: {
        ...getNamedOutputMapping("SCHUFA B2B probability of default"),
        hint: `A decimal metric expressing the probability that a company will default in the next twelve months (e.g., 0.2).`,
      },
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultSchufaB2CReport = (): IntegrationResourceT => ({
  providerResource: { provider: "schufa", resource: "b2c_report" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      first_name: {
        id: uuidV4(),
        type: "text",
        displayName: "First name",
        assignedTo: "",
        rules: { required: true },
      },
      last_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Last name",
        assignedTo: "",
        rules: { required: true },
      },
      title: {
        id: uuidV4(),
        type: "text",
        displayName: "Title",
        assignedTo: "",
      },
      date_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Date of birth",
        assignedTo: "",
        rules: { required: true },
        hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20".`,
      },
      place_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Place of birth",
        assignedTo: "",
        hint: `The city in which the individual was born.`,
      },
      gender: {
        id: uuidV4(),
        type: "text",
        displayName: "Gender",
        assignedTo: "",
        rules: { required: true },
        hint: `Options are: “MALE”, “FEMALE”, “UNKNOWN”`,
      },
    },
    grouped: {},
    lists: { addresses: getDefaultSchufaAddressList("b2c_report", true) },
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.PROVIDER_ENTITY_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      schufa_score: {
        ...getNamedOutputMapping("SCHUFA Score"),
        hint: `The primary SCHUFA Score that is part of the B2C report according to your agreement with SCHUFA. By default, this is the "SCHUFA Score for Banks", but you can also ask SCHUFA to return the "SCHUFA Score for Mortgage Banking" (also called "HypoScore") instead of or in addition to the bank score. If SCHUFA enabled both scores for you, you can access the HypoScore via this insight and the bank score via the "Alternative SCHUFA Score" insight. See our docs to learn how to interpret the different scores.`,
      },
      schufa_grade: {
        ...getNamedOutputMapping("SCHUFA Grade"),
        hint: `The SCHUFA Grade ranges from A to P (from better to worse). It directly corresponds to the SCHUFA Score value. See our docs for more information.`,
      },
      alternative_schufa_score: {
        ...getNamedOutputMapping("Alternative SCHUFA Score"),
        hint: `See the "SCHUFA Score" insight help text`,
      },
      solvency_index: {
        ...getNamedOutputMapping("Solvency index"),
        hint: `Ranges from 100 to 600, with 100 being the best possible score. 600 translates to 99.99% chance of default in the next year.`,
      },
    },
  },
  config: defaultResourceConfig,
});

const getDefaultSchufaFraudPreCheckAddress = (): {
  [key: string]: InputMappingT;
} => ({
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
    rules: { required: true },
    hint: `Either provide the entire street name (e.g., "Beispielstrasse 123a") here or use the Street, Number, and Affix fields to split up the street name. The total length must not exceed 50 characters.`,
  },
  house_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Number",
    assignedTo: "",
  },
  affix: {
    id: uuidV4(),
    type: "text",
    displayName: "Affix",
    assignedTo: "",
    hint: `Additional information about the house number, e.g., "a" if the house number is "123a"`,
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    rules: { required: true },
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: { required: true },
  },
});

export const getDefaultSchufaFraudPreCheck = (): IntegrationResourceT => ({
  providerResource: { provider: "schufa", resource: "schufa_fraud_pre_check" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      business_transaction_reference: {
        id: uuidV4(),
        type: "text",
        displayName: "Business transaction reference",
        assignedTo: "",
        rules: { required: true },
        hint: `Used to assign further FraudPreCheck requests to a single business transaction. This value can be chosen freely and will be returned in the response.`,
      },
      first_name: {
        id: uuidV4(),
        type: "text",
        displayName: "First name",
        assignedTo: "",
        rules: { required: true },
      },
      last_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Last name",
        assignedTo: "",
        rules: { required: true },
      },
      date_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Date of birth",
        assignedTo: "",
        hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20"`,
      },
    },
    grouped: {
      address: {
        getDefaultElements: () => getDefaultSchufaFraudPreCheckAddress(),
      },
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      fraud_pre_check_score: {
        ...getNamedOutputMapping("Fraud Pre-Check score"),
        hint: `A score between 0.0 and 1.0 used to assess the inquiry regarding potential fraudulence. A higher score indicates a higher level of suspicion about the inquired entity.`,
      },
    },
  },
  config: defaultResourceConfig,
});
