import { isNumber, isString, uniq } from "lodash";
import { toJS } from "mobx";
import { useCallback, useEffect, useRef, useState } from "react";
import { useEventListener } from "usehooks-ts";

import { EnumOptionsBET } from "src/api/flowTypes";
import { ReviewCaseResponseSchema } from "src/api/types";
import {
  ManualReviewDescription,
  ManualReviewDivider,
  ManualReviewField,
  ManualReviewResponseFormField,
  ManualReviewResponseFormFieldEnumInner,
} from "src/clients/flow-api";
import { ManualReviewNodeDataT } from "src/constants/NodeDataTypes";
import { useFlowContext } from "src/router/routerContextHooks";
import { getUrlToReviewCasePreview } from "src/router/urls";
import { logger } from "src/utils/logger";

const PREVIEW_TAB = "preview-tab";
const PREVIEW_TAB_PARENT = "preview-tab-parent";

const READY_MESSAGE = { source: PREVIEW_TAB, payload: "ready" } as const;

type Highlight =
  | ManualReviewDescription
  | ManualReviewDivider
  | ManualReviewField;

type PreviewDataPayload = {
  nodeName: string;
  highlights: Highlight[];
  schema: ReviewCaseResponseSchema;
};

export const usePreviewTab = (data: ManualReviewNodeDataT) => {
  const { orgId, workspace, flow } = useFlowContext();
  const previewWindow = useRef<Window | null>(null);

  const postMessage = useCallback(() => {
    previewWindow.current?.postMessage({
      source: PREVIEW_TAB_PARENT,
      payload: {
        nodeName: data.label,
        highlights: toJS(data.highlights),
        schema: responseFormFieldsToSchema(data.response_form),
      },
    });
  }, [data]);

  useEventListener("message", (event) => {
    if (event.data.source === PREVIEW_TAB && event.data.payload === "ready") {
      postMessage();
    }
  });

  return {
    open: () => {
      logger.log("open", previewWindow.current);
      if (!previewWindow.current || previewWindow.current.closed) {
        previewWindow.current = window.open(
          "/decide" + getUrlToReviewCasePreview(orgId, workspace.id, flow.id),
        );
      } else {
        previewWindow.current.focus();
        postMessage();
      }
    },
  };
};

export const usePreviewData = () => {
  const [data, setData] = useState<PreviewDataPayload | null>(null);

  useEventListener("message", (event) => {
    const message = event.data;
    if (message.source === PREVIEW_TAB_PARENT && message.payload) {
      setData(message.payload);
    }
  });

  useEffect(() => {
    window.opener?.postMessage(READY_MESSAGE);
  }, []);

  return { hasOpener: Boolean(window.opener), data };
};

type ResponseFormFieldWithKey = Omit<ManualReviewResponseFormField, "key"> & {
  key: string;
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function isResponseFormFieldWithKey(
  field: ManualReviewResponseFormField,
): field is ResponseFormFieldWithKey {
  return Boolean(field.key && field.name);
}

const responseFormFieldsToSchema = (
  response_form: ManualReviewNodeDataT["response_form"],
): ReviewCaseResponseSchema => {
  const fields = response_form.fields?.filter(isResponseFormFieldWithKey) ?? [];
  const required = uniq(
    fields?.filter((field) => field.required).map((field) => field.key),
  );
  const order = uniq(fields?.map((field) => field.key));
  const schema: ReviewCaseResponseSchema = {
    type: "object",
    required,
    properties: {},
    order,
    description: response_form.description,
  };

  fields.forEach((field) => {
    if (field.enum) {
      schema.properties[field.key] = {
        description: field.name,
        enum: parseEnum(field.enum) as EnumOptionsBET,
      };
    } else {
      schema.properties[field.key] = {
        description: field.name,
        type: field.type as "string" | "number" | "boolean" | "integer",
      };
    }
  });

  return schema;
};

const parseEnum = (enumValues: ManualReviewResponseFormFieldEnumInner[]) => {
  return toJS(enumValues)
    .map((v) => {
      try {
        if (isNumber(v)) return v;
        if (isString(v)) return JSON.parse(v);
        return undefined;
      } catch (e) {
        return undefined;
      }
    })
    .filter((v) => v !== undefined);
};
