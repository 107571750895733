import { AxiosError } from "axios";
import React from "react";

import { Button } from "src/base-components/Button";
import { Modal } from "src/design-system/Modal";
import { toastActions } from "src/design-system/Toast/utils";
import { useStopRun } from "src/jobs/api/queries";
import { useFlowContext } from "src/router/routerContextHooks";

type StopRunModalProps = {
  isOpen: boolean;
  onClose: () => void;
  jobId?: string;
  runId?: string;
};
export const StopRunModal: React.FC<StopRunModalProps> = ({
  isOpen,
  onClose,
  jobId,
  runId,
}) => {
  const { workspace } = useFlowContext();
  const stopRunMutation = useStopRun(workspace.base_url!);

  const onConfirmStop = async () => {
    try {
      if (jobId !== undefined && runId !== undefined) {
        await stopRunMutation.mutateAsync({ jobId, runId });
      }
      onClose();
    } catch (err) {
      toastActions.failure({
        title: `Failed to stop the Job run`,
        description:
          (err as AxiosError)?.message ??
          "Something went wrong. Please reload and try again.",
      });
    }
  };

  return (
    <Modal open={isOpen} variant="danger" onClose={onClose}>
      <Modal.Header>Stop Job run?</Modal.Header>
      <Modal.Content>
        <div className="flex flex-col gap-y-2.5 text-gray-500 font-inter-normal-13px">
          Are you sure you want to stop this Job run? If you stop now: <br />
          <ul className="ml-4 list-disc">
            <li className="list-item">
              No additional decisions will be initiated.
            </li>
            <li>All pending rows will remain as is.</li>
            <li>The run cannot be resumed later.</li>
          </ul>
          You can download the results of completed decisions at any time.
          Confirm if you wish to stop the Job run.
        </div>
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="confirm-stop-run"
            htmlType="submit"
            variant="warning"
            onClick={onConfirmStop}
          >
            Stop Job run
          </Button>
        }
      />
    </Modal>
  );
};
