import {
  faClone,
  faDatabase,
  faFile,
  faPowerOff,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import cronstrue from "cronstrue";
import { useNavigate } from "react-router-dom";

import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import { MENU_DIVIDER } from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { Pill } from "src/base-components/Pill";
import { TableComp as Table } from "src/base-components/Table";
import { useModal } from "src/design-system/Modal";
import { ExcludesFalse } from "src/flow/types";
import { useCapabilities } from "src/hooks/useCapabilities";
import { ActivateJobModal } from "src/jobs/common/ActivateJobModal";
import { DeactivateJobModal } from "src/jobs/common/DeactivateJobModal";
import { DeleteJobModal } from "src/jobs/common/DeleteJobModal";
import { JobStatusPill } from "src/jobs/common/JobStatusPill";
import { JobVersionsPills } from "src/jobs/common/JobVersionsPills";
import { ManageJobModal } from "src/jobs/common/ManageJobModal";
import { CellWrapper, ColumnHeader } from "src/jobs/common/TableElements";
import { getFutureMatches } from "src/jobs/jobUtils";
import { Job } from "src/jobs/types";
import { JobWithVersionsUsed } from "src/jobs/types";
import { FlowPageParamsT, getUrlToJobPage } from "src/router/urls";
import { dateFromNow, formatDate } from "src/utils/datetime";
import { getTimezoneOffsetHours } from "src/utils/timezones";
import { useParamsDecode } from "src/utils/useParamsDecode";

const helper = createColumnHelper<JobWithVersionsUsed>();

const COLUMNS = [
  helper.accessor("name", {
    header: () => <ColumnHeader>Job name</ColumnHeader>,
    cell: (info) => <CellWrapper>{info.getValue()}</CellWrapper>,
  }),
  helper.accessor("status", {
    header: () => <ColumnHeader>Status</ColumnHeader>,
    cell: (info) => (
      <CellWrapper>
        <JobStatusPill status={info.getValue()} />
      </CellWrapper>
    ),
  }),
  helper.accessor("versionsUsed", {
    header: () => <ColumnHeader>Version</ColumnHeader>,
    cell: (info) => {
      const versionsUsed = info.getValue();
      return (
        <CellWrapper>
          <JobVersionsPills versionsUsed={versionsUsed} />
        </CellWrapper>
      );
    },
  }),
  helper.accessor("active_source", {
    header: () => <ColumnHeader>Data source</ColumnHeader>,
    cell: (info) => {
      const activeSource = info.getValue();

      if (!activeSource) return <CellWrapper>-</CellWrapper>;
      return (
        <CellWrapper>
          <Pill variant="gray">
            <Pill.Icon
              icon={
                activeSource.configuration.type === "dataset"
                  ? faFile
                  : faDatabase
              }
            ></Pill.Icon>
            <Pill.Text>{activeSource.name}</Pill.Text>
          </Pill>
        </CellWrapper>
      );
    },
  }),
  helper.accessor((row) => row.live_runs_count + row.sandbox_runs_count, {
    id: "total_runs",
    header: () => <ColumnHeader>Total runs</ColumnHeader>,
    cell: (info) => (
      <CellWrapper>
        <span data-loc="total-runs-count">{info.getValue()}</span>
      </CellWrapper>
    ),
  }),
  helper.accessor("last_run_at", {
    header: () => <ColumnHeader>Last run</ColumnHeader>,
    cell: (info) => {
      const lastRunAt = info.getValue();

      return (
        <CellWrapper>{lastRunAt ? dateFromNow(lastRunAt) : "-"}</CellWrapper>
      );
    },
  }),
  helper.accessor("schedule", {
    id: "next_run",
    header: () => <ColumnHeader>Next scheduled run</ColumnHeader>,
    cell: (info) => {
      const schedule = info.getValue();
      const { schedule_timezone } = info.row.original;
      if (!schedule || !schedule_timezone) return <CellWrapper>-</CellWrapper>;
      return (
        <CellWrapper>
          {schedule &&
            formatDate(
              getFutureMatches(schedule, schedule_timezone),
              "MMM d, yyyy 'at' h:mmaaa",
            )}
        </CellWrapper>
      );
    },
  }),
  helper.accessor("schedule", {
    header: () => <ColumnHeader>Schedule</ColumnHeader>,
    cell: (info) => {
      const schedule = info.getValue();
      if (!schedule) return <CellWrapper>-</CellWrapper>;

      const { schedule_timezone } = info.row.original;
      const diffHrsLocalAndTimezone = schedule_timezone
        ? getTimezoneOffsetHours(schedule_timezone).diffHrs * -1
        : 0;

      return (
        <CellWrapper>
          {cronstrue.toString(schedule, {
            verbose: true,
            tzOffset: diffHrsLocalAndTimezone,
          })}
        </CellWrapper>
      );
    },
  }),
  helper.display({
    id: "actions",
    cell: ({ row, table }) => {
      if (!table.options.meta?.actions) return null;

      return (
        <JobActionsMenu
          job={row.original}
          onActivate={table.options.meta?.actions.onActivateJob}
          onDeactivate={table.options.meta?.actions.onDeactivateJob}
          onDelete={table.options.meta?.actions.onDeleteJob}
          onDuplicate={table.options.meta?.actions.onDuplicateJob}
        />
      );
    },
  }),
];

const JobActionsMenu: React.FC<{
  job: Job;
  onDelete: (job: Job) => void;
  onDuplicate: (job: Job) => void;
  onActivate: (job: Job) => void;
  onDeactivate: (job: Job) => void;
}> = ({ job, onDelete, onDuplicate, onActivate, onDeactivate }) => {
  const { jobs } = useCapabilities();
  const options = [
    jobs.canToggleStatus && {
      key: job.status === "inactive" ? "Activate Job" : "Deactivate Job",
      icon: faPowerOff,
      action: () =>
        job.status === "inactive" ? onActivate(job) : onDeactivate(job),
    },
    { key: "Duplicate Job", icon: faClone, action: () => onDuplicate(job) },
    jobs.canDelete && MENU_DIVIDER,
    jobs.canDelete && {
      key: "Delete Job",
      icon: faTrashAlt,
      action: () => onDelete(job),
    },
  ].filter(Boolean as any as ExcludesFalse);

  return (
    <EllipsisOptionsDropdown
      buttonClassName="text-gray-300 w-5 invisible group-hover:visible group-[.is-open]:visible"
      buttonDataLoc="job-actions"
      elements={options}
      ellipsisDirection="vertical"
      timeoutDuration={200}
    />
  );
};

export const JobsTable: React.FC<{
  data: JobWithVersionsUsed[];
  isLoading: boolean;
}> = ({ data, isLoading }) => {
  const navigate = useNavigate();
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
    data: jobToDelete,
  } = useModal<Job>();
  const {
    isOpen: isDuplicateModalOpen,
    openModal: openDuplicateModal,
    closeModal: closeDuplicateModal,
    data: jobToDuplicate,
  } = useModal<Job>();
  const {
    isOpen: isActivateModalOpen,
    data: jobToActivate,
    openModal: openActivateModal,
    closeModal: closeActivateModal,
  } = useModal<Job>();
  const {
    isOpen: isDeactivateModalOpen,
    data: jobToDeactivate,
    openModal: openDeactivateModal,
    closeModal: closeDeactivateModal,
  } = useModal<Job>();

  const { orgId, wsId, flow_id } = useParamsDecode<FlowPageParamsT>();
  return (
    <>
      <Table
        actions={{
          onDeleteJob: (job: Job) => openDeleteModal(job),
          onDuplicateJob: (job: Job) => openDuplicateModal(job),
          onActivateJob: (job: Job) => openActivateModal(job),
          onDeactivateJob: (job: Job) => openDeactivateModal(job),
        }}
        columns={COLUMNS}
        data={data}
        frameClassName="w-full px-3 pb-2"
        isLoading={isLoading}
        rowClassName="cursor-pointer hover:bg-gray-50"
        rowPropsGetter={(row) => ({
          onClick: () =>
            navigate(getUrlToJobPage(orgId, wsId, flow_id, row.original.id)),
        })}
      />
      <DeleteJobModal
        isOpen={isDeleteModalOpen}
        job={jobToDelete}
        onClose={closeDeleteModal}
      />
      <ManageJobModal
        action="duplicate"
        job={jobToDuplicate}
        open={isDuplicateModalOpen}
        onClose={closeDuplicateModal}
      />
      <ActivateJobModal
        isOpen={isActivateModalOpen}
        job={jobToActivate}
        onClose={closeActivateModal}
      />
      <DeactivateJobModal
        isOpen={isDeactivateModalOpen}
        job={jobToDeactivate}
        onClose={closeDeactivateModal}
      />
    </>
  );
};
