import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef } from "react";
import ReactFlow, {
  Edge,
  OnNodesChange,
  OnEdgesChange,
  OnConnect,
  Background,
  useReactFlow,
  NodeChange,
  ReactFlowInstance,
  NodeMouseHandler,
  NodeRemoveChange,
  NodeDimensionChange,
} from "reactflow";
import "reactflow/dist/style.css";
import { useEventListener } from "usehooks-ts";

import { useUpdateFlowVersionGraph } from "src/api/flowVersionQueries";
import { useSelectedNodesLockedByOtherUser } from "src/authoringMultiplayerLock/useSelectedNodesLockedByOtherUser";
import { useIsFloatingWindowPinned } from "src/base-components/FloatingWindow/hooks";
import {
  HistoricalRunEdgeMarkerDefinitions,
  NodeAddingEdgeMarkerDefinitions,
} from "src/constants/DefaultValues";
import { BeMappedNode, NodeT } from "src/constants/NodeDataTypes";
import { BE_MAPPED_NODE_TYPES, NODE_TYPE } from "src/constants/NodeTypes";
import { DETAILED_VIEW_ID } from "src/dataTable/TableUtils";
import { toastActions } from "src/design-system/Toast/utils";
import {
  useAuthoringUIActions,
  useIsNodeEditorOpen,
} from "src/flowContainer/AuthoringUIContext";
import { EDGES } from "src/flowGraph/Edges";
import { GraphControls } from "src/flowGraph/GraphControls";
import { NODES } from "src/flowGraph/Nodes";
import { CreateGroupModal } from "src/flowGraph/modals/CreateGroupModal";
import { DeleteGroupModal } from "src/flowGraph/modals/DeleteGroupModal";
import { DeleteNodeModal } from "src/flowGraph/modals/DeleteNodeModal";
import { DeleteNodesModal } from "src/flowGraph/modals/DeleteNodesModal";
import { RenameGroupModal } from "src/flowGraph/modals/RenameGroupModal";
import { UngroupModal } from "src/flowGraph/modals/UngroupModal";
import { MultiSelectOptions } from "src/flowGraph/multiSelectOptions/MultiSelectOptions";
import { getCopySuccessMessage } from "src/flowGraph/nodeControls/NodeControls";
import { useSyncSelectedNodeToURL } from "src/flowGraph/useSyncSelectedNodeToURL";
import { useCanAuthoringEditFlowVersion } from "src/hooks/useCanAuthoringEditFlowVersion";
import {
  pasteSuccessMessage,
  trackPastingEvents,
} from "src/nodeAdding/PasteNodesCard";
import { ChildVersionChangeNotifications } from "src/parentFlowNodes/flowNode/ChildVersionChangeNotifications";
import { useOpenSchemaEditor } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { AuthorPageParamsT } from "src/router/urls";
import { useGraphStore, useSyncWithGraphStore } from "src/store/StoreProvider";
import { theme } from "src/styles/theme";
import { useParamsDecode } from "src/utils/useParamsDecode";

type FlowPropsT = {
  nodes: NodeT[];
  edges: Edge[];
  onNodesChange?: OnNodesChange;
  onEdgesChange?: OnEdgesChange;
  onConnect?: OnConnect;
  onNodeClick?: NodeMouseHandler;
};

const Flow: React.FC<FlowPropsT> = observer(
  ({ nodes, edges, onNodesChange, onEdgesChange, onConnect, onNodeClick }) => {
    const flowInstance = useRef<ReactFlowInstance<any, any> | null>(null);
    const onInit = useCallback((reactFlowInstance: ReactFlowInstance) => {
      flowInstance.current = reactFlowInstance;
    }, []);
    return (
      <ReactFlow
        deleteKeyCode="Backspace"
        edgeTypes={EDGES}
        edges={edges}
        fitViewOptions={{ maxZoom: 1 }}
        minZoom={0.05}
        multiSelectionKeyCode="Shift"
        nodeTypes={NODES}
        nodes={nodes}
        nodesDraggable={false}
        panActivationKeyCode={null}
        selectionKeyCode={null}
        zoomActivationKeyCode={null}
        zoomOnDoubleClick={false}
        fitView
        onConnect={onConnect}
        onEdgesChange={onEdgesChange}
        onInit={onInit}
        onNodeClick={onNodeClick}
        onNodesChange={onNodesChange}
      >
        <HistoricalRunEdgeMarkerDefinitions />
        <NodeAddingEdgeMarkerDefinitions />
        <Background
          className="bg-gray-50"
          color={theme.gray200}
          gap={17}
          size={3}
        />
        <GraphControls flowInstance={flowInstance} />
      </ReactFlow>
    );
  },
);

export const DecisionGraph: React.FC<{}> = observer(() => {
  const openSchemaEditor = useOpenSchemaEditor();
  const { version } = useAuthoringContext();
  const {
    visibleNodes,
    visibleEdges,
    setSelectedNode,
    setInitialState,
    deSelectNode,
    multiSelectionSelectedNodes,
    isMultiselecting,
    injectGraphUpdateCallback,
    setNodeToDelete,
    setIsDeleteNodeModalVisible,
    copySelectedNodesToClipboard,
    clickedVisibleEdgeId,
    pasteClipboard,
    setIsDeleteNodesModalVisible,
    isValidCopyDeleteSelection,
    updateNodeDimensions,
  } = useGraphStore();

  const canvasDiv = useRef<HTMLDivElement>(null);
  const { getNode, getNodes } = useReactFlow();
  const updateFlowVersionGraph = useUpdateFlowVersionGraph();
  const { wsId, version_id, flow_id, orgId } =
    useParamsDecode<AuthorPageParamsT>();

  const { setNodeEditorOpen } = useAuthoringUIActions();

  // If any of the selected nodes is locked by another user, do not allow cut or delete operations
  const selectedNodeLockedByOtherUser = useSelectedNodesLockedByOtherUser();

  // Update graph state
  useEffect(() => {
    if (version.graph) {
      setInitialState({
        graph: version.graph,
        versionId: version.id,
        flowId: flow_id,
      });
    }
  }, [version.id, setInitialState, version.graph, flow_id]);

  useSyncWithGraphStore();
  useSyncSelectedNodeToURL();

  useEffect(() => {
    injectGraphUpdateCallback(updateFlowVersionGraph.mutateAsync);
  }, [injectGraphUpdateCallback, updateFlowVersionGraph.mutateAsync]);

  const canModifyGraph = useCanAuthoringEditFlowVersion();

  const onNodesChange = useCallback(
    (nodeChanges: NodeChange[]) => {
      if (
        nodeChanges.some((change) => change.type === "remove") &&
        canModifyGraph &&
        !selectedNodeLockedByOtherUser
      ) {
        const deleteChanges = nodeChanges.filter(
          (change) => change.type === "remove",
        );
        if (deleteChanges.length === 1) {
          setNodeToDelete((deleteChanges[0] as NodeRemoveChange).id);
          setIsDeleteNodeModalVisible(true);
        } else if (isValidCopyDeleteSelection.valid) {
          setIsDeleteNodesModalVisible(true);
        }
      }

      if (nodeChanges.some((change) => change.type === "dimensions")) {
        updateNodeDimensions(
          nodeChanges.filter(
            (change) => change.type === "dimensions",
          ) as NodeDimensionChange[],
        );
      }

      const selectNodeChange = nodeChanges.find(
        (nodeChange: NodeChange) =>
          nodeChange.type === "select" && nodeChange.selected,
      );
      const deselectNodeChanges = nodeChanges.filter(
        (nodeChange: NodeChange) =>
          nodeChange.type === "select" && !nodeChange.selected,
      );

      if (selectNodeChange?.type === "select") {
        const nodeSelected: NodeT | undefined = getNode(selectNodeChange.id);
        if (
          (BE_MAPPED_NODE_TYPES as readonly (string | undefined)[]).includes(
            nodeSelected?.type,
          )
        ) {
          const beMappedNodeToBeSelected = nodeSelected as BeMappedNode;
          const currentSelectedNodes = getNodes().filter(
            (node) => node.id !== selectNodeChange.id && node.selected,
          );
          const isMultiSelection =
            deselectNodeChanges.length === 0 &&
            currentSelectedNodes.length !== 0;

          setSelectedNode(beMappedNodeToBeSelected.id, isMultiSelection);
          if (!isMultiSelection) {
            setNodeEditorOpen(true);
          }
          if (
            beMappedNodeToBeSelected &&
            (beMappedNodeToBeSelected.type === NODE_TYPE.INPUT_NODE ||
              beMappedNodeToBeSelected.type === NODE_TYPE.OUTPUT_NODE)
          ) {
            openSchemaEditor(beMappedNodeToBeSelected);
          }
        }
        // (deselectNodeChanges.length === 1 && isMultiselecting) is handled by onNodeClick
      } else if (deselectNodeChanges.length >= 2 && isMultiselecting) {
        setSelectedNode(null);
      }
    },
    [
      canModifyGraph,
      selectedNodeLockedByOtherUser,
      isMultiselecting,
      isValidCopyDeleteSelection.valid,
      setNodeToDelete,
      setIsDeleteNodeModalVisible,
      setIsDeleteNodesModalVisible,
      updateNodeDimensions,
      getNode,
      getNodes,
      setSelectedNode,
      setNodeEditorOpen,
      openSchemaEditor,
    ],
  );

  const isNodeEditorOpen = useIsNodeEditorOpen();
  const isDetailedViewPinned = useIsFloatingWindowPinned(DETAILED_VIEW_ID);

  const onNodeClick: NodeMouseHandler = (e, node) => {
    const clickedNode = visibleNodes.find((n) => n.id === node.id);
    if (clickedNode?.selected) {
      if (e.shiftKey) {
        deSelectNode(clickedNode.id);
      } else if (multiSelectionSelectedNodes) {
        if (
          (BE_MAPPED_NODE_TYPES as readonly (string | undefined)[]).includes(
            clickedNode?.type,
          )
        ) {
          setSelectedNode(clickedNode.id);
        }
      } else {
        if (!isDetailedViewPinned) {
          setSelectedNode(null);
        }
        setNodeEditorOpen(!isNodeEditorOpen);
      }
    }
  };

  // Update graph whenever nodes get copied, cut or pasted via keyboard shortcuts
  const onCopyCut = (cutting: boolean) => async () => {
    if (
      !selectedNodeLockedByOtherUser &&
      document.activeElement === canvasDiv.current
    ) {
      const copiedClipboard = await copySelectedNodesToClipboard(wsId, cutting);
      if (copiedClipboard) {
        toastActions.success({
          title: getCopySuccessMessage(copiedClipboard, cutting),
        });
      } else if (copiedClipboard === false) {
        toastActions.failure({
          title: "Copy to clipboard failed",
          description: "See the documentation for more information",
        });
      }
    }
  };
  useEventListener("copy" as keyof WindowEventMap, onCopyCut(false));
  useEventListener("cut" as keyof WindowEventMap, onCopyCut(true));

  const onPaste = async () => {
    if (clickedVisibleEdgeId) {
      const result = await pasteClipboard(wsId);
      if (result) {
        pasteSuccessMessage(result);
        trackPastingEvents(result, version_id, flow_id, orgId);
      }
    }
  };
  useEventListener("paste" as keyof WindowEventMap, onPaste);

  return (
    <>
      <div
        ref={canvasDiv}
        className="relative flex h-full w-full"
        data-loc="canvas-div"
        id={`graph-${version.graph?.id}`}
        tabIndex={0}
        // Keyboard shortcuts for copy, cut and paste are only recognized when the canvas is focused.
        // Leads to issues otherwise when using the same shortcuts in e.g. node/schema editors.
        onClick={() => {
          canvasDiv.current?.focus();
        }}
      >
        <Flow
          edges={visibleEdges}
          nodes={visibleNodes}
          onNodeClick={onNodeClick}
          onNodesChange={onNodesChange}
        />
        <MultiSelectOptions />
      </div>
      <DeleteNodeModal />
      <DeleteNodesModal />
      <CreateGroupModal />
      <DeleteGroupModal />
      <UngroupModal />
      <RenameGroupModal />
      <ChildVersionChangeNotifications />
    </>
  );
});
