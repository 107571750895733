import React from "react";

import { GenericObjectT } from "src/api/flowTypes";
import { TableComp as Table } from "src/base-components/Table";
import { getColumns } from "src/dataTable/TableUtils";
import { Callout } from "src/design-system/Callout";

type PropsT = {
  message: string;
  failingInput?: GenericObjectT;
  action?: {
    text: string;
    onClick: () => void;
  };
};

// Errors found here from nodes
export const ErrorInfo: React.FC<PropsT> = ({
  message,
  failingInput,
  action,
}) => {
  return (
    <>
      <Callout action={action} type="error">
        <span
          /*min-w-0 necessary because of the parent flex box */
          className="min-w-0 whitespace-pre-wrap break-words"
        >
          {message}
        </span>
      </Callout>

      {failingInput && (
        <div className="mt-6">
          <div className="mb-4 text-gray-800 font-inter-semibold-13px">
            Failing Node input:
          </div>
          <Table
            columns={getColumns([failingInput], "topLeft")}
            data={[failingInput]}
            frameClassName="px-4 border border-gray-200 rounded-lg"
            rowClassName="border-t"
          />
        </div>
      )}
    </>
  );
};
