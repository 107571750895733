import {
  faBolt,
  faCheck,
  faArrowDownToLine,
  faEye,
  faFile,
  faSpinner,
  faStop,
  faSync,
  faTimes,
  faWarning,
  faXmark,
  faDatabase,
} from "@fortawesome/pro-regular-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import {
  addDays,
  differenceInSeconds,
  endOfDay,
  format,
  formatISO,
  parseISO,
  startOfDay,
} from "date-fns";
import { capitalize, uniqueId } from "lodash";
import React, { ComponentProps, useMemo, useState } from "react";
import { v4 as uuidV4 } from "uuid";

import { useEnvironmentFilterAsQueryParam } from "./useEnvironmentFilterAsQueryParam";
import {
  informPreparingDownload,
  pollAndNotifyDownloadSuccess,
} from "src/api/downloadUtils";
import {
  ExportDecisionsEndpoint,
  ExporterDatasetJobsEndpoint,
} from "src/api/endpoints";
import { DecisionEnvironment } from "src/api/types";
import { Breakdown } from "src/base-components/Breakdown";
import { Button } from "src/base-components/Button";
import { ColumnFilter } from "src/base-components/ColumnFilter";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { EnvironmentPill } from "src/base-components/EnvironmentPill";
import { Icon } from "src/base-components/Icon";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import {
  MENU_DIVIDER,
  OptionsDropdownElement,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { Pill, PillVariants } from "src/base-components/Pill";
import { CustomPopover } from "src/base-components/Popover";
import { TableComp as Table } from "src/base-components/Table";
import { DOCS_JOBS } from "src/constants/ExternalLinks";
import { EmptyState } from "src/design-system/EmptyState";
import { useModal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { ExcludesFalse } from "src/flow/types";
import {
  useJobDestinations,
  usePollRunsInProgress,
  useRuns,
} from "src/jobs/api/queries";
import { ConnectionIcon } from "src/jobs/common/ConnectionIcon";
import { JobVersionsPills } from "src/jobs/common/JobVersionsPills";
import { RunButton } from "src/jobs/common/RunButton";
import { S3BucketIcon } from "src/jobs/common/SourceDropdown";
import { StopRunModal } from "src/jobs/common/StopRunModal";
import { CellWrapper, ColumnHeader } from "src/jobs/common/TableElements";
import { DELETED_VERSION_NAME } from "src/jobs/common/constants";
import {
  Job,
  JobDestination,
  JobDestinationDB,
  JobRun,
  JobRunStatus,
  JobRunStatusType,
} from "src/jobs/types";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useFlowContext } from "src/router/routerContextHooks";
import { getUrlFlowDecisionHistory } from "src/router/urls";
import { DECISION_ERROR_STATUS_CODES } from "src/utils/constants";
import { formatDate, formatDistance } from "src/utils/datetime";
import { logger } from "src/utils/logger";
import { formatNumber } from "src/utils/numbers";
import { assertUnreachable } from "src/utils/typeUtils";

const getStatusIcon = (status: JobRunStatus) => {
  switch (status.type) {
    case JobRunStatusType.Completed:
      return faCheck;
    case JobRunStatusType.Running:
      return faSpinner;
    case JobRunStatusType.Failed:
      return faWarning;
    case JobRunStatusType.Stopped:
      return faStop;
    case JobRunStatusType.Writing:
      return faSpinner;
    default:
      assertUnreachable(status);
      return faWarning;
  }
};

const getStatusTitle = (status: JobRunStatus) => {
  if (status.type === JobRunStatusType.Failed) {
    return status.error_title;
  }

  if (status.type === JobRunStatusType.Writing) {
    return "Writing to destination...";
  }

  return capitalize(status.type);
};

const getStatusPillVariant = (status: JobRunStatus): PillVariants => {
  switch (status.type) {
    case JobRunStatusType.Completed:
      return "green";
    case JobRunStatusType.Running:
      return "indigo-light";
    case JobRunStatusType.Failed:
      return "red";
    case JobRunStatusType.Stopped:
      return "yellow";
    case JobRunStatusType.Writing:
      return "indigo-light";
    default:
      assertUnreachable(status);
      return "gray";
  }
};

const RunStatusPill: React.FC<{ status: JobRunStatus }> = ({ status }) => {
  const statusTitle = getStatusTitle(status);
  const pill = (
    <Pill dataLoc="run-status-pill" variant={getStatusPillVariant(status)}>
      <Pill.Icon
        icon={getStatusIcon(status)}
        spin={
          status.type === JobRunStatusType.Running ||
          status.type === JobRunStatusType.Writing
        }
      />
      <Pill.Text>{statusTitle}</Pill.Text>
    </Pill>
  );

  if (status.type === JobRunStatusType.Failed) {
    return (
      <CustomPopover
        button={<span className="cursor-pointer">{pill}</span>}
        offsetX={24}
        placement="bottom-end"
      >
        <div className="relative w-96 cursor-default">
          <CustomPopover.Button className="absolute right-4 top-4">
            <Icon color="text-gray-500" icon={faXmark} size="xs" />
          </CustomPopover.Button>
          <div className="border-b border-gray-100 px-5 pb-3 pt-4">
            <h1 className="flex items-center gap-x-1 text-left text-gray-800 font-inter-semibold-16px">
              <Icon color="text-red-500" icon={faWarning} size="xs" />
              {statusTitle}
            </h1>
          </div>
          <div className="px-5 pb-5 pt-3 text-gray-500 font-inter-normal-13px">
            {status.error_details}
          </div>
          <div className="flex justify-end px-5 pb-4 pt-3">
            <Button
              variant="secondary"
              onClick={() => window.open(DOCS_JOBS, "_blank")}
            >
              Learn more
            </Button>
          </div>
        </div>
      </CustomPopover>
    );
  }

  if (status.type === JobRunStatusType.Stopped) {
    return (
      <Tooltip
        body={
          <p className="text-gray-300 font-inter-normal-12px">
            Stopping a Job halts the initiation of new Decisions, but the
            Decision count may still increment for those already underway.
          </p>
        }
        placement="top"
        asChild
      >
        <span className="cursor-auto">{pill}</span>
      </Tooltip>
    );
  }

  return pill;
};

const helper = createColumnHelper<ExtendedJobRun>();
const getColumns = (
  jobId: string,
  statusFilter: JobRunStatusType | undefined,
) =>
  [
    helper.accessor("id", {
      header: () => <ColumnHeader>Run ID</ColumnHeader>,
      cell: (info) => (
        <CellWrapper>
          <CopyTextIcon
            dataLoc="user-id"
            tooltip="Copy Run ID"
            value={info.getValue()}
          />
        </CellWrapper>
      ),
    }),
    helper.accessor("status", {
      header: (context) => (
        <ColumnHeader>
          Status{" "}
          <ColumnFilter
            description="Filter by status"
            elements={[
              { key: JobRunStatusType.Completed, value: "Completed" },
              { key: JobRunStatusType.Running, value: "Running" },
              { key: JobRunStatusType.Failed, value: "Failed" },
              { key: JobRunStatusType.Stopped, value: "Stopped" },
            ]}
            isFiltering={!!statusFilter}
            placement="bottomRight"
            selected={statusFilter}
            onResetRequest={() => {
              context.table.options.meta?.actions?.setStatusFilter(undefined);
            }}
            onSelect={(key) =>
              context.table.options.meta?.actions?.setStatusFilter(
                statusFilter === key ? undefined : key,
              )
            }
          />
        </ColumnHeader>
      ),
      cell: (info) => (
        <CellWrapper>
          <RunStatusPill status={info.getValue()} />
        </CellWrapper>
      ),
    }),
    helper.accessor("trigger", {
      header: () => <ColumnHeader>Trigger</ColumnHeader>,
      cell: (info) => {
        const trigger = info.getValue().type;
        return (
          <CellWrapper>
            <Pill variant="gray">
              <Pill.Text>
                {trigger === "manual" ? "Manual Trigger" : "Scheduled Run"}
              </Pill.Text>
            </Pill>
          </CellWrapper>
        );
      },
    }),
    helper.accessor("environment", {
      header: () => <ColumnHeader>Type</ColumnHeader>,
      cell: (info) => {
        const env = info.getValue();
        return (
          <CellWrapper>
            <Pill variant={env === DecisionEnvironment.LIVE ? "green" : "gray"}>
              <Pill.Text>{capitalize(env)}</Pill.Text>
            </Pill>
          </CellWrapper>
        );
      },
    }),
    helper.accessor("source_name", {
      header: () => <ColumnHeader>Source</ColumnHeader>,
      cell: (info) => (
        <CellWrapper>
          <div className="flex items-center gap-x-1.5">
            <div className="flex h-5 w-5 items-center justify-center rounded border border-gray-200">
              <Icon color="text-gray-500" icon={faFile} size="3xs" />
            </div>
            <p className="max-w-40 truncate">{info.getValue()}</p>
          </div>
        </CellWrapper>
      ),
    }),
    isFeatureFlagEnabled(FEATURE_FLAGS.jobDestination) &&
      helper.accessor(
        (row) => {
          const { destination_name, destination_type, destination_id } = row;
          return {
            id: destination_id,
            name: destination_name,
            type: destination_type,
          };
        },
        {
          id: "destination",
          header: () => <ColumnHeader>Destination</ColumnHeader>,
          cell: (info) => {
            const { id, name, type } = info.getValue();
            return (
              <CellWrapper>
                <div className="flex items-center gap-x-1.5">
                  {!id ? (
                    "-"
                  ) : (
                    <>
                      <RunDestinationIcon
                        destinationId={id}
                        jobId={jobId}
                        type={type}
                      />
                      <p className="max-w-40 truncate">{name}</p>
                    </>
                  )}
                </div>
              </CellWrapper>
            );
          },
        },
      ),
    helper.accessor((row) => row.versionsUsed, {
      id: "decision_flow",
      header: () => <ColumnHeader>Version</ColumnHeader>,
      cell: (info) => {
        const versionsUsed = info.getValue();
        return (
          <CellWrapper>
            <JobVersionsPills versionsUsed={versionsUsed} />
          </CellWrapper>
        );
      },
    }),
    helper.accessor("created_at", {
      header: () => <ColumnHeader>Time</ColumnHeader>,
      cell: (info) => (
        <CellWrapper>
          {formatDate(info.getValue(), "MMM d, yyyy 'at' hh:mm aaa")}
        </CellWrapper>
      ),
    }),
    helper.accessor(
      (row) => {
        const isInProgress = row.status.type === JobRunStatusType.Running;

        const startedAt = new Date(row.created_at);
        const finishedAt = isInProgress
          ? new Date()
          : row.status.type === JobRunStatusType.Completed &&
              row.status.completed_at
            ? new Date(row.status.completed_at)
            : new Date(row.updated_at);

        const durationInSeconds = differenceInSeconds(finishedAt, startedAt);

        if (durationInSeconds === 0) return "—";

        return (
          <div className="flex items-center gap-x-0.5">
            {isInProgress && <Icon icon={faSpinner} size="2xs" spin />}
            {formatDistance(finishedAt, startedAt, true)}
          </div>
        );
      },
      {
        id: "execution_time",
        header: () => <ColumnHeader>Execution Time</ColumnHeader>,
        cell: (info) => <CellWrapper>{info.getValue()}</CellWrapper>,
      },
    ),
    helper.accessor((row) => row, {
      id: "decisions",
      header: () => <ColumnHeader>Decisions by status</ColumnHeader>,
      cell: (info) => {
        const row = info.getValue();
        return (
          <CellWrapper>
            <DecisionsByStatus {...row} />
          </CellWrapper>
        );
      },
    }),
    helper.display({
      id: "actions",
      cell: (info) => (
        <CellWrapper>
          <RunOptionsMenu
            row={info.row.original}
            onCreateSourceFromFailedRows={
              info.table.options.meta?.actions?.onCreateSourceFromFailedRows
            }
            onDownloadRunResults={
              info.table.options.meta?.actions?.downloadRunResults
            }
            onStopRun={info.table.options.meta?.actions?.onStopRun}
          />
        </CellWrapper>
      ),
    }),
  ].filter(Boolean as any as ExcludesFalse);

const RunOptionsMenu: React.FC<{
  row: ExtendedJobRun;
  onDownloadRunResults: ((runId: string) => void) | undefined;
  onStopRun: (({ runId }: { runId: string }) => void) | undefined;
  onCreateSourceFromFailedRows:
    | (({ run }: { run: ExtendedJobRun }) => void)
    | undefined;
}> = ({
  row,
  onDownloadRunResults,
  onStopRun,
  onCreateSourceFromFailedRows,
}) => {
  const { orgId, workspace, flow } = useFlowContext();
  return (
    <EllipsisOptionsDropdown
      buttonClassName="text-gray-300 w-5 invisible group-hover:visible group-[.is-open]:visible"
      buttonDataLoc="run-options-menu"
      elements={(
        [
          {
            key: "View Decisions",
            icon: faEye,
            action: () => {
              const createdAt = parseISO(row.created_at);
              return window.open(
                "/decide" +
                  getUrlFlowDecisionHistory(orgId, workspace.id, flow.id, {
                    jobId: row.job_id,
                    jobRunId: row.id,
                    env: row.environment,
                    from: formatISO(startOfDay(createdAt)),
                    to: formatISO(endOfDay(addDays(createdAt, 1))),
                  }),
                "_blank",
              );
            },
          },
          // {
          //   key: "Download Job Input",
          //   icon: faArrowDownToLine,
          //   action: () => {},
          // },
          {
            key: "Download Results",
            icon: faArrowDownToLine,
            action: () => {
              onDownloadRunResults?.(row.id);
            },
            disabled:
              row.status.type === JobRunStatusType.Running &&
              "These results will be available once the run is finished",
          },
          (row.status.type === JobRunStatusType.Running ||
            (row.status.type === JobRunStatusType.Completed &&
              row.failed_rows_count !== 0)) &&
            MENU_DIVIDER,
          row.status.type === JobRunStatusType.Running && {
            key: "Stop run",
            icon: faTimes,
            action: () => {
              onStopRun?.({
                runId: row.id,
              });
            },
          },
          (row.status.type === JobRunStatusType.Stopped ||
            row.status.type === JobRunStatusType.Completed) &&
            row.failed_rows_count !== 0 && {
              key: "Convert Failed Rows to Job File",
              subtitle:
                "This will create a new Job file with only the failed rows so that you can resolve the errors",
              icon: faSync,
              action: () => {
                onCreateSourceFromFailedRows?.({
                  run: row,
                });
              },
            },
        ] satisfies (OptionsDropdownElement | false)[]
      ).filter(Boolean as any as ExcludesFalse)}
      ellipsisDirection="vertical"
      timeoutDuration={200}
    />
  );
};

const RunDestinationIcon: React.FC<{
  destinationId: string | undefined;
  jobId: string;
  type: JobDestination["configuration"]["type"];
}> = ({ destinationId, jobId, type }) => {
  const { workspace } = useFlowContext();
  const { data: availableDestinationsData } = useJobDestinations(
    workspace.base_url!,
    jobId,
  );

  if (type === "s3_bucket") {
    return <S3BucketIcon />;
  }

  if (type === "sql") {
    const destination = availableDestinationsData?.destinations?.find(
      (c) => c.id === destinationId,
    );
    if (!destination) {
      return <Icon icon={faDatabase} size="sm" />;
    }

    return (
      <ConnectionIcon
        connectionId={
          (destination as JobDestinationDB)?.configuration.connection_id
        }
        fallbackIcon={faDatabase}
        size="md"
      />
    );
  }

  return null;
};

type ExtendedJobRun = JobRun & {
  versionsUsed: { versionName: string; percentage: number }[];
};

const areFiltersApplied = (statusFilter: JobRunStatusType | undefined) =>
  !!statusFilter;

export const RunsTable: React.FC<{ job: Job }> = ({ job }) => {
  const queryClient = useQueryClient();
  const { workspace, flow } = useFlowContext();
  const [environmentFilter, setEnvironmentFilter] =
    useEnvironmentFilterAsQueryParam();
  const [statusFilter, setStatusFilter] = useState<
    JobRunStatusType | undefined
  >();
  const runsQuery = useRuns(workspace.base_url!, job.id, {
    environment: environmentFilter,
    status: statusFilter,
  });

  const runs = runsQuery.data?.pages.flatMap((page) => page);
  // Runs in progress are polled so they
  // are as up to date as possible
  usePollRunsInProgress(workspace.base_url!, runs ?? []);

  logger.log("Runs", runs);

  const {
    isOpen: isStopRunModalOpen,
    data: runToStopIds,
    openModal: openStopRunModal,
    closeModal: closeStopRunModal,
  } = useModal<{ jobId: string; runId: string }>();

  const header = (
    <div className="mb-3 ml-4 mt-4 flex w-full items-center">
      <div className="mr-4 text-gray-800 font-inter-semibold-13px">
        Run history
      </div>
      <EnvironmentPill
        value={environmentFilter}
        onChange={setEnvironmentFilter}
      />
    </div>
  );

  const columns = useMemo(
    () => getColumns(job.id, statusFilter),
    [job.id, statusFilter],
  );

  const runsMergedWithFlowVersionInfos = runs?.map((run) => ({
    ...run,
    versionsUsed: run.traffic_policy
      ? run.traffic_policy.policy.flow_versions
          .filter(({ flow_version_id }) =>
            flow.versions.find((v) => v.id === flow_version_id),
          )
          .map(({ flow_version_id, percentage }) => ({
            versionName:
              flow.versions.find((v) => v.id === flow_version_id)?.name ??
              DELETED_VERSION_NAME,
            percentage,
          }))
      : [
          {
            versionName:
              flow.versions.find(
                (version) => version.id === run.flow_version_id,
              )?.name ?? DELETED_VERSION_NAME,
            percentage: 100,
          },
        ],
  }));

  const downloadRunResults = async (runId: string) => {
    const toastId = uuidV4();
    try {
      informPreparingDownload(toastId);
      const downloadJob = await ExportDecisionsEndpoint.exportDecisionsAsync(
        workspace.base_url!,
        {
          flow_id: flow.id,
          request: {
            flow_slug: flow.slug,
            input_filters: { job_run_id: runId },
            output_filters: {
              include_extra_fields: false,
              include_response_data: true,
              include_traffic_policy: false,
              include_request_data: false,
            },
            format: "json",
          },
        },
      );
      await pollAndNotifyDownloadSuccess(
        downloadJob,
        workspace.base_url!,
        toastId,
        ExportDecisionsEndpoint.pollDecisionExport,
      );
    } catch (err) {
      logger.error("Failed to create download dataset job", err);
      toastActions.failure({
        id: toastId,
        title: `Something went wrong when downloading the run results`,
      });
    }
  };

  const onCreateSourceFromFailedRows = async ({ run }: { run: JobRun }) => {
    const toastId = uniqueId();

    const onUnexpectedError = (err: unknown) => {
      logger.error(err);
      toastActions.failure({
        id: toastId,
        title: "Something went wrong",
        description: TAKTILE_TEAM_NOTIFIED,
      });
    };

    toastActions.loading({
      id: toastId,
      title: "Creating Job file...",
      description:
        "This could take a few minutes. The process will continue in the background even when this notification is dismissed.",
      duration: Infinity,
    });

    let datasetJob =
      await ExporterDatasetJobsEndpoint.createCreateSourceJobFromJobRunDatasetJob(
        workspace.base_url!,
        {
          flow_id: flow.id,
          request: {
            job_run_id: run.id,
            name: `Failed rows from run at ${format(
              new Date(run.created_at),
              "MMMM do",
            )}`,
            status_codes: DECISION_ERROR_STATUS_CODES as unknown as string[],
          },
        },
      ).catch((err) => {
        onUnexpectedError(err);
        throw err;
      });

    while (datasetJob.status === "PENDING") {
      await new Promise((r) => setTimeout(r, 1000));
      datasetJob = await ExporterDatasetJobsEndpoint.getDatasetJob({
        baseURL: workspace.base_url!,
        id: datasetJob.id,
      });
    }

    if (datasetJob.status === "FAILED") {
      onUnexpectedError(datasetJob.error);
    } else {
      queryClient.invalidateQueries([
        "job_sources",
        workspace.base_url!,
        job.id,
      ]);
      queryClient.invalidateQueries(["datasets", workspace.base_url!, flow.id]);
      toastActions.success({
        id: toastId,
        title: "Job File created successfully!",
        description:
          job.status === "active"
            ? "You can now deactivate the Job to test it with the new file."
            : "You can now test the Job with the new file",
      });
    }
  };

  const showTable =
    areFiltersApplied(statusFilter) ||
    (runs && runs.length > 0) ||
    runsQuery.isLoading;

  const showNoResults = showTable && runs?.length === 0;

  return (
    <>
      <div className="flex h-full w-full flex-col" data-loc="job-runs-table">
        {header}
        {showTable ? (
          <Table
            actions={{
              setStatusFilter: setStatusFilter,
              onStopRun: ({ runId }: { runId: string }) => {
                openStopRunModal({ jobId: job.id, runId });
              },
              onCreateSourceFromFailedRows,
              downloadRunResults,
            }}
            columns={columns}
            data={runsMergedWithFlowVersionInfos || []}
            frameClassName="w-full px-3 pb-2"
            getRowId={(r) => r.id} // So the <Breakdown/> animation is not replayed when switching between Live/Sandbox
            isLoading={runsQuery.isLoading}
          />
        ) : (
          <div data-loc="job-runs-table-empty-state">
            <EmptyState
              action={<RunButton job={job} />}
              description={`No job runs in ${
                job.status === "active" ? "live" : "sandbox"
              } mode. Initiate a new job run to view it here.`}
              headline="No Job runs"
              icon={faBolt}
            />
          </div>
        )}
        {showNoResults && (
          <div data-loc="job-runs-table-empty-state">
            <EmptyState
              action={<RunButton job={job} />}
              description="No job runs were found with selected filters. Change the filters or initiate a new job run."
              headline="No Job runs found"
              icon={faBolt}
            />
          </div>
        )}
      </div>

      <StopRunModal
        isOpen={isStopRunModalOpen}
        onClose={closeStopRunModal}
        {...runToStopIds}
      />
    </>
  );
};

const DecisionsByStatus: React.FC<
  Pick<
    JobRun,
    | "success_rows_count"
    | "failed_rows_count"
    | "pending_rows_count"
    | "total_rows_count"
  >
> = ({
  failed_rows_count,
  pending_rows_count,
  success_rows_count,
  total_rows_count,
}) => {
  const processedCount =
    success_rows_count + pending_rows_count + failed_rows_count;

  const data = [
    {
      type: "success",
      displayText: "Successful",
      count: success_rows_count,
      color: "green",
    },
    {
      type: "pending",
      displayText: "Pending",
      count: pending_rows_count,
      color: "indigo",
    },
    {
      type: "failed",
      displayText: "Failed",
      count: failed_rows_count,
      color: "red",
    },
    {
      type: "not_started",
      displayText: "Waiting",
      count: total_rows_count - processedCount,
      color: "gray",
    },
  ] satisfies (ComponentProps<typeof Breakdown>["bars"][number] & {
    type: string;
    displayText: string;
  })[];
  // Satisfies allows us to complain with <Breakdown/> while
  // having the option of specifying additional fields
  // and don't get a TypeScript error

  return (
    <CustomPopover
      button={
        <div
          className="flex cursor-pointer items-center gap-x-1.5"
          data-loc="run-decisions-by-status-button"
        >
          <div className="w-32">
            <Breakdown bars={data} />
          </div>
          <span data-loc="total-run-rows-count">
            {formatNumber(total_rows_count)}
          </span>
          <span
            className="text-gray-500"
            data-loc="run-rows-processed-percentage"
          >
            (
            {formatNumber(
              total_rows_count !== 0
                ? (success_rows_count +
                    failed_rows_count +
                    pending_rows_count) /
                    total_rows_count
                : 0,
              {
                style: "percent",
              },
            )}
            )
          </span>
        </div>
      }
      offsetX={24}
      placement="right"
    >
      <div className="relative w-122 cursor-default">
        <CustomPopover.Button
          className="absolute right-4 top-4"
          data-loc="close-decisions-by-status"
        >
          <Icon color="text-gray-500" icon={faXmark} size="xs" />
        </CustomPopover.Button>
        <div className="border-b border-gray-100 px-5 pb-3 pt-4">
          <h1 className="text-left text-gray-800 font-inter-semibold-16px">
            Decisions by status
          </h1>
        </div>
        <div className="px-5 pb-6 pt-4">
          <div className="border border-gray-100 text-left text-gray-800">
            <div className="flex h-8 bg-gray-50 font-inter-medium-12px">
              <div className="flex min-w-[127px] items-center border-r border-gray-100 px-2">
                Status
              </div>
              <div className="flex min-w-0 flex-1 items-center border-r border-gray-100 px-2">
                Decisions
              </div>
              <div className="flex min-w-[62px] items-center border-r border-gray-100 px-2"></div>
              <div className="flex min-w-[169px] items-center px-2"></div>
            </div>
            {data.map((entry) => (
              <div
                key={entry.type}
                className="flex h-8 border-t border-gray-100 font-inter-normal-12px"
              >
                <div className="flex min-w-[127px] items-center border-r border-gray-100 px-1.5">
                  <Pill
                    variant={
                      entry.color === "indigo" ? "indigo-light" : entry.color
                    }
                  >
                    <Pill.Text>{entry.displayText}</Pill.Text>
                  </Pill>
                </div>
                <div className="flex min-w-0 flex-1 items-center border-r border-gray-100 px-2">
                  <p
                    className="flex-1 text-right text-gray-800 font-inter-normal-12px"
                    data-loc={`run-decisions-by-status-button-${entry.type}-count`}
                  >
                    {formatNumber(entry.count)}
                  </p>
                </div>
                <div className="flex min-w-[62px] items-center border-r border-gray-100 px-2">
                  <p
                    className="flex-1 text-right text-gray-500 font-inter-normal-12px"
                    data-loc={`run-decisions-by-status-button-${entry.type}-percentage`}
                  >
                    (
                    {formatNumber(
                      total_rows_count !== 0
                        ? entry.count / total_rows_count
                        : 0,
                      {
                        style: "percent",
                      },
                    )}
                    )
                  </p>
                </div>
                <div className="flex min-w-[169px] items-center px-2">
                  <Breakdown bars={data} highlightOnly={entry.color} />
                </div>
              </div>
            ))}
            <div className="flex h-8 border-t border-gray-100 font-inter-normal-12px">
              <div className="flex min-w-[127px] items-center border-r border-gray-100 px-1.5 text-gray-800 font-inter-normal-12px">
                Total
              </div>
              <div className="flex min-w-0 flex-1 items-center border-r border-gray-100 px-2">
                <p className="flex-1 text-right text-gray-800 font-inter-semibold-13px">
                  {formatNumber(total_rows_count)}
                </p>
              </div>
              <div className="flex min-w-[62px] items-center border-r border-gray-100 px-2">
                <p className="flex-1 text-right text-gray-500 font-inter-normal-12px">
                  (100%)
                </p>
              </div>
              <div className="flex min-w-[169px] items-center px-2">
                <Breakdown bars={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomPopover>
  );
};
