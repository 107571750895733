import { isEmpty } from "lodash";
import React from "react";
import { useForm } from "react-hook-form";

import {
  taktileInternalPrefix,
  validateNotStartsWithInternalPrefix,
} from "src/api/constants";
import { useUpdateWorkspaceApiKey } from "src/apiKeys/api/queries";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { ExternalLink } from "src/base-components/ExternalLink";
import { Input } from "src/base-components/Input";
import { WorkspaceServiceAccount } from "src/clients/taktile-api";
import { DOCS_API_KEYS } from "src/constants/ExternalLinks";
import { Modal } from "src/design-system/Modal";
import { logger } from "src/utils/logger";

type ApiKeyInputs = {
  name: string;
};

type PropsT = {
  keyToEdit: WorkspaceServiceAccount | undefined;
  organizationId: string;
  workspaceId: string;
  onClose: () => void;
};

export const EditApiKeyModal: React.FC<PropsT> = ({
  keyToEdit,
  organizationId: orgId,
  workspaceId: wsId,
  onClose,
}) => {
  const updateWorkspaceApiKey = useUpdateWorkspaceApiKey(
    orgId,
    wsId,
    keyToEdit?.id ?? "",
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<ApiKeyInputs>({
    mode: "onChange",
    values: keyToEdit
      ? {
          name: keyToEdit.name,
        }
      : undefined,
  });

  const onConfirm = handleSubmit(async (input: ApiKeyInputs) => {
    if (!keyToEdit) {
      return;
    }

    try {
      await updateWorkspaceApiKey.mutateAsync(input);
    } catch (error) {
      logger.error(`Error when updating ApiKey: ${error}`);
    }
    onClose();
  });

  return (
    <Modal open={keyToEdit !== undefined} onClose={onClose}>
      <Modal.Header
        description={
          <>
            Read more about API keys on our{" "}
            <ExternalLink href={DOCS_API_KEYS}>Documentation</ExternalLink>{" "}
            page.
          </>
        }
      >
        API key
      </Modal.Header>
      <form onSubmit={onConfirm}>
        <Modal.Content>
          <Input
            {...register("name", {
              required: true,
              validate: validateNotStartsWithInternalPrefix(
                `API key name cannot start with ${taktileInternalPrefix}`,
              ),
            })}
            errored={Boolean(errors.name?.message)}
            placeholder="Name your API key"
            fullWidth
          />
          {Boolean(errors.name?.message) && (
            <ErrorHint>{errors.name?.message}</ErrorHint>
          )}
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              disabled={
                !isEmpty(errors) || !isValid || isSubmitting || !isDirty
              }
              htmlType="submit"
              loading={isSubmitting}
              variant="primary"
            >
              Save key
            </Button>
          }
        />
      </form>
    </Modal>
  );
};
