import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import { IntegrationResourceT } from "src/integrationNode/types";

export const getDefaultCodatCompanyInfo = (): IntegrationResourceT => ({
  providerResource: { provider: "codat", resource: "company_info" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      company_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Company ID",
        rules: { required: true },
        assignedTo: "",
        hint: `The Company ID is a unique identifier of a company that is used internally by Codat. It can be retrieved by using the Company Identification Node.`,
      },
    },
    grouped: {},
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(CommonOutputMappingKey.RETRIEVAL_DATETIME),
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultCodatCompanyIdentification =
  (): IntegrationResourceT => ({
    providerResource: { provider: "codat", resource: "company_identification" },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        company_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Company name",
          rules: { required: true },
          assignedTo: "",
          hint: `The company's name as specified on the Codat platform. The company needs to be connected to your Codat account to use the Company Identification report. Note that the input to this field can also be just a part of the company's name. For example, if there is a company "Sandbox Company", searching "Sandbox" will also result in that company being returned.`,
        },
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(),
        company_id: {
          ...getNamedOutputMapping("Company ID"),
          hint: `The Company ID is a unique identifier of a company that is used internally by Codat. It can be retrieved by using the Company Identification Node.`,
        },
        exact_company_name: {
          ...getNamedOutputMapping("Exact company name"),
          hint: `Useful when the input used for search is not the complete company name. It allows you to confirm that the returned company is the one you were looking for.`,
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultCodatEnhancedProfitAndLossAccounts =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "codat",
      resource: "enhanced_profit_and_loss_accounts",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        company_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Company ID",
          rules: { required: true },
          assignedTo: "",
          hint: `The Company ID is a unique identifier of a company that is used internally by Codat. It can be retrieved by using the Company Identification Node.`,
        },
        number_of_periods: {
          id: uuidV4(),
          type: "text",
          displayName: "Number of periods",
          rules: { required: true },
          assignedTo: "",
          hint: `An integer between 1 and 12 that specifies the number of months that the report should cover - counting down from the report date specified below.

          For example, specifying a number of periods of 3 and report date "2022-12-12" will result in a report for December 2022, November 2022 and October 2022.`,
        },
        report_date: {
          id: uuidV4(),
          type: "text",
          displayName: "Report date",
          rules: { required: true },
          assignedTo: "",
          hint: `A string in the format of "YYYY-MM-DD" that specifies the date up to which the report is created. Note that you must specify a specific date, yet the response will be provided for the full month.

          For example, specifying a number of periods of 3 and report date "2022-12-12" will result in a report for December 2022, November 2022 and October 2022.`,
        },
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(CommonOutputMappingKey.RETRIEVAL_DATETIME),
        revenue: {
          ...getNamedOutputMapping("Revenue"),
          hint: `The company's revenue excluding non-operating income.`,
        },
        revenue_annualized: {
          ...getNamedOutputMapping("Revenue (annualized)"),
          hint: `The company's revenue in the last 12 months of the query period. If less than 12 months of data is available, the value is extrapolated to 12 months (e.g., 10000 in 6 months would yield a value of 10000 * 12 / 6 = 20000).`,
        },
        revenue_growth: {
          ...getNamedOutputMapping("Revenue growth"),
          hint: `The normalized slope of the revenue development between the first three months and the last three months of the request period. For example, if the sum of revenue in the first three months is 10000 and in the last three months 30000, the revenue growth is calculated as 30000 / 10000 - 1 = 2.0. We return the value None if less than 12 months of data are available.`,
        },
        gross_profit: {
          ...getNamedOutputMapping("Gross profit"),
          hint: `The profit the business makes after subtracting all the costs that are related to manufacturing and selling its products or services.`,
        },
        gross_profit_margin: {
          ...getNamedOutputMapping("Gross profit margin"),
          hint: `The gross profit over the total operating revenue.`,
        },
        ebitda: {
          ...getNamedOutputMapping("EBITDA"),
          hint: `The company's earnings (including non-operational revenue) before interest, taxes, depreciation and amortization.`,
        },
        ebitda_margin: {
          ...getNamedOutputMapping("EBITDA margin"),
          hint: `The company's EBITDA over the total revenue (including non-operational revenue).`,
        },
        ebda: {
          ...getNamedOutputMapping("EBDA"),
          hint: `The company's EBDA summed over the complete query period.`,
        },
        ebda_annualized: {
          ...getNamedOutputMapping("EBDA (annualized)"),
          hint: `The company's EBDA in the last 12 months of the query period. If less than 12 months of data is available, the value is extrapolated to 12 months (e.g., 10000 in 6 months would yield a value of 10000 * 12 / 6 = 20000).`,
        },
        net_profit: {
          ...getNamedOutputMapping("Net profit"),
          hint: `The company's earnings after deducting all operating, interest, and tax expenses over the requested period of time.`,
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultCodatEnhancedBalanceSheetAccounts =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "codat",
      resource: "enhanced_balance_sheet_accounts",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        company_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Company ID",
          rules: { required: true },
          assignedTo: "",
          hint: `The Company ID is a unique identifier of a company that is used internally by Codat. It can be retrieved by using the Company Identification Node.`,
        },
        number_of_periods: {
          id: uuidV4(),
          type: "text",
          displayName: "Number of periods",
          rules: { required: true },
          assignedTo: "",
          hint: `An integer between 1 and 12 that specifies the number of months that the report should cover - counting down from the report date specified below.

          For example, specifying a number of periods of 3 and report date "2022-12-12" will result in a report for December 2022, November 2022 and October 2022.`,
        },
        report_date: {
          id: uuidV4(),
          type: "text",
          displayName: "Report date",
          rules: { required: true },
          assignedTo: "",
          hint: `A string in the format of "YYYY-MM-DD" that specifies the date up to which the report is created. Note that you must specify a specific date, yet the response will be provided for the full month.

          For example, specifying a number of periods of 3 and report date "2022-12-12" will result in a report for December 2022, November 2022 and October 2022.`,
        },
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(CommonOutputMappingKey.RETRIEVAL_DATETIME),
        total_assets: {
          ...getNamedOutputMapping("Total assets"),
          hint: `The sum of all the company's assets in the requested period of time.`,
        },
        total_liabilities: {
          ...getNamedOutputMapping("Total liabilities"),
          hint: `The sum of all the company's liabilities in the requested period of time.`,
        },
        total_equity: {
          ...getNamedOutputMapping("Total equity"),
          hint: `The sum of all the company's equity in the requested period of time.`,
        },
        net_assets: {
          ...getNamedOutputMapping("Net assets"),
          hint: `The value of the company's assets minus its liabilities.`,
        },
        liquidity_ratio: {
          ...getNamedOutputMapping("Liquidity ratio"),
          hint: `The ratio of total bank and accounts receivable over the current liabilities for the last month in the requested period of time.`,
        },
        liquidity_ratio_trend: {
          ...getNamedOutputMapping("Liquidity ratio trend"),
          hint: `Slope of the liquidity ratio development over the requested number of months, normalized by the latest month's liquidity ratio value.`,
        },
        debt_to_assets_ratio: {
          ...getNamedOutputMapping("Debt-to-assets ratio"),
          hint: `The company's total debts against its total assets for the last month in the requested period of time.`,
        },
        debt_to_assets_delta: {
          ...getNamedOutputMapping("Debt-to-assets delta"),
          hint: `The growth of the debt-to-asset ratio in the latest query month compared to three months earlier. If the debt-to-asset ratio three months prior to the latest query month is zero, the debt-to-assets delta is either 1 (if the latest query month's debt-to-asset delta is > 0) or 0 (if the lastest query month's debt-to-asset delta is ≤ 0).`,
        },
        total_bank_last_6_months: {
          ...getNamedOutputMapping("Total bank (last 6 months)"),
          hint: `The company's accounts with a “Bank” tag summed over the last 6 months of the query period (or the complete query period if it is shorter than 6 months).`,
        },
        total_AR_last_6_months: {
          ...getNamedOutputMapping("Total AR (last 6 months)"),
          hint: `The company's accounts receivable (AR) summed over the last 6 months of the query period (or the complete query period if it is shorter than 6 months).`,
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultCodatAgedDebtorsReport = (): IntegrationResourceT => ({
  providerResource: {
    provider: "codat",
    resource: "aged_debtors_report",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      company_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Company ID",
        rules: { required: true },
        assignedTo: "",
        hint: `The Company ID is a unique identifier of a company that is used internally by Codat. It can be retrieved by using the Company Identification Node.`,
      },
      number_of_periods: {
        id: uuidV4(),
        type: "text",
        displayName: "Number of periods",
        assignedTo: "",
        hint: `An integer between 1 and 12 that specifies the number of months that the report should cover - counting down from the report date specified below.

        For example, specifying a number of periods of 3 and report date "2022-12-12" will result in a report for December 2022, November 2022 and October 2022.`,
      },
      period_length_days: {
        id: uuidV4(),
        type: "text",
        displayName: "Period length (days)",
        assignedTo: "",
        hint: `The length of one period in days.`,
      },
      report_date: {
        id: uuidV4(),
        type: "text",
        displayName: "Report date",
        assignedTo: "",
        hint: `A string in the format of "YYYY-MM-DD" that specifies the date up to which the report is created. Note that you must specify a specific date, yet the response will be provided for the full month.

        For example, specifying a number of periods of 3 and report date "2022-12-12" will result in a report for December 2022, November 2022 and October 2022.`,
      },
    },
    grouped: {},
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(CommonOutputMappingKey.RETRIEVAL_DATETIME),
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultCodatAgedCreditorsReport = (): IntegrationResourceT => ({
  providerResource: {
    provider: "codat",
    resource: "aged_creditors_report",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      company_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Company ID",
        rules: { required: true },
        assignedTo: "",
        hint: `The Company ID is a unique identifier of a company that is used internally by Codat. It can be retrieved by using the Company Identification Node.`,
      },
      number_of_periods: {
        id: uuidV4(),
        type: "text",
        displayName: "Number of periods",
        assignedTo: "",
        hint: `An integer between 1 and 12 that specifies the number of months that the report should cover - counting down from the report date specified below.

        For example, specifying a number of periods of 3 and report date "2022-12-12" will result in a report for December 2022, November 2022 and October 2022.`,
      },
      period_length_days: {
        id: uuidV4(),
        type: "text",
        displayName: "Period length (days)",
        assignedTo: "",
        hint: `The length of one period in days.`,
      },
      report_date: {
        id: uuidV4(),
        type: "text",
        displayName: "Report date",
        assignedTo: "",
        hint: `A string in the format of "YYYY-MM-DD" that specifies the date up to which the report is created. Note that you must specify a specific date, yet the response will be provided for the full month.

        For example, specifying a number of periods of 3 and report date "2022-12-12" will result in a report for December 2022, November 2022 and October 2022.`,
      },
    },
    grouped: {},
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(CommonOutputMappingKey.RETRIEVAL_DATETIME),
    },
  },
  config: defaultResourceConfig,
});
