import { ChartDimensionReduceEnum, ChartMark } from "src/clients/flow-api";

export type ChartMarkType = `${ChartMark}`;

export const CHART_MARKS: [string, ChartMarkType][] = [
  ["Auto Chart", ChartMark.AUTO],
  ["Bar Chart", ChartMark.BAR],
  ["Line Chart", ChartMark.LINE],
  ["Area Chart", ChartMark.AREA],
  ["Dot Chart", ChartMark.DOT],
  ["Summary Chart", ChartMark.SUMMARY],
];

export const REDUCER_NAMES: [string, ChartDimensionReduceEnum | null][] = [
  ["—", null],
  ["Auto", ChartDimensionReduceEnum.AUTO],
  ["Count", ChartDimensionReduceEnum.COUNT],
  ["Distinct", ChartDimensionReduceEnum.DISTINCT],
  ["Sum", ChartDimensionReduceEnum.SUM],
  ["Min", ChartDimensionReduceEnum.MIN],
  ["Max", ChartDimensionReduceEnum.MAX],
  ["Mean", ChartDimensionReduceEnum.MEAN],
  ["Median", ChartDimensionReduceEnum.MEDIAN],
  ["Mode", ChartDimensionReduceEnum.MODE],
  ["Proportion", ChartDimensionReduceEnum.PROPORTION_FACET],
  ["Proportion (total)", ChartDimensionReduceEnum.PROPORTION],
];

export const DEFAULT_CHART_TITLE = "Untitled Chart";
