import { m } from "framer-motion";
import { isEmpty, isNil, isObject, isString, noop } from "lodash";
import { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useDebounceCallback } from "usehooks-ts";

import {
  DecisionsOutcomeFilter,
  MultipleOutcomeFilter,
  OutcomePresenceFilter,
  SingleOutcomeFilter,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { Card } from "src/base-components/Card";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { FormItem } from "src/base-components/FormItem";
import {
  EQUALITY_OPERATOR_OPTIONS,
  OperatorDropdown,
} from "src/base-components/OperatorDropdown";
import { Select } from "src/base-components/Select";
import { Switch } from "src/base-components/Switch";
import { EQUALITY_OPERATORS } from "src/constants/operators";
import { transformOutcomesToOptionsWithType } from "src/decisionsOverview/utils";
import { BOOLEAN_FILTERS, OUTCOME_KEY_FILTERS } from "src/outcomes/constants";
import { useOutcomeTypes } from "src/outcomes/queries";
import { OutcomeType } from "src/outcomes/types";
import { SchemaOptions } from "src/router/SearchParams";
import { useFlowContext } from "src/router/routerContextHooks";
import { PropertyUIT, SchemaConverter } from "src/schema/utils";

type OutcomesFilterProps = {
  value: Nullable<DecisionsOutcomeFilter>;
  onChange: (value: Nullable<DecisionsOutcomeFilter>) => void;
};

const getDefaultFilter = (property?: PropertyUIT) => {
  if (!property) return "exists" as OutcomePresenceFilter["filter"];

  if (property.type[0] === "enum") {
    return {
      property: property.fieldName,
      operator: "in",
      values: [],
    } as MultipleOutcomeFilter["filter"];
  }

  const isBoolean = property.type[0] === "boolean";

  return {
    property: property.fieldName,
    operator: "==",
    value: isBoolean ? true : "",
  } as SingleOutcomeFilter["filter"];
};

type EmptyOutcomeFilter = {
  outcome_type_key: null;
  filter: string;
};

export const OutcomesFilter: React.FC<OutcomesFilterProps> = ({
  value: _,
  onChange,
}) => {
  const [enabled, setEnabled] = useState(false);
  const { flow } = useFlowContext();
  const { data: outcomeTypes, isFetched } = useOutcomeTypes({
    flowId: flow.id,
  });

  const [outcomeFilter, setOutcomeFilter] = useState<
    DecisionsOutcomeFilter | EmptyOutcomeFilter
  >({
    outcome_type_key: null,
    filter: "",
  });

  const setOutcomeFilterDebounced = useDebounceCallback(setOutcomeFilter, 250);

  const outcomeSelectValue = outcomeFilter.outcome_type_key
    ? isObject(outcomeFilter.filter)
      ? `${outcomeFilter.outcome_type_key}.${outcomeFilter.filter.property}`
      : outcomeFilter.outcome_type_key
    : null;
  const onOutcomeChange = (value: Nullable<string>) => {
    const [outcomeKey] = value?.split(".") ?? [];
    const property = getPropertyFromOutcomeTypePropertyKey(outcomeTypes, value);

    setOutcomeFilter(() => {
      return {
        outcome_type_key: outcomeKey,
        filter: getDefaultFilter(property),
      };
    });
  };

  const options = transformOutcomesToOptionsWithType(
    outcomeTypes ?? [],
    true,
  ).map((option) => {
    const value = option.isOutcomeType
      ? option.outcomeName
      : option.propertyName;
    const key = option.isOutcomeType ? option.outcomeKey : option.propertyKey;

    return {
      key,
      value: (
        <div
          className={twJoin(
            option.isOutcomeType
              ? "font-inter-medium-13px"
              : "pl-4 font-inter-normal-13px",
          )}
        >
          {value}
        </div>
      ),
    };
  });

  const property = getPropertyFromOutcomeTypePropertyKey(
    outcomeTypes,
    outcomeSelectValue,
  );
  const propertyIsBoolean = property?.type[0] === "boolean";
  const propertyIsEnum = property?.type[0] === "enum";

  const isDefaultState = !outcomeFilter.outcome_type_key;

  useEffect(() => {
    if (!enabled) return;
    if (isDefaultState) return;

    try {
      const preparedOutcomeFilter = isSingleOutcomeFilter(outcomeFilter)
        ? {
            ...outcomeFilter,
            filter: {
              ...outcomeFilter.filter,
              value: JSON.parse(outcomeFilter.filter.value as string),
            },
          }
        : outcomeFilter;

      if (isValidOutcomeFilter(preparedOutcomeFilter)) {
        onChange(preparedOutcomeFilter);
      } else {
        onChange(null);
      }
    } catch (e) {
      onChange(null);
    }
  }, [enabled, isDefaultState, onChange, outcomeFilter]);

  return (
    <div>
      <div className="flex justify-between">
        <FormItem
          description="Select a subset of your dataset with filtered outcomes"
          gap={false}
          label="Filter by Outcomes"
        />
        <Switch
          disabled={!isFetched}
          enabled={enabled}
          onChange={(value) => {
            setEnabled(value);
            if (!value) {
              onChange(null);
              setOutcomeFilter({
                outcome_type_key: null,
                filter: "",
              });
            }
          }}
        />
      </div>
      {enabled && (
        <m.div animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
          <Card>
            <Card.Content>
              <FormItem gap={false} label="Outcome" isRequired>
                <Select
                  buttonRenderer={(option) => {
                    return isOutcomeTypeProperty(option?.key)
                      ? option?.key
                      : option?.value;
                  }}
                  dataLoc="outcome-filter-key"
                  options={options}
                  placeholder="Select outcome"
                  value={outcomeSelectValue}
                  onChange={onOutcomeChange}
                />
              </FormItem>
              <FormItem
                gap={false}
                label={
                  propertyIsBoolean
                    ? "Is"
                    : propertyIsEnum
                      ? "Is in"
                      : "Condition"
                }
                isRequired
              >
                {isDefaultState ? (
                  <Select
                    dataLoc="outcome-filter-condition"
                    options={[]}
                    placeholder="Select condition"
                    value={null}
                    disabled
                    onChange={noop}
                  />
                ) : isOutcomePresenceFilter(outcomeFilter) ? (
                  <Select
                    dataLoc="outcome-filter-condition"
                    options={OUTCOME_KEY_FILTERS}
                    placeholder="Select condition"
                    value={outcomeFilter.filter}
                    onChange={(value) => {
                      setOutcomeFilter({
                        outcome_type_key: outcomeFilter.outcome_type_key,
                        filter: value,
                      } as OutcomePresenceFilter);
                    }}
                  />
                ) : isMultipleOutcomeFilter(outcomeFilter) ? (
                  <Select
                    dataLoc="outcome-filter-condition"
                    options={
                      property?.enum?.map(({ value }) => {
                        const parsedValue = JSON.parse(value);
                        return {
                          key: value,
                          value: parsedValue,
                          searchText: String(parsedValue),
                        };
                      }) ?? []
                    }
                    placeholder="Select values"
                    value={outcomeFilter.filter.values.map((value) =>
                      JSON.stringify(value),
                    )}
                    multiple
                    onChange={(values) => {
                      setOutcomeFilter({
                        ...outcomeFilter,
                        filter: {
                          ...outcomeFilter.filter,
                          values: values.map((value) => JSON.parse(value)),
                        },
                      } as MultipleOutcomeFilter);
                    }}
                  />
                ) : propertyIsBoolean ? (
                  <Select
                    dataLoc="outcome-filter-condition"
                    options={BOOLEAN_FILTERS}
                    placeholder="Select condition"
                    value={outcomeFilter.filter.value.toString()}
                    onChange={(value) => {
                      setOutcomeFilter({
                        outcome_type_key: outcomeFilter.outcome_type_key,
                        filter: {
                          ...outcomeFilter.filter,
                          value: value === "true",
                        },
                      });
                    }}
                  />
                ) : (
                  <AutocompleteCodeInput
                    dataLoc="outcome-filter-condition"
                    placeholder=""
                    readOnly={false}
                    value={outcomeFilter.filter.value.toString()}
                    onChange={(value) => {
                      setOutcomeFilterDebounced({
                        ...outcomeFilter,
                        filter: {
                          ...outcomeFilter.filter,
                          value,
                        },
                      });
                    }}
                  >
                    <OperatorDropdown
                      options={EQUALITY_OPERATOR_OPTIONS}
                      value={
                        (Object.entries(EQUALITY_OPERATORS).find(
                          ([_, operator]) =>
                            operator.code === outcomeFilter.filter.operator,
                        )?.[0] ?? "eq") as keyof typeof EQUALITY_OPERATORS
                      }
                      onChange={(value) => {
                        if (value in EQUALITY_OPERATORS) {
                          setOutcomeFilter({
                            ...outcomeFilter,
                            filter: {
                              ...outcomeFilter.filter,
                              operator:
                                EQUALITY_OPERATORS[
                                  value as keyof typeof EQUALITY_OPERATORS
                                ].code,
                            },
                          });
                        }
                      }}
                    />
                  </AutocompleteCodeInput>
                )}
              </FormItem>
            </Card.Content>
          </Card>
        </m.div>
      )}
    </div>
  );
};

const isOutcomeTypeProperty = (key?: Nullable<string>): boolean =>
  Boolean(key?.includes("."));

const getPropertyFromOutcomeTypePropertyKey = (
  outcomes?: OutcomeType[],
  key?: Nullable<string>,
) => {
  if (!outcomes || !key) return;
  if (!isOutcomeTypeProperty(key)) return;

  const [outcomeKey, propertyName] = key.split(".");
  const outcome = outcomes.find((o) => o.key === outcomeKey);

  if (!outcome) return;

  const outcomePayloadSchema = SchemaConverter.beToUI(
    outcome.payload_schema,
    SchemaOptions.Output,
  );

  return outcomePayloadSchema.properties.find(
    (p) => p.fieldName === propertyName,
  );
};

// Check only that required fields are present
const isValidOutcomeFilter = (
  outcomeFilter: DecisionsOutcomeFilter | EmptyOutcomeFilter,
): outcomeFilter is DecisionsOutcomeFilter => {
  if (!outcomeFilter.outcome_type_key) return false;

  if (isOutcomePresenceFilter(outcomeFilter)) return true;

  if (isSingleOutcomeFilter(outcomeFilter)) {
    return (
      !isNil(outcomeFilter.filter.operator) &&
      !isNil(outcomeFilter.filter.value) &&
      !isNil(outcomeFilter.filter.property)
    );
  }

  return (
    isObject(outcomeFilter.filter) &&
    Object.entries(outcomeFilter.filter).every(
      ([key, value]) =>
        ["property", "operator", "value", "values"].includes(key) &&
        value !== undefined &&
        value !== null &&
        // (isBoolean(value) || !isEmpty(value)) &&
        (Array.isArray(value) ? !isEmpty(value) : true),
    )
  );
};

const isOutcomePresenceFilter = (
  outcomeFilter: Partial<DecisionsOutcomeFilter>,
): outcomeFilter is OutcomePresenceFilter => {
  return isString(outcomeFilter.filter);
};

const isMultipleOutcomeFilter = (
  outcomeFilter: Partial<DecisionsOutcomeFilter>,
): outcomeFilter is MultipleOutcomeFilter => {
  return isObject(outcomeFilter.filter) && "values" in outcomeFilter.filter;
};

const isSingleOutcomeFilter = (
  outcomeFilter: Partial<DecisionsOutcomeFilter>,
): outcomeFilter is SingleOutcomeFilter => {
  return isObject(outcomeFilter.filter) && "value" in outcomeFilter.filter;
};
