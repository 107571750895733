import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  useSensor,
} from "@dnd-kit/core";
import { snapCenterToCursor } from "@dnd-kit/modifiers";
import { faBox } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { NO_FOLDER_FILTER } from "src/api";
import { FlowT } from "src/api/flowTypes";
import { useFlows } from "src/api/queries";
import {
  useAddFlow,
  useDeleteFlow,
  useEditFlow,
  useWorkspace,
} from "src/api/queries";
import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { toastActions } from "src/design-system/Toast/utils";
import { DeleteFlowModal } from "src/flowsOverview/DeleteFlowModal";
import {
  EditFlowModal,
  EditFlowModalOutputsT,
} from "src/flowsOverview/EditFlowModal";
import { FlowListHeader } from "src/flowsOverview/FlowListHeader";
import { GRID_COLUMNS_CLASS } from "src/flowsOverview/FlowOverview";
import { FlowListSkeletonV2 } from "src/flowsOverview/v2/FlowListSkeletonV2";
import { FlowListV2 } from "src/flowsOverview/v2/FlowListV2";
import { FolderList } from "src/flowsOverview/v2/FolderList";
import { MoveFlowModal } from "src/flowsOverview/v2/MoveFlowModal";
import { FolderDropdownSelection } from "src/flowsOverview/v2/SelectFolderDropdown";
import {
  useCreateFolder,
  useFolders,
} from "src/flowsOverview/v2/folderQueries";
import { handleNoFolderId } from "src/flowsOverview/v2/handleNoFolderId";
import { useOrderFlowsBy } from "src/flowsOverview/v2/useOrderFlowsBy";
import { useSubmitFlowSettingsUpdate } from "src/flowsOverview/v2/useSubmitFlowSettingsUpdate";
import { useCapabilities } from "src/hooks/useCapabilities";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { useFolderIdSearchParam } from "src/router/SearchParams";
import {
  getBaseUrl,
  getFlowVersionsUrl,
  getLinkToApiDocsPage,
  getUrlToReviewQueue,
} from "src/router/urls";
import { useUserData } from "src/store/AuthStore";
import { copyTextToClipboard } from "src/utils/clipboard";
import { fixCursorSnapOffset } from "src/utils/fixCursorSnapCollisonOffset";
import { logger } from "src/utils/logger";
import { wrapWithAxiosResponseErrorHandler } from "src/utils/toastError";

export const DND_NO_FOLDER_ID = "dndNoFolder" as const;

const FlowDragOverlay: React.FC<{
  name: string | undefined;
}> = ({ name }) => {
  return (
    <div className="flex w-fit min-w-[180px] cursor-default items-center gap-2 rounded-lg bg-white p-4 text-gray-700 shadow-sm font-inter-medium-13px">
      <Icon color="text-gray-500" icon={faBox} />
      {name}
    </div>
  );
};

export const FlowFolderList: React.FC<{
  organizationId: string;
  workspaceId: string;
}> = ({ workspaceId, organizationId }) => {
  const [searchParamFolderId, setSearchParamFolderId] =
    useFolderIdSearchParam();
  const [orderFlowsBy, _setOrderFlowsBy] = useOrderFlowsBy();

  const flowResult = useFlows({
    workspaceId,
    folderId: searchParamFolderId || NO_FOLDER_FILTER,
    orderBy: orderFlowsBy,
  });
  const [folderId] = useFolderIdSearchParam();
  const foldersResult = useFolders({ workspaceId: workspaceId });
  const { flows: flowPermissions, flowVersions: flowVersionsPermissions } =
    useCapabilities();
  const workspace = useWorkspace(workspaceId);

  const [currentFlow, setCurrentFlow] = useState<FlowT>();
  const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);
  const [moveModalIsOpen, setMoveModalIsOpen] = useState<boolean>(false);
  const [flowIdToDelete, setIdFlowToDelete] = useState<string | undefined>();
  const [deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen] =
    useState<boolean>(false);
  const addFlow = useAddFlow();
  const deleteFlow = useDeleteFlow();
  const editFlow = useEditFlow(workspaceId);
  const createFolder = useCreateFolder();
  const { signed_in_user_id } = useUserData();
  const onSubmitFlowSettings = useSubmitFlowSettingsUpdate(
    workspaceId,
    currentFlow,
  );

  const navigate = useNavigate();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const handleCreateFlow = flowPermissions.canCreate
    ? () => setCreateModalOpen(true)
    : undefined;

  const onViewFlow = (flow: FlowT) => {
    flowVersionsPermissions.canAccess
      ? navigate(getFlowVersionsUrl(organizationId, workspaceId, flow.id))
      : navigate(getUrlToReviewQueue(organizationId, workspaceId, flow.id));
  };

  const onRename = (flow: FlowT) => {
    setCurrentFlow(flow);
    setEditModalIsOpen(true);
  };

  const onMove = (flow: FlowT) => {
    setCurrentFlow(flow);
    setMoveModalIsOpen(true);
  };

  const onCopy = (flow: FlowT) => {
    copyTextToClipboard(
      getBaseUrl() + getFlowVersionsUrl(organizationId, workspaceId, flow.id),
    ).then(() => {
      toastActions.success({
        title: "Link copied to clipboard",
      });
    });
  };

  const onDelete = (flow: FlowT) => {
    setIdFlowToDelete(flow.id);
    setDeleteConfirmationModalIsOpen(true);
  };

  const onViewApiDocs = (flow: FlowT) => {
    try {
      const apiDocsUrl = getLinkToApiDocsPage(
        organizationId,
        workspaceId,
        flow,
      );
      if (apiDocsUrl) {
        navigate(apiDocsUrl);
      }
    } catch {
      logger.error(`Couldnt access docs of flow ${flow.name}`);
    }
  };

  const onCloseModal = () => {
    setEditModalIsOpen(false);
  };

  const onConfirmMoveModal = async ({
    folderSelection,
  }: {
    folderSelection: FolderDropdownSelection;
  }) => {
    let folderId: string | null = null;
    if (folderSelection.mode === "addFolder") {
      const newFolder = (
        await createFolder.mutateAsync({
          name: folderSelection.folderName,
          workspace_id: workspaceId,
        })
      ).data;
      folderId = newFolder.id;
    } else {
      folderId = folderSelection.folderId;
    }
    if (currentFlow) {
      await editFlow.mutateAsync({
        flowId: currentFlow.id,
        flow_folder_id: folderId,
        old_folder_id: currentFlow.flow_folder_id,
      });
    }
  };

  const onRemoveFromFolder = wrapWithAxiosResponseErrorHandler(
    async (flow: FlowT) => {
      await editFlow.mutateAsync({
        flowId: flow.id,
        flow_folder_id: null,
        old_folder_id: flow.flow_folder_id,
      });
    },
  );

  const onCloseDeleteModal = () => {
    setDeleteConfirmationModalIsOpen(false);
    setIdFlowToDelete(undefined);
  };

  const onConfirmDeleteModal = async () => {
    if (flowIdToDelete) {
      try {
        await deleteFlow.mutateAsync(flowIdToDelete);
        toastActions.success({ title: "Successfully deleted flow" });
      } catch {
        logger.error("Error deleting flow");
      }
    }
    setDeleteConfirmationModalIsOpen(false);
    setIdFlowToDelete(undefined);
  };

  const onAddFlow = async ({
    name,
    slug,
    wsId,
    description,
    orgId,
    folderSelection,
    reviewConfig,
  }: EditFlowModalOutputsT) => {
    let folderId: string | null = null;
    if (folderSelection.mode === "addFolder") {
      const newFolder = (
        await createFolder.mutateAsync({
          name: folderSelection.folderName,
          workspace_id: workspaceId,
        })
      ).data;
      folderId = newFolder.id;
    } else {
      folderId = folderSelection.folderId;
    }
    const createdFlow = await addFlow.mutateAsync({
      name,
      description: description ?? "",
      wsId,
      slug,
      flow_folder_id: folderId,
      initial_version_description: "Initial Version",
      initial_version_name: "v1.0",
      created_by_id: signed_in_user_id ?? "",
      review_configuration: reviewConfig?.requires_review
        ? reviewConfig
        : undefined,
    });
    setSearchParamFolderId(folderId || undefined);
    await tracker.emit(
      trackingEvents.createFlow({
        organization_id: orgId,
        workspace_id: wsId,
        flow_id: createdFlow.id,
      }),
    );
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      // To enable the standard click interactions of the flow cards, only start
      // the drag interaction after a minimum drag width
      distance: 20,
    },
  });

  const [dndActiveId, setDndActiveId] = useState<string | number>();
  const onDragStart = (event: DragStartEvent) => {
    setDndActiveId(event.active.id);
  };

  const onDragEnd = (event: DragEndEvent) => {
    setDndActiveId(undefined);
    const { active, over } = event;
    if (!over) return;
    if (typeof active.id !== "string" || typeof over.id !== "string") return;
    const oldFolderId = flowResult.data?.find(
      (flow) => flow.id === active.id,
    )?.flow_folder_id;
    const targetFolderId = over.id !== DND_NO_FOLDER_ID ? over.id : null;
    if (oldFolderId !== targetFolderId) {
      wrapWithAxiosResponseErrorHandler(editFlow.mutateAsync)({
        flowId: active.id,
        flow_folder_id: targetFolderId,
        old_folder_id:
          flowResult.data?.find((flow) => flow.id === active.id)
            ?.flow_folder_id || null,
      });
    }
  };

  const skeletonCount =
    folderId === undefined
      ? foldersResult.data?.flows_outside_folders_count
      : foldersResult.data?.folders.find((folder) => folder.id === folderId)
          ?.flow_count;

  return (
    <>
      <FlowListHeader onCreate={handleCreateFlow} />
      <div className={twJoin(GRID_COLUMNS_CLASS, "min-h-0 flex-1")}>
        <DndContext
          collisionDetection={fixCursorSnapOffset}
          sensors={[mouseSensor]}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
        >
          <div className="col-span-full flex h-full min-h-0 gap-x-4.5 bg-gray-100">
            <FolderList
              folderResult={foldersResult}
              organizationId={organizationId}
              workspaceId={workspaceId}
            />
            <div className="flex-1 overflow-auto scrollbar-hide">
              <LoadingView
                queryResult={flowResult}
                renderUpdated={(data: FlowT[]) => (
                  <FlowListV2
                    flowActions={{
                      onCopy,
                      onDelete,
                      onRename,
                      onViewApiDocs,
                      onViewFlow,
                      onMove,
                      onRemoveFromFolder,
                    }}
                    flows={data}
                  />
                )}
                renderUpdating={() => (
                  <FlowListSkeletonV2 skeletonCount={skeletonCount} />
                )}
              />
            </div>
          </div>
          <DragOverlay
            // Drop animation behaves weird with async state updates on drop. Let's work without one for now
            dropAnimation={null}
            modifiers={[snapCenterToCursor]}
          >
            <div className="p-4">
              <FlowDragOverlay
                name={
                  flowResult.data?.find((flow) => flow.id === dndActiveId)?.name
                }
              />
            </div>
          </DragOverlay>
        </DndContext>
        <EditFlowModal
          flow={currentFlow}
          mode="edit"
          open={editModalIsOpen}
          organizationId={organizationId}
          title="Edit Decision Flow"
          workspaceId={workspaceId}
          onClose={onCloseModal}
          onConfirm={onSubmitFlowSettings}
          onUnmount={() => setCurrentFlow(undefined)}
        />
        <DeleteFlowModal
          flowId={flowIdToDelete}
          isOpen={deleteConfirmationModalIsOpen}
          workspace={workspace.data}
          onClose={onCloseDeleteModal}
          onConfirm={onConfirmDeleteModal}
        />
        <EditFlowModal
          initialFolderId={handleNoFolderId(searchParamFolderId)}
          mode="add"
          open={createModalOpen}
          organizationId={organizationId}
          title="Create new Decision Flow"
          workspaceId={workspaceId}
          onClose={() => setCreateModalOpen(false)}
          onConfirm={onAddFlow}
        />
        <MoveFlowModal
          flow={currentFlow}
          open={moveModalIsOpen}
          workspaceId={workspaceId}
          onAddFolder={() => {}}
          onClose={() => setMoveModalIsOpen(false)}
          onConfirm={onConfirmMoveModal}
        />
      </div>
    </>
  );
};
