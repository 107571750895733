import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { Chart } from "src/analytics/types";
import { logger } from "src/utils/logger";

type FlowModalOpenArgs = {
  type: "flow";
  chart?: Chart;
  nodeId?: undefined;
};

type ModalOpenArgs =
  | {
      type: "node";
      nodeId: string;
      chart?: Chart;
    }
  | FlowModalOpenArgs;

type ChartModalState =
  | {
      type: "node";
      nodeId: string;
      chart?: Chart;
    }
  | {
      type: "flow";
      chart?: Chart;
      nodeId?: undefined;
    }
  | {
      type: null;
      nodeId?: undefined;
      chart?: undefined;
    };

type ChartModalActions = {
  open: (args: ModalOpenArgs) => void;
  close: () => void;
  afterLeave: () => void;
};

type ChartModalStore = ZustandStore<ChartModalState, ChartModalActions>;

const useChartModalStore = create(
  immer<ChartModalStore>((set) => ({
    type: null,
    nodeId: undefined,
    chart: undefined,
    actions: {
      open: ({ nodeId, type, chart }) => {
        return set((state) => {
          state.type = type;
          state.nodeId = nodeId;
          state.chart = chart;
        });
      },
      close: () =>
        set((state) => {
          state.type = null;
        }),
      afterLeave: () =>
        set((state) => {
          state.nodeId = undefined;
          state.chart = undefined;
        }),
    },
  })),
);

export const useChartModalActions = () =>
  useChartModalStore((state) => state.actions);
export const useIsChartModalOpen = (type: "node" | "flow") =>
  useChartModalStore((state) => state.type === type);
export const useChartModalChart = () =>
  useChartModalStore((state) => state.chart);
export const useChartModalNodeId = () =>
  useChartModalStore((state) => state.nodeId);

export const useOpenEditChartModal = () => {
  const { open: openModal } = useChartModalActions();

  return (chart: Chart) => {
    if (!chart.resource_type) {
      logger.error(
        "Chart does not have a resource type: chart_id = ",
        chart.id,
      );
      return;
    }
    const isFlowChart = chart.resource_type === "flow";
    return openModal(
      isFlowChart
        ? {
            type: "flow",
            chart,
          }
        : {
            type: "node",
            nodeId: chart.resource_id,
            chart,
          },
    );
  };
};

export const useOpenFlowChartModal = () => {
  const { open: openModal } = useChartModalActions();

  return (chart?: Chart) => openModal({ type: "flow", chart });
};

export const useOpenNodeChartModal = () => {
  const { open: openModal } = useChartModalActions();

  return (nodeId: string, chart?: Chart) =>
    openModal({ type: "node", nodeId, chart });
};
