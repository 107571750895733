import { faUserCircle } from "@fortawesome/pro-regular-svg-icons";

import { ReviewCaseStatus as ReviewCaseStatusType } from "src/api/types";
import { Icon } from "src/base-components/Icon";

export const ReviewCaseStatus: React.FC<{ status: ReviewCaseStatusType }> = ({
  status,
}) => {
  return (
    <div className="flex items-center gap-1 overflow-hidden pr-2">
      <Icon
        color={status === "needs_review" ? "text-indigo-400" : "text-green-500"}
        icon={faUserCircle}
        size="xs"
      />
      <span className="truncate">
        {status === "needs_review"
          ? "Needs manual review"
          : "Review in Progress"}
      </span>
    </div>
  );
};
