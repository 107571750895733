import { map } from "lodash";
import { UseFormReturn } from "react-hook-form";

import { FieldErrorsT } from "src/api/types";
import { InputMappingRow } from "src/integrationNode/editorComponents/InputMappingRow";
import { InputMappingsT, InputMappingT } from "src/integrationNode/types";

type Props = {
  inputMappings: { [key: string]: InputMappingT };
  formProps: UseFormReturn<InputMappingsT>;
  runFieldErrors?: FieldErrorsT;
  immutable: boolean;
  groupClassName: string;
};

export const InputMappingUngrouped: React.FC<Props> = ({
  inputMappings,
  formProps,
  runFieldErrors,
  immutable,
  groupClassName,
}) => {
  if (Object.keys(inputMappings).length > 0) {
    return (
      <div className={groupClassName}>
        {map(inputMappings, (inputMapping, beName) => (
          <InputMappingRow
            key={beName}
            beName={beName}
            formControl={formProps.control}
            immutable={immutable}
            inputMapping={inputMapping}
            inputProps={formProps.register(`ungrouped.${beName}.assignedTo`)}
            runFieldError={runFieldErrors?.[inputMapping.id]}
          />
        ))}
      </div>
    );
  }
};
