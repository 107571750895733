import { closestCorners, DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";

export const SortableWrapper = ({
  order,
  children,
  onChangeOrder,
}: {
  order: string[];
  children: (charts: string[]) => React.ReactNode;
  onChangeOrder: (chartOrder: string[]) => void;
}) => {
  const [chartOrder, setChartOrder] = useState(order);

  useEffect(() => {
    if (!isEqual(chartOrder, order)) {
      setChartOrder(order);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = order.indexOf(active.id as string);
      const newIndex = order.indexOf(over.id as string);

      const newChartOrder = arrayMove(order, oldIndex, newIndex);
      setChartOrder(newChartOrder);
      onChangeOrder(newChartOrder);
    }
  };

  return (
    <DndContext collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
      <SortableContext
        items={chartOrder}
        strategy={verticalListSortingStrategy}
      >
        {children(chartOrder)}
      </SortableContext>
    </DndContext>
  );
};
