import * as ObservablePlot from "@observablehq/plot";
import { ChannelValue } from "@observablehq/plot";
import { isDate } from "date-fns";
import {
  cloneDeepWith,
  defaultsDeep,
  get,
  isBoolean,
  isNil,
  isObject,
  isString,
  set,
} from "lodash";

import {
  Chart,
  ChartState,
  DimensionReducer,
  ObservableChart,
  ObservableChartDimensions,
  ObservableChartMark,
  SummaryChart,
} from "src/analytics/types";
import { GenericObjectT } from "src/api/flowTypes";
import {
  ChartDimension,
  ChartDimensionReduceEnum,
  ChartMark,
} from "src/clients/flow-api";
import { SUB_COLUMN_SEPARATOR } from "src/datasets/DatasetTable/utils";
import { COLORS } from "src/utils/constants";
import { isISO8601 } from "src/utils/datetime";
import { formatNumber } from "src/utils/numbers";

const undefinedIfAuto = (value?: DimensionReducer) =>
  value === "auto" ? undefined : value;

export type ObservableChannelValue = {
  value?: ChannelValue;
  reduce?: ObservablePlot.Reducer | null;
  zero?: boolean;
} & ObservablePlot.BinOptions;

export const tickFormat = (t: any) => {
  if (isString(t)) {
    const truncatedTick = t.slice(0, 20);

    if (truncatedTick.length < t.length) {
      return `${truncatedTick}...`;
    }

    return t;
  }

  return t;
};

export const getTickFormat = (
  dimension: ObservableChannelValue | null | undefined,
  valueExample: any,
): ((t: any, i: number) => string) | undefined => {
  if (
    [
      ChartDimensionReduceEnum.PROPORTION,
      ChartDimensionReduceEnum.PROPORTION_FACET,
    ].includes(dimension?.reduce as ChartDimensionReduceEnum)
  ) {
    return (t) => formatNumber(t, { style: "percent" });
  }

  // If the value example is a date, we want the observable handle formatting
  return isDate(valueExample) ? undefined : tickFormat;
};

export const getDisplayKey = <D = null | undefined>(
  dimension: DeepNullableOptional<ChartDimension> | null | undefined,
  keys: AnalyticsKey[],
  fallbackValue: D,
): string | D => {
  if (!dimension?.value) {
    if (
      [
        ChartDimensionReduceEnum.PROPORTION,
        ChartDimensionReduceEnum.PROPORTION_FACET,
      ].includes(dimension?.reduce as ChartDimensionReduceEnum)
    ) {
      return "Percentage";
    }

    return fallbackValue as D;
  }

  const key = keys.find((k) => k.actualKey === dimension.value);

  if (!key) return fallbackValue as D;

  return [
    key.displayKey,
    key.actualKey.startsWith("output_data.") ? " [output]" : "",
  ].join("");
};

const createValueGetter = (
  value: string | undefined | null,
  defaultValue?: any,
) => {
  if (!value) return undefined;

  return (d: any) => {
    const result = get(d, value);
    return defaultValue && isNil(result) ? defaultValue : result;
  };
};

const getDefaultValue = (value: string | undefined | null) => {
  if (!value) return undefined;

  if (value.startsWith("outcome_data.")) return "Not Reported";

  return undefined;
};

export const getObservableChartSpecFromChart = (
  {
    mark,
    dimensions,
  }: { mark: ObservableChartMark; dimensions: ObservableChartDimensions },
  keys: AnalyticsKey[],
): ObservableChartSpec => {
  return {
    mark: mark === "auto" ? undefined : mark,
    x: {
      value: createValueGetter(
        dimensions.x.value,
        getDefaultValue(dimensions.x.value),
      ),
      reduce: undefinedIfAuto(dimensions.x.reduce),
      label: getDisplayKey(dimensions.x, keys, undefined),
    },
    y: {
      value: createValueGetter(
        dimensions.y.value,
        getDefaultValue(dimensions.y.value),
      ),
      reduce: undefinedIfAuto(dimensions.y.reduce),
      label: getDisplayKey(dimensions.y, keys, undefined),
    },
    color: dimensions.color.value
      ? {
          value: createValueGetter(
            dimensions.color.value,
            getDefaultValue(dimensions.color.value),
          ),
          // We don't use reducer for color in form, so we set it to undefined
          // Keep it here for future reference
          reduce: undefined,
          label: getDisplayKey(dimensions.color, keys, undefined),
        }
      : // Use default color if color is not set
        COLORS[0],
    fx: createValueGetter(
      dimensions.fx.value,
      getDefaultValue(dimensions.fx.value),
    ),
    // We don't use this values now, but I keep them here for future reference
    size: undefined,
    fy: undefined,
  };
};

export type ObservableChartSpec = {
  mark: Exclude<ObservableChartMark, ChartMark.AUTO> | undefined;
  x: {
    value: ReturnType<typeof createValueGetter>;
    reduce: Exclude<DimensionReducer, "auto"> | undefined;
    label: string | undefined;
  };
  y: {
    value: ReturnType<typeof createValueGetter>;
    reduce: Exclude<DimensionReducer, "auto"> | undefined;
    label: string | undefined;
  };
  color:
    | {
        value: ReturnType<typeof createValueGetter>;
        reduce: undefined;
        label: string | undefined;
      }
    | string;
  fx: ReturnType<typeof createValueGetter>;
  size: undefined;
  fy: undefined;
};

export enum SectionKey {
  data = "data",
  aux_data = "aux_data",
  output_data = "output_data",
  node_logic = "node_logic",
  outcomes = "outcomes",
  other = "other",
}

export type AnalyticsKey = {
  displayKey: string;
  actualKey: string;
  sectionKey: SectionKey;
  disabled: boolean;
};

const taktileKeyPrefix = "__tktl_";
export const VERSION_KEY = "__tktl_flow_version_name";
export const FLOW_ANALYTICS_OUTCOMES_DATA_KEY_PREFIX = "__tktl_outcomes_data";

export const getAnalyticsKeys = (
  dataColumns: string[],
  auxDataColumns: string[],
  outcomesDataColumns: string[],
  disabledOutputDataKeys: Record<string, boolean>,
): AnalyticsKey[] => {
  const keys: AnalyticsKey[] = [
    {
      displayKey: "version",
      actualKey: VERSION_KEY,
      sectionKey: SectionKey.other,
      disabled: false,
    },
  ];

  for (const dataColumn of dataColumns) {
    keys.push({
      displayKey: `data.${dataColumn}`,
      actualKey: dataColumn,
      sectionKey: SectionKey.data,
      disabled: !!disabledOutputDataKeys[dataColumn],
    });
  }

  for (const auxColumn of auxDataColumns) {
    const thereIsCollision = dataColumns.includes(auxColumn);
    const actualKey = thereIsCollision ? `aux_${auxColumn}` : auxColumn;
    keys.push({
      displayKey: auxColumn,
      actualKey,
      sectionKey: SectionKey.aux_data,
      disabled: !!disabledOutputDataKeys[actualKey],
    });
  }

  for (const outcomeColumn of outcomesDataColumns) {
    const actualKey = `${FLOW_ANALYTICS_OUTCOMES_DATA_KEY_PREFIX}.${outcomeColumn}`;
    keys.push({
      displayKey: outcomeColumn.replace(SUB_COLUMN_SEPARATOR, "."),
      actualKey,
      sectionKey: SectionKey.outcomes,
      disabled: !!disabledOutputDataKeys[actualKey],
    });
  }

  return keys;
};

const speakPython = <T>(value: T): string | T => {
  if (isBoolean(value)) return value ? "True" : "False";
  if (value === null) return "None";
  return value;
};

const parseDate = <T>(value: T): T extends string ? Date | T : T => {
  if (typeof value !== "string") return value as any;

  return isISO8601(value) ? new Date(value) : (value as any);
};

export const preprocessAnalyticsData = <T extends GenericObjectT>(
  data: T[],
  keys: AnalyticsKey[],
) => {
  const nullValuesBase = {} as Record<keyof T, any>;

  keys.forEach((key) => {
    set(nullValuesBase, key.actualKey, null);
  });

  return data.map((row) => {
    const newRow = cloneDeepWith(row, (value, key) => {
      if (typeof key !== "string") return;
      // Do not process taktile keys that are not outcomes data
      if (
        key.startsWith(taktileKeyPrefix) &&
        key !== FLOW_ANALYTICS_OUTCOMES_DATA_KEY_PREFIX
      )
        return;
      // Analytics data have only primitive values, so we don't need to deep clone
      // if there is an object, it's a nested object to transform
      if (isObject(value)) return;

      return speakPython(parseDate(value));
    });

    return defaultsDeep(newRow, nullValuesBase);
  });
};

export const isSummaryChart = (
  chart: Chart | ChartState,
): chart is SummaryChart => {
  return chart.mark === ChartMark.SUMMARY;
};

export const isObservableChart = (
  chart: Chart | ChartState,
): chart is ObservableChart => {
  return chart.mark !== ChartMark.SUMMARY;
};

export const hasVersionBreakdown = (chart: ObservableChart): boolean => {
  return Boolean(chart.dimensions.fx.value === VERSION_KEY);
};

export const filterDataIfExcludeRowsWithoutOutcome = (
  data: GenericObjectT[],
  dimensions: ObservableChartDimensions,
) => {
  const dimensionValuesToExclude = Object.values(dimensions)
    .filter(
      (dimension) => dimension.value && dimension.exclude_rows_without_outcome,
    )
    .map((dimension) => dimension.value as string);

  return data.filter(
    (row) => !dimensionValuesToExclude.some((value) => isNil(get(row, value))),
  );
};
