export type SizeName =
  | "4xs" // 6px
  | "3xs" // 8px
  | "2xs" // 10px
  | "xs" // 12px
  | "sm" // 14px
  | "md" // 16px
  | "lg" // 18px
  | "xl" // 20px
  | "2xl" // 24px
  | "3xl" // 32px
  | "4xl" // 64px
  | "5xl" // 96px
  | "6xl"; // 128px

type Size = {
  width: `w-${number}`;
  height: `h-${number}`;
};

const SIZE_MAP: Record<SizeName, Size> = {
  "4xs": { width: "w-1.5", height: "h-1.5" },
  "3xs": { width: "w-2", height: "h-2" },
  "2xs": { width: "w-2.5", height: "h-2.5" },
  xs: { width: "w-3", height: "h-3" },
  sm: { width: "w-3.5", height: "h-3.5" },
  md: { width: "w-4", height: "h-4" },
  lg: { width: "w-4.5", height: "h-4.5" },
  xl: { width: "w-5", height: "h-5" },
  "2xl": { width: "w-6", height: "h-6" },
  "3xl": { width: "w-8", height: "h-8" },
  "4xl": { width: "w-16", height: "h-16" },
  "5xl": { width: "w-24", height: "h-24" },
  "6xl": { width: "w-32", height: "h-32" },
} as const;

export const getSize = (size: SizeName): Size => SIZE_MAP[size];
