import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { SortableContext } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  faQuestionCircle,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import { v4 } from "uuid";

import { SQLDatabaseProviderT } from "src/api/connectApi/types";
import { FieldErrorsT } from "src/api/types";
import { Button } from "src/base-components/Button";
import { Card } from "src/base-components/Card";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Icon } from "src/base-components/Icon";
import { ReorderHandle } from "src/base-components/ReorderHandle";
import { SimpleDropDown } from "src/base-components/SimpleDropDown";
import { DatabaseConnectionForm } from "src/databaseConnectionNode/types";
import { Tooltip } from "src/design-system/Tooltip";

const createDefaultVariable = () => ({
  id_key: v4(),
  id_expression: v4(),
  key: "",
  expression: "",
  id_type: v4(),
  type_: null,
});

type VariableRowPropsT = {
  id: string;
  index: number;
  immutable: boolean;
  typeOptions: { key: string; value: string }[] | null;
  errorLeft?: string;
  errorRight?: string;
  onRemove?: () => void;
};

const getVariableTypeOptions = (provider: SQLDatabaseProviderT) => {
  if (provider === "bigquery") {
    return [
      { key: "BOOL", value: "Bool" },
      { key: "DATE", value: "Date" },
      { key: "DATETIME", value: "Datetime" },
      { key: "FLOAT64", value: "Float64" },
      { key: "INT64", value: "Int64" },
      { key: "NUMERIC", value: "Numeric" },
      { key: "STRING", value: "String" },
      { key: "TIMESTAMP", value: "Timestamp" },
    ];
  }
  return null;
};

const VariableHeader: React.FC<{ includesType: boolean }> = ({
  includesType,
}) => (
  <div className="flex gap-x-2 text-gray-500 font-inter-normal-12px">
    {/* Empty span with w-5 is replacing the ReorderHandler */}
    <span className="w-5"></span>
    <span className="w-36">Name</span>
    {includesType && <span className="w-28">Type</span>}
    <span className="flex-grow">Value</span>
  </div>
);

const VariableRow: React.FC<VariableRowPropsT> = ({
  id,
  index,
  immutable,
  typeOptions,
  errorLeft,
  errorRight,
  onRemove,
}) => {
  const { listeners, setNodeRef, transform, transition } = useSortable({
    id: id,
  });
  const { control } = useFormContext<DatabaseConnectionForm>();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} className="flex items-center gap-x-2" style={style}>
      <ReorderHandle immutable={immutable} listeners={listeners} />
      <Controller
        control={control}
        name={`variables.${index}.key`}
        render={({ field: controlledField }) => (
          <div className="w-36">
            <AutocompleteCodeInput
              dataLoc={`dbc-node-variable-key-${index}`}
              disabled={immutable}
              error={errorLeft}
              placeholder="variable"
              prefix="$"
              value={controlledField.value}
              plaintext
              onChange={controlledField.onChange}
            />
          </div>
        )}
      />
      {typeOptions && (
        <Controller
          control={control}
          name={`variables.${index}.type_`}
          render={(props) => (
            <SimpleDropDown
              buttonDataLoc={`dbc-node-variable-type-${index}`}
              className="h-8 w-28"
              disabled={immutable}
              elements={typeOptions}
              itemsClassNames="w-full"
              itemsWidth="w-full"
              placeholder="Select type"
              placement="bottomLeft"
              selectedKey={String(props.field.value)}
              onSelect={(value) => {
                props.field.onChange(value);
              }}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name={`variables.${index}.expression`}
        render={({ field: controlledField }) => (
          <div className="flex-grow">
            <AutocompleteCodeInput
              dataLoc={`dbc-node-variable-expression-${index}`}
              disabled={immutable}
              error={errorRight}
              placeholder="data.field"
              value={controlledField.value}
              onChange={controlledField.onChange}
            />
          </div>
        )}
      />
      {onRemove && (
        <Icon
          color={
            immutable ? "text-gray-400" : "text-gray-500 hover:text-gray-700"
          }
          dataLoc="dbc-node-delete-variable-button"
          disabled={immutable}
          icon={faTrashAlt}
          size="xs"
          onClick={onRemove}
        />
      )}
    </div>
  );
};

type PropsT = {
  immutable: boolean;
  runFieldErrors?: FieldErrorsT;
  provider: SQLDatabaseProviderT;
};

export const VariablesMapping: React.FC<PropsT> = ({
  immutable,
  runFieldErrors,
  provider,
}) => {
  const { control } = useFormContext<DatabaseConnectionForm>();
  const {
    fields: variables,
    append,
    remove,
    move,
  } = useFieldArray({
    control,
    name: "variables",
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const fieldToMove = variables.findIndex(
        (variable) => variable.id === active.id,
      );
      const fieldToInsert = variables.findIndex(
        (variable) => variable.id === over.id,
      );
      if (fieldToMove !== -1 && fieldToInsert !== -1) {
        move(fieldToMove, fieldToInsert);
      }
    }
  };
  const typeOptions = getVariableTypeOptions(provider);

  const VariablesTooltipBody: React.FC = () => (
    <div>
      Use variables if your query depends on data from a specific decision run.
      <br />
      <br />A variable name can be any valid Python variable name. The value can
      be any valid Python expression, with both the <code>data</code> and{" "}
      <code>params</code> objects available. For example:
      <br />
      <br />- <code>data.name</code>
      <br />- <code>params.threshold</code>
      <br />- <code>"yes" if data.is_accepted else "no"</code>
    </div>
  );

  return (
    <>
      <span className="text-gray-500 font-inter-normal-12px">
        Define variables to reference within your SQL query
        <Tooltip body={<VariablesTooltipBody />} placement="top">
          <Icon
            color="text-gray-500"
            cursorType="pointer"
            icon={faQuestionCircle}
            size="2xs"
          />
        </Tooltip>
      </span>
      <Card className="mt-3 w-full space-y-3">
        <div className="space-y-2">
          <VariableHeader includesType={Boolean(typeOptions)} />
          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext
              disabled={immutable}
              items={variables.map((row) => row.id)}
            >
              {variables.map((variable, index) => (
                <VariableRow
                  key={variable.id}
                  errorLeft={runFieldErrors?.[variable.id_key]}
                  errorRight={runFieldErrors?.[variable.id_expression]}
                  id={variable.id}
                  immutable={immutable}
                  index={index}
                  typeOptions={typeOptions}
                  onRemove={() => remove(index)}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
        <Button
          dataLoc="dbc-node-add-variable-button"
          disabled={immutable}
          iconLeft={faPlus}
          size="sm"
          variant="secondary"
          onClick={() => append(createDefaultVariable())}
        >
          Add variable
        </Button>
      </Card>
    </>
  );
};
