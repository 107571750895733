import { useState } from "react";

import { NodeTestRunResult } from "src/api/types";
import { ParentNode } from "src/constants/NodeDataTypes";
import { Callout } from "src/design-system/Callout";
import { toastActions } from "src/design-system/Toast/utils";
import { CreateChildDatasetModal } from "src/nodeEditor/CreateChildDatasetModal";
import { useUploadParentNodeDataToChild } from "src/parentFlowNodes/flowNode/uploadParentNodeDataToChild";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { getBaseUrl, getUrlToAuthoringPage } from "src/router/urls";
import { useGraphStore } from "src/store/StoreProvider";
import { useNodeRunState } from "src/store/runState/RunState";

type Props = {
  nodeData: ParentNode["data"];
  testRunResult: NodeTestRunResult;
};

export const CreateChildDatasetPill: React.FC<Props> = ({
  nodeData,
  testRunResult,
}) => {
  const { workspace, flow, version } = useAuthoringContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const childFlow = flow.versions
    .find((refVersion) => refVersion.id === version.id)
    ?.child_flows?.find((childFlow) => childFlow.id === nodeData.child_flow_id);
  const childVersion = version.child_versions?.find(
    (child) => child.id === nodeData.child_flow_version_id,
  );
  const { dataReadyForUpload, uploadDataToChild } =
    useUploadParentNodeDataToChild(
      testRunResult,
      nodeData.child_flow_id ?? "",
      childVersion,
    );

  const { selectedNode } = useGraphStore();
  const selectedNodeRunState = useNodeRunState(selectedNode?.id ?? "");

  const hasFailures =
    selectedNodeRunState?.type === "test-run"
      ? selectedNodeRunState.testResult.failure_count > 0
      : false;

  const handleUploadDataToChild = async (name: string, onlyFailures: boolean) =>
    uploadDataToChild(name, onlyFailures).then(() => {
      if (nodeData.child_flow_id !== null && nodeData.child_flow_version_id) {
        const childFlowId = nodeData.child_flow_id;
        const childVersionId = nodeData.child_flow_version_id;
        toastActions.success({
          title: "Dataset created successfully",
          description: `You can find the dataset in the Child Flow ${childFlow?.name}.`,
          actionText: "Open in new tab",
          onActionClick: () => {
            if (childFlowId) {
              window.open(
                getBaseUrl() +
                  getUrlToAuthoringPage(
                    workspace.organization_id,
                    workspace.id,
                    childFlowId,
                    childVersionId,
                  ),
                "_blank",
              );
            }
          },
        });
      }
    });
  return (
    <>
      <Callout
        action={{
          text: dataReadyForUpload ? "Create dataset" : "Loading",
          onClick: dataReadyForUpload ? () => setModalIsOpen(true) : () => {},
        }}
        type="info"
      >
        Create dataset to continue testing the logic on the Child Flow{" "}
        {childFlow?.name ?? ""}
      </Callout>
      <CreateChildDatasetModal
        createDataset={handleUploadDataToChild}
        flowName={childFlow?.name ?? ""}
        hasFailures={hasFailures}
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      />
    </>
  );
};
