import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { isObject } from "lodash";
import { ReactNode } from "react";
import { twJoin } from "tailwind-merge";
import { useSessionStorage } from "usehooks-ts";

import {
  DataList,
  DataValue as DataValueBase,
} from "src/base-components/DataList";
import { PillToggle } from "src/base-components/PillToggle";
import {
  ExpectedDataPopover,
  MatchPill,
  MismatchPill,
} from "src/dataTable/ExpectedDataCell";
import { hasExpectedData } from "src/dataTable/TableUtils";
import { ResultDataAndAuxRowV2 } from "src/dataTable/types";
import { EmptyState } from "src/design-system/EmptyState";
import { useSelectedResultsRowIndex } from "src/flowContainer/AuthoringUIContext";
import { INTERNAL_DATA_KEYS } from "src/parentFlowNodes/loopNode/constants";

type InspectDataProps = {
  row?: ResultDataAndAuxRowV2 | undefined;
  rowData: Record<string, unknown> | undefined;
  accessedFields: string[];
  isFetching: boolean;
  isHistorical?: boolean;
  withAccessedFields: boolean;
  selectedRowIndex: number | null;
  dividers?: boolean;
};

export const InspectData: React.FC<
  Omit<InspectDataProps, "selectedRowIndex">
> = (props) => {
  const selectedRowIndex = useSelectedResultsRowIndex();

  return <InspectDataBase {...props} selectedRowIndex={selectedRowIndex} />;
};

export const InspectDataBase: React.FC<InspectDataProps> = ({
  row,
  rowData,
  accessedFields,
  isFetching,
  isHistorical,
  withAccessedFields,
  selectedRowIndex,
  dividers = false,
}) => {
  const isOutputRow = row && hasExpectedData(row);

  return (
    <InspectDataList
      accessedFields={accessedFields}
      dividers={dividers}
      isHistorical={isHistorical}
      isLoading={isFetching || !rowData}
      rows={Object.entries(rowData ?? {})
        .filter(([key]) => !INTERNAL_DATA_KEYS.includes(key))
        .map(([key, value]) => [
          key,
          <DataValue
            expectedOutputData={
              isOutputRow ? row?.expectedOutputData : undefined
            }
            expectedOutputKeysMismatch={
              isOutputRow ? row?.expectedOutputKeysMismatch : undefined
            }
            field={key}
            value={value}
          />,
        ])}
      selectedRowIndex={selectedRowIndex}
      withAccessedFields={withAccessedFields}
    />
  );
};

enum InspectDataListView {
  ALL_FIELDS = "all_fields",
  ACCESSIBLE_FIELDS = "accessible_fields",
}

const InspectDataList: React.FC<{
  rows: [string, ReactNode][];
  accessedFields: string[];
  isHistorical?: boolean;
  withAccessedFields: boolean;
  selectedRowIndex: number | null;
  dividers?: boolean;
  isLoading?: boolean;
}> = ({
  rows,
  accessedFields,
  isHistorical,
  withAccessedFields,
  selectedRowIndex,
  dividers = false,
  isLoading = false,
}) => {
  const [selectedField, setSelectedField] =
    useSessionStorage<InspectDataListView>(
      "inspect-data-list-view",
      InspectDataListView.ALL_FIELDS,
    );

  const filteredRows = withAccessedFields
    ? rows.filter(
        ([id]) =>
          selectedField === InspectDataListView.ALL_FIELDS ||
          (selectedField === InspectDataListView.ACCESSIBLE_FIELDS &&
            accessedFields?.includes(id)),
      )
    : rows;

  return (
    <div
      className="flex h-full min-h-0 flex-1 flex-col gap-y-2"
      data-loc="inspect-data-fields"
    >
      {withAccessedFields && (
        <PillToggle value={selectedField} onChange={setSelectedField}>
          <PillToggle.Button value={InspectDataListView.ALL_FIELDS}>
            All fields
          </PillToggle.Button>
          <PillToggle.Button value={InspectDataListView.ACCESSIBLE_FIELDS}>
            Accessed fields
          </PillToggle.Button>
        </PillToggle>
      )}

      <div
        className={twJoin(
          "decideScrollbar h-full min-h-0 flex-1 pr-4",
          dividers && "divide-y divide-gray-100",
        )}
      >
        {isLoading ? (
          <DataList dividers={dividers} isLoading={isLoading} />
        ) : filteredRows.length > 0 ? (
          <DataList dividers={dividers} rows={filteredRows} />
        ) : selectedField === InspectDataListView.ACCESSIBLE_FIELDS ? (
          <EmptyState
            description={
              isHistorical
                ? `This node did not access any fields for this decision`
                : `This node did not access any fields for #${
                    (selectedRowIndex || 0) + 1
                  } test case during the last test run`
            }
            headline="No fields accessed"
            icon={faSearch}
          />
        ) : (
          <EmptyState
            description={
              isHistorical
                ? "This decision had no fields for this decision"
                : "This test case had no fields during the last test run"
            }
            headline="No fields found"
            icon={faSearch}
          />
        )}
      </div>
    </div>
  );
};

const DataValue: React.FC<{
  field: string;
  value: unknown;
  expectedOutputData?: Record<string, unknown>;
  expectedOutputKeysMismatch?: string[];
}> = ({ field, value, expectedOutputData, expectedOutputKeysMismatch }) => {
  const isRowWithExpectedData = expectedOutputData?.[field] !== undefined;
  const isMatch =
    isRowWithExpectedData && !expectedOutputKeysMismatch?.includes(field);

  if (Array.isArray(value) || isObject(value)) {
    return (
      <div className="flex w-full flex-col items-start gap-y-1.5">
        <DataValueBase field={field} value={value} />

        {isRowWithExpectedData && (
          <ExpectedDataPopover
            actualOutput={{
              value: JSON.stringify(value),
              isMatch,
            }}
            expectedOutput={{
              value: JSON.stringify(expectedOutputData?.[field]),
              isMatch,
            }}
          >
            {isMatch ? <MatchPill /> : <MismatchPill />}
          </ExpectedDataPopover>
        )}
      </div>
    );
  }

  return (
    <div className="flex w-full items-center justify-between">
      <DataValueBase field={field} value={value} />

      {isRowWithExpectedData && (
        <ExpectedDataPopover
          actualOutput={{
            value: String(value),
            isMatch,
          }}
          expectedOutput={{
            value: String(expectedOutputData?.[field]),
            isMatch,
          }}
        >
          {isMatch ? <MatchPill /> : <MismatchPill />}
        </ExpectedDataPopover>
      )}
    </div>
  );
};
