import { useState } from "react";

import { usePatchWorkspace } from "./queries";
import { Button } from "src/base-components/Button";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { Modal } from "src/design-system/Modal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedWs: WorkspaceDataplane;
};

export const ModifyWsConfigModal: React.FC<Props> = ({
  isOpen,
  onClose,
  selectedWs,
}) => {
  const [config, setConfig] = useState(selectedWs.config ?? {});

  const patchWs = usePatchWorkspace();
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>Modify Workspace Configuration</Modal.Header>
      <Modal.Content>
        {Object.entries(config)
          .filter(([key]) => {
            return (
              key !== "aurora_generation_sufix" &&
              key !== "snapshot_ARN_aurora" &&
              key !== "use_shared_aurora_in_dev"
            );
          })
          .map(([key, value]) => (
            <div key={key}>
              <Label>{key}</Label>
              <Input
                key={key}
                value={value}
                onChange={(event) => {
                  setConfig((prev) => ({ ...prev, [key]: event.target.value }));
                }}
              />
            </div>
          ))}
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="submit"
            loading={patchWs.isLoading}
            variant="primary"
            onClick={async () => {
              let workspace = selectedWs;
              workspace.config = config;
              await patchWs.mutateAsync(workspace);
              onClose();
            }}
          >
            Submit
          </Button>
        }
      />
    </Modal>
  );
};
