import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { useFormContext } from "react-hook-form";

import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Icon } from "src/base-components/Icon";
import { Textarea } from "src/base-components/Textarea";
import {
  SnowflakeConnectionConfigInputsT,
  Environment,
  SnowflakeConnectionAuthConfigFieldT,
} from "src/connections/types";
import { Tooltip } from "src/design-system/Tooltip";

type SecretPropsT = {
  secretConfigField: SnowflakeConnectionAuthConfigFieldT;
  secretName: string;
  placeholder: string;
  helpText: string;
  isRequired?: boolean;
  environment: Environment;
};

export const SnowflakePrivateKey: React.FC<SecretPropsT> = ({
  secretConfigField,
  secretName,
  placeholder,
  helpText,
  isRequired,
  environment,
}) => {
  const {
    formState: { errors },
    watch,
    setValue,
    register,
  } = useFormContext<SnowflakeConnectionConfigInputsT>();

  const secretKey = `${environment}Config.${secretConfigField}` as const;
  const fieldError =
    errors?.[`${environment}Config`]?.["keyPairAuthConfig"]?.["privateKey"]
      ?.value;

  const secretRecord = watch(secretKey);

  const enableSecret = () => {
    setValue(secretKey, { ...secretRecord, secret: false, value: null });
  };

  return (
    <FormItem
      gap="xs"
      helpClassName="mt-1"
      helpTooltip={helpText}
      isRequired={isRequired}
      label={secretName}
    >
      {Boolean(fieldError) && <ErrorHint>{fieldError?.message}</ErrorHint>}
      <div className="flex w-full items-start gap-x-2">
        <div className="flex-1">
          <Textarea
            disabled={secretRecord.secret}
            errored={Boolean(fieldError)}
            {...register(`${secretKey}.value`, {
              required: isRequired ? `${secretName} is required` : false,
            })}
            placeholder={placeholder}
            fullWidth
          />
        </div>
        {secretRecord.secret && (
          <Tooltip
            placement="right"
            title={`Edit ${secretName.toLowerCase()}`}
            asChild
          >
            <Icon
              color="text-gray-500"
              icon={faEdit}
              size="xs"
              onClick={enableSecret}
            />
          </Tooltip>
        )}
      </div>
    </FormItem>
  );
};
