import React from "react";
import { useForm } from "react-hook-form";

import { Button } from "src/base-components/Button";
import { Input } from "src/base-components/Input";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { Callout, CalloutType } from "src/design-system/Callout";
import { Modal, ModalProps } from "src/design-system/Modal";

type TextConfirmationInputsT = {
  name: string;
};

type PropsT = Pick<
  ModalProps,
  "open" | "variant" | "onClose" | "afterLeave"
> & {
  onConfirm: (() => Promise<void>) | (() => void);
  title: string;
  description: string;
  additionalContent?: React.ReactNode;
  label: string;
  placeholder?: string;
  challengeText?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  calloutPill?: {
    type: CalloutType;
    text: string;
  };
};

export const TextConfirmationModal: React.FC<PropsT> = ({
  onClose,
  onConfirm,
  open,
  challengeText,
  title,
  description,
  additionalContent,
  label,
  placeholder,
  confirmButtonText = "Delete",
  cancelButtonText = "Cancel",
  variant = "danger",
  calloutPill,
  afterLeave,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
  } = useForm<TextConfirmationInputsT>({
    mode: "onChange",
    defaultValues: { name: "" },
  });

  const matchChallengeText = (value: string) => value === challengeText;

  const handleClose = () => {
    onClose();
    reset();
  };

  const submitHandler = handleSubmit(async () => {
    await onConfirm();
    handleClose();
  });

  return (
    <Modal
      afterLeave={afterLeave}
      open={open}
      size="sm"
      variant={variant}
      onClose={handleClose}
    >
      <Modal.Header description={description}>{title}</Modal.Header>
      <form onSubmit={submitHandler}>
        <Modal.Content>
          {additionalContent}
          <div className="mb-2 text-gray-800 font-inter-semibold-13px">
            {label} <RequiredAsterisk />
          </div>
          <Input
            autoComplete="off"
            data-loc="delete-confirmation"
            placeholder={placeholder ?? challengeText}
            fullWidth
            {...register("name", {
              required: true,
              minLength: 1,
              validate: matchChallengeText,
            })}
          />
          {calloutPill && (
            <div className="mt-4">
              <Callout type={calloutPill.type}>{calloutPill.text}</Callout>
            </div>
          )}
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="confirm"
              disabled={!isValid}
              htmlType="submit"
              loading={isSubmitting}
              variant={variant === "danger" ? "warning" : "primary"}
              onClick={submitHandler}
            >
              {confirmButtonText}
            </Button>
          }
          secondaryButton={
            <Button variant="secondary" onClick={handleClose}>
              {cancelButtonText}
            </Button>
          }
        />
      </form>
    </Modal>
  );
};
