import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { useFormContext, Controller } from "react-hook-form";

import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Icon } from "src/base-components/Icon";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import { Switch } from "src/base-components/Switch";
import { Textarea } from "src/base-components/Textarea";
import { ConfigFieldsetCard } from "src/connections/config/FieldsetCard";
import { mTLSKeyPlaceholder } from "src/connections/config/database/shared/MTLSConfig";
import {
  PostgreSQLConnectionConfigInputsT,
  Environment,
} from "src/connections/types";
import { Tooltip } from "src/design-system/Tooltip";

const MTLSHelpText: React.FC = () => {
  return (
    <>
      <p>
        When enabled, you are required to provide your client SSL certificate
        and client SSL key into the corresponding text input areas. Connections
        made to the configured PostgreSQL instance will use these credentials
        for mTLS authentication.
        <br />
        <br />
        When disabled, connections established to the configured PostgreSQL
        instance will proceed using standard authentication, without requiring
        client SSL credentials.
      </p>
    </>
  );
};

type PropsT = {
  environment: Environment;
};

export const PostgreSQLConnectionMTLSConfigFields: React.FC<PropsT> = ({
  environment,
}) => {
  const {
    control,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<PostgreSQLConnectionConfigInputsT>();

  const hasMTLSEnabledKey = `${environment}Config.hasMTLSEnabled` as const;
  const hasMTLSEnabled = watch(hasMTLSEnabledKey);
  const envPrefix = `${environment}Config` as const;
  const sslCACertificateError = errors[envPrefix]?.mTLSConfig?.CACertificate;
  const sslCertificateError = errors[envPrefix]?.mTLSConfig?.clientCertificate;
  const privateKeyError = errors[envPrefix]?.mTLSConfig?.clientKey;
  const privateKeyRecord = watch(`${envPrefix}.mTLSConfig.clientKey`);
  const enableSecret = () => {
    setValue(`${envPrefix}.mTLSConfig.clientKey`, {
      ...privateKeyRecord,
      secret: false,
      value: null,
    });
  };
  return (
    <div className="mb-6 last:mb-0">
      <FormItem
        className="flex flex-row items-center justify-between"
        description="Require client-side authentication credentials"
        gap="xxs"
        helpTooltipBody={<MTLSHelpText />}
        label="Enable mutual TLS"
      >
        <Controller
          control={control}
          name={hasMTLSEnabledKey}
          render={(props) => (
            <Switch
              enabled={props.field.value}
              onChange={() => {
                props.field.onChange(!props.field.value);
              }}
            />
          )}
        />
      </FormItem>
      {hasMTLSEnabled && (
        <ConfigFieldsetCard>
          <FormItem
            description="The client certificate used for authentication"
            gap="xxs"
            label="Client certificate"
            isRequired
          >
            {Boolean(sslCertificateError) && (
              <ErrorHint>{sslCertificateError?.message}</ErrorHint>
            )}
            <div className="flex w-full items-start gap-x-2">
              <div className="flex-1">
                <Textarea
                  errored={Boolean(sslCertificateError)}
                  {...register(
                    `${environment}Config.mTLSConfig.clientCertificate`,
                    {
                      required: true,
                    },
                  )}
                  placeholder={mTLSKeyPlaceholder}
                  fullWidth
                />
              </div>
            </div>
          </FormItem>

          <FormItem
            description="The client key used for authentication"
            gap="xxs"
            label="Client key"
            isRequired
          >
            {Boolean(privateKeyError) && (
              <ErrorHint>{privateKeyError?.message}</ErrorHint>
            )}
            <div className="flex w-full items-start gap-x-2">
              <div className="flex-1">
                <Textarea
                  disabled={privateKeyRecord.secret}
                  errored={Boolean(privateKeyError)}
                  {...register(`${envPrefix}.mTLSConfig.clientKey.value`, {
                    required: true,
                  })}
                  placeholder={mTLSKeyPlaceholder}
                  fullWidth
                />
              </div>
              {privateKeyRecord.secret && (
                <Tooltip placement="right" title="Edit client key" asChild>
                  <Icon
                    color="text-gray-500"
                    icon={faEdit}
                    size="xs"
                    onClick={enableSecret}
                  />
                </Tooltip>
              )}
            </div>
          </FormItem>

          <FormItem
            description="The CA certificate used to verify the server certificate"
            gap="xxs"
            label="CA certificate"
          >
            {Boolean(sslCACertificateError) && (
              <ErrorHint>{sslCACertificateError?.message}</ErrorHint>
            )}
            <div className="flex w-full items-start gap-x-2">
              <div className="flex-1">
                <Textarea
                  errored={Boolean(sslCACertificateError)}
                  {...register(
                    `${environment}Config.mTLSConfig.CACertificate`,
                    {
                      required: true,
                    },
                  )}
                  placeholder={mTLSKeyPlaceholder}
                  fullWidth
                />
              </div>
            </div>
          </FormItem>

          <FormItem gap="xxs" label="Verification mode" isRequired>
            <Controller
              control={control}
              name={`${environment}Config.mTLSConfig.mode`}
              render={(props) => (
                <SimpleRadioGroup
                  orientation="vertical"
                  value={String(props.field.value)}
                  onValueChange={props.field.onChange}
                >
                  <SimpleRadioGroup.Item
                    label="Full verification"
                    labelClassName="pl-2.5"
                    value="verify-full"
                  />
                  <SimpleRadioGroup.Item
                    label="Verify CA certificate"
                    labelClassName="pl-2.5"
                    value="verify-ca"
                  />
                  <SimpleRadioGroup.Item
                    label="Skip CA certificate verification"
                    labelClassName="pl-2.5"
                    value="require"
                  />
                </SimpleRadioGroup>
              )}
            />
          </FormItem>
        </ConfigFieldsetCard>
      )}
    </div>
  );
};
