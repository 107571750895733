import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import { IntegrationResourceT } from "src/integrationNode/types";

const CREDIT_HISTORY_IDENTIFICATION_TYPES = [
  {
    key: `"NIT"`,
    value:
      "NIT (Tax identification number/Número de identificación tributaria)",
  },
  {
    key: `"PJE"`,
    value: "PJE (Foreign legal entity/Persona jurídica del extranjero)",
  },
];

const CHAMBER_OF_COMMERCE_IDENTIFICATION_TYPES = [
  ...CREDIT_HISTORY_IDENTIFICATION_TYPES,
  {
    key: `"CC"`,
    value: "CC (Citizenship card/Cedula de ciudadanía)",
  },
  {
    key: `"CE"`,
    value: "CE (Alien card/Cedula de extranjería)",
  },
];

export const getDefaultExperianColombiaCreditHistoryForLegalEntities =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "experian_colombia",
      resource: "experian_colombia_credit_history_for_legal_entities",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        identification: {
          id: uuidV4(),
          type: "text",
          displayName: "Subject identification number (identificación)",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        identification_type: {
          id: uuidV4(),
          type: "dropDown",
          displayName: "Type of identification number (tipo de identificación)",
          assignedTo: "",
          elements: CREDIT_HISTORY_IDENTIFICATION_TYPES,
          rules: {
            required: true,
          },
        },
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(CommonOutputMappingKey.RETRIEVAL_DATETIME),
        score: {
          ...getNamedOutputMapping("Credit score"),
          hint: `The credit score of the queried legal entity, as a string`,
        },
        score_reason: {
          ...getNamedOutputMapping("Credit score reason"),
          hint: `The reason provided for the credit score, extracted from the report and composed into a single string`,
        },
        response_code: {
          ...getNamedOutputMapping("Response code"),
          hint: `The response code of the upstream request, which can be used to identify empty reports`,
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultExperianColombiaChamberOfCommerce =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "experian_colombia",
      resource: "experian_colombia_chamber_of_commerce",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        identification: {
          id: uuidV4(),
          type: "text",
          displayName: "Subject identification number (identificación)",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        identification_type: {
          id: uuidV4(),
          type: "dropDown",
          displayName: "Type of identification number (tipo de identificación)",
          assignedTo: "",
          elements: CHAMBER_OF_COMMERCE_IDENTIFICATION_TYPES,
          rules: {
            required: true,
          },
        },
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(CommonOutputMappingKey.RETRIEVAL_DATETIME),
        response_code: {
          ...getNamedOutputMapping("Response code"),
          hint: `The response code of the upstream request, which can be used to identify empty reports`,
        },
      },
    },
    config: defaultResourceConfig,
  });
