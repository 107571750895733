import { useQuery } from "@tanstack/react-query";
import { DateRange } from "react-day-picker";

import {
  ExporterMetricsEndpoint,
  GetDecisionSummaryParams,
} from "src/api/endpoints";
import { DecisionSummaryResponse } from "src/api/types";

export const useDecisionsOverview = (
  baseUrl: string | undefined,
  params: Omit<
    GetDecisionSummaryParams,
    "start_start_time" | "end_start_time"
  > & {
    timeWindow?: DateRange;
  },
) => {
  return useQuery<DecisionSummaryResponse, Error>(
    ["decision-overview", JSON.stringify(params)],
    () =>
      ExporterMetricsEndpoint.getDecisionSummary(baseUrl!, {
        start_start_time: params.timeWindow?.from?.toISOString(),
        end_start_time: params.timeWindow?.to?.toISOString(),
        ...params,
      }),
    {
      enabled: Boolean(baseUrl),
    },
  );
};
