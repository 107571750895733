import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

type BaseProps = {
  errored?: boolean;
  selected?: boolean;
  icon?: React.ReactNode;
  displayLock?: boolean;
};

type LinkProps = BaseProps & {
  linkTarget: string;
  reloadDocument?: boolean;
  onClick?: never;
};

type OnClickProps = BaseProps & {
  linkTarget?: never;
  reloadDocument?: never;
  onClick: () => void;
};

export type NavIconContainerProps = LinkProps | OnClickProps;

type WrapperProps = {
  linkTarget?: string;
  reloadDocument?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  className: string;
};

const Wrapper: React.FC<WrapperProps> = ({
  linkTarget,
  reloadDocument,
  children,
  className,
  onClick,
}) => {
  if (linkTarget) {
    return (
      <Link
        className={className}
        reloadDocument={reloadDocument}
        to={linkTarget}
      >
        {children}
      </Link>
    );
  }

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};

/** Main component for flow sidebar buttons. */
export const NavIconContainer: React.FC<NavIconContainerProps> = ({
  errored,
  linkTarget,
  reloadDocument,
  icon,
  selected,
  onClick,
}) => {
  return (
    <Wrapper
      className={twJoin(
        "relative z-10 flex cursor-pointer items-center justify-center rounded-lg p-1.5 text-xl",
        selected
          ? "bg-indigo-600 text-white"
          : "text-gray-500 hover:text-indigo-600",
      )}
      linkTarget={linkTarget}
      reloadDocument={reloadDocument}
      onClick={onClick}
    >
      <div className="flex h-6 w-6 items-center justify-center">{icon}</div>
      {errored && (
        <div
          className={twJoin(
            "absolute right-2 top-2 h-1.5 w-1.5 rounded-full border bg-red-500",
            selected ? "border-indigo-600" : "border-white",
          )}
        />
      )}
    </Wrapper>
  );
};
