import {
  faCheck,
  faChevronDown,
  faDatabase,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { keyBy } from "lodash";
import { twMerge } from "tailwind-merge";

import { useConnections } from "src/api/connectApi/queries";
import { isSQLDatabaseProvider } from "src/api/connectApi/types";
import { Dataset } from "src/api/types";
import S3Icon from "src/assets/S3Icon.svg?react";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { ProviderIcon } from "src/connections/config/Icon";
import { SourceIcon as DatasetSourceIcon } from "src/datasets/DatasetList/SourceIcon";
import { DecideDropzone as Dropzone } from "src/datasets/Dropzone";
import {
  useCreateDatasetFromScratchMutation,
  useDatasets,
} from "src/datasets/api/queries";
import { DEFAULT_DATASET_NAME } from "src/datasets/constants";
import { useDatasetFileUpload as useDatasetUpload } from "src/datasets/useDatasetUpload";
import { separateFilenameExtension } from "src/datasets/utils";
import { useModal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { useCapabilities } from "src/hooks/useCapabilities";
import {
  useCreateJobSource,
  useDeleteJobSource,
  useJobSources,
  useUpdateJob,
} from "src/jobs/api/queries";
import { EditDatasetSourceModal } from "src/jobs/common/EditDatasetSourceModal";
import {
  S3BucketDatasetModalCreate,
  S3BucketDatasetModalEdit,
} from "src/jobs/common/S3BucketDatasetModal";
import { SQLSourceModal } from "src/jobs/common/SQLSourceModal/SQLSourceModal";
import { SourceActions } from "src/jobs/common/SourceActions";
import { DEFAULT_METADATA_MAPPING } from "src/jobs/common/constants";
import { handlePreconditionError } from "src/jobs/jobUtils";
import {
  Job,
  JobS3BucketDatasetSource,
  JobDatasetSource,
  JobSQLSource,
  JobSource,
} from "src/jobs/types";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import {
  useFlowContext,
  useWorkspaceContext,
} from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";

const AddSourceButton: React.FC<{
  dataLoc?: string;
  children: React.ReactNode;
  onClick?: () => void;
}> = ({ children, onClick, dataLoc }) => (
  <button
    className="flex w-full items-center gap-x-1 py-3.5 pl-5 pr-6 text-gray-800 font-inter-normal-12px hover:bg-gray-50"
    data-loc={dataLoc}
    onClick={onClick}
  >
    <Icon color="text-gray-500" icon={faPlus} size="2xs" />
    <p>{children}</p>
  </button>
);

const isDatasetSource = (source: JobSource): source is JobDatasetSource =>
  source.configuration.type === "dataset";

const isS3BucketDatasetSource = (
  source: JobSource,
): source is JobS3BucketDatasetSource =>
  source.configuration.type === "s3_bucket_dataset";

export const SourceDropdown: React.FC<{ job: Job }> = ({ job }) => {
  const { workspace } = useFlowContext();
  const { jobs: jobCapabilities } = useCapabilities();
  const datasets = useDatasets({
    flowId: job.flow_id,
    baseUrl: workspace.base_url,
  });
  const sources = useJobSources(workspace.base_url!, job.id);
  const createDatasetFromScratch = useCreateDatasetFromScratchMutation(
    workspace?.base_url,
  );
  const { uploadDataset } = useDatasetUpload();
  const createSource = useCreateJobSource(workspace.base_url!, job.id);
  const jobUpdateMutation = useUpdateJob(workspace.base_url!, job);
  const deleteSource = useDeleteJobSource(workspace.base_url!, job.id);

  const {
    isOpen: isDeleteModalOpen,
    data: sourceToDelete,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal<JobSource>();

  const {
    isOpen: isSQLSourceModalOpen,
    data: editingSourceId,
    openModal: openSQLSourceModal,
    closeModal: closeSQLSourceModal,
  } = useModal<JobSQLSource["id"]>();

  const {
    isOpen: isDatasetSourceModalOpen,
    data: editingDatasetSource,
    openModal: openDatasetSourceModal,
    closeModal: closeDatasetSourceModal,
  } = useModal<JobDatasetSource>();

  const {
    isOpen: isS3BucketDatasetModalOpen,
    openModal: openS3BucketDatasetModal,
    closeModal: closeS3BucketDatasetModal,
  } = useModal<undefined>();

  const {
    isOpen: isS3BucketDatasetModalEditOpen,
    data: editingS3Source,
    openModal: openS3BucketDatasetEditModal,
    closeModal: closeS3BucketDatasetEditModal,
  } = useModal<JobS3BucketDatasetSource>();

  const createSQLSource = async () => {
    try {
      const sqlSource = (await createSource.mutateAsync({
        name: "Untitled SQL source",
        job_id: job.id,
        configuration: {
          type: "sql",
          connection_id: null,
          query: null,
          metadata_mapping: DEFAULT_METADATA_MAPPING,
          mapping: [],
          credentials_for_sandbox_run: "sandbox_credentials",
        },
      })) as JobSQLSource;
      await jobUpdateMutation.mutateAsync({
        active_source: sqlSource.id,
        etag: job.etag,
      });

      openSQLSourceModal(sqlSource.id);
    } catch (e) {
      const isPreconditionError = await handlePreconditionError(
        workspace,
        job,
        e,
      );
      if (!isPreconditionError) {
        toastActions.failure({
          title: "Source creation failed",
          description: TAKTILE_TEAM_NOTIFIED,
        });
      }
    }
  };

  const onDeleteModalConfirm = async () => {
    if (sourceToDelete) {
      await deleteSource.mutateAsync(sourceToDelete.id);
    }
    closeDeleteModal();
  };

  if (!datasets.data || !sources.data)
    return <SkeletonPlaceholder height="h-4" width="w-16" />;

  const datasetsById = keyBy(datasets.data, "id");

  const onSubmitFiles = async (files: File[], fileErrors: string[]) => {
    if (fileErrors.length > 0) {
      toastActions.failure({
        title: "Error uploading dataset files",
        description: fileErrors.join(", "),
      });
      return;
    }
    if (files.length === 0) {
      return;
    }
    const toastLoadingId = toastActions.loading({
      title: "Uploading source file",
      description:
        "This could take a few minutes. The process will continue in the background even when this notification is dismissed.",
      duration: Infinity,
    });
    try {
      const datasetFileUpload = await uploadDataset({
        file: files[0],
        purpose: "job_source",
      });
      const finishedDatasetFileUpload = await datasetFileUpload.finishedPromise;
      if (finishedDatasetFileUpload.status !== "COMPLETED") {
        throw new Error("File upload failed");
      }
      const createdSource = await createSource.mutateAsync({
        name: datasetFileUpload.file_name,
        job_id: job.id,
        configuration: {
          type: "dataset",
          dataset_id: finishedDatasetFileUpload.dataset_id,
        },
      });
      await jobUpdateMutation.mutateAsync({
        active_source: createdSource.id,
        etag: job.etag,
      });
      toastActions.success({
        id: toastLoadingId,
        title: "Source successfully created",
      });
    } catch (e) {
      const isPreconditionError = await handlePreconditionError(
        workspace,
        job,
        e,
      );
      if (!isPreconditionError) {
        logger.error(e);
        toastActions.failure({
          id: toastLoadingId,
          title: "Source creation failed",
          description: TAKTILE_TEAM_NOTIFIED,
        });
      }
    }
  };

  const renderFooter = () => (
    <div
      className={twMerge(
        "bg-white",
        sources.data.length !== 0 && "mt-2.5 border-t border-gray-200",
      )}
    >
      {sources.data.length !== 0 && (
        <div className="mt-2.5 cursor-default py-2 pl-6 text-gray-800 font-inter-normal-12px">
          Add data source
        </div>
      )}
      <div
        className="flex items-center justify-between py-3.5 pl-5 pr-6 text-gray-800 font-inter-normal-12px hover:bg-gray-50"
        onClick={async () => {
          try {
            const dataset = await createDatasetFromScratch.mutateAsync({
              name: DEFAULT_DATASET_NAME,
              flow_id: job.flow_id,
              input_columns: [],
              mock_columns: [],
              purpose: "job_source",
            });
            const createdSource = (await createSource.mutateAsync({
              name: "Untitled source",
              job_id: job.id,
              configuration: { type: "dataset", dataset_id: dataset.id },
            })) as JobDatasetSource;
            await jobUpdateMutation.mutateAsync({
              active_source: createdSource.id,
              etag: job.etag,
            });
            openDatasetSourceModal(createdSource);
          } catch (e) {
            const isPreconditionError = await handlePreconditionError(
              workspace,
              job,
              e,
            );
            if (!isPreconditionError) {
              toastActions.failure({
                title: "Source creation failed",
                description: TAKTILE_TEAM_NOTIFIED,
              });
            }
          }
        }}
      >
        <div className="flex flex-row items-center gap-x-1">
          <Icon color="text-gray-500" icon={faPlus} size="2xs" />
          <p>From scratch</p>
        </div>
      </div>
      <Dropzone
        content={({ getRootProps, getInputProps }) => (
          <div //stop propagation so that the dropdown is not closed before a file is select for upload.
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div {...getRootProps()} data-loc="datasets-upload">
              <AddSourceButton>Upload file</AddSourceButton>
              <input {...getInputProps()} />
            </div>
          </div>
        )}
        onSubmitFiles={onSubmitFiles}
      />
      <AddSourceButton dataLoc="create-sql-source" onClick={createSQLSource}>
        From Database Connection
      </AddSourceButton>
      {isFeatureFlagEnabled(FEATURE_FLAGS.jobS3BucketSourceAndDestination) && (
        <AddSourceButton
          dataLoc="create-batch-data-upload"
          onClick={() => openS3BucketDatasetModal()}
        >
          AWS S3 Bucket
        </AddSourceButton>
      )}
    </div>
  );

  const handleSelect = async (sourceId: string) => {
    try {
      await jobUpdateMutation.mutateAsync({
        active_source: sourceId,
        etag: job.etag,
      });
    } catch (error) {
      const isPreconditionError = await handlePreconditionError(
        workspace,
        job,
        error,
      );

      if (!isPreconditionError)
        toastActions.failure({
          title: "Failed to change the active source of the Job",
          description: TAKTILE_TEAM_NOTIFIED,
        });
    }
  };

  const disabled = !jobCapabilities.canEdit || job.status === "active";

  return (
    <>
      <FixedPositionedDropdown
        buttonClassName="border-none active:ring-0 focus:ring-0 ring-0"
        buttonDataLoc="set-data-source-dropdown-button"
        dataLoc="set-data-source-dropdown"
        disabled={disabled}
        elements={sources.data.map((source) => ({
          key: source.id,
          value: {
            source,
            dataset: isDatasetSource(source)
              ? datasetsById[source.configuration.dataset_id]
              : undefined,
          },
          sectionKey: source.configuration.type,
        }))}
        itemsClassNames="max-h-[min(500px,50vh)] overflow-auto w-[283px] z-30"
        placement="bottomLeft"
        placementOffsetX={-20}
        renderButtonValue={(selectedValue) => {
          if (!selectedValue)
            return (
              <div className="text-indigo-600 font-inter-medium-12px">
                Set a Data Source
              </div>
            );

          return (
            <div className="flex items-center">
              <div className="mr-1">
                <SourceIcon
                  color="text-gray-500"
                  dataset={selectedValue.dataset}
                  size="sm"
                  source={selectedValue.source}
                />
              </div>
              <p className="ml-0.5 max-w-44 truncate text-gray-800 font-inter-medium-12px">
                {selectedValue.source.name}
              </p>
              {!disabled && (
                <Icon color="text-gray-500" icon={faChevronDown} size="2xs" />
              )}
            </div>
          );
        }}
        renderFooter={renderFooter}
        renderValue={({ value: { dataset, source }, selected }) => (
          <div
            className="group flex items-center gap-x-2 px-5 py-3.5 font-inter-normal-12px"
            data-loc={`source-item-${source.name}`}
          >
            <div className="flex-0">
              <SourceIcon dataset={dataset} source={source} />
            </div>
            <div className="flex min-w-0 flex-1 items-center">
              <p className="ml-1 truncate">{source.name}</p>
            </div>
            <div className="flex flex-row items-center">
              {selected && (
                <Icon
                  color="text-indigo-600 group-hover:hidden"
                  icon={faCheck}
                  size="xs"
                />
              )}
              <div className="hidden items-center space-x-3 group-hover:flex">
                <SourceActions
                  dataset={dataset}
                  source={source}
                  onDelete={() => openDeleteModal(source)}
                  onEdit={
                    isDatasetSource(source)
                      ? () => openDatasetSourceModal(source)
                      : isS3BucketDatasetSource(source)
                        ? () =>
                            openS3BucketDatasetEditModal(
                              source as JobS3BucketDatasetSource,
                            )
                        : () => openSQLSourceModal(source.id)
                  }
                />
              </div>
            </div>
          </div>
        )}
        sections={[
          { key: "dataset", displayText: "Files" },
          { key: "sql", displayText: "Database connections" },
          { key: "s3_bucket_dataset", displayText: "S3 Bucket Datasets" },
        ]}
        selected={job.active_source?.id}
        listMatchesButtonWidth
        onSelect={handleSelect}
      />
      <ConfirmationModal
        confirmationButtonText="Yes, delete"
        open={isDeleteModalOpen}
        title={
          sourceToDelete
            ? `Delete ${separateFilenameExtension(sourceToDelete.name).name}`
            : ""
        }
        variant="danger"
        onClose={closeDeleteModal}
        onConfirm={onDeleteModalConfirm}
      >
        <div className="text-gray-500 font-inter-normal-12px">
          Are you sure you want to delete this source? This action cannot be
          undone.
        </div>
      </ConfirmationModal>
      <SQLSourceModal
        job={job}
        open={isSQLSourceModalOpen}
        sourceId={editingSourceId}
        onClose={closeSQLSourceModal}
      />
      <EditDatasetSourceModal
        dataset={
          editingDatasetSource
            ? datasetsById[editingDatasetSource.configuration.dataset_id]
            : undefined
        }
        job={job}
        open={isDatasetSourceModalOpen}
        sourceId={editingDatasetSource?.id}
        onClose={closeDatasetSourceModal}
      />
      <S3BucketDatasetModalCreate
        afterLeave={closeS3BucketDatasetModal}
        job={job}
        open={isS3BucketDatasetModalOpen}
        onClose={() => {
          closeS3BucketDatasetModal();
        }}
        onSuccess={openS3BucketDatasetEditModal}
      />
      <S3BucketDatasetModalEdit
        afterLeave={closeS3BucketDatasetEditModal}
        open={isS3BucketDatasetModalEditOpen}
        source={editingS3Source}
        onClose={closeS3BucketDatasetEditModal}
      />
    </>
  );
};

export const S3BucketIcon: React.FC = () => {
  return (
    <div className="rounded-md border border-gray-200 p-1">
      <S3Icon className="h-3 w-3" />
    </div>
  );
};

const SourceIcon: React.FC<{
  source: JobSource;
  dataset?: Dataset;
  size?: "sm" | "md";
  color?: string;
}> = ({ source, dataset, size = "md", color }) => {
  if (isDatasetSource(source)) {
    const mappedSize = size === "md" ? "xs" : "2xs";
    return (
      <DatasetSourceIcon
        color={color}
        size={mappedSize}
        source={dataset?.source ?? "scratch"}
      />
    );
  }

  if (isS3BucketDatasetSource(source)) {
    return <S3BucketIcon />;
  }

  return <SqlSourceIcon size={size} source={source} />;
};

const SqlSourceIcon: React.FC<{ source: JobSQLSource; size?: "sm" | "md" }> = ({
  source,
  size = "md",
}) => {
  const { workspace } = useWorkspaceContext();
  const connections = useConnections(workspace.base_url);

  const connectionsById = keyBy(
    connections.data?.filter((connection) =>
      isSQLDatabaseProvider(connection.provider),
    ),
    "id",
  );

  if (
    source.configuration.connection_id &&
    connectionsById[source.configuration.connection_id]
  ) {
    const mappedSize = size === "md" ? "sm" : "xs";

    return (
      <ProviderIcon
        provider={connectionsById[source.configuration.connection_id]?.provider}
        size={mappedSize}
      />
    );
  }

  const mappedSize = size === "md" ? "xs" : "2xs";
  return <Icon color="text-gray-500" icon={faDatabase} size={mappedSize} />;
};
