import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { faHome } from "@fortawesome/pro-solid-svg-icons";
import { ReactNode, Component } from "react";

import { EmptyState } from "src/design-system/EmptyState";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { parseUrl } from "src/router/urls";
import { logger } from "src/utils/logger";

type Props = { children: ReactNode; fallbackErrorContent?: ReactNode };

export class ErrorBoundary extends Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    logger.error("Error boundary caught an error", {
      // Done because we want this stringified, but simply stringifying and error gives an empty object.
      // https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
      error: JSON.parse(
        JSON.stringify(error, Object.getOwnPropertyNames(error)),
      ),
      errorInfo,
    });
    const { orgId } = parseUrl(window.location.href);
    tracker.emit(
      trackingEvents.clientRuntimeError({
        error_message: error.message,
        error_stack: error.stack,
        error_component_stack: errorInfo.componentStack,
        organization_id: orgId,
      }),
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex h-full items-center justify-center">
          {this.props.fallbackErrorContent || (
            <EmptyState
              actionButton={{
                text: "Go to home page",
                icon: faHome,
                onClick: () => window.location.assign("/"),
              }}
              dataLoc="error-boundary"
              description="Oops, something went wrong."
              headline="Error"
              icon={faExclamationCircle}
              variant="error"
            />
          )}
        </div>
      );
    }

    return this.props.children;
  }
}
