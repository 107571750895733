import { omit } from "lodash";

import {
  ConnectionConfiguration,
  ConnectionConfigurationCreateT,
  ConnectionCreateT,
  ConnectionT,
  RedshiftConnectionConfigurationBE,
} from "src/api/connectApi/types";
import { SSH_TUNNEL_DEFAULTS } from "src/connections/config/database/sshTunnel/SSHTunnelDefaultValues";
import {
  filterSecretsByEnvironment,
  filterUpdatedSecrets,
  findSecret,
  getDefaultSecret,
  markSecrets,
  setSecretEnv,
} from "src/connections/model/common";
import {
  KeyValuePairT,
  RedshiftConnectionConfigInputsT,
  RedshiftConnectionConfigT,
} from "src/connections/types";

export const getRedshiftConnectionConfigDefaultValues = (
  env: string | null,
): RedshiftConnectionConfigT => ({
  host: "",
  port: null,
  databaseName: "",
  clusterIdentifier: "",
  awsRegion: "",
  roleArn: "",
  allowTestInvocations: false,
  authMethod: "username_password",
  hasSslEnabled: false,
  usernamePasswordConfig: {
    username: getDefaultSecret("username", env),
    password: getDefaultSecret("password", env),
  },
  awsIamConfig: {
    databaseUsername: getDefaultSecret("database_username", env),
    awsAccessKeyId: getDefaultSecret("aws_access_key_id", env),
    awsSecretKey: getDefaultSecret("aws_secret_key", env),
  },
  hasSSHTunnelEnabled: false,
  SSHTunnel: SSH_TUNNEL_DEFAULTS,
});

export const getRedshiftConnectionConfigInputsDefaultValues =
  (): RedshiftConnectionConfigInputsT => ({
    name: "",
    dataRetention: {
      value: 0,
      unit: "days",
    },
    enableNonProdConfigs: false,
    productionConfig: getRedshiftConnectionConfigDefaultValues(null),
    sandboxConfig: getRedshiftConnectionConfigDefaultValues("sandbox"),
  });

const convertBEConnectionConfigurationToRedshiftConnectionConfig = (
  config: ConnectionConfiguration,
  envSecrets: KeyValuePairT[],
  env: string | null,
): RedshiftConnectionConfigT => {
  const redshiftConfig = config as RedshiftConnectionConfigurationBE;
  return {
    databaseName: redshiftConfig.database_name,
    host: redshiftConfig.host,
    port: redshiftConfig.port,
    clusterIdentifier: redshiftConfig.cluster_identifier,
    awsRegion: redshiftConfig.aws_region,
    roleArn: redshiftConfig.role_arn,
    allowTestInvocations: config.allow_test_invocations ?? false,
    hasSslEnabled: redshiftConfig.has_ssl_enabled ?? false,
    hasSSHTunnelEnabled: redshiftConfig.has_ssh_tunnel_enabled ?? false,
    SSHTunnel: {
      host: redshiftConfig.ssh_tunnel?.host ?? "",
      port: redshiftConfig.ssh_tunnel?.port ?? 22,
      user: redshiftConfig.ssh_tunnel?.user ?? "",
      privateKey:
        findSecret(envSecrets, "ssh_private_key") ??
        getDefaultSecret("ssh_private_key", env),
    },
    authMethod: redshiftConfig.auth_method,
    usernamePasswordConfig: {
      username:
        findSecret(envSecrets, "username") ?? getDefaultSecret("username", env),
      password:
        findSecret(envSecrets, "password") ?? getDefaultSecret("password", env),
    },
    awsIamConfig: {
      databaseUsername:
        findSecret(envSecrets, "database_username") ??
        getDefaultSecret("database_username", env),
      awsAccessKeyId:
        findSecret(envSecrets, "aws_access_key_id") ??
        getDefaultSecret("aws_access_key_id", env),
      awsSecretKey:
        findSecret(envSecrets, "aws_secret_key") ??
        getDefaultSecret("aws_secret_key", env),
    },
  };
};

const convertBENonProdEnvConfigToRedshiftConnectionConfigs = (
  nonProdEnvConfigs: Record<string, ConnectionConfiguration>,
  env: string,
  allSecrets: KeyValuePairT[],
): RedshiftConnectionConfigT | null => {
  if (!nonProdEnvConfigs?.[env]) return null;
  const envSecrets = filterSecretsByEnvironment(allSecrets, env);
  return convertBEConnectionConfigurationToRedshiftConnectionConfig(
    nonProdEnvConfigs[env],
    envSecrets,
    env,
  );
};

/**
 * Converts BE Redshift connection configuration to FE format.
 * If the provider of the connection is not "redshift", it throws an error.
 *
 * @param {ConnectionT} connection - The Redshift connection object in BE format.
 * @returns {RedshiftConnectionConfigInputsT} - The converted Redshift connection object in FE format suitable to use with components.
 * @throws {Error} - Throws an error if the provider of the connection is not "redshift".
 */
export const convertBEConnectionToRedshiftConnectionConfigInputs = (
  connection: ConnectionT,
): RedshiftConnectionConfigInputsT => {
  if (connection.provider !== "redshift")
    throw new Error(
      `Invalid connection type: ${connection.provider} for Redshift converter`,
    );

  const defaultValues = getRedshiftConnectionConfigInputsDefaultValues();
  const nonProdEnvConfigs = connection.non_prod_env_configs;

  const allSecrets = markSecrets(connection.secrets) ?? [];
  const prodSecrets = filterSecretsByEnvironment(allSecrets, null);
  return {
    ...defaultValues,
    name: connection.name,
    dataRetention: connection.data_retention ?? defaultValues.dataRetention,
    enableNonProdConfigs: connection.enable_non_prod_configs,
    productionConfig:
      convertBEConnectionConfigurationToRedshiftConnectionConfig(
        connection.configuration,
        prodSecrets,
        null,
      ),
    sandboxConfig:
      nonProdEnvConfigs && nonProdEnvConfigs["sandbox"]
        ? convertBENonProdEnvConfigToRedshiftConnectionConfigs(
            nonProdEnvConfigs,
            "sandbox",
            allSecrets,
          )
        : getRedshiftConnectionConfigDefaultValues("sandbox"),
  };
};

const extractSecretsFromRedshiftConnectionConfig = (
  config: RedshiftConnectionConfigT,
  env: string | null,
): KeyValuePairT[] => {
  const secrets = config.SSHTunnel
    ? [setSecretEnv(config.SSHTunnel.privateKey, env)]
    : [];

  switch (config.authMethod) {
    case "username_password": {
      return secrets.concat([
        setSecretEnv(config.usernamePasswordConfig.username, env),
        setSecretEnv(config.usernamePasswordConfig.password, env),
      ]);
    }
    case "aws_iam": {
      return secrets.concat([
        setSecretEnv(config.awsIamConfig.databaseUsername, env),
        setSecretEnv(config.awsIamConfig.awsAccessKeyId, env),
        setSecretEnv(config.awsIamConfig.awsSecretKey, env),
      ]);
    }
  }
};

const extractSecretsFromRedshiftConnectionConfigInputs = (
  config: RedshiftConnectionConfigInputsT,
): KeyValuePairT[] => {
  const secrets = extractSecretsFromRedshiftConnectionConfig(
    config.productionConfig,
    null,
  );

  // Only include non-prod secrets if non-prod configs are enabled
  if (config.enableNonProdConfigs && config.sandboxConfig) {
    secrets.push(
      ...extractSecretsFromRedshiftConnectionConfig(
        config.sandboxConfig,
        "sandbox",
      ),
    );
  }
  // Removing un-changed secrets, so that they are not sent to the backend
  return filterUpdatedSecrets(secrets);
};

const convertRedshiftConnectionConfigToBEConnectionConfiguration = (
  config: RedshiftConnectionConfigT,
): ConnectionConfigurationCreateT => {
  return {
    host: config.host || "",
    port: config.port || null, // Replace "" with null when port is deleted
    database_name: config.databaseName || "",
    cluster_identifier: config.clusterIdentifier || "",
    aws_region: config.awsRegion || "",
    role_arn: config.roleArn || "",
    auth_method: config.authMethod,
    allow_test_invocations: config.allowTestInvocations,
    has_ssl_enabled: config.hasSslEnabled,
    has_ssh_tunnel_enabled: config.hasSSHTunnelEnabled,
    ssh_tunnel: omit(config.SSHTunnel, "privateKey"),
  };
};

/**
 * Converts FE Redshift connection object to BE format.
 *
 * @param {RedshiftConnectionConfigInputsT} inputs - The Redshift connection object in FE format.
 * @returns {ConnectionCreateT} - The Redshift connection object in BE format suitable for sending POST request.
 */
export const convertRedshiftConnectionConfigInputsToBEConnection = (
  inputs: RedshiftConnectionConfigInputsT,
): ConnectionCreateT => {
  const secrets = extractSecretsFromRedshiftConnectionConfigInputs(inputs);

  return {
    name: inputs.name,
    is_sandbox: false,
    provider: "redshift",
    configuration: convertRedshiftConnectionConfigToBEConnectionConfiguration(
      inputs.productionConfig,
    ),
    secrets,
    data_retention: inputs.dataRetention,
    enable_non_prod_configs: inputs.enableNonProdConfigs,
    non_prod_env_configs:
      inputs.enableNonProdConfigs && inputs.sandboxConfig
        ? {
            sandbox: convertRedshiftConnectionConfigToBEConnectionConfiguration(
              inputs.sandboxConfig,
            ),
          }
        : {},
  };
};
