import { AnalyticsBrowser } from "@segment/analytics-next";
import { posthog } from "posthog-js";

import { analyticsWriteKey, posthogSendKey } from "src/api/hosts";
import { createPosthogPlugin } from "src/instrumentation/posthogPlugin";

export const analytics = new AnalyticsBrowser();

export const loadAnalytics = () => {
  if (
    analyticsWriteKey() !== "" &&
    posthogSendKey() !== "" &&
    localStorage.getItem("user_accepts_analytics_cookies") === "true"
  ) {
    // initialize posthog first, otherwise we run the risk
    // of not capturing the session_id correctly
    posthog.init(posthogSendKey(), {
      api_host: "https://ph.tm.taktile.com",
      ui_host: "https://eu.posthog.com",
      capture_pageview: false, // pageviews get captured via analytics.tsx
      enable_heatmaps: true,
      autocapture: true,
      enable_recording_console_log: true,
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
          email: true,
        },
      },
      loaded: (posthog_instance) => {
        analytics
          .load(
            {
              writeKey: analyticsWriteKey(),
              cdnURL: "https://stcdn.tm.taktile.com",
            },
            {
              integrations: {
                "Segment.io": {
                  apiHost: "stapi.tm.taktile.com/v1",
                },
              },
            },
          )
          .then(([analytics]) => {
            analytics.register(createPosthogPlugin(posthog_instance));
          });
      },
    });
  }
};
