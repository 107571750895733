import { isEmpty } from "lodash";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import {
  taktileInternalPrefix,
  validateNotStartsWithInternalPrefix,
} from "src/api/constants";
import { KeyTypeSelector } from "src/apiKeys/KeyTypeSelector";
import { useCreateWorkspaceApiKey } from "src/apiKeys/api/queries";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Input } from "src/base-components/Input";
import {
  ApiKeyType,
  WorkspaceServiceAccountWithKey,
} from "src/clients/taktile-api";
import { Modal } from "src/design-system/Modal";
import { logger } from "src/utils/logger";

type ApiKeyInputs = {
  name: string;
  keyTypes: ApiKeyType[];
};

type PropsT = {
  open: boolean;
  organizationId: string;
  workspaceId: string;
  onClose: () => void;
  onApiKeyCreate: (apiKey: WorkspaceServiceAccountWithKey) => void;
};

export const CreateApiKeyModal: React.FC<PropsT> = ({
  open,
  organizationId: orgId,
  workspaceId: wsId,
  onClose,
  onApiKeyCreate,
}) => {
  const createWorkspaceApiKey = useCreateWorkspaceApiKey(orgId, wsId);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    reset,
  } = useForm<ApiKeyInputs>({
    mode: "onChange",
    defaultValues: { name: "", keyTypes: [ApiKeyType.SANDBOX] },
  });

  const submitApiKey = handleSubmit(async (input: ApiKeyInputs) => {
    if (createWorkspaceApiKey.isLoading) {
      return;
    }

    try {
      const createdKey = (
        await createWorkspaceApiKey.mutateAsync({
          key_types: input.keyTypes,
          name: input.name,
        })
      ).data;
      onApiKeyCreate(createdKey);
    } catch (error) {
      logger.error(`Error when creating ApiKey: ${error}`);
    }
    onClose();
  });

  return (
    <Modal afterLeave={() => reset()} open={open} onClose={onClose}>
      <Modal.Header>Create API key</Modal.Header>
      <form onSubmit={submitApiKey}>
        <Modal.Content>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <label className="text-sm text-gray-600">Key name</label>
              {Boolean(errors.name?.message) && (
                <ErrorHint>{errors.name?.message}</ErrorHint>
              )}
              <Input
                placeholder="Name your API key"
                fullWidth
                {...register("name", {
                  required: true,
                  validate: validateNotStartsWithInternalPrefix(
                    `API key name cannot start with ${taktileInternalPrefix}`,
                  ),
                })}
              />
            </div>

            <div className="flex flex-col gap-2">
              <label className="text-sm text-gray-600">Key permissions</label>
              <Controller
                control={control}
                name="keyTypes"
                render={({ field }) => (
                  <KeyTypeSelector
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              disabled={
                !(isDirty && isValid) || !isEmpty(errors) || isSubmitting
              }
              htmlType="submit"
              loading={isSubmitting}
              variant="primary"
            >
              Create API key
            </Button>
          }
        />
      </form>
    </Modal>
  );
};
