import { get, isNull } from "lodash";
import { Controller, UseFormReturn } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import {
  ReviewCaseResponseData,
  ReviewCaseResponseSchema,
} from "src/api/types";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { Select } from "src/base-components/Select";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import { Textarea } from "src/base-components/Textarea";
import { Callout } from "src/design-system/Callout";

export const MR_FORM_ELEMENTS_PREFIX = "mr_form_elements_prefix";
export const Form: React.FC<{
  onClickSubmit: () => void;
  disabled: boolean;
  schema: ReviewCaseResponseSchema;
  form: UseFormReturn<ReviewCaseResponseData>;
  error?: string;
  onChange: () => void;
}> = ({ onClickSubmit, disabled, schema, form, onChange, error }) => {
  const order = schema.order ?? Object.keys(schema.properties);
  const orderedProperties = order.map(
    (key) => [key, schema.properties[key]] as const,
  );

  return (
    <form
      className={twJoin("relative", !error && "pt-7")}
      data-loc="manual-review-response-form"
      onChange={onChange}
    >
      {error && (
        <div className="sticky left-0 right-0 top-0 z-10 bg-white py-7">
          <Callout type="error">{error}</Callout>
        </div>
      )}
      {orderedProperties.map(([responseKey, responseProperty]) => {
        const isRequired =
          schema.required.includes(responseKey) && "This field is required";
        return (
          <FormItem
            key={responseKey}
            dataLoc={`manual-review-form-${responseKey}`}
            gap="sm"
            id={`${MR_FORM_ELEMENTS_PREFIX}_${responseKey}`}
            isRequired={!!isRequired}
            label={responseProperty.description}
          >
            {"enum" in responseProperty ? (
              <Controller
                control={form.control}
                name={responseKey}
                render={({ field }) => {
                  const selectProps = {
                    dataLoc: "manual-review-form-select",
                    disabled,
                    errored: !!get(form.formState.errors, responseKey),
                    options: responseProperty.enum.map((option, i) => ({
                      key: i.toString(),
                      value: option,
                    })),
                    placeholder: "Select option",
                    value: field.value
                      ? responseProperty.enum
                          .indexOf(field.value as string | number)
                          .toString()
                      : null,
                  };

                  // Split into two separate cases, otherwise typescript will complain about the onChange function
                  return !isRequired ? (
                    <Select
                      {...selectProps}
                      showResetButton
                      onChange={(key) => {
                        const isChosenValue =
                          key &&
                          responseProperty.enum[parseInt(key)] === field.value;

                        return field.onChange(
                          isNull(key) || isChosenValue
                            ? null
                            : responseProperty.enum[parseInt(key)],
                        );
                      }}
                    />
                  ) : (
                    <Select
                      {...selectProps}
                      onChange={(key) => {
                        const isChosenValue =
                          key &&
                          responseProperty.enum[parseInt(key)] === field.value;
                        return field.onChange(
                          isChosenValue
                            ? undefined
                            : responseProperty.enum[parseInt(key)],
                        );
                      }}
                    />
                  );
                }}
                rules={{
                  required: isRequired,
                }}
              />
            ) : responseProperty.type === "boolean" ? (
              <Controller
                control={form.control}
                name={responseKey}
                render={({ field }) => (
                  <SimpleRadioGroup
                    className="space-x-4"
                    disabled={disabled}
                    orientation="horizontal"
                    value={field.value?.toString()}
                    onValueChange={(value) => field.onChange(value === "true")}
                  >
                    <SimpleRadioGroup.Item
                      disabled={disabled}
                      label="Yes"
                      labelClassName="ml-1"
                      value="true"
                    />
                    <SimpleRadioGroup.Item
                      disabled={disabled}
                      label="No"
                      labelClassName="ml-1"
                      value="false"
                    />
                  </SimpleRadioGroup>
                )}
                rules={{
                  validate: (value) => {
                    if (isRequired && value === undefined) return isRequired;
                    else return true;
                  },
                }}
              />
            ) : responseProperty.type === "string" &&
              responseProperty.asTextArea ? (
              <Textarea
                disabled={disabled}
                errored={!!get(form.formState.errors, responseKey)}
                {...form.register(responseKey, {
                  required: isRequired,
                })}
                placeholder="Add notes"
              />
            ) : (
              <Input
                data-loc="manual-review-form-input"
                disabled={disabled}
                errored={!!get(form.formState.errors, responseKey)}
                placeholder={`Enter ${responseProperty.description}`}
                fullWidth
                {...form.register(responseKey, {
                  required: isRequired,
                  validate: (value) => {
                    if (value === undefined) return true;
                    else if (
                      typeof value === "string" &&
                      !isRequired &&
                      value.trim() === ""
                    )
                      return true;
                    else if (responseProperty.type === "string") return true;
                    else if (
                      responseProperty.type === "integer" &&
                      !Number.isInteger(parseFloat(value as string))
                    ) {
                      return `This field must contain an integer`;
                    } else if (
                      responseProperty.type === "number" &&
                      isNaN(parseFloat(value as string))
                    ) {
                      return `This field must contain a number`;
                    } else return true;
                  },
                })}
              />
            )}
            {get(form.formState.errors, responseKey) && (
              <ErrorHint>
                {get(form.formState.errors, responseKey)?.message}
              </ErrorHint>
            )}
          </FormItem>
        );
      })}
      <div className="sticky bottom-0 -mt-6 bg-white py-7">
        <Button
          dataLoc="manual-review-form-submit"
          disabled={disabled}
          fullWidth
          onClick={onClickSubmit}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};
