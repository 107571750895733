import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/design-system/Tooltip";

type Props = {
  icon: IconProp;
  deactivated: boolean;
  onClick: () => void;
  tooltipTitle: string;
  borderRight?: boolean;
  dataLoc?: string;
};

export const MultiSelectOptionsIcon: React.FC<Props> = ({
  icon,
  deactivated,
  onClick,
  tooltipTitle,
  borderRight,
  dataLoc,
}) => (
  <Tooltip placement="top" title={tooltipTitle}>
    <button
      className={borderRight ? "border-r border-gray-200 pr-2" : undefined}
      data-loc={dataLoc}
      onClick={!deactivated ? onClick : undefined}
    >
      <Icon
        animate={false}
        color={!deactivated ? "text-gray-500" : "text-gray-200"}
        icon={icon}
        size="xs"
      />
    </button>
  </Tooltip>
);
