import { faCodeCompare } from "@fortawesome/pro-regular-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { Operation } from "src/api/flowVersionUpdateIndex";
import { useOrganizationUser } from "src/api/taktile/queries";
import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { OpenDiffViewParams } from "src/changeHistory/DiffViewModal/GetChangeToCompareAgainst";
import { getChangeAction } from "src/changeHistory/changeUtils";
import {
  AdditionalTypes,
  getChangeResourceAndIcon,
} from "src/changeHistory/getChangeResourceAndIcon";
import {
  ChangeLogDb,
  CommentDb,
  ResourceType,
  ReviewCommentStatus,
} from "src/clients/flow-api";
import { CommentActions } from "src/comments/CommentActions";
import { CommentCard } from "src/comments/CommentCard";
import { useCommentActions } from "src/comments/useCommentActions";
import { Tooltip } from "src/design-system/Tooltip";
import { User } from "src/flow/User";
import { ShortDateFromNow } from "src/flowReview/ReviewLogEntry";
import { UserTip } from "src/flowReview/ReviewStatusPills";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import {
  AuthorPageParamsT,
  DashboardPageParamsT,
  getUrlToAuthoringPage,
  getUrlToRevisionPage,
} from "src/router/urls";
import { useCurrentUserId } from "src/store/AuthStore";
import { useGraphStore } from "src/store/StoreProvider";
import { sentanceList } from "src/utils/stringUtils";
import { wrapWithErrorHandler } from "src/utils/toastError";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const canViewDiffViewForChange = (change: ChangeLogDb) => {
  return (
    change.diff &&
    (change.diff.operation === Operation.EDIT_NODE ||
      change.diff.operation === Operation.EDIT_PARAMETERS ||
      change.diff.operation === Operation.EDIT_SCHEMA)
  );
};

type Props = {
  change: ChangeLogDb;
  latestSeenEtag?: string;
  hasNextRow: boolean;
  openNodeDiffView?: (params: OpenDiffViewParams) => void;
  onDeleteComment?: (comment: CommentDb) => Promise<boolean>;
  reviewHistory?: {
    isVisible: boolean;
    toggle: () => void;
  };
  hideChangeIcon?: boolean;
};

const AuthorDisplay: React.FC<{ changeAuthor: string }> = observer(
  ({ changeAuthor }) => {
    const { orgId } = useParamsDecode<DashboardPageParamsT>();
    const userId = useCurrentUserId();
    const userResult = useOrganizationUser(orgId, changeAuthor);

    return (
      <div className="text-gray-800 font-inter-medium-12px">
        {userId === changeAuthor ? (
          "You"
        ) : userResult.data !== undefined ? (
          <User
            avatar={userResult.data.avatar_url}
            name={userResult.data.full_name ?? ""}
          />
        ) : (
          <User avatar="" name="Unknown user" />
        )}
      </div>
    );
  },
);

type ActionsProps = {
  change: ChangeLogDb;
  onViewHistory: () => void;
  onOpenNodeDiff?: () => void;
  onOpenRevision: () => void;
};

const Actions: React.FC<ActionsProps> = ({
  change,
  onViewHistory,
  onOpenNodeDiff,
  onOpenRevision,
}) => {
  const historicalFlowVersionId = change?.previous_change_flow_version_id;

  return (
    <div className="ml-auto flex flex-row items-center gap-x-1">
      {historicalFlowVersionId !== undefined &&
        change.diff &&
        change.diff.operation === Operation.VERSION_DUPLICATED && (
          <div className="mr-1 w-[96px]">
            <Button
              size="sm"
              variant="secondary"
              fullWidth
              onClick={onViewHistory}
            >
              View history
            </Button>
          </div>
        )}
      <p className="text-nowrap text-gray-600 font-inter-normal-12px">
        <ShortDateFromNow date={change.created_at} />
      </p>
      {onOpenNodeDiff && change.diff && canViewDiffViewForChange(change) && (
        <Tooltip placement="top" title="Inspect changes" asChild>
          <Icon
            color="text-gray-500 hover:text-gray-700"
            dataLoc={`inspect-changes-etag-${change.etag}`}
            icon={faCodeCompare}
            size="xs"
            onClick={onOpenNodeDiff}
          />
        </Tooltip>
      )}
      <Tooltip placement="top" title="Open snapshot" asChild>
        <Icon
          color="text-gray-500 hover:text-gray-700"
          dataLoc="change-history-open-revision-icon"
          icon={faArrowUpRightFromSquare}
          size="2xs"
          onClick={onOpenRevision}
        />
      </Tooltip>
    </div>
  );
};

export const ChangeHistoryRow: React.FC<Props> = ({
  change,
  latestSeenEtag,
  hasNextRow,
  hideChangeIcon = false,
  reviewHistory,
  openNodeDiffView,
  onDeleteComment,
}) => {
  const {
    resourceName: resource,
    resourceType,
    resourceId,
    resourceIds,
    icon,
    inPill,
  } = getChangeResourceAndIcon(change);
  const navigate = useNavigate();
  const graphStore = useGraphStore();
  const userId = useCurrentUserId();
  const { orgId, wsId, version_id, flow_id } =
    useParamsDecode<AuthorPageParamsT>();

  const commentActions = useCommentActions(
    change.comment,
    onDeleteComment ?? (async () => {}),
  );

  const unseen =
    latestSeenEtag !== undefined &&
    change.etag > latestSeenEtag &&
    userId !== change.authored_by;

  const commentReviewerStatus = change.comment?.reviewer_status;
  const isReviewRerequestedEntry =
    change.comment?.reviewer_status === ReviewCommentStatus.REVIEW_REREQUESTED;
  const isCancelledEntry =
    change.comment?.reviewer_status === ReviewCommentStatus.CANCELLED;

  const handleOpenRevisionTab = () => {
    const revisionUrl = getUrlToRevisionPage(
      orgId,
      wsId,
      flow_id,
      version_id,
      change.etag,
    );
    window.open(window.location.origin + revisionUrl, "_blank");
    tracker.emit(
      trackingEvents.openRevisionPage({
        flow_id: flow_id,
        flow_version_id: version_id,
        organization_id: orgId,
      }),
    );
  };

  const handleViewHistory = () => {
    const previousFlowVersionId = change?.previous_change_flow_version_id;
    if (!previousFlowVersionId) return;

    const previousFlowId = change?.previous_change_flow_id ?? flow_id;

    navigate(
      getUrlToAuthoringPage(orgId, wsId, previousFlowId, previousFlowVersionId),
    );
    tracker.emit(
      trackingEvents.jumpToPreviousFlowVersion({
        flow_id: flow_id,
        flow_version_id: version_id,
        organization_id: orgId,
      }),
    );
  };

  const onClickPill = (resourceType: string, resourceId: string) =>
    wrapWithErrorHandler(async () => {
      if (resourceType === ResourceType.NODE) {
        graphStore.setSelectedNode(resourceId);
      } else {
        throw new Error("Unknown resource type for pill click handler");
      }
    });

  return (
    <div
      className={twJoin(
        "flex w-full flex-row gap-x-2.5",
        !hideChangeIcon && "px-5",
        unseen && "bg-indigo-50",
      )}
      data-loc={`change-history-row-etag-${change.etag}`}
    >
      {!hideChangeIcon && (
        <div className="relative flex flex-col items-center">
          <div className="relative pb-1 pt-3">
            {unseen && (
              <div className="absolute -left-[2px] top-[10px] h-1.5 w-1.5 rounded-full border border-white bg-indigo-600" />
            )}
            {icon}
          </div>
          {hasNextRow && (
            <div className="relative flex-grow">
              <div className="absolute h-[calc(100%+8px)] w-px border-r border-r-gray-100" />
            </div>
          )}
        </div>
      )}
      <div
        className={twJoin(
          "flex min-w-0 flex-grow flex-col gap-x-2.5 gap-y-3 py-3",
          hasNextRow && "border-b border-b-gray-100",
        )}
      >
        <div className="flex min-h-[1.5rem] flex-grow">
          <div className="flex min-w-0 flex-row flex-wrap items-center gap-x-1.5 gap-y-1 text-gray-600 font-inter-normal-12px">
            <AuthorDisplay changeAuthor={change.authored_by} />
            <p className="text-nowrap">{getChangeAction(change)}</p>
            {inPill ? (
              resourceId && resourceType === ResourceType.NODE ? (
                <div className="min-w-0 flex-grow">
                  <Pill
                    variant="gray"
                    fullWidth
                    onClick={onClickPill(resourceType, resourceId)}
                  >
                    <Pill.Text>
                      {resource || graphStore.nodes.get(resourceId)?.data.label}
                    </Pill.Text>
                  </Pill>
                </div>
              ) : resourceIds && resourceType === AdditionalTypes.USER ? (
                sentanceList(
                  resourceIds.map((id) => (
                    <span
                      key={id}
                      className="inline-block whitespace-nowrap text-gray-800"
                    >
                      <UserTip key={id} orgId={orgId} userId={id} />
                    </span>
                  )),
                )
              ) : (
                <div className="min-w-0 flex-grow">
                  <Pill variant="gray" fullWidth>
                    <Pill.Text>{resource}</Pill.Text>
                  </Pill>
                </div>
              )
            ) : (
              <p className="truncate text-gray-800 font-inter-normal-12px">
                {resource}
              </p>
            )}
          </div>
          {change.comment ? (
            <div className="ml-auto flex items-center gap-x-1 text-gray-600 font-inter-normal-12px">
              <ShortDateFromNow date={change.created_at} />
              {!isCancelledEntry && !isReviewRerequestedEntry && (
                <CommentActions
                  comment={change.comment}
                  {...commentActions}
                  editOnly={
                    commentReviewerStatus &&
                    commentReviewerStatus !== ReviewCommentStatus.COMMENTED
                  }
                />
              )}
            </div>
          ) : (
            <Actions
              change={change}
              onOpenNodeDiff={
                openNodeDiffView
                  ? () =>
                      openNodeDiffView({
                        change,
                      })
                  : undefined
              }
              onOpenRevision={handleOpenRevisionTab}
              onViewHistory={handleViewHistory}
            />
          )}
        </div>
        {change.comment && !isCancelledEntry && !isReviewRerequestedEntry && (
          <CommentCard
            actions={commentActions}
            comment={change.comment}
            hideHeader
            onDelete={async () => {}}
          />
        )}
        {isCancelledEntry && !hasNextRow && reviewHistory && (
          <div>
            <Button
              size="sm"
              variant="secondary"
              onClick={reviewHistory.toggle}
            >
              {reviewHistory.isVisible ? "Hide" : "View"} review history
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
