import { faSparkles } from "@fortawesome/pro-regular-svg-icons";
import { ComponentProps } from "react";

import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/design-system/Tooltip";

export const BetaPill: React.FC<{
  tooltipPlacement: ComponentProps<typeof Tooltip>["placement"];
}> = ({ tooltipPlacement }) => {
  return (
    <Tooltip
      body={
        <p className="text-gray-300">
          This feature has been recently developed and your feedback is crucial
          as we continue to refine our products to better serve your needs.
        </p>
      }
      placement={tooltipPlacement}
      title="Beta feature 🎉"
    >
      <Pill size="sm">
        <Pill.Icon icon={faSparkles} />
        <Pill.Text>Beta</Pill.Text>
      </Pill>
    </Tooltip>
  );
};
