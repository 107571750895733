import { completionStatus } from "@codemirror/autocomplete";
import { useWindowSize } from "@react-hooks-library/core";
import ReactCodeMirror, {
  BasicSetupOptions,
  Compartment,
  EditorState,
  EditorView,
  Extension,
  ReactCodeMirrorProps,
  ReactCodeMirrorRef,
  StateField,
} from "@uiw/react-codemirror";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { twJoin, twMerge } from "tailwind-merge";

import { showUpdatedSuggestionsPlugin } from "src/base-components/CodeInput/Autocomplete/useAutocompleteExtension";
import { useFocusHandlers } from "src/base-components/CodeInput/CodeInputFocusHandlers";
import {
  CodeInputTheme,
  CursorInvisibleTheme,
  TruncateLinesTheme,
} from "src/base-components/CodeInput/Themes";
import { useEditorBounds } from "src/base-components/CodeInput/useEditorBounds";
import { useDispatchCreateEvent } from "src/base-components/CodeInput/useOnCreateEvent";
import { useSetDataLoc } from "src/base-components/CodeInput/useSetDataLoc";
import { Tooltip } from "src/design-system/Tooltip";

export type CodeInputProps = Pick<
  ReactCodeMirrorProps,
  "onBlur" | "readOnly" | "placeholder" | "children"
> & {
  /**
   * Use disabled to signal users that the input is not editable (forbidden cursor and grayed out)
   * If you just want to prevent the user from editing the input use readOnly
   */
  disabled?: boolean;
  bgColor?: "bg-green-50";
  onChange: (newVal: string) => void;
  value: string;
  error?: string | boolean;
  prefix?: "data." | "$";
  dataLoc?: string;
  variant?: "cell";
  transparentBackground?: boolean;
  plaintext?: boolean;
  completionExtension?: Extension;
  containerDataLoc?: string;
};

const CodeMirrorDefaultSetup: BasicSetupOptions = {
  lineNumbers: false,
  highlightActiveLine: false,
  highlightActiveLineGutter: false,
  foldGutter: false,
  searchKeymap: false,
};

// https://discuss.codemirror.net/t/dynamically-change-linewrapping/3027
export const lineWrappingComp = new Compartment();
const DefaultExtensions: Extension[] = [
  // Prevents adding of newlines
  EditorState.transactionFilter.of((tr) => {
    return tr.newDoc.lines > 1 ? [] : [tr];
  }),
  // Line wrapping by default only gets switched on and off when focusing expandable fields
  lineWrappingComp.of([]),
];

/**
 * This field is present to determine if the input is prefixed with "data.".
 */
export const isDataPrefixed = StateField.define({
  create: () => {
    return true;
  },
  update: (value) => value,
});

const minHeight = "min-h-[32px]";

type GetDynamicEditorWidthArgs = {
  // Due to a bug in Scorecard Node value is sometimes undefined.
  // The null check is a workaround to prevent that editor from crashing.
  value: string | undefined;
  windowWidth: number;
  editorDivLeft: number;
  preChildWidth: number;
  outerWrapperLeft: number;
  outerWrapperWidth: number;
};

const getDynamicEditorWidth = ({
  value,
  windowWidth,
  editorDivLeft,
  preChildWidth,
  outerWrapperLeft,
  outerWrapperWidth,
}: GetDynamicEditorWidthArgs): number => {
  const MIN_DISTANCE_TO_VIEWPORT_EDGE = 28;

  const pixelToViewportEdge = windowWidth - editorDivLeft;
  const widthLimitFromViewport =
    pixelToViewportEdge - MIN_DISTANCE_TO_VIEWPORT_EDGE;

  const CHARACTER_WIDTH = 7.2;
  const PADDING_IN_EDITOR = 30;
  const lengthOfCharacters =
    Math.floor((value?.length || 0) * CHARACTER_WIDTH) + PADDING_IN_EDITOR;

  const widthOfChidrenAndCharacters = lengthOfCharacters + preChildWidth;

  let widthCodeEditorCanFillWithinOuterCountainer =
    outerWrapperWidth - (editorDivLeft - outerWrapperLeft);

  return Math.max(
    Math.min(widthOfChidrenAndCharacters, widthLimitFromViewport),
    widthCodeEditorCanFillWithinOuterCountainer,
  );
};

export const CodeInput = forwardRef<ReactCodeMirrorRef, CodeInputProps>(
  (
    {
      onChange,
      onBlur,
      value,
      disabled = false,
      error,
      prefix = false,
      placeholder,
      dataLoc,
      children,
      variant,
      transparentBackground = false,
      completionExtension,
      plaintext,
      bgColor,
      containerDataLoc,
      readOnly,
    },
    editorRef,
  ) => {
    const outerWrapperRef = useRef<HTMLDivElement>(null);
    const preDisplayedChildrenRef = useRef<HTMLDivElement>(null);

    const editor = useRef<ReactCodeMirrorRef>(null);
    useImperativeHandle(editorRef, () => editor.current as ReactCodeMirrorRef);
    const { onCreateEditor } = useSetDataLoc(dataLoc, editor.current);
    const { dispatchCreateEvent } = useDispatchCreateEvent();
    const { width: windowWidth } = useWindowSize();

    const absolute = variant !== "cell";
    const expandable = absolute;
    const showBorders = variant !== "cell";
    const dataPrefix = prefix === "data.";

    const { maxX } = useEditorBounds();

    // Dynamically update cm-editor width for non cell inputs.
    // We rely on line wrapping to automatically adjust the height of the editor.
    // This stops the editor from growing along the x-axis which is why we manually adjust its width.
    useEffect(() => {
      const editorDiv = editor.current?.editor;
      const preChildrenWidth = preDisplayedChildrenRef.current?.clientWidth;
      const outerWrapperDiv = outerWrapperRef.current;
      if (
        expandable &&
        editorDiv &&
        preChildrenWidth !== undefined &&
        outerWrapperDiv
      ) {
        const newDynamicWidth = getDynamicEditorWidth({
          value,
          windowWidth: maxX === null ? windowWidth : maxX,
          editorDivLeft: editorDiv.getBoundingClientRect().left,
          preChildWidth: preChildrenWidth,
          outerWrapperLeft: outerWrapperDiv.getBoundingClientRect().left,
          outerWrapperWidth: outerWrapperDiv.clientWidth,
        });
        editorDiv.style.width = newDynamicWidth + "px";
      }
    }, [expandable, value, windowWidth, maxX]);

    const extensions = useMemo(() => {
      const extensions = [...DefaultExtensions];
      if (variant === "cell") {
        extensions.push(EditorView.lineWrapping);
      }
      if (completionExtension) {
        extensions.push(completionExtension);
        extensions.push(showUpdatedSuggestionsPlugin);
        if (prefix === "data.") {
          extensions.push(isDataPrefixed);
        }
      }
      return extensions;
    }, [completionExtension, variant, prefix]);

    const { handleFocus, handleBlur } = useFocusHandlers({
      editor,
      disabled,
      toggleWrappingOnFocus: expandable,
      toggleScrollbarOnFocus: expandable,
      onBlur,
    });

    const textColor = plaintext
      ? "text-gray-800"
      : dataPrefix
        ? "text-green-600"
        : "text-indigo-600";

    // One CSS style is defined in index.css because the Theme does not allow accessing the most outer element (cm-theme)
    const theme = useMemo(
      () =>
        EditorView.theme({
          ...CodeInputTheme,
          ...(expandable && TruncateLinesTheme),
          ...(disabled && CursorInvisibleTheme),
        }),
      [disabled, expandable],
    );

    const hasErrorMessage = typeof error === "string";

    const inner = (
      <Tooltip
        disabled={
          !(
            hasErrorMessage &&
            // We dont want to show the tooltip when the completion is open
            (!editor.current?.view?.state ||
              completionStatus(editor.current.view?.state) !== "active")
          )
        }
        placement="top"
        title={hasErrorMessage ? error : undefined}
        asChild
      >
        <div
          className={twJoin(
            "item-center flex h-full w-full",
            absolute && "absolute min-w-full",
          )}
        >
          {prefix && (
            <div
              className={twJoin(
                "flex items-center rounded-l-lg border-y border-l border-gray-200 bg-gray-100 py-1 pl-3 pr-2 font-mono text-xs",
                minHeight,
                plaintext ? "text-gray-800" : "text-indigo-600",
              )}
              data-loc={`${dataLoc}-prefix`}
            >
              {prefix}
            </div>
          )}
          <div
            className={twMerge(
              "min-w-0 grow rounded-lg",
              expandable && minHeight,
              expandable &&
                "focus-within:h-fit focus-within:w-fit focus-within:min-w-fit",
              disabled && !transparentBackground && "bg-gray-100",
              !disabled && !transparentBackground && bgColor,
              !disabled && !transparentBackground && !bgColor && "bg-white",
            )}
            data-loc={`${dataLoc}-wrapper`}
          >
            <ReactCodeMirror
              ref={editor}
              basicSetup={CodeMirrorDefaultSetup}
              className={twJoin(
                "flex h-full min-h-0 w-full min-w-0 max-w-full overflow-y-auto rounded-r-lg font-mono text-xs scrollbar-hide",
                absolute && "items-start",
                expandable ? "focus-within:max-h-[300px]" : "items-center",
                showBorders && "border focus-within:border-indigo-600",
                showBorders && expandable && "focus-within:shadow-codeInput",
                !prefix && "rounded-l-lg pl-2",
                textColor,
                error && "border-red-400",
                disabled && "cursor-not-allowed",
                !disabled && "cursor-text",
              )}
              data-loc={containerDataLoc}
              extensions={extensions}
              indentWithTab={false}
              placeholder={placeholder}
              readOnly={disabled || readOnly}
              theme={theme}
              value={value}
              onBlur={handleBlur}
              onChange={onChange}
              onCreateEditor={(view) => {
                onCreateEditor(view);
                dispatchCreateEvent();
              }}
              onFocus={handleFocus}
            >
              {/* We need to prevent the default here because otherwise a click on the children opens the completions on the input*/}
              <div
                ref={preDisplayedChildrenRef}
                className="flex !h-full min-h-[30px] items-center self-start pt-0.5"
                onFocus={(e) => e.preventDefault()}
              >
                {children}
              </div>
            </ReactCodeMirror>
          </div>
        </div>
      </Tooltip>
    );

    if (variant === "cell") {
      return inner;
    }

    return (
      <div
        ref={outerWrapperRef}
        className={twJoin(
          "relative h-8 flex-grow rounded-lg",
          expandable && "focus-within:z-[15]",
        )}
      >
        {inner}
      </div>
    );
  },
);
