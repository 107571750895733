import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useFieldArray } from "react-hook-form";

import { Button } from "src/base-components/Button";
import { SchemaEnumRow, SchemaEnumErrorStatus } from "src/schema/SchemaEnumRow";

const getNextEnumValue = (fields: Record<"id", string>[]) => {
  return `"value_${fields.length + 1}"`;
};

export const SchemaEnumRows: React.FC<{
  immutable: boolean;
  enumFormLocation: string;
  withValidation: boolean;
  errorStatus?: SchemaEnumErrorStatus;
}> = ({ immutable, errorStatus, enumFormLocation, withValidation }) => {
  const { fields, move, append, remove } = useFieldArray({
    name: enumFormLocation,
  });
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const fieldToMove = fields.findIndex((field) => field.id === active.id);
      const fieldToInsert = fields.findIndex((field) => field.id === over.id);
      if (fieldToMove !== -1 && fieldToInsert !== -1) {
        move(fieldToMove, fieldToInsert);
      }
    }
  };

  return (
    <ol className="my-4 ml-2 flex flex-col gap-y-4 border-l-2 border-gray-200">
      <DndContext onDragEnd={handleDragEnd}>
        <SortableContext
          disabled={immutable}
          items={fields.map((enumValue) => enumValue.id)}
        >
          {fields.map((field, index) => (
            <SchemaEnumRow
              key={field.id}
              deletable={fields.length !== 1}
              enumFormLocation={enumFormLocation}
              errorStatus={errorStatus}
              fieldIndex={index}
              id={field.id}
              readonly={immutable}
              withValidation={withValidation}
              onDelete={() => remove(index)}
            />
          ))}
        </SortableContext>
      </DndContext>
      <div className="ml-4">
        <Button
          dataLoc="add-enum-value"
          disabled={immutable}
          iconLeft={faPlus}
          size="sm"
          variant="secondary"
          onClick={() => append({ value: getNextEnumValue(fields) })}
        >
          Add value
        </Button>
      </div>
    </ol>
  );
};
