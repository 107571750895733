import { AxiosError } from "axios";
import { ReactNode } from "react";

export const pluralize = (count: number, noun: string, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;

export type SerializedJSON = string & { __brand: "SerializedJSON" };

/**
 * Join a list of elements with comma seperators except the last element
 * which is joined with "and".
 * @param items
 */
export const sentanceList = (items: ReactNode[]): (ReactNode | string)[] =>
  items.flatMap((item, i) => {
    return [
      item,
      i === items.length - 2 ? " and " : i === items.length - 1 ? "" : ", ",
    ];
  });

export const isJsonObject = (s: unknown): s is SerializedJSON => {
  try {
    const parsed = JSON.parse(s as string);
    //JSON.parse also reads "true" "false" and "null" as valid jsons, which they aren't.
    //Therefore these additional checks are necessarry.
    return typeof parsed === "object" && parsed !== null;
  } catch (e) {
    return false;
  }
};

export const errorMessage = (err: unknown): string => {
  if (err instanceof AxiosError && err?.response?.data?.detail) {
    const detail = err.response.data.detail;
    if (Array.isArray(detail)) {
      return detail.map((item) => item.msg).join(", ");
    } else {
      return err.response.data.detail;
    }
  }
  if (err instanceof Error) {
    return err.message;
  } else if (typeof err === "string") {
    return err;
  } else if (typeof err === "object") {
    return JSON.stringify(err);
  } else if (err === undefined || err === null) {
    return "";
  }
  return String(err);
};

export const compareStringsAscendingCaseInsensitive = (
  a: string,
  b: string,
) => {
  a = a.toLowerCase();
  b = b.toLowerCase();
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const getMaximumDisplayedCount = (
  count: number | undefined,
  maxCount: number,
) => {
  if (count === undefined) {
    return count;
  }
  return count > maxCount ? `${maxCount}+` : count;
};
