import {
  faCheck,
  faChevronDown,
  faUser,
  faUserCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { twJoin } from "tailwind-merge";

import { useReviewCaseMutation } from "src/api/queries";
import { Icon } from "src/base-components/Icon";
import { SearchableSelect } from "src/base-components/SearchableSelect";
import { Skeleton } from "src/base-components/Skeleton";
import { OrganizationUser } from "src/clients/taktile-api";
import { Avatar } from "src/design-system/Avatar";
import { EmptyState } from "src/design-system/EmptyState";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { useFlowContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";
import { isPreconditionError } from "src/utils/predicates";
import { canUserBeAssignee } from "src/utils/user";

type UserPickerProps = {
  value?: Nullable<string>;
  users: OrganizationUser[];
  workspaceId: string;
  onChange: (value: Nullable<string>) => void;
  /**
   * We have very specific display styles
   * for when we show user picker in a table row
   */
  isInRow?: boolean;
};

type UserOption = {
  key: string;
  value: OrganizationUser;
};

const getUserName = (user?: OrganizationUser) =>
  user?.full_name ?? user?.username ?? user?.email ?? "";

const UNSET_UPTION = { key: "unset", value: "Unset user" };

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function isUser(user?: any): user is OrganizationUser {
  return Boolean(user.role);
}

export const UserPicker: React.FC<UserPickerProps> = ({
  value,
  users,
  workspaceId,
  onChange,
  isInRow,
}) => {
  const options: (UserOption | typeof UNSET_UPTION)[] =
    users
      ?.filter((user) => canUserBeAssignee(workspaceId, user))
      .map((user) => ({ key: user.id, value: user })) ?? [];

  const userValue = users.find((user) => user.id === value);
  return (
    <SearchableSelect<OrganizationUser | string>
      buttonRenderer={({ isOpen, placeholder }) => (
        <div className="flex items-center gap-x-2 truncate text-left text-gray-800 font-inter-normal-12px">
          {userValue ? (
            <>
              <Avatar user={userValue} />
              <p className="truncate">{getUserName(userValue)}</p>
            </>
          ) : (
            <>
              <span
                className={twJoin(
                  isInRow && "group-hover/row:inline",
                  "truncate italic text-gray-500",
                  isInRow && !isOpen && "hidden",
                  (!isInRow || isOpen) && "inline",
                )}
              >
                {placeholder}
              </span>
              <span
                className={twJoin(
                  isInRow && "group-hover/row:hidden",
                  isInRow && !isOpen && "inline",
                  (!isInRow || isOpen) && "hidden",
                )}
              >
                -
              </span>
            </>
          )}
          <div
            className={twJoin(
              isInRow && "group-hover/row:visible",
              isInRow && !isOpen && "invisible",
              (!isInRow || isOpen) && "visible",
            )}
          >
            <Icon color="text-gray-500" icon={faChevronDown} size="3xs" />
          </div>
        </div>
      )}
      dataLoc="manual-review-user-picker"
      emptyState={
        <EmptyState
          description="Change your search query to try again"
          headline="No users found"
          icon={faUser}
        />
      }
      isLoading={users.length === 0}
      itemRenderer={({ value: item }) =>
        isUser(item) ? (
          <div className="flex items-center gap-x-2 pr-2 text-gray-800 font-inter-normal-13px">
            <Avatar user={item} />
            <p className="truncate">{getUserName(item)}</p>
            {item.id === userValue?.id && (
              <div className="ml-auto">
                <Icon color="text-indigo-600" icon={faCheck} size="sm" />
              </div>
            )}
          </div>
        ) : (
          <div className="flex items-center gap-x-2 text-gray-800 font-inter-normal-13px">
            <div className="px-px">
              <Icon
                color="text-gray-400"
                icon={faUserCircle}
                padding={false}
                size="sm"
              />
            </div>
            No assignee
            {value === null && (
              <div className="ml-auto">
                <Icon color="text-indigo-600" icon={faCheck} size="sm" />
              </div>
            )}
          </div>
        )
      }
      options={[UNSET_UPTION, ...options]}
      placeholder="Select Assignee"
      searchOptions={{
        keys: ["email", "username", "full_name"],
      }}
      searchPlaceholder="Search user..."
      skeletonRenderer={() => (
        <div className="flex items-center gap-x-2 px-4 py-2 font-inter-normal-13px">
          <Skeleton height="h-4.5" variant="rounded-full" width="w-4.5" />
          <div className="flex-auto">
            <Skeleton />
          </div>
        </div>
      )}
      onChange={(userId) => onChange(userId === "unset" ? null : userId)}
    />
  );
};

export const UserPickerCell: React.FC<
  Pick<UserPickerProps, "value" | "users"> & { caseId: string; etag: string }
> = ({ value, users, caseId, etag }) => {
  const queryClient = useQueryClient();
  const { workspace, flow } = useFlowContext();
  const caseMutation = useReviewCaseMutation(
    workspace.base_url,
    flow.slug,
    caseId,
  );

  const updateAssignee = async (userId: Nullable<string>) => {
    try {
      await caseMutation.mutateAsync({ assignee: userId, etag });
    } catch (e: any) {
      if (isPreconditionError(e)) {
        await queryClient.invalidateQueries([
          "reviewCases",
          workspace.base_url,
          flow.slug,
        ]);
        toastActions.failure({
          title: "Failed to update assignee",
          description: "We refreshed review cases. Please try again",
        });
      } else {
        toastActions.failure({
          title: "Failed to update assignee",
          description: TAKTILE_TEAM_NOTIFIED,
        });
      }
      logger.error(e);
    }
  };

  return (
    <div
      className="overflow-hidden truncate pr-2"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <UserPicker
        users={users}
        value={value}
        workspaceId={workspace.id}
        isInRow
        onChange={updateAssignee}
      />
    </div>
  );
};
