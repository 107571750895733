/* eslint-disable sonarjs/prefer-single-boolean-return */
import React, { ReactElement } from "react";

import { FlowVersionT, NodeBET } from "src/api/flowTypes";
import { Operation } from "src/api/flowVersionUpdateIndex";
import { LoadingView } from "src/base-components/LoadingView";
import { Pill } from "src/base-components/Pill";
import { Spinner } from "src/base-components/Spinner";
import { NodeDiffView } from "src/changeHistory/DiffViewModal/NodeDiffView";
import { ParamsDiffView } from "src/changeHistory/DiffViewModal/ParamsDiffView";
import { SchemaDiffView } from "src/changeHistory/DiffViewModal/SchemaDiffView";
import { useRevision } from "src/changeHistory/queries";
import { ChangeLogDb, NodeUpsert } from "src/clients/flow-api";
import { Modal } from "src/design-system/Modal";
import { UserNameInfo } from "src/flowContainer/versionEditedInfo/UsernameInfo";
import { GraphTransform } from "src/utils/GraphUtils";
import { formatDate } from "src/utils/datetime";

type ChangeProps = {
  change: ChangeLogDb;
  compareAgainst: ChangeLogDb;
};

export type DiffRevisionProps = {
  change: ChangeLogDb;
  previouseRevision: FlowVersionT;
};

type RevisionProviderProps = ChangeProps & {
  renderUpdated: (args: DiffRevisionProps) => ReactElement;
};

type PropsT = {
  change: ChangeLogDb | undefined;
  compareAgainst: ChangeLogDb | undefined;
  handleClose: () => void;
  open: boolean;
  afterLeave: () => void;
  /**
   * Used to indicate that the diffs are related to a specific node.
   */
  forNodeId?: string;
};

const MetaInfoRow: React.FC<ChangeProps> = ({ change, compareAgainst }) => {
  return (
    <div className="flex gap-x-6">
      <div className="flex w-1/2 items-center gap-x-1">
        <Pill size="base" variant="red">
          <Pill.Text>Before</Pill.Text>
        </Pill>
        <div className="text-gray-500 font-inter-normal-12px">by</div>
        <div className="flex items-center text-gray-800 font-inter-medium-12px">
          <UserNameInfo userId={compareAgainst.authored_by} withAvatar />
        </div>
        <div className="text-gray-500 font-inter-normal-12px">on</div>
        <div className="text-gray-800 font-inter-medium-12px">
          {formatDate(compareAgainst.created_at, "dd MMM yyyy HH:mmaaa")}
        </div>
      </div>
      <div className="flex w-1/2 items-center gap-x-1">
        <Pill size="base" variant="green">
          <Pill.Text>After</Pill.Text>
        </Pill>
        <div className="text-gray-500 font-inter-normal-12px">by</div>
        <div className="flex items-center text-gray-800 font-inter-medium-12px">
          <UserNameInfo userId={change.authored_by} withAvatar />
        </div>
        <div className="text-gray-500 font-inter-normal-12px">on</div>
        <div className="text-gray-800 font-inter-medium-12px">
          {formatDate(change.created_at, "dd MMM yyyy HH:mmaaa")}
        </div>
      </div>
    </div>
  );
};

const PreviouseRevisionProvider: React.FC<RevisionProviderProps> = ({
  change,
  compareAgainst,
  renderUpdated,
}) => {
  const previousRevision = useRevision({
    versionId: change?.flow_version_id,
    etag: compareAgainst?.etag,
  });

  return (
    <LoadingView
      queryResult={previousRevision}
      renderUpdated={(revision: FlowVersionT) => {
        return renderUpdated({ change, previouseRevision: revision });
      }}
      renderUpdating={() => (
        <div className="flex h-[30rem] items-center">
          <Spinner />
        </div>
      )}
    />
  );
};

const findFirstNodeUpsertEntry = (nodeUpserts: {
  [key: string]: NodeUpsert;
}) => {
  return Object.entries(nodeUpserts).find(
    ([_id, nodeUpsert]) =>
      nodeUpsert !== null &&
      !nodeUpsert.meta?.is_split_branch_node &&
      !nodeUpsert.meta?.is_split_merge_node,
  );
};

const NodeDiffViewWrapper: React.FC<
  DiffRevisionProps & { forNodeId?: string }
> = ({ change, previouseRevision, forNodeId }) => {
  const nodeUpserts = change?.diff?.graph?.nodes;
  if (!nodeUpserts) return <></>;

  const nodeUpsertEntry = forNodeId
    ? // If a node is specified, we want to display for that specidific node, otherwise the first node that was changed
      ([forNodeId, nodeUpserts[forNodeId]] as const)
    : findFirstNodeUpsertEntry(nodeUpserts);
  const determinedNodeId = nodeUpsertEntry?.[0];

  const updatedNodeBe: NodeBET | undefined | "deleted" =
    nodeUpsertEntry?.[1] === null
      ? "deleted"
      : nodeUpsertEntry &&
          nodeUpsertEntry[1]?.meta &&
          nodeUpsertEntry[1]?.type &&
          nodeUpsertEntry[1]?.name !== undefined
        ? {
            name: nodeUpsertEntry[1].name,
            type: nodeUpsertEntry[1].type,
            meta: nodeUpsertEntry[1].meta,
            id: nodeUpsertEntry[0],
          }
        : undefined;
  if (updatedNodeBe === undefined || determinedNodeId === undefined)
    return <></>;
  const originalNode =
    previouseRevision.graph?.nodes.find(
      (node) => node.id === determinedNodeId,
    ) || "notYetCreated";
  const originalNodeUI =
    originalNode !== "notYetCreated"
      ? GraphTransform.nodeMapper(originalNode, 0)
      : originalNode;
  const updatedNodeUI =
    updatedNodeBe !== "deleted"
      ? GraphTransform.nodeMapper(updatedNodeBe, 0)
      : updatedNodeBe;
  return (
    <NodeDiffView changedNode={updatedNodeUI} originalNode={originalNodeUI} />
  );
};

export const DiffViewModal: React.FC<PropsT> = ({
  open,
  change,
  compareAgainst,
  forNodeId,
  handleClose,
  afterLeave,
}) => {
  return (
    <Modal afterLeave={afterLeave} open={open} size="lg" onClose={handleClose}>
      <Modal.Header>Inspect changes</Modal.Header>
      <Modal.Content noScroll>
        {change?.diff === undefined || compareAgainst === undefined ? (
          <></>
        ) : (
          <div className="flex h-full min-h-0 flex-col gap-y-4 py-4">
            <MetaInfoRow change={change} compareAgainst={compareAgainst} />
            <PreviouseRevisionProvider
              change={change}
              compareAgainst={compareAgainst}
              renderUpdated={({ change, previouseRevision }) => {
                if (forNodeId) {
                  return (
                    <NodeDiffViewWrapper
                      change={change}
                      forNodeId={forNodeId}
                      previouseRevision={previouseRevision}
                    />
                  );
                }
                if (change?.diff?.operation === Operation.EDIT_NODE) {
                  return (
                    <NodeDiffViewWrapper
                      change={change}
                      previouseRevision={previouseRevision}
                    />
                  );
                }
                if (change?.diff?.operation === Operation.EDIT_PARAMETERS) {
                  return (
                    <ParamsDiffView
                      change={change}
                      previouseRevision={previouseRevision}
                    />
                  );
                }
                if (change?.diff?.operation === Operation.EDIT_SCHEMA) {
                  return (
                    <SchemaDiffView
                      change={change}
                      previouseRevision={previouseRevision}
                    />
                  );
                }
                return <></>;
              }}
            />
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};
