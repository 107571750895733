import { observer } from "mobx-react-lite";
import React from "react";
import { useToggle } from "usehooks-ts";

import { Modal } from "src/design-system/Modal";
import { useIsNodeEditorOpen } from "src/flowContainer/AuthoringUIContext";
import { useGraphStore } from "src/store/StoreProvider";

export type SidePaneNodeEditorChildrenFuncArgs = {
  toggleFullscreen: () => void;
  isFullscreen: boolean;
  wrapperRef: React.RefObject<HTMLDivElement>;
};

type Props = {
  children: (args: SidePaneNodeEditorChildrenFuncArgs) => React.ReactElement;
  sidePaneRef: React.RefObject<HTMLDivElement>;
  fullScreenRef: React.RefObject<HTMLDivElement>;
};

export const SidePaneNodeEditorContainer: React.FC<Props> = observer(
  ({ children, sidePaneRef, fullScreenRef }) => {
    const { selectedNode } = useGraphStore();

    const isNodeEditorOpen = useIsNodeEditorOpen();
    const [fullscreen, toggleFullscreen] = useToggle();

    if (!isNodeEditorOpen || !selectedNode) {
      return <></>;
    }

    return (
      <div
        className="relative flex h-full w-[150ch] min-w-[464px] flex-col bg-white pt-6 shadow-lg"
        data-loc="node-editor-pane"
      >
        {!fullscreen &&
          children({
            toggleFullscreen,
            isFullscreen: false,
            wrapperRef: sidePaneRef,
          })}
        <Modal open={fullscreen} size="xl" onClose={toggleFullscreen}>
          <div className="flex min-h-0 flex-col justify-stretch justify-items-stretch pb-2 pt-6">
            {children({
              toggleFullscreen,
              isFullscreen: true,
              wrapperRef: fullScreenRef,
            })}
          </div>
        </Modal>
      </div>
    );
  },
);
