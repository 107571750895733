import { faCube, faEdit } from "@fortawesome/pro-regular-svg-icons";
import { faUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { Root } from "@radix-ui/react-accordion";
import { keyBy } from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";

import { FlowT, FlowVersionFlowChild, FlowVersionT } from "src/api/flowTypes";
import { useFlow } from "src/api/queries";
import { Button } from "src/base-components/Button";
import {
  EditorAccordionItem as AccordionItem,
  accordionRootClassName,
} from "src/base-components/EditorAccordionItem";
import { ExternalLink } from "src/base-components/ExternalLink";
import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { useDiffViewContext } from "src/changeHistory/DiffViewModal/DiffViewContext";
import { useRevision } from "src/changeHistory/queries";
import { FlowNode, FlowNodeDataT } from "src/constants/NodeDataTypes";
import { useSchemas } from "src/datasets/utils";
import { Callout } from "src/design-system/Callout";
import { EmptyState } from "src/design-system/EmptyState";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import { DataMap } from "src/parentFlowNodes/DataMap";
import { ChangeVersionModal } from "src/parentFlowNodes/flowNode/ChangeVersionModal";
import { SelectedVersion } from "src/parentFlowNodes/flowNode/SelectedVersion";
import { FlowNodeForm } from "src/parentFlowNodes/flowNode/types";
import { useGetChildVersionLink } from "src/parentFlowNodes/useGetChildVersionLink";
import { useUpdateKnownEtag } from "src/parentFlowNodes/useUpdateKnownEtag";
import {
  queryErrorLikelyBecauseChildFlowGotDeleted,
  setupFlowNodeForm,
} from "src/parentFlowNodes/utils";
import { ErrorContent } from "src/router/ErrorPage";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useSubmitForm } from "src/utils/useSubmitForm";

type PropsT = {
  selectedNode: FlowNode;
} & NodeEditorBaseProps<FlowNodeDataT>;

export type FlowNodeCurrentChildVersion = FlowVersionFlowChild;

type LoadedViewProps = {
  childFlow: FlowT;
  childVersionCurrent?: FlowNodeCurrentChildVersion;
  childVersionRevision?: Pick<
    FlowVersionT,
    "etag" | "input_schema" | "output_schema"
  >;
  nodeData: FlowNodeDataT;
  nodeId: string;
  isReactive: boolean;
  isImmutable: boolean;
  onUpdate: PropsT["onUpdate"];
};

const LoadedView: React.FC<LoadedViewProps> = ({
  childFlow,
  childVersionCurrent,
  childVersionRevision,
  nodeData,
  nodeId,
  isImmutable,
  isReactive,
  onUpdate,
}) => {
  const { orgId, workspace } = useAuthoringContext();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const childUiSchemas = useSchemas(childVersionRevision);
  const handleSubmitRef = useRef<VoidFunction>(() => {});

  const getChildFlowLink = useGetChildVersionLink();

  const [defaultValues, schemaMappingErrors, fieldsToRemove] = useMemo(() => {
    if (isImmutable) {
      return [
        {
          input_mappings: nodeData.input_mappings,
          output_mappings: nodeData.output_mappings,
          child_flow_version_id: nodeData.child_flow_version_id,
          child_flow_version_known_etag: nodeData.child_flow_version_known_etag,
        },
        [],
        [],
      ];
    } else {
      return setupFlowNodeForm(nodeData, childUiSchemas);
    }
  }, [isImmutable, nodeData, childUiSchemas]);

  const formMethods = useForm<FlowNodeForm>({
    defaultValues,
    resetOptions: { keepTouched: true },
    ...(isReactive && { values: defaultValues }),
  });

  const { unregister } = formMethods;

  // keep refs up to date
  handleSubmitRef.current = formMethods.handleSubmit((data) => {
    onUpdate({
      nodeId,
      newData: {
        ...nodeData,
        ...data,
      },
    });
  });

  useSubmitForm({
    onChange: (data: FlowNodeForm) => {
      onUpdate({ newData: { ...nodeData, ...data } });
    },
    disabled: isReactive || isImmutable,
    previousValues: defaultValues,
    watch: formMethods.watch,
  });

  useEffect(() => {
    // Prevent unnecessary submits because they e.g. result in change history entries
    if (fieldsToRemove.length > 0) {
      unregister(fieldsToRemove);
      if (!isReactive && !isImmutable) {
        handleSubmitRef.current();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unregister, fieldsToRemove.join(","), isReactive, isImmutable]);

  const { renderedInDiffView } = useDiffViewContext();

  useUpdateKnownEtag({
    nodeId: nodeId,
    nodeData,
    immutable: isImmutable,
    isReactive,
    onUpdate,
    etag: childVersionCurrent?.etag,
  });

  if (!childVersionRevision || !childVersionCurrent) {
    return <>Unable to load child version</>;
  }

  const childFlowName = `${childFlow.name}, ${childVersionCurrent.name}`;

  const inputMappingErrors = schemaMappingErrors.filter(
    (e) => e.type === "input",
  );
  const outputMappingErrors = schemaMappingErrors.filter(
    (e) => e.type === "output",
  );
  const fieldMappingErrors = schemaMappingErrors.filter(
    (e) => e.issue === "field-missing",
  );
  const isStale = !!nodeData.is_stale;
  const hasMissingSchema = !childUiSchemas.input || !childUiSchemas.output;

  return (
    <FormProvider {...formMethods}>
      {hasMissingSchema && isStale && (
        <Callout type="warning">
          To complete the configuration, inputs and outputs need to be mapped.
        </Callout>
      )}
      <Root
        className={accordionRootClassName}
        defaultValue={["version", "mapping"]}
        type="multiple"
      >
        <AccordionItem
          disabled={renderedInDiffView}
          headerContent={
            <div className="pr-4">
              <Tooltip placement="bottom-end" title="Open in new tab" asChild>
                <div>
                  <ExternalLink
                    className="text-gray-500"
                    href={getChildFlowLink({
                      orgId,
                      workspaceId: workspace.id,
                      flowId: childFlow.id,
                      versionId: childVersionCurrent.id,
                    })}
                  >
                    <Icon icon={faUpRightFromSquare} size="xs" />
                  </ExternalLink>
                </div>
              </Tooltip>
            </div>
          }
          title="Select the Decision Flow you want to call"
          value="version"
        >
          <div className="flex flex-col items-start pb-4">
            <SelectedVersion
              flowName={childFlow.name}
              version={childVersionCurrent}
            />
            <Button
              dataLoc="change-child-version-button"
              disabled={isImmutable}
              iconLeft={faEdit}
              size="sm"
              variant="secondary"
              onClick={() => setIsModalOpen(true)}
            >
              Change version
            </Button>
          </div>
        </AccordionItem>
        <AccordionItem
          disabled={renderedInDiffView}
          title="Map inputs and outputs of Decision Flows"
          value="mapping"
        >
          <DataMap
            childFlowId={childFlow.id}
            childFlowName={childFlowName}
            childFlowSlug={childFlow.slug}
            childVersionId={childVersionCurrent.id}
            description={
              <>
                Map the data from this Decision Flow to the inputs of the Child
                Flow <span className="text-gray-800">{childFlowName} </span>
              </>
            }
            fieldMappingErrors={keyBy(
              inputMappingErrors.filter(
                (error) => error.issue === "field-missing",
              ),
              "fieldName",
            )}
            hasError={fieldMappingErrors.length > 0}
            isNodeStale={isStale}
            readonly={isImmutable}
            schema={childUiSchemas.input}
            title="Inputs"
          />
          <DataMap
            childFlowId={childFlow.id}
            childFlowName={childFlowName}
            childFlowSlug={childFlow.slug}
            childVersionId={childVersionCurrent.id}
            description={
              <>
                Map the outputs from the Child Flow{" "}
                <span className="text-gray-800">{childFlowName} </span> to the{" "}
                data from this Decision Flow
              </>
            }
            fieldMappingErrors={keyBy(
              outputMappingErrors.filter(
                (error) => error.issue === "field-missing",
              ),
              "fieldName",
            )}
            isNodeStale={isStale}
            readonly={isImmutable}
            schema={childUiSchemas.output}
            title="Outputs"
            isOutput
          />
        </AccordionItem>
      </Root>
      <ChangeVersionModal
        flowId={childFlow.id}
        open={isModalOpen}
        selectedVersionId={childVersionCurrent.id}
        versions={childFlow.versions}
        onClose={() => setIsModalOpen(false)}
      />
    </FormProvider>
  );
};

export const FlowNodeEditor: React.FC<PropsT> = ({
  selectedNode,
  onUpdate,
  immutable,
  isReactive,
}) => {
  const childFlowQuery = useFlow(selectedNode.data.child_flow_id ?? "", {
    refetchInterval: 5000,
  });
  const childVersionRevisionQuery = useRevision({
    versionId: selectedNode.data.child_flow_version_id ?? undefined,
    etag: selectedNode.data.child_flow_version_known_etag ?? undefined,
    keepPreviousData: true,
  });

  useEffect(() => {
    toastActions.dismiss(selectedNode.id);
  }, [selectedNode.id]);

  if (selectedNode.data.child_flow_id === null)
    return (
      <div className="flex h-full w-full items-center justify-center rounded-lg border border-gray-200">
        <EmptyState
          description="No version has been selected for the called Decision Flow."
          headline="Version does not exist"
          icon={faCube}
        />
      </div>
    );
  return (
    <>
      {immutable ? (
        <LoadingView
          queryResult={[childFlowQuery, childVersionRevisionQuery]}
          renderErrored={(error) => {
            if (queryErrorLikelyBecauseChildFlowGotDeleted(error))
              return (
                <div className="flex h-full w-full items-center justify-center rounded-lg border border-gray-200">
                  <EmptyState
                    description="Called Decision Flow version has been deleted."
                    headline="Version does not exist"
                    icon={faCube}
                  />
                </div>
              );
            else return <ErrorContent message={error} />;
          }}
          renderUpdated={([childFlow, childVersionRevision]: [
            FlowT,
            FlowVersionT,
          ]) => {
            const childVersionUpToDate = childFlow.versions.find(
              (version) =>
                version.id === selectedNode.data.child_flow_version_id,
            );
            return (
              <LoadedView
                childFlow={childFlow}
                childVersionCurrent={childVersionUpToDate}
                childVersionRevision={childVersionRevision}
                isImmutable={immutable}
                isReactive={isReactive}
                nodeData={selectedNode.data}
                nodeId={selectedNode.id}
                onUpdate={onUpdate}
              />
            );
          }}
        />
      ) : (
        <LoadingView
          queryResult={childFlowQuery}
          renderUpdated={(childFlow: FlowT) => {
            const childVersionUpToDate = childFlow.versions.find(
              (version) =>
                version.id === selectedNode.data.child_flow_version_id,
            );
            return (
              <LoadedView
                childFlow={childFlow}
                childVersionCurrent={childVersionUpToDate}
                childVersionRevision={childVersionUpToDate}
                isImmutable={immutable}
                isReactive={isReactive}
                nodeData={selectedNode.data}
                nodeId={selectedNode.id}
                onUpdate={onUpdate}
              />
            );
          }}
        />
      )}
    </>
  );
};
