import {
  faCheckCircle,
  faCircleNotch,
  faExclamationCircle,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import toast, { ToastBar } from "react-hot-toast";

import { Icon } from "src/base-components/Icon";
import { NotificationVariant, Props } from "src/design-system/Toast/constants";
import { useTrackNotification } from "src/design-system/Toast/utils";
import { assertUnreachable } from "src/utils/typeUtils";

export const RESET_TOASTBAR_STYLES: React.CSSProperties = {
  padding: 0,
  boxShadow: "unset",
  borderRadius: 0,
  background: "transparent",
};

export const getIcon = (variant: NotificationVariant): React.ReactNode => {
  switch (variant) {
    case "default":
      return null;

    case "success":
      return <Icon color="text-green-600" icon={faCheckCircle} size="base" />;

    case "error":
      return (
        <Icon color="text-red-600" icon={faExclamationCircle} size="base" />
      );
    case "loading":
      return (
        <Icon color="text-indigo-600" icon={faCircleNotch} size="base" spin />
      );

    default:
      assertUnreachable(variant);
      return null;
  }
};

/**
 * Toast component
 * NOTE: Avoid using this component directly, use helpers from utils
 * Figma: https://www.figma.com/design/BO9DWO8PKNGNSI3HhpSobA/Design-System-v2?node-id=9503-2702&node-type=text&m=dev
 */
export const Toast: React.FC<Props> = ({
  dismissible = true,
  variant,
  title,
  description,
  toastInstance,
  actionText,
  onActionClick,
}) => {
  useTrackNotification({ variant, title, description });
  return (
    <ToastBar style={RESET_TOASTBAR_STYLES} toast={toastInstance}>
      {() => (
        <div
          className="flex w-[365px] gap-x-2 rounded-2xl bg-white p-5 shadow-lg"
          data-loc={`${variant}-notification`}
        >
          {getIcon(variant)}
          <div className="flex flex-grow flex-col gap-y-2">
            <div className="flex justify-between">
              <p className="text-gray-800 font-inter-semibold-16px">{title}</p>
              {dismissible && (
                <Icon
                  color="text-gray-500 hover:text-gray-700"
                  dataLoc="notification-close"
                  icon={faXmark}
                  size="base"
                  onClick={() => toast.dismiss(toastInstance.id)}
                />
              )}
            </div>

            {description && (
              <div className="text-gray-500 font-inter-normal-13px">
                {description}
              </div>
            )}

            {actionText && (
              <button
                className="text-left text-gray-800 font-inter-semibold-13px hover:underline"
                onClick={onActionClick}
              >
                {actionText}
              </button>
            )}
          </div>
        </div>
      )}
    </ToastBar>
  );
};
