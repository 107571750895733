import axios from "axios";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FlowT, FlowVersionT } from "src/api/flowTypes";
import {
  useAddFlowVersion,
  useDuplicateFlowVersion,
} from "src/api/flowVersionQueries";
import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { Textarea } from "src/base-components/Textarea";
import { Modal } from "src/design-system/Modal";
import {
  VersionPicker,
  FromScratchElement,
} from "src/flow/modals/VersionPicker";
import { getNameErrorMessage, nameValidations } from "src/flow/modals/utils";
import { getLatestReleaseVersion } from "src/flow/modals/utils";
import {
  trackingEvents,
  tracker,
} from "src/instrumentation/customTrackingEvents";
import { DashboardPageParamsT, getUrlToAuthoringPage } from "src/router/urls";
import { useUserData } from "src/store/AuthStore";
import { getActiveVersions } from "src/utils/flowVersion";
import { logger } from "src/utils/logger";
import { useParamsDecode } from "src/utils/useParamsDecode";

type DecisionFlowVersionInputsT = {
  name: string;
  description: string;
  duplicateVersionId: string;
  includeComments: boolean;
};

export type AddVersionsModalPropsT = {
  isOpen: boolean;
  onClose: () => void;
  afterLeave?: () => void;
  flow: FlowT;
  versionToDuplicate?: string;
};

const baseDefaultValues = {
  includeComments: true,
};

export const AddVersionModal: React.FC<AddVersionsModalPropsT> = ({
  isOpen,
  onClose,
  flow,
  versionToDuplicate,
  afterLeave,
}) => {
  const [submitError, setSubmitError] = useState<boolean>(false);
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();

  const { mutateAsync: createVersion } = useAddFlowVersion();
  const { mutateAsync: duplicateVersion } = useDuplicateFlowVersion();

  const navigate = useNavigate();
  const { signed_in_user_id } = useUserData();

  const versions = getActiveVersions(flow.versions, versionToDuplicate);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
    control,
    watch,
  } = useForm<DecisionFlowVersionInputsT>({
    defaultValues: {
      ...baseDefaultValues,
      duplicateVersionId: versionToDuplicate,
    },
    mode: "onChange",
  });

  useEffect(() => {
    // If the modal is opened without a version to duplicate selected it should prefill the version to duplicate with the latest published version
    if (!versionToDuplicate) {
      reset({
        ...baseDefaultValues,
        duplicateVersionId:
          getLatestReleaseVersion(flow.versions)?.id ?? FromScratchElement.key,
      });
    } else {
      reset({
        ...baseDefaultValues,
        duplicateVersionId: versionToDuplicate,
      });
    }
  }, [versionToDuplicate, flow.versions, reset, isOpen]);

  const onModalConfirm = (
    name: string,
    duplicateVersionId: string,
    description: string,
    includeComments: boolean,
  ) => {
    if (duplicateVersionId !== FromScratchElement.key) {
      return duplicateVersion({
        flowVersionId: duplicateVersionId,
        name: name,
        release_note: description,
        created_by_id: signed_in_user_id ?? "",
        include_comments: includeComments,
      });
    } else {
      return createVersion({
        flowId: flow.id,
        name: name,
        release_note: description,
        created_by_id: signed_in_user_id ?? "",
      });
    }
  };

  const onFormConfirm = handleSubmit(
    async (data: DecisionFlowVersionInputsT) => {
      try {
        const newVersion: FlowVersionT = await onModalConfirm(
          data.name,
          data.duplicateVersionId,
          data.description,
          data.includeComments,
        );
        tracker.emit(
          trackingEvents.createNewFlowVersion({
            source_flow_version_id: data.duplicateVersionId,
            flow_version_name: data.name,
            description: data.description,
            flow_version_id: newVersion.id,
            flow_id: flow.id,
            organization_id: orgId,
          }),
        );
        onClose();
        navigate(getUrlToAuthoringPage(orgId, wsId, flow.id, newVersion.id));
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 409) {
          setError("name", { type: "notAvailable" }, { shouldFocus: true });
        } else {
          logger.error(e);
          setSubmitError(true);
        }
      }
    },
  );

  return (
    <Modal afterLeave={afterLeave} open={isOpen} size="sm" onClose={onClose}>
      <Modal.Header>Create version</Modal.Header>
      <form onSubmit={onFormConfirm}>
        <Modal.Content>
          <Label mb="mb-1" required>
            Create from
          </Label>
          <div className="mb-2 text-gray-500 font-inter-normal-12px">
            Start from scratch or select version to duplicate
          </div>
          <Controller
            control={control}
            name="duplicateVersionId"
            render={(props) => (
              <VersionPicker
                data_loc="version-picker"
                selected={props.field.value}
                versions={versions}
                onChange={props.field.onChange}
              />
            )}
            rules={{ required: true }}
          />
          {watch("duplicateVersionId") !== FromScratchElement.key && (
            <Controller
              control={control}
              name="includeComments"
              render={(props) => (
                <label className="ml-1 mt-3 flex cursor-pointer flex-row items-center font-inter-semibold-13px">
                  <Checkbox
                    checked={props.field.value}
                    className="mr-2"
                    onChange={props.field.onChange}
                  />
                  Duplicate unresolved comments to new Decision Flow
                </label>
              )}
            />
          )}
          <div className="my-4">
            <Label required>Version name</Label>
            <Input
              data-loc="name-input"
              placeholder="Type version name"
              type="text"
              fullWidth
              {...register("name", {
                required: true,
                validate: nameValidations,
              })}
            />
            <ErrorHint height="h-2.5">
              {errors.name && getNameErrorMessage(errors.name.type)}
            </ErrorHint>
          </div>
          <Label>Description</Label>
          <Textarea
            autoComplete="off"
            data-loc="description-input"
            placeholder="Type description"
            {...register("description")}
          />
          <ErrorHint height="h-2">{errors.description?.type}</ErrorHint>
          {submitError && (
            <ErrorHint>Error submitting the data - please try again</ErrorHint>
          )}
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="save"
              disabled={!isEmpty(errors)}
              htmlType="submit"
              loading={isSubmitting}
              variant="primary"
            >
              Create
            </Button>
          }
        />
      </form>
    </Modal>
  );
};
