import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  IntegrationResourceT,
  InputMappingsT,
} from "src/integrationNode/types";

export const getDefaultPlaidAssetReport = (): IntegrationResourceT => ({
  providerResource: { provider: "plaid", resource: "asset_report" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      relay_token: {
        id: uuidV4(),
        type: "text",
        displayName: "Relay token",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: `After creating an Asset Report via Plaid's API, you can request a relay token by calling Plaid's “/credit/relay/create” endpoint. This relay token allows Taktile to access the Asset Report on your behalf.`,
      },
    },
    grouped: {},
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultPlaidFinancialInsightsReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "plaid",
      resource: "financial_insights_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        relay_token: {
          id: uuidV4(),
          type: "text",
          displayName: "Relay token",
          assignedTo: "",
          rules: {
            required: true,
          },
          hint: `After creating an Asset Report via Plaid's API, you can request a relay token by calling Plaid's “/credit/relay/create” endpoint. This relay token allows Taktile to access the Financial Insights on your behalf.`,
        },
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(
          CommonOutputMappingKey.PROVIDER_REQUEST_ID,
          CommonOutputMappingKey.RETRIEVAL_DATETIME,
        ),
      },
    },
    config: defaultResourceConfig,
  });

const getDefaultPlaidCraReportInput = (): InputMappingsT => ({
  ungrouped: {
    user_token: {
      id: uuidV4(),
      type: "text",
      displayName: "User token",
      assignedTo: "",
      hint: `The Plaid user token associated with the user for whom a report is being requested`,
    },
  },
  grouped: {},
  lists: {},
});

export const getDefaultPlaidCraBaseReport = (): IntegrationResourceT => ({
  providerResource: {
    provider: "plaid",
    resource: "plaid_cra_base_report",
  },
  connectionId: "",
  resourceConfigId: "",
  input: getDefaultPlaidCraReportInput(),
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.PROVIDER_ENTITY_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      account_insights: {
        ...getNamedOutputMapping("Account insights"),
        hint: `A collection of historical bank account insights derived from transaction-level data, extracted from all accounts and provided as a list of objects`,
      },
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultPlaidCraIncomeInsightsReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "plaid",
      resource: "plaid_cra_income_insights_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: getDefaultPlaidCraReportInput(),
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(
          CommonOutputMappingKey.PROVIDER_REQUEST_ID,
          CommonOutputMappingKey.PROVIDER_ENTITY_ID,
          CommonOutputMappingKey.RETRIEVAL_DATETIME,
        ),
        bank_income_summary: {
          ...getNamedOutputMapping("Bank income summary"),
          hint: `Summary of bank income across all income sources and items, provided as an object`,
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultPlaidCraPartnerInsightsReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "plaid",
      resource: "plaid_cra_partner_insights_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: getDefaultPlaidCraReportInput(),
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(
          CommonOutputMappingKey.PROVIDER_REQUEST_ID,
          CommonOutputMappingKey.PROVIDER_ENTITY_ID,
          CommonOutputMappingKey.RETRIEVAL_DATETIME,
        ),
        prism_insights: {
          ...getNamedOutputMapping("Prism Insights"),
          hint: `The data from the Insights product returned by Prism Data.\nThe "Prism Data Insights" partner product must be enabled to use this insight.`,
        },
        prism_cash_score: {
          ...getNamedOutputMapping("Prism CashScore®"),
          hint: `The CashScore® returned by Prism Data. Ranges from 1-999, with higher score indicating lower risk.\nThe "Prism Data CashScore® + FirstDetect" partner product must be enabled to use this insight.`,
        },
        prism_first_detect_score: {
          ...getNamedOutputMapping("Prism FirstDetect score"),
          hint: `The FirstDetect score returned by Prism Data. Ranges from 1-999, with higher score indicating lower risk.\nThe "Prism Data CashScore® + FirstDetect" partner product must be enabled to use this insight.`,
        },
      },
    },
    config: defaultResourceConfig,
  });
