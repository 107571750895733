import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { faInfoCircle, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";

import { Button } from "src/base-components/Button";
import { Card } from "src/base-components/Card";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { ManualReviewResponseFormFieldTypeEnum } from "src/clients/flow-api";
import { Tooltip } from "src/design-system/Tooltip";
import { ManualReviewDescriptionEditor } from "src/manualReviewNode/ManualReviewDescriptionEditor";
import { ManualReviewFormT } from "src/manualReviewNode/ManualReviewNodeEditor";
import { SchemaRow } from "src/manualReviewNode/SchemaRow";

export const ReviewResponseConfiguration: React.FC<{
  immutable: boolean;
}> = ({ immutable }) => {
  const { control } = useFormContext<ManualReviewFormT>();
  const { fields, append, remove, move } = useFieldArray<
    ManualReviewFormT,
    "response_form.fields",
    "_id"
  >({
    name: "response_form.fields",
    // useFieldArray's internal type for generated ids
    // With default value causes unexpected re-render when used with useSortable
    keyName: "_id",
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const fieldToMove = fields.findIndex((field) => field.id === active.id);
      const fieldToInsert = fields.findIndex((field) => field.id === over.id);
      if (fieldToMove !== -1 && fieldToInsert !== -1) {
        move(fieldToMove, fieldToInsert);
      }
    }
  };

  return (
    <Card className="mb-6">
      <Card.Header className="mb-2 font-inter-semibold-13px">
        Write a description of the reviewer's task
      </Card.Header>
      <Controller
        control={control}
        name="response_form.description"
        render={(props) => (
          <ManualReviewDescriptionEditor
            dataLoc="manual-review-response-description-editor"
            disabled={immutable}
            placeholder="Write a description of the task for the reviewer"
            value={props.field.value}
            onChange={props.field.onChange}
          />
        )}
      />
      <Divider spacing="my-4" />
      <p className="font-inter-semibold-13px">
        State which fields the reviewer should respond with
      </p>
      <ol className="my-4 flex flex-col gap-y-4">
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext
            disabled={immutable}
            items={fields.map((field) => field.id)}
          >
            {fields.map((property, index) => (
              <SchemaRow
                key={property.id}
                handleRemove={() => remove(index)}
                id={property.id}
                immutable={immutable}
                index={index}
              />
            ))}
          </SortableContext>
        </DndContext>
      </ol>
      <Button
        dataLoc="add-manual-review-response-field"
        disabled={immutable}
        iconLeft={faPlus}
        size="sm"
        variant="secondary"
        onClick={() =>
          append({
            id: uuid(),
            key: undefined,
            type: ManualReviewResponseFormFieldTypeEnum.STRING,
            required: true,
            name: "",
          })
        }
      >
        Add fields
      </Button>
      <Divider spacing="my-4" />
      <p className="mb-2 flex items-center font-inter-semibold-13px">
        Set output location for review metadata{" "}
        <Tooltip
          body="Specify a location in the data object for review metadata. This will include the username of the reviewer that submitted the review and the date & time when they did so."
          placement="top"
        >
          <Icon color="text-gray-500" icon={faInfoCircle} size="xs" />
        </Tooltip>
      </p>
      <Controller
        name="response_metadata_key"
        render={({ field, fieldState }) => (
          <AutocompleteCodeInput
            disabled={immutable}
            error={fieldState.error?.message}
            placeholder="field"
            prefix="data."
            value={field.value ?? ""}
            onChange={field.onChange}
          />
        )}
      />
    </Card>
  );
};
