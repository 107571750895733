import { Controller, useForm } from "react-hook-form";

import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { Input } from "src/base-components/Input";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";

type NameInput = { name: string; type: "all" | "failed" };

type Props = {
  open: boolean;
  flowName: string;
  createDataset: (name: string, onlyFailures: boolean) => Promise<void>;
  onClose: () => void;
  hasFailures: boolean;
};

export const CreateChildDatasetModal: React.FC<Props> = ({
  open,
  flowName,
  createDataset,
  onClose,
  hasFailures,
}) => {
  const { register, reset, handleSubmit, control } = useForm<NameInput>({
    defaultValues: {
      name: "",
      type: "all",
    },
  });

  const submitHandler = handleSubmit(async (values) => {
    await createDataset(values.name, values.type === "failed");
    onClose();
  });

  return (
    <ConfirmationModal
      afterLeave={() => reset()}
      confirmationButtonText="Create dataset"
      description={
        <p>
          Based on the current test run, we will generate a dataset for the
          Child Flow <span className="text-gray-800">{flowName}</span>
        </p>
      }
      open={open}
      title="Create test dataset"
      onClose={onClose}
      onConfirm={submitHandler}
    >
      <form onSubmit={submitHandler}>
        <div className="mb-2 mt-3 text-gray-800 font-inter-semibold-13px">
          Dataset name <RequiredAsterisk />
        </div>
        <Input fullWidth {...register("name")} />
        {hasFailures && (
          <>
            <p className="mt-6 text-gray-800 font-inter-semibold-13px">
              Which rows should be included in this dataset?{" "}
              <RequiredAsterisk />
            </p>
            <p className="text-gray-500 font-inter-normal-12px">
              This will help in narrow down your debugging experience.
            </p>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <SimpleRadioGroup
                  className="mt-2"
                  orientation="horizontal"
                  value={field.value}
                  onValueChange={field.onChange}
                >
                  <SimpleRadioGroup.Item
                    label="All rows"
                    labelClassName="pl-2 mr-14"
                    value="all"
                  />
                  <SimpleRadioGroup.Item
                    label="Only failed rows"
                    labelClassName="pl-2"
                    value="failed"
                  />
                </SimpleRadioGroup>
              )}
            />
          </>
        )}
      </form>
    </ConfirmationModal>
  );
};
