import {
  flip,
  FloatingPortal,
  useHover,
  useInteractions,
  useRole,
  useDismiss,
  useFocus,
  useFloating,
  autoUpdate,
  shift,
  FloatingArrow,
  arrow,
  Placement,
  UseHoverProps,
  safePolygon,
  offset,
} from "@floating-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useRef, useState } from "react";
import React from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { logger } from "src/utils/logger";

export type TooltipProps = {
  children: React.ReactNode;
  disabled?: boolean; // activated in legacy Tooltip component
  placement?: Placement;
  title?: React.ReactNode;
  body?: React.ReactNode;
  delay?: UseHoverProps["delay"]; // delayDuration in legacy Tooltip component
  asChild?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  headerAction?: {
    icon: IconProp;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onMouseDownCapture?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  };
  footerAction?: {
    text: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  };
  dataLoc?: string;
  offset?: number;
};

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  title,
  body,
  placement = "top",
  delay,
  disabled = false,
  open: controlledOpen,
  onOpenChange: controlledOnOpenChange,
  asChild,
  headerAction,
  footerAction,
  dataLoc,
  offset: offsetDistance = 10,
}) => {
  const [uncontrolledOpen, uncontrollerOnOpenChange] = useState(false);
  const arrowRef = useRef(null);

  const isOpen = disabled ? false : (controlledOpen ?? uncontrolledOpen);
  const onOpenChange = controlledOnOpenChange ?? uncontrollerOnOpenChange;

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange,
    placement,
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: offsetDistance,
        alignmentAxis: -offsetDistance,
      }),
      flip({
        fallbackAxisSideDirection: "start",
      }),
      shift(),
      arrow({ element: arrowRef }),
    ],
  });

  // Event listeners to change the open state
  const hover = useHover(context, {
    move: false,
    delay,
    handleClose: safePolygon(),
    enabled: !disabled,
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, { role: "tooltip" });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  // Dev environment checks
  if (process.env.NODE_ENV === "development") {
    if (!asChild) return;

    if (!React.isValidElement(children)) {
      logger.warn(
        "Tooltip: when using `asChild`, children must be a valid React element. Falling back to a button.",
      );
    }

    // Check if children can accept a ref
    const canForwardRef =
      React.isValidElement(children) &&
      (typeof children.type === "string" || hasForwardRef(children));

    if (!canForwardRef) {
      logger.warn(
        "Tooltip: when using `asChild`, children must be able to accept a ref. Falling back to a button.",
      );
    }
  }

  const isOnlyTitle = !body && title;

  return (
    <>
      {asChild && React.isValidElement(children) ? (
        React.cloneElement(
          children,
          getReferenceProps({
            ref: refs.setReference,
            tabIndex: -1,
            [`data-${isOpen ? "open" : "closed"}`]: true,
            ...children.props,
          }),
        )
      ) : (
        <button
          ref={refs.setReference}
          {...getReferenceProps({
            tabIndex: -1,
            [`data-${isOpen ? "open" : "closed"}`]: true,
            className: TOOLTIP_WRAPPER_CLASS,
          })}
          type="button"
        >
          {children}
        </button>
      )}
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            className={twJoin(
              "z-50 flex max-w-xs flex-col gap-y-2 bg-gray-800 p-4",
              isOnlyTitle ? "rounded-lg px-4 py-2.5" : "rounded-xl p-4",
            )}
            data-loc={dataLoc}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {(title || headerAction) && (
              <div className="flex items-center justify-between gap-x-1">
                {title && (
                  <div className="break-words text-white font-inter-semibold-13px">
                    {title}
                  </div>
                )}
                {headerAction?.icon && (
                  <Icon
                    color="text-white"
                    data-loc={`${dataLoc ?? "tooltip"}-inline-action`}
                    icon={headerAction.icon}
                    size="xs"
                    onClick={headerAction.onClick}
                    onMouseDownCapture={headerAction.onMouseDownCapture}
                  />
                )}
              </div>
            )}
            {body && (
              <div
                className={twJoin(
                  "font-inter-normal-12px",
                  title ? "text-gray-300" : "text-white",
                )}
              >
                {body}
              </div>
            )}
            {footerAction && (
              <button
                className="w-fit text-white underline font-inter-normal-13px"
                data-loc={`${dataLoc ?? "tooltip"}-action`}
                onClick={footerAction.onClick}
              >
                {footerAction.text}
              </button>
            )}
            <FloatingArrow
              ref={arrowRef}
              className="fill-gray-800"
              context={context}
              height={8}
              width={15}
            />
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export const TOOLTIP_WRAPPER_CLASS = "tktl-tooltip-wrapper" as const;

const hasForwardRef = (component: React.ReactNode) => {
  return (
    React.isValidElement(component) &&
    // Check if component uses forwardRef
    ((component.type as any).$$typeof === Symbol.for("react.forward_ref") ||
      // Check if memoized component forwards refs
      ((component.type as any).$$typeof === Symbol.for("react.memo") &&
        (component.type as any).type?.$$typeof ===
          Symbol.for("react.forward_ref")))
  );
};
