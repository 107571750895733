import { faRobot } from "@fortawesome/pro-regular-svg-icons";

import { Button } from "src/base-components/Button";

export const CopilotButton: React.FC<{
  onClick: () => void;
  disabled: boolean;
}> = ({ disabled, onClick }) => {
  return (
    <div className="relative">
      <div className="absolute left-1/2 top-1/2 h-[calc(100%+2px)] w-[calc(100%+2px)] -translate-x-1/2 -translate-y-1/2 rounded-md bg-[radial-gradient(110%_150%_at_0%_16%,#A1FF09_0%,#493FC1_100%)]" />
      <div className="relative">
        <Button
          dataLoc="ask-copilot-node"
          disabled={disabled}
          iconLeft={faRobot}
          size="sm"
          variant="secondary"
          onClick={onClick}
        >
          <span className="whitespace-nowrap">Ask Copilot</span>
        </Button>
        {/* cover the button's border */}
        <div className="pointer-events-none absolute top-0 h-full w-full rounded-md border border-white" />
      </div>
    </div>
  );
};
