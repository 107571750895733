import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { InfiniteData } from "@tanstack/react-query";
import axios from "axios";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { usePublishFlowVersion } from "src/api/flowVersionQueries";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { ErrorHint } from "src/base-components/ErrorHint";
import { ExternalLink } from "src/base-components/ExternalLink";
import { Input } from "src/base-components/Input";
import { LoadingView } from "src/base-components/LoadingView";
import { SearchParamsTabView } from "src/base-components/SearchParamsTabView";
import { Textarea } from "src/base-components/Textarea";
import { ChangeHistoryList } from "src/changeHistory/ChangeHistoryList";
import {
  canFetchNextPage,
  etag_including_comments,
  useFetchAllChanges,
  useLoadChangeHistory,
} from "src/changeHistory/queries";
import { ChangeLogDb } from "src/clients/flow-api";
import { DOCS_FUNDAMENTALS_VERSIONS_AND_PUBLISHING } from "src/constants/ExternalLinks";
import { Callout } from "src/design-system/Callout";
import { VersionUpdates } from "src/flow/modals/VersionUpdates";
import { getNameErrorMessage, nameValidations } from "src/flow/modals/utils";
import { useUserData } from "src/store/AuthStore";
import { useGraphStore } from "src/store/StoreProvider";
import { logger } from "src/utils/logger";

type PublishFlowInputT = {
  name: string;
  description: string;
};

type PublishVersionModalPropsT = {
  open: boolean;
  onClose: () => void;
  flow: FlowT;
  flowVersion: FlowVersionT | undefined;
  afterLeave?: () => void;
};

export const PublishVersionModal: React.FC<PublishVersionModalPropsT> =
  observer(({ open, onClose, flowVersion, afterLeave }) => {
    const { mutateAsync: publishVersion } = usePublishFlowVersion();
    const { signed_in_user_id } = useUserData();
    const { resultsOutdated } = useGraphStore();
    const changeHistory = useLoadChangeHistory(
      flowVersion?.id,
      etag_including_comments(flowVersion?.etag),
    );
    useFetchAllChanges(changeHistory);
    const canFetchNextChanges = canFetchNextPage({
      hasNextPage: changeHistory.hasNextPage,
      isFetchingNextPage: changeHistory.isFetchingNextPage,
    });
    const amountOfChanges = changeHistory?.data?.pages.flatMap(
      (page) => page,
    ).length;

    const fetchNextChanges = () => {
      changeHistory.fetchNextPage();
    };
    const {
      register,
      handleSubmit,
      reset,
      setError,
      formState: { errors },
    } = useForm<PublishFlowInputT>({
      mode: "onChange",
    });

    useEffect(() => {
      // Everytime the Modal is reopened with a new version we need to reset the form to set the new default value
      if (open && flowVersion) {
        reset({
          name: flowVersion.name,
          description: flowVersion.meta.release_note,
        });
      }
    }, [reset, open, flowVersion]);

    const onConfirm = handleSubmit(async (input: PublishFlowInputT) => {
      if (!flowVersion) return;
      try {
        // First set the status of the version to published
        await publishVersion({
          publishArgs: {
            version: flowVersion,
            name: input.name,
            release_note: input.description,
            published_by_id: signed_in_user_id ?? "",
            published_at: new Date(Date.now()).toISOString(),
          },
          enableTrafficPolicies: true,
        });
        onClose();
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 409) {
          setError("name", { type: "notAvailable" }, { shouldFocus: true });
        }
        logger.error(`Error when publishing Flow: ${error}`);
      }
    });

    const renderTabView = () => (
      <>
        <div className="flex flex-col gap-y-2 text-gray-600 font-inter-normal-12px">
          {resultsOutdated && (
            <Callout icon={faInfoCircle} type="warning">
              You have not tested your latest changes to the Decision Flow.
              <br />
              It is recommended to test the flow before publishing.
            </Callout>
          )}
          <div className="-ml-4 -mr-2">
            <SearchParamsTabView
              defaultValue="publish-details"
              searchParamKey="publish-flow-modal"
              tabs={[
                {
                  title: "Publish Details",
                  dataLoc: "publish-details-tab",
                  searchParamValue: "publish-details",
                  component: (
                    <div className="ml-4 mr-2 pt-4">
                      {renderName()}
                      {renderDescription()}
                    </div>
                  ),
                },
                {
                  title: "Updates",
                  dataLoc: "updates-tab",
                  searchParamValue: "updates",
                  suffix: amountOfChanges ? (
                    <VersionUpdates totalUpdates={amountOfChanges} />
                  ) : (
                    <></>
                  ),
                  component: (
                    <LoadingView
                      queryResult={changeHistory}
                      renderUpdated={(data: InfiniteData<ChangeLogDb[]>) => (
                        <ChangeHistoryList
                          canFetchNextChanges={canFetchNextChanges}
                          changes={
                            data.pages
                              ? data.pages.flatMap((page) => page)
                              : undefined
                          }
                          containerClassName="mx-4 max-h-[300px]"
                          fetchNextChanges={fetchNextChanges}
                          isFetchingNextPage={changeHistory.isFetchingNextPage}
                        />
                      )}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </>
    );

    const renderName = () => (
      <>
        <div className="mb-2 mt-3 flex flex-col text-gray-600 font-inter-normal-12px">
          {`Publish draft <${flowVersion?.name}> as`}
        </div>
        <Input
          defaultValue={flowVersion?.name}
          errored={Boolean(errors.name)}
          fullWidth
          {...register("name", {
            required: true,
            validate: nameValidations,
          })}
        />
        <div className="mt-2.5">
          <Callout
            action={{
              text: (
                <ExternalLink
                  className="underline font-inter-medium-12px"
                  href={DOCS_FUNDAMENTALS_VERSIONS_AND_PUBLISHING}
                >
                  Read more
                </ExternalLink>
              ),
              onClick: () => {},
            }}
            type="info"
          >
            You can set a version as default after it is published
          </Callout>
        </div>
        <ErrorHint className="mb-6" height="h-2">
          {errors.name && getNameErrorMessage(errors.name.type)}
        </ErrorHint>
      </>
    );

    const renderDescription = () => (
      <>
        <div className="mb-2 text-gray-600 font-inter-normal-12px">
          Version description
        </div>
        <div className="mb-4">
          <Textarea
            autoComplete="off"
            defaultValue={flowVersion?.meta?.release_note}
            placeholder="Enter description"
            {...register("description")}
          />
        </div>
      </>
    );

    return (
      <ConfirmationModal
        afterLeave={afterLeave}
        confirmationButtonText="Publish version"
        description={
          <>
            <p>You are about to publish a new version of this Decision Flow</p>
            <p>
              The version description, schema, and logic cannot be changed
              later.{" "}
              <ExternalLink href={DOCS_FUNDAMENTALS_VERSIONS_AND_PUBLISHING}>
                Learn more
              </ExternalLink>
            </p>
          </>
        }
        open={open}
        size="md"
        title="Publish version"
        onClose={onClose}
        onConfirm={onConfirm}
      >
        {renderTabView()}
      </ConfirmationModal>
    );
  });
