import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBolt,
  faBoxArchive,
  faBracketsCurly,
  faCheck,
  faCheckCircle,
  faClose,
  faExclamationCircle,
  faEye,
  faFileImport,
  faInfoCircle,
  faMessageLines,
  faObjectUngroup,
  faPaste,
  faScissors,
  faSliders,
  faSync,
  faTrashAlt,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import assert from "assert";
import { ReactNode } from "react";

import { NodeBET } from "src/api/flowTypes";
import { Operation } from "src/api/flowVersionUpdateIndex";
import { getOperation } from "src/changeHistory/changeUtils";
import {
  ChangeLogDb,
  CommentResourceType,
  FlowVersionUpdate,
  NodeUpsert,
  ResourceType,
  ReviewCommentStatus,
} from "src/clients/flow-api";
import { getDefaultSplitNodeV2 } from "src/constants/DefaultValues";
import { BeMappedNode } from "src/constants/NodeDataTypes";
import { BasicNodeIcon, getNodeIconFromNode } from "src/constants/NodeIcons";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { GraphTransform } from "src/utils/GraphUtils";
import { logger } from "src/utils/logger";
import { assertUnreachable } from "src/utils/typeUtils";

export enum AdditionalTypes {
  USER = "user",
}
export type AppResourceType = ResourceType | AdditionalTypes;

const NodeUpsertToBeMappedNode = (node: NodeUpsert) => {
  try {
    return GraphTransform.nodeMapper(node as unknown as NodeBET, 0);
  } catch (e) {
    logger.error(e);
    return "Errored" as const;
  }
};

const ChangeHistoryGroupIcon: React.FC<{ length: number }> = ({ length }) => (
  <div className="flex h-6 w-6 flex-row gap-0.5 overflow-hidden rounded-md">
    <div className="flex grow flex-col gap-0.5">
      {length >= 1 && <div className="grow bg-gray-200" />}
      {length >= 4 && <div className="grow bg-gray-200" />}
    </div>
    {length >= 2 && (
      <div className="flex grow flex-col gap-0.5">
        <div className="grow bg-gray-200" />
        {length >= 3 && <div className="grow bg-gray-200" />}
      </div>
    )}
  </div>
);

const ChangeHistoryIcon: React.FC<
  | {
      icon: IconProp;
      bg?: string;
      color?: string;
      node?: never;
    }
  | { node: BeMappedNode; icon?: never; bg?: never; color?: never }
> = ({ icon, bg = "bg-gray-100", color = "text-gray-800", node }) => (
  <div className="h-6 w-6">
    {node !== undefined ? (
      <>{getNodeIconFromNode(node, "sm")}</>
    ) : (
      <BasicNodeIcon
        backgroundClassName={bg}
        colorClassName={color}
        icon={icon}
        size="sm"
      />
    )}
  </div>
);

const deleteIcon = <ChangeHistoryIcon icon={faTrashAlt} />;

const cutIcon = <ChangeHistoryIcon icon={faScissors} />;

const getDeletedNodeNames = (diff: FlowVersionUpdate): string => {
  if (!diff.deleted_nodes_names || !diff.graph || !diff.graph.nodes)
    return "a node";
  const nodesIds = Object.keys(diff.graph.nodes);
  return nodesIds.map((id) => diff.deleted_nodes_names![id]).join(", ");
};

const getDeletedNodeGroupNames = (diff: FlowVersionUpdate): string => {
  if (!diff.deleted_nodegroups_names || !diff.graph || !diff.graph.node_groups)
    return "a node group";
  const nodeGroupsIds = Object.keys(diff.graph.node_groups);
  return nodeGroupsIds
    .map((id) => diff.deleted_nodegroups_names![id])
    .join(", ");
};

export const findUserMentions = (content: string): string[] => {
  const userMentions = content.matchAll(/@user:(?<uid>[a-zA-Z0-9-]+)/g);
  return Array.from(userMentions, (m) => m?.groups?.uid || []).flat();
};

export const getChangeResourceAndIcon = (
  change: ChangeLogDb,
): {
  resourceName?: string;
  resourceType?: AppResourceType;
  resourceId?: string;
  resourceIds?: string[];
  icon: React.ReactNode;
  inPill: boolean;
} => {
  const operation = getOperation(change);
  const diff = change.diff;
  switch (operation) {
    case Operation.EDIT_PARAMETERS:
      return {
        resourceName: "parameters",
        icon: <ChangeHistoryIcon icon={faSliders} />,
        inPill: false,
      };
    case Operation.EDIT_SCHEMA:
      return {
        resourceName: "schema",
        icon: <ChangeHistoryIcon icon={faBracketsCurly} />,
        inPill: false,
      };
    case Operation.CREATE_NODE:
    case Operation.CREATE_SPLIT_NODE:
    case Operation.EDIT_NODE:
    case Operation.RENAME_NODE:
    case Operation.CREATE_BRANCH:
    case Operation.DELETE_BRANCH:
    case Operation.PASTE_NODE:
      assert(diff !== undefined);
      if (diff.graph === undefined || diff.graph.nodes === undefined) {
        break;
      }
      // If multiple nodes are in the diff (e.g. in case of delete branch), we want to get the actual node that was changed not the deleted branch node
      const entry = Object.entries(diff.graph.nodes).find(
        ([_, node]) =>
          node !== null &&
          !node.meta?.is_split_branch_node &&
          !node.meta?.is_split_merge_node,
      );
      if (!entry) {
        break;
      }
      const [node_id, node] = entry;
      try {
        const uiNode = GraphTransform.nodeMapper(node as unknown as NodeBET, 0);
        return {
          resourceName: uiNode.data.label,
          resourceId: node_id,
          resourceType: ResourceType.NODE,
          icon: (
            <div className="h-6 w-6">{getNodeIconFromNode(uiNode, "sm")}</div>
          ),
          inPill: true,
        };
      } catch (e) {
        logger.error(e);
        // If mapping fails we should mark this change entry as an error
        const uiNode = NodeUpsertToBeMappedNode(node);
        if (uiNode === "Errored") {
          return {
            resourceName: "error",
            icon: <ChangeHistoryIcon icon={faWarning} />,
            inPill: true,
          };
        }
        return {
          resourceName: uiNode.data.label,
          icon: <ChangeHistoryIcon node={uiNode} />,
          inPill: true,
        };
      }
    case Operation.RENAME_BRANCH:
      assert(diff !== undefined);
      if (diff.graph === undefined || diff.graph.nodes === undefined) {
        break;
      }
      // If multiple nodes are in the diff (e.g. in case of delete branch), we want to get the actual node that was changed not the deleted branch node
      const branchNode = Object.values(diff.graph.nodes).find(
        (n) => n.meta?.is_split_branch_node,
      );
      if (!branchNode) {
        break;
      }
      return {
        resourceName: branchNode.name,
        icon: <ChangeHistoryIcon node={getDefaultSplitNodeV2({})} />,
        inPill: true,
      };
    case Operation.RENAME_GROUP:
    case Operation.CREATE_GROUP:
      assert(diff !== undefined);
      if (diff.graph === undefined || diff.graph.node_groups === undefined) {
        break;
      }
      const group = Object.values(diff.graph.node_groups)[0];
      return {
        resourceName: group.name,
        icon: <ChangeHistoryGroupIcon length={group.node_ids?.length ?? 0} />,
        inPill: true,
      };
    case Operation.DELETE_NODE:
      assert(diff !== undefined);
      return {
        resourceName: getDeletedNodeNames(diff),
        icon: deleteIcon,
        inPill: false,
      };
    case Operation.DELETE_SPLIT_NODE:
    case Operation.DELETE_NODES:
      assert(diff !== undefined);
      return {
        resourceName: getDeletedNodeNames(diff),
        icon: deleteIcon,
        inPill: false,
      };
    case Operation.CUT_NODE:
      assert(diff !== undefined);
      return {
        resourceName: getDeletedNodeNames(diff),
        icon: cutIcon,
        inPill: false,
      };
    case Operation.CUT_NODES:
      assert(diff !== undefined);
      return {
        resourceName: getDeletedNodeNames(diff),
        icon: cutIcon,
        inPill: false,
      };
    case Operation.CUT_GROUP:
      assert(diff !== undefined);
      return {
        resourceName: getDeletedNodeGroupNames(diff),
        icon: cutIcon,
        inPill: false,
      };
    case Operation.DELETE_GROUP_WITH_NODES:
      assert(diff !== undefined);
      return {
        resourceName: getDeletedNodeGroupNames(diff),
        icon: deleteIcon,
        inPill: false,
      };
    case Operation.UNGROUP_GROUP:
      assert(diff !== undefined);
      return {
        resourceName: getDeletedNodeGroupNames(diff),
        icon: <ChangeHistoryIcon icon={faObjectUngroup} />,
        inPill: false,
      };
    case Operation.PASTE_NODES:
      return {
        resourceName: "nodes",
        icon: <ChangeHistoryIcon icon={faPaste} />,
        inPill: false,
      };
    case Operation.VERSION_CREATED:
      return {
        resourceName: "this Decision Flow version",
        icon: <ChangeHistoryIcon icon={faBolt} />,
        inPill: false,
      };
    case Operation.VERSION_DUPLICATED:
      assert(diff !== undefined);
      return {
        resourceName: diff.name ?? "this Decision Flow version",
        icon: <ChangeHistoryIcon icon={faBolt} />,
        inPill: false,
      };
    case Operation.VERSION_RESTORED:
      return {
        resourceName: "this Decision Flow version",
        icon: <ChangeHistoryIcon icon={faSync} />,
        inPill: false,
      };
    case Operation.VERSION_METADATA_EDITED:
      assert(diff !== undefined);
      const changed = diff.name
        ? "name"
        : diff.meta?.release_note
          ? "description"
          : "metadata";
      return {
        resourceName: `version ${changed}`,
        icon: <ChangeHistoryIcon icon={faInfoCircle} />,
        inPill: true,
      };
    case Operation.PUBLISH:
      return {
        resourceName: "this Decision Flow version",
        icon: <ChangeHistoryIcon icon={faCheckCircle} />,
        inPill: true,
      };

    case Operation.ARCHIVE:
      return {
        resourceName: "this Decision Flow version",
        icon: <ChangeHistoryIcon icon={faBoxArchive} />,
        inPill: true,
      };
    case Operation.UNKNOWN:
      return {
        resourceName: "this Decision Flow version",
        icon: <ChangeHistoryIcon icon={faBolt} />,
        inPill: false,
      };
    case Operation.VERSION_IMPORTED:
      return {
        resourceName: "this Decision Flow version",
        icon: <ChangeHistoryIcon icon={faFileImport} />,
        inPill: false,
      };
    case Operation.CREATE_COMMENT:
      assert(change.comment !== undefined);
      const commentIcon = (
        <ChangeHistoryIcon
          bg="bg-yellow-50"
          color="text-yellow-500"
          icon={faMessageLines}
        />
      );
      if (change.comment.resource_type === CommentResourceType.FLOW_VERSION) {
        return {
          resourceName: "the flow",
          icon: commentIcon,
          inPill: false,
        };
      } else if (change.comment.resource_type === CommentResourceType.NODE) {
        return {
          resourceType: ResourceType.NODE,
          resourceId: change.comment.resource_id,
          icon: commentIcon,
          inPill: true,
        };
      } else if (change.comment.resource_type === CommentResourceType.REVIEW) {
        if (change.comment.reviewer_status === ReviewCommentStatus.APPROVED) {
          return {
            resourceName: "",
            icon: (
              <ChangeHistoryIcon
                bg="bg-green-50"
                color="text-green-400"
                icon={faCheck}
              />
            ),
            inPill: false,
          };
        }

        if (
          change.comment.reviewer_status ===
          ReviewCommentStatus.REQUESTED_CHANGES
        ) {
          return {
            resourceName: "",
            icon: (
              <ChangeHistoryIcon
                bg="bg-yellow-50"
                color="text-yellow-400"
                icon={faExclamationCircle}
              />
            ),
            inPill: false,
          };
        }

        if (change.comment.reviewer_status === ReviewCommentStatus.CANCELLED) {
          return {
            resourceName: "",
            icon: (
              <ChangeHistoryIcon
                bg="bg-gray-100"
                color="text-gray-800"
                icon={faClose}
              />
            ),
            inPill: false,
          };
        }

        if (
          change.comment.reviewer_status ===
          ReviewCommentStatus.REVIEW_REREQUESTED
        ) {
          return {
            resourceType: AdditionalTypes.USER,
            resourceIds: findUserMentions(change.comment.content),
            icon: (
              <ChangeHistoryIcon
                bg="bg-indigo-50"
                color="text-indigo-600"
                icon={faEye}
              />
            ),
            inPill: true,
          };
        }

        return {
          resourceName: "the review",
          icon: commentIcon,
          inPill: false,
        };
      } else {
        assert(false);
      }
    // eslint-disable-next-line no-fallthrough
    default:
      assertUnreachable(operation);
  }

  return {
    resourceName: "this Decision Flow version",
    icon: <ChangeHistoryIcon icon={faBolt} />,
    inPill: false,
  };
};

const getChangedNodes = (change: ChangeLogDb): string[] | undefined => {
  return change.diff?.graph?.nodes && Object.keys(change.diff?.graph?.nodes);
};

export type ChangesGroupedByResource = {
  nodes: {
    [key: string]: {
      changes: ChangeLogDb[];
      displayName: string;
      displayIcon: ReactNode;
    };
  };
  schema: ChangeLogDb[];
  parameter: ChangeLogDb[];
};

const NodeTypesNotRelevantForGrouping = [
  NODE_TYPE.OUTPUT_NODE,
  NODE_TYPE.INPUT_NODE,
  NODE_TYPE.SPLIT_BRANCH_NODE_V2,
  NODE_TYPE.SPLIT_MERGE_NODE,
];

/**
 * This function assumes that ALL changes are passed to it. It might not give the correct node names and icons
 * for deleted nodes if only a partial change log is passed to it.
 */
type GroupChangesByResourceParams = {
  changes: ChangeLogDb[];
  afterEtagFilter?: string;
  excludeNodesOnlyPartOfFirstChange?: boolean;
};

export const groupChangesByResource = ({
  changes,
  afterEtagFilter,
  excludeNodesOnlyPartOfFirstChange = false,
}: GroupChangesByResourceParams):
  | ChangesGroupedByResource
  | "noGroupedChanges" => {
  const schemaChanges: ChangeLogDb[] = [];
  const parameterChanges: ChangeLogDb[] = [];
  const nodeChanges: { [key: string]: ChangeLogDb[] } = {};
  for (const change of changes) {
    if (change.diff?.operation === Operation.EDIT_SCHEMA) {
      schemaChanges.push(change);
    }
    if (change.diff?.operation === Operation.EDIT_PARAMETERS) {
      parameterChanges.push(change);
    }
    const nodesChanged = getChangedNodes(change);
    if (nodesChanged) {
      for (const nodeId of nodesChanged) {
        if (!nodeChanges[nodeId]) {
          nodeChanges[nodeId] = [];
        }
        nodeChanges[nodeId].push(change);
      }
    }
  }
  const nodes: ChangesGroupedByResource["nodes"] = {};
  for (const nodeId in nodeChanges) {
    const nodeGotDeleted =
      nodeChanges[nodeId][0].diff?.graph?.nodes?.[nodeId] === null;
    // If the node got deleted in its most recent change, get the latest state from the previous change
    const latestNodeState = nodeChanges[nodeId].at(nodeGotDeleted ? 1 : 0)?.diff
      ?.graph?.nodes?.[nodeId];

    const uiNode = latestNodeState && NodeUpsertToBeMappedNode(latestNodeState);
    if (!uiNode || uiNode === "Errored") {
      nodes[nodeId] = {
        changes: nodeChanges[nodeId],
        displayName: "error",
        displayIcon: <ChangeHistoryIcon icon={faWarning} />,
      };
      continue;
    }
    if (NodeTypesNotRelevantForGrouping.includes(uiNode.type)) {
      continue;
    }
    nodes[nodeId] = {
      changes: nodeChanges[nodeId],
      displayName: uiNode.data.label,
      displayIcon: <ChangeHistoryIcon node={uiNode} />,
    };
  }

  if (afterEtagFilter) {
    Object.keys(nodes).forEach((nodeId) => {
      const filteredChanges = nodes[nodeId].changes.filter(
        (change) => change.etag && change.etag > afterEtagFilter,
      );
      if (filteredChanges.length > 0) {
        nodes[nodeId].changes = filteredChanges;
      } else {
        delete nodes[nodeId];
      }
    });
  }

  if (excludeNodesOnlyPartOfFirstChange) {
    const firstChange =
      changes[0].etag < changes[changes.length - 1].etag
        ? changes[0]
        : changes[changes.length - 1];
    const firstChangeNodes = getChangedNodes(firstChange);
    if (firstChangeNodes) {
      for (const nodeId of firstChangeNodes) {
        if (nodes[nodeId] && nodes[nodeId].changes.length <= 1) {
          delete nodes[nodeId];
        }
      }
    }
  }

  if (
    Object.keys(nodes).length === 0 &&
    schemaChanges.length === 0 &&
    parameterChanges.length === 0
  ) {
    return "noGroupedChanges";
  }
  return {
    schema: schemaChanges,
    parameter: parameterChanges,
    nodes,
  };
};
