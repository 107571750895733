import React from "react";

import { MultipleOutcomeFilter } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { ColumnMultiValueFilter } from "src/base-components/ColumnFilter";

export const EnumFilter: React.FC<{
  dataLoc: string;
  groupKey: string;
  property: string;
  enumValues: string[];
  filter: MultipleOutcomeFilter | null;
  onReset: (groupKey: string, property: string) => void;
  onApply: (groupKey: string, filter: MultipleOutcomeFilter["filter"]) => void;
}> = ({
  dataLoc,
  groupKey,
  property,
  enumValues,
  filter,
  onReset,
  onApply,
}) => {
  const filterValues =
    filter?.filter.values.map((value) => JSON.stringify(value)) ?? [];

  const handleApply = (selected: string[]) => {
    if (selected.length === 0) {
      return onReset(groupKey, property);
    }

    return onApply(groupKey, {
      property,
      operator: "in",
      values: selected.map((value) => JSON.parse(value)),
    });
  };

  const isFiltering = Boolean(filter);
  const options = enumValues?.map((value) => ({
    key: value,
    value: JSON.parse(value),
  }));

  return (
    <ColumnMultiValueFilter
      dataLoc={dataLoc}
      defaultValue={filterValues}
      headline={`Filter by ${property}:`}
      isFiltering={isFiltering}
      options={options}
      onApply={handleApply}
      onResetRequest={() => onReset(groupKey, property)}
    />
  );
};
