import { Navigate } from "react-router-dom";

import { useCapabilities } from "src/hooks/useCapabilities";
import { DashboardPageParamsT, getUrlToSettingsPage } from "src/router/urls";
import { SettingsSubpages } from "src/settings/SettingsPage";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const SettingsPageRedirect: React.FC = () => {
  const { connections, apiKeys, usersPermissions } = useCapabilities();
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();

  if (connections.canAccess) {
    return (
      <Navigate
        to={getUrlToSettingsPage(orgId, wsId, SettingsSubpages.Connections)}
      />
    );
  }

  if (apiKeys.canAccess) {
    return (
      <Navigate
        to={getUrlToSettingsPage(orgId, wsId, SettingsSubpages.ApiKeys)}
      />
    );
  }

  if (usersPermissions.canAccess) {
    return (
      <Navigate
        to={getUrlToSettingsPage(orgId, wsId, SettingsSubpages.Users)}
      />
    );
  }

  return null;
};
