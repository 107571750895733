import {
  faArrowDownToLine,
  faEdit,
  faSpinner,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import { m } from "framer-motion";
import React, { useState } from "react";
import { v4 as uuidV4 } from "uuid";

import {
  informPreparingDownload,
  pollAndNotifyDownloadSuccess,
} from "src/api/downloadUtils";
import { ExporterDatasetJobsEndpoint } from "src/api/endpoints";
import { Dataset, DatasetFileFormat } from "src/api/types";
import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { separateFilenameExtension } from "src/datasets/utils";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { useCapabilities } from "src/hooks/useCapabilities";
import { JobSource } from "src/jobs/types";
import { useFlowContext } from "src/router/routerContextHooks";
import { stopPropagationWrapper } from "src/utils/functions";
import { logger } from "src/utils/logger";

type Props = {
  onDelete: () => void;
  onEdit?: () => void;
  animateOnMount?: boolean;
  source: JobSource;
  dataset?: Dataset;
};

export const SourceActions: React.FC<Props> = ({
  dataset,
  source,
  onDelete,
  onEdit,
  animateOnMount = false,
}) => {
  const { jobs } = useCapabilities();

  return (
    <m.div
      animate={
        animateOnMount
          ? {
              opacity: 1,
              transition: {
                type: "tween",
                ease: "easeOut",
                delay: 0.15,
              },
            }
          : undefined
      }
      className="flex gap-x-2"
      exit={{ opacity: 0, transition: { duration: 0.08 } }}
      initial={animateOnMount ? { opacity: 0 } : undefined}
      transition={{ type: "tween", ease: "easeOut" }}
      layout
    >
      {onEdit && (
        <Tooltip placement="top" title="Edit source" asChild>
          <Icon
            color="text-gray-500 hover:text-gray-700"
            dataLoc={`edit-${source.name}`}
            icon={faEdit}
            size="xs"
            onClick={onEdit}
          />
        </Tooltip>
      )}
      {dataset && <DatasetDownloadDropdown dataset={dataset} />}
      {jobs.canDeleteSource && (
        <Tooltip placement="top" title="Delete source" asChild>
          <Icon
            color="text-gray-500 hover:text-gray-700"
            dataLoc={`delete-${source.name}`}
            icon={faTrashCan}
            size="xs"
            onClick={stopPropagationWrapper(onDelete)}
          />
        </Tooltip>
      )}
    </m.div>
  );
};

const DatasetDownloadDropdown: React.FC<{ dataset: Dataset }> = ({
  dataset,
}) => {
  const { workspace, flow } = useFlowContext();
  const { name: fileName } = separateFilenameExtension(dataset.name);
  const [isDownloadingDataset, setIsDownloadingDataset] = useState(false);

  const handleDownload = async (format: DatasetFileFormat) => {
    try {
      setIsDownloadingDataset(true);
      const toastId = uuidV4();
      informPreparingDownload(toastId);
      const job = await ExporterDatasetJobsEndpoint.createDownloadDatasetJob(
        workspace.base_url!,
        {
          flow_id: flow.id,
          request: { dataset_id: dataset.id, format },
        },
      );
      await pollAndNotifyDownloadSuccess(job, workspace.base_url!, toastId);
    } catch (err) {
      logger.error("Failed to create download dataset job", err);
      toastActions.failure({
        title: `Something went wrong when downloading ${fileName}`,
      });
    } finally {
      setIsDownloadingDataset(false);
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <FixedPositionedDropdown
        buttonClassName="border-0 ring-0 ui-open:border-0 ui-open:ring-0 focus:border-0 focus:ring-0 focus:ring-shadow bg-transparent"
        dataLoc={`download-${dataset.name}`}
        elements={[
          { key: "json", value: "Download as JSON" } as const,
          { key: "csv", value: "Download as CSV" } as const,
          { key: "xlsx", value: "Download as XLSX" } as const,
        ]}
        itemsClassNames="w-66 font-inter-semibold-14px"
        renderButtonValue={() => (
          <Tooltip
            placement="top"
            title={
              isDownloadingDataset
                ? "There's a dataset download in progress"
                : "Download test dataset"
            }
            asChild
          >
            <span>
              <Icon
                color="text-gray-500 hover:text-gray-700"
                disabled={isDownloadingDataset}
                icon={isDownloadingDataset ? faSpinner : faArrowDownToLine}
                size="xs"
                spin={isDownloadingDataset}
              />
            </span>
          </Tooltip>
        )}
        renderValue={(value) => (
          <div className="bg-white p-5 hover:bg-gray-50">{value.value}</div>
        )}
        onSelect={handleDownload}
      />
    </div>
  );
};
