import { faCheck, faMultiply } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";

import { CellContent } from "./types";
import { Pill } from "src/base-components/Pill";
import { CustomPopover } from "src/base-components/Popover";
import { ObjectDetailPane } from "src/dataTable/ObjectDetailPane";
import { RawCell } from "src/dataTable/RawCell";
import { speakPythonPrimitive } from "src/utils/speakPython";
import { isJsonObject } from "src/utils/stringUtils";

type CommonProps = {
  expectedOutput?: { value: string; isMatch: boolean };
  actualOutput?: { value: string; isMatch: boolean };
};

type ExpectedDataCellProps<T> = {
  cell: CellContent<T>;
  suffix?: React.ReactNode;
  className: string;
  classNameOverrides?: string;
} & CommonProps;

type ExpectedDataPopoverProps = {
  value?: any;
  children: React.ReactNode;
} & CommonProps;

const renderValueAccordingToItsType = (value: string) => {
  if (isJsonObject(value)) {
    return (
      <div className="mt-2">
        <ObjectDetailPane
          innerDimensionClass="max-h-64 max-w-164"
          jsonObject={JSON.parse(value)}
        />
      </div>
    );
  } else {
    return (
      <p className="mt-2 break-words text-gray-800 font-inter-normal-13px">
        {speakPythonPrimitive(value)}
      </p>
    );
  }
};

export const ExpectedDataPopover = ({
  expectedOutput,
  actualOutput,
  value,
  children,
}: ExpectedDataPopoverProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <CustomPopover
      button={children}
      isOpen={tooltipOpen}
      placement="top"
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
    >
      <div
        className="decideScrollbar max-h-150 min-w-[220px] max-w-128 overflow-auto px-3 pb-2 pt-3"
        data-loc="expected-data-info-popover"
      >
        <Pill size="sm" variant="gray">
          <Pill.Text>Calculated Value</Pill.Text>
        </Pill>
        {renderValueAccordingToItsType(actualOutput?.value ?? value)}
        <div className="mt-2 border-t border-gray-100" />
        <div className="mt-2 flex items-center justify-between gap-x-3">
          <Pill size="sm" variant="gray">
            <Pill.Text>Expected Value</Pill.Text>
          </Pill>
          {actualOutput?.isMatch || expectedOutput?.isMatch ? (
            <MatchPill />
          ) : (
            <MismatchPill />
          )}
        </div>
        {renderValueAccordingToItsType(expectedOutput?.value ?? value)}
      </div>
    </CustomPopover>
  );
};

export const ExpectedDataCell = <T,>(props: ExpectedDataCellProps<T>) => {
  return (
    <ExpectedDataPopover value={props.cell.getValue()} {...props}>
      <RawCell {...props} />
    </ExpectedDataPopover>
  );
};

export const MatchPill = () => (
  <Pill dataLoc="field-match-status" size="sm" variant="green">
    <Pill.Text>Match</Pill.Text>
    <Pill.Icon icon={faCheck} />
  </Pill>
);

export const MismatchPill = () => (
  <Pill dataLoc="field-match-status" size="sm" variant="yellow">
    <Pill.Text>Mismatch</Pill.Text>
    <Pill.Icon icon={faMultiply} />
  </Pill>
);
