import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { AnimatePresence, m } from "framer-motion";
import { keyBy, range } from "lodash";
import { useState } from "react";
import { twJoin } from "tailwind-merge";

import { FlowT, FlowVersionStatusT } from "src/api/flowTypes";
import { usePublishFlowVersion } from "src/api/flowVersionQueries";
import { useActivateTrafficPolicy, useTrafficPolicies } from "src/api/queries";
import { useWorkspaceUsers } from "src/api/taktile/queries";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import { CustomPopover } from "src/base-components/Popover";
import {
  FlowVersionStatus,
  TrafficPolicyInDB,
  TrafficPolicyWindowInDB,
} from "src/clients/flow-api";
import { OrganizationUser } from "src/clients/taktile-api";
import { AssembleDatasetModal } from "src/datasets/AssembleDatasetModal/Modal";
import { toastActions } from "src/design-system/Toast/utils";
import { SubHeader } from "src/flow/SubHeader";
import { User } from "src/flow/User";
import { ExcludesFalse } from "src/flow/types";
import { useCapabilities } from "src/hooks/useCapabilities";
import { maxTableWidth } from "src/layout/constants";
import { ManageTrafficModal } from "src/manageTraffic/Modal";
import { useFlowContext } from "src/router/routerContextHooks";
import { DashboardPageParamsT } from "src/router/urls";
import { useUserData } from "src/store/AuthStore";
import { formatDate } from "src/utils/datetime";
import { getVersionsColors } from "src/utils/getVersionsColors";
import { logger } from "src/utils/logger";
import { useParamsDecode } from "src/utils/useParamsDecode";

type TimelinePoint = {
  timeWindow: TrafficPolicyWindowInDB;
  trafficPolicy: TrafficPolicyInDB;
  user?: OrganizationUser;
};

const getTimelineForFlow = (
  flowTrafficPolicies: TrafficPolicyInDB[],
  users: OrganizationUser[],
): TimelinePoint[] => {
  const timelinePoints = flowTrafficPolicies.flatMap((trafficPolicy) =>
    trafficPolicy.traffic_policy_windows
      ? trafficPolicy.traffic_policy_windows.map((timeWindow) => ({
          timeWindow,
          trafficPolicy,
          user: users.find((u) => u.id === timeWindow.created_by_user_id),
        }))
      : [],
  );

  return timelinePoints.sort(
    (a, b) =>
      new Date(b.timeWindow.starts_at).valueOf() -
      new Date(a.timeWindow.starts_at).valueOf(),
  );
};

const PolicyReactivationModal: React.FC<{
  afterLeave: () => void;
  onConfirm: (policyId: string, archivedVersionIds: string[]) => void;
  onClose: () => void;
  open: boolean;
  flow: FlowT;
  rollbackPolicy?: TrafficPolicyInDB;
}> = ({ rollbackPolicy, afterLeave, open, onClose, onConfirm, flow }) => {
  const versionsByKey = keyBy(flow.versions, "id");
  const policyArchivedVersions =
    rollbackPolicy?.policy?.flow_versions.filter(
      (version) =>
        versionsByKey[version.flow_version_id]?.status ===
        FlowVersionStatusT.ARCHIVED,
    ) ?? [];

  const moreThanOneArchivedVersions = policyArchivedVersions.length > 1;

  return (
    <ConfirmationModal
      afterLeave={afterLeave}
      confirmationButtonText={
        policyArchivedVersions.length
          ? `Republish version${
              moreThanOneArchivedVersions ? "s" : ""
            } and Rollback`
          : "Rollback"
      }
      open={open}
      title={rollbackPolicy ? `Rollback to ${rollbackPolicy.name}` : ""}
      onClose={onClose}
      onConfirm={() =>
        rollbackPolicy &&
        onConfirm(
          rollbackPolicy.id,
          policyArchivedVersions.map((v) => v.flow_version_id),
        )
      }
    >
      {rollbackPolicy && (
        <div className="text-gray-500 font-inter-normal-13px">
          <p className="mb-2">
            Your current policy will be deactivated, and{" "}
            <strong className="text-gray-800 font-inter-semibold-13px">
              {rollbackPolicy.name}
            </strong>{" "}
            will be reactivated with its existing settings. Are you sure you
            want to rollback to{" "}
            <strong className="text-gray-800 font-inter-semibold-13px">
              {rollbackPolicy.name}
            </strong>
            ?
          </p>
          {policyArchivedVersions.length > 0 && (
            <>
              <p className="mb-2">
                <strong className="text-gray-800 font-inter-semibold-13px">
                  {rollbackPolicy.name}
                </strong>{" "}
                used{" "}
                {moreThanOneArchivedVersions
                  ? policyArchivedVersions?.length
                  : "a"}{" "}
                version
                {moreThanOneArchivedVersions && "s"} which are currently
                archived:
              </p>
              <ul className="mb-2 ml-4 list-disc">
                {policyArchivedVersions?.map((version) => (
                  <li
                    key={version.flow_version_id}
                    className="text-gray-800 font-inter-semibold-13px"
                  >
                    {versionsByKey[version.flow_version_id]?.name}
                  </li>
                ))}
              </ul>
              <p className="mb-2">
                Do you want to republish these versions which are currently
                archived?
              </p>
            </>
          )}
        </div>
      )}
    </ConfirmationModal>
  );
};

export const FlowTrafficContent: React.FC = () => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const {
    flowVersions: { canSetDefault },
  } = useCapabilities();
  const { flow } = useFlowContext();
  const flowTrafficPolicies = useTrafficPolicies(flow.id);
  const usersResult = useWorkspaceUsers(orgId, wsId);
  const { mutateAsync: activateTrafficPolicy } = useActivateTrafficPolicy();
  const { mutateAsync: publishVersion } = usePublishFlowVersion();

  const [assembleDatasetWithPolicy, setAssembleDatasetWithPolicy] =
    useState<string>();

  const [isTrafficModalOpen, setIsTrafficModalOpen] = useState(false);

  const [rollbackModalPolicy, setRollbackModalPolicy] =
    useState<TrafficPolicyInDB>();
  const [isRollbackModalOpen, setIsRollbackModalOpen] = useState(false);
  const { signed_in_user_id } = useUserData();

  const onActivateTrafficPolicy = async (
    policyId: string,
    archivedVersionIds: string[],
  ) => {
    try {
      for (const versionId of archivedVersionIds) {
        const flowVersion = flow.versions.find((v) => v.id === versionId);
        if (!flowVersion) continue;
        await publishVersion({
          publishArgs: {
            version: flowVersion,
            name: flowVersion.name,
            release_note: flowVersion.meta.release_note ?? "",
            published_by_id: signed_in_user_id ?? "",
            published_at: new Date(Date.now()).toISOString(),
          },
          enableTrafficPolicies: false,
        });
      }
      await activateTrafficPolicy({
        flowId: flow.id,
        trafficPolicyId: policyId,
      });
    } catch (err) {
      logger.error("Rollback error", err);
      toastActions.failure({
        title: "Rollback failed",
        description:
          "Rollback to a different policy failed due to an error. Please try again later.",
      });
    } finally {
      setIsRollbackModalOpen(false);
    }
  };

  const atLeastTwoVersionsPublished =
    flow.versions.filter((v) => v.status === FlowVersionStatus.PUBLISHED)
      .length >= 2;
  return (
    <>
      <SubHeader title="Routing policies" paddedParent>
        {canSetDefault && (
          <SubHeader.Button
            dataLoc="manage-traffic-button"
            disabled={!atLeastTwoVersionsPublished}
            icon={faPlus}
            tooltip={
              atLeastTwoVersionsPublished
                ? "Manage routing"
                : "Managing traffic allocation requires at least two published versions."
            }
            onClick={() => setIsTrafficModalOpen(true)}
          />
        )}
      </SubHeader>
      <div
        className={twJoin(
          "mx-auto flex w-full min-w-[1000px] flex-col rounded-lg border border-gray-200 bg-white",
          maxTableWidth,
        )}
      >
        <div
          className="ml-[90px] flex-1 border-l border-gray-100"
          data-loc="routing-policies-table"
        >
          <div className="flex h-[42px] items-center pl-[180px] font-inter-medium-12px">
            <div className="w-[294px]">Traffic allocation</div>
            <div className="w-[240px]">Activated by</div>
            <div>Activated on</div>
          </div>
          {flowTrafficPolicies.data && usersResult.data ? (
            <Timeline
              flow={flow}
              timeline={getTimelineForFlow(
                flowTrafficPolicies.data,
                usersResult.data,
              )}
              onActivateTrafficPolicy={(policy) => {
                setIsRollbackModalOpen(true);
                setRollbackModalPolicy(policy);
              }}
              onAssembleDatasetWithTrafficPolicy={setAssembleDatasetWithPolicy}
            />
          ) : (
            <TimelineSkeleton />
          )}
        </div>
        {flowTrafficPolicies.data && (
          <AssembleDatasetModal
            flow={flow}
            open={!!assembleDatasetWithPolicy}
            trafficPolicies={flowTrafficPolicies.data}
            trafficPolicyIdDefaultOverride={assembleDatasetWithPolicy}
            onClose={() => {
              setAssembleDatasetWithPolicy(undefined);
            }}
          />
        )}
        {flow.active_traffic_policy?.policy && (
          <ManageTrafficModal
            afterLeave={() => undefined}
            currentTrafficPolicy={flow.active_traffic_policy.policy}
            flowId={flow.id}
            open={isTrafficModalOpen}
            publishedVersions={flow.versions.filter(
              (version) => version.status === FlowVersionStatusT.PUBLISHED,
            )}
            onClose={() => setIsTrafficModalOpen(false)}
          />
        )}
        <PolicyReactivationModal
          afterLeave={() => setRollbackModalPolicy(undefined)}
          flow={flow}
          open={!!isRollbackModalOpen}
          rollbackPolicy={rollbackModalPolicy}
          onClose={() => setIsRollbackModalOpen(false)}
          onConfirm={onActivateTrafficPolicy}
        />
      </div>
    </>
  );
};

const Timeline: React.FC<{
  timeline: TimelinePoint[];
  onAssembleDatasetWithTrafficPolicy: (policyId: string) => void;
  flow: FlowT;
  onActivateTrafficPolicy: (policy: TrafficPolicyInDB) => void;
}> = ({
  timeline,
  onAssembleDatasetWithTrafficPolicy,
  flow,
  onActivateTrafficPolicy,
}) => {
  const {
    flowVersions: { canSetDefault },
  } = useCapabilities();
  return (
    <AnimatePresence initial={false}>
      {timeline.map((timelinePoint) => {
        const isActivePolicy =
          timelinePoint.trafficPolicy.id === flow.active_traffic_policy?.id;
        const isActiveWindow = !timelinePoint.timeWindow.ends_at;
        return (
          <TimelineRow
            key={timelinePoint.timeWindow.id}
            canSetDefault={canSetDefault}
            flow={flow}
            isActivePolicy={isActivePolicy}
            isActiveWindow={isActiveWindow}
            timelinePoint={timelinePoint}
            type="interactive"
            onActivateTrafficPolicy={onActivateTrafficPolicy}
            onAssembleDatasetWithTrafficPolicy={
              onAssembleDatasetWithTrafficPolicy
            }
          />
        );
      })}
    </AnimatePresence>
  );
};
const TimelineSkeleton: React.FC = () => {
  return (
    <AnimatePresence initial={false}>
      {range(7).map((i) => (
        <TimelineRow key={i} i={i} type="skeleton" />
      ))}
    </AnimatePresence>
  );
};

const TimelineRow: React.FC<
  | {
      type: "interactive";
      timelinePoint: TimelinePoint;
      isActiveWindow: boolean;
      flow: FlowT;
      isActivePolicy: boolean;
      canSetDefault: boolean;
      onAssembleDatasetWithTrafficPolicy: (policyId: string) => void;
      onActivateTrafficPolicy: (policy: TrafficPolicyInDB) => void;
    }
  | { type: "skeleton"; i: number }
> = (props) => {
  const movingElement = (
    <div
      className="absolute inset-0 -translate-x-full transform bg-gradient-to-r from-gray-100 via-gray-50 to-gray-100"
      style={{ animation: "shimmer 1s infinite" }}
    ></div>
  );
  return (
    <m.div
      animate={{
        opacity: 1,
        transition: {
          type: "tween",
          ease: "easeOut",
          delay: 0.25,
        },
      }}
      className="relative flex h-[68px] items-center border-t border-gray-100 pl-10 pr-6"
      data-loc={`${props.type}-timeline-row`}
      initial={{ opacity: 0 }}
      transition={{ type: "tween", ease: "easeOut" }}
      layout
    >
      <div className="absolute -top-[1px] left-[3px] flex -translate-x-full -translate-y-1/2 items-center text-gray-500 font-inter-normal-12px">
        {props.type === "interactive" ? (
          props.isActiveWindow ? (
            "Present"
          ) : (
            formatDate(props.timelinePoint.timeWindow.ends_at!, "MMM d")
          )
        ) : props.i === 0 ? (
          "Present"
        ) : (
          <div className="relative h-4 w-[54px] overflow-hidden rounded bg-gray-100">
            {movingElement}
          </div>
        )}
        <div
          className={twJoin(
            "ml-[9px] h-1.5 w-1.5 rounded-full",
            (props.type === "interactive" && props.isActiveWindow) ||
              (props.type === "skeleton" && props.i === 0)
              ? "bg-indigo-500"
              : "bg-gray-400",
          )}
        />
      </div>
      <div className="w-[140px] truncate pr-4 leading-[1] text-gray-800 font-inter-medium-12px">
        {props.type === "interactive" ? (
          props.timelinePoint.trafficPolicy.name
        ) : (
          <div className="relative h-4 w-[112px] overflow-hidden rounded bg-gray-100">
            {movingElement}
          </div>
        )}
      </div>
      <div className="w-[214px] self-stretch">
        {props.type === "interactive" ? (
          <PolicyGraph
            flow={props.flow}
            policy={props.timelinePoint.trafficPolicy}
          />
        ) : (
          <div className="flex h-full w-full items-center">
            <div className="relative h-4 w-full overflow-hidden rounded bg-gray-100">
              {movingElement}
            </div>
          </div>
        )}
      </div>
      <div className="ml-[80px] w-[240px] overflow-hidden pr-2 text-gray-500 font-inter-normal-12px">
        {props.type === "interactive" ? (
          props.timelinePoint.user && (
            <User
              avatar={props.timelinePoint.user.avatar_url}
              name={props.timelinePoint.user.username ?? ""}
            />
          )
        ) : (
          <div className="relative h-4 w-[136px] overflow-hidden rounded bg-gray-100">
            {movingElement}
          </div>
        )}
      </div>
      <div className="flex-shrink-0 flex-grow leading-[1] text-gray-500 font-inter-normal-12px">
        {props.type === "interactive" ? (
          formatDate(props.timelinePoint.timeWindow.starts_at)
        ) : (
          <div className="relative h-4 w-[136px] overflow-hidden rounded bg-gray-100">
            {movingElement}
          </div>
        )}
      </div>
      {props.type === "interactive" ? (
        <EllipsisOptionsDropdown
          buttonDataLoc="timeline-row-options"
          elements={[
            !props.isActivePolicy &&
              props.canSetDefault && {
                key: "Rollback to this policy",
                action: () => {
                  props.onActivateTrafficPolicy(
                    props.timelinePoint.trafficPolicy,
                  );
                },
              },
            {
              key: "Assemble dataset",
              action: () => {
                props.onAssembleDatasetWithTrafficPolicy(
                  props.timelinePoint.trafficPolicy.id,
                );
              },
            },
          ].filter(Boolean as unknown as ExcludesFalse)}
          ellipsisDirection="vertical"
          iconSize="xs"
          timeoutDuration={0}
        />
      ) : (
        <div className="relative h-4 w-5 overflow-hidden rounded bg-gray-100">
          {movingElement}
        </div>
      )}
    </m.div>
  );
};

const PolicyGraph: React.FC<{
  policy: TrafficPolicyInDB;
  flow: FlowT;
}> = ({ policy, flow }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  if (!policy.policy) return null;

  const policyVersions = flow.versions.filter(
    (v) =>
      v.status === FlowVersionStatusT.PUBLISHED ||
      v.status === FlowVersionStatusT.ARCHIVED,
  );
  const colors = getVersionsColors(policyVersions);

  const sortedVersions = policy.policy.flow_versions
    .filter((version) => version.percentage > 0)
    .sort((a, b) => b.percentage - a.percentage);

  return (
    <CustomPopover
      button={
        <div className="flex h-full items-center">
          <div className="flex h-3 w-full overflow-hidden rounded-sm">
            {sortedVersions.map((version) => (
              <div
                key={version.flow_version_id}
                style={{
                  width: `${version.percentage}%`,
                  backgroundColor:
                    colors[version.flow_version_id]?.dark ?? "transparent",
                }}
              ></div>
            ))}
          </div>
        </div>
      }
      isOpen={tooltipOpen}
      placement="right"
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
    >
      <div className="z-10 min-w-[160px] max-w-[450px] px-4 py-1">
        {sortedVersions.map((version) => (
          <div
            key={version.flow_version_id}
            className="flex h-11 items-center border-t border-gray-100 text-gray-500 font-inter-normal-12px first:border-0"
          >
            <div
              className="h-2 w-2 rounded-[1px]"
              style={{
                backgroundColor:
                  colors[version.flow_version_id]?.dark ?? "transparent",
              }}
            />
            <div className="ml-2 min-w-0 flex-1 truncate pr-2">
              {
                flow.versions.find((v) => v.id === version.flow_version_id)
                  ?.name
              }
            </div>
            <div className="text-gray-700 font-inter-semibold-13px">
              {version.percentage}%
            </div>
          </div>
        ))}
      </div>
    </CustomPopover>
  );
};
