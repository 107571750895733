import React from "react";
import {
  FieldErrorsImpl,
  FieldError,
  Merge,
  UseFormRegisterReturn,
} from "react-hook-form";

import { MonospacedInput } from "src/base-components/MonospacedInput";
import { Tooltip } from "src/design-system/Tooltip";
import { PropertyUIT } from "src/schema/utils";

const enumErrors: Record<string, { title: string; description: string }> = {
  required: {
    title: "No Value Specified",
    description: "Enum option cannot be empty.",
  },
  validValue: {
    title: "Invalid option",
    description:
      "Invalid enum option. Options must be numbers or “quoted” strings.",
  },
  unique: {
    title: "Duplicate Name",
    description: "Enum option must be unique",
  },
} as const;

export const SchemaEnumInput: React.FC<{
  disabled: boolean;
  errorStatus?: Merge<FieldError, FieldErrorsImpl<PropertyUIT>>;
  formProps: UseFormRegisterReturn;
}> = ({ errorStatus, disabled, formProps }) => {
  const hasError = errorStatus !== undefined;
  const enumError = errorStatus?.type
    ? enumErrors[errorStatus.type as string]
    : null;

  return (
    <Tooltip
      body={enumError?.description ?? ""}
      disabled={!hasError}
      placement="top"
      title={enumError?.title}
      asChild
    >
      <div className="grow">
        <MonospacedInput
          containerClassName="w-full"
          disabled={disabled}
          errored={hasError}
          formProps={formProps}
          inputDataLoc="schema-enum-value"
          codeColors
          errorIconOnError
        />
      </div>
    </Tooltip>
  );
};
