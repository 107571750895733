import { faRotateLeft } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { Icon } from "src/base-components/Icon";
import { BeMappedNode } from "src/constants/NodeDataTypes";
import { NodePill } from "src/copilot/NodePill";

type Props = {
  node: BeMappedNode;
  onReset: VoidFunction;
};

export const TitleRightContent: React.FC<Props> = ({ node, onReset }) => {
  return (
    <>
      <div className="ml-auto mr-1 flex max-w-56 items-center">
        {node && <NodePill node={node} />}
      </div>
      <span className="ml-2 text-gray-200">|</span>
      <Icon
        color="text-gray-500 hover:text-gray-800"
        icon={faRotateLeft}
        size="xs"
        onClick={onReset}
      />
    </>
  );
};
