import React, { useRef, useEffect, useCallback, useState } from "react";

import { FloatingEditorCodeInput } from "src/base-components/EditorTable/FloatingEditorCodeInput";
import { EditorCellProps } from "src/base-components/EditorTable/types";
import { focusContentEditable } from "src/base-components/EditorTable/utils";
import {
  ALL_OPERATOR_OPTIONS,
  OperatorDropdown,
} from "src/base-components/OperatorDropdown";
import {
  DecisionTableOperators,
  DecisionTablePredicate,
} from "src/clients/flow-api";
import { isUnaryOperator } from "src/decisionTableNode/ConditionEditor/utils";
import { RowShape } from "src/decisionTableNode/utils";

type Props = EditorCellProps<RowShape, DecisionTablePredicate | null>;

const ConditionEditor: React.FC<Props> = ({
  value,
  state,
  cellRef,
  cellId,
  onChange,
}) => {
  const safeValue: DecisionTablePredicate = value ?? {
    id: "",
    operator: DecisionTableOperators.IS_ANY,
    value: "",
  };
  const hasUnaryOperator = isUnaryOperator(safeValue.operator);
  const ref = useRef<HTMLDivElement>(null);
  const [userValue, setUserValue] = useState(safeValue.value);

  const handleStringChange = useCallback(
    (predicateValue: string) => {
      if (hasUnaryOperator || !value?.id) {
        return;
      }

      if (value.value !== predicateValue) {
        onChange({
          id: value.id,
          operator: value.operator,
          value: predicateValue,
        });
      }
    },
    [hasUnaryOperator, onChange, value?.id, value?.operator, value?.value],
  );

  const handleOperatorChange = useCallback(
    (predicateOperator: string) => {
      if (!value?.id) {
        return;
      }
      const updatedOperator: DecisionTableOperators =
        predicateOperator as unknown as DecisionTableOperators;
      const stringValue = isUnaryOperator(updatedOperator) ? "" : value.value;
      setUserValue(stringValue);
      onChange({
        id: value.id,
        value: stringValue,
        operator: updatedOperator,
      });
    },
    [onChange, value?.value, value?.id],
  );

  const handleEditorBlur = useCallback(() => {
    handleStringChange(userValue);
  }, [userValue, handleStringChange]);

  useEffect(() => {
    if (ref.current && state.isSelected) {
      ref.current.focus();
    }
  }, [state.isSelected]);

  useEffect(() => {
    if (ref.current && state.isEditing) {
      focusContentEditable(ref.current);
    }
  }, [state.isEditing]);

  return (
    <div className="flex h-full min-h-[41px] w-full items-center gap-x-1.5 py-1 pr-2">
      <FloatingEditorCodeInput
        cellId={cellId}
        cellRef={cellRef}
        disabled={hasUnaryOperator || state.readonly}
        hidden={hasUnaryOperator}
        isEditing={state.isEditing}
        isSelected={state.isSelected}
        value={userValue}
        onBlur={handleEditorBlur}
        onChange={setUserValue}
      >
        <div onClick={(e) => e.preventDefault()}>
          <OperatorDropdown
            disabled={state.readonly}
            options={ALL_OPERATOR_OPTIONS}
            value={safeValue.operator}
            onChange={handleOperatorChange}
          />
        </div>
      </FloatingEditorCodeInput>
    </div>
  );
};

const MemorizedConditionEditor = React.memo(
  ConditionEditor,
) as typeof ConditionEditor;

export { MemorizedConditionEditor as ConditionEditor };
