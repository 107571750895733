import React from "react";

export const TitleHelpContent: React.FC = () => {
  return (
    <div className="font-inter-normal-12px">
      <p>
        Copilot helps you create, edit, and debug nodes more efficiently. It can
        explain existing logic, suggest improvements, and assist with common
        coding tasks. Copilot is currently supported for:
      </p>
      <ul className="list-inside list-disc">
        <li>Code nodes</li>
        <li>Rule nodes</li>
        <li>Assignment nodes</li>
      </ul>
      <p>Usage limits apply. Human verification is required for changes.</p>
      {/* TODO: https://linear.app/taktile/issue/AUTH-6177/update-link-to-copilot-docs */}
      <a
        className="underline"
        href="https://docs.taktile.com/references/copilot"
        rel="noreferrer"
        target="_blank"
      >
        Learn more
      </a>
    </div>
  );
};
