import React from "react";
import { Toast } from "react-hot-toast";

export const TOAST_VARIANTS = {
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
  DEFAULT: "default",
} as const;

export const CONTAINER_CLASS_NAMES = "ml-12 mb-12";
export const SIDEBAR_MARGIN_CLASS = "ml-[166px]";
export const DEFAULT_TOAST_DURATION_MS = 4000;

export const TAKTILE_TEAM_NOTIFIED = "The Taktile team has been notified.";

export type NotificationVariant =
  (typeof TOAST_VARIANTS)[keyof typeof TOAST_VARIANTS];

type BaseProps = {
  variant: NotificationVariant;
  title: string;
  description?: React.ReactNode;
  toastInstance: Toast;
  dismissible?: boolean;
};

export type ActionProps = {
  onActionClick: React.MouseEventHandler;
  actionText: React.ReactNode;
};

export type NoActionProps = {
  onActionClick?: never;
  actionText?: never;
};

export type Props = (BaseProps & ActionProps) | (BaseProps & NoActionProps);

type BaseParams = {
  id?: string;
  title: string;
  description?: React.ReactNode;
  duration?: Nullable<number>;
  dismissible?: boolean;
  withSidebar?: boolean;
};

export type ToastParams =
  | (BaseParams & ActionProps)
  | (BaseParams & NoActionProps);

export type TrackNotificationArgs = {
  variant: NotificationVariant;
  title: string;
  description?: React.ReactNode;
};
