import {
  faArrowUpRightFromSquare,
  faMagnifyingGlass,
  faRefresh,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import { Root as AccordionRoot } from "@radix-ui/react-accordion";
import { useQueryClient } from "@tanstack/react-query";
import { range } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import { useSearchParams } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";

import {
  DecisionHistoryRecordV3,
  decisionsV3BaseKey,
  DevisionHistoryV3GetRequest,
  NO_DECISION_FOUND,
  SingleDecisionEnvelope,
  useHistoryDecisionsV3,
  useHistoryDecisionV3,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { GroupFilter } from "src/api/endpoints";
import { FlowT } from "src/api/flowTypes";
import { useFlow, useFlows } from "src/api/queries";
import { DecisionEnvironment, DecisionSummaryResponse } from "src/api/types";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { EditorAccordionItem } from "src/base-components/EditorAccordionItem";
import {
  EnvironmentPill,
  EnvironmentPillPlaceholder,
} from "src/base-components/EnvironmentPill";
import { FloatingWindowsProvider } from "src/base-components/FloatingWindow/FloatingWindowsProvider";
import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { Pill } from "src/base-components/Pill";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { HistoricalDecisionV3 } from "src/clients/history-v3";
import { ColumnSelector } from "src/decisionsOverview/ColumnSelector";
import { DecisionsSidepane } from "src/decisionsOverview/DecisionsSidepane";
import { LinkedFlowsPill } from "src/decisionsOverview/LinkedFlowsPill";
import { useDecisionsOverview } from "src/decisionsOverview/queries";
import {
  useDecisionEnv,
  useDetermineDecisionEnvironment,
} from "src/decisionsOverview/useDetermineDecisionEnvironment";
import { useViewOptions } from "src/decisionsOverview/useViewOptions";
import { EmptyState } from "src/design-system/EmptyState";
import { Tooltip } from "src/design-system/Tooltip";
import { SubHeader } from "src/flow/SubHeader";
import { DecisionHistoryTable } from "src/flow/decisionHistory/TableWrapper";
import { DashboardContent } from "src/layout/DashboardContent";
import { useOmniboxActions } from "src/omnibox/OmniboxStore";
import { URLKeys } from "src/router/SearchParams";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import {
  DashboardPageParamsT,
  getBaseUrl,
  getFlowVersionsUrl,
  getUrlToWsDashboard,
} from "src/router/urls";
import { pluralize } from "src/utils/stringUtils";
import { StrictDateRange, useTimeWindow } from "src/utils/timeWindow";
import { useParamsDecode } from "src/utils/useParamsDecode";

/*
The width of placeholder div is computed as:
500px (w of sidepane) - 32px (padding of canvas) - 28px (margin of table) + 24px (distance between table and side pane)
*/
const sidePaneWidthClass = "w-[500px]" as const;
const sidePanePlaceholderClass = "w-[464px]" as const;

const useQueryParams = () => {
  const [searchParams] = useSearchParams();

  return {
    entityId: searchParams.get(URLKeys.EntityId),
    decisionId: searchParams.get(URLKeys.DecisionId),
  };
};

const RightContent: React.FC<{
  count: number | undefined;
  flow: FlowT;
}> = ({ count, flow }) => {
  const { value, isSaving, canSelect, canDeselect, onChange, onReset, onSave } =
    useViewOptions({
      flow,
    });
  return (
    <div className="flex h-13 items-center gap-x-2 border-b border-b-gray-200 px-6">
      <Pill dataLoc={`decisions-count-${count}`} size="sm" variant="gray">
        <Pill.Text>
          {count !== undefined ? (
            pluralize(count, "Decision")
          ) : (
            <>&nbsp;&nbsp; Decisions</>
          )}
        </Pill.Text>
      </Pill>
      <ColumnSelector
        canDeselect={canDeselect}
        canSelect={canSelect}
        flow={flow}
        isSaving={isSaving}
        value={value}
        onChange={onChange}
        onReset={onReset}
        onSave={onSave}
      />
    </div>
  );
};

type ItemProps = {
  flow: FlowT;
  title: string;
  count: number | undefined;
  displayedDecision: HistoricalDecisionV3 | null;
  singleDecision: HistoricalDecisionV3 | null;
  setDisplayedDecision: (decision: HistoricalDecisionV3 | null) => void;
  topLevelFilters: TopLevelFilters;
  filter: DevisionHistoryV3GetRequest | undefined;
  setFilter: (tableFilter: DevisionHistoryV3GetRequest) => void;
};

const buildQueryFilter = (
  filter: TopLevelFilters,
  fields: DevisionHistoryV3GetRequest | undefined,
): DevisionHistoryV3GetRequest => {
  return {
    startStartTime: filter.timeWindow?.from.toISOString(),
    endStartTime: filter.timeWindow?.to.toISOString(),
    environments: filter.decisionEnv,
    ...(filter.entityId && { entityIds: filter.entityId }),
    ...(filter.decisionId && { parentDecisionIds: filter.decisionId }),
    ...fields,
  };
};

const FlowAccordionItem: React.FC<ItemProps> = ({
  flow: flowShallow,
  title,
  count,
  topLevelFilters,
  filter,
  singleDecision,
  displayedDecision,
  setDisplayedDecision,
  setFilter,
}) => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const flow = useFlow(flowShallow?.id);
  const { columnVisibility, fieldsMask } = useViewOptions({ flow: flow.data });
  const { workspace } = useWorkspaceContext();

  const decisions = useHistoryDecisionsV3({
    baseUrl: workspace?.base_url ?? "",
    flowIds: flowShallow?.id!,
    ...buildQueryFilter(topLevelFilters, filter),
    enabled: flowShallow?.slug !== undefined,
    $fields: fieldsMask,
  });

  if (!flow.data) return <AccordionSkeletonItem key={flowShallow?.id} />;

  const linkToFlow =
    getBaseUrl() + getFlowVersionsUrl(orgId, wsId, flow.data.id);

  return (
    <EditorAccordionItem
      className="decideScrollbar ml-11 mr-4 overflow-hidden overflow-x-auto pl-0 pr-0"
      contentWrapperClassName="border-b-gray-200 border-b"
      dataLoc={`accordion-item-${flow.data.name}`}
      headerClassName="border-b-gray-200 border-b flex-grow"
      headerContent={<RightContent count={count} flow={flow.data} />}
      title={
        <div
          className="flex items-center gap-x-1.5"
          data-loc="accordion-item-title"
        >
          {title}
          <Tooltip placement="right" title="Open in new tab" asChild>
            <div>
              <Icon
                color="text-gray-800"
                icon={faArrowUpRightFromSquare}
                size="2xs"
                onClick={(event) => {
                  event.stopPropagation();
                  window.open(linkToFlow, "_blank");
                }}
              />
            </div>
          </Tooltip>
          <LinkedFlowsPill
            childFlows={flowShallow.child_flows}
            parentFlows={flowShallow.parent_flows}
          />
        </div>
      }
      value={flow.data.id}
      headerAsChild
    >
      <div className="relative mx-auto flex max-h-[640px]">
        <FloatingWindowsProvider>
          <DecisionHistoryTable
            canFetchNextPage={
              !singleDecision &&
              Boolean(!decisions.isFetching && decisions.hasNextPage)
            }
            changeFilterFields={setFilter}
            columnVisibility={columnVisibility}
            decisionHistory={
              singleDecision
                ? [singleDecision]
                : decisions.data?.pages.flatMap((page) => page.decisions)
            }
            env={topLevelFilters.decisionEnv}
            fetchNextPage={decisions.fetchNextPage}
            filterFields={filter || {}}
            flow={flow.data}
            isFetching={!singleDecision && decisions.isLoading}
            selectedDecisionId={
              displayedDecision ? displayedDecision.id : undefined
            }
            includeInputOutput
            includeOutcomes
            onDecisionClick={{
              predicate: () => true,
              effect: setDisplayedDecision,
            }}
          />
        </FloatingWindowsProvider>
      </div>
    </EditorAccordionItem>
  );
};
type FlowGroup = {
  flow: FlowT;
};

type FlowGroupForCount = {
  flowId: string;
  count: number;
};

const isFlowGroup = (arg: Partial<FlowGroup>): arg is FlowGroup => {
  return arg.flow !== undefined;
};

const isFlowGroupForCount = (
  arg: Partial<FlowGroupForCount>,
): arg is FlowGroupForCount => {
  return arg.flowId !== undefined && arg.count !== undefined;
};

type Props = {
  singleDecision: HistoricalDecisionV3 | null;
  flowGroups: FlowGroup[];
  flowGroupsForCount: FlowGroupForCount[] | undefined;
  displayedDecision: HistoricalDecisionV3 | null;
  setDisplayedDecision: (decision: HistoricalDecisionV3 | null) => void;
  topLevelFilters: TopLevelFilters;
  groupFilter: GroupFilter;
  setGroupFilter: React.Dispatch<React.SetStateAction<GroupFilter>>;
};

const FlowAccordion: React.FC<Props> = ({
  singleDecision,
  flowGroups,
  flowGroupsForCount,
  topLevelFilters,
  displayedDecision,
  setDisplayedDecision,
  groupFilter,
  setGroupFilter,
}) => {
  const { decisionId, entityId } = useQueryParams();
  const [accordionValue, setAccordionValue] = useSessionStorage<string[]>(
    `decisions-overview-accordion-search-id-${decisionId ?? entityId}`,
    [flowGroups.at(0)?.flow.id ?? ""],
  );

  useEffect(() => {
    setDisplayedDecision(null);
  }, [topLevelFilters, groupFilter, setDisplayedDecision]);

  const handleAccordionChange = (value: string[]) => {
    if (
      displayedDecision?.flow?.id &&
      !value.includes(displayedDecision.flow.id)
    ) {
      setDisplayedDecision(null);
    }
    setAccordionValue(value);
  };

  return (
    <AccordionRoot
      type="multiple"
      value={accordionValue}
      onValueChange={handleAccordionChange}
    >
      {flowGroups.map((group) => (
        <FlowAccordionItem
          key={group.flow.id}
          count={
            flowGroupsForCount
              ? (flowGroupsForCount.find(
                  (flowGroup) => flowGroup.flowId === group.flow.id,
                )?.count ?? 0)
              : undefined
          }
          displayedDecision={displayedDecision}
          filter={groupFilter[group.flow.id]}
          flow={group.flow}
          setDisplayedDecision={setDisplayedDecision}
          setFilter={(tableFilter: DevisionHistoryV3GetRequest) => {
            setGroupFilter((prevGroupFilter) => ({
              ...prevGroupFilter,
              [group.flow.id]: tableFilter,
            }));
          }}
          singleDecision={
            singleDecision?.flow.id === group.flow.id ? singleDecision : null
          }
          title={group.flow.name}
          topLevelFilters={topLevelFilters}
        />
      ))}
    </AccordionRoot>
  );
};

type TopLevelFilters = {
  decisionEnv: DecisionEnvironment;
  entityId: string | null;
  decisionId: string | null;
  timeWindow: StrictDateRange | undefined;
};

const AccordionSkeletonItem: React.FC = () => {
  return (
    <EditorAccordionItem
      className="border-b border-b-gray-200"
      headerClassName="border-b-gray-200 border-b flex-grow"
      title={<SkeletonPlaceholder width="w-96" />}
      value="dummy"
    >
      <></>
    </EditorAccordionItem>
  );
};
const AccordionSkeleton: React.FC = () => {
  return (
    <AccordionRoot defaultValue={[]} type="multiple">
      {range(4).map((index) => (
        <AccordionSkeletonItem key={index} />
      ))}
    </AccordionRoot>
  );
};

export const DecisionsOverview: React.FC = () => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const { showOmnibox } = useOmniboxActions();
  const { workspace } = useWorkspaceContext();
  const { entityId, decisionId } = useQueryParams();

  const {
    decisionEnvParam,
    decisionEnvForQueries,
    confirmedNoLiveDecisions,
    setDecisionEnvParam,
    setConfirmedNoLiveDecisions,
  } = useDecisionEnv();

  const [displayedDecision, setDisplayedDecision] =
    useState<HistoricalDecisionV3 | null>(null);

  const {
    dateRangePickerValue,
    onDateRangePickerChange,
    onDateRangePickerReset,
    timeWindow,
  } = useTimeWindow<StrictDateRange | undefined>(
    undefined,
    "decisions-overview-time-window",
  );

  const topLevelFilters: TopLevelFilters = useMemo(() => {
    return {
      decisionEnv: decisionEnvForQueries,
      entityId,
      decisionId,
      timeWindow,
    };
  }, [decisionEnvForQueries, entityId, decisionId, timeWindow]);

  const [groupFilter, setGroupFilter] = useSessionStorage<GroupFilter>(
    "decisions-overview-group-filter",
    {},
  );
  const queryClient = useQueryClient();

  const decisionsOverviewQuery = useDecisionsOverview(workspace.base_url, {
    environments: decisionEnvForQueries,
    groupby: "flow.id",
    aggregate: "count",
    entity_id: entityId ?? undefined,
    parent_decision_id: decisionId ?? undefined,
    timeWindow: timeWindow,
  });

  const decisionsOverviewQueryForCount = useDecisionsOverview(
    workspace.base_url,
    {
      environments: decisionEnvForQueries,
      groupby: "flow.id",
      aggregate: "count",
      entity_id: entityId ?? undefined,
      parent_decision_id: decisionId ?? undefined,
      timeWindow: timeWindow,
      groupfilter: groupFilter,
    },
  );

  const singleDecisionQuery = useHistoryDecisionV3({
    baseUrl: workspace.base_url ?? "",
    decisionId: decisionId ?? "",
    environmentToMatch: decisionEnvForQueries,
    // We give an initial value for the deactivated state so that the loading view treats this as loaded.
    initialData: decisionId
      ? undefined
      : { isQueryError: true, reason: NO_DECISION_FOUND },
  });

  const getDatePickerValue = (): DateRange | undefined => {
    if (!decisionId) {
      return dateRangePickerValue;
    }

    if (
      !singleDecisionQuery.data?.isQueryError &&
      singleDecisionQuery.data?.decision?.start_time
    ) {
      return {
        from: new Date(singleDecisionQuery?.data?.decision?.start_time),
      };
    }

    return undefined;
  };

  const flowsQuery = useFlows({ workspaceId: wsId });

  const flowGroupsForCount: FlowGroupForCount[] | undefined = useMemo(() => {
    const groups = decisionsOverviewQueryForCount.data?.groups
      .map((group) => ({
        flowId: group.flow?.id,
        count: group.count,
      }))
      .filter(isFlowGroupForCount);
    const singleDecision = singleDecisionQuery.data;
    if (
      singleDecision &&
      !singleDecision.isQueryError &&
      singleDecision.decision.flow.id
    ) {
      const existingGroup = groups?.find(
        (group) => group.flowId === singleDecision.decision.flow.id,
      );
      if (existingGroup) {
        existingGroup.count += 1;
      } else {
        groups?.push({
          flowId: singleDecision.decision.flow.id,
          count: 1,
        });
      }
    }
    return groups;
  }, [decisionsOverviewQueryForCount.data, singleDecisionQuery.data]);

  const handleRefresh = () => {
    decisionsOverviewQuery.refetch();
    decisionsOverviewQueryForCount.refetch();
    queryClient.refetchQueries(decisionsV3BaseKey);
  };

  useDetermineDecisionEnvironment({
    decisionEnvParam,
    entityId,
    decisionsOverview: decisionsOverviewQuery.data,
    setDecisionEnvParam,
    decisionId,
    singleDecision: singleDecisionQuery.data,
    confirmedNoLiveDecisions,
    setConfirmedNoLiveDecisions,
  });

  return (
    <DashboardContent
      Header={
        <SubHeader
          backTo={getUrlToWsDashboard({ orgId, wsId })}
          title={
            <>
              <span className="mr-2 whitespace-nowrap align-middle">
                {decisionId
                  ? "Decision ID"
                  : entityId
                    ? "Decisions for Entity ID"
                    : "Decisions"}
              </span>
              <div className="mb-0.5 inline-block h-fit max-w-60 items-center overflow-hidden align-middle">
                <Pill
                  dataLoc="decisions-overview-id-pill"
                  size="sm"
                  variant="gray"
                  maxWidth
                >
                  <Pill.Text fontType="code">
                    {decisionId ?? entityId ?? "-"}
                  </Pill.Text>
                </Pill>
              </div>
            </>
          }
          titleAction={
            <>
              <CopyTextIcon
                tooltip="Copy ID"
                value={decisionId ?? entityId ?? ""}
              />
              {decisionEnvParam === null ? (
                <EnvironmentPillPlaceholder />
              ) : (
                <EnvironmentPill
                  value={decisionEnvParam}
                  onChange={setDecisionEnvParam}
                />
              )}
            </>
          }
        >
          <SubHeader.Button
            dataLoc="header-search"
            icon={faMagnifyingGlass}
            tooltip="Search"
            onClick={showOmnibox}
          />
          <SubHeader.DatePicker
            disabled={Boolean(decisionId)}
            placeholder="All time"
            value={getDatePickerValue()}
            resetable
            onChange={onDateRangePickerChange}
            onReset={onDateRangePickerReset}
          />
          <SubHeader.Button
            dataLoc="refresh-decisions"
            disabled={decisionsOverviewQuery.isRefetching}
            icon={faRefresh}
            spin={decisionsOverviewQuery.isRefetching}
            tooltip="Refresh"
            onClick={handleRefresh}
          />
        </SubHeader>
      }
    >
      <div className="flex h-full w-full">
        <div className="mx-7 mb-10 h-fit min-w-[600px] grow overflow-hidden rounded-2xl border border-gray-200 bg-white">
          <LoadingView
            queryResult={[
              decisionsOverviewQuery,
              flowsQuery,
              singleDecisionQuery,
            ]}
            renderUpdated={([decisionSummary, flows, singleDecision]: [
              DecisionSummaryResponse,
              FlowT[],
              SingleDecisionEnvelope<DecisionHistoryRecordV3>,
            ]) => {
              const flowGroups: FlowGroup[] =
                decisionSummary.groups
                  .map((group) => ({
                    flow: flows.find((flow) => flow.id === group.flow?.id),
                  }))
                  .filter(isFlowGroup) ?? [];
              if (
                !singleDecision.isQueryError &&
                !flowGroups.find(
                  (group) => group.flow?.id === singleDecision.decision.flow.id,
                )
              ) {
                const singleDecisionFlow = flows.find(
                  (flow) => flow.id === singleDecision.decision.flow.id,
                );
                if (singleDecisionFlow) {
                  flowGroups.unshift({
                    flow: singleDecisionFlow,
                  });
                }
              }
              if (flowGroups.length === 0) {
                return (
                  <div className="h-[calc(100vh-120px)]">
                    <EmptyState
                      description="Try adjusting the search or filter to find what you are looking for"
                      headline="No results found"
                      icon={faSearch}
                    />
                  </div>
                );
              }
              return (
                <FlowAccordion
                  displayedDecision={displayedDecision}
                  flowGroups={flowGroups.sort((a, b) =>
                    a.flow?.name.localeCompare(b.flow?.name),
                  )}
                  flowGroupsForCount={flowGroupsForCount}
                  groupFilter={groupFilter}
                  setDisplayedDecision={setDisplayedDecision}
                  setGroupFilter={setGroupFilter}
                  singleDecision={
                    singleDecision.isQueryError ? null : singleDecision.decision
                  }
                  topLevelFilters={topLevelFilters}
                />
              );
            }}
            renderUpdating={() => <AccordionSkeleton />}
          />
        </div>
        {/*Make some space for the sidepane to take, but allow it to overlap the
        table on small screens. */}
        {!!displayedDecision && <div className={sidePanePlaceholderClass} />}
        <DecisionsSidepane
          baseUrl={workspace.base_url!}
          decision={displayedDecision}
          isOpen={!!displayedDecision}
          widthClass={sidePaneWidthClass}
          onClose={() => setDisplayedDecision(null)}
        />
      </div>
      <div className="h-14" />
    </DashboardContent>
  );
};
