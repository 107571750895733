import { useDroppable } from "@dnd-kit/core";
import {
  faBox,
  faFolder,
  faLink,
  faPen,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import { twJoin } from "tailwind-merge";

import { NO_FOLDER_FILTER } from "src/api";
import { Icon } from "src/base-components/Icon";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import {
  MENU_DIVIDER,
  OptionsDropdownElement,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { FlowFolderListItem } from "src/clients/flow-api";
import { DND_NO_FOLDER_ID } from "src/flowsOverview/v2/FlowFolderList";
import { useCapabilities } from "src/hooks/useCapabilities";

type BaseProps = {
  onRequestSelect: (folderId: string) => void;
  onRequestEdit?: (folder: FlowFolderListItem) => void;
  onRequestDelete: (folder: FlowFolderListItem) => void;
  onCopyLink: (folder: FlowFolderListItem | undefined) => void;
  selected: boolean;
};
export type Props = BaseProps &
  (
    | {
        folder: FlowFolderListItem;
        count?: never;
      }
    | {
        folder: undefined;
        count: number;
      }
  );

export const Folder: React.FC<Props> = ({
  folder,
  selected,
  onRequestSelect,
  onRequestEdit,
  onRequestDelete,
  onCopyLink,
  count,
}) => {
  const { flowFolders } = useCapabilities();
  const { setNodeRef, isOver } = useDroppable({
    id: folder?.id || DND_NO_FOLDER_ID,
  });

  const dropdownItems: OptionsDropdownElement[] = [];
  if (folder !== undefined && flowFolders.canEdit) {
    dropdownItems.push({
      key: "Rename",
      icon: faPen,
      action: () => {
        onRequestEdit?.(folder);
      },
    });
  }

  dropdownItems.push({
    key: "Copy link",
    icon: faLink,
    action: () => {
      onCopyLink(folder);
    },
  });

  if (folder !== undefined && flowFolders.canDelete) {
    dropdownItems.push(MENU_DIVIDER);
    dropdownItems.push({
      key: "Delete",
      icon: faTrashCan,
      action: () => {
        onRequestDelete(folder);
      },
    });
  }

  return (
    <li
      ref={setNodeRef}
      className={twJoin(
        "group/folder flex w-full min-w-0 items-center rounded border px-[7px] py-1.5",
        selected && "bg-gray-200",
        isOver ? "border-indigo-500" : "border-transparent",
      )}
      data-loc={
        (folder ? `folder-${folder?.name}` : "no-folder") +
        (selected ? "-selected" : "")
      }
      role="button"
      tabIndex={0}
      onClick={() => onRequestSelect(folder?.id ?? NO_FOLDER_FILTER)}
    >
      <Icon
        color="text-gray-500"
        icon={folder === undefined ? faBox : faFolder}
      />
      <span
        className="truncate text-left text-gray-800 font-inter-medium-13px"
        data-loc="folder-name"
      >
        {folder === undefined ? "No folder" : folder.name}
        {
          /*Non-breaking space that does not get collapsed by the span*/ "\u00A0"
        }
      </span>
      <span
        className="grow text-left text-gray-800 font-inter-medium-13px"
        data-loc={
          folder === undefined ? "no-folder-flow-count" : "folder-flow-count"
        }
      >
        ({folder === undefined ? count : folder.flow_count})
      </span>
      <div className="flex items-center">
        <EllipsisOptionsDropdown
          buttonClassName="ml-2 text-gray-800 hidden group-hover/folder:block"
          buttonDataLoc="folder-options-dropdown-button"
          elements={dropdownItems}
          iconSize="sm"
          placement="bottom-start"
          renderMenuInPlace
        />
      </div>
    </li>
  );
};
