import { v4 as uuidV4 } from "uuid";

import "src/integrationNode/integrationResources/DefaultResourceConfig";
import { InputMappingGroupT, InputMappingT } from "src/integrationNode/types";

export const SOCURE_FIRST_NAME_HINT = `Omit any prefixes (e.g., Mr., Ms.). This field is required if "full name" is not provided and must not exceed 240 characters.`;
export const SOCURE_LAST_NAME_HINT = `Omit any suffixes (Sr., Jr., III, etc.). This field is required if "full name" is not provided and must not exceed 240 characters.`;
export const SOCURE_DATE_OF_BIRTH_HINT = `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20".`;
export const SOCURE_NATIONAL_ID_HINT = `The consumer's 9-digit Social Security Number (SSN) or Individual Tax Identification Number (ITIN). The last four digits of the SSN are also accepted. Hyphens are optional. Must not exceed 11 characters.`;
export const SOCURE_PHONE_NUMBER_HINT = `The consumer's phone number in E.164 format - usually written as follows: [+][country code][number including area code]. An example of a U.S. number in the E.164 format is +16194442323. A mobile phone number is preferred. Hyphens are optional and the number must not exceed 16 characters.`;
export const SOCURE_EMAIL_HINT = `Must conform to RFC 5322 format (e.g., "john.doe@example.com") and contain a valid top-level domain.`;
export const SOCURE_USER_CONSENT_HINT = `This Boolean value (True/False) indicates if you have obtained consent from the end-user to have Socure process their data. This is relevant if Socure implicitly calls eCBSV (which happens for around 8-14% of all requests). If the value is False, Socure will return an error response. If the value is None, the request to eCBSV will be skipped. See our documentation for more information on implicit calls to eCBSV.`;
export const SOCURE_USER_CONSENT_TIMESTAMP_HINT = `The timestamp of when the end-user consent was collected in ISO 8601 format (i.e., "YYYY-MM-DDTHH:MM:SSZ"). The date cannot be in the future and cannot be more than 90 days old from the current time. This field is only relevant if Socure implicitly calls eCBSV (see our documentation for more information).`;
export const SOCURE_IP_ADDRESS_HINT = `The consumer's IP address in IPv4 decimal or IPv6 hexadecimal format`;
export const SOCURE_DEVICE_SESSION_ID_HINT = `The session ID retrieved by Socure SDK from the consumer's browser or application`;
export const SOCURE_CUSTOMER_USER_ID_HINT = `A unique ID to indentify sessions for the same user`;
export const SOCURE_DRIVERS_LICENSE_HINT = `The driver's license identification number`;
export const SOCURE_DRIVERS_LICENSE_STATE_HINT = `The state where the driver's license was issued`;
export const SOCURE_DOCUMENT_UUID_HINT = `The identifier of the uploaded images. This field is returned after the consumer successfully uploads their ID and selfie images using the Capture App.`;

export const getSocureAddressFields = (
  allOptional: boolean = false,
): { [key: string]: InputMappingT } => ({
  // `allOptional` is used to make all field optional
  // for DocV bundle. When not set, all fields except
  // for `second_address_line` and `region` are required.
  first_address_line: {
    id: uuidV4(),
    type: "text",
    displayName: "First address line",
    assignedTo: "",
    rules: { required: !allOptional },
    hint: `The first line of the consumer's address in valid postal-address format (e.g., 123 N. Main St)`,
  },
  second_address_line: {
    id: uuidV4(),
    type: "text",
    displayName: "Second address line",
    assignedTo: "",
    hint: `The second line of the consumer's address. Use for secondary designators (apartment, suite, unit, building, etc.).`,
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    hint: `The consumer's ZIP code in valid postal address format. At most 9 characters. Hyphens are optional. Mandatory if Region is not provided.`,
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: { required: !allOptional },
    hint: `City of residence in valid postal-address standard. For international requests, city and country are required. Must not exceed 255 characters.`,
  },
  region: {
    id: uuidV4(),
    type: "text",
    displayName: "Region",
    assignedTo: "",
    hint: `The state, province, or region where the consumer resides, specified in ISO 3166-2 format (e.g., "CA" for California). Must contain 2 characters. Mandatory if Postal Code is not provided.`,
  },
  country: {
    id: uuidV4(),
    type: "text",
    displayName: "Country",
    assignedTo: "",
    rules: { required: !allOptional },
    hint: `The country where the consumer resides, specified in ISO 3166-1 alpha-2 format (e.g., "US" for the United States). Must contain 2 characters.`,
  },
});

export const getDefaultSocureAddress = (): InputMappingGroupT => ({
  displayName: "Address",
  getDefaultElements: getSocureAddressFields,
  elements: getSocureAddressFields(),
  rules: { required: true },
});
