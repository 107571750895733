import {
  FlowT,
  FlowVersionFlowChild,
  FlowVersionStatusT,
} from "src/api/flowTypes";
import { ProbabilisticPolicyTypeEnum } from "src/clients/flow-api";
import { AddVersionModal } from "src/flow/modals/AddVersionModal";
import { ArchiveVersionModal } from "src/flow/modals/ArchiveVersionModal";
import { DeleteVersionModal } from "src/flow/modals/DeleteVersionModal";
import { DuplicateVersionToFlowModal } from "src/flow/modals/DuplicateVersionToFlowModal";
import { EditFlowVersionModal } from "src/flow/modals/EditVersionModal";
import { PublishVersionModal } from "src/flow/modals/PublishVersionModal";
import { RelatedFlowsModal } from "src/flow/modals/RelatedFlowsModal";
import { RepublishVersionModal } from "src/flow/modals/RepublishVersionModal";
import { SetSandboxDefaultVersionModal } from "src/flow/modals/SetDefaultVersionModal";
import { VersionManageModals } from "src/flow/types";
import { FlowVersionReviewModal } from "src/flowReview/FlowVersionReviewModal";
import { ManageTrafficModal } from "src/manageTraffic/Modal";
import { FeVersionStatusFilterValue } from "src/utils/flowVersion";

type FlowVersionControlModalsProps = {
  flow: FlowT;
  version?: FlowVersionFlowChild;
  modal?: VersionManageModals;
  onClose: () => void;
  afterLeave: () => void;
  openManageTrafficModal?: () => void;
  setFilter?: (filter: FeVersionStatusFilterValue | null) => void;
};

export const FlowVersionControlModals: React.FC<
  FlowVersionControlModalsProps
> = ({
  flow,
  version,
  modal,
  onClose,
  afterLeave,
  openManageTrafficModal,
  setFilter,
}) => (
  <>
    <DeleteVersionModal
      afterLeave={afterLeave}
      isOpen={modal === "delete_version"}
      version={version}
      onClose={onClose}
    />
    <EditFlowVersionModal
      afterLeave={afterLeave}
      isOpen={modal === "edit_version"}
      version={version}
      onClose={onClose}
    />
    <AddVersionModal
      afterLeave={afterLeave}
      flow={flow}
      isOpen={modal === "add_version"}
      versionToDuplicate={version?.id}
      onClose={onClose}
    />
    <SetSandboxDefaultVersionModal
      afterLeave={afterLeave}
      flowId={flow.id}
      isOpen={modal === "default_sandbox_version"}
      version={version}
      onClose={onClose}
    />
    <PublishVersionModal
      afterLeave={afterLeave}
      flow={flow}
      flowVersion={version}
      open={modal === "publish_version"}
      onClose={onClose}
    />
    <ManageTrafficModal
      afterLeave={afterLeave}
      currentTrafficPolicy={
        flow.active_traffic_policy?.policy ?? {
          flow_versions: flow.default_version
            ? [{ percentage: 100, flow_version_id: flow.default_version }]
            : [],
          type: ProbabilisticPolicyTypeEnum.PROBABILISTIC,
        }
      }
      flowId={flow.id}
      open={modal === "manage_traffic"}
      publishedVersions={flow.versions.filter(
        (version) => version.status === FlowVersionStatusT.PUBLISHED,
      )}
      selectedVersion={version}
      onClose={onClose}
    />
    {openManageTrafficModal && (
      <ArchiveVersionModal
        afterLeave={afterLeave}
        flow={flow}
        flowVersion={version}
        open={modal === "archive_version"}
        onClose={onClose}
        onOpenManageTrafficModal={openManageTrafficModal}
      />
    )}
    <RelatedFlowsModal
      afterLeave={afterLeave}
      open={modal === "archive_version_live_parents"}
      relatedFlows={version?.parent_flows}
      subtitle="You cannot archive a version which is called by a live version of another Decision Flow. See the list of Decision Flow versions calling this version below."
      title={`Version ${version?.name} is called by another Decision Flow`}
      onClose={onClose}
    />
    <RepublishVersionModal
      afterLeave={afterLeave}
      flow={flow}
      flowVersion={version}
      open={modal === "republish_version"}
      onClose={onClose}
      onRepublish={() => setFilter?.(null)}
    />
    <RelatedFlowsModal
      afterLeave={afterLeave}
      open={modal === "unpublished_child_flows"}
      relatedFlows={version?.child_flows}
      subtitle="All Decision Flow versions called by this version must also be published before publishing this version."
      title="Publish version"
      onClose={onClose}
    />
    <RelatedFlowsModal
      afterLeave={afterLeave}
      open={modal === "republish_unpublished_child_flows"}
      relatedFlows={version?.child_flows}
      subtitle="You cannot republish a version that calls archived versions of another Decision Flow. To complete this action, republish the unarchived versions listed below first."
      title={`Version ${version?.name} is calling archived versions of another Decision Flow`}
      onClose={onClose}
    />
    {version && (
      <FlowVersionReviewModal
        flow={flow}
        flowVersion={version}
        isOpen={modal === "review_version"}
        onClose={onClose}
      />
    )}
    {version && (
      <DuplicateVersionToFlowModal
        afterLeave={afterLeave}
        open={modal === "duplicate_version_to_flow"}
        version={version}
        onClose={onClose}
      />
    )}
  </>
);
