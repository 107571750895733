import { Controller, useFormContext } from "react-hook-form";

import { SSHTunnelPrivateKeyPlaceholder } from "../shared/SSHTunnelConfig";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { Switch } from "src/base-components/Switch";
import { ConfigFieldsetCard } from "src/connections/config/FieldsetCard";
import { SSHSecret } from "src/connections/config/database/sshTunnel/SSHSecret";
import {
  DBConnectionWithSSHTunnelInputs,
  Environment,
} from "src/connections/types";

type PropsT = {
  environment: Environment;
};

export const SSHTunnelFieldSet: React.FC<PropsT> = ({ environment }) => {
  const envPrefix = `${environment}Config` as const;
  const {
    watch,
    register,
    control,
    formState: { errors },
  } = useFormContext<DBConnectionWithSSHTunnelInputs>();
  const hasSSHTunnelEnabled = watch(`${envPrefix}.hasSSHTunnelEnabled`);

  return (
    <div className="mb-6 last:mb-0">
      <FormItem
        className="flex flex-row items-center justify-between"
        description="Enable an SSH tunnel for additional security"
        gap="xxs"
        helpTooltip="Enabling the SSH tunnel will create a secure connection to the database by routing the traffic through an SSH server. This is useful when the database is not directly accessible from the internet."
        label="Enable SSH tunnel"
      >
        <Controller
          control={control}
          name={`${envPrefix}.hasSSHTunnelEnabled` as const}
          render={(props) => (
            <Switch
              enabled={props.field.value}
              onChange={() => {
                props.field.onChange(!props.field.value);
              }}
            />
          )}
        />
      </FormItem>
      {hasSSHTunnelEnabled && (
        <ConfigFieldsetCard>
          <div>
            <FormItem
              description="The hostname or IP address of the SSH server that will be used to create the tunnel."
              label="SSH Host"
              isRequired
            >
              <ErrorHint>
                {errors[envPrefix]?.SSHTunnel?.host?.message}
              </ErrorHint>
              <Input
                errored={Boolean(errors[envPrefix]?.SSHTunnel?.host)}
                {...register(`${envPrefix}.SSHTunnel.host`, {
                  required: hasSSHTunnelEnabled
                    ? "SSH Host is required"
                    : false,
                })}
                placeholder="e.g., ssh.example.com"
                fullWidth
              />
            </FormItem>
            <FormItem
              description="The port number of the SSH server that will be used to create the tunnel."
              label="SSH Port"
              isRequired
            >
              <ErrorHint>
                {errors[envPrefix]?.SSHTunnel?.port?.message}
              </ErrorHint>
              <Input
                errored={Boolean(errors[envPrefix]?.SSHTunnel?.port)}
                {...register(`${envPrefix}.SSHTunnel.port`, {
                  required: hasSSHTunnelEnabled
                    ? "SSH Port is required"
                    : false,
                })}
                placeholder="e.g., 22"
                fullWidth
              />
            </FormItem>
            <FormItem
              description="The username to authenticate with the SSH server."
              label="SSH User"
              isRequired
            >
              <ErrorHint>
                {errors[envPrefix]?.SSHTunnel?.user?.message}
              </ErrorHint>
              <Input
                errored={Boolean(errors[envPrefix]?.SSHTunnel?.user)}
                {...register(`${envPrefix}.SSHTunnel.user`, {
                  required: hasSSHTunnelEnabled
                    ? "SSH User is required"
                    : false,
                })}
                placeholder="e.g., ec2-user"
                fullWidth
              />
            </FormItem>
            <SSHSecret
              displayName="SSH Private Key"
              fieldError={errors[envPrefix]?.SSHTunnel?.privateKey?.value}
              helpTitle="The private key to authenticate with the SSH server. This should be a valid SSH private key in PEM format."
              isRequired={hasSSHTunnelEnabled}
              name={`${envPrefix}.SSHTunnel.privateKey` as const}
              placeholder={SSHTunnelPrivateKeyPlaceholder}
            />
          </div>
        </ConfigFieldsetCard>
      )}
    </div>
  );
};
