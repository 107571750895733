import {
  faArrowUpRightAndArrowDownLeftFromCenter,
  faArrowDownToLine,
  faEdit,
  faSpinner,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import { m } from "framer-motion";
import React from "react";

import { Dataset, DatasetFileFormat } from "src/api/types";
import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { useCreateDownloadDatasetJob } from "src/datasets/api/queries";
import { useThereIsDownloadPending } from "src/datasets/hooks/useThereIsDownloadPending";
import { separateFilenameExtension } from "src/datasets/utils";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { ExcludesFalse } from "src/flow/types";
import { useCapabilities } from "src/hooks/useCapabilities";
import { useSetEditDatasetId } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { stopPropagationWrapper } from "src/utils/functions";
import { logger } from "src/utils/logger";

type Props = {
  dataset: Dataset;
  onDelete: () => void;
  animateOnMount?: boolean;
  variant?: "item" | "header";
};

export const ItemActions: React.FC<Props> = ({
  dataset,
  onDelete,
  animateOnMount = false,
  variant = "item",
}) => {
  const { workspace, flow } = useAuthoringContext();
  const { name: fileName } = separateFilenameExtension(dataset.name);
  const { datasets } = useCapabilities();
  const { mutateAsync: downloadFile, isLoading: isCreatingDownloadJob } =
    useCreateDownloadDatasetJob(workspace.base_url!);

  const thereIsADownloadPending = useThereIsDownloadPending();

  const showDownloadLoading = thereIsADownloadPending || isCreatingDownloadJob;
  const setEditDatasetId = useSetEditDatasetId();

  const handleDownload = async (format: DatasetFileFormat) => {
    try {
      const job = await downloadFile({
        flow_id: flow.id,
        request: { dataset_id: dataset.id, format },
      });
      toastActions.loading({
        id: job.id,
        title: "Preparing your download...",
        description:
          "This could take a few minutes. The process will continue in the background even when this notification is dismissed.",
        duration: Infinity,
      });
    } catch (err) {
      logger.error("Failed to create download dataset job", err);
      toastActions.failure({
        title: `Something went wrong when downloading ${fileName}`,
      });
    }
  };

  return (
    <m.div
      animate={
        animateOnMount
          ? {
              opacity: 1,
              transition: {
                type: "tween",
                ease: "easeOut",
                delay: 0.15,
              },
            }
          : undefined
      }
      className="flex gap-x-2"
      exit={{ opacity: 0, transition: { duration: 0.08 } }}
      initial={animateOnMount ? { opacity: 0 } : undefined}
      transition={{ type: "tween", ease: "easeOut" }}
      layout
    >
      {variant === "item" && (
        <Tooltip placement="top" title="Edit test dataset" asChild>
          <Icon
            color="text-gray-500 hover:text-gray-700"
            dataLoc={`edit-${dataset.name}`}
            icon={faEdit}
            size="xs"
            onClick={stopPropagationWrapper(() => setEditDatasetId(dataset.id))}
          />
        </Tooltip>
      )}
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <FixedPositionedDropdown
          buttonClassName="border-0 ring-0 ui-open:border-0 ui-open:ring-0 focus:border-0 focus:ring-0 focus:ring-shadow bg-transparent"
          dataLoc={`download-${dataset.name}`}
          elements={[
            { key: "json", value: "Download as JSON" } as const,
            { key: "csv", value: "Download as CSV" } as const,
            { key: "xlsx", value: "Download as XLSX" } as const,
          ].filter(Boolean as unknown as ExcludesFalse)}
          itemsClassNames="w-66 font-inter-semibold-14px"
          placement="bottomLeft"
          renderButtonValue={() => (
            <Tooltip
              placement="top"
              title={
                showDownloadLoading
                  ? "There's a dataset download in progress"
                  : "Download test dataset"
              }
              asChild
            >
              <span>
                <Icon
                  color="text-gray-500 hover:text-gray-700"
                  disabled={showDownloadLoading}
                  icon={showDownloadLoading ? faSpinner : faArrowDownToLine}
                  size="xs"
                  spin={showDownloadLoading}
                />
              </span>
            </Tooltip>
          )}
          renderValue={(value) => (
            <div className="bg-white p-5 hover:bg-gray-50">{value.value}</div>
          )}
          onSelect={handleDownload}
        />
      </div>
      {datasets.canDelete && (
        <Tooltip placement="top" title="Delete test dataset" asChild>
          <Icon
            color="text-gray-500 hover:text-gray-700"
            dataLoc={`delete-${dataset.name}`}
            icon={faTrashCan}
            size="xs"
            onClick={stopPropagationWrapper(onDelete)}
          />
        </Tooltip>
      )}
      {variant === "header" && (
        <Tooltip placement="top" title="Expand test dataset" asChild>
          <Icon
            color="text-gray-500 hover:text-gray-700"
            dataLoc={`edit-${dataset.name}`}
            icon={faArrowUpRightAndArrowDownLeftFromCenter}
            size="2xs"
            onClick={stopPropagationWrapper(() => setEditDatasetId(dataset.id))}
          />
        </Tooltip>
      )}
    </m.div>
  );
};
