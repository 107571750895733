import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  InputMappingGroupT,
  InputMappingT,
  IntegrationResourceT,
} from "src/integrationNode/types";

const getBinInput = (): InputMappingT => ({
  id: uuidV4(),
  type: "text",
  displayName: "BIN",
  assignedTo: "",
  rules: {
    required: true,
  },
  hint: `A unique 9-digit business identification number assigned by Experian. This is not the same as a Tax ID.`,
});

const getCustomerReferenceInput = (): InputMappingT => ({
  id: uuidV4(),
  type: "text",
  displayName: "Customer reference",
  assignedTo: "",
  hint: "Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.",
});

const getDefaultAddressFields = (): { [key: string]: InputMappingT } => ({
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
    hint: `Current street address of the business, include suite number if applicable (e.g., "475 ANTON BLVD")`,
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    hint: `City is required if the postal code is not passed`,
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    hint: `A two-letter code for the U.S. state or territory of the address (e.g., "CA" for California). State is required if the postal code is not passed.`,
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    hint: `The company's 5-digit ZIP code. The postal code is required if the city and state are not passed.`,
  },
});

const getDefaultAddress = (): InputMappingGroupT => ({
  displayName: "Address",
  getDefaultElements: getDefaultAddressFields,
  elements: getDefaultAddressFields(),
});

export const getDefaultExperianUSB2BBusinessSearch =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "experian_us_b2b",
      resource: "experian_us_b2b_business_search",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        business_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Business name",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
        tax_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Tax ID",
          assignedTo: "",
        },
        phone_number: {
          id: uuidV4(),
          type: "text",
          displayName: "Phone number",
          assignedTo: "",
        },
        customer_reference: getCustomerReferenceInput(),
      },
      grouped: {
        address: getDefaultAddress(),
      },
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(CommonOutputMappingKey.PROVIDER_REQUEST_ID),
        most_confident_bin: {
          ...getNamedOutputMapping("Most confident BIN"),
          hint: `The Business Identity Number or BIN is a unique identifier used to identify a business. This report returns all businesses and BINs that match the search parameters provided. This insight provides the BIN that the search identifies as the most likely match.`,
        },
        highest_reliability_code: {
          ...getNamedOutputMapping("Highest reliability code"),
          hint: `This code signifies the confidence with which Experian believes that the top result is in fact the company being searched for. The code ranges from 0 to 100.4, with a low-confidence score being below 79, a medium-confidence being 79-90.74, and high being 90.75-100.4. Adding more details of the company in your search will improve results.`,
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultExperianUSB2BSmallBusinessCreditShareProfilesReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "experian_us_b2b",
      resource: "experian_us_b2b_small_business_credit_share_profiles_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        bin: getBinInput(),
        customer_reference: getCustomerReferenceInput(),
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        total_past_due_amount: {
          ...getNamedOutputMapping("Total past due amount"),
          hint: `The total amount of money owed by the business that is past due across all accounts`,
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultExperianUSB2BSmallBusinessCreditShareScores =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "experian_us_b2b",
      resource: "experian_us_b2b_small_business_credit_share_scores",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        bin: getBinInput(),
        customer_reference: getCustomerReferenceInput(),
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(),
        score: {
          ...getNamedOutputMapping("SBCS Acquisition Score"),
          hint: (
            <>
              A score from 1 to 100 indicating the likelihood that the business
              will become 90 days or more past due on a financial account in the
              next 24 months. A lower scores indicates a higher risk. There are
              two exclusion scores:
              <br /> 998: the business has declared bankruptcy in the previous 2
              years
              <br /> 999: there is insufficient data on the business to generate
              a score
            </>
          ),
        },
      },
    },
    config: defaultResourceConfig,
  });
