import { forwardRef } from "react";

import { ConverseResponse } from "src/clients/copilot";
import { BeMappedNode } from "src/constants/NodeDataTypes";
import { ConversationMessageItem } from "src/copilot/ConversationMessageItem";
import { LoadingMessageItem } from "src/copilot/LoadingMessageItem";
import { NodeSuggestionType } from "src/copilot/NodeSuggestion";

type Props = {
  node: BeMappedNode;
  conversation: ConverseResponse;
  loading?: boolean;
  retrying?: boolean;
  regenerating?: boolean;
  indexToAnimate: number;
  onApply: (suggestion: NodeSuggestionType) => void;
  onRegenerate: VoidFunction;
};

export const ConversationMessageList = forwardRef<HTMLUListElement, Props>(
  (
    {
      conversation,
      loading,
      retrying,
      regenerating,
      node,
      onApply,
      onRegenerate,
      indexToAnimate,
    },
    ref,
  ) => {
    const loadingMode = (() => {
      switch (true) {
        case regenerating:
          return "regenerating";
        case retrying:
          return "retrying";
        default:
          return "loading";
      }
    })();

    return (
      <ul
        ref={ref}
        className="decideScrollbar flex h-full flex-col gap-y-4 px-2 py-2"
      >
        {conversation.messages.map((message, index) => (
          <ConversationMessageItem
            key={`${message.role}-${index}`}
            animate={indexToAnimate === index}
            completionId={
              index === conversation.messages.length - 1
                ? conversation.completion_id
                : null
            }
            conversationId={conversation.conversation_id}
            message={message}
            node={node}
            onApply={onApply}
            onRegenerate={onRegenerate}
          />
        ))}
        {loading && <LoadingMessageItem mode={loadingMode} />}
      </ul>
    );
  },
);
