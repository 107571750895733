import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";

export type Props = {
  onRequestAddFolder: () => void;
};

export const AddFolder: React.FC<Props> = ({ onRequestAddFolder }) => {
  return (
    <button
      className={twJoin(
        "sticky bottom-0 flex w-full min-w-0 items-center gap-x-2 rounded border-t border-gray-200 bg-gray-100 px-2 py-1.5 text-indigo-600",
      )}
      data-loc="add-folder"
      onClick={onRequestAddFolder}
    >
      <Icon icon={faPlus} />
      <span className="grow truncate text-left font-inter-medium-13px">
        Add folder
      </span>
    </button>
  );
};
