import { twJoin } from "tailwind-merge";

const MovingElement: React.FC<{ className: string }> = ({ className }) => (
  <div
    className={twJoin(
      "relative overflow-hidden rounded bg-gray-200",
      className,
    )}
  >
    <div
      className="absolute inset-0 -translate-x-full transform bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200"
      style={{ animation: "shimmer 2s infinite" }}
    />
  </div>
);

export const FolderListSkeleton: React.FC = () => (
  <div className="flex flex-col gap-3">
    <MovingElement className="h-9.5 w-full" />
    <MovingElement className="h-9.5 w-full" />
    <MovingElement className="h-9.5 w-full" />
  </div>
);
