import { useMemo } from "react";

import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { DecisionFields } from "src/decisionsOverview/DecisionFields";
import {
  hasInputSchemaError,
  hasOutputSchemaError,
} from "src/decisionsOverview/utils";
import { Callout } from "src/design-system/Callout";
import { LeftPaneOptions } from "src/router/SearchParams";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { getBaseUrl, getUrlToAuthoringPage } from "src/router/urls";
import { errorMessage } from "src/utils/stringUtils";

const defaultErrorMessage =
  "An unexpected error occured while running the decision.";

export const ErrorTabContent: React.FC<{
  decision: DecisionHistoryRecordV2 & {
    is_error: true;
  };
}> = ({ decision }) => {
  const { orgId, workspace } = useWorkspaceContext();
  const parsedErrorMessage = errorMessage(
    decision.error?.msg ?? defaultErrorMessage,
  );
  const isInputSchemaError = hasInputSchemaError(decision);
  const isOutputSchemaError = hasOutputSchemaError(decision);
  const isSchemaError = isInputSchemaError || isOutputSchemaError;

  const handleClick = () => {
    if (!decision.error?.node_id) {
      return;
    }

    const params = {
      selectedNodeId: decision.error.node_id,
      decisionId: decision.id,
      leftPane: LeftPaneOptions.DecisionHistory,
    } as const;

    const url =
      getBaseUrl() +
      getUrlToAuthoringPage(
        orgId,
        workspace.id,
        decision.flow.id,
        decision.flow.version_id,
        params,
      );

    window.open(url, "_blank");
  };

  const actionText = useMemo(() => {
    if (!decision.error) {
      return null;
    }

    if (isSchemaError && !decision.is_sandbox) {
      return null;
    }

    if (isSchemaError) {
      return "Edit schema";
    }

    return "View Node";
  }, [decision, isSchemaError]);

  return (
    <div className="flex flex-col gap-y-4 px-4">
      <Callout
        action={
          decision.error?.node_id && actionText
            ? {
                text: actionText,
                onClick: handleClick,
              }
            : undefined
        }
        type="error"
      >
        <p className="overflow-hidden break-words">{parsedErrorMessage}</p>
      </Callout>
      {isInputSchemaError && (
        <DecisionFields decisionId={decision.id} type="request" />
      )}
    </div>
  );
};
