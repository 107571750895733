import { faArrowTurnDownLeft } from "@fortawesome/pro-regular-svg-icons";
import Quill from "quill";
import "quill-mention/autoregister";
import { useCallback, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { twJoin } from "tailwind-merge";

import { NodeBET } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { BeMappedNode } from "src/constants/NodeDataTypes";
import { getNodeIconFromNode } from "src/constants/NodeIcons";
import { MentionBlot } from "src/copilot/MentionBlot";
import { getQuillContent } from "src/copilot/quillUtils";
import { useInitQuill } from "src/copilot/useInitQuill";
import { useGraphStore } from "src/store/StoreProvider";

type Props = {
  value: string;
  disabled: boolean;
  minified: boolean;
  errored: boolean;
  onReset: () => void;
  onChange: (value: string) => void;
  onSubmit: (content: string, mentionedNodes: NodeBET[]) => void;
};

const MentionItem = ({ node }: { node: BeMappedNode }) => {
  return (
    <div className="flex w-[296px] cursor-pointer items-center px-3 py-4 hover:bg-gray-100">
      <div className="mr-2 h-5 w-5 shrink-0">
        {getNodeIconFromNode(node, "sm")}
      </div>
      <p>{node.data.label}</p>
    </div>
  );
};

export const renderDropdownItem = (node: BeMappedNode) => {
  const container = document.createElement("div");
  const root = createRoot(container);
  root.render(<MentionItem node={node} />);
  return container;
};

Quill.register("formats/mention", MentionBlot);

export const PromptBox: React.FC<Props> = ({
  disabled,
  value,
  minified,
  errored,
  onReset,
  onChange,
  onSubmit,
}) => {
  const { nodesArray } = useGraphStore();
  const editorRef = useRef<HTMLDivElement>(null);

  const handleSubmit = useCallback(
    (quill: Nullable<Quill>) => {
      if (disabled) return;

      const { content, mentionedNodes } = getQuillContent(quill, nodesArray);
      onSubmit(content ?? "", mentionedNodes);
    },
    [onSubmit, nodesArray, disabled],
  );

  const submitRef = useRef(handleSubmit);
  const quill = useInitQuill({
    editorRef,
    nodes: nodesArray,
    minified,
    submitRef,
    value,
    onChange,
  });

  useEffect(() => {
    setTimeout(() => {
      if (quill.current) {
        quill.current.focus();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    submitRef.current = handleSubmit;
  }, [handleSubmit]);

  if (errored) {
    return (
      <div className="broder-gray-200 flex items-center justify-center rounded-lg border bg-gray-50 p-3.5">
        <Button
          iconRight={faArrowTurnDownLeft}
          size="sm"
          variant="primary"
          onClick={onReset}
        >
          Start a new chat
        </Button>
      </div>
    );
  }

  return (
    <div className="broder-gray-200 relative flex flex-col rounded-lg border bg-gray-0 shadow-sm">
      <div
        ref={editorRef}
        className={twJoin(
          "resize-none",
          "border border-transparent",
          "pb-2.5 pl-3 pt-2",
          "text-gray-800 font-inter-normal-13px",
          "focus:border-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500/25",
          minified ? "rounded-lg" : "rounded-t-lg",
          minified ? "pr-24" : "pr-3",
        )}
        data-loc="copilot-prompt"
      />
      {minified && (
        <div className="absolute right-2 top-2">
          <Button
            disabled={disabled}
            htmlType="submit"
            iconRight={faArrowTurnDownLeft}
            size="sm"
            variant="primary"
            onClick={() => handleSubmit(quill.current)}
          >
            Continue
          </Button>
        </div>
      )}
      {!minified && (
        <div className="mx-3 flex items-center justify-between border-t border-t-gray-100 py-2">
          <p className="text-gray-500 font-inter-normal-12px">
            Use @ to refer to other nodes
          </p>
          <Button
            dataLoc="copilot-start-chat"
            disabled={disabled}
            htmlType="submit"
            iconRight={faArrowTurnDownLeft}
            size="sm"
            variant="primary"
            onClick={() => handleSubmit(quill.current)}
          >
            Start Chat
          </Button>
        </div>
      )}
    </div>
  );
};
