import { faBolt, faClock, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";

import { DecisionEnvironment } from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { FlowDbShallow, FlowVersionDbShallow } from "src/clients/flow-api";
import { Tooltip } from "src/design-system/Tooltip";
import { useJobRun } from "src/jobs/api/queries";
import { Job } from "src/jobs/types";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import {
  getBaseUrl,
  getUrlToHistoricDecisionTrace,
  getUrlToJobPage,
} from "src/router/urls";
import { formatDate } from "src/utils/datetime";
import { assertUnreachable } from "src/utils/typeUtils";

export enum SourceDetailType {
  ParentFlow = "parentFlow",
  Job = "job",
  ApiCall = "apiCall",
}
export type SourceDetails =
  | {
      type: SourceDetailType.ParentFlow;
      parentFlow: FlowDbShallow | undefined;
      parentVersion: FlowVersionDbShallow | undefined;
      parentDecisionId: string | undefined;
    }
  | {
      type: SourceDetailType.Job;
      job: Job | undefined;
      runId: string;
    }
  | { type: SourceDetailType.ApiCall };

type Props = {
  sourceDetails: SourceDetails;
  decisionEnv: DecisionEnvironment;
  orgId: string;
  wsId: string;
};
export const SourcePill: React.FC<Props> = ({
  sourceDetails,
  decisionEnv,
  orgId,
  wsId,
}) => {
  switch (sourceDetails.type) {
    case SourceDetailType.ParentFlow: {
      const parentFlowAndVersionName = `${sourceDetails.parentFlow?.name}, ${sourceDetails.parentVersion?.name}`;
      const parentFlowName = sourceDetails.parentFlow?.name;
      const pill = (
        <Tooltip placement="bottom" title={parentFlowAndVersionName} asChild>
          <Pill dataLoc="parent-pill" variant="outlined-white" maxWidth>
            <Pill.Text>{parentFlowName}</Pill.Text>
          </Pill>
        </Tooltip>
      );
      return sourceDetails.parentFlow &&
        sourceDetails.parentVersion &&
        sourceDetails.parentDecisionId ? (
        <a
          className="max-w-full"
          href={getUrlToHistoricDecisionTrace({
            orgId,
            wsId,
            flowId: sourceDetails.parentFlow.id,
            versionId: sourceDetails.parentVersion.id,
            decisionId: sourceDetails.parentDecisionId,
            decisionEnv: decisionEnv,
          })}
          rel="noreferrer"
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          {pill}
        </a>
      ) : (
        pill
      );
    }
    case SourceDetailType.Job: {
      const pill = (
        <Pill dataLoc="parent-pill" variant="outlined-white">
          <Pill.Icon icon={faBolt} />
          <Pill.Text>
            {sourceDetails.job
              ? (sourceDetails.job.name ?? "Unnamed Job")
              : "Deleted Job"}
          </Pill.Text>
          {sourceDetails.job && (
            <RunDetails
              jobId={sourceDetails.job.id}
              runId={sourceDetails.runId}
            />
          )}
        </Pill>
      );
      return sourceDetails.job ? (
        <a
          href={
            getBaseUrl() +
            getUrlToJobPage(
              orgId,
              wsId,
              sourceDetails.job.flow_id,
              sourceDetails.job.id,
              decisionEnv,
            )
          }
          rel="noreferrer"
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          {pill}
        </a>
      ) : (
        pill
      );
    }
    case SourceDetailType.ApiCall: {
      return (
        <Pill dataLoc="parent-pill" variant="outlined-white">
          <Pill.Text>API call</Pill.Text>
        </Pill>
      );
    }
    default: {
      assertUnreachable(sourceDetails);
    }
  }
};

const RunDetails: React.FC<{
  runId: string;
  jobId: string;
}> = (props) => {
  const { workspace } = useWorkspaceContext();
  const [enableQuery, setEnableQuery] = useState(false);

  const jobRun = useJobRun({
    ...props,
    baseURL: workspace.base_url!,
    enabled: enableQuery,
  });

  const tooltipBody = jobRun.data ? (
    <p className="text-white font-inter-semibold-13px">
      Job Run on {formatDate(jobRun.data.created_at, "d MMM yyyy hh:mm aaa")}
    </p>
  ) : (
    <div className="flex items-center gap-x-2 text-white font-inter-semibold-13px">
      <Icon
        color="text-gray-500 hover:text-gray-700"
        icon={faSpinner}
        size="2xs"
        spin
      />
      Loading...
    </div>
  );

  return (
    <Tooltip body={tooltipBody} placement="top">
      <div onMouseOver={!enableQuery ? () => setEnableQuery(true) : undefined}>
        <Icon
          color="text-gray-500 hover:text-gray-700"
          icon={faClock}
          size="2xs"
        />
      </div>
    </Tooltip>
  );
};
