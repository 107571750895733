import { isObject } from "lodash";
import React from "react";
import { twJoin } from "tailwind-merge";

import { GenericObjectT } from "src/api/flowTypes";
import { ExpectedDataCell } from "src/dataTable/ExpectedDataCell";
import { JSONCell } from "src/dataTable/JSONCell";
import { RawCell } from "src/dataTable/RawCell";
import { CellContent, isReactNode } from "src/dataTable/types";
import { isJsonObject } from "src/utils/stringUtils";

export const cellBaseClassName =
  "text-gray-800 font-inter-normal-12px py-2.5 max-w-40 pr-2 truncate" as const;

type PropsT<T> = {
  cell: CellContent<T> | React.ReactElement;
  objectDetailPosition: "topRight" | "topLeft";
  className?: string;
  columnTitle?: string;
  withTooltip?: boolean;
  actualOutput?: { value: string; isMatch: boolean };
  expectedOutput?: { value: string; isMatch: boolean };
  suffix?: React.ReactNode;
};

export const ResultCell = <T extends GenericObjectT>({
  cell,
  objectDetailPosition,
  className,
  columnTitle,
  withTooltip = false,
  expectedOutput,
  actualOutput,
  suffix,
}: PropsT<T>) => {
  if (
    (expectedOutput || actualOutput) &&
    !isReactNode(cell) &&
    cell.getValue()
  ) {
    return (
      <ExpectedDataCell<T>
        actualOutput={actualOutput}
        cell={cell}
        className={cellBaseClassName}
        classNameOverrides={className}
        expectedOutput={expectedOutput}
        suffix={suffix}
      />
    );
  } else if (
    !isReactNode(cell) &&
    !isReactNode(cell.getValue()) &&
    (isJsonObject(cell.getValue()) || isObject(cell.getValue()))
  ) {
    return (
      <JSONCell<T>
        cell={cell}
        className={twJoin(cellBaseClassName, className)}
        columnTitle={columnTitle}
        placement={
          objectDetailPosition === "topRight" ? "top-end" : "top-start"
        }
        suffix={suffix}
        value={cell.getValue()}
      />
    );
  } else {
    return (
      <RawCell<T>
        cell={cell}
        className={cellBaseClassName}
        classNameOverrides={className}
        suffix={suffix}
        withTooltip={withTooltip}
      />
    );
  }
};
