import React, { useEffect, useState } from "react";

import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { WorkspaceServiceAccountWithKey } from "src/clients/taktile-api";
import { Modal } from "src/design-system/Modal";

type PropsT = {
  createdApiKey?: WorkspaceServiceAccountWithKey;
  onUnmount: () => void;
};

export const ShowApiKeyModal: React.FC<PropsT> = ({
  createdApiKey,
  onUnmount,
}) => {
  // The open state of this modal is controlled by the "createdApiKey" prop.
  // Whenever it is set away from undefined, the modal opens.
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (createdApiKey !== undefined) {
      setOpen(true);
    }
  }, [createdApiKey]);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Modal afterLeave={onUnmount} open={open} onClose={closeModal}>
      <Modal.Header>Your new API key</Modal.Header>
      <Modal.Content>
        <div className="mb-4 text-gray-500 font-inter-normal-12px">
          Please copy your API key now. You won't be able to see it again. Save
          and store it in a secure place, such as a password manager or secret
          store.
        </div>
        <div className="flex w-full justify-between rounded-lg border border-gray-300 py-2 pl-2 pr-3 text-gray-800 font-inter-normal-13px">
          {createdApiKey?.api_key}
          <CopyTextIcon
            feedback="inline"
            tooltip="Copy"
            value={createdApiKey?.api_key ?? ""}
          />
        </div>
      </Modal.Content>
      <Modal.Footer primaryButton={false} secondaryButton="Done" />
    </Modal>
  );
};
