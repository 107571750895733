import { DatasetRow } from "src/api/types";
import { useDeleteRowMutation } from "src/datasets/api/queries";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { useFlowContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";

export const useDeleteRow = (datasetId: string, row: DatasetRow) => {
  const { workspace, flow } = useFlowContext();
  const { mutateAsync: deleteRow } = useDeleteRowMutation(
    datasetId,
    flow.id,
    workspace?.base_url,
  );

  return async () => {
    try {
      await deleteRow(row.id);
    } catch (e) {
      toastActions.failure({
        title: `Failed to delete row`,
        description: TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(e);
    }
  };
};
