import {
  AuthSettings,
  ConnectionConfiguration,
  ConnectionConfigurationCreateT,
  ConnectionCreateT,
  ConnectionT,
  CustomConnectionAuthMethod,
  OAuth2AuthSettings,
} from "src/api/connectApi/types";
import {
  filterSecretsByEnvironment,
  filterUpdatedSecrets,
  markSecrets,
} from "src/connections/model/common";
import {
  ConnectionAuthOAuth2T,
  ConnectionConfigAuthMethods,
  ConnectionConfigInputsT,
  ConnectionConfigT,
  ConnectionInputsT,
  ConnectionSSLConfigT,
  KeyValuePairT,
  ProviderSelectInputsT,
} from "src/connections/types";
import { assertUnreachable } from "src/utils/typeUtils";

const KVPairsToObj = (pairs: KeyValuePairT[]) => {
  if (pairs.length === 0) return undefined;
  return pairs
    .filter((p) => p.key.trim() !== "") // remove empty keys but allow empty values
    .reduce(
      (acc, pair) => ({
        ...acc,
        [pair.key]: pair.value,
      }),
      {},
    );
};

const toKVPairs = (obj: Record<string, string> | undefined | null) => {
  if (obj == null) return;
  return Object.entries(obj).map(([key, value]) => ({ key, value }));
};

export const providerDefaultValues = (): ProviderSelectInputsT => ({
  provider: undefined,
  isManifestProvider: false,
});

export const connectionToProviderSelectInput = (
  connection: ConnectionT | undefined,
): ProviderSelectInputsT => {
  if (connection === undefined) return providerDefaultValues();
  const { provider } = connection;
  return {
    provider,
    isManifestProvider: Boolean(connection.manifest_version),
  };
};

export const JSONOrString = (value: string | undefined | null) => {
  if (value == null) return undefined;
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const JSONPretty = (value: string | object | undefined | null) => {
  if (value == null) return "";
  if (typeof value === "object") return JSON.stringify(value, null, 2);

  return String(value);
};

// Shared with the Outgoing Webhooks creation form.
// The order of secrets in the list is relevant for the forms!
export const defaultAuthMethodSecrets: Record<
  CustomConnectionAuthMethod,
  (KeyValuePairT & { value: string })[]
> = {
  no_auth: [],
  basic: [
    { key: "login", value: "", required: true },
    { key: "password", value: "", required: true },
  ],
  api_key: [{ key: "api_key", value: "", required: true }],
  oauth2: [
    { key: "client_id", value: "", required: true },
    { key: "client_secret", value: "", required: true },
  ],
  google_oauth2: [
    { key: "jwt_secret_key", value: "", required: true },
    { key: "client_id", value: "", required: true },
  ],
  aws_signature: [{ key: "aws_secret_key", value: "", required: true }],
};

export const isSecretFieldRequired = (
  authMethod: CustomConnectionAuthMethod,
  key: string,
) => {
  const secrets = defaultAuthMethodSecrets[authMethod];
  return secrets.some((secret) => secret.key === key && secret.required);
};

export const connectionConfigAuthMethodsDefaultValues =
  (): ConnectionConfigAuthMethods => ({
    authMethod: "no_auth",
    noAuthConfig: {
      auth_method: "no_auth",
    },
    basicAuthConfig: {
      auth_method: "basic",
    },
    apiKeyConfig: {
      auth_method: "api_key",
      header: undefined,
      prefix: undefined,
      api_key_as_query_param: false,
      query_param_name: undefined,
    },
    oauth2Config: {
      auth_method: "oauth2",
      uses_basic_auth: false,
      prefix: "Bearer",
      header: "Authorization",
      authorize_query_params: [],
      authorize_headers: [],
      refresh_query_params: [],
      refresh_headers: [],
      token_refresh_interval: 1,
    },
    googleOAuth2Config: {
      auth_method: "google_oauth2",
      scope: "",
      authorize_url: "",
      token_refresh_interval: 1,
      filename: "",
    },
    awsSignatureConfig: {
      auth_method: "aws_signature",
      aws_access_key: "",
      aws_host: "",
      aws_region: "",
      aws_service: "",
    },
  });
// Used to populate the default values for production configuration,
// but also the nonProd ones.
export const connectionConfigDefaultValues = (): Omit<
  ConnectionConfigT,
  "url" | "name" | "probeUrl"
> => ({
  headers: [],
  enableSSL: false,
  hasSSHTunnelEnabled: false,
  SSHTunnel: {
    host: "",
    port: 22,
    user: "",
    privateKey: { key: "", value: "", secret: true },
  },
  secrets: [],
  allowTestInvocations: false,
  ...connectionConfigAuthMethodsDefaultValues(),
});

export const connectionInputsDefaultValues =
  (): Partial<ConnectionConfigInputsT> => ({
    ...connectionConfigDefaultValues(),
    allowTestInvocations: false,
    enableNonProdConfigs: false,
    mediaKey: undefined,
    dataRetention: {
      value: 0,
      unit: "days",
    },
  });

type ConnectionConfigInputT = {
  connectionConfig: Partial<ConnectionConfigT>;
  secrets: KeyValuePairT[];
};

export const Oauth2SettingsToOauthInputs = (
  auth_settings: OAuth2AuthSettings,
): ConnectionAuthOAuth2T => {
  const defaults = connectionConfigDefaultValues();
  const oauth2Default = defaults.oauth2Config;
  return {
    ...oauth2Default,
    auth_method: "oauth2",
    authorize_url: auth_settings.authorize_url,
    uses_basic_auth: auth_settings.uses_basic_auth,
    basic_auth_header: auth_settings.basic_auth_header,
    basic_auth_prefix: auth_settings.basic_auth_prefix,
    prefix: auth_settings.prefix,
    header: auth_settings.header,
    authorize_body: JSONPretty(auth_settings.authorize_body),
    refresh_url: auth_settings.refresh_url,
    refresh_body: JSONPretty(auth_settings.refresh_body),
    grant_type: "client_credentials",
    scopes: auth_settings.scopes ? auth_settings.scopes.join(",") : "",
    scope_delimiter: auth_settings.scope_delimiter,
    authorize_query_params:
      toKVPairs(auth_settings.authorize_query_params) ??
      oauth2Default.authorize_query_params,
    authorize_headers:
      toKVPairs(auth_settings.authorize_headers) ??
      oauth2Default.authorize_headers,
    refresh_query_params:
      toKVPairs(auth_settings.refresh_query_params) ??
      oauth2Default.refresh_query_params,
    refresh_headers:
      toKVPairs(auth_settings.refresh_headers) ?? oauth2Default.refresh_headers,
    token_refresh_interval: auth_settings.token_refresh_interval,
    // Path are stored with the body. prefix in the server to support other
    // namespaces in the future. For example headers.
    authorize_body_expires_path: auth_settings.authorize_body_expires_path
      ? auth_settings.authorize_body_expires_path.replace("body.", "")
      : "",
    authorize_body_token_path: auth_settings.authorize_body_token_path
      ? auth_settings.authorize_body_token_path.replace("body.", "")
      : "",
  };
};

const connectionConfigToInput = (
  configuration: ConnectionConfiguration,
  secrets: KeyValuePairT[],
): ConnectionConfigInputT => {
  // Converts the configuration from the API to the format we expect on the FE.
  // Used for both production and nonProd configurations
  const auth_settings = configuration.auth_settings as AuthSettings;
  const defaults = connectionConfigDefaultValues();
  let connectionSecrets: KeyValuePairT[] = secrets;

  let connectionConfig: Partial<ConnectionConfigInputsT> = {
    allowTestInvocations: configuration.allow_test_invocations,
    url: configuration.base_url,
    probeUrl: configuration.probe_url,
    mediaKey: configuration.media_key,
    authMethod: auth_settings.auth_method,
    headers: toKVPairs(configuration.headers) ?? defaults.headers,
    enableSSL: auth_settings.ssl_enabled,
  };

  if (auth_settings.ssl_enabled) {
    // If ssl is enabled, we want to fetch ssl_key and ssl_certificate from the secrets list

    // Repopulate the connection secrets so that it does not contain ssl_key and ssl_certificate
    connectionSecrets = [];
    const sslConfig: ConnectionSSLConfigT = {
      ssl_certificate: { value: null },
      ssl_key: { value: null },
    };

    secrets.forEach((secret) => {
      if (secret.key === "ssl_key" || secret.key === "ssl_certificate") {
        sslConfig[secret.key] = { value: secret.value, secret: true };
      } else {
        connectionSecrets.push(secret);
      }
    });
    connectionConfig["sslConfig"] = sslConfig;
  }

  switch (auth_settings.auth_method) {
    case "no_auth":
      connectionConfig = {
        ...connectionConfig,
        noAuthConfig: {
          ...defaults.noAuthConfig,
          auth_method: "no_auth",
        },
      };
      break;
    case "basic":
      connectionConfig = {
        ...connectionConfig,
        basicAuthConfig: {
          ...defaults.basicAuthConfig,
          auth_method: "basic",
        },
      };
      break;
    case "api_key":
      connectionConfig = {
        ...connectionConfig,
        apiKeyConfig: {
          ...defaults.apiKeyConfig,
          ...{
            auth_method: "api_key",
            header: auth_settings.header,
            prefix: auth_settings.prefix,
            api_key_as_query_param: auth_settings.api_key_as_query_param,
            query_param_name: auth_settings.query_param_name,
          },
        },
      };
      break;
    case "oauth2":
      connectionConfig = {
        ...connectionConfig,
        oauth2Config: Oauth2SettingsToOauthInputs(auth_settings),
      };
      break;
    case "aws_signature":
      const { aws_access_key, aws_host, aws_region, aws_service } =
        auth_settings;
      connectionConfig = {
        ...connectionConfig,
        awsSignatureConfig: {
          auth_method: "aws_signature",
          aws_access_key,
          aws_host,
          aws_region,
          aws_service,
        },
      };
      break;
    case "google_oauth2":
      const googleOAuth2Default = defaults.googleOAuth2Config;
      connectionConfig = {
        ...connectionConfig,
        googleOAuth2Config: {
          ...googleOAuth2Default,
          auth_method: "google_oauth2",
          scope: auth_settings.scopes ? auth_settings.scopes[0] : "",
          authorize_url: auth_settings.authorize_url,
          token_refresh_interval: auth_settings.token_refresh_interval,
          filename: auth_settings.google_auth_filename,
        },
      };
      break;
  }

  return {
    connectionConfig,
    secrets: connectionSecrets,
  };
};

const nonProdEnvConfigsToInput = (
  nonProdEnvConfigs: Record<string, ConnectionConfiguration>,
  allSecrets: KeyValuePairT[],
): Record<string, ConnectionConfigT> => {
  // Converts the non_prod_env_configs we receive from the API
  // to the format we expect on the FE.
  const configs: Record<string, ConnectionConfigT> = {};

  Object.entries(nonProdEnvConfigs).forEach(([env, config]) => {
    const envSecrets = filterSecretsByEnvironment(allSecrets, env);
    const { connectionConfig, secrets } = connectionConfigToInput(
      config,
      envSecrets,
    );
    const { url, probeUrl, headers, ...configuration } = connectionConfig;

    configs[env] = {
      ...connectionConfigDefaultValues(),
      ...configuration,
      url: url as string,
      probeUrl: probeUrl as string,
      headers: headers as KeyValuePairT[],
      authMethod: configuration.authMethod as CustomConnectionAuthMethod,
      secrets,
    };
  });

  return configs;
};

export const connectionToConnectionInputs = (
  connection: ConnectionT | undefined,
): Partial<ConnectionConfigInputsT> => {
  const defaults = connectionInputsDefaultValues();

  if (connection == null) return defaults;
  const { configuration: config, non_prod_env_configs } = connection;
  const { auth_settings } = config;
  // Return defaults when webhook connection is provided
  if (auth_settings == null) return defaults;

  const markedSecrets = markSecrets(connection.secrets) ?? defaults.secrets;
  const { connectionConfig, secrets } = connectionConfigToInput(
    config,
    markedSecrets,
  );

  return {
    ...defaults,
    ...connectionConfig,
    enableNonProdConfigs: connection.enable_non_prod_configs,
    hasRawProviderResponseEnabled: connection.has_raw_response_enabled,
    hasRawProviderRequestEnabled:
      connection.has_raw_requests_enabled_in_resource,
    name: connection.name,
    dataRetentionDays: 0,
    dataRetention: connection.data_retention ?? defaults.dataRetention,
    // null environment is how we store the production secrets
    secrets: filterSecretsByEnvironment(secrets, null),
    nonProdEnvConfigs:
      non_prod_env_configs &&
      nonProdEnvConfigsToInput(non_prod_env_configs, markedSecrets),
  } as ConnectionConfigInputsT;
};

export const emptyToUndefined = (value: string | undefined) => {
  if (value == null) return undefined;
  return typeof value === "string" && value.trim() === "" ? undefined : value;
};

// This method is shared with the Outgoing Webhooks Modal!
export const authSettingsFromConfig = (
  configConnection: Pick<
    ConnectionConfigInputsT,
    | "enableSSL"
    | "authMethod"
    | "noAuthConfig"
    | "basicAuthConfig"
    | "apiKeyConfig"
    | "oauth2Config"
    | "awsSignatureConfig"
    | "googleOAuth2Config"
  >,
) => {
  const {
    authMethod,
    noAuthConfig,
    basicAuthConfig,
    apiKeyConfig,
    oauth2Config,
    awsSignatureConfig,
    googleOAuth2Config,
  } = configConnection;

  const baseConfig = {
    ssl_enabled: configConnection.enableSSL,
  };

  switch (authMethod) {
    case "no_auth":
      return { ...baseConfig, ...noAuthConfig };
    case "basic":
      return { ...baseConfig, ...basicAuthConfig };
    case "api_key":
      return { ...baseConfig, ...apiKeyConfig };
    case "oauth2":
      const authorize_query_params = KVPairsToObj(
        filterUpdatedSecrets(oauth2Config.authorize_query_params),
      );
      const authorize_headers = KVPairsToObj(
        filterUpdatedSecrets(oauth2Config.authorize_headers),
      );
      const refresh_query_params = KVPairsToObj(
        filterUpdatedSecrets(oauth2Config.refresh_query_params),
      );
      const refresh_headers = KVPairsToObj(
        filterUpdatedSecrets(oauth2Config.refresh_headers),
      );
      const token_path = emptyToUndefined(
        oauth2Config.authorize_body_token_path,
      );
      const expires_path = emptyToUndefined(
        oauth2Config.authorize_body_expires_path,
      );
      return {
        ...baseConfig,
        ...oauth2Config,
        authorize_query_params,
        authorize_headers,
        refresh_query_params,
        refresh_headers,
        refresh_body: JSONOrString(oauth2Config.refresh_body),
        authorize_body: JSONOrString(oauth2Config.authorize_body),
        token_refresh_interval: oauth2Config.token_refresh_interval || 1,
        scopes: oauth2Config.scopes
          ? oauth2Config.scopes.split(",").map((s) => s.trim())
          : undefined,
        scope_delimiter: oauth2Config.scope_delimiter,
        // Path are stored with the body. prefix in the server to support other
        // namespaces in the future. For example headers.
        authorize_body_token_path: token_path && `body.${token_path}`,
        authorize_body_expires_path: expires_path && `body.${expires_path}`,
      };
    case "aws_signature":
      return { ...baseConfig, ...awsSignatureConfig };
    case "google_oauth2":
      const { scope, filename, ...rest } = googleOAuth2Config;
      return {
        ...baseConfig,
        google_auth_filename: filename,
        scopes: [scope],
        ...rest,
      };
    default:
      return assertUnreachable(authMethod);
  }
};

const sslConfigToSecrets = (
  sslConfig: ConnectionSSLConfigT,
  environment?: string,
): KeyValuePairT[] => {
  // Converts the sslConfig from the FE to the format the BE expects.
  // Which is to return them as secrets

  const { ssl_certificate, ssl_key } = sslConfig;

  const results = [];

  if (!ssl_key.secret) {
    results.push({ key: "ssl_key", value: ssl_key.value, environment });
  }

  if (!ssl_certificate.secret) {
    results.push({
      key: "ssl_certificate",
      value: ssl_certificate.value,
      environment,
    });
  }

  return results;
};

const nonProdEnvConfigsInputsToCreateConnection = (
  nonProdEnvConfigs: Record<string, ConnectionConfigT>,
): [Record<string, any>, KeyValuePairT[]] => {
  // Converts the frontend format for nonProdEnvConfigs into the one the BE expects.
  // It also returns all the secrets for all environments.
  // That are to be sent separately to the BE.
  const configs: Record<string, any> = {};
  const allSecrets: KeyValuePairT[] = [];

  Object.entries(nonProdEnvConfigs).forEach(([env, config]) => {
    const { secrets, sslConfig, ...configuration } = config;
    configs[env] = inputConfigurationToCreateConnection(configuration);

    const sslSecrets =
      configuration.enableSSL && sslConfig
        ? sslConfigToSecrets(sslConfig, env)
        : [];

    allSecrets.push(
      ...filterUpdatedSecrets(secrets).map((s) => ({ ...s, environment: env })),
      ...sslSecrets,
    );
  });

  return [configs, allSecrets];
};

// Converts the common(production + nonProd) configuration from the FE
// to the one the BE expects.
const inputConfigurationToCreateConnection = (
  configuration: ConnectionConfigT,
): Pick<
  ConnectionConfigurationCreateT,
  "base_url" | "probe_url" | "headers" | "auth_settings"
> => ({
  base_url: configuration.url,
  probe_url: configuration.probeUrl,
  headers: KVPairsToObj(configuration.headers) ?? {},
  auth_settings: authSettingsFromConfig(configuration),
});

export const connectionInputsToCreateConnection = (
  data: ConnectionInputsT,
): ConnectionCreateT => {
  const {
    selectProviderForm: { provider },
    configConnectionForm,
  } = data;

  if (!provider) {
    throw new Error("Provider is required to create a connection");
  }

  const {
    name,
    mediaKey,
    secrets,
    allowTestInvocations,
    enableNonProdConfigs,
    enableSSL,
    sslConfig,
    nonProdEnvConfigs,
    dataRetention,
    hasRawProviderResponseEnabled,
    hasRawProviderRequestEnabled,
  } = configConnectionForm;

  const sslSecrets =
    enableSSL && sslConfig ? sslConfigToSecrets(sslConfig, undefined) : [];

  const [non_prod_env_configs, nonProdSecrets] =
    enableNonProdConfigs && nonProdEnvConfigs
      ? nonProdEnvConfigsInputsToCreateConnection(nonProdEnvConfigs)
      : [{}, []];

  return {
    name,
    is_sandbox: false,
    configuration: {
      ...inputConfigurationToCreateConnection(configConnectionForm),
      media_key: mediaKey,
      allow_test_invocations: allowTestInvocations,
    },
    secrets: [
      ...filterUpdatedSecrets(secrets),
      ...nonProdSecrets,
      ...sslSecrets,
    ],
    provider,
    enable_non_prod_configs: enableNonProdConfigs,
    non_prod_env_configs,
    data_retention: dataRetention,
    has_raw_response_enabled: hasRawProviderResponseEnabled,
    has_raw_requests_enabled_in_resource: hasRawProviderRequestEnabled,
  };
};
