import dagre from "@dagrejs/dagre";
import { keyBy } from "lodash";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import {
  Edge,
  NodeDimensionChange,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  getRectOfNodes,
} from "reactflow";
import { v4 as uuidV4, v4 } from "uuid";

import { ManifestIntegrationProvider } from "src/api/connectApi/manifestTypes";
import {
  CustomProviderResourceT,
  IntegrationProviderResourceT,
  InboundWebhookProviderResourceT,
  ManualReviewProviderResourceT,
  DatabaseProviderResourceT,
  ManifestIntegrationProviderResourceT,
} from "src/api/connectApi/types";
import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowVersionT, GraphT } from "src/api/flowTypes";
import {
  FlowVersionGraphUpdate,
  Operation,
  updateFlowVersionGraph,
} from "src/api/flowVersionUpdateIndex";
import { getOptimistacallyUpdatedNodes } from "src/api/getOptimisticNodes";
import { flowKeys, useFlow } from "src/api/queries";
import { ErroredRow, FlowRunnerResultV2 } from "src/api/types";
import { SplitBranch } from "src/clients/flow-api";
import {
  COLLAPSED_GROUP_HEIGHT,
  COLLAPSED_GROUP_WIDTH,
  DEFAULT_NODE_WIDTH,
  getDefaultAssignmentNode,
  getDefaultCodeNode,
  getDefaultCustomConnectionNode,
  getDefaultDecisionTableNode,
  getDefaultFlowEdge,
  getDefaultFlowNode,
  getDefaultGroupEdge,
  getDefaultGroupNode,
  getDefaultIntegrationNode,
  getDefaultManifestConnectionNode,
  getDefaultManualReviewNode,
  getDefaultMlNode,
  getDefaultRuleNodeV2,
  getDefaultScorecardNode,
  getDefaultSplitBranchNodeV2,
  getDefaultSplitEdge,
  getDefaultSplitMergeNode,
  getDefaultSplitNodeV2,
  getDefaultInboundWebhookConnectionNode,
  getDefaultDatabaseConnectionNode,
  getDefaultLoopNode,
} from "src/constants/DefaultValues";
import {
  BeMappedNode,
  GroupNode,
  GroupNodeDataT,
  GroupSeparatorNode,
  NodeDataT,
  NodeT,
  SplitNodeV2,
  SplitNodeV2DataT,
} from "src/constants/NodeDataTypes";
import {
  NODE_TYPE,
  PARENT_FLOW_NODE_TYPES,
  SimpleCreatableNodeTypeT,
} from "src/constants/NodeTypes";
import { ResultDataAndAuxRowV2 } from "src/dataTable/types";
import { singleFlowIsChildFlow } from "src/flow/Model";
import { EDGE_TYPE, GroupEdge } from "src/flowGraph/EdgeTypes";
import {
  InvalidCopySelectionReason,
  InvalidGroupReason,
} from "src/flowGraph/multiSelectOptions/MultiSelectOptions";
import { getDefaultResource } from "src/integrationNode/integrationResources/DefaultResources";
import { getDefaultManifestProviderResource } from "src/manifestConnectionNode/manifestConnectionResources/DefaultResources";
import { queryClient } from "src/queryClient";
import {
  getDefaultBranch,
  getNewSplitBranch,
} from "src/splitNodeV2Editor/types";
import { clearHighlightingGraphStore } from "src/store/NodeHighlighting";
import { dagreToReactFlow } from "src/store/positioningHelpers";
import {
  clearRunStates,
  getNodeRunState,
  setResults,
} from "src/store/runState/RunState";
import {
  GraphTransform,
  SubGraphClipboard,
  findBranchNodes,
  findBranchesAndNodes,
  findSplitMergeNode,
  getAbsoluteXPosition,
  getEdgesInSubgraph,
  getNodesAndEdgesWithNewIDs,
  getParentGroup,
  getSelectableParentNode,
  getSourceAndSink,
  getUpdatedGroup,
  graphHasRootAndIsConnected,
  orderNodesAndEdgesForLayout,
} from "src/utils/GraphUtils";
import {
  DebouncedAndArgsSaveFunction,
  debounceAndSaveArgs,
} from "src/utils/debounceAndSaveArgs";
import {
  readGraphFromClipboard,
  writeGraphToClipboard,
} from "src/utils/decideClipboard";
import { logger } from "src/utils/logger";
import { isHistoricalRunState, isIntegrationNodes } from "src/utils/predicates";
import { assertUnreachable } from "src/utils/typeUtils";
import { getEdgeWasPartOfHistoricalDecision } from "src/versionDecisionHistory/getEdgeWasPartOfHistoricalDecision";

const TAG: string = "GraphStore";
export const UPDATE_DEBOUNCE_WAIT_TIME = 500;
const MAXIMUM_Z_INDEX = 2147483647 as const;
export const MAXIMUM_PARENT_NODES = 20 as const;
export const PROVIDED_ID_NODE_A_NODE_ID =
  "provided nodeId is not in the graph" as const;

export type AddNodeParamsT =
  | { nodeType: SimpleCreatableNodeTypeT }
  | {
      nodeType: NODE_TYPE.INTEGRATION_NODE;
      providerResource: IntegrationProviderResourceT;
      connectionId: string;
      resourceConfigId: string;
    }
  | {
      nodeType: NODE_TYPE.MANIFEST_CONNECTION_NODE;
      providerResource: ManifestIntegrationProviderResourceT;
      connectionId: string;
      resourceConfigId: string;
      manifest: ManifestIntegrationProvider;
    }
  | {
      nodeType: NODE_TYPE.CUSTOM_CONNECTION_NODE;
      providerResource: CustomProviderResourceT;
      connectionId: string;
      resourceConfigId: string;
      mediaKey: string | null;
    }
  | {
      nodeType: NODE_TYPE.WEBHOOK_CONNECTION_NODE;
      providerResource: InboundWebhookProviderResourceT;
      connectionId: string;
      resourceConfigId: string;
      mediaKey: string | null;
    }
  | {
      nodeType: NODE_TYPE.SQL_DATABASE_CONNECTION_NODE;
      providerResource: DatabaseProviderResourceT;
      connectionId: string;
      resourceConfigId: string;
    }
  | {
      nodeType: NODE_TYPE.REVIEW_CONNECTION_NODE;
      providerResource: ManualReviewProviderResourceT;
      connectionId: string;
      resourceConfigId: string;
    };

type CreateSubgraphArgs = {
  nodesToAdd: BeMappedNode[];
  edgesToAdd: Edge[];
  groupsToAdd?: GroupNode[];
  atVisibleEdgeId: string;
  operation: Operation;
};

export type UpdateNodeArgs<T extends NodeDataT> = {
  // This typing enforces to only pass keys that are part of the node type T which should make this method much safer to use.
  newData?: Omit<Partial<Exact<T>>, "label" | "uiState">;
  newName?: string;
  nodeId?: string;
};

type UpdateGroupArgs = {
  groupId: string;
  newName: string;
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function assertId(id: string | null): asserts id is string {
  if (id === null) {
    throw new Error(
      "GraphStore must be initialized and flowId + versionId  must exist. Use `setInitialState` before calling actions.",
    );
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function assertUpdateVersion(
  updateVersion: typeof updateFlowVersionGraph | null,
): asserts updateVersion is typeof updateFlowVersionGraph {
  if (updateVersion === null) {
    throw new Error(
      "GraphStore must be initialized with graph query mutations",
    );
  }
}

const getFlow = (id: string) => {
  return queryClient.getQueryData<ReturnType<typeof useFlow>["data"]>(
    flowKeys.detail(id),
  );
};

const EXPANDED_GROUP_Y_MARGIN = 17 as const;
const EXPANDED_GROUP_X_MARGIN = 13 as const;

export class GraphStore {
  constructor() {
    makeObservable(this, {
      updateFlowVersionGraphAndStore: false,
      debouncedUpdateSingleNode: false,

      flowId: observable,
      versionId: observable,
      nodes: observable,
      edges: observable,
      clickedEdgeIdStore: observable,
      nodeToDelete: observable,
      isDeleteNodeModalVisible: observable,
      isDeleteNodesModalVisible: observable,
      isCreateGroupModalVisible: observable,
      isRenameNodeModalVisible: observable,
      isDeleteGroupModalVisible: observable,
      isUngroupModalVisible: observable,
      groupToChangeId: observable,
      selectedDatasetId: observable,
      resultsOutdated: observable,
      lastRunDatasetId: observable,
      displayedErroredRow: observable,
      groups: observable,
      clipboard: observable,
      clickedVisibleEdgeId: computed,
      selectedNode: computed,
      selectedNodes: computed,
      multiSelectionSelectedNodes: computed,
      inputNode: computed,
      outputNode: computed,
      visibleNodes: computed,
      visibleEdges: computed,
      isValidGroup: computed,
      isValidCopyDeleteSelection: computed,
      edgesArray: computed,
      isMultiselecting: computed,
      groupsArray: computed,
      nodesArray: computed,
      integrationNodes: computed,
      databaseNodes: computed,

      // Getter functions dont need to be marked
      getNodeToIncomingEdge: false,
      getBranchNodesToDelete: false,
      getBranchesAndNodes: false,
      getMergeNode: false,
      findParentSplitNode: false,
      getAllChildren: false,
      getParents: false,
      canPasteClipboard: false,
      getOutgoingNode: false,

      updateGraphState: action,
      updateResults: action,
      setHistoryRunState: action,
      setInitialState: action,
      injectGraphUpdateCallback: action,
      setClickedVisibleEdgeId: action,
      setNodeToDelete: action,
      setIsDeleteNodeModalVisible: action,
      setIsDeleteNodesModalVisible: action,
      setIsCreateGroupModalVisible: action,
      setIsRenameNodeModalVisible: action,
      setIsDeleteGroupModalVisible: action,
      setIsUngroupModalVisible: action,
      setGroupToChangeId: action,
      addNode: action,
      deleteNode: action,
      updateNode: action,
      updateGroup: action,
      setSelectedNode: action,
      positionGraph: action,
      updateEdgesZIndex: action,
      updateSelectedDatasetId: action,
      setResultsOutdated: action,
      setLastRunDatasetId: action,
      readClipboard: action,
      copyToClipboard: action,
      copySelectedNodesToClipboard: action,
      pasteClipboard: action,
      createSubgraphAtEdge: action,
      clearHistoryRunState: action,
      selectErroredNode: action,
      selectOutputNode: action,
      createSimpleNode: action,
      createNewSplitNodeV2: action,
      addSplitBranchV2: action,
      deleteSplitBranchV2: action,
      deleteSplitNodeV2: action,
      setNodes: action,
      setEdges: action,
      setGroups: action,
      setDisplayedErroredRow: action,
      groupSelectedNodes: action,
      toggleGroupNode: action,
      deleteGroup: action,
      deleteMultipleConnectedNodes: action,
      deSelectNode: action,
      changeSplitBranchV2Order: action,
      graphHasChanged: action,
      updateNodeDimensions: action,
    });
  }

  // States
  groups: Map<string, GroupNode> = new Map();
  flowId: string | null = null;
  versionId: string | null = null;
  nodes: Map<string, BeMappedNode> = new Map();
  edges: Map<string, Edge> = new Map();
  /**
   * To store the clicked edge id. To read it use clickedVisibleEdgeId, since it could have been deleted by a BE fetch
   */
  clickedEdgeIdStore: string | null = null;
  isDeleteNodeModalVisible: boolean = false;
  nodeToDelete: string | null = null;
  selectedDatasetId: string | null = null;
  resultsOutdated: boolean = false;
  lastRunDatasetId: string | null = null;
  displayedErroredRow: ErroredRow | undefined = undefined;
  isDeleteGroupModalVisible: boolean = false;
  groupToChangeId: string | null = null;
  isCreateGroupModalVisible: boolean = false;
  isRenameNodeModalVisible: boolean = false;
  isUngroupModalVisible: boolean = false;
  isDeleteNodesModalVisible: boolean = false;
  clipboard: SubGraphClipboard = {
    nodes: [],
    edges: [],
    groups: [],
    workspaceId: "",
  };

  updateFlowVersionGraphAndStore: typeof updateFlowVersionGraph | null = null;
  debouncedUpdateSingleNode: DebouncedAndArgsSaveFunction<
    (args: {
      flowVersionId: string;
      nodeId: string;
      updatedNode: BeMappedNode;
      renamed: boolean;
    }) => Promise<FlowVersionT>
  > | null = null;

  updateGraphState = (graph: GraphT) => {
    assertId(this.versionId);
    // Nodes optimistaclaly updated from the pending request queue
    const optimisticUpdatedNodes = getOptimistacallyUpdatedNodes(
      this.versionId,
      graph.nodes,
    );
    // Apply the update that is currently in debounce
    const pendingNodeUpdateInEditorDebounce =
      this.debouncedUpdateSingleNode?.pending()
        ? this.debouncedUpdateSingleNode.lastInvokedArgs()?.[0].updatedNode
        : undefined;
    if (pendingNodeUpdateInEditorDebounce) {
      const indexOfPendingNode = optimisticUpdatedNodes.findIndex(
        (node) => node.id === pendingNodeUpdateInEditorDebounce.id,
      );
      const reverseMappedPendingNode = GraphTransform.nodeReverseMapper(
        pendingNodeUpdateInEditorDebounce,
      );
      if (indexOfPendingNode > -1 && reverseMappedPendingNode) {
        optimisticUpdatedNodes[indexOfPendingNode] = reverseMappedPendingNode;
      }
    }
    const { nodes, groups, edges } = GraphTransform.map({
      ...graph,
      nodes: optimisticUpdatedNodes,
    });
    // Nodes
    const newNodes = nodes.map((newNode) => {
      const nodeIsSelected = this.selectedNodes.some(
        (selectedNode) => selectedNode.id === newNode.id,
      );
      return {
        ...newNode,
        data: {
          ...newNode.data,
        },
        selected: nodeIsSelected,
        ...(this.nodes.get(newNode.id) && {
          height: this.nodes.get(newNode.id)?.height, // We need to keep the extends of the nodes, otherwise they are reset
          width: this.nodes.get(newNode.id)?.width,
        }),
      };
    });

    // Groups
    const newGroups = groups.map((newGroup) => {
      return {
        ...newGroup,
        data: {
          ...newGroup.data,
          ...(this.groups.get(newGroup.id) && {
            uiState: this.groups.get(newGroup.id)?.data.uiState,
          }),
        },
        ...(this.groups.get(newGroup.id) && {
          height: this.groups.get(newGroup.id)?.height, // We need to keep the extends of the groups, otherwise they are reset
          width: this.groups.get(newGroup.id)?.width,
        }),
      };
    });

    this.setNodes(newNodes);
    this.setEdges(edges);
    this.setGroups(newGroups);
    this.positionGraph();
    this.graphHasChanged();
  };

  // TODO AUTH-1903 check for a more performant way to update
  setInitialState = (params: {
    graph: GraphT;
    versionId: string;
    flowId: string;
  }) => {
    this.flowId = params.flowId;
    this.versionId = params.versionId;
    this.updateGraphState(params.graph);
  };

  injectGraphUpdateCallback = (callback: typeof updateFlowVersionGraph) => {
    this.updateFlowVersionGraphAndStore = async (
      args: FlowVersionGraphUpdate,
    ) => {
      const flowVersion = await callback(args);
      if (
        // the ? is added so that mocking in tests is easier
        flowVersion?.graph
      ) {
        this.updateGraphState(flowVersion.graph);
      }
      return flowVersion;
    };
    this.debouncedUpdateSingleNode = debounceAndSaveArgs(
      async ({
        flowVersionId,
        nodeId,
        updatedNode,
        renamed,
      }: {
        flowVersionId: string;
        nodeId: string;
        updatedNode: BeMappedNode;
        renamed: boolean;
      }) => {
        return await callback({
          flowVersionId,
          nodes: { [nodeId]: updatedNode },
          edges: {},
          groups: {},
          operation: renamed
            ? updatedNode.type === NODE_TYPE.SPLIT_BRANCH_NODE_V2
              ? Operation.RENAME_BRANCH
              : Operation.RENAME_NODE
            : Operation.EDIT_NODE,
        });
      },
      UPDATE_DEBOUNCE_WAIT_TIME,
    );
  };

  get edgesArray() {
    return Array.from(this.edges.values());
  }

  get groupsArray() {
    return Array.from(this.groups.values());
  }

  get nodesArray() {
    return Array.from(this.nodes.values());
  }

  get integrationNodes() {
    return this.nodesArray.filter(isIntegrationNodes);
  }

  get databaseNodes() {
    return this.nodesArray.filter(
      (node) => node.type === NODE_TYPE.SQL_DATABASE_CONNECTION_NODE,
    );
  }

  get visibleNodes(): NodeT[] {
    // Unset the parentNode for all the nodes (this is necessary because a group node might have just been deleted)
    this.nodes.forEach((node) => {
      node.parentNode = undefined;
    });

    // If there are no groups visibleNodes are equal to nodes
    if (this.groupsArray.length === 0) {
      return this.nodesArray;
    }
    const collapsedGroups = this.groupsArray.filter(
      (group) => !group.data.uiState.expanded,
    );
    const extendedGroups = this.groupsArray.filter(
      (group) => group.data.uiState.expanded,
    );
    // add two separators for every extended group
    const activeSeparatorNodes: GroupSeparatorNode[] = extendedGroups.flatMap(
      (group) => [
        group.data.uiState.preSeparatorNode,
        group.data.uiState.postSeparatorNode,
      ],
    );

    // Remove all nodes which are children of a collapsed group
    const nodesInAGroup = collapsedGroups.flatMap((g) => g.data.childrenIDs);
    const newNodes = this.nodesArray.filter(
      (n) => !nodesInAGroup.includes(n.id),
    );

    // Set the parentNode of the children nodes to the group id. This ensures that the children nodes are rendered relative to the group node
    // Todo check if this also changes the underlying node observable
    extendedGroups.forEach((group) => {
      runInAction(() => {
        group.data.childrenIDs.forEach((nodeId) => {
          const childrenNode = newNodes.find((n) => n.id === nodeId);
          if (childrenNode) {
            childrenNode.parentNode = group.id;
          }
        });
        group.data.uiState.preSeparatorNode.parentNode = group.id;
        group.data.uiState.postSeparatorNode.parentNode = group.id;
      });
    });
    return [...this.groupsArray, ...activeSeparatorNodes, ...newNodes];
  }

  get visibleEdges() {
    const collapsedGroups = this.groupsArray.filter(
      (group) => !group.data.uiState.expanded,
    );
    const expandedGroups = this.groupsArray.filter(
      (group) => group.data.uiState.expanded,
    );
    // If there are groups, then visibleEdges are equal to edges
    if (collapsedGroups.length === 0 && expandedGroups.length === 0) {
      return this.edgesArray;
    }
    // Get all edges between the visible nodes (i.e. all edges except the edges between the nodes in the collapsed group)
    let currentEdges = [
      ...getEdgesInSubgraph(this.edgesArray, this.visibleNodes),
      // Source and sink edges to collapsed nodes are added to use them for reference for nodes before
      // and after collapsed nodes. They will be replaced.
      ...this.edgesArray.filter((edge) =>
        collapsedGroups.some(
          (group) =>
            group.data.sourceChildId === edge.target ||
            group.data.sinkChildId === edge.source,
        ),
      ),
    ];

    expandedGroups.forEach((group) => {
      const incomingOriginalEdge = this.edgesArray.find(
        (edge) => edge.target === group.data.sourceChildId,
      );
      const incomingCurrentEdge = currentEdges.find(
        (edge) => edge.target === group.data.sourceChildId,
      );
      const outgoingOriginalEdge = this.edgesArray.find(
        (edge) => edge.source === group.data.sinkChildId,
      );
      const outgoingCurrentEdge = currentEdges.find(
        (edge) => edge.source === group.data.sinkChildId,
      );
      // find previous and next node
      const preSourceNodeId = incomingCurrentEdge?.source;
      const postSinkNodeId = outgoingCurrentEdge?.target;

      if (
        preSourceNodeId &&
        postSinkNodeId &&
        incomingOriginalEdge &&
        incomingCurrentEdge &&
        outgoingOriginalEdge &&
        outgoingCurrentEdge
      ) {
        // remove current replaced edges
        currentEdges = currentEdges.filter(
          (edge) =>
            edge.id !== incomingCurrentEdge.id &&
            edge.id !== outgoingCurrentEdge.id,
        );
        const toPreSeparatorEdge = getDefaultGroupEdge({
          id: group.data.uiState.preSeparatorNode.data.incomingEdgeId,
          sourceId: preSourceNodeId,
          targetId: group.data.uiState.preSeparatorNode.id,
          originalEdgeId: incomingOriginalEdge.id,
          insideGroup: false,
        });
        const fromPreSeparatorEdge = getDefaultGroupEdge({
          id: group.data.uiState.preSeparatorNode.data.outgoingEdgeId,
          sourceId: group.data.uiState.preSeparatorNode.id,
          targetId: group.data.sourceChildId,
          originalEdgeId: incomingOriginalEdge.id,
          insideGroup: true,
        });
        const toPostSeparatorEdge = getDefaultGroupEdge({
          id: group.data.uiState.postSeparatorNode.data.incomingEdgeId,
          sourceId: group.data.sinkChildId,
          targetId: group.data.uiState.postSeparatorNode.id,
          originalEdgeId: outgoingOriginalEdge.id,
          insideGroup: true,
        });
        const fromPostSeparatorEdge = getDefaultGroupEdge({
          id: group.data.uiState.postSeparatorNode.data.outgoingEdgeId,
          sourceId: group.data.uiState.postSeparatorNode.id,
          targetId: postSinkNodeId,
          originalEdgeId: outgoingOriginalEdge.id,
          insideGroup: false,
        });
        currentEdges.push(
          toPreSeparatorEdge,
          fromPreSeparatorEdge,
          toPostSeparatorEdge,
          fromPostSeparatorEdge,
        );
      }
    });

    // Create two new edges for each collapsed group: one from the node before the group to the group node, one from the group node to the node after the group
    // We need to apply some extra logic here because it might be that the node behind a group is the children of a group node itself.
    collapsedGroups.forEach((group) => {
      const incomingOriginalEdge = this.edgesArray.find(
        (edge) => edge.target === group.data.sourceChildId,
      );
      const incomingCurrentEdge = currentEdges.find(
        (edge) => edge.target === group.data.sourceChildId,
      );
      const outgoingOriginalEdge = this.edgesArray.find(
        (edge) => edge.source === group.data.sinkChildId,
      );
      const outgoingCurrentEdge = currentEdges.find(
        (edge) => edge.source === group.data.sinkChildId,
      );
      if (
        incomingOriginalEdge &&
        incomingCurrentEdge &&
        outgoingOriginalEdge &&
        outgoingCurrentEdge
      ) {
        // remove current replaced edges
        currentEdges = currentEdges.filter(
          (edge) =>
            edge.source !== group.data.sinkChildId &&
            edge.target !== group.data.sourceChildId,
        );
        const nodeBeforeGroupId = incomingCurrentEdge.source;
        const newIncomingEdge = getDefaultGroupEdge({
          id: group.data.uiState.incomingEdgeId,
          sourceId: nodeBeforeGroupId,
          targetId: group.id,
          originalEdgeId: incomingOriginalEdge.id,
          insideGroup: false,
        });

        const nodeAfterGroup = outgoingCurrentEdge.target;
        const newOutgoingEdge = getDefaultGroupEdge({
          id: group.data.uiState.outgoingEdgeId,
          sourceId: group.id,
          targetId: nodeAfterGroup,
          originalEdgeId: outgoingOriginalEdge.id,
          insideGroup: true,
        });

        currentEdges.push(newIncomingEdge, newOutgoingEdge);
      }
    });
    return currentEdges;
  }

  get clickedVisibleEdgeId() {
    return this.visibleEdges.find(
      (vEdge) => vEdge.id === this.clickedEdgeIdStore,
    )?.id;
  }

  /**
   * The nodes which are currently selected
   */
  get selectedNodes() {
    return this.nodesArray.filter((node) => node.selected);
  }

  /**
   * Is the node which is currently focused/being edited. Is undefined if more than one node is selected.
   */
  get selectedNode() {
    // Branch nodes can have selected set to true (to show the blue outline when the parent split node is selected) but they cannot actually be the "selected node"
    const selectedNodesWithoutBranches = this.selectedNodes.filter(
      (node) =>
        node.type !== NODE_TYPE.SPLIT_BRANCH_NODE_V2 &&
        node.type !== NODE_TYPE.SPLIT_MERGE_NODE,
    );
    if (selectedNodesWithoutBranches.length === 1) {
      return selectedNodesWithoutBranches[0];
    }
    return undefined;
  }

  /**
   * The nodes which are part of the current multi-selection, if there exists one. Otherwise undefined.
   */
  get multiSelectionSelectedNodes() {
    if (this.selectedNodes.length > 1 && !this.selectedNode) {
      return this.selectedNodes;
    }
    return undefined;
  }

  get isMultiselecting() {
    return this.multiSelectionSelectedNodes !== undefined;
  }

  /**
   * Check whether the current multi-selected nodes are a valid group
   */
  get isValidCopyDeleteSelection():
    | { valid: true }
    | { valid: false; reason: InvalidCopySelectionReason } {
    if (
      this.multiSelectionSelectedNodes === undefined ||
      this.multiSelectionSelectedNodes.length <= 1
    ) {
      return { valid: false, reason: "NotEnoughNodes" };
    }
    if (
      this.multiSelectionSelectedNodes.some(
        (node) =>
          // Check no input or output node is included
          node.type === NODE_TYPE.INPUT_NODE ||
          node.type === NODE_TYPE.OUTPUT_NODE,
      )
    ) {
      return { valid: false, reason: "NoInputOutput" };
    }
    if (
      !graphHasRootAndIsConnected(
        getEdgesInSubgraph(this.edgesArray, this.multiSelectionSelectedNodes),
        this.multiSelectionSelectedNodes,
      )
    ) {
      return { valid: false, reason: "NotConnected" };
    }
    // If there are split nodes in the selection, check if all of their children are also selected
    const splitNodeChildrenIDs: BeMappedNode[] = [];
    this.multiSelectionSelectedNodes.forEach((node) => {
      if (node.type === NODE_TYPE.SPLIT_NODE_V2) {
        splitNodeChildrenIDs.push(...this.getAllChildren(node as SplitNodeV2));
      }
    });
    if (
      splitNodeChildrenIDs.some(
        (id) => !this.multiSelectionSelectedNodes?.includes(id),
      )
    ) {
      return { valid: false, reason: "NotAllSplitNodeChildren" };
    }

    return { valid: true };
  }

  /**
   * Check whether the current multi-selected nodes are a valid group
   */
  get isValidGroup():
    | { valid: true }
    | { valid: false; reason: InvalidGroupReason } {
    if (
      this.multiSelectionSelectedNodes === undefined ||
      this.multiSelectionSelectedNodes.length <= 1
    ) {
      return { valid: false, reason: "NotEnoughNodes" };
    }

    const groupChildrenIds = this.groupsArray.flatMap(
      (group) => group.data.childrenIDs,
    );
    const selectionIds = this.multiSelectionSelectedNodes.map(
      (node) => node.id,
    );

    if (selectionIds.some((id) => groupChildrenIds.includes(id))) {
      return { valid: false, reason: "NoGroups" };
    }

    return this.isValidCopyDeleteSelection;
  }

  get inputNode() {
    return this.nodesArray.find((node) => node.type === NODE_TYPE.INPUT_NODE);
  }

  get outputNode() {
    return this.nodesArray.find((node) => node.type === NODE_TYPE.OUTPUT_NODE);
  }

  // Getters
  getNodeToIncomingEdge = (edgeId: string) => {
    const edge = this.edges.get(edgeId);
    if (edge) {
      return this.nodes.get(edge.target);
    }
  };

  /**
   * Get an outgoing node from the provided node id. Returns undefined if there is none.
   */
  getOutgoingNode = (nodeId: string) => {
    const node = this.nodes.get(nodeId);
    if (!node) return undefined;
    const outgoingNodes = getOutgoers(node, this.nodesArray, this.edgesArray);
    return outgoingNodes.at(0);
  };

  getBranchNodesToDelete = (deletedBranchId: string): BeMappedNode[] => {
    const branchNodes = new Set<BeMappedNode>();
    const currNodes = this.nodesArray;
    const currEdges = this.edgesArray;
    const firstNode = this.nodes.get(deletedBranchId);
    const parentSplitNode = getSelectableParentNode(
      firstNode,
      currNodes,
      currEdges,
    );
    if (firstNode && parentSplitNode) {
      findBranchNodes(
        firstNode,
        currNodes,
        currEdges,
        branchNodes,
        parentSplitNode as SplitNodeV2,
        true,
      );
    }
    return Array.from(branchNodes);
  };

  getBranchesAndNodes = (
    splitNode: SplitNodeV2,
    excludeNestedMergeNodes: boolean = false,
  ) => {
    return findBranchesAndNodes(
      splitNode,
      this.nodesArray,
      this.edgesArray,
      excludeNestedMergeNodes,
    );
  };

  getMergeNode = (splitNode: SplitNodeV2) => {
    return findSplitMergeNode(
      splitNode as SplitNodeV2,
      this.nodesArray,
      this.edgesArray,
    );
  };

  findParentSplitNode = (nodeId: string) => {
    const node = this.nodes.get(nodeId);
    if (!this.nodes.has(nodeId)) {
      return PROVIDED_ID_NODE_A_NODE_ID;
    }
    if (node) {
      return getSelectableParentNode(node, this.nodesArray, this.edgesArray);
    }
  };

  /**
   * Gets all nodes on branches of the split node as well as its merge node
   */
  getAllChildren = (splitNode: SplitNodeV2) => {
    const allNodesOnBranches = this.getBranchesAndNodes(splitNode).flat();
    const mergeNode = this.getMergeNode(splitNode);
    // A merge node should always be found, but to satisfy typescript the return is split
    if (mergeNode) return [...allNodesOnBranches, mergeNode];
    else return allNodesOnBranches;
  };

  /**
   * Gets all nodes that are direct parents of the provided node
   */
  getParents = (nodeId: string) => {
    const node = this.nodes.get(nodeId);
    if (node) {
      return getIncomers(node, this.nodesArray, this.edgesArray);
    }
  };

  // Actions
  setClickedVisibleEdgeId = (clickedVisibleEdgeId: string | null) => {
    if (clickedVisibleEdgeId) {
      this.setSelectedNode(null);
    }
    this.clickedEdgeIdStore = clickedVisibleEdgeId;
  };

  setNodeToDelete = (nodeToDelete: string | null) => {
    this.nodeToDelete = nodeToDelete;
  };

  setIsDeleteNodeModalVisible = (isDeleteNodeModalVisible: boolean) => {
    this.isDeleteNodeModalVisible = isDeleteNodeModalVisible;
  };

  setIsDeleteNodesModalVisible = (isVisible: boolean) => {
    this.isDeleteNodesModalVisible = isVisible;
  };

  setResultsOutdated = (outdated: boolean) => {
    this.resultsOutdated = outdated;
  };

  deSelectNode = (nodeId: string) => {
    const nodeToDeselect = this.nodes.get(nodeId);
    if (nodeToDeselect) {
      const nodeIDs = new Set<string>([nodeId]);
      if (nodeToDeselect.type === NODE_TYPE.SPLIT_NODE_V2) {
        this.getAllChildren(nodeToDeselect as SplitNodeV2).forEach(
          (childNode) => nodeIDs.add(childNode.id),
        );
      }
      this.setNodes(
        this.nodesArray.map((node) => {
          if (nodeIDs.has(node.id)) {
            return { ...node, selected: false };
          }
          return node;
        }),
      );
    }
  };

  setSelectedNode = (
    newSelectedNodeId: string | undefined | null,
    multiSelect: boolean = false,
  ) => {
    const selectedNodeSetIds = new Set<string>();
    let newSelectedNode: BeMappedNode | undefined = undefined;
    if (newSelectedNodeId) {
      selectedNodeSetIds.add(newSelectedNodeId);
      newSelectedNode = this.nodes.get(newSelectedNodeId);
    }

    if (newSelectedNode?.type === NODE_TYPE.SPLIT_BRANCH_NODE_V2) {
      // We want to select all nodes in a split node group (split node and branches)
      // So we have made split branches selectable through UI
      // Now whenever we select either split branches or split node itself,
      // it would search for split node (the parent) and add all nodes in the group to selectedNodeSetIds
      newSelectedNode = getSelectableParentNode(
        newSelectedNode,
        this.nodesArray,
        this.edgesArray,
      );
    }
    if (newSelectedNode) {
      // Select the split nodes children when a multi selection is started
      if (this.selectedNode?.type === NODE_TYPE.SPLIT_NODE_V2 && multiSelect) {
        this.getAllChildren(this.selectedNode as SplitNodeV2).forEach((node) =>
          selectedNodeSetIds.add(node.id),
        );
      }
      if (newSelectedNode.type === NODE_TYPE.SPLIT_NODE_V2) {
        // Select split node children when it is added to the multi selection
        if (multiSelect) {
          this.getAllChildren(newSelectedNode as SplitNodeV2).forEach((node) =>
            selectedNodeSetIds.add(node.id),
          );
        } // otherwise select only its branch node
        else {
          getOutgoers(
            newSelectedNode,
            this.nodesArray,
            this.edgesArray,
          ).forEach((node) => selectedNodeSetIds.add(node.id));
        }
      }
      selectedNodeSetIds.add(newSelectedNode.id);
    }
    this.setNodes(
      this.nodesArray.map((node: BeMappedNode) => {
        if (selectedNodeSetIds.has(node.id)) {
          return { ...node, selected: true };
        } else if (multiSelect) {
          return node;
        } else {
          return { ...node, selected: false };
        }
      }),
    );
    if (newSelectedNode) {
      this.setClickedVisibleEdgeId(null);
    }
  };

  updateResults = (
    nodesArray: BeMappedNode[],
    result: FlowRunnerResultV2 | DecisionHistoryRecordV2,
  ) => {
    assertId(this.versionId);
    setResults(nodesArray, this.edgesArray, result, this.versionId);
    this.setResultsOutdated(false);
    this.updateEdgesZIndex();
  };

  setHistoryRunState = (results: DecisionHistoryRecordV2) => {
    assertId(this.versionId);
    this.updateResults(this.nodesArray, results);
  };

  clearHistoryRunState = () => {
    const decisionHistoryStateIsActive = this.nodesArray.some((node) => {
      assertId(this.versionId);
      const runState = getNodeRunState(node.id, this.versionId);
      return runState && isHistoricalRunState(runState);
    });
    if (decisionHistoryStateIsActive) {
      assertId(this.versionId);
      clearRunStates(this.nodesArray, this.versionId);
      this.updateEdgesZIndex();
    }
  };
  selectErroredNode = () => {
    const erroredNode = this.nodesArray.find((node: BeMappedNode) => {
      assertId(this.versionId);
      const runState = getNodeRunState(node.id, this.versionId);
      return (
        runState?.type === "test-error" || runState?.type === "historical-error"
      );
    });
    if (erroredNode) {
      this.setSelectedNode(erroredNode.id);
      // If the errored node is in a group expand that group
      const parentGroup = this.groupsArray.find((group) =>
        group.data.childrenIDs.includes(erroredNode.id),
      );
      if (parentGroup && !parentGroup.data.uiState.expanded) {
        this.toggleGroupNode(parentGroup.id);
      }
    }
    return erroredNode;
  };

  selectOutputNode = () => {
    this.setSelectedNode(this.outputNode?.id);
  };

  createSimpleNode = async (atVisibleEdgeId: string, node: BeMappedNode) => {
    assertId(this.versionId);

    const newNode = { ...node, id: uuidV4() };

    await this.createSubgraphAtEdge({
      nodesToAdd: [newNode],
      edgesToAdd: [],
      atVisibleEdgeId,
      operation: Operation.CREATE_NODE,
    });

    return newNode.id;
  };

  createNewSplitNodeV2 = async (atVisibleEdgeId: string) => {
    assertId(this.versionId);

    // Create the new nodes
    const newSplitNode = getDefaultSplitNodeV2({});
    const newSplitBranchNode = getDefaultSplitBranchNodeV2({
      name: "Branch 1",
    });
    const newSplitFallbackBranchNode = getDefaultSplitBranchNodeV2({
      name: "Default Branch",
    });
    const newSplitMergeNode = getDefaultSplitMergeNode({});
    const nodesToAdd = [
      newSplitNode,
      newSplitBranchNode,
      newSplitFallbackBranchNode,
      newSplitMergeNode,
    ];

    const edgesToAdd: Edge[] = [
      getDefaultSplitEdge({
        sourceId: newSplitNode.id,
        targetId: newSplitBranchNode.id,
      }),
      getDefaultSplitEdge({
        sourceId: newSplitNode.id,
        targetId: newSplitFallbackBranchNode.id,
      }),
      getDefaultFlowEdge({
        sourceId: newSplitBranchNode.id,
        targetId: newSplitMergeNode.id,
      }),
      getDefaultFlowEdge({
        sourceId: newSplitFallbackBranchNode.id,
        targetId: newSplitMergeNode.id,
      }),
    ];

    newSplitNode.data = {
      ...newSplitNode.data,
      default_edge_id: edgesToAdd[1].id,
      branches: [
        getNewSplitBranch(edgesToAdd[0].id),
        getDefaultBranch(edgesToAdd[1].id),
      ],
    };

    await this.createSubgraphAtEdge({
      nodesToAdd,
      edgesToAdd,
      atVisibleEdgeId,
      operation: Operation.CREATE_SPLIT_NODE,
    });

    return newSplitNode.id;
  };

  addNode = async (params: AddNodeParamsT) => {
    let newNodeId: string | undefined;
    if (this.clickedVisibleEdgeId) {
      switch (params.nodeType) {
        case NODE_TYPE.CODE_NODE: {
          newNodeId = await this.createSimpleNode(
            this.clickedVisibleEdgeId,
            getDefaultCodeNode({}),
          );
          break;
        }
        case NODE_TYPE.SPLIT_NODE_V2: {
          newNodeId = await this.createNewSplitNodeV2(
            this.clickedVisibleEdgeId,
          );
          break;
        }
        case NODE_TYPE.INTEGRATION_NODE: {
          const integrationResource = getDefaultResource(
            params.providerResource,
            params.connectionId,
            params.resourceConfigId,
          );
          newNodeId = await this.createSimpleNode(
            this.clickedVisibleEdgeId,
            getDefaultIntegrationNode({ integrationResource }),
          );
          break;
        }
        case NODE_TYPE.MANIFEST_CONNECTION_NODE: {
          const manifestIntegrationResource =
            getDefaultManifestProviderResource(
              params.providerResource,
              params.connectionId,
              params.resourceConfigId,
              params.manifest,
            );
          newNodeId = await this.createSimpleNode(
            this.clickedVisibleEdgeId,
            getDefaultManifestConnectionNode({ manifestIntegrationResource }),
          );
          break;
        }
        case NODE_TYPE.ML_NODE: {
          newNodeId = await this.createSimpleNode(
            this.clickedVisibleEdgeId,
            getDefaultMlNode({}),
          );
          break;
        }
        case NODE_TYPE.RULE_NODE_V2: {
          newNodeId = await this.createSimpleNode(
            this.clickedVisibleEdgeId,
            getDefaultRuleNodeV2({
              defaultEffects: [{ id: uuidV4(), value: "None", target: "" }],
            }),
          );
          break;
        }
        case NODE_TYPE.DECISION_TABLE_NODE: {
          newNodeId = await this.createSimpleNode(
            this.clickedVisibleEdgeId,
            getDefaultDecisionTableNode({}),
          );
          break;
        }
        case NODE_TYPE.ASSIGNMENT_NODE: {
          newNodeId = await this.createSimpleNode(
            this.clickedVisibleEdgeId,
            getDefaultAssignmentNode({}),
          );
          break;
        }
        case NODE_TYPE.SCORECARD_NODE: {
          newNodeId = await this.createSimpleNode(
            this.clickedVisibleEdgeId,
            getDefaultScorecardNode({}),
          );
          break;
        }
        case NODE_TYPE.CUSTOM_CONNECTION_NODE: {
          newNodeId = await this.createSimpleNode(
            this.clickedVisibleEdgeId,
            getDefaultCustomConnectionNode({
              providerResource: params.providerResource,
              connectionId: params.connectionId,
              resourceConfigId: params.resourceConfigId,
              mediaKey: params.mediaKey,
            }),
          );
          break;
        }
        case NODE_TYPE.WEBHOOK_CONNECTION_NODE: {
          newNodeId = await this.createSimpleNode(
            this.clickedVisibleEdgeId,
            getDefaultInboundWebhookConnectionNode({
              providerResource: params.providerResource,
              connectionId: params.connectionId,
              resourceConfigId: params.resourceConfigId,
              mediaKey: params.mediaKey,
            }),
          );
          break;
        }
        case NODE_TYPE.SQL_DATABASE_CONNECTION_NODE: {
          newNodeId = await this.createSimpleNode(
            this.clickedVisibleEdgeId,
            getDefaultDatabaseConnectionNode({
              providerResource: params.providerResource,
              connectionId: params.connectionId,
              resourceConfigId: params.resourceConfigId,
            }),
          );
          break;
        }
        case NODE_TYPE.FLOW_NODE: {
          assertId(this.flowId);
          const flow = getFlow(this.flowId);
          if (flow === undefined || singleFlowIsChildFlow(flow)) {
            logger.error("Cannot create a Flow Node inside of a child flow");
          } else {
            newNodeId = await this.createSimpleNode(
              this.clickedVisibleEdgeId,
              getDefaultFlowNode({}),
            );
          }
          break;
        }
        case NODE_TYPE.LOOP_NODE: {
          assertId(this.flowId);
          const flow = getFlow(this.flowId);
          if (flow === undefined || singleFlowIsChildFlow(flow)) {
            logger.error("Cannot create a Flow Node inside of a child flow");
          } else {
            newNodeId = await this.createSimpleNode(
              this.clickedVisibleEdgeId,
              getDefaultLoopNode({}),
            );
          }
          break;
        }
        case NODE_TYPE.REVIEW_CONNECTION_NODE: {
          newNodeId = await this.createSimpleNode(
            this.clickedVisibleEdgeId,
            getDefaultManualReviewNode({
              providerResource: params.providerResource,
              connection_id: params.connectionId,
              resource_config_id: params.resourceConfigId,
              highlights: [],
              response_form: { description: "", fields: [] },
            }),
          );
          break;
        }
        default:
          assertUnreachable(params);
          break;
      }
      if (newNodeId) {
        this.setSelectedNode(newNodeId);
        return newNodeId;
      }
    }
  };

  updateSelectedDatasetId = (selectedDatasetId: string | null) => {
    this.selectedDatasetId = selectedDatasetId;
    this.setResultsOutdated(true);
  };

  /**
   * Debounced Updates either the node with the given node id or the selected node.
   * New data will be merged on the top level with the exisiting data object.
   * A new name has to be passed explicitly and will be disrefarded if part of the new data object.
   */
  updateNode = <T extends NodeDataT>({
    newData,
    newName,
    nodeId,
  }: UpdateNodeArgs<T>) => {
    const nodeToChange = nodeId ? this.nodes.get(nodeId) : this.selectedNode;
    if (nodeToChange) {
      const newNodes = this.nodesArray.map((node: BeMappedNode) => {
        if (node.id === nodeToChange?.id) {
          assertId(this.versionId);
          if (this.debouncedUpdateSingleNode === null) {
            throw new Error(
              "GraphStore debounce update query mutation must be provided",
            );
          }

          const updatedNode = {
            ...node,
            data: {
              ...node.data,
              ...newData,
              // Allow editing the label only by explicitly passing a newName, NOT by including it in the updated node data
              // This is a safeguard against node editors saving the complete node data object, despite the label not being
              // part of their form.
              label: newName !== undefined ? newName : node.data.label,
            },
          };
          this.debouncedUpdateSingleNode({
            flowVersionId: this.versionId,
            nodeId: updatedNode.id,
            updatedNode: updatedNode,
            renamed: newName !== undefined,
          });
          return updatedNode;
        }
        return node;
      });
      this.setNodes(newNodes);
    }
  };

  addSplitBranchV2 = async (currentBranches: number) => {
    assertId(this.versionId);
    assertUpdateVersion(this.updateFlowVersionGraphAndStore);

    if (
      this.selectedNode &&
      this.selectedNode.type === NODE_TYPE.SPLIT_NODE_V2
    ) {
      const splitNode = this.selectedNode as SplitNodeV2;

      const splitMergeNode = this.getMergeNode(splitNode);
      if (splitMergeNode) {
        const newBranchNode = getDefaultSplitBranchNodeV2({
          name: `Branch ${currentBranches + 1}`,
        });

        const incomingEdgeToNewBranchNode = getDefaultSplitEdge({
          sourceId: splitNode.id,
          targetId: newBranchNode.id,
        });
        const outgoingEdgeToNewBranchNode = getDefaultFlowEdge({
          sourceId: newBranchNode.id,
          targetId: splitMergeNode.id,
        });
        const newBranch = getNewSplitBranch(incomingEdgeToNewBranchNode.id);

        const flatCopiedOriginalBranches = [...splitNode.data.branches];
        flatCopiedOriginalBranches.splice(-1, 0, newBranch);

        const updatedSplitNode: SplitNodeV2 = {
          ...splitNode,
          data: {
            ...splitNode.data,
            branches: flatCopiedOriginalBranches,
          },
        };

        const parentGroup = getParentGroup(this.groups, splitNode.id);
        const updatedGroup = parentGroup
          ? getUpdatedGroup({
              group: parentGroup,
              updatedChildrenIds: [
                ...parentGroup.data.childrenIDs,
                newBranchNode.id,
              ],
            })
          : null;
        await this.updateFlowVersionGraphAndStore({
          flowVersionId: this.versionId,
          nodes: {
            [newBranchNode.id]: newBranchNode,
            [splitNode.id]: updatedSplitNode,
          },
          edges: {
            [incomingEdgeToNewBranchNode.id]: incomingEdgeToNewBranchNode,
            [outgoingEdgeToNewBranchNode.id]: outgoingEdgeToNewBranchNode,
          },
          groups: {
            ...(parentGroup && { [parentGroup.id]: updatedGroup }),
          },
          operation: Operation.CREATE_BRANCH,
        });
        return newBranch;
      } else {
        logger.error(TAG, "Split merge Node not found");
      }
    } else {
      logger.error(TAG, "No active node");
    }
  };

  changeSplitBranchV2Order = async (
    branchIndexToMove: number,
    branchIndexToInsertAt: number,
  ) => {
    assertId(this.versionId);
    assertUpdateVersion(this.updateFlowVersionGraphAndStore);

    if (
      this.selectedNode &&
      this.selectedNode.type === NODE_TYPE.SPLIT_NODE_V2
    ) {
      const splitNode = this.selectedNode as SplitNodeV2;
      const newBranches = [...splitNode.data.branches];
      const branchToMove = newBranches[branchIndexToMove];
      newBranches.splice(branchIndexToMove, 1);
      newBranches.splice(branchIndexToInsertAt, 0, branchToMove);
      const updatedSplitNodeDate: SplitNodeV2DataT = {
        ...splitNode.data,
        branches: newBranches,
      };
      this.updateNode<SplitNodeV2DataT>({
        newData: updatedSplitNodeDate,
        nodeId: splitNode.id,
      });
      this.positionGraph();
    }
  };

  deleteSplitBranchV2 = async (edgeToDeleteBranchId: string) => {
    assertId(this.versionId);
    assertUpdateVersion(this.updateFlowVersionGraphAndStore);
    if (
      this.selectedNode &&
      this.selectedNode.type === NODE_TYPE.SPLIT_NODE_V2
    ) {
      const splitNode = this.selectedNode as SplitNodeV2;
      const currEdges = this.edgesArray;
      const nodesOnSameBranch = new Set<BeMappedNode>();
      const edgeToDelete = this.edges.get(edgeToDeleteBranchId);
      const deletedNode = edgeToDelete && this.nodes.get(edgeToDelete?.target);

      if (deletedNode) {
        findBranchNodes(
          deletedNode,
          this.nodesArray,
          currEdges,
          nodesOnSameBranch,
          splitNode,
        );
        const deletedNodeIds = new Set(
          Array.from(nodesOnSameBranch).map((node: BeMappedNode) => node.id),
        );

        const edgesToDelete = currEdges.filter(
          (edge: Edge) =>
            deletedNodeIds.has(edge.source) || deletedNodeIds.has(edge.target),
        );
        const edgesToDeleteIds = new Set(
          edgesToDelete.map((edge: Edge) => edge.id),
        );
        const updatedNode: BeMappedNode = {
          ...splitNode,
          data: {
            ...splitNode.data,
            branches: splitNode.data.branches.filter(
              (branch: SplitBranch) => !edgesToDeleteIds.has(branch.edge_id),
            ),
          },
        };

        const parentGroup = getParentGroup(this.groups, deletedNode.id);

        const updatedChildrenIds = parentGroup
          ? parentGroup.data.childrenIDs.filter((childId) =>
              Array.from(deletedNodeIds.values()).every(
                (deletedNodeId) => deletedNodeId !== childId,
              ),
            )
          : [];

        const updatedGroup = parentGroup
          ? getUpdatedGroup({ group: parentGroup, updatedChildrenIds })
          : null;

        const groupsToDelete: GroupNode[] = [];
        for (const [_, group] of this.groups) {
          if (group.data.childrenIDs.every((id) => deletedNodeIds.has(id))) {
            groupsToDelete.push(group);
          }
        }

        await this.updateFlowVersionGraphAndStore({
          flowVersionId: this.versionId,
          nodes: {
            ...Array.from(deletedNodeIds).reduce(
              (acc, id) => ({ ...acc, [id]: null }),
              {},
            ),
            [updatedNode.id]: updatedNode,
          },
          edges: Object.fromEntries(
            edgesToDelete.map((edge) => [edge.id, null]),
          ),
          groups: {
            ...(parentGroup && { [parentGroup.id]: updatedGroup }),
            ...Object.fromEntries(
              groupsToDelete.map((group) => [group.id, null]),
            ),
          },
          operation: Operation.DELETE_BRANCH,
        });
      }
    }
  };

  deleteNode = async (nodeToDelete: NodeT, isCutting: boolean) => {
    if (nodeToDelete.type === NODE_TYPE.GROUP_NODE) {
      const childrenNodes = (nodeToDelete as GroupNode).data.childrenIDs
        .map((id) => this.nodes.get(id))
        .filter((node) => node !== undefined) as BeMappedNode[];
      if (
        childrenNodes.length ===
        (nodeToDelete as GroupNode).data.childrenIDs.length
      ) {
        await this.deleteMultipleConnectedNodes(
          childrenNodes,
          isCutting ? Operation.CUT_GROUP : Operation.DELETE_GROUP_WITH_NODES,
        );
      }
    } else if (nodeToDelete.type === NODE_TYPE.SPLIT_NODE_V2) {
      await this.deleteSplitNodeV2(nodeToDelete as SplitNodeV2);
    } else {
      await this.deleteMultipleConnectedNodes(
        [nodeToDelete as BeMappedNode],
        isCutting ? Operation.CUT_NODE : Operation.DELETE_NODE,
        {
          selectIncomingNode: true,
        },
      );
    }
  };

  deleteSplitNodeV2 = async (nodeToDelete: SplitNodeV2) => {
    const splitMergeNode = this.getMergeNode(nodeToDelete);
    if (splitMergeNode) {
      // Delete the split node, the nodes on all the branches and the merge node
      const nodesToDelete = [
        nodeToDelete,
        ...this.getAllChildren(nodeToDelete),
      ];
      await this.deleteMultipleConnectedNodes(
        nodesToDelete,
        Operation.DELETE_SPLIT_NODE,
        {
          selectIncomingNode: true,
        },
      );
    } else {
      logger.error(TAG, "Split merge Node not found");
    }
  };

  setNodes = (nodes: BeMappedNode[]) => {
    const newMap = new Map();
    nodes.forEach((node) => {
      newMap.set(node.id, node);
    });
    this.nodes = newMap;
  };

  setEdges = (edges: Edge[]) => {
    const newMap = new Map();
    edges.forEach((edge) => {
      newMap.set(edge.id, edge);
    });
    this.edges = newMap;
  };

  setGroups = (groups: GroupNode[]) => {
    const newMap = new Map();
    groups.forEach((group) => {
      newMap.set(group.id, group);
    });

    this.groups = newMap;
  };

  updateNodeDimensions = (newDimensions: NodeDimensionChange[]) => {
    let changed = false;
    newDimensions.forEach((dimensionChange) => {
      const changedNode =
        this.nodes.get(dimensionChange.id) ||
        this.groups.get(dimensionChange.id);
      if (changedNode) {
        const newWidth = dimensionChange.dimensions?.width;
        if (newWidth && changedNode.width !== newWidth) {
          changedNode.width = newWidth;
          changed = true;
        }
        const newHeight = dimensionChange.dimensions?.height;
        if (newHeight && changedNode.height !== newHeight) {
          changedNode.height = newHeight;
          changed = true;
        }
      }
    });
    if (changed) {
      this.positionGraph();
    }
  };

  /*
   * Layout the graph using dagreJS
   */
  positionGraph = () => {
    assertId(this.versionId);
    const graph = new dagre.graphlib.Graph();
    graph.setGraph({
      nodesep: 100,
      edgesep: 100,
      ranksep: 64,
      rankdir: "BT",
    });
    graph.setDefaultEdgeLabel(() => ({}));

    // We do not want to layout expanded group nodes as they should appear where their child nodes appear
    const [nodes, edges] = orderNodesAndEdgesForLayout(
      this.visibleNodes.filter(
        (n) =>
          !(
            n.type === NODE_TYPE.GROUP_NODE &&
            (n.data as GroupNodeDataT).uiState.expanded
          ),
      ),
      this.visibleEdges,
    );

    nodes.forEach((node: NodeT) => {
      graph.setNode(node.id, {
        label: node.data.label,
        width: node.width ?? 0,
        height: node.height ?? 0,
      });
    });

    edges.forEach((edge: Edge) => {
      graph.setEdge(edge.target, edge.source);
    });

    dagre.layout(graph);

    // Now first update the positions of the groups
    for (const [_, group] of this.groups) {
      if (!group.data.uiState.expanded) {
        const positionedNode = graph.node(group.id);
        group.position = dagreToReactFlow({
          xPosition: positionedNode.x,
          yPosition: positionedNode.y,
          width: positionedNode.width,
          height: positionedNode.height,
        });
      } else {
        // If the group is expanded we need to assign the group node the position of its children nodes
        const childNodes = this.visibleNodes.filter(
          (n) => n.parentNode === group.id,
        );
        // Get all children nodes with their new positions
        const childNodesWithNewPosition = childNodes.map((n) => {
          const positionedNode = graph.node(n.id);
          return {
            ...n,
            position: {
              x: positionedNode.x,
              y: positionedNode.y,
            },
            height: 0,
            width: 0,
          };
        });
        const rect = getRectOfNodes(childNodesWithNewPosition);
        // The width of the rectangle of the nodes in the group does not include the width of the nodes (because getRectOfNodes only looks at the positions of the nodes)
        // We also want some margin on the left and right so we need to add that as well
        group.width =
          rect.width + EXPANDED_GROUP_X_MARGIN * 2 + DEFAULT_NODE_WIDTH;
        group.height = rect.height + EXPANDED_GROUP_Y_MARGIN * 2;
        group.position = {
          x: rect.x - (group.width - rect.width) / 2,
          y: rect.y - 64 / 2,
        };
        const positionedPreSeparatorNode = graph.node(
          group.data.uiState.preSeparatorNode.id,
        );
        group.data.uiState.preSeparatorNode.position = dagreToReactFlow({
          xPosition: positionedPreSeparatorNode.x,
          yPosition: positionedPreSeparatorNode.y,
          width: positionedPreSeparatorNode.width,
          height: positionedPreSeparatorNode.height,
          xOffset: -group.position.x,
          yOffset: -group.position.y,
        });

        const positionedPostSeparatorNode = graph.node(
          group.data.uiState.postSeparatorNode.id,
        );
        group.data.uiState.postSeparatorNode.position = dagreToReactFlow({
          xPosition: positionedPostSeparatorNode.x,
          yPosition: positionedPostSeparatorNode.y,
          width: positionedPostSeparatorNode.width,
          height: positionedPostSeparatorNode.height,
          xOffset: -group.position.x,
          yOffset: -group.position.y,
        });
      }
    }

    this.setNodes(
      this.nodesArray.map((node) => {
        const positionedNode = graph.node(node.id);
        // We map over all the nodes, but only layout the visible nodes so this might be undefined
        if (!positionedNode) {
          return node;
        }

        const parentNode = this.visibleNodes.find(
          (n) => n.id === node.parentNode,
        );
        return {
          ...node,
          position: dagreToReactFlow({
            xPosition: positionedNode.x,
            yPosition: positionedNode.y,
            width: positionedNode.width,
            height: positionedNode.height,
            // If this is a node in a group we need to calculate the relative position to the parent node by substracting the parents position
            ...(parentNode !== undefined && {
              xOffset: -parentNode.position.x,
              yOffset: -parentNode.position.y,
            }),
          }),
        };
      }),
    );
    // After updating all the nodes positions we need to update the z-scores of the nodes and groups
    // This ensures that the z-score from left to right of the nodes and groups is decreasing and thus we make sure that the node/group controls are always above the node/groups
    this.nodes.forEach((node) => {
      const nodeAbsoluteXPosition = getAbsoluteXPosition(node, this.groups);
      node.style = { zIndex: -Math.floor(nodeAbsoluteXPosition) };
    });
    for (const [_, group] of this.groups) {
      if (group.data.uiState.expanded) {
        // If the group is expanded set the z-score to the minimum of its children z-scores
        let max = -2147483647;
        group.data.childrenIDs.forEach((childId) => {
          const node = this.nodes.get(childId);
          if (node && node.position.x + group.position.x > max) {
            max = node.position.x + group.position.x;
          }
        });
        // - 2 to make sure the group is placed below equally positioned nodes and edges
        group.style = { zIndex: -Math.floor(max) - 2 };
      } else {
        // If the group is collapsed just set the z-score to the (negative) x position
        group.style = { zIndex: -Math.floor(group.position.x) };
      }
    }
    this.updateEdgesZIndex();
  };

  updateEdgesZIndex = (
    selectedRowNodeExecutionMetadata?: ResultDataAndAuxRowV2["nodeExecutionMetadata"],
  ) => {
    assertId(this.versionId);
    for (const [_, edge] of this.edges) {
      const source = this.nodes.get(edge.source);
      const target = this.nodes.get(edge.target);
      if (target && source) {
        const sourceAbsoluteXPosition = getAbsoluteXPosition(
          source,
          this.groups,
        );
        const targetAbsoluteXPosition = getAbsoluteXPosition(
          target,
          this.groups,
        );
        const edgeWasPartOfHistoricalRun = getEdgeWasPartOfHistoricalDecision(
          source,
          target,
          this.versionId,
        );
        const edgeWasPartOfSelectedRow =
          selectedRowNodeExecutionMetadata &&
          source.id in selectedRowNodeExecutionMetadata &&
          target.id in selectedRowNodeExecutionMetadata;

        if (edgeWasPartOfHistoricalRun || edgeWasPartOfSelectedRow) {
          this.edges.set(edge.id, {
            ...edge,
            style: { zIndex: MAXIMUM_Z_INDEX },
            zIndex: MAXIMUM_Z_INDEX,
          });
        } else {
          // Set the z-index of a edge to the maximum of target and source x position
          // -1 to make sure the edges is placed above equally positioned groups and below equally positioned nodes
          edge.style = {
            zIndex:
              -Math.floor(
                Math.max(sourceAbsoluteXPosition, targetAbsoluteXPosition),
              ) - 1,
          };
          edge.zIndex =
            -Math.floor(
              Math.max(sourceAbsoluteXPosition, targetAbsoluteXPosition),
            ) - 1;
        }
      }
    }
  };

  setLastRunDatasetId = (newLastRunDatasetId: string | null) => {
    this.lastRunDatasetId = newLastRunDatasetId;
  };

  /**
   * Adds the node to the clipboard. If its a splitnode all children nodes and their edges will also be added.
   * @param  node The node to be added to the clipboard
   */
  copyToClipboard = async (nodes: NodeT[], workspaceId: string) => {
    assertId(this.versionId);
    const clipboardNodes: Set<BeMappedNode> = new Set();
    const clipboardGroups: Set<GroupNode> = new Set();

    if (nodes.length === 1 && nodes[0].type === NODE_TYPE.SPLIT_NODE_V2) {
      const splitNode = nodes[0] as SplitNodeV2;
      // If the node is a splitnode we need to find all of its children nodes and its merge node. All of these nodes (and the edges between them) we need to add to the clipboard
      const mergeNode = this.getMergeNode(splitNode);
      if (mergeNode) {
        const branchesAndNodes = this.getBranchesAndNodes(splitNode).flat(2);
        // The order is important here: First is the splitnode, then all the nodes on the branches, last is the merge node
        const splitNodeAndChildren = [
          splitNode,
          ...branchesAndNodes,
          mergeNode,
        ];
        splitNodeAndChildren.forEach((node) => clipboardNodes.add(node));
      }
    }

    nodes.forEach((node) => {
      if (node.type === NODE_TYPE.GROUP_NODE) {
        // If the node is a group node we need to find all of its children nodes and add these ones as well
        const groupNode = node as GroupNode;
        const childrenNodes = groupNode.data.childrenIDs
          .map((childId) => this.nodes.get(childId))
          .filter((n) => n !== undefined) as BeMappedNode[];

        childrenNodes.forEach((node) => clipboardNodes.add(node));
        clipboardGroups.add(groupNode);
      } else {
        // If the node is a simple node just add it to the nodes array
        clipboardNodes.add(node as BeMappedNode | SplitNodeV2);
      }
    });

    for (const [_, group] of this.groups) {
      if (
        group.data.childrenIDs.every((id) =>
          Array.from(clipboardNodes).find((node) => node.id === id),
        )
      ) {
        clipboardGroups.add(group);
      }
    }

    const clipboardNodesArray = Array.from(clipboardNodes).map((node) => ({
      ...node,
      selected: false,
    }));

    clearRunStates(clipboardNodesArray, this.versionId);
    const edges = getEdgesInSubgraph(this.edgesArray, clipboardNodesArray);

    const newClipboard: SubGraphClipboard = {
      nodes: clipboardNodesArray,
      edges,
      groups: Array.from(clipboardGroups),
      workspaceId,
    };

    const success = await writeGraphToClipboard(newClipboard);

    if (success) {
      this.clipboard = newClipboard;
      return newClipboard;
    }

    return false;
  };

  readClipboard = async (options: { promptUser: boolean }) => {
    const newClipboard = await readGraphFromClipboard(options);
    if (newClipboard) {
      this.clipboard = newClipboard;
    }
  };

  /**
   * Returns copied clipboard if the selected node(s) were successfully copied to the clipboard
   */
  copySelectedNodesToClipboard = async (
    workspaceId: string,
    isCutting?: boolean,
  ): Promise<false | undefined | SubGraphClipboard> => {
    let copiedClipboard = undefined;
    // multiple nodes
    if (
      this.isMultiselecting &&
      this.selectedNodes &&
      this.isValidCopyDeleteSelection.valid
    ) {
      copiedClipboard = await this.copyToClipboard(
        this.selectedNodes,
        workspaceId,
      );
      if (copiedClipboard) {
        if (isCutting) {
          try {
            await this.deleteMultipleConnectedNodes(
              this.selectedNodes,
              Operation.CUT_NODES,
            );
          } catch {
            return this.clipboard;
          }
          this.setSelectedNode(undefined);
        }
        return this.clipboard;
      } else {
        return false;
      }
    } else {
      // single node
      if (
        this.selectedNode &&
        this.selectedNode.type !== NODE_TYPE.INPUT_NODE &&
        this.selectedNode.type !== NODE_TYPE.OUTPUT_NODE
      ) {
        copiedClipboard = await this.copyToClipboard(
          [this.selectedNode],
          workspaceId,
        );
        if (copiedClipboard) {
          if (isCutting) {
            try {
              await this.deleteNode(this.selectedNode, true);
            } catch {
              return this.clipboard;
            }
            this.setSelectedNode(undefined);
          }
          return this.clipboard;
        } else {
          return false;
        }
      }
    }
  };

  /**
   * Returns whether the current clipboard can be pasted at the current clicked edge and if not a reason
   */
  canPasteClipboard = (
    workspaceId: string,
  ): { canPaste: boolean; reason?: string } => {
    if (this.clipboard.nodes.length === 0) return { canPaste: false };
    if (
      this.clipboard.workspaceId !== workspaceId &&
      this.clipboard.nodes.some(
        (n) =>
          n.type === NODE_TYPE.ML_NODE ||
          n.type === NODE_TYPE.CUSTOM_CONNECTION_NODE ||
          n.type === NODE_TYPE.WEBHOOK_CONNECTION_NODE ||
          n.type === NODE_TYPE.INTEGRATION_NODE ||
          PARENT_FLOW_NODE_TYPES.includes(n.type),
      )
    )
      return {
        canPaste: false,
        reason:
          "Machine Learning, Integration, Decision Flow and Loop nodes cannot be pasted across workspaces",
      };
    if (
      this.clipboard.nodes.some((n) => PARENT_FLOW_NODE_TYPES.includes(n.type))
    ) {
      assertId(this.flowId);
      const flow = getFlow(this.flowId);
      if (flow === undefined) {
        logger.error("No flow found by graphstore");
        return {
          canPaste: false,
        };
      }
      if (singleFlowIsChildFlow(flow)) {
        return {
          canPaste: false,
          reason:
            "Decision Flow or Loop Node cannot be pasted into a Child Flow",
        };
      }
      const currentParentNodesCount = this.nodesArray.filter(
        (n) => n.type === NODE_TYPE.FLOW_NODE,
      ).length;
      const clipboardParentNodesCount = this.clipboard.nodes.filter(
        (n) => n.type === NODE_TYPE.FLOW_NODE,
      ).length;
      if (
        currentParentNodesCount + clipboardParentNodesCount >
        MAXIMUM_PARENT_NODES
      ) {
        return {
          canPaste: false,
          reason:
            "A Decision Flow allows a maximum of 20 Decision Flow and Loop nodes",
        };
      }
    }

    if (!this.clickedVisibleEdgeId) return { canPaste: false };
    const clickedEdge = this.visibleEdges.find(
      (vEdge) => vEdge.id === this.clickedVisibleEdgeId,
    );
    if (!clickedEdge) return { canPaste: false };
    const sourceNode = this.visibleNodes.find(
      (vNodes) => vNodes.id === clickedEdge?.source,
    );
    const targetNode = this.visibleNodes.find(
      (vNodes) => vNodes.id === clickedEdge?.target,
    );
    const pastingGroupInsideGroup =
      this.clipboard.groups.length > 0 &&
      sourceNode?.parentNode &&
      sourceNode?.parentNode === targetNode?.parentNode;
    if (pastingGroupInsideGroup)
      return {
        canPaste: false,
        reason: "Cannot paste a Node group within a Node group",
      };
    return { canPaste: true };
  };

  /**
   * Creates the subgraph that is currently stored in the clipboard in the graph and patches the update to flow-api
   * @returns The added nodes and edges
   */
  pasteClipboard = async (workspaceId: string) => {
    // We need to fetch the newest clipboard from the system clipboard in the graph store
    await this.readClipboard({ promptUser: true });
    if (
      this.clickedVisibleEdgeId &&
      this.canPasteClipboard(workspaceId).canPaste
    ) {
      const { nodes, edges, groups } = getNodesAndEdgesWithNewIDs({
        nodes: this.clipboard.nodes,
        edges: this.clipboard.edges,
        groups: this.clipboard.groups,
      });

      await this.createSubgraphAtEdge({
        nodesToAdd: nodes,
        edgesToAdd: edges,
        atVisibleEdgeId: this.clickedVisibleEdgeId,
        groupsToAdd: groups,
        operation:
          nodes.length > 1 ? Operation.PASTE_NODES : Operation.PASTE_NODE,
      });
      this.setClickedVisibleEdgeId(null);
      return { nodes: nodes, edges: edges, groups: groups };
    }
  };

  /**
   * Creates the given subgraph in the graph and patches the update to flow-api
   * @param  nodesToAdd  A list of nodes that should be added. Should only contain the backend mappable nodes to be added.
   * @param  edgesToAdd  A list of edges that should be added. Should not contain the incoming edge from the rest of the graph to the subgraph and the outgoing edge from the subgraph to the rest of the graph
   * @param groupsToAdd A list of groups that should be added.
   * @param  edgeIDToReplace The id of the edge that should be replaced with the subgraph
   */
  createSubgraphAtEdge = async ({
    nodesToAdd,
    edgesToAdd: parameterEdgesToAdd,
    groupsToAdd,
    atVisibleEdgeId,
    operation,
  }: CreateSubgraphArgs) => {
    assertId(this.versionId);
    assertUpdateVersion(this.updateFlowVersionGraphAndStore);
    const edgesToAdd = [...parameterEdgesToAdd];
    const atVisibleEdge = this.visibleEdges.find(
      (edge) => edge.id === atVisibleEdgeId,
    );

    const originalEdgeId =
      atVisibleEdge?.type === EDGE_TYPE.GROUP_EDGE
        ? ((atVisibleEdge as GroupEdge).data?.originalEdgeId ??
          "data needs to be set")
        : atVisibleEdgeId;
    const edgeToReplace = this.edges.get(originalEdgeId);
    const subgraphSource = nodesToAdd.find(
      (node) => getIncomers(node, nodesToAdd, edgesToAdd).length === 0,
    );
    const subgraphSink = nodesToAdd.find(
      (node) => getOutgoers(node, nodesToAdd, edgesToAdd).length === 0,
    );

    if (atVisibleEdge && edgeToReplace && subgraphSource && subgraphSink) {
      // If the location visible edge is completly inside a group, the new subgraph will be created inside that group
      const sourceGroupId = this.visibleNodes.find(
        (vNode) => vNode.id === atVisibleEdge.source,
      )?.parentNode;
      const targetGroupId = this.visibleNodes.find(
        (vNode) => vNode.id === atVisibleEdge.target,
      )?.parentNode;
      const parentGroup =
        sourceGroupId !== undefined && sourceGroupId === targetGroupId
          ? this.groups.get(sourceGroupId)
          : undefined;
      // Don't allow creating groups inside other groups
      if (groupsToAdd && groupsToAdd.length > 0 && parentGroup) return;
      const newSourceChildId =
        parentGroup?.data.uiState.preSeparatorNode.id === atVisibleEdge.source
          ? subgraphSource.id
          : undefined;
      const newSinkChildId =
        parentGroup?.data.uiState.postSeparatorNode.id === atVisibleEdge.target
          ? subgraphSink.id
          : undefined;
      const updatedGroup = parentGroup
        ? getUpdatedGroup({
            group: parentGroup,
            updatedChildrenIds: [
              ...parentGroup.data.childrenIDs,
              ...nodesToAdd.map((node) => node.id),
            ],
            newSourceChildId,
            newSinkChildId,
          })
        : null;

      const sourceNodeForDeletedEdge = this.nodes.get(edgeToReplace.source);
      const targetNodeForDeletedEdge = this.nodes.get(edgeToReplace.target);
      if (sourceNodeForDeletedEdge && targetNodeForDeletedEdge) {
        // Add an edge from the previous node to the first node of the subgraph, and an edge from the last node of the subgraph to the next node
        edgesToAdd.push(
          getDefaultFlowEdge({
            sourceId: sourceNodeForDeletedEdge.id,
            targetId: subgraphSource.id,
          }),
          getDefaultFlowEdge({
            sourceId: subgraphSink.id,
            targetId: targetNodeForDeletedEdge.id,
          }),
        );
        // Make a call to flow-api creating the new nodes and edges and deleting the edge at whose position we insert the subgraph
        await this.updateFlowVersionGraphAndStore({
          flowVersionId: this.versionId,
          nodes: keyBy(nodesToAdd, "id"),
          edges: {
            ...keyBy(edgesToAdd, "id"),
            [edgeToReplace.id]: null,
          },
          groups: {
            ...(parentGroup && { [parentGroup.id]: updatedGroup }),
            ...(groupsToAdd && keyBy(groupsToAdd, "id")),
          },
          operation,
        });
      }
    } else {
      logger.error(
        TAG,
        "createSubgraphAtEdge failed, edgeIDToReplace not found",
      );
    }
  };

  setDisplayedErroredRow = (displayedErroredRow: ErroredRow | undefined) => {
    this.displayedErroredRow = displayedErroredRow;
  };

  groupSelectedNodes = async (groupName: string) => {
    assertId(this.versionId);
    assertUpdateVersion(this.updateFlowVersionGraphAndStore);
    const selectedNodesIds = this.selectedNodes.map((node) => node.id);
    const newGroupId = v4();
    const { source, sink } = getSourceAndSink(
      selectedNodesIds,
      this.nodesArray,
      this.edgesArray,
    );
    if (selectedNodesIds.length > 1 && source && sink) {
      const newGroup = getDefaultGroupNode({
        id: newGroupId,
        name: groupName,
        expanded: false,
        childrenIDs: selectedNodesIds,
        sourceChildId: source.id,
        sinkChildId: sink.id,
      });
      await this.updateFlowVersionGraphAndStore({
        flowVersionId: this.versionId,
        nodes: {},
        edges: {},
        groups: { [newGroupId]: newGroup },
        operation: Operation.CREATE_GROUP,
      });
      runInAction(() => {
        this.setSelectedNode(null);
      });
    }
  };

  updateGroup = async ({ groupId, newName }: UpdateGroupArgs) => {
    assertId(this.versionId);
    assertUpdateVersion(this.updateFlowVersionGraphAndStore);
    const group = this.groups.get(groupId);
    if (group) {
      await this.updateFlowVersionGraphAndStore({
        flowVersionId: this.versionId,
        nodes: {},
        edges: {},
        groups: {
          [group.id]: {
            ...group,
            data: {
              ...group.data,
              label: newName,
            },
          },
        },
        operation: Operation.RENAME_GROUP,
      });
    }
  };

  toggleGroupNode = (groupId: string, expanded?: boolean) => {
    const groupToToggle = this.groups.get(groupId);
    if (groupToToggle) {
      groupToToggle.data.uiState.expanded =
        expanded ?? !groupToToggle.data.uiState.expanded;
      // If a group node got collapsed make sure to de-select all children nodes and reset the width and height of the group
      if (!groupToToggle.data.uiState.expanded) {
        groupToToggle.width = COLLAPSED_GROUP_WIDTH;
        groupToToggle.height = COLLAPSED_GROUP_HEIGHT;
        if (
          this.selectedNode &&
          groupToToggle.data.childrenIDs.includes(this.selectedNode.id)
        ) {
          this.setSelectedNode(null);
        } else if (this.selectedNodes) {
          this.selectedNodes.forEach((node) => {
            if (groupToToggle.data.childrenIDs.includes(node.id)) {
              this.deSelectNode(node.id);
            }
          });
        }
      }
      // Re-position the graph
      this.positionGraph();
    }
  };

  deleteGroup = async (groupId: string) => {
    assertId(this.versionId);
    assertUpdateVersion(this.updateFlowVersionGraphAndStore);
    const groupToDelete = this.groups.get(groupId);
    if (groupToDelete) {
      await this.updateFlowVersionGraphAndStore({
        flowVersionId: this.versionId,
        nodes: {},
        edges: {},
        groups: { [groupId]: null },
        operation: Operation.UNGROUP_GROUP,
      });
    }
  };

  /**
   * Deletes multiple nodes in the graph
   * @param nodesToDelete - The nodes which should be deleted. Nodes should be connected and contain all children nodes in case of a split node
   */
  deleteMultipleConnectedNodes = async (
    nodesToDelete: BeMappedNode[],
    operation: Operation,
    options?: { selectIncomingNode?: boolean },
  ) => {
    assertId(this.versionId);
    assertUpdateVersion(this.updateFlowVersionGraphAndStore);
    // TODO: Check whether nodes can even be deleted
    if (nodesToDelete.length > 0) {
      const { source: firstNode, sink: lastNode } = getSourceAndSink(
        nodesToDelete.map((node) => node.id),
        this.nodesArray,
        this.edgesArray,
      );
      const incomingNodes = firstNode
        ? (getIncomers(
            firstNode,
            this.nodesArray,
            this.edgesArray,
          ) as BeMappedNode[])
        : [];
      const outgoingNodes = lastNode
        ? (getOutgoers(
            lastNode,
            this.nodesArray,
            this.edgesArray,
          ) as BeMappedNode[])
        : [];
      const edgesToDelete = getConnectedEdges(nodesToDelete, this.edgesArray);
      if (
        incomingNodes.length === 1 &&
        outgoingNodes.length === 1 &&
        firstNode &&
        lastNode
      ) {
        // This code relies on the nodes being connected and in case of a split node or merge node
        // being included that all children of them are also included!
        const groupsToUpdate = new Map<string, GroupNode | null>();
        for (const group of this.groups.values()) {
          const updatedChildrenIds = group.data.childrenIDs.filter(
            (id) => !nodesToDelete.map((node) => node.id).includes(id),
          );
          if (updatedChildrenIds.length === group.data.childrenIDs.length) {
            continue;
          } else if (updatedChildrenIds.length === 0) {
            groupsToUpdate.set(group.id, null);
          } else {
            // Because the nodes are connected, if the source is deleted and nodes are left in the group,
            // the new source must be the next node in the graph
            const newSourceChildId = nodesToDelete.some(
              (nodeToDelete) => nodeToDelete.id === group.data.sourceChildId,
            )
              ? outgoingNodes[0].id
              : undefined;
            // The same but the other way around for the sink child
            const newSinkChildId = nodesToDelete.some(
              (nodeToDelete) => nodeToDelete.id === group.data.sinkChildId,
            )
              ? incomingNodes[0].id
              : undefined;

            groupsToUpdate.set(
              group.id,
              getUpdatedGroup({
                group,
                updatedChildrenIds,
                newSourceChildId,
                newSinkChildId,
              }),
            );
          }
        }
        const newEdge =
          incomingNodes[0].type === NODE_TYPE.SPLIT_NODE_V2
            ? getDefaultSplitEdge({
                sourceId: incomingNodes[0].id,
                targetId: outgoingNodes[0].id,
              })
            : getDefaultFlowEdge({
                sourceId: incomingNodes[0].id,
                targetId: outgoingNodes[0].id,
              });
        await this.updateFlowVersionGraphAndStore({
          flowVersionId: this.versionId,
          nodes: Object.fromEntries(
            nodesToDelete.map((node) => [node.id, null]),
          ),
          edges: {
            ...Object.fromEntries(edgesToDelete.map((edge) => [edge.id, null])),
            [newEdge.id]: newEdge,
          },
          groups: {
            ...Object.fromEntries(groupsToUpdate.entries()),
          },
          operation,
        });
        if (options?.selectIncomingNode) {
          this.setSelectedNode(
            getSelectableParentNode(
              incomingNodes[0],
              this.nodesArray,
              this.edgesArray,
            )?.id,
          );
        }
      } else {
        logger.error(TAG, "incomingNodes or outgoingNodes inconsistent");
      }
    }
  };
  setIsDeleteGroupModalVisible = (isVisible: boolean) => {
    this.isDeleteGroupModalVisible = isVisible;
  };
  setGroupToChangeId = (id: string | null) => {
    this.groupToChangeId = id;
  };
  setIsUngroupModalVisible = (isVisible: boolean) => {
    this.isUngroupModalVisible = isVisible;
  };
  setIsCreateGroupModalVisible = (isVisible: boolean) => {
    this.isCreateGroupModalVisible = isVisible;
  };
  setIsRenameNodeModalVisible = (isVisible: boolean) => {
    this.isRenameNodeModalVisible = isVisible;
  };

  /**
   * This function should be called everytime the graph is changed
   * Be careful that the functions in here inside have a check to run only when its really necessary
   */
  graphHasChanged = () => {
    this.setResultsOutdated(true);
    if (this.versionId) {
      clearHighlightingGraphStore(this.versionId);
    }
    this.clearHistoryRunState();
  };
}
