import { faCube } from "@fortawesome/pro-regular-svg-icons";
import { twJoin } from "tailwind-merge";

import { FlowT } from "src/api/flowTypes";
import { EmptyState } from "src/design-system/EmptyState";
import { FlowCardV2 } from "src/flowsOverview/FlowCardV2";
import { GRID_COLUMNS_CLASS } from "src/flowsOverview/FlowOverview";

type Props = {
  flows: FlowT[];
  flowActions: {
    onCopy: (flow: FlowT) => void;
    onDelete: (flow: FlowT) => void;
    onRename: (flow: FlowT) => void;
    onViewApiDocs: (flow: FlowT) => void;
    onViewFlow: (flow: FlowT) => void;
    onMove: (flow: FlowT) => void;
    onRemoveFromFolder: (flow: FlowT) => void;
  };
};

export const FlowListV2: React.FC<Props> = ({ flows, flowActions }) => {
  return (
    <div className={GRID_COLUMNS_CLASS}>
      {flows.length === 0 ? (
        <div
          className={twJoin(
            "col-span-full h-full rounded-lg border border-gray-200 bg-gray-50",
            "p-8 lg:p-16",
          )}
        >
          <EmptyState
            description="Create a new Decision Flow or drag and drop an existing one into this folder."
            headline="This folder has no Decision Flows"
            icon={faCube}
          />
        </div>
      ) : (
        <>
          {flows.map((flow: FlowT) => (
            <FlowCardV2
              key={flow.id}
              flow={flow}
              onCopy={() => flowActions.onCopy(flow)}
              onDelete={() => flowActions.onDelete(flow)}
              onMove={() => flowActions.onMove(flow)}
              onRemoveFromFolder={() => flowActions.onRemoveFromFolder(flow)}
              onRename={() => flowActions.onRename(flow)}
              onViewApiDocs={() => flowActions.onViewApiDocs(flow)}
              onViewFlow={() => flowActions.onViewFlow(flow)}
            />
          ))}
        </>
      )}
    </div>
  );
};
