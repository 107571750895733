import {
  faCheck,
  faEye,
  faUserCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { ColumnDef, Row } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useReviewCasesAvailableFilters } from "src/api/queries";
import {
  ReviewCase,
  ReviewCaseStatus as ReviewCaseStatusType,
  ReviewCasesAvailableFiltersResponse,
} from "src/api/types";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { Icon } from "src/base-components/Icon";
import { OrganizationUser } from "src/clients/taktile-api";
import { ReviewCaseStatus } from "src/manualReview/ReviewCaseStatus";
import {
  Table,
  AssigneeFilterDropdown,
  BaseCell,
  FiltersDropdown,
  SortIcon,
} from "src/manualReview/Table";
import { UserPickerCell } from "src/manualReview/UserPicker";
import {
  ReviewCasesOrdering,
  ReviewQueueFilters,
} from "src/manualReview/types";
import { convertFiltersToParams } from "src/manualReview/utils";
import { useFlowContext } from "src/router/routerContextHooks";
import { getUrlToReviewCase } from "src/router/urls";
import { dateFromNow } from "src/utils/datetime";

const HeaderCell: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => (
  <div className="flex items-center gap-x-1 py-1.5 pr-4">{children}</div>
);

type GetColumnsArgs = {
  onChangeFilter: (filter: Partial<ReviewQueueFilters>) => void;
  onChangeOrder: (order: ReviewCasesOrdering) => void;
  filters: ReviewQueueFilters;
  availableFilters: ReviewCasesAvailableFiltersResponse | undefined;
  users: OrganizationUser[];
};

const getColumns = ({
  onChangeFilter,
  onChangeOrder,
  filters,
  availableFilters,
  users,
}: GetColumnsArgs): ColumnDef<ReviewCase, any>[] => [
  {
    id: "number",
    accessorKey: "number",
    header: () => <HeaderCell>#</HeaderCell>,
    cell: ({ cell }) => <BaseCell value={cell.getValue()} />,
    maxSize: 60,
    minSize: 60,
    size: 60,
  },
  {
    id: "start_time",
    accessorKey: "decision.start_time",
    header: () => (
      <HeaderCell>
        Request time
        <SortIcon
          isActive={filters.order === "decision_start_time_desc"}
          onClick={() =>
            onChangeOrder(
              filters.order === "decision_start_time_desc"
                ? "created_at_asc"
                : "decision_start_time_desc",
            )
          }
        />
      </HeaderCell>
    ),
    cell: ({ cell }) => (
      <BaseCell
        value={format(parseISO(cell.getValue()), "dd/MM/yyyy HH:mm:ss")}
      />
    ),
    maxSize: 152,
    minSize: 152,
    size: 152,
  },
  {
    id: "time_since_request",
    accessorKey: "created_at",
    header: () => (
      <HeaderCell>
        Needs review since
        <SortIcon
          isActive={filters.order === "created_at_asc"}
          onClick={() =>
            onChangeOrder(
              filters.order === "decision_start_time_desc"
                ? "created_at_asc"
                : "decision_start_time_desc",
            )
          }
        />
      </HeaderCell>
    ),
    cell: ({ cell }) => <BaseCell value={dateFromNow(cell.getValue(), true)} />,
    maxSize: 152,
    minSize: 152,
    size: 152,
  },
  {
    id: "version",
    accessorKey: "flow.version_name",
    header: () => (
      <HeaderCell>
        Version
        <FiltersDropdown
          description="Filter by version:"
          elements={
            availableFilters?.flow_versions.map((version) => ({
              key: version,
              value: version,
            })) ?? []
          }
          isFiltering={!!filters.flowVersionNames}
          selected={filters.flowVersionNames}
          onResetRequest={() => onChangeFilter({ flowVersionNames: undefined })}
          onSelect={(keys: string[]) =>
            onChangeFilter({
              flowVersionNames: keys.length === 0 ? undefined : keys,
            })
          }
        />
      </HeaderCell>
    ),
    cell: ({ cell }) => <BaseCell value={cell.getValue()} />,
    maxSize: 120,
    minSize: 80,
    size: 120,
  },
  {
    id: "assignee",
    accessorKey: "assignee",
    header: () => (
      <HeaderCell>
        Assigned to
        <AssigneeFilterDropdown
          elements={users
            .filter((user) => availableFilters?.assignees.includes(user.id))
            .map((user) => ({
              key: user.id,
              value: user,
            }))}
          isFiltering={!!filters.assignees}
          selected={filters.assignees}
          onResetRequest={() => onChangeFilter({ assignees: undefined })}
          onSelect={(keys: string[]) =>
            onChangeFilter({
              assignees: keys.length === 0 ? undefined : keys,
            })
          }
        />
      </HeaderCell>
    ),
    cell: ({ cell }) => (
      <UserPickerCell
        caseId={cell.row.original.id}
        etag={cell.row.original.etag}
        users={users}
        value={cell.getValue()}
      />
    ),
    maxSize: 170,
    minSize: 100,
    size: 170,
  },
  {
    id: "node_name",
    accessorKey: "node_name",
    header: () => (
      <HeaderCell>
        Node
        <FiltersDropdown
          description="Filter by Node name:"
          elements={
            availableFilters?.node_names.map((nodeName) => ({
              key: nodeName,
              value: nodeName,
            })) ?? []
          }
          isFiltering={!!filters.nodeNames}
          selected={filters.nodeNames}
          onResetRequest={() => onChangeFilter({ nodeNames: undefined })}
          onSelect={(keys: string[]) =>
            onChangeFilter({
              nodeNames: keys.length === 0 ? undefined : keys,
            })
          }
        />
      </HeaderCell>
    ),
    cell: ({ cell }) => <BaseCell value={cell.getValue()} />,
    maxSize: 160,
  },
  {
    id: "status",
    accessorKey: "status",
    header: () => (
      <HeaderCell>
        Status
        <FiltersDropdown
          description="Filter by status:"
          elements={[
            {
              key: "needs_review",
              value: "Needs manual review",
            },
            {
              key: "in_progress",
              value: "Review in progress",
            },
          ]}
          isFiltering={!!filters.statuses}
          renderValue={({ value, selected, key }) => (
            <div className="flex w-[240px] items-center justify-between gap-x-1 px-4 py-2.5">
              <Icon
                color={
                  key === "in_progress" ? "text-green-500" : "text-indigo-500"
                }
                icon={faUserCircle}
                size="xs"
              />
              <span className="inline-block flex-1">{value}</span>
              {selected && (
                <Icon color="text-indigo-600" icon={faCheck} size="2xs" />
              )}
            </div>
          )}
          selected={filters.statuses}
          onResetRequest={() => onChangeFilter({ statuses: undefined })}
          onSelect={(keys: string[]) =>
            onChangeFilter({
              statuses:
                keys.length === 0
                  ? undefined
                  : (keys as unknown as Exclude<
                      ReviewCaseStatusType,
                      "completed"
                    >[]),
            })
          }
        />
      </HeaderCell>
    ),
    cell: ({ cell }) => <ReviewCaseStatus status={cell.getValue()} />,
    maxSize: 180,
    minSize: 180,
    size: 180,
  },
  {
    id: "id",
    accessorKey: "id",
    header: () => <HeaderCell>Decision ID</HeaderCell>,
    cell: ({ cell }) => (
      <CopyTextIcon
        dataLoc="manual-review-copy-decision-id"
        tooltip="Copy Decision ID"
        value={cell.row.original.decision.id}
      />
    ),
    maxSize: 80,
    minSize: 80,
    size: 80,
  },
  {
    id: "entity_id",
    accessorKey: "decision.entity_id",
    header: () => <HeaderCell>Entity ID</HeaderCell>,
    cell: ({ cell }) =>
      cell.row.original.decision.entity_id ? (
        <CopyTextIcon
          tooltip="Copy Entity ID"
          value={cell.row.original.decision.entity_id}
        />
      ) : (
        <BaseCell value="-" />
      ),
    maxSize: 70,
    minSize: 70,
    size: 70,
  },
  {
    id: "actions",
    header: () => null,
    cell: () => (
      <span
        className="invisible text-right text-gray-500 group-hover/row:visible"
        data-loc="manual-review-open-case"
      >
        <Icon
          color="text-gray-500 hover:text-gray-700"
          icon={faEye}
          size="xs"
        />
      </span>
    ),
    maxSize: 30,
    minSize: 30,
    size: 30,
  },
];

type ReviewQueueTableProps = {
  cases: ReviewCase[];
  isLoading: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  canFetchNextPage: boolean;
  hidden: boolean;
  onChangeOrder: (order: ReviewCasesOrdering) => void;
  onChangeFilter: (filter: Partial<ReviewQueueFilters>) => void;
  filters: ReviewQueueFilters;
  users: OrganizationUser[] | undefined;
};

export const ReviewQueueTable: React.FC<ReviewQueueTableProps> = ({
  cases,
  isLoading,
  isFetchingNextPage,
  fetchNextPage,
  canFetchNextPage,
  hidden,
  onChangeOrder,
  onChangeFilter,
  filters,
  users = [],
}) => {
  const { workspace, flow, orgId } = useFlowContext();

  const availableFilters = useReviewCasesAvailableFilters(
    workspace.base_url,
    flow.slug,
    convertFiltersToParams(filters),
  );
  const navigate = useNavigate();
  const { search } = useLocation();

  // We need to memoize columns because
  // tanstack remounts the table on every render
  const columns = useMemo(() => {
    return getColumns({
      onChangeOrder,
      onChangeFilter,
      filters,
      availableFilters: availableFilters.data,
      users,
    });
  }, [availableFilters.data, filters, onChangeFilter, onChangeOrder, users]);

  const rowOnClick = useCallback(
    (row: Row<ReviewCase>, e: React.MouseEvent) => {
      const url = getUrlToReviewCase(
        orgId,
        workspace.id,
        flow.id,
        row.original.id,
        search,
      );
      if (e.metaKey || e.ctrlKey) {
        window.open(`/decide${url}`, "_blank");
      } else {
        navigate(url);
      }
    },
    [flow.id, navigate, orgId, search, workspace.id],
  );

  return (
    !hidden && (
      <Table
        canFetchNextPage={canFetchNextPage}
        columns={columns}
        data={cases}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        rowOnClick={rowOnClick}
      />
    )
  );
};
