import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { capitalize } from "lodash";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Dataset,
  DesiredType,
  DesiredTypes,
  ExtendedDatasetColumnGroups,
} from "src/api/types";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Input } from "src/base-components/Input";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { Select } from "src/base-components/Select";
import { Modal } from "src/design-system/Modal";

type Props = {
  dataset?: Dataset;
  open: boolean;
  onClose: () => void;
  onAddColumn: (
    name: string,
    group: ExtendedDatasetColumnGroups,
    desiredType?: DesiredType,
  ) => Promise<void>;
};

type AdditionalFieldInputs = {
  name: string;
  desiredType: DesiredType;
};

const validateFieldName = (name: string) => {
  if (/^\d/.test(name)) {
    return "Field name can't start with numbers";
  }

  if (/\s/.test(name)) {
    return "Field name can't contain spaces";
  }
};

export const AddAdditionalFieldModal: React.FC<Props> = ({
  open,
  onClose,
  onAddColumn,
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<AdditionalFieldInputs>({
    mode: "onChange",
    defaultValues: { desiredType: "string" },
  });

  const onSubmit = (data: AdditionalFieldInputs) => {
    onAddColumn(data.name.trim(), "additional_columns", data.desiredType);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>Add additional field</Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <div className="relative min-h-0 flex-1">
            <div className="mb-2 text-gray-800 font-inter-semibold-13px">
              Field name <RequiredAsterisk />
            </div>
            <Input
              autoComplete="off"
              errored={!!errors.name}
              placeholder="field_name"
              fullWidth
              {...register("name", {
                required: true,
                validate: validateFieldName,
              })}
            />
            <ErrorHint>{errors.name?.message}</ErrorHint>
            <div className="mb-2 mt-6 text-gray-800 font-inter-semibold-13px">
              Field type <RequiredAsterisk />
            </div>
            <Controller
              control={control}
              name="desiredType"
              render={({ field }) => (
                <Select
                  multiple={false}
                  options={DesiredTypes.map((type) => ({
                    key: type,
                    value: capitalize(type),
                  }))}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button htmlType="submit" iconLeft={faPlus} loading={isSubmitting}>
              Add field
            </Button>
          }
        />
      </form>
    </Modal>
  );
};
