import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "src/base-components/Button";
import { TextConfirmationModal } from "src/base-components/TextConfirmationModal";
import { Modal } from "src/design-system/Modal";
import { toastActions } from "src/design-system/Toast/utils";
import { useDeleteJob } from "src/jobs/api/queries";
import { Job } from "src/jobs/types";
import { useFlowContext } from "src/router/routerContextHooks";
import { getUrlToJobsPage } from "src/router/urls";

type DeleteJobModalProps = {
  isOpen: boolean;
  onClose: () => void;
  job?: Job;
};
export const DeleteJobModal: React.FC<DeleteJobModalProps> = ({
  isOpen,
  onClose,
  job,
}) => {
  const { workspace, flow } = useFlowContext();
  const deleteJobMutation = useDeleteJob(workspace.base_url!, flow.id);
  const navigate = useNavigate();

  if (!job) return null;

  const onConfirmDelete = async () => {
    try {
      await deleteJobMutation.mutateAsync(job.id);
      onClose();
      navigate(
        getUrlToJobsPage(workspace.organization_id, workspace.id, flow.id),
      );
      toastActions.success({
        title: `Job successfully deleted`,
      });
    } catch (err) {
      toastActions.failure({
        title: `Failed to delete the Job`,
        description: "Something went wrong. Please reload and try again.",
      });
    }
  };

  if (job.status === "active") {
    return (
      <Modal open={isOpen} onClose={onClose}>
        <Modal.Header>Cannot delete Job</Modal.Header>
        <Modal.Content>
          <p className="text-gray-500 font-inter-normal-13px">
            This Job cannot be deleted as it is currently active. Please
            deactivate the Job before deleting.
          </p>
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="delete-job-button"
              htmlType="submit"
              variant="primary"
              disabled
            >
              Delete Job
            </Button>
          }
        />
      </Modal>
    );
  }

  return (
    <TextConfirmationModal
      challengeText={job.name}
      description="Are you sure you want to delete this Job and all of its runs? This action is not reversible."
      label="Please enter the Job's name to confirm"
      open={isOpen}
      title={`Confirm delete ${job.name}`}
      variant="danger"
      onClose={onClose}
      onConfirm={onConfirmDelete}
    />
  );
};
