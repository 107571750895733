import { useLayoutEffect, useState } from "react";

import {
  SingleDecisionEnvelope,
  ENVIRONMENT_MISSMATCH_IS_SANDBOX,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { DecisionEnvironment, DecisionSummaryResponse } from "src/api/types";
import { HistoricalDecisionV3 } from "src/clients/history-v3";
import { useDecisionEnvSearchParam } from "src/decisionsOverview/useDecisionEnvSearchParam";

export const useDecisionEnv = () => {
  const [decisionEnvParam, setDecisionEnvParam] = useDecisionEnvSearchParam();
  const [confirmedNoLiveDecisions, setConfirmedNoLiveDecisions] =
    useState(false);
  const decisionEnvForQueries =
    decisionEnvParam ??
    (confirmedNoLiveDecisions
      ? DecisionEnvironment.SANDBOX
      : DecisionEnvironment.LIVE);
  return {
    decisionEnvParam,
    decisionEnvForQueries,
    confirmedNoLiveDecisions,
    setDecisionEnvParam,
    setConfirmedNoLiveDecisions,
  };
};

/**
 * If there is no environment URL param set we determine the environment based on the query results.
 */
export const useDetermineDecisionEnvironment = ({
  decisionEnvParam,
  entityId,
  decisionsOverview,
  setDecisionEnvParam,
  decisionId,
  singleDecision,
  confirmedNoLiveDecisions,
  setConfirmedNoLiveDecisions,
}: {
  decisionEnvParam: DecisionEnvironment | null;
  entityId: string | null;
  decisionId: string | null;
  decisionsOverview: DecisionSummaryResponse | undefined;
  singleDecision: SingleDecisionEnvelope<HistoricalDecisionV3> | undefined;
  confirmedNoLiveDecisions: boolean;
  setConfirmedNoLiveDecisions: (value: boolean) => void;
  setDecisionEnvParam: (env: DecisionEnvironment) => void;
}) => {
  // Layout effect to pre-empt the query results getting rendered in case we want to switch the environment.
  useLayoutEffect(() => {
    if (decisionEnvParam !== null) {
      if (confirmedNoLiveDecisions) {
        setConfirmedNoLiveDecisions(false);
      }
      return;
    }

    if (entityId !== null && decisionsOverview !== undefined) {
      if (!confirmedNoLiveDecisions) {
        if (decisionsOverview.groups.length === 0) {
          setConfirmedNoLiveDecisions(true);
        } else {
          setDecisionEnvParam(DecisionEnvironment.LIVE);
        }
      }
      if (confirmedNoLiveDecisions) {
        if (decisionsOverview.groups.length === 0) {
          setDecisionEnvParam(DecisionEnvironment.LIVE);
        } else {
          setDecisionEnvParam(DecisionEnvironment.SANDBOX);
        }
      }
    }
    if (decisionId !== null && singleDecision !== undefined) {
      if (
        singleDecision?.isQueryError &&
        singleDecision.reason === ENVIRONMENT_MISSMATCH_IS_SANDBOX
      ) {
        setDecisionEnvParam(DecisionEnvironment.SANDBOX);
      } else {
        setDecisionEnvParam(DecisionEnvironment.LIVE);
      }
    }
  }, [
    decisionEnvParam,
    entityId,
    decisionsOverview,
    setDecisionEnvParam,
    decisionId,
    singleDecision,
    confirmedNoLiveDecisions,
    setConfirmedNoLiveDecisions,
  ]);
};
