import React, { useState } from "react";
import { twJoin } from "tailwind-merge";
import { v4 as uuid } from "uuid";

import { FlowVersionFlowChild } from "src/api/flowTypes";
import { LoadingView } from "src/base-components/LoadingView";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import { MENU_DIVIDER } from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { Skeleton } from "src/base-components/Skeleton";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { ReviewDb } from "src/clients/flow-api";
import { DescriptionRow } from "src/flow/DescriptionRow";
import { RelatedFlowsPill, SandboxDefaultPill } from "src/flow/Pills";
import { UserFromId } from "src/flow/User";
import { VersionNameExpandable } from "src/flow/VersionName";
import { JobsPill } from "src/flow/VersionsTable/JobsPill";
import { Cell, Row, Table } from "src/flow/VersionsTable/VersionsTable";
import { VersionsTableColumn } from "src/flow/VersionsTable/types";
import { ExcludesFalse } from "src/flow/types";
import { publishingBlockedReason } from "src/flowContainer/versionActionConditions";
import { ReviewProcessPills } from "src/flowReview/ReviewStatusPills";
import { useFlowVersionReview } from "src/flowReview/api/queries";
import { useCapabilities } from "src/hooks/useCapabilities";
import { useJobs } from "src/jobs/api/queries";
import { useFlowContext } from "src/router/routerContextHooks";
import { getUrlToAuthoringPage } from "src/router/urls";
import { formatDate } from "src/utils/datetime";

type InReviewVersionRowProps = {
  version: FlowVersionFlowChild;
  isDefaultSandbox: boolean;
  onSetDefaultSandbox: () => void;
  onDuplicate: () => void;
  onDuplicateToFlow: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onPublish: () => void;
  canDelete: boolean;
};

export const InReviewVersionRow: React.FC<InReviewVersionRowProps> = ({
  version,
  isDefaultSandbox,
  onSetDefaultSandbox,
  onDuplicate,
  onDuplicateToFlow,
  onEdit,
  onDelete,
  onPublish,
  canDelete,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const { orgId, workspace, flow } = useFlowContext();
  const reviewQuery = useFlowVersionReview(version.id);

  const { flowVersions } = useCapabilities();
  const canPublish = flowVersions.canPublish(flow);

  const options = [
    { key: "Edit", action: onEdit },
    { key: "Duplicate", action: onDuplicate },
    !isDefaultSandbox && {
      key: "Set Sandbox Default",
      action: onSetDefaultSandbox,
    },
    canPublish && MENU_DIVIDER,
    canPublish && {
      key: "Publish",
      action: onPublish,
      disabled: publishingBlockedReason(flow, reviewQuery.data),
    },
    flowVersions.canCreate && MENU_DIVIDER,
    flowVersions.canCreate && {
      key: "Duplicate to another Decision Flow",
      action: onDuplicateToFlow,
    },
    canDelete && MENU_DIVIDER,
    canDelete && { key: "Delete", action: onDelete },
  ].filter(Boolean as any as ExcludesFalse);

  const jobs = useJobs(workspace.base_url!, flow.id);

  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);
  const onClickExpand = () => setExpanded(!expanded);

  return (
    <>
      <Row
        className={twJoin("cursor-pointer", isHover && "!bg-gray-50")}
        to={getUrlToAuthoringPage(orgId, workspace.id, flow.id, version.id)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Cell data-loc="version-name" font="inter-medium-13px">
          <VersionNameExpandable
            expanded={expanded}
            version={version}
            onClickExpand={onClickExpand}
          />
        </Cell>
        <Cell>
          <UserFromId userId={version.meta.created_by_id || ""} />
        </Cell>
        <Cell>
          <div className="whitespace-nowrap">
            {formatDate(version.updated_at)}
          </div>
        </Cell>
        <Cell paddingY="none">
          <LoadingView
            queryResult={reviewQuery}
            renderUpdated={(review: ReviewDb | null) => {
              if (!review) return <></>;
              else return <ReviewProcessPills review={review} />;
            }}
            renderUpdating={() => <SkeletonPlaceholder height="h-4" />}
          />
        </Cell>
        <Cell>
          <JobsPill
            jobs={jobs.data?.filter(
              (job) => job.flow_version_id === version.id,
            )}
          />
        </Cell>
        <Cell>
          <div className="inline-flex items-center gap-2">
            {Boolean(version.child_flows?.length) && (
              <RelatedFlowsPill
                flows={version.child_flows!}
                includeVersions
                isParent
              />
            )}
            {Boolean(version.parent_flows?.length) && (
              <RelatedFlowsPill flows={version.parent_flows!} includeVersions />
            )}
          </div>
        </Cell>
        <Cell align="right">
          <div className="inline-flex items-center gap-2">
            {isDefaultSandbox && <SandboxDefaultPill />}
          </div>
        </Cell>
        <Cell align="right">
          {flowVersions.canEdit && (
            <EllipsisOptionsDropdown
              buttonClassName="text-gray-300 w-5"
              buttonDataLoc={`${version.name}-options`}
              elements={options}
              menuClassName="inline-block"
              timeoutDuration={200}
            />
          )}
        </Cell>
      </Row>

      {expanded && (
        <tr
          className={twJoin(
            "group border-t-transparent transition-colors duration-150",
            isHover && "bg-gray-50",
          )}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <DescriptionRow description={version.meta.release_note} />
        </tr>
      )}
    </>
  );
};

export const InReviewVersionRowSkeleton: React.FC = () => (
  <tr>
    <Cell data-loc="version-name" font="inter-medium-13px">
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell align="right">
      <Skeleton />
    </Cell>
  </tr>
);

type InReviewVersionsTableProps = {
  children: React.ReactNode;
  animate: boolean;
};

const IN_REVIEW_TABLE_COLUMNS: VersionsTableColumn[] = [
  {
    id: uuid(),
    name: "In review",
    width: "w-1/5",
  },
  { id: uuid(), name: "Created by", width: "w-1/6" },
  { id: uuid(), name: "Last edited", width: "w-1/6" },
  { id: uuid(), name: "Review status", width: "w-1/6" },
  {
    id: uuid(),
    name: "Jobs",
    width: "w-1/8",
  },
  { id: uuid(), name: "Decision Flows", width: "w-1/8" },
  { id: uuid(), name: "" },
  { id: uuid(), name: "", width: "w-16" },
].filter(Boolean as any as ExcludesFalse);

export const InReviewVersionsTable: React.FC<InReviewVersionsTableProps> = ({
  children,
  animate,
}) => (
  <Table
    animate={animate}
    columns={IN_REVIEW_TABLE_COLUMNS}
    dataLoc="in-review-versions-table"
  >
    {children}
  </Table>
);
