import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { Control, Controller, UseFormRegisterReturn } from "react-hook-form";

import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { DropDown, RenderValueFn } from "src/base-components/DropDown";
import { FormItem } from "src/base-components/FormItem";
import { Icon } from "src/base-components/Icon";
import { InputMappingRowTooltipBody } from "src/integrationNode/editorComponents/InputMappingRowTooltip";
import { InputMappingsT, InputMappingT } from "src/integrationNode/types";

const renderValue: RenderValueFn<string> = ({ value }) => (
  <div className="font-fira-code w-full px-4 py-2">{value}</div>
);

const renderButtonValue = (value?: string) => (
  <div className="flex h-8 items-center justify-between py-1 pl-2.5 pr-1.5">
    <div className="font-fira-code grow text-left text-gray-800">{value}</div>
    <div className="flex h-auto items-center">
      <Icon color="text-gray-500" icon={faChevronDown} size="xs" />
    </div>
  </div>
);

type PropsT = {
  inputMapping: InputMappingT;
  beName?: string;
  formControl: Control<InputMappingsT>;
  // To be able to use the typed react hook forms controller in this component, we have to specify
  // which fields the component excepts
  inputProps: UseFormRegisterReturn<
    | `grouped.${string}.${string}.assignedTo`
    | `ungrouped.${string}.assignedTo`
    | `lists.${string}.elements.${string}.${string}.assignedTo`
  >;
  runFieldError: string | undefined;
  immutable: boolean;
};

export const InputMappingRow: React.FC<PropsT> = ({
  inputMapping,
  beName = "",
  formControl,
  inputProps,
  runFieldError,
  immutable,
}) => {
  const renderInput = () => {
    if (inputMapping.type === "dropDown") {
      return (
        <Controller
          control={formControl}
          name={inputProps.name}
          render={({ field }) => (
            <DropDown<string>
              className="flex-grow"
              disabled={immutable}
              elements={inputMapping.elements}
              itemsClassNames="w-full"
              renderButtonValue={renderButtonValue}
              renderValue={renderValue}
              selectedKey={field.value}
              onSelect={(key) => {
                field.onChange(key);
              }}
            />
          )}
        />
      );
    }
    if (inputMapping.type === "text") {
      return (
        <Controller
          control={formControl}
          name={inputProps.name}
          render={({ field }) => (
            <AutocompleteCodeInput
              disabled={immutable}
              error={runFieldError}
              placeholder="data.field"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      );
    }
  };
  const hasTooltipContent =
    inputMapping.hint ||
    inputMapping.beType ||
    inputMapping.rules?.minLength ||
    inputMapping.rules?.maxLength ||
    inputMapping.example;

  return (
    <FormItem
      className="flex w-full flex-row items-center"
      dataLoc={`input-mapping-row-${beName}`}
      gap="xxs"
    >
      <FormItem.Label
        className="mr-4 w-[168px] text-gray-700 font-inter-normal-12px"
        dataLoc={`input-mapping-row-${beName}-name`}
        helpTooltipBody={
          hasTooltipContent ? (
            <InputMappingRowTooltipBody {...inputMapping} />
          ) : undefined
        }
        isRequired={inputMapping.rules?.required}
      >
        {inputMapping.displayName}
      </FormItem.Label>
      {renderInput()}
    </FormItem>
  );
};
