// External imports
import {
  faCamera,
  faTimes,
  faCircleNotch,
} from "@fortawesome/pro-regular-svg-icons";
import React, { useState, useRef } from "react";

// Internal imports
import { ConnectionsEndpoint } from "src/api/connectApi/endpoints";
import { useWorkspace } from "src/api/queries";
import * as s3Api from "src/api/s3";
import { Icon } from "src/base-components/Icon";
import { CustomIcon } from "src/connections/config/CustomIcon";
import { toastActions } from "src/design-system/Toast/utils";
import { DashboardPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

const HIDDEN_INPUT_STYLE = { display: "none" };
const CROSS_ICON_CLASSES =
  "group absolute right-0 top-[-9px] z-10 h-[18px] w-[18px] cursor-pointer rounded-[4px] border border-gray-300 bg-gray-200 p-[3px] text-center text-[10px] font-bold text-gray-800 invisible group-hover:visible no-transition";
const CONTAINER_CLASSES =
  "relative mt-[9px] flex h-[68px] w-[68px] cursor-pointer rounded-lg bg-gray-100 text-gray-500";

const getFileExtension = (file: File) => {
  const fileName = file.name;
  return fileName.substring(fileName.lastIndexOf(".") + 1);
};

const uploadIconToS3 = async (file: File, baseUrl: string) => {
  const { url, fields } = await ConnectionsEndpoint.getSignedUrlLogo(
    baseUrl,
    getFileExtension(file),
  );
  await s3Api.uploadFile({ url, fields }, file);
  return fields.key;
};

interface IconUploadProps {
  onUploadStarted?: () => void;
  onFileSelected?: (file: File) => void;
  onFileRemoved?: () => void;
  onUploadFinished?: (success: boolean, key?: string) => void;
  errorMessage?: string;
  mediaKey?: string | null;
}

export const UploadIcon: React.FC<IconUploadProps> = ({
  onUploadStarted,
  onFileSelected,
  onUploadFinished,
  onFileRemoved,
  mediaKey,
  errorMessage = "Use a square image under 100 KB in size. Supported formats are SVG, PNG, JPG, and GIF.",
}) => {
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [previewURL, setPreviewURL] = useState<string>("");
  const [iconMediaKey, setIconMediaKey] = useState<string>(mediaKey || "");
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { wsId } = useParamsDecode<DashboardPageParamsT>();
  const workspace = useWorkspace(wsId);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = event.target.files || [];

    if (file && workspace.data?.base_url) {
      onFileSelected?.(file);
      uploadSelectedFile(file, workspace.data.base_url);
    }
  };

  const uploadSelectedFile = async (file: File, baseUrl: string) => {
    onUploadStarted?.();
    setUploadInProgress(true);

    try {
      const mediaKey = await uploadIconToS3(file, baseUrl);
      setPreviewURL(URL.createObjectURL(file));
      toastActions.success({ title: "Logo uploaded successfully" });
      onUploadFinished?.(true, mediaKey);
    } catch (error) {
      resetPreview();
      toastActions.failure({
        title: "Image upload failed",
        description: errorMessage,
      });
      onUploadFinished?.(false);
    } finally {
      setUploadInProgress(false);
    }
  };

  const resetPreview = () => {
    setPreviewURL("");
    setIconMediaKey("");
    onFileRemoved?.();
    inputFileRef.current?.value && (inputFileRef.current.value = "");
  };

  const shouldShowCrossIcon = previewURL || iconMediaKey;

  return (
    <div className="group relative h-[77px] w-[77px]">
      <input
        ref={inputFileRef}
        accept=".svg,.jpg,.jpeg,.png,.gif"
        data-loc="icon-upload-input"
        style={HIDDEN_INPUT_STYLE}
        type="file"
        onChange={handleFileChange}
      />

      {shouldShowCrossIcon && (
        <span
          className={CROSS_ICON_CLASSES}
          data-loc="icon-upload-remove"
          onClick={(e) => {
            e.stopPropagation();
            resetPreview();
          }}
        >
          <Icon icon={faTimes} padding={false} size="2xs" />
        </span>
      )}

      <div
        className={CONTAINER_CLASSES}
        onClick={() => inputFileRef.current?.click()}
      >
        <label className="flex h-full w-full cursor-pointer items-center justify-center">
          {uploadInProgress ? (
            <div className="justify-center">
              <Icon color="gray" icon={faCircleNotch} spin={true} />
            </div>
          ) : previewURL ? (
            <img
              alt="Preview"
              className="h-full w-full object-contain"
              src={previewURL}
            />
          ) : iconMediaKey ? (
            <CustomIcon
              className="h-full w-full object-contain"
              mediaKey={iconMediaKey}
            />
          ) : (
            <Icon dataLoc="upload-preview-icon" icon={faCamera} size="lg" />
          )}
        </label>
      </div>
    </div>
  );
};
