import {
  BigQuerySQLConnectionAuthMethod,
  CustomConnectionAuthMethod,
  InboundWebhookConnectionAuthMethod,
  InboundWebhookResourceT,
  IntegrationProviderT,
  MySQLConnectionAuthMethod,
  PostgreSQLConnectionAuthMethod,
  ProviderT,
  RedshiftConnectionAuthMethod,
  RedshiftSSLMode,
  ResourceConfigT,
  ResourceTypesForIntegrationProvider,
  SnowflakeConnectionAuthMethod,
  TLSMode,
} from "src/api/connectApi/types";
import {
  Clause,
  DataRetentionUnit as DataRetentionUnitEnum,
  Junctions,
} from "src/clients/flow-api";

export type ConnectionAuthNoAuthT = {
  auth_method: "no_auth";
};

export type ConnectionAuthAPIKeyT = {
  auth_method: "api_key";
  header?: string;
  prefix?: string;
  api_key_as_query_param?: boolean;
  query_param_name?: string;
};
export type ConnectionAuthAWSSignatureT = {
  auth_method: "aws_signature";
  aws_access_key: string;
  aws_host: string;
  aws_region: string;
  aws_service: string;
};

export type KeyValuePairT = {
  key: string;
  value: string | null;
  secret?: boolean;
  required?: boolean;
  environment?: string | null;
  tenant_id?: string | null;
};

export type ConnectionSSLConfigValueT = {
  value: string | null;
  secret?: boolean;
};

export type ConnectionSSLConfigT = {
  ssl_certificate: ConnectionSSLConfigValueT;
  ssl_key: ConnectionSSLConfigValueT;
};

export type ConnectionAuthOAuth2T = {
  auth_method: "oauth2";
  uses_basic_auth: boolean;
  basic_auth_header?: string;
  basic_auth_prefix?: string;
  authorize_url?: string;
  authorize_query_params?: KeyValuePairT[];
  authorize_headers?: KeyValuePairT[];
  authorize_body?: string;
  authorize_body_token_path?: string;
  authorize_body_expires_path?: string;
  scopes?: string;
  scope_delimiter?: string;
  header?: string;
  prefix?: string;
  // always client_credentials
  grant_type?: "client_credentials";

  // Refresh properties
  refresh_url?: string;
  refresh_query_params?: KeyValuePairT[];
  refresh_headers?: KeyValuePairT[];
  refresh_body?: string;
  token_refresh_interval?: number;
};
export type ConnectionAuthBasicAuthT = {
  auth_method: "basic";
};

export type InboundWebhookConnectionAuthNoAuthT = {};

export type InboundWebhookConnectionAuthBasicAuthT = {
  login: KeyValuePairT; // secret
  password: KeyValuePairT; // secret
};

export type InboundWebhookConnectionAuthAPIKeyT = {
  header?: string | null;
  prefix?: string | null;
  apiKey: KeyValuePairT; // secret
};

export type InboundWebhookConnectionAuthHMACT = {
  hashAlgorithm: string;
  signatureEncoding: string;
  header: string;
  signingKey: KeyValuePairT; // secret
};

export type PostgreSQLConnectionAuthUsernamePasswordT = {
  username: KeyValuePairT; // secret
  password: KeyValuePairT; // secret
};

export type MySQLConnectionAuthUsernamePasswordT = {
  username: KeyValuePairT; // secret
  password: KeyValuePairT; // secret
};

export type BigQuerySQLConnectionAuthServiceAccountT = {
  service_account_key: KeyValuePairT; // secret
};

export type SnowflakeConnectionBasicAuthT = {
  basicAuthUsername: KeyValuePairT; // secret
  password: KeyValuePairT; // secret
};

export type SnowflakeConnectionKeyPairAuthT = {
  username: KeyValuePairT; // secret
  privateKey: KeyValuePairT; // secret
  privateKeyPassphrase: KeyValuePairT; // secret
};

export type RedshiftConnectionUsernamePasswordAuthT = {
  username: KeyValuePairT; // secret
  password: KeyValuePairT; // secret
};

export type RedshiftConnectionAwsIamAuthT = {
  databaseUsername: KeyValuePairT; // secret
  awsAccessKeyId: KeyValuePairT; // secret
  awsSecretKey: KeyValuePairT; // secret
};

export type ConnectionAuthGoogleOAuth2T = {
  auth_method: "google_oauth2";
  authorize_url: string;
  scope: string;
  filename?: string;
  token_refresh_interval?: number;
};
export type ConnectionConfigAuthMethods = {
  authMethod: CustomConnectionAuthMethod;
  // React hook form doesn't support union types: https://github.com/react-hook-form/react-hook-form/issues/9287
  noAuthConfig: ConnectionAuthNoAuthT;
  basicAuthConfig: ConnectionAuthBasicAuthT;
  oauth2Config: ConnectionAuthOAuth2T;
  apiKeyConfig: ConnectionAuthAPIKeyT;
  awsSignatureConfig: ConnectionAuthAWSSignatureT;
  googleOAuth2Config: ConnectionAuthGoogleOAuth2T;
};

export type ConnectionConfigT = ConnectionConfigAuthMethods & {
  url: string;
  probeUrl: string;
  secrets?: KeyValuePairT[];
  headers: KeyValuePairT[];
  allowTestInvocations: boolean;

  enableSSL: boolean;
  sslConfig?: ConnectionSSLConfigT;
  hasSSHTunnelEnabled: boolean;
  SSHTunnel?: DBConnectionSSHTunnel;
};

export type DataRetentionUnit = `${DataRetentionUnitEnum}`;

export type DataRetention = {
  value: number;
  unit: DataRetentionUnit;
};

export type ConnectionConfigInputsT = ConnectionConfigT & {
  name: string;
  mediaKey?: string | null;
  hasRawProviderResponseEnabled: boolean;
  hasRawProviderRequestEnabled: boolean;
  dataRetentionDays: number;
  enableNonProdConfigs: boolean;
  dataRetention: DataRetention;

  nonProdEnvConfigs?: Record<string, ConnectionConfigT>;
};

export type InboundWebhookConnectionConfigT = {
  webhookAuthMethod: InboundWebhookConnectionAuthMethod;
  webhookNoAuthConfig: InboundWebhookConnectionAuthNoAuthT;
  webhookBasicAuthConfig: InboundWebhookConnectionAuthBasicAuthT;
  webhookApiKeyConfig: InboundWebhookConnectionAuthAPIKeyT;
  webhookHMACConfig: InboundWebhookConnectionAuthHMACT;
  webhookURL: string | null;
  allowTestInvocations: boolean;
};

export type InboundWebhookResourceConfigT = {
  id: string;
  name: string;
  resource: InboundWebhookResourceT;
  correlationIDPath: string;
  activation: {
    junction: Junctions;
    /* This is a temporary hack, otherwise we get the error:
      "Type 'Clause' is not assignable to type 'ConfigRecordT'.
      Index signature for type 'string' is missing in type 'Clause'."
      Which is caused because Clause now is an interface (instead of a type)
     */
    clauses: (Clause & { [key: string]: any })[];
  } | null;
};

export type InboundWebhookConnectionConfigInputsT = {
  name: string;
  dataRetention: DataRetention;
  enableNonProdConfigs: boolean;

  productionConfig: InboundWebhookConnectionConfigT;
  sandboxConfig: InboundWebhookConnectionConfigT | null;
  resourceConfigs: InboundWebhookResourceConfigT[];
  customSuccessStatusCode: number | null;
};

export type MTLSConfig = {
  mode: TLSMode;
  clientCertificate: string;
  clientKey: KeyValuePairT; // secret
  CACertificate: string;
};

export type PostgreSQLConnectionMTLSConfig = MTLSConfig;
export type MySQLConnectionMTLSConfig = MTLSConfig;

/// DB Connection SSH Tunnel Config
export type DBConnectionSSHTunnel = {
  host: string;
  port: number;
  user: string;
  privateKey: KeyValuePairT;
};
export interface DBSSHTunnelInputs {
  SSHTunnel?: DBConnectionSSHTunnel;
  hasSSHTunnelEnabled: boolean;
}

export type PostgreSQLConnectionConfigT = {
  databaseName: string;
  host: string;
  port?: number | null;
  authMethod: PostgreSQLConnectionAuthMethod;
  usernamePasswordConfig: PostgreSQLConnectionAuthUsernamePasswordT;
  allowTestInvocations: boolean;
  hasMTLSEnabled: boolean;
  mTLSConfig: PostgreSQLConnectionMTLSConfig;
} & DBSSHTunnelInputs;

export type PostgreSQLConnectionConfigInputsT = {
  name: string;
  dataRetention: DataRetention;
  enableNonProdConfigs: boolean;

  productionConfig: PostgreSQLConnectionConfigT;
  sandboxConfig: PostgreSQLConnectionConfigT | null;
};

export type MySQLConnectionConfigT = {
  databaseName: string;
  host: string;
  port?: number | null;
  authMethod: MySQLConnectionAuthMethod;
  usernamePasswordConfig: MySQLConnectionAuthUsernamePasswordT;
  allowTestInvocations: boolean;
  hasMTLSEnabled: boolean;
  mTLSConfig: MySQLConnectionMTLSConfig;
};

export type MySQLConnectionConfigInputsT = {
  name: string;
  dataRetention: DataRetention;
  enableNonProdConfigs: boolean;

  productionConfig: MySQLConnectionConfigT;
  sandboxConfig: MySQLConnectionConfigT | null;
};
export type Environment = "production" | "sandbox";

export type PostgreSQLConnectionAuthConfigFieldT =
  `usernamePasswordConfig.${keyof PostgreSQLConnectionConfigT["usernamePasswordConfig"]}`;

export type MySQLConnectionAuthConfigFieldT =
  `usernamePasswordConfig.${keyof MySQLConnectionConfigT["usernamePasswordConfig"]}`;

export type BigQuerySQLConnectionConfigT = {
  scopes: string[];
  authMethod: BigQuerySQLConnectionAuthMethod;
  serviceAccountConfig: BigQuerySQLConnectionAuthServiceAccountT;
  allowTestInvocations: boolean;
};

export type BigQuerySQLConnectionConfigInputsT = {
  name: string;
  dataRetention: DataRetention;
  enableNonProdConfigs: boolean;

  productionConfig: BigQuerySQLConnectionConfigT;
  sandboxConfig: BigQuerySQLConnectionConfigT | null;
};

export type SnowflakeConnectionConfigT = {
  accountIdentifier: string;
  warehouseName: string;
  databaseName: string;
  databaseSchema: string | null;
  userRole: string | null;
  authMethod: SnowflakeConnectionAuthMethod;
  basicAuthConfig: SnowflakeConnectionBasicAuthT;
  keyPairAuthConfig: SnowflakeConnectionKeyPairAuthT;
  allowTestInvocations: boolean;
};

export type SnowflakeConnectionConfigInputsT = {
  name: string;
  dataRetention: DataRetention;
  enableNonProdConfigs: boolean;

  productionConfig: SnowflakeConnectionConfigT;
  sandboxConfig: SnowflakeConnectionConfigT | null;
};

export type SnowflakeConnectionAuthConfigFieldT =
  | `basicAuthConfig.${keyof SnowflakeConnectionConfigT["basicAuthConfig"]}`
  | `keyPairAuthConfig.${keyof SnowflakeConnectionConfigT["keyPairAuthConfig"]}`;

export type RedshiftConnectionSslConfigT = {
  mode: RedshiftSSLMode;
};

export type RedshiftConnectionConfigT = {
  host?: string | null;
  port?: number | null;
  databaseName?: string | null;
  clusterIdentifier?: string | null;
  awsRegion?: string | null;
  roleArn?: string | null;
  hasSslEnabled: boolean;
  SSHTunnel?: DBConnectionSSHTunnel;
  hasSSHTunnelEnabled: boolean;
  authMethod: RedshiftConnectionAuthMethod;
  usernamePasswordConfig: RedshiftConnectionUsernamePasswordAuthT;
  awsIamConfig: RedshiftConnectionAwsIamAuthT;
  allowTestInvocations: boolean;
};

export type RedshiftConnectionConfigInputsT = {
  name: string;
  dataRetention: DataRetention;
  enableNonProdConfigs: boolean;

  productionConfig: RedshiftConnectionConfigT;
  sandboxConfig: RedshiftConnectionConfigT | null;
};

export type RedshiftConnectionAuthConfigFieldT =
  | `usernamePasswordConfig.${keyof RedshiftConnectionConfigT["usernamePasswordConfig"]}`
  | `awsIamConfig.${keyof RedshiftConnectionConfigT["awsIamConfig"]}`;

export type ProviderSelectInputsT = {
  provider?: ProviderT | string;
  isManifestProvider: boolean;
};

export type ConnectionInputsT = {
  selectProviderForm: ProviderSelectInputsT;
  configConnectionForm: ConnectionConfigInputsT;
};

// Undefined secret type maps to a basic text input
type ConnectionSecretsT = "multiline" | null;

export type ConnectionTemplate<
  IntegrationProvider extends IntegrationProviderT,
> = {
  provider: IntegrationProvider;
  secrets: {
    key: string;
    name: string;
    hint?: string;
    required?: boolean;
    type?: ConnectionSecretsT;
  }[];
  resources: Record<
    ResourceTypesForIntegrationProvider<IntegrationProvider>,
    ConnectionResourceTemplate
  >;
};

export type ConnectionResourceTemplate = {
  name: string;
  deprecated?: boolean;
  configKeys: ConnectionResourceTemplateConfigKey[];
  hint?: string;
};

export type ConnectionResourceTemplateConfigKeyParent = {
  key: string;
  title: string;
  subtitle?: string;
  required?: boolean;
};

export type ConnectionResourceTemplateConfigKey = {
  key: string;
  name: string;
  required: boolean;
  hint?: string;
  placeholder?: string;
  parent?: ConnectionResourceTemplateConfigKeyParent;
  // Callback to hide optional configuration fields based on
  // values of other fields
  isHidden?: (config: ResourceConfigT) => boolean;
} & (
  | {
      type: "text";
    }
  | {
      type: "number";
    }
  | {
      type: "inline_number";
    }
  | {
      type: "simple_selection";
      elements: { key: string | number | undefined; value: string }[];
    }
  | {
      type: "multiple_selection";
      elements: { key: string | number; value: string }[];
      minItems?: number;
      maxItems?: number;
    }
  | {
      type: "switch";
    }
);

// Needed to make TypeScript infer the generic
// parameter based on the provider field
export const makeConnectionTemplate = <
  IntegrationProvider extends IntegrationProviderT,
>(
  template: ConnectionTemplate<IntegrationProvider>,
): ConnectionTemplate<IntegrationProvider> => template;

// This should be changed to support any string
// instead of just "sandbox" once we support multiple environments
export type AvailableEnvironmentPrefixes = "nonProdEnvConfigs.sandbox." | "";

export type ConnectionCreationFieldError = {
  loc: string | null;
  msg: string;
};

export type InboundWebhookValidationErrorT = {
  loc: (string | number)[];
  msg: string;
  type?: string;
  ctx?: {
    msg?: string;
    field_errors?: ConnectionCreationFieldError[];
  };
};

export type InboundWebhookValidationErrorResponseT = {
  detail?: InboundWebhookValidationErrorT[];
};

export type RetoolConnectionConfigT = {
  url: string;
  appUUID: string;
  groupID: string;
  apiKey: KeyValuePairT;
};

export type RetoolConnectionConfigInputsT = {
  name: string;
  dataRetention: DataRetention;
  enableNonProdConfigs: boolean;

  productionConfig: RetoolConnectionConfigT;
  sandboxConfig: RetoolConnectionConfigT | null;
};

export type DBConnectionWithSSHTunnelInputs = PostgreSQLConnectionConfigInputsT;
