import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";

const VARIANTS = {
  default: "bg-indigo-100 text-indigo-600",
  error: "bg-red-100 text-red-600",
} as const;

const SIZES = {
  md: "py-16",
  sm: "py-4",
} as const;

export type ActionButtonProps = {
  text: string;
  icon?: IconDefinition;
  disabled?: boolean;
} & (
  | {
      onClick: () => void;
      linkTo?: never;
    }
  | {
      linkTo: string;
      onClick?: never;
    }
);

export type EmptyProps = {
  dataLoc?: string;
  icon: IconProp;
  headline: React.ReactNode;
  description: React.ReactNode;
  action?: React.ReactNode;
  variant?: keyof typeof VARIANTS;
  size?: keyof typeof SIZES;
  actionButton?: ActionButtonProps | false;
};

/**
 * Figma: https://www.figma.com/design/BO9DWO8PKNGNSI3HhpSobA/Design-System-v2?node-id=6506-9504&node-type=frame&m=dev
 */

export const EmptyState: React.FC<EmptyProps> = ({
  dataLoc,
  icon,
  headline,
  description,
  action,
  actionButton,
  variant = "default",
  size = "md",
}) => (
  <div
    className={twJoin(
      "flex h-full flex-col items-center justify-center text-center",
      SIZES[size],
    )}
    data-loc={dataLoc}
  >
    <div
      className={twJoin("mb-4 inline-block rounded-lg p-3", VARIANTS[variant])}
    >
      <Icon icon={icon} />
    </div>
    <h1 className="mb-2 inline-block max-w-60 text-gray-800 font-inter-semibold-13px">
      {headline}
    </h1>
    <p
      className={twJoin(
        "inline-block min-h-0 max-w-60 overflow-hidden break-words text-gray-500 font-inter-normal-12px",
        (action || actionButton) && "mb-6",
      )}
    >
      {description}
    </p>
    {actionButton &&
      (actionButton.linkTo ? (
        <Link to={actionButton.linkTo}>
          <Button
            dataLoc={`${dataLoc}-action`}
            disabled={actionButton.disabled}
            iconLeft={actionButton.icon}
            variant="secondary"
          >
            {actionButton.text}
          </Button>
        </Link>
      ) : (
        <Button
          dataLoc={`${dataLoc}-action`}
          disabled={actionButton.disabled}
          iconLeft={actionButton.icon}
          variant="secondary"
          onClick={actionButton.onClick}
        >
          {actionButton.text}
        </Button>
      ))}
    {action}
  </div>
);
