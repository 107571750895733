import { useState } from "react";

import { FlowT } from "src/api/flowTypes";
import { SearchBox } from "src/base-components/SearchBox";
import { FlowCard } from "src/nodeEditor/SelectFlowPane/FlowCard";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useFuseSearch } from "src/utils/useFuseSearch";

type Props = {
  flows: FlowT[];
  onSelect: (flow: FlowT) => void;
};

export const FlowList: React.FC<Props> = ({ flows, onSelect }) => {
  const { flow: currentFlow } = useAuthoringContext();
  const [filter, setFilter] = useState("");
  const search = useFuseSearch(
    flows.filter((flow) => flow.id !== currentFlow.id),
    { keys: ["name"] },
  );

  return (
    <div className="flex h-full flex-col">
      <div className="mb-2">
        <div className="border-b border-gray-100 pb-2">
          <SearchBox
            dataLoc="search-select-child-flow"
            placeholder="Search a Decision Flow"
            onChange={setFilter}
          />
        </div>
      </div>
      <ul className="decideScrollbar -mr-4 flex flex-col gap-y-3 !overflow-y-scroll">
        {search(filter).map((flow) => (
          <FlowCard key={flow.id} flow={flow} onClick={() => onSelect(flow)} />
        ))}
      </ul>
    </div>
  );
};
