import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowDbShallow, FlowVersionDbShallow } from "src/clients/flow-api";
import {
  HistoricalDecisionV3,
  HistoricalDecisionV3OriginTypeEnum,
} from "src/clients/history-v3";
import {
  SourceDetailType,
  SourceDetails,
} from "src/flow/decisionHistory/SourcePill";
import { Job } from "src/jobs/types";

export const getSourceDetails = (
  record: DecisionHistoryRecordV2 | HistoricalDecisionV3,
  parentFlows: FlowDbShallow[],
  potentialParentVersions: FlowVersionDbShallow[],
  jobs: Job[],
): SourceDetails => {
  if ("origin" in record) {
    if (record.origin.type === HistoricalDecisionV3OriginTypeEnum.API_CALL) {
      return { type: SourceDetailType.ApiCall };
    } else if (record.origin.type === HistoricalDecisionV3OriginTypeEnum.FLOW) {
      return {
        type: SourceDetailType.ParentFlow,
        parentFlow: parentFlows.find(
          (parentFlow) => parentFlow.id === record.origin.parent_flow_id,
        ),
        parentVersion: potentialParentVersions?.find(
          (potentialParentVersion) =>
            potentialParentVersion?.id === record.origin.parent_flow_version_id,
        ),
        parentDecisionId: record.origin.parent_decision_id,
      };
    } else {
      const job = jobs.find((job) => job.id === record.origin.job_id);
      return {
        type: SourceDetailType.Job,
        job: job,
        runId: record.origin.job_run_id!,
      };
    }
  } else {
    if (
      record.parent_flow_id &&
      record.parent_flow_version_id &&
      record.parent_decision_id
    ) {
      const parentFlow = parentFlows.find(
        (parentFlow) => parentFlow.id === record.parent_flow_id,
      );
      const parentVersion = potentialParentVersions?.find(
        (potentialParentVersion) =>
          potentialParentVersion?.id === record.parent_flow_version_id,
      );
      return {
        type: SourceDetailType.ParentFlow,
        parentFlow,
        parentVersion,
        parentDecisionId: record.parent_decision_id,
      };
    }
    if (record.response.metadata.job_run_id) {
      const job = jobs.find(
        (job) => job.id === record.response?.metadata?.job_id,
      );
      return {
        type: SourceDetailType.Job,
        job: job,
        runId: record.response.metadata.job_run_id,
      };
    } else return { type: SourceDetailType.ApiCall };
  }
};
