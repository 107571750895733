import { faChartSimple } from "@fortawesome/pro-regular-svg-icons";
import { twJoin } from "tailwind-merge";

import { useAnalyticsPlot } from "src/analytics/hooks";
import { ObservableChartSpec } from "src/analytics/utils";
import { GenericObjectT } from "src/api/flowTypes";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { EmptyState } from "src/design-system/EmptyState";

export const ObservableChartBase: React.FC<{
  data: GenericObjectT[];
  spec: ObservableChartSpec;
  fitContainer?: boolean;
}> = ({ data, spec, fitContainer = false }) => {
  const { containerRef, error } = useAnalyticsPlot({
    data,
    spec,
    options: {
      fitContainer,
    },
  });

  return (
    <div className="relative h-full w-full">
      <div
        ref={containerRef}
        className={twJoin(
          "flex h-full w-full items-center justify-center",
          error && "invisible",
        )}
        data-loc="analytics-plot-container"
      />
      {error && (
        <div className="absolute inset-0">
          <EmptyState
            description={error.description}
            headline={error.headline}
            icon={faChartSimple}
            variant="error"
          />
        </div>
      )}
    </div>
  );
};

export const ObservableChartPlaceholder: React.FC = () => {
  return (
    <div className="flex h-full w-full flex-col items-center gap-8">
      <div className="flex h-full w-full items-center gap-7">
        <SkeletonPlaceholder height="h-32" width="w-4" />
        <div className="h-full flex-1 pt-4">
          <SkeletonPlaceholder height="h-full" width="w-full" />
        </div>
        <div className="flex flex-col gap-10">
          <SkeletonPlaceholder height="h-4" width="w-8" />
          <SkeletonPlaceholder height="h-4" width="w-8" />
          <SkeletonPlaceholder height="h-4" width="w-8" />
          <SkeletonPlaceholder height="h-4" width="w-8" />
        </div>
      </div>
      <SkeletonPlaceholder height="h-4" width="w-32" />
    </div>
  );
};

export const ObservableChart: React.FC<{
  data: GenericObjectT[];
  spec: ObservableChartSpec;
  isLoading?: boolean;
  fitContainer?: boolean;
}> = ({ data, spec, isLoading, fitContainer }) => {
  if (isLoading) {
    return <ObservableChartPlaceholder />;
  }

  return (
    <ObservableChartBase data={data} fitContainer={fitContainer} spec={spec} />
  );
};
