import {
  faChevronDown,
  faChevronLeft,
  faPlus,
  faTableColumns,
} from "@fortawesome/pro-regular-svg-icons";
import { Menu } from "@headlessui/react";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";

import { FlowVersionFlowChild } from "src/api/flowTypes";
import { DatasetColumnGroups } from "src/api/types";
import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { useAvailableIntegrationNodes } from "src/datasets/DatasetTable/hooks";
import { DatasetContext } from "src/datasets/DatasetTable/types";
import { useSchemas } from "src/datasets/utils";
import { EmptyState as BaseEmptyState } from "src/design-system/EmptyState";

type MenuElement = {
  key: string;
  value: string;
  children?: MenuElement[];
};

const AddColumnMenu: FC<{
  onAddColumn: (name: string, group: DatasetColumnGroups) => void;
  onAddAdditionalColumn: () => void;
  inputColumns: string[];
  outputColumns: string[];
  mockColumns: string[];
  context: DatasetContext;
}> = ({
  onAddColumn,
  onAddAdditionalColumn,
  inputColumns,
  outputColumns,
  mockColumns,
  context,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(searchParams.has("openAddColumnMenu"));
  const [view, setView] = useState<string>("");

  const isAuthoringDatasetContext = context === "authoring";

  useEffect(() => {
    setSearchParams((searchParams) => {
      searchParams.delete("openAddColumnMenu");
      return searchParams;
    });
  }, [setSearchParams]);

  const menu: MenuElement[] = useMemo(() => {
    const items = [];

    if (inputColumns.length > 0) {
      items.push({
        key: "input_columns",
        value: "Add input column",
        children: inputColumns.map((column) => ({
          key: column,
          value: column,
        })),
      });
    }

    if (isAuthoringDatasetContext && mockColumns.length > 0) {
      items.push({
        key: "mock_columns",
        value: "Add mock column",
        children: mockColumns.map((column) => ({
          key: column,
          value: column,
        })),
      });
    }

    if (isAuthoringDatasetContext && outputColumns.length > 0) {
      items.push({
        key: "output_columns",
        value: "Add expected column",
        children: outputColumns.map((column) => ({
          key: column,
          value: column,
        })),
      });
    }

    items.push({
      key: "additional_column",
      value: "Add additional column",
    });

    return items;
  }, [inputColumns, mockColumns, outputColumns, isAuthoringDatasetContext]);

  const handleClick =
    (element: MenuElement) => (e: React.MouseEvent<HTMLButtonElement>) => {
      if (element.children) {
        e.preventDefault();
        setView(element.key);
        return;
      }

      if (element.key === "additional_column") {
        onAddAdditionalColumn();
        return;
      }

      setView("");
      onAddColumn(element.key, view as DatasetColumnGroups);
    };

  const menuElements =
    view === ""
      ? menu
      : (menu.find((item) => item.key === view)?.children ?? menu);

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  return (
    <Menu ref={ref} as="div" className="relative">
      <Menu.Button onClick={() => setIsOpen((isOpen) => !isOpen)}>
        <Button iconLeft={faPlus} iconRight={faChevronDown} variant="secondary">
          Add column
        </Button>
      </Menu.Button>
      {isOpen && (
        <Menu.Items
          className="absolute left-1/2 mt-2 w-66 -translate-x-1/2 rounded-lg bg-white py-2 shadow-lg"
          static
        >
          {view !== "" && (
            <Menu.Item
              as="button"
              className="flex w-full items-center gap-x-2 px-4 py-2.5 text-gray-800 font-inter-normal-13px hover:bg-gray-50"
              onClick={(e) => {
                e.preventDefault();
                setView("");
              }}
            >
              <Icon icon={faChevronLeft} size="sm" />
              Back
            </Menu.Item>
          )}
          <div className="decideScrollbar max-h-48">
            {menuElements.map((element) => (
              <Menu.Item
                key={element.key}
                as="button"
                className="flex w-full items-center gap-x-2 px-4 py-2.5 text-gray-800 font-inter-normal-13px hover:bg-gray-50"
                onClick={handleClick(element)}
              >
                <Icon color="text-indigo-500" icon={faPlus} size="sm" />
                {element.value}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      )}
    </Menu>
  );
};

export const EmptyState: React.FC<{
  version?: FlowVersionFlowChild;
  onAddColumn: (name: string, group: DatasetColumnGroups) => void;
  onAddAdditionalColumn: () => void;
  context: DatasetContext;
}> = ({ version, onAddColumn, onAddAdditionalColumn, context }) => {
  const integrationNodes = useAvailableIntegrationNodes(version);
  const schemas = useSchemas(version);

  return (
    <BaseEmptyState
      action={
        <AddColumnMenu
          context={context}
          inputColumns={
            schemas.input?.properties?.map((prop) => prop.fieldName) ?? []
          }
          mockColumns={integrationNodes.map((node) => node.name)}
          outputColumns={
            schemas.output?.properties?.map((prop) => prop.fieldName) ?? []
          }
          onAddAdditionalColumn={onAddAdditionalColumn}
          onAddColumn={onAddColumn}
        />
      }
      description="Add a column to this dataset"
      headline="Add Column"
      icon={faTableColumns}
    />
  );
};
