import { faCheck, faFilter } from "@fortawesome/pro-regular-svg-icons";
import { faFilter as faFilterSolid } from "@fortawesome/pro-solid-svg-icons";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui2/react";
import { isEqual } from "lodash";
import { useState } from "react";
import { twJoin } from "tailwind-merge";

import { Button } from "src/base-components/Button";
import {
  FixedDropDownKey,
  FixedPositionedDropdown,
  FixedPositionedDropdownPropsT,
} from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { Dropdown, DropdownItem } from "src/design-system/Dropdown";

type PropsT<T extends string> = {
  isFiltering: boolean;
  onResetRequest: () => void;
  description: string;
  selected: T | undefined;
  onSelect: (key: T) => void;
  dataLoc?: string;
} & Pick<
  FixedPositionedDropdownPropsT<string, T>,
  "elements" | "placement" | "itemsPortal"
>;

export const ColumnFilter = <T extends string>({
  isFiltering,
  onResetRequest,
  description,
  dataLoc,
  ...props
}: PropsT<T>) => (
  <FixedPositionedDropdown
    buttonClassName="border-0 bg-transparent"
    dataLoc={dataLoc}
    elements={props.elements}
    itemsPortal={props.itemsPortal}
    multiple={false}
    placement={props.placement}
    renderButtonValue={() => (
      <Icon
        color={
          isFiltering ? "text-indigo-500" : "text-gray-500 hover:text-gray-700"
        }
        icon={isFiltering ? faFilterSolid : faFilter}
        size="2xs"
      />
    )}
    renderHeader={() => (
      <div className="flex w-[240px] cursor-default items-center justify-between px-5 py-3.5">
        <h3 className="font-inter-semibold-13px">{description}</h3>
        {isFiltering && (
          <button
            className="text-indigo-500 font-inter-medium-13px"
            onClick={onResetRequest}
          >
            Reset
          </button>
        )}
      </div>
    )}
    renderValue={({ value, selected, disabled }) => {
      return (
        <div
          className={twJoin(
            "flex w-[240px] items-center justify-between py-2 pl-5 pr-6",
            disabled && "cursor-default",
          )}
        >
          <span className={twJoin("inline-block", disabled && "text-gray-300")}>
            {value}
          </span>
          {selected && (
            <div className="inline-block">
              <Icon color="text-indigo-500" icon={faCheck} size="sm" />
            </div>
          )}
        </div>
      );
    }}
    selected={props.selected}
    onSelect={props.onSelect}
  />
);

type MultiPropsT<T extends FixedDropDownKey> = {
  isFiltering: boolean;
  onResetRequest: () => void;
  headline: string;
  defaultValue: T[] | undefined;
  onApply: (key: T[]) => void;
  dataLoc?: string;
  options: FixedPositionedDropdownPropsT<string, T>["elements"];
};

export const ColumnMultiValueFilter = <T extends FixedDropDownKey>({
  isFiltering,
  onResetRequest,
  options,
  defaultValue,
  onApply,
  headline,
  dataLoc,
}: MultiPropsT<T>) => {
  const [value, setValue] = useState<T[]>(defaultValue ?? []);

  return (
    <Listbox value={value} multiple onChange={setValue}>
      <ListboxButton data-loc={dataLoc}>
        <Icon
          color={
            isFiltering
              ? "text-indigo-500"
              : "text-gray-500 hover:text-gray-700"
          }
          icon={isFiltering ? faFilterSolid : faFilter}
          size="2xs"
        />
      </ListboxButton>
      <ListboxOptions
        anchor={{
          to: "bottom end",
        }}
        as={Dropdown}
        data-loc={`${dataLoc}-dropdown`}
        transition
      >
        <div className="flex h-10 items-center px-4 pb-2.5 text-gray-800 font-inter-semibold-13px">
          {headline}
        </div>
        {options.map(({ key, value }) => (
          <ListboxOption key={key} as={DropdownItem} value={key}>
            {value}
          </ListboxOption>
        ))}
        <div className="mt-2.5 flex cursor-default justify-between border-t border-gray-200 bg-white px-4 pb-2 pt-4">
          <Button
            dataLoc={`${dataLoc}-reset-button`}
            disabled={value?.length === 0}
            size="sm"
            variant="secondary"
            onClick={onResetRequest}
          >
            Reset
          </Button>
          <Button
            dataLoc={`${dataLoc}-apply-button`}
            disabled={
              isEqual(value, defaultValue) ||
              (defaultValue?.length === 0 && value?.length === 0)
            }
            size="sm"
            onClick={() => onApply(value)}
          >
            Apply
          </Button>
        </div>
      </ListboxOptions>
    </Listbox>
  );
};
