import {
  faCheckCircle,
  faWarning,
  faClock,
} from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { NodeRunStateV2 } from "src/constants/NodeDataTypes";

type IconProps = { marginLeft?: boolean };

const ErroredIcon: React.FC<IconProps> = ({ marginLeft = false }) => (
  <div
    className={twJoin("flex h-4 items-center", marginLeft && "ml-1")}
    data-loc="run-icon-error"
  >
    <Icon color="text-red-500" icon={faWarning} size="2xs" />
  </div>
);
const SuccessfulIcon: React.FC<IconProps> = ({ marginLeft = false }) => (
  <div
    className={twJoin("flex h-4 items-center", marginLeft && "ml-1")}
    data-loc="run-icon-success"
  >
    <Icon color="text-green-400" icon={faCheckCircle} size="2xs" />
  </div>
);
const PendingIcon: React.FC<IconProps> = ({ marginLeft = false }) => (
  <div
    className={twJoin("flex h-4 items-center", marginLeft && "ml-1")}
    data-loc="run-icon-pending"
  >
    <Icon color="text-indigo-400" icon={faClock} size="2xs" />
  </div>
);

type Props = { runStateV2: NodeRunStateV2 | undefined; marginLeft?: boolean };

export const NodeRunIconV2: React.FC<Props> = ({
  runStateV2,
  marginLeft = false,
}) => {
  if (!runStateV2 || runStateV2.type === "not-run") return <></>;

  if (
    runStateV2.type === "test-error" ||
    runStateV2.type === "historical-error"
  )
    return <ErroredIcon marginLeft={marginLeft} />;

  if (runStateV2.type === "historical-data")
    return (
      <div>
        <SuccessfulIcon marginLeft={marginLeft} />
      </div>
    );

  if (runStateV2.type === "historical-pending")
    return <PendingIcon marginLeft={marginLeft} />;

  const thereAreErrors = runStateV2.testResult.failure_count !== 0;

  if (thereAreErrors) return <ErroredIcon marginLeft={marginLeft} />;
  else return <SuccessfulIcon marginLeft={marginLeft} />;
};

export const GroupNodeRunIcon: React.FC<{
  runStates: (NodeRunStateV2 | undefined)[];
}> = ({ runStates }) => {
  const erroredRunState = runStates.find(
    (runState) =>
      runState?.type === "test-error" || runState?.type === "historical-error",
  );
  const childrenNodeHasFailures = runStates.find(
    (runState) =>
      runState?.type === "test-run" &&
      (runState.testResult.failure_count > 0 ||
        runState.testResult.ignored_count > 0),
  );
  if (erroredRunState || childrenNodeHasFailures) {
    return <ErroredIcon />;
  } else if (
    runStates.some((runState) => runState && runState.type !== "not-run")
  ) {
    return <SuccessfulIcon />;
  }
  return <></>;
};
