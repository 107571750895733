import { Pill } from "src/base-components/Pill";
import { BeMappedNode } from "src/constants/NodeDataTypes";
import { getNodeIconFromNode } from "src/constants/NodeIcons";
import { Tooltip } from "src/design-system/Tooltip";

export const NodePill: React.FC<{ node: BeMappedNode }> = ({ node }) => (
  <Tooltip placement="bottom" title={node.data.label} asChild>
    <Pill size="sm" variant="gray" fullWidth>
      <div className="-ml-px h-4 w-4">{getNodeIconFromNode(node, "sm")}</div>
      <Pill.Text>{node.data.label}</Pill.Text>
    </Pill>
  </Tooltip>
);
