import {
  faEye,
  faCircleNotch,
  faCircleInfo,
  faBan,
} from "@fortawesome/pro-regular-svg-icons";
import { ColumnDef, CellContext, Cell } from "@tanstack/react-table";
import { twJoin } from "tailwind-merge";

import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowT, FlowVersionT, GenericObjectT } from "src/api/flowTypes";
import { DecisionEnvironment } from "src/api/types";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { Icon } from "src/base-components/Icon";
import { RawCell } from "src/dataTable/RawCell";
import { ResultCell } from "src/dataTable/ResultCell";
import { Tooltip } from "src/design-system/Tooltip";
import { singleFlowGetParentFlows } from "src/flow/Model";
import { getSourceDetails } from "src/flow/decisionHistory/DecisionHistoryUtils";
import {
  SharedDecisionHistoryStatus,
  DecisionStatusCode,
  POSSIBLE_DECISION_STATUS_CODES,
} from "src/flow/decisionHistory/SharedStatusColumn";
import { SourceDetails, SourcePill } from "src/flow/decisionHistory/SourcePill";
import { Job } from "src/jobs/types";
import { formatDate } from "src/utils/datetime";
import { RowMenu } from "src/versionDecisionHistory/RowMenu";
import { getDisableReasonMessage } from "src/versionDecisionHistory/Table";
import { DecisionHistoryTableFilter } from "src/versionDecisionHistory/TableFilter";

export const CONTROL_COLUMN_ID = "control";

const HeaderCell: React.FC<{
  children?: React.ReactNode;
  className: string;
}> = ({ children, className }) => (
  <div
    className={twJoin(
      "py-1.5 pr-4 text-left text-gray-800 font-inter-medium-12px",
      className,
    )}
  >
    {children}
  </div>
);

type Args = {
  orgId: string;
  wsId: string;
  flow: FlowT;
  version: FlowVersionT;
  jobs: Job[];
  env: DecisionEnvironment;
  selectedRowIsLoading: boolean;
  shouldRowBeDisabled: (
    record: DecisionHistoryRecordV2,
  ) => "internal_error" | "outdated" | false;
  onAddTestCase: (decisionId: string) => void;
  statusCodeFilter: DecisionStatusCode | undefined;
  setStatusCodeFilter: (
    statusCodeFilter: DecisionStatusCode | undefined,
  ) => void;
  isPowertoolsEnabled?: boolean;
  xrayTraceURL?: string | null;
};

export const getVersionDecisionHistoryColumns = ({
  orgId,
  wsId,
  flow,
  version,
  jobs,
  env,
  selectedRowIsLoading,
  shouldRowBeDisabled,
  onAddTestCase,
  statusCodeFilter,
  setStatusCodeFilter,
  isPowertoolsEnabled,
  xrayTraceURL,
}: Args): ColumnDef<DecisionHistoryRecordV2, any>[] => {
  const statusFilterOptions = POSSIBLE_DECISION_STATUS_CODES.map((option) => ({
    ...option,
    disabled: false,
  }));
  const parentFlows = singleFlowGetParentFlows(flow);
  const parentVersions = version.parent_versions ?? [];

  const getRowControls = (
    cell: Cell<DecisionHistoryRecordV2<GenericObjectT>, any>,
  ) => {
    const isSelected = cell.row.getIsSelected();
    const disabledReason = shouldRowBeDisabled(cell.row.original);
    const currentDecision =
      cell.getContext().table.options.meta?.currentDecision;
    const isCurrentDecision = currentDecision?.id === cell.row.original.id;
    const hasLinkedDecisions =
      Object.keys(
        isCurrentDecision && currentDecision
          ? (currentDecision.linked_decisions ?? {})
          : {},
      ).length > 0;

    return (
      <div className="justify-right ml-auto flex w-12 flex-row items-center">
        {selectedRowIsLoading && isSelected ? (
          <span className="inline-block animate-spin justify-end align-middle text-indigo-400">
            <Icon icon={faCircleNotch} size="xs" />
          </span>
        ) : disabledReason ? (
          <Tooltip
            placement="top"
            title={getDisableReasonMessage(disabledReason)}
            asChild
          >
            <span className="cursor-default justify-end align-middle text-gray-500 opacity-0 group-hover/row:opacity-100">
              <Icon icon={faBan} size="sm" />
            </span>
          </Tooltip>
        ) : (
          <span
            className={twJoin(
              "inline-block justify-end align-middle text-gray-500",
              isSelected
                ? "text-indigo-500"
                : "opacity-0 group-hover/row:opacity-100",
            )}
          >
            <Icon icon={faEye} size="sm" />
          </span>
        )}
        {(cell.row.original.status_code !== ("202" as DecisionStatusCode) ||
          hasLinkedDecisions) && (
          <RowMenu
            cell={cell}
            currentDecision={
              cell.getContext().table.options.meta?.currentDecision
            }
            isPowertoolsEnabled={Boolean(isPowertoolsEnabled)}
            isSelected={isSelected}
            xrayTraceURL={xrayTraceURL}
            onAddTestCase={onAddTestCase}
          />
        )}
      </div>
    );
  };

  return [
    {
      id: "decisionId",
      size: 50,
      header: () => (
        <HeaderCell className="flex items-center pl-3">
          <span className="inline-block">ID</span>
          <Tooltip placement="top" title="Decision ID">
            <div className="ml-1">
              <Icon
                color="text-gray-500"
                cursorType="pointer"
                icon={faCircleInfo}
                size="2xs"
              />
            </div>
          </Tooltip>
        </HeaderCell>
      ),
      accessorFn: (row) => (
        <>
          <CopyTextIcon dataLoc="copy-id" value={row.id} />
        </>
      ),
      cell: ({ cell }) => (
        <RawCell<DecisionHistoryRecordV2>
          cell={cell}
          classNameOverrides="max-w-0 pl-3.5"
        />
      ),
    },
    {
      id: "datetime",
      size: 100,
      header: () => <HeaderCell className="w-24">Time</HeaderCell>,
      accessorFn: (row) => formatDate(row.start_time, "dd MMM yy HH:mm:ss"),
      cell: ({ cell }) => (
        <RawCell<DecisionHistoryRecordV2>
          cell={cell}
          classNameOverrides="max-w-40 w-32"
        />
      ),
    },
    {
      id: "status",
      size: 150,
      header: () => (
        <HeaderCell className="flex w-auto items-center">
          <span>Status</span>
          <DecisionHistoryTableFilter
            elements={statusFilterOptions}
            isFiltering={Boolean(statusCodeFilter)}
            placement="bottomLeft"
            selected={statusCodeFilter}
            onResetRequest={() => setStatusCodeFilter(undefined)}
            onSelect={(value: string) =>
              setStatusCodeFilter(value as DecisionStatusCode)
            }
          />
        </HeaderCell>
      ),
      accessorFn: (row) => (
        <SharedDecisionHistoryStatus statusCode={row.status_code} />
      ),
      cell: ({ cell }: CellContext<DecisionHistoryRecordV2, any>) => {
        const message = (
          <>
            <span className="mr-auto justify-start">{cell.getValue()}</span>
          </>
        );

        return (
          <ResultCell<DecisionHistoryRecordV2>
            cell={message}
            className="max-w-[unset] overflow-auto"
            objectDetailPosition="topRight"
          />
        );
      },
    },
    {
      id: "source",
      size: 100,
      header: () => <HeaderCell className="w-24">Source</HeaderCell>,
      accessorFn: (row) =>
        getSourceDetails(row, parentFlows, parentVersions, jobs),
      cell: ({ cell }: CellContext<DecisionHistoryRecordV2, SourceDetails>) => {
        const sourceDetails = cell.getValue();
        const content = (
          <SourcePill
            decisionEnv={env}
            orgId={orgId}
            sourceDetails={sourceDetails}
            wsId={wsId}
          />
        );
        return (
          <RawCell<DecisionHistoryRecordV2>
            cell={content}
            classNameOverrides="max-w-0 w-32"
          />
        );
      },
    },
    {
      id: CONTROL_COLUMN_ID,
      size: 50,
      header: () => <></>,
      accessorFn: (row) =>
        parentFlows.find((parent) => parent.id === row.parent_flow_id)?.name,
      cell: ({ cell }: CellContext<DecisionHistoryRecordV2, any>) => {
        const content = (
          <div className="flex items-center">{getRowControls(cell)}</div>
        );
        return (
          <RawCell<DecisionHistoryRecordV2>
            cell={content}
            classNameOverrides="cell-container px-1.5"
          />
        );
      },
    },
  ];
};
