import { FlipProp, IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { forwardRef, HTMLProps } from "react";
import { twJoin } from "tailwind-merge";

// Link to the Figma file with the variants and sizes: https://www.figma.com/file/M6oWoeOynJBBK0xppg19h9/Decide-Redesign%3A-Deliverables?node-id=1473-6255&t=2LixZSZKq1NoiHy4-11

export type IconSizes =
  | "4xs" // 6px
  | "3xs" // 8px
  | "2xs" // 10px
  | "xs" // 12px
  | "sm" // 14px
  | "md" // 16px
  | "base" // 16px
  | "lg" // 18px
  | "xl" // 20px
  | "2xl"; // 24px
type CursorType = "pointer" | "text" | "default";

type BaseProps = {
  icon: IconProp;
  size?: IconSizes;
  fixedWidth?: boolean;
  color?: string;
  spin?: boolean;
  dataLoc?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseDownCapture?: React.MouseEventHandler<HTMLButtonElement>;
  padding?: boolean;
  animate?: boolean;
  disabled?: boolean;
  flip?: FlipProp;
};

type ButtonIconProps = BaseProps & { cursorType?: never } & Pick<
    HTMLProps<HTMLButtonElement>,
    "onClick" | "disabled"
  >;

type DivIconProps = {
  onClick?: never;
  cursorType?: CursorType;
} & BaseProps;

export type IconProps = ButtonIconProps | DivIconProps;

export const Icon = React.memo(
  forwardRef<HTMLSpanElement, IconProps>(
    (
      {
        icon,
        size = "base",
        cursorType,
        color,
        fixedWidth = false,
        spin,
        dataLoc,
        onClick,
        onMouseDownCapture,
        padding = true,
        animate = true,
        flip,
        // spreading the remaining DOM props allows the use of asChild for library components like the tooltip:
        // https://github.com/radix-ui/primitives/issues/953
        ...restOfDomProps
      },
      ref,
    ) => {
      const Wrapper =
        onClick !== undefined || onMouseDownCapture !== undefined
          ? "button"
          : React.Fragment;
      return (
        // The conditional passing of button props is required because they are not allowed to be set at all on a Fragment, not even to undefined.
        <Wrapper
          {...(Wrapper === "button" && {
            onClick,
            onMouseDownCapture,
            type: "button",
            ...restOfDomProps,
          })}
        >
          <span
            ref={ref}
            className={twJoin(
              "flex justify-center",
              cursorType === "pointer" && "cursor-pointer",
              cursorType === "text" && "cursor-text",
              cursorType === "default" && "cursor-default",
              "disabled" in restOfDomProps &&
                restOfDomProps["disabled"] === true &&
                "cursor-not-allowed",
              padding && "p-1",
            )}
            {...(Wrapper === React.Fragment && { ...restOfDomProps })}
          >
            <FontAwesomeIcon
              className={twJoin(
                "aspect-square",
                animate && "transition-all",
                size === "4xs" && "h-1.5 w-1.5",
                size === "3xs" && "h-2 w-2",
                size === "2xs" && "h-2.5 w-2.5",
                size === "xs" && "h-3 w-3",
                size === "sm" && "h-3.5 w-3.5",
                (size === "base" || size === "md") && "h-4 w-4",
                size === "lg" && "h-[1.125rem] w-[1.125rem]",
                size === "xl" && "h-5 w-5",
                size === "2xl" && "h-6 w-6",
                color,
              )}
              data-loc={dataLoc}
              fixedWidth={fixedWidth}
              flip={flip}
              icon={icon}
              spin={spin}
            />
          </span>
        </Wrapper>
      );
    },
  ),
);
