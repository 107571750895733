import {
  faCheck,
  faChevronDown,
  faList,
} from "@fortawesome/pro-regular-svg-icons";
import { capitalize, isEqual, pick } from "lodash";
import React, { useEffect } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { DimensionsCombobox } from "src/analytics/DimensionsCombobox";
import type { DimensionOption as DimensionOptionT } from "src/analytics/DimensionsCombobox";
import AreaIcon from "src/analytics/chartIcons/area.svg?react";
import BarIcon from "src/analytics/chartIcons/bar.svg?react";
import DotIcon from "src/analytics/chartIcons/dot.svg?react";
import LineIcon from "src/analytics/chartIcons/line.svg?react";
import SummaryIcon from "src/analytics/chartIcons/summary.svg?react";
import {
  CHART_MARKS,
  ChartMarkType,
  DEFAULT_CHART_TITLE,
  REDUCER_NAMES,
} from "src/analytics/constants";
import { useAnalyticsPlotSpec } from "src/analytics/hooks";
import {
  Chart,
  ChartState,
  DimensionReducer,
  ObservableChartDimensions,
} from "src/analytics/types";
import {
  AnalyticsKey,
  SectionKey,
  VERSION_KEY,
  isObservableChart,
  isSummaryChart,
  getObservableChartSpecFromChart,
  filterDataIfExcludeRowsWithoutOutcome,
} from "src/analytics/utils";
import { ObservableChart } from "src/analytics/visualisations/ObservableChart";
import { SummaryChartPreview } from "src/analytics/visualisations/SummaryChart";
import { GenericObjectT } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { Divider } from "src/base-components/Divider";
import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Select, SELECT_DIVIDER } from "src/base-components/Select";
import { TitleEditor } from "src/base-components/TitleEditor";
import {
  ChartDimensionReduceEnum,
  ChartMark,
  ChartAggregator,
  ChartFormat,
} from "src/clients/flow-api";
import { DOCS_COMPARATIVE_ANALYTICS } from "src/constants/ExternalLinks";
import { Callout } from "src/design-system/Callout";
import { EmptyState } from "src/design-system/EmptyState";
import { Modal } from "src/design-system/Modal";
import { Tooltip } from "src/design-system/Tooltip";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { logger } from "src/utils/logger";

type ChartFormModalProps = {
  isOpen: boolean;
  onClose: () => void;
  analytics?: { data: GenericObjectT[]; keys: AnalyticsKey[] };
  chart?: Chart;
  afterLeave?: () => void;
  isMultiversionTestRun?: boolean;
  onSubmit: (chartData: ChartState) => void;
  headerPill?: React.ReactNode;
  isFlowLevelAnalytics?: boolean;
};

const DEFAULT_OBSERVABLE_CHART_STATE: ChartState = {
  mark: ChartMark.AUTO,
  title: DEFAULT_CHART_TITLE,
  description: "",
  dimensions: {
    x: { value: null, reduce: ChartDimensionReduceEnum.AUTO },
    y: { value: null, reduce: ChartDimensionReduceEnum.AUTO },
    color: { value: null, reduce: null },
    fx: { value: null },

    // We don't use these, but still need to pass them to the API
    size: { value: null },
    fy: { value: null },
  },
};

const DEFAULT_OBSERVABLE_CHART_STATE_WITH_VERSION: ChartState = {
  ...DEFAULT_OBSERVABLE_CHART_STATE,
  dimensions: {
    ...DEFAULT_OBSERVABLE_CHART_STATE.dimensions,
    fx: { value: VERSION_KEY },
  },
};

const DEFAULT_SUMMARY_CHART_STATE: ChartState = {
  mark: ChartMark.SUMMARY,
  title: DEFAULT_CHART_TITLE,
  description: "",
  dimensions: {
    field: null,
    aggregator: ChartAggregator.COUNT,
    format: ChartFormat.NUMBER,
  },
};

const DIMENSION_FIELDS = ["x", "y", "color", "fx"] as const;

const FIELDS_TO_COMPARE = [
  "mark",
  "dimensions.x",
  "dimensions.y",
  "dimensions.color",
  "dimensions.fx",
];
const isDefaultChartState = (
  [mark, dimensions]: [ChartState["mark"], ChartState["dimensions"]],
  isFlowLevelAnalytics: boolean,
) =>
  isEqual(
    pick({ mark, dimensions }, FIELDS_TO_COMPARE),
    pick(getDefaultFormState(isFlowLevelAnalytics), FIELDS_TO_COMPARE),
  );

const getDefaultFormState = (isFlowLevelAnalytics: boolean) => {
  return isFlowLevelAnalytics
    ? DEFAULT_OBSERVABLE_CHART_STATE_WITH_VERSION
    : DEFAULT_OBSERVABLE_CHART_STATE;
};

export const ChartFormModal = ({
  isOpen,
  onClose,
  analytics = { data: [], keys: [] },
  chart,
  afterLeave,
  isMultiversionTestRun,
  onSubmit,
  headerPill,
  isFlowLevelAnalytics = false,
}: ChartFormModalProps) => {
  const isEditing = !!chart;
  const formMethods = useForm<ChartState>({
    defaultValues: getDefaultFormState(isFlowLevelAnalytics),
  });
  const {
    reset,
    formState: { isDirty, isValid },
  } = formMethods;

  const form = useWatch<ChartState, ["mark", "dimensions"]>({
    name: ["mark", "dimensions"],
    control: formMethods.control,
  });

  useEffect(() => {
    if (chart && isOpen) {
      reset(pick(chart, ["title", "description", "dimensions", "mark"]));
    }
  }, [chart, reset, isOpen]);

  const onSubmitHandler = async (chartData: ChartState) => {
    try {
      const data = isSummaryChart(chartData)
        ? {
            ...chartData,
            dimensions: {
              field: chartData.dimensions.field,
              aggregator:
                chartData.dimensions.aggregator ?? ChartAggregator.COUNT,
              format: chartData.dimensions.format ?? ChartFormat.NUMBER,
            },
          }
        : isObservableChart(chartData)
          ? {
              ...chartData,
              dimensions: {
                x: chartData.dimensions.x,
                y: chartData.dimensions.y,
                color: chartData.dimensions.color,
                fx: chartData.dimensions.fx,
                size: chartData.dimensions.size,
                fy: chartData.dimensions.fy,
              },
            }
          : undefined;

      if (data) {
        await onSubmit(data);
      }
    } catch (e) {
      logger.error(e);
    }
  };

  const submitDisabled = !isDirty || !isValid;

  return (
    <Modal
      afterLeave={() => {
        reset(getDefaultFormState(isFlowLevelAnalytics));
        afterLeave?.();
      }}
      autoFocus={false}
      open={isOpen}
      size="lg"
      onClose={onClose}
    >
      <Modal.Header>
        <div className="flex items-center justify-between">
          {isEditing ? "Edit chart" : "Create chart"}
          {headerPill}
        </div>
      </Modal.Header>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmitHandler)}>
          <Modal.Content>
            <div className="flex h-[541px] gap-x-4">
              <ChartForm
                analytics={analytics}
                isFlowLevelAnalytics={isFlowLevelAnalytics}
              />
              <div className="min-w-0 flex-1">
                <div className="flex h-full flex-col gap-y-4 rounded-lg border border-gray-200 px-4 py-3">
                  <div className="flex gap-x-4">
                    <div className="flex min-w-0 flex-1 flex-col">
                      <Controller
                        name="title"
                        render={({ field }) => (
                          <TitleEditor
                            className="-mx-1.5 rounded px-1.5 text-gray-800 outline-0 font-inter-semibold-13px hover:bg-gray-100 focus:bg-white"
                            dataLoc="chart-modal-title"
                            placeholder={DEFAULT_CHART_TITLE}
                            value={field.value}
                            onSubmit={field.onChange}
                          />
                        )}
                      />
                      <Controller
                        name="description"
                        render={({ field }) => (
                          <TitleEditor
                            className="-mx-1.5 rounded px-1.5 italic text-gray-500 outline-0 font-inter-normal-12px hover:bg-gray-100 focus:bg-white"
                            dataLoc="chart-modal-description"
                            placeholder="Add chart description"
                            value={field.value || ""}
                            onSubmit={field.onChange}
                          />
                        )}
                      />
                    </div>
                    {isMultiversionTestRun && (
                      <MultiVersionWarning
                        isFlowLevelAnalytics={isFlowLevelAnalytics}
                      />
                    )}
                  </div>
                  <div
                    className={twJoin(
                      "flex min-h-0 flex-1 items-center justify-center rounded-lg",
                      form[0] === ChartMark.SUMMARY && "bg-gray-50",
                    )}
                    data-loc="chart-modal-chart"
                  >
                    {isDefaultChartState(form, isFlowLevelAnalytics) ? (
                      <EmptyState
                        description="Add the relevant information for us to configure the chart"
                        headline="Add chart configuration"
                        icon={faList}
                      />
                    ) : (
                      <AnalyticsChartPreview
                        data={analytics.data ?? []}
                        keys={analytics.keys ?? []}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Footer
            primaryButton={
              <Button
                dataLoc="chart-form-modal-submit"
                disabled={submitDisabled}
                htmlType="submit"
                loading={formMethods.formState.isSubmitting}
              >
                Save
              </Button>
            }
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

const isThereVersionUsage = (dimensions: ObservableChartDimensions) => {
  return DIMENSION_FIELDS.some((dimension) => {
    const dimensionValue = dimensions[dimension];
    return (
      typeof dimensionValue === "object" && dimensionValue.value === VERSION_KEY
    );
  });
};

const MultiVersionWarning: React.FC<{ isFlowLevelAnalytics: boolean }> = ({
  isFlowLevelAnalytics,
}) => {
  const form = useWatch<ChartState, ["mark", "dimensions"]>({
    name: ["mark", "dimensions"],
  });
  const [mark, dimensions] = form;

  if (
    !isDefaultChartState(form, isFlowLevelAnalytics) &&
    mark !== ChartMark.SUMMARY &&
    !isThereVersionUsage(dimensions as ObservableChartDimensions)
  ) {
    return (
      <div>
        <Callout
          action={{
            text: "Learn more",
            onClick: () => {
              window.open(DOCS_COMPARATIVE_ANALYTICS, "_blank");
            },
          }}
          type="warning"
        >
          Chart data is from multiple versions with no version comparison or
          breakdown.
        </Callout>
      </div>
    );
  }
};

const FormField = ({
  label,
  children,
  reducer,
}: {
  children: React.ReactNode;
  label: string;
  reducer?: React.ReactNode;
}) => (
  <div>
    <div className="flex items-center justify-between">
      <div className="mb-1 text-gray-500 font-inter-normal-12px">{label}</div>
      <div>{reducer}</div>
    </div>
    {children}
  </div>
);

const ReducerDropdown: React.FC<{
  value: Exclude<DimensionReducer, null>;
  onChange: (value: DimensionReducer) => void;
  autoValue?: Nullable<string>;
  dataLoc?: string;
}> = ({ value, onChange, autoValue, dataLoc }) => (
  <FixedPositionedDropdown<string, DimensionReducer>
    buttonClassName="border-0 ring-0 p-0.5 focus:border-0 focus:ring-0"
    dataLoc={dataLoc}
    elements={REDUCER_NAMES.map(([value, key]) => ({
      key,
      value,
    }))}
    itemsClassNames="max-h-60 overflow-y-auto min-w-[10rem]"
    listMatchesButtonWidth={false}
    renderButtonValue={(value) => {
      const isAuto = value === "auto";
      const hasAutoReducer = isAuto && autoValue !== undefined;
      return (
        <Pill size="sm" variant="gray">
          <Pill.Text fontType="code">
            {value ?? "—"} {hasAutoReducer ? `- ${autoValue}` : ""}
          </Pill.Text>
          <Pill.Icon icon={faChevronDown} />
        </Pill>
      );
    }}
    renderValue={(element) => (
      <div className="flex justify-between px-4 py-3.5 text-xs-sm text-gray-800 hover:bg-gray-50">
        {element.value}
        {element.selected && (
          <Icon color="text-indigo-600" icon={faCheck} size="2xs" />
        )}
      </div>
    )}
    selected={value}
    onSelect={onChange}
  />
);

const DimensionOption: React.FC<{
  value: string;
  sectionKey: SectionKey;
  actualKey: string;
  disabled: boolean;
}> = ({ value, sectionKey, actualKey, disabled }) => {
  const isOutputKey = actualKey.startsWith("output_data.");
  const [prefixOrEntireValue, ...rest] = value.split(".");

  const displayValue =
    sectionKey === SectionKey.node_logic || rest.length === 0 ? (
      <span className="truncate text-gray-800">{prefixOrEntireValue}</span>
    ) : (
      <div
        className="flex items-center justify-between gap-x-1"
        data-loc={isOutputKey ? "dimension-option-output" : "dimension-option"}
      >
        <span>
          <span className="text-green-600">{prefixOrEntireValue}.</span>
          {rest.join(".")}
        </span>
        {isOutputKey && (
          <Pill size="sm" variant="gray">
            <Pill.Text fontType="code">Output</Pill.Text>
          </Pill>
        )}
      </div>
    );

  if (disabled) {
    return (
      <Tooltip
        placement="right"
        title="Plotting charts with Arrays or Objects is not supported."
        asChild
      >
        <div className="w-full truncate text-indigo-700 opacity-40">
          {displayValue}
        </div>
      </Tooltip>
    );
  }
  return (
    <div
      className={twJoin(
        "truncate text-indigo-700 font-code-13",
        disabled && "opacity-40",
      )}
    >
      {displayValue}
    </div>
  );
};

const CHART_ICONS: Record<ChartMark, Nullable<typeof LineIcon>> = {
  [ChartMark.AUTO]: null,
  [ChartMark.LINE]: LineIcon,
  [ChartMark.BAR]: BarIcon,
  [ChartMark.AREA]: AreaIcon,
  [ChartMark.DOT]: DotIcon,
  [ChartMark.RULE]: LineIcon,
  [ChartMark.SUMMARY]: SummaryIcon,
};

const ChartTypeOption: React.FC<{
  displayName: string;
  value: ChartMarkType;
}> = ({ displayName, value }) => {
  const Icon = CHART_ICONS[value];

  return (
    <div className="flex items-center gap-x-2 font-inter-normal-13px">
      {Icon && (
        <div className="flex h-4.5 w-6.5 items-center justify-center overflow-hidden rounded-sm border border-gray-300 px-px py-0">
          <Icon className="h-full w-full" />
        </div>
      )}
      {displayName}
    </div>
  );
};

const MARK_OPTIONS = (currentMark: ChartMark) => {
  const marks = CHART_MARKS.map(([displayName, mark]) => ({
    key: mark,
    value: <ChartTypeOption displayName={displayName} value={mark} />,
  }));

  if (
    !isFeatureFlagEnabled(FEATURE_FLAGS.summaryChart) &&
    currentMark !== ChartMark.SUMMARY
  ) {
    return marks.slice(0, -1);
  }

  return [...marks.slice(0, -1), SELECT_DIVIDER, marks[marks.length - 1]];
};

const SUMMARY_NUMBER_TYPES = Object.values(ChartFormat).map((value) => ({
  key: value.toLowerCase(),
  value: capitalize(value),
}));

const SUMMARY_AGGREGATE_TYPES = Object.values(ChartAggregator).map((value) => ({
  key: value.toLowerCase(),
  value: capitalize(value),
}));

const ChartForm: React.FC<{
  isFlowLevelAnalytics: boolean;
  analytics: { data: GenericObjectT[]; keys: AnalyticsKey[] };
}> = ({ isFlowLevelAnalytics, analytics }) => {
  const { reset } = useFormContext();
  const mark = useWatch({ name: "mark" });
  const dimensionOptions = analytics.keys.map((key) => ({
    key: key.actualKey,
    displayKey: key.displayKey,
    value: (
      <DimensionOption
        actualKey={key.actualKey}
        disabled={key.disabled}
        sectionKey={key.sectionKey}
        value={key.displayKey}
      />
    ),
    sectionKey: key.sectionKey,
    disabled: key.disabled,
  }));

  return (
    <div className="flex w-70 flex-col gap-y-3">
      <FormField label="Chart type">
        <Controller
          name="mark"
          render={({ field }) => (
            <Select
              dataLoc="chart-type-picker"
              options={MARK_OPTIONS(field.value)}
              value={field.value}
              onChange={(v) => {
                reset(
                  v === ChartMark.SUMMARY
                    ? DEFAULT_SUMMARY_CHART_STATE
                    : { ...DEFAULT_OBSERVABLE_CHART_STATE, mark: v },
                  { keepDirtyValues: true },
                );
              }}
            />
          )}
        />
      </FormField>

      <Divider />

      {mark === ChartMark.SUMMARY ? (
        <>
          <FormField label="Key value">
            <Controller
              name="dimensions.field"
              render={({ field }) => (
                <DimensionsCombobox
                  dataLoc="dimensions-field-picker"
                  isFlowLevelAnalytics={isFlowLevelAnalytics}
                  options={dimensionOptions}
                  placeholder="Select field"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
              rules={{ required: true }}
            />
          </FormField>
          <FormField label="Aggregate">
            <Controller
              name="dimensions.aggregator"
              render={({ field }) => {
                return (
                  <Select
                    dataLoc="dimensions-aggregate-picker"
                    options={SUMMARY_AGGREGATE_TYPES}
                    value={field.value ?? ChartAggregator.COUNT}
                    onChange={field.onChange}
                  />
                );
              }}
            />
          </FormField>
          <FormField label="Type">
            <Controller
              name="dimensions.format"
              render={({ field }) => (
                <Select
                  dataLoc="dimensions-type-picker"
                  options={SUMMARY_NUMBER_TYPES}
                  value={field.value ?? ChartFormat.NUMBER}
                  onChange={field.onChange}
                />
              )}
            />
          </FormField>
        </>
      ) : (
        <ObservableChartForm
          analytics={analytics}
          dimensionOptions={dimensionOptions}
          isFlowLevelAnalytics={isFlowLevelAnalytics}
        />
      )}
    </div>
  );
};

const ObservableChartForm: React.FC<{
  isFlowLevelAnalytics: boolean;
  analytics: { data: GenericObjectT[]; keys: AnalyticsKey[] };
  dimensionOptions: DimensionOptionT[];
}> = ({ isFlowLevelAnalytics, analytics, dimensionOptions }) => {
  const form = useWatch({ name: ["mark", "dimensions"] });

  const plotSpec = useAnalyticsPlotSpec({
    data: analytics.data,
    spec: getObservableChartSpecFromChart(
      {
        mark: form[0] ?? DEFAULT_OBSERVABLE_CHART_STATE.mark,
        dimensions: form[1] ?? DEFAULT_OBSERVABLE_CHART_STATE.dimensions,
      },
      analytics.keys ?? [],
    ),
  });

  return (
    <>
      <FormField
        label="X Axis"
        reducer={
          <Controller
            defaultValue={ChartDimensionReduceEnum.AUTO}
            name="dimensions.x.reduce"
            render={({ field }) => (
              <ReducerDropdown
                autoValue={plotSpec.xReducer}
                dataLoc="dimensions-x-reduce"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        }
      >
        <Controller
          name="dimensions.x.value"
          render={({ field }) => (
            <DimensionsCombobox
              dataLoc="dimensions-x-picker"
              isFlowLevelAnalytics={isFlowLevelAnalytics}
              options={dimensionOptions}
              placeholder="Select field"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <ExcludeRowsWithoutOutcomeCheckbox dimension="dimensions.x" />
      </FormField>

      <FormField
        label="Y Axis"
        reducer={
          <Controller
            defaultValue={ChartDimensionReduceEnum.AUTO}
            name="dimensions.y.reduce"
            render={({ field }) => (
              <ReducerDropdown
                autoValue={plotSpec.yReducer}
                dataLoc="dimensions-y-reduce"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        }
      >
        <Controller
          name="dimensions.y.value"
          render={({ field }) => (
            <DimensionsCombobox
              dataLoc="dimensions-y-picker"
              isFlowLevelAnalytics={isFlowLevelAnalytics}
              options={dimensionOptions}
              placeholder="Select field"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <ExcludeRowsWithoutOutcomeCheckbox dimension="dimensions.y" />
      </FormField>

      <FormField label="Break down by">
        <Controller
          name="dimensions.color.value"
          render={({ field }) => (
            <DimensionsCombobox
              dataLoc="dimensions-color-picker"
              isFlowLevelAnalytics={isFlowLevelAnalytics}
              options={dimensionOptions}
              placeholder="Select field"
              value={field.value}
              onChange={(v) => field.onChange(v)}
            />
          )}
        />
        <ExcludeRowsWithoutOutcomeCheckbox dimension="dimensions.color" />
      </FormField>

      <FormField label="Compare by">
        <Controller
          name="dimensions.fx.value"
          render={({ field }) => (
            <DimensionsCombobox
              dataLoc="dimensions-fx-picker"
              isFlowLevelAnalytics={isFlowLevelAnalytics}
              options={dimensionOptions}
              placeholder="Select field"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <ExcludeRowsWithoutOutcomeCheckbox dimension="dimensions.fx" />
      </FormField>
    </>
  );
};

const ExcludeRowsWithoutOutcomeCheckbox: React.FC<{
  dimension: string;
}> = ({ dimension }) => {
  const { register } = useFormContext();
  const dimensionValue = useWatch({ name: `${dimension}.value` });

  if (
    !isFeatureFlagEnabled(FEATURE_FLAGS.excludeRowsWithoutOutcome) ||
    !dimensionValue?.startsWith("outcome_data.")
  ) {
    return null;
  }

  return (
    <label className="mt-2 flex items-center gap-x-1.5 text-gray-500 font-inter-normal-12px">
      <Checkbox {...register(`${dimension}.exclude_rows_without_outcome`)} />{" "}
      Exclude decisions without this outcome
    </label>
  );
};

const AnalyticsChartPreview: React.FC<{
  data: GenericObjectT[];
  keys: AnalyticsKey[];
}> = ({ data, keys }) => {
  const [mark, dimensions] = useWatch({ name: ["mark", "dimensions"] });

  if (mark === ChartMark.SUMMARY) {
    return <SummaryChartPreview data={data} spec={dimensions} />;
  }

  const spec = getObservableChartSpecFromChart(
    {
      mark: mark ?? DEFAULT_OBSERVABLE_CHART_STATE.mark,
      dimensions: dimensions ?? DEFAULT_OBSERVABLE_CHART_STATE.dimensions,
    },
    keys,
  );

  return (
    <ObservableChart
      data={filterDataIfExcludeRowsWithoutOutcome(data, dimensions)}
      spec={spec}
    />
  );
};
