import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { MonospacedInput } from "src/base-components/MonospacedInput";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { Textarea } from "src/base-components/Textarea";
import { Parameter } from "src/clients/flow-api";
import { Modal } from "src/design-system/Modal";
import { toastActions } from "src/design-system/Toast/utils";
import { ParameterForm } from "src/globalParameters/GlobalParametersList";
import { errorMessage } from "src/utils/stringUtils";

type PropsT = {
  editParameter: (param: ParameterForm) => Promise<void>;
  onClose: () => void;
  isOpen: boolean;
  isAdding: boolean;
  defaultValues?: Partial<Parameter>;
  expressionFocused?: boolean;
  currentParameterNames: string[];
  focusValueInput?: boolean;
};

const PYTHON_DICT_KEY_REGEX = /^[a-zA-Z_][a-zA-Z0-9_]*$/;

const validateValueInput = (value?: string) => {
  if (!value) {
    return "Parameter value cannot be empty";
  }
  return true;
};

const emptyFormValues: ParameterForm = {
  name: "",
  description: "",
  expr: "",
};

export const EditParameterModal: React.FC<PropsT> = ({
  editParameter,
  onClose,
  isOpen,
  isAdding,
  defaultValues,
  currentParameterNames,
  focusValueInput = false,
}) => {
  const { reset, handleSubmit, register, formState, setError, setFocus } =
    useForm<ParameterForm>({
      defaultValues: defaultValues ?? emptyFormValues,
      mode: "onChange",
    });
  const [title, setTitle] = useState("Add Parameter");

  useEffect(() => {
    if (isOpen) {
      if (isAdding) {
        setTitle("Add Parameter");
      } else {
        setTitle("Edit Parameter");
      }
    }
  }, [isAdding, isOpen]);

  useEffect(() => {
    // Reset the modal every time it is opened
    if (isOpen) {
      reset(defaultValues ?? emptyFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, isOpen]);

  useEffect(() => {
    if (isOpen && focusValueInput) {
      // We need to set a timeout so that this gets executed after the form is initialized
      setTimeout(() => setFocus("expr"), 0);
    }
  }, [focusValueInput, setFocus, isOpen]);

  const onModalConfirm = handleSubmit(async (parameter) => {
    try {
      await editParameter(parameter);
      toastActions.success({ title: "Successfully edited Parameter" });
      onClose();
    } catch (error: any) {
      //TODO AUTH-1950 type this correctly?
      if (errorMessage(error) === "Invalid expression")
        setError(
          "expr",
          {
            type: "invalidExpression",
            message:
              "Parameter value must be a basic python value. See the docs for details.",
          },
          { shouldFocus: true },
        );
      else {
        onClose();
      }
    }
  });

  const validateNameInput = (name: string) => {
    if (!name) {
      return "Parameter name cannot be empty";
    } else if (
      currentParameterNames
        .filter((p) => p !== defaultValues?.name)
        .includes(name)
    ) {
      return "Parameter name already exists";
    } else if (!PYTHON_DICT_KEY_REGEX.test(name)) {
      return "Parameter name must begin with a letter, and may only include letters, numbers, and underscores.";
    }
    return true;
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <p className="mb-2 font-inter-semibold-13px">
          Name <RequiredAsterisk />
        </p>
        {formState.errors.name && (
          <ErrorHint>{formState.errors.name.message}</ErrorHint>
        )}
        <MonospacedInput
          errored={!!formState.errors.name}
          formProps={register("name", {
            validate: validateNameInput,
          })}
          inputDataLoc="name-input"
          placeholder="name"
          prefix="params."
          codeColors
        />
        <p className="mb-2 mt-4 font-inter-semibold-13px">Description</p>
        <Textarea
          data-loc="description-input"
          placeholder="Add description here..."
          {...register("description")}
        />
        <p className="mb-2 mt-4 font-inter-semibold-13px">
          Value <RequiredAsterisk />
        </p>
        {formState.errors.expr && (
          <ErrorHint>{formState.errors.expr.message}</ErrorHint>
        )}
        <MonospacedInput
          errored={!!formState.errors.expr}
          formProps={register("expr", {
            validate: validateValueInput,
          })}
          inputDataLoc="value-input"
          placeholder="Enter Parameter value here..."
          codeColors
        />
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="confirm"
            disabled={!isEmpty(formState.errors)}
            loading={formState.isSubmitting}
            variant="primary"
            onClick={async () => {
              await onModalConfirm();
            }}
          >
            Save
          </Button>
        }
      />
    </Modal>
  );
};
