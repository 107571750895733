import BoringAvatar from "boring-avatars";
import React, { useState } from "react";
import { twJoin } from "tailwind-merge";

import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { OrganizationUser } from "src/clients/taktile-api";
import { getSize, SizeName } from "src/design-system/utils/sizes";
import { tailwindSizeToPixels } from "src/utils/tailwindcss";
import { getUserName } from "src/utils/user";

const FALLBACK_COLORS = ["#5946E5", "#6366F1", "#A7F3D0", "#22D3EE", "#493FC1"];

// Extract from Sizes to make sure we only use the allowed sizes
type AvatarSize = Extract<SizeName, "md" | "lg" | "3xl" | "6xl">;

type AvatarProps = {
  user: Pick<OrganizationUser, "full_name" | "avatar_url" | "username">;
  size?: AvatarSize;
};

export const AvatarLoadingPlaceholder: React.FC<Pick<AvatarProps, "size">> = ({
  size = "md",
}) => {
  const sizeInfo = getSize(size);
  return (
    <SkeletonPlaceholder
      height={sizeInfo.height}
      rounded="rounded-full"
      width={sizeInfo.width}
      shrink0
    />
  );
};

/**
 * Figma: https://www.figma.com/design/BO9DWO8PKNGNSI3HhpSobA/Design-System-v2?node-id=5111-40963&node-type=frame&m=dev
 */
export const Avatar: React.FC<AvatarProps> = ({ user, size = "md" }) => {
  const [isFailed, setIsFailed] = useState(false);
  const userName = getUserName(user);
  const initialLetter = (userName ?? "").substring(0, 1);
  const sizeInfo = getSize(size);

  if (user.avatar_url && !isFailed) {
    return (
      <div
        className={twJoin(
          "flex shrink-0 justify-center overflow-hidden rounded-full",
          sizeInfo.width,
          sizeInfo.height,
        )}
      >
        <img
          alt={userName}
          className="object-cover"
          src={user.avatar_url}
          onError={() => setIsFailed(true)}
        />
      </div>
    );
  }

  return (
    <div
      className={twJoin(
        "relative flex shrink-0 items-center justify-center overflow-hidden rounded-full",
        sizeInfo.width,
        sizeInfo.height,
      )}
    >
      <BoringAvatar
        colors={FALLBACK_COLORS}
        name={userName}
        size={tailwindSizeToPixels(sizeInfo.width)}
        variant="marble"
      />
      <span
        className={twJoin(
          "absolute uppercase text-white text-shadow-sm",
          size === "md" && "font-inter-medium-12px",
          size === "lg" && "font-inter-medium-12px",
          size === "3xl" && "font-inter-semibold-16px",
          size === "6xl" && "scale-[3] font-inter-semibold-16px",
        )}
      >
        {initialLetter}
      </span>
    </div>
  );
};
