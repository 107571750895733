import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  InputMappingGroupT,
  InputMappingT,
  IntegrationResourceT,
} from "src/integrationNode/types";

const getDefaultDriversLicenseFields = (): {
  [key: string]: InputMappingT;
} => ({
  number: {
    id: uuidV4(),
    type: "text",
    displayName: "Number",
    assignedTo: "",
  },
  issuance_state: {
    id: uuidV4(),
    type: "text",
    displayName: "Issuance state",
    assignedTo: "",
    hint: `Two-letter abbreviation of the state that issued the license`,
  },
  issuance_date: {
    id: uuidV4(),
    type: "text",
    displayName: "Issuance date",
    assignedTo: "",
    hint: `Issuance date of the license, formatted according to ISO: 'YYYY-MM-DD'`,
  },
  expiration_date: {
    id: uuidV4(),
    type: "text",
    displayName: "Expiration date",
    assignedTo: "",
    hint: `Expiration date of the license, formatted according to ISO: 'YYYY-MM-DD'`,
  },
});

const getDefaultDriversLicense = (): InputMappingGroupT => ({
  displayName: "Driver's license",
  getDefaultElements: getDefaultDriversLicenseFields,
});

const getDefaultStateIdFields = (): { [key: string]: InputMappingT } => ({
  number: {
    id: uuidV4(),
    type: "text",
    displayName: "Number",
    assignedTo: "",
  },
  issuance_state: {
    id: uuidV4(),
    type: "text",
    displayName: "Issuance state",
    assignedTo: "",
    hint: `Two-letter abbreviation of the state that issued the state ID`,
  },
  issuance_date: {
    id: uuidV4(),
    type: "text",
    displayName: "Issuance date",
    assignedTo: "",
    hint: `Issuance date of the state ID, formatted according to ISO: 'YYYY-MM-DD'`,
  },
  expiration_date: {
    id: uuidV4(),
    type: "text",
    displayName: "Expiration date",
    assignedTo: "",
    hint: `Expiration date of the state ID, formatted according to ISO: 'YYYY-MM-DD'`,
  },
});

const getDefaultStateId = (): InputMappingGroupT => ({
  displayName: "State ID",
  getDefaultElements: getDefaultStateIdFields,
});

const getDefaultCreditCardFields = (): { [key: string]: InputMappingT } => ({
  account_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Account number",
    assignedTo: "",
    hint: `Account number of the bank account`,
  },
  company_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Company name",
    assignedTo: "",
    hint: `The name of the company that issued the credit card`,
  },
  expiration_date: {
    id: uuidV4(),
    type: "text",
    displayName: "Expiration date",
    assignedTo: "",
    hint: `Expiration date of the credit card, formatted according to ISO: 'YYYY-MM-DD'`,
  },
});

const getDefaultCreditCard = (): InputMappingGroupT => ({
  displayName: "Credit card",
  getDefaultElements: getDefaultCreditCardFields,
});

const getDefaultAddressFields = (): { [key: string]: InputMappingT } => ({
  unparsed_street: {
    id: uuidV4(),
    type: "text",
    displayName: "Unparsed street",
    assignedTo: "",
    hint: `Allows for free-form entry of street information, e.g., "123 MAIN ST, APT 2". If this field is used, do not provide input data for the other street fields (street name, house number, etc.). Up to 48 characters.`,
  },
  street_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Street name",
    assignedTo: "",
    hint: `Do not include if the unparsed street field is used. Up to 28 characters.`,
  },
  house_number: {
    id: uuidV4(),
    type: "text",
    displayName: "House number",
    assignedTo: "",
    hint: `Do not include if the unparsed street field is used. Up to 10 characters.`,
  },
  predirectional: {
    id: uuidV4(),
    type: "text",
    displayName: "Predirectional",
    assignedTo: "",
    hint: `Predirectional of the address, e.g., if the address is "123 N MAIN ST", the predirectional is "N". Do not include if the unparsed street field is used. Up to 2 characters.`,
  },
  postdirectional: {
    id: uuidV4(),
    type: "text",
    displayName: "Postdirectional",
    assignedTo: "",
    hint: `Postdirectional of the address, e.g., if the address is "123 MAIN ST N", the postdirectional is "N". Do not include if the unparsed street field is used. Up to 2 characters.`,
  },
  street_type: {
    id: uuidV4(),
    type: "text",
    displayName: "Street type",
    assignedTo: "",
    hint: `The primary type of street. Refer to our docs for a list of supported street types. Do not include if the unparsed street field is used.`,
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    hint: `Up to 28 characters`,
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    hint: `Include ZIP extension preceded by a dash, e.g., "60661-1234". Up to 10 characters.`,
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    hint: `Two-letter state abbreviation. Refer to our docs for a list of supported values.`,
  },
});

const getDefaultSubjectAddressFields = (): {
  [key: string]: InputMappingT;
} => ({
  ...getDefaultAddressFields(),
  residence_status: {
    id: uuidV4(),
    type: "text",
    displayName: "Residence status",
    assignedTo: "",
    hint: `Specifies whether the subject owns or rents the residence at this address. Valid values are: "own", "rent" "board".`,
  },
  residence_duration: {
    id: uuidV4(),
    type: "text",
    displayName: "Residence duration",
    assignedTo: "",
    hint: `The length of the subject's residence at this address. Up to 7 characters.`,
  },
});

const getDefaultCurrentAddress = (): InputMappingGroupT => ({
  displayName: "Current address",
  getDefaultElements: getDefaultSubjectAddressFields,
  elements: getDefaultSubjectAddressFields(),
  rules: {
    required: true,
  },
});

const getDefaultPreviousAddress = (): InputMappingGroupT => ({
  displayName: "Previous address",
  getDefaultElements: getDefaultSubjectAddressFields,
});

const getDefaultEmploymentFields = (): { [key: string]: InputMappingT } => ({
  employer_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Employer name",
    assignedTo: "",
    hint: `The name of the subject's employer. Up to 50 characters.`,
  },
  employee_occupation: {
    id: uuidV4(),
    type: "text",
    displayName: "Employee occupation",
    assignedTo: "",
    hint: `The subject's occupation, e.g., "TEACHER". Up to 50 characters.`,
  },
  date_hired: {
    id: uuidV4(),
    type: "text",
    displayName: "Date hired",
    assignedTo: "",
    hint: `The date the subject was hired, formatted according to ISO: 'YYYY-MM-DD'`,
  },
  date_terminated: {
    id: uuidV4(),
    type: "text",
    displayName: "Date terminated",
    assignedTo: "",
    hint: `The date the subject's employment was terminated, formatted according to ISO: 'YYYY-MM-DD'`,
  },
  employment_duration: {
    id: uuidV4(),
    type: "text",
    displayName: "Employment duration",
    assignedTo: "",
    hint: `The (integer) number of years the subject has been employed by this employer`,
  },
  employment_status: {
    id: uuidV4(),
    type: "text",
    displayName: "Employment status",
    assignedTo: "",
    hint: `The subject's employment status. Valid values are: "active", "inactive", "leave", "terminated".`,
  },
  pay_schedule: {
    id: uuidV4(),
    type: "text",
    displayName: "Pay schedule",
    assignedTo: "",
    hint: `The subject's pay schedule. Valid values are: "hourly", "daily", "weekly", "semimonthly", "monthly", "bimonthly", "yearly". Note that "semimonthly" means twice a month, while "bimonthly" means every other month.`,
  },
  income_amount: {
    id: uuidV4(),
    type: "text",
    displayName: "Income amount",
    assignedTo: "",
    hint: `Valid format is whole dollars for all amounts except for "hourly" pay schedules, for which a dollars-and-cents format is used. For example, an income of $15.50 an hour is entered as 1550.`,
  },
  ...getDefaultAddressFields(),
  phone_number_unparsed: {
    id: uuidV4(),
    type: "text",
    displayName: "Phone number unparsed",
    assignedTo: "",
    hint: `Allows for free-form entry of the employer's phone number, e.g., "111-555-1234". If this field is used, do not provide input data for the other phone fields.`,
  },
  phone_area_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Phone area code",
    assignedTo: "",
    hint: `The three-digit area code of the employer's phone number, e.g., "111". Do not include if the unparsed phone number field is used.`,
  },
  phone_exchange: {
    id: uuidV4(),
    type: "text",
    displayName: "Phone exchange",
    assignedTo: "",
    hint: `Three-digit exchange code of the employer's phone number. Do not include if the unparsed phone number field is used.`,
  },
  phone_suffix: {
    id: uuidV4(),
    type: "text",
    displayName: "Phone suffix",
    assignedTo: "",
    hint: `Four-digit suffix code of the employer's phone number. Do not include if the unparsed phone number field is used.`,
  },
  phone_extension: {
    id: uuidV4(),
    type: "text",
    displayName: "Phone extension",
    assignedTo: "",
    hint: `The extension code of the employer's phone number. Up to 5 characters. Do not include if the unparsed phone number field is used.`,
  },
});

const getDefaultEmployment = (): InputMappingGroupT => ({
  displayName: "Employment",
  getDefaultElements: getDefaultEmploymentFields,
});

const getDefaultPhoneFields = (): { [key: string]: InputMappingT } => ({
  number_unparsed: {
    id: uuidV4(),
    type: "text",
    displayName: "Number unparsed",
    assignedTo: "",
    hint: `Allows for free-form entry of the subject's phone number, e.g., "111-555-1234". If this field is used, do not provide input data for the other phone fields.`,
  },
  area_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Area code",
    assignedTo: "",
    hint: `The phone number's three-digit area code, e.g., "111". Do not include if the unparsed phone number field is used.`,
  },
  phone_exchange: {
    id: uuidV4(),
    type: "text",
    displayName: "Phone exchange",
    assignedTo: "",
    hint: `Three-digit phone exchange code. Do not include if the unparsed phone number field is used.`,
  },
  suffix: {
    id: uuidV4(),
    type: "text",
    displayName: "Suffix",
    assignedTo: "",
    hint: `Four-digit phone suffix code. Do not include if the unparsed phone number field is used.`,
  },
  extension: {
    id: uuidV4(),
    type: "text",
    displayName: "Extension",
    assignedTo: "",
    hint: `The phone number's extension code. Up to 5 characters. Do not include if the unparsed phone number field is used.`,
  },
});

const getDefaultPhone = (): InputMappingGroupT => ({
  displayName: "Phone",
  getDefaultElements: getDefaultPhoneFields,
});

export const getDefaultTransUnionTruVisionModelReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "trans_union",
      resource: "tru_vision_model_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        customer_reference: {
          id: uuidV4(),
          type: "text",
          displayName: "Customer reference",
          assignedTo: "",
          hint: `Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.`,
        },
        consumer_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Consumer ID",
          assignedTo: "",
          hint: `TransUnion's unique identifier for individuals. You will usually not know this value.`,
        },
        first_name: {
          id: uuidV4(),
          type: "text",
          displayName: "First name",
          assignedTo: "",
          hint: `Up to 20 characters.`,
          rules: {
            required: true,
          },
        },
        middle_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Middle name",
          assignedTo: "",
          hint: `Up to 20 characters.`,
        },
        last_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Last name",
          assignedTo: "",
          hint: `Up to 32 characters.`,
          rules: {
            required: true,
          },
        },
        name_prefix: {
          id: uuidV4(),
          type: "text",
          displayName: "Name prefix",
          assignedTo: "",
          hint: `Name prefix of the subject, e.g., "MRS", "REVEREND", "SERGEANT".`,
        },
        generational_suffix: {
          id: uuidV4(),
          type: "text",
          displayName: "Generational suffix",
          assignedTo: "",
          hint: `Name suffix of the subject, e.g. "JR", "SR", "IV".`,
        },
        country: {
          id: uuidV4(),
          type: "text",
          displayName: "Country",
          assignedTo: "",
          hint: `Country of the subject. Allowed values are "us" and "canada".`,
        },
        social_security_number: {
          id: uuidV4(),
          type: "text",
          displayName: "Social security number",
          assignedTo: "",
          hint: `9-digit social security number of the subject. Do not include hyphens or whitespace.`,
        },
        email: {
          id: uuidV4(),
          type: "text",
          displayName: "Email",
          assignedTo: "",
        },
        ip_address: {
          id: uuidV4(),
          type: "text",
          displayName: "IP address",
          assignedTo: "",
          hint: `IP address of the subject`,
        },
        date_of_birth: {
          id: uuidV4(),
          type: "text",
          displayName: "Date of birth",
          assignedTo: "",
          hint: `The subject's date of birth, formatted according to ISO: 'YYYY-MM-DD'`,
        },
        age: {
          id: uuidV4(),
          type: "text",
          displayName: "Age",
          assignedTo: "",
          hint: `Age of the subject in years, e.g., "25"`,
        },
        gender: {
          id: uuidV4(),
          type: "text",
          displayName: "Gender",
          assignedTo: "",
          hint: `Gender of the subject. Valid values are "male", "female", "unknown".`,
        },
      },
      grouped: {
        current_address: getDefaultCurrentAddress(),
        previous_address: getDefaultPreviousAddress(),
        drivers_license: getDefaultDriversLicense(),
        state_id: getDefaultStateId(),
        credit_card: getDefaultCreditCard(),
        employment: getDefaultEmployment(),
        phone: getDefaultPhone(),
      },
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(CommonOutputMappingKey.RETRIEVAL_DATETIME),
        user_reference: {
          ...getNamedOutputMapping("User reference"),
          hint: `The user reference you provided in the input, echoed back in the provider response`,
        },
        file_hit_indicator: {
          ...getNamedOutputMapping("File hit indicator"),
          hint: `Indicates whether a matching subject record was found. See our docs for possible values.`,
        },
        ssn_match_indicator: {
          ...getNamedOutputMapping("SSN match indicator"),
          hint: `Indicates whether the SSN in the response's subject record matches the input SSN. See our docs for possible values.`,
        },
        is_suppressed: {
          ...getNamedOutputMapping("Is suppressed"),
          hint: `Boolean (True/False) that indicates whether the credit data in the file is suppressed`,
        },
        is_frozen: {
          ...getNamedOutputMapping("Is frozen"),
          hint: `Boolean (True/False) that indicates whether the credit data in the file is frozen`,
        },
        belongs_to_minor: {
          ...getNamedOutputMapping("Belongs to minor"),
          hint: `Boolean (True/False) that indicates whether the credit data in the file belongs to a minor`,
        },
        is_disputed: {
          ...getNamedOutputMapping("Is disputed"),
          hint: `Boolean (True/False) that indicates whether the file was held back due to disputed data on the file. Only relevant for the state of Georgia.`,
        },
      },
    },
    config: defaultResourceConfig,
  });
