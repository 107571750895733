import {
  faArrowLeft,
  faArrowUpRightFromSquare,
  faBolt,
  faChevronDown,
  faClock,
  faClone,
  faCog,
  faCube,
  faPen,
  faTimes,
  faTrashAlt,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowDownRight,
  faArrowLeft as faArrowLeftSolid,
  faArrowUpRight,
} from "@fortawesome/pro-solid-svg-icons";
import { useIsMutating } from "@tanstack/react-query";
import cronstrue from "cronstrue";
import { capitalize, isNil, noop, padStart, pickBy, range } from "lodash";
import { useRef } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { FlowT } from "src/api/flowTypes";
import { DecisionEnvironment } from "src/api/types";
import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { FloatingWindowsProvider } from "src/base-components/FloatingWindow/FloatingWindowsProvider";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import { OptionsDropdownElement } from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { Pill } from "src/base-components/Pill";
import { Select } from "src/base-components/Select";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { Summary } from "src/base-components/Summary";
import { FlowVersionStatus } from "src/clients/flow-api";
import { Callout } from "src/design-system/Callout";
import { EmptyState } from "src/design-system/EmptyState";
import { Modal } from "src/design-system/Modal";
import { useModal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { ExcludesFalse } from "src/flow/types";
import { useCapabilities } from "src/hooks/useCapabilities";
import { RunsTable } from "src/jobs/RunsTable";
import {
  useDeactivateJob as useDeactivateJobMutation,
  useJob,
  useUpdateJob,
} from "src/jobs/api/queries";
import { ActivateJobModal } from "src/jobs/common/ActivateJobModal";
import { DeactivateJobModal } from "src/jobs/common/DeactivateJobModal";
import { DeleteJobModal } from "src/jobs/common/DeleteJobModal";
import { DestinationDropdown } from "src/jobs/common/DestinationDropdown";
import { JobStatusPill } from "src/jobs/common/JobStatusPill";
import { ManageJobModal } from "src/jobs/common/ManageJobModal";
import { RunButton } from "src/jobs/common/RunButton";
import { RunJobModal } from "src/jobs/common/RunJobModal";
import { SourceDropdown } from "src/jobs/common/SourceDropdown";
import { TrafficPolicyModal } from "src/jobs/common/TrafficPolicyModal";
import { WhitePane } from "src/jobs/common/WhitePane";
import { useRunJobHandler, useUsedVersions } from "src/jobs/common/hooks";
import {
  ScheduleForm,
  WEEKDAYS,
  formatSchedule,
  getFutureMatches,
  handlePreconditionError,
  parseSchedule,
} from "src/jobs/jobUtils";
import { Job, JobConfiguration, JobRateLimitInterval } from "src/jobs/types";
import { DetailHeader } from "src/layout/DetailHeader";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useFlowContext } from "src/router/routerContextHooks";
import {
  FlowPageParamsT,
  JobPageParamsT,
  getFlowSubpageUrl,
  getUrlToAuthoringPage,
  getUrlToJobsPage,
} from "src/router/urls";
import { formatDate } from "src/utils/datetime";
import { logger } from "src/utils/logger";
import { formatOrdinalNumber } from "src/utils/numbers";
import {
  getLocalTimezone,
  getTimezoneOffsetHours,
  timezoneOptions,
} from "src/utils/timezones";
import { useParamsDecode } from "src/utils/useParamsDecode";

const useDeactivateJob = (job?: Job) => {
  const { flow, workspace } = useFlowContext();

  const deactivateJobMutation = useDeactivateJobMutation(
    workspace.base_url!,
    flow.id,
  );
  const isMutating = !!useIsMutating({
    mutationKey: ["deactivate_job", workspace.base_url, flow.id],
  });

  const deactivateJob = async () => {
    if (!job) return;
    try {
      await deactivateJobMutation.mutateAsync(job.id);
    } catch (error) {
      logger.error(error);

      const isPreconditionError = await handlePreconditionError(
        workspace,
        job,
        error,
      );

      if (!isPreconditionError)
        toastActions.failure({
          title: "Failed to deactivate Job",
          description: TAKTILE_TEAM_NOTIFIED,
        });
    }
  };

  return { deactivateJob, isMutating };
};

type JobHeaderProps = {
  flow: FlowT;
  job?: Job;
  onEdit: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
};

const JobHeader: React.FC<JobHeaderProps> = ({
  job,
  onEdit,
  onDuplicate,
  onDelete,
}) => {
  const { jobs: jobCapabilities } = useCapabilities();
  const { flow_id: flowId, orgId, wsId } = useParamsDecode<FlowPageParamsT>();

  const {
    isOpen: activateModalIsOpen,
    openModal: openActivateModal,
    closeModal: closeActivateModal,
  } = useModal();

  const {
    isOpen: deactivateModalIsOpen,
    openModal: openDeactivateModal,
    closeModal: closeDeactivateModal,
  } = useModal();

  return (
    <>
      <DetailHeader
        actions={
          <>
            <div className="flex items-center gap-x-3">
              {job && (
                <>
                  <RunButton job={job} />
                  {jobCapabilities.canToggleStatus && (
                    <Button
                      dataLoc="toggle-job-activation"
                      size="sm"
                      variant="secondary"
                      onClick={
                        job.status === "active"
                          ? openDeactivateModal
                          : openActivateModal
                      }
                    >
                      {job.status === "active" ? "Deactivate" : "Activate"}
                    </Button>
                  )}
                  <JobMenu
                    job={job}
                    onDelete={onDelete}
                    onDuplicate={onDuplicate}
                    onEdit={onEdit}
                  />
                </>
              )}
            </div>
          </>
        }
        main={
          <div
            className="flex items-center gap-x-3"
            data-loc="job-content-header-left"
          >
            <Link
              data-loc="go-back"
              to={getFlowSubpageUrl(orgId, wsId, flowId, "jobs")}
            >
              <Icon icon={faArrowLeft} padding={false} size="xs" />
            </Link>
            {!job ? (
              <SkeletonPlaceholder height="h-4" width="w-16" />
            ) : (
              <>
                <span className="text-gray-800 font-inter-semibold-13px">
                  {job.name}
                </span>
                <JobStatusPill status={job.status} />
              </>
            )}
            {job?.status === "active" &&
              job.schedule &&
              job.schedule_timezone && (
                <Pill variant="gray">
                  <Pill.Text>
                    Scheduled to run again on{" "}
                    {formatDate(
                      getFutureMatches(job.schedule, job.schedule_timezone),
                      "d MMMM yyyy h:mmaaa",
                    )}
                  </Pill.Text>
                </Pill>
              )}
          </div>
        }
      />

      <ActivateJobModal
        isOpen={activateModalIsOpen}
        job={job}
        onClose={closeActivateModal}
      />
      <DeactivateJobModal
        isOpen={deactivateModalIsOpen}
        job={job}
        onClose={closeDeactivateModal}
      />
    </>
  );
};

const Subheader: React.FC<{
  isLoading: boolean;
  job?: Job;
}> = ({ isLoading, job }) => {
  const { jobs: jobCapabilities } = useCapabilities();
  const loading = isLoading || !job;
  const isJobActive = job?.status === "active";
  const showJobNotEditableinfo =
    jobCapabilities.canChangeSchedule && isJobActive;
  const showJobNotEditableByEditor = !jobCapabilities.canChangeSchedule;
  const showDisablingAction = jobCapabilities.canToggleStatus;
  const { deactivateJob, isMutating: jobIsMutating } = useDeactivateJob(job);
  const deactivateJobAction = {
    text: "Deactivate Job",
    onClick: !jobIsMutating ? deactivateJob : noop,
  };

  return (
    <Summary>
      <Summary.Cell icon={faCube} title="Decision Flow">
        {loading ? (
          <SkeletonPlaceholder height="h-4" width="w-16" />
        ) : (
          <Tooltip
            body="You cannot change Decision Flow of an active Job. To edit it, please deactivate the Job first."
            disabled={!showJobNotEditableinfo}
            footerAction={showDisablingAction ? deactivateJobAction : undefined}
            placement="bottom"
            asChild
          >
            <span>
              <ConnectDecisionFlow job={job} />
            </span>
          </Tooltip>
        )}
      </Summary.Cell>
      <Summary.Cell icon={faArrowUpRight} title="Data source" truncate={false}>
        {loading ? (
          <SkeletonPlaceholder height="h-4" width="w-16" />
        ) : (
          <Tooltip
            body="You cannot change data source of an active Job. To edit the data source, please deactivate the Job first."
            disabled={!showJobNotEditableinfo}
            footerAction={showDisablingAction ? deactivateJobAction : undefined}
            placement="bottom"
            asChild
          >
            {/*The empty div is required for the Tooltip to attach to, it can't attach to a dropdown button.*/}
            <div>
              <SourceDropdown job={job} />
            </div>
          </Tooltip>
        )}
      </Summary.Cell>
      {isFeatureFlagEnabled(FEATURE_FLAGS.jobDestination) && (
        <Summary.Cell
          icon={faArrowDownRight}
          title="Data destination"
          truncate={false}
        >
          {loading ? (
            <SkeletonPlaceholder height="h-4" width="w-16" />
          ) : (
            <Tooltip
              body="You cannot change data destination of an active Job. To edit the data destination, please deactivate the Job first."
              disabled={!showJobNotEditableinfo}
              footerAction={
                showDisablingAction ? deactivateJobAction : undefined
              }
              placement="bottom"
              asChild
            >
              {/*The empty div is required for the Tooltip to attach to, it can't attach to a dropdown button.*/}
              <div>
                <DestinationDropdown job={job} />
              </div>
            </Tooltip>
          )}
        </Summary.Cell>
      )}
      <Summary.Cell icon={faClock} title="Schedule">
        {loading ? (
          <SkeletonPlaceholder height="h-4" width="w-16" />
        ) : (
          <Tooltip
            body={
              showJobNotEditableinfo
                ? "You cannot change the schedule of an active Job. To edit that, please deactivate the Job first."
                : "Only admins can set schedules"
            }
            disabled={!(showJobNotEditableinfo || showJobNotEditableByEditor)}
            footerAction={
              showDisablingAction && showJobNotEditableinfo
                ? deactivateJobAction
                : undefined
            }
            placement="bottom"
            asChild
          >
            <span>
              <SetSchedule job={job} />
            </span>
          </Tooltip>
        )}
      </Summary.Cell>
      <Summary.Cell icon={faCog} title="Advanced Settings">
        {loading ? (
          <SkeletonPlaceholder height="h-4" width="w-16" />
        ) : (
          <Tooltip
            body="You cannot change advance settings of an active Job. To edit them, please deactivate the Job first."
            disabled={!isJobActive}
            placement="bottom"
          >
            <span>
              <SetAdvancedSettings job={job} />
            </span>
          </Tooltip>
        )}
      </Summary.Cell>
    </Summary>
  );
};

const ConnectDecisionFlow: React.FC<{ job: Job }> = ({ job }) => {
  const { orgId, workspace, flow } = useFlowContext();
  const { jobs: jobCapabilities } = useCapabilities();
  const disabled = job.status === "active" || !jobCapabilities.canEdit;
  const { isOpen, openModal, closeModal } = useModal();

  const usedVersions = useUsedVersions(job);

  const areSomeSelectedVersionsArchived = usedVersions.some(
    (v) => v.status === FlowVersionStatus.ARCHIVED,
  );

  const selectedVersion =
    usedVersions.length !== 0 ? (
      <div className="flex items-center gap-x-0.5">
        <span className="text-gray-800">{flow.name}</span>
        <Pill size="sm" variant="gray">
          <Pill.Text>
            {usedVersions.length === 1
              ? usedVersions[0].name
              : "Multiple versions"}
          </Pill.Text>
        </Pill>
        {areSomeSelectedVersionsArchived && (
          <Pill size="sm" variant="yellow">
            <Pill.Icon icon={faWarning} />
            Archived
          </Pill>
        )}
        <Link
          target="_blank"
          to={getUrlToAuthoringPage(
            orgId,
            workspace.id,
            flow.id,
            usedVersions[0].id,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <Icon
            color="text-gray-500"
            icon={faArrowUpRightFromSquare}
            size="2xs"
          />
        </Link>
        {!disabled && (
          <Icon color="text-gray-500" icon={faChevronDown} size="2xs" />
        )}
      </div>
    ) : (
      "Connect a Decision Flow"
    );

  return (
    <>
      <button
        className="text-indigo-600"
        data-loc="connect-flow-button"
        disabled={disabled}
        onClick={openModal}
      >
        {selectedVersion}
      </button>
      <TrafficPolicyModal job={job} open={isOpen} onClose={closeModal} />
    </>
  );
};

const DEFAULT_VALUES: ScheduleForm = {
  every: "hour",
  minute: 0,
  timezone: getLocalTimezone(),
};

const SetScheduleForm: React.FC<{
  job: Job;
  onSubmit: (schedule?: ScheduleForm) => void;
  isSubmitting: boolean;
}> = ({ job, onSubmit, isSubmitting }) => {
  const { formState, control, handleSubmit, register } = useForm<ScheduleForm>({
    mode: "onBlur",
    defaultValues: job.schedule
      ? parseSchedule(job.schedule, job.schedule_timezone ?? undefined)
      : DEFAULT_VALUES,
  });

  const unit = useWatch({ control, name: "every" });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content>
        <div className="flex flex-col gap-y-6">
          <div>
            <Label required>Repeat every</Label>
            <Controller
              control={control}
              defaultValue={DEFAULT_VALUES.every}
              name="every"
              render={({ field }) => (
                <Select
                  options={[
                    { key: "hour", value: "Hour" },
                    { key: "day", value: "Day" },
                    { key: "week", value: "Week" },
                    { key: "month", value: "Month" },
                  ]}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
              rules={{ required: true }}
            />
          </div>
          {unit === "week" && (
            <div>
              <Label required>On these days</Label>
              <div className="flex flex-wrap gap-x-2">
                {WEEKDAYS.map((day) => (
                  <label
                    key={day}
                    className="flex items-center gap-x-2 text-gray-700 font-inter-normal-13px"
                  >
                    <Checkbox {...register(`weekDays.${day}`)} /> {day}
                  </label>
                ))}
              </div>
            </div>
          )}
          {unit === "month" && (
            <div>
              <Label required>On these days</Label>
              <Controller
                control={control}
                defaultValue={[1]}
                name="monthDays"
                render={({ field }) => (
                  <Select
                    options={range(1, 32)
                      .map((day) => ({
                        key: day.toString(),
                        value: `${formatOrdinalNumber(day)} day of the month`,
                      }))
                      .concat({ key: "L", value: "Last day of the month" })}
                    placeholder="Select days"
                    value={field.value?.map((v) => v.toString()) ?? []}
                    multiple
                    onChange={(value) =>
                      field.onChange(
                        value.map((v) => (v !== "L" ? parseInt(v) : v)),
                      )
                    }
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          )}
          <div>
            <Label required>At this time</Label>
            <div className="flex items-center gap-x-2">
              {unit !== "hour" && (
                <div className="flex-1">
                  <Controller
                    control={control}
                    defaultValue={0}
                    name="hour"
                    render={({ field }) => (
                      <Select
                        options={range(0, 24).map((minute) => ({
                          key: minute.toString(),
                          value: minute.toString(),
                        }))}
                        value={field.value?.toString() ?? null}
                        onChange={(value) => field.onChange(parseInt(value))}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
              )}
              <div className="flex-1">
                <Controller
                  control={control}
                  defaultValue={DEFAULT_VALUES.minute}
                  name="minute"
                  render={({ field }) => (
                    <Select
                      options={range(0, 60, 5).map((minute) => ({
                        key: minute.toString(),
                        value:
                          unit === "hour"
                            ? `${minute} min past the hour`
                            : padStart(minute.toString(), 2, "0"),
                      }))}
                      value={field.value.toString()}
                      onChange={(value) => field.onChange(parseInt(value))}
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
              <div className={unit === "hour" ? "flex-1" : "flex-3"}>
                <Controller
                  control={control}
                  defaultValue={getLocalTimezone()}
                  name="timezone"
                  render={({ field }) => (
                    <Select
                      options={timezoneOptions}
                      searchPlaceholder="Search time zone..."
                      value={field.value}
                      searchable
                      onChange={field.onChange}
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="create-schedule"
            disabled={!formState.isValid || isSubmitting}
            htmlType="submit"
            loading={isSubmitting}
            variant="primary"
          >
            Set schedule
          </Button>
        }
      />
    </form>
  );
};

const SetSchedule: React.FC<{ job: Job }> = ({ job }) => {
  const { jobs: jobCapabilities } = useCapabilities();
  const disabled =
    !jobCapabilities.canChangeSchedule || job.status === "active";
  const { workspace } = useFlowContext();
  const { isOpen, isVisible, closeModal, openModal, afterLeave } = useModal();
  const jobUpdateMutation = useUpdateJob(workspace.base_url!, job);

  const onSubmit = async (schedule?: ScheduleForm) => {
    try {
      await jobUpdateMutation.mutateAsync({
        schedule: schedule ? formatSchedule(schedule) : null,
        schedule_timezone: schedule?.timezone,
        etag: job.etag,
      });
      closeModal();
    } catch (error) {
      logger.error(error);

      const isPreconditionError = await handlePreconditionError(
        workspace,
        job,
        error,
      );

      if (!isPreconditionError)
        toastActions.failure({
          title: "Failed to set schedule",
          description: TAKTILE_TEAM_NOTIFIED,
        });
    }
  };
  const unsetSchedule = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onSubmit();
  };
  const diffHrsLocalAndTimezone = job.schedule_timezone
    ? getTimezoneOffsetHours(job.schedule_timezone).diffHrs * -1
    : 0;

  const button = job.schedule ? (
    <div className="flex items-center gap-x-0.5">
      <span className="min-w-0 flex-1 truncate text-gray-800">
        {cronstrue.toString(job.schedule, {
          verbose: true,
          tzOffset: diffHrsLocalAndTimezone,
        })}
      </span>
      {!disabled && (
        <>
          <Icon color="text-gray-500" icon={faChevronDown} size="2xs" />
          <span role="button" onClick={unsetSchedule}>
            <Icon color="text-gray-500" icon={faTimes} size="2xs" />
          </span>
        </>
      )}
    </div>
  ) : (
    "Set schedule"
  );

  return (
    <>
      <button
        className={twJoin(!job.schedule && "text-indigo-600", "truncate")}
        data-loc="set-schedule"
        disabled={disabled}
        onClick={openModal}
      >
        {button}
      </button>
      <Modal afterLeave={afterLeave} open={isOpen} onClose={closeModal}>
        <Modal.Header description="Define the frequency and timing for automatic Job Runs">
          Set schedule
        </Modal.Header>
        {isVisible && (
          <SetScheduleForm
            isSubmitting={jobUpdateMutation.isLoading}
            job={job}
            onSubmit={onSubmit}
          />
        )}
      </Modal>
    </>
  );
};

export const getConfigurationString = (
  _configuration: JobConfiguration = DEFAULT_JOB_CONFIGURATION,
) => {
  const configuration = _configuration || DEFAULT_JOB_CONFIGURATION;
  if (configuration.execution_concurrency) {
    return `Concurrency ${configuration.execution_concurrency} ${
      configuration.execution_concurrency > 1 ? "rows" : "row"
    } in parallel`;
  }

  if (configuration.rate_limit) {
    return `Rate limit ${configuration.rate_limit} ${
      configuration.rate_limit > 1 ? "rows" : "row"
    } per ${configuration.rate_limit_interval.toLowerCase()}`;
  }

  // Fallback, we never should reach this state
  return "-";
};

const SetAdvancedSettings: React.FC<{ job: Job }> = ({ job }) => {
  const { jobs: jobCapabilities } = useCapabilities();
  const disabled = !jobCapabilities.canEdit || job.status === "active";
  const { workspace } = useFlowContext();
  const { isOpen, isVisible, closeModal, openModal, afterLeave } = useModal();
  const jobUpdateMutation = useUpdateJob(workspace.base_url!, job);

  // eslint-disable-next-line sonarjs/no-identical-functions
  const onSubmit = async (configuration?: JobConfiguration) => {
    try {
      await jobUpdateMutation.mutateAsync({
        configuration: configuration,
        etag: job.etag,
      });
      closeModal();
    } catch (error) {
      logger.error(error);

      const isPreconditionError = await handlePreconditionError(
        workspace,
        job,
        error,
      );

      if (!isPreconditionError)
        toastActions.failure({
          title: "Failed to set schedule",
          description: TAKTILE_TEAM_NOTIFIED,
        });
    }
  };

  const button = (
    <div className="flex items-center gap-x-0.5">
      <span className="min-w-0 flex-1 truncate text-gray-800">
        {getConfigurationString(job.configuration)}
      </span>
      {!disabled && (
        <Icon color="text-gray-500" icon={faChevronDown} size="2xs" />
      )}
    </div>
  );

  return (
    <>
      <button
        className={twJoin(!job.schedule && "text-indigo-600", "truncate")}
        data-loc="set-advanced-settings"
        disabled={disabled}
        onClick={openModal}
      >
        {button}
      </button>
      <Modal afterLeave={afterLeave} open={isOpen} onClose={closeModal}>
        <Modal.Header>Advanced settings</Modal.Header>
        {isVisible && (
          <SetAdvancedSettingsForm
            isSubmitting={jobUpdateMutation.isLoading}
            job={job}
            onSubmit={onSubmit}
          />
        )}
      </Modal>
    </>
  );
};

const DEFAULT_JOB_CONFIGURATION_VALUES = {
  execution_concurrency: 1,
  rate_limit: 100,
  rate_limit_interval: JobRateLimitInterval.SECOND,
};

const DEFAULT_JOB_CONFIGURATION: JobConfiguration = {
  execution_concurrency: DEFAULT_JOB_CONFIGURATION_VALUES.execution_concurrency,
  rate_limit: null,
  rate_limit_interval: null,
};

type LastUsedValues = {
  rate_limit: Nullable<number>;
  rate_limit_interval: Nullable<JobRateLimitInterval>;
  execution_concurrency: Nullable<number>;
};

const SetAdvancedSettingsForm: React.FC<{
  job: Job;
  onSubmit: (configuration?: JobConfiguration) => void;
  isSubmitting: boolean;
}> = ({ job, onSubmit, isSubmitting }) => {
  const lastUsedValuesRef = useRef<LastUsedValues>({
    ...DEFAULT_JOB_CONFIGURATION_VALUES,
    ...pickBy(job.configuration, (value) => !isNil(value)),
  });
  const lastUsedValues = lastUsedValuesRef.current;
  const form = useForm<JobConfiguration>({
    defaultValues: job.configuration ?? DEFAULT_JOB_CONFIGURATION,
  });

  const execution_concurrency = useWatch({
    control: form.control,
    name: "execution_concurrency",
  });

  const mode = execution_concurrency ? "execution_concurrency" : "rate_limit";

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Modal.Content>
        <div className="flex flex-col gap-y-6">
          <SimpleRadioGroup
            orientation="vertical"
            value={mode}
            onValueChange={(mode) => {
              const currentValues = form.getValues();
              if (mode === "execution_concurrency") {
                form.setValue(
                  "execution_concurrency",
                  lastUsedValues.execution_concurrency,
                );
                form.setValue("rate_limit", null);
                form.setValue("rate_limit_interval", null);
              } else {
                form.setValue("execution_concurrency", null);
                form.setValue("rate_limit", lastUsedValues.rate_limit);
                form.setValue(
                  "rate_limit_interval",
                  lastUsedValues.rate_limit_interval,
                );
              }

              lastUsedValuesRef.current = {
                ...lastUsedValues,
                ...pickBy(currentValues, (value) => !isNil(value)),
              };
            }}
          >
            <SimpleRadioGroup.Item
              label="Execution concurrency"
              labelClassName="text-gray-800 font-inter-semibold-13px ml-2"
              value="execution_concurrency"
              boldLabel
            />
            <div className="ml-6">
              <p className="text-gray-500 font-inter-normal-12px">
                Specify how many decisions are permitted to run in parallel. A
                higher concurrency level can expedite the completion of job but
                may place more demand on external connections. Adjust this
                setting to optimize performance without straining your external
                connections.
              </p>
              <div className="mt-2">
                <Controller
                  control={form.control}
                  name="execution_concurrency"
                  render={({ field }) => (
                    <Input
                      disabled={mode !== "execution_concurrency"}
                      type="number"
                      value={
                        field.value ||
                        lastUsedValues.execution_concurrency ||
                        ""
                      }
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  )}
                  rules={{ min: 1 }}
                />
              </div>
            </div>
            <SimpleRadioGroup.Item
              label="Rate limit"
              labelClassName="text-gray-800 font-inter-semibold-13px ml-2"
              value="rate_limit"
              boldLabel
            />
            <div className="ml-6">
              <p className="text-gray-500 font-inter-normal-12px">
                Adjust the maximum number of decisions that can execute in a
                time interval. This control helps to prevent overwhelming
                external connections with too frequent requests, allowing for a
                cool-down period between decisions.
              </p>
              <div className="mt-2 flex items-center gap-x-2">
                <div className="w-1/2">
                  <Controller
                    control={form.control}
                    name="rate_limit"
                    render={({ field }) => (
                      <Input
                        disabled={mode !== "rate_limit"}
                        type="number"
                        value={field.value || lastUsedValues.rate_limit || ""}
                        fullWidth
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                    )}
                    rules={{ min: 1 }}
                  />
                </div>
                <div className="shrink-0 text-gray-500 font-inter-normal-12px">
                  decisions per
                </div>
                <div className="flex-1">
                  <Controller
                    control={form.control}
                    name="rate_limit_interval"
                    render={({ field }) => (
                      <Select
                        disabled={mode !== "rate_limit"}
                        options={Object.values(JobRateLimitInterval).map(
                          (interval) => ({
                            key: interval,
                            value: capitalize(interval.toLowerCase()),
                          }),
                        )}
                        value={
                          field.value || lastUsedValues.rate_limit_interval
                        }
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </SimpleRadioGroup>
          <Callout type="neutral">
            <p>
              A new decision is initiated once the previous decision is in{" "}
              <Pill size="sm" variant="gray">
                <Pill.Text>completed</Pill.Text>
              </Pill>{" "}
              <Pill size="sm" variant="gray">
                <Pill.Text>failed</Pill.Text>
              </Pill>{" "}
              <Pill size="sm" variant="gray">
                <Pill.Text>pending</Pill.Text>
              </Pill>{" "}
              or in{" "}
              <Pill size="sm" variant="gray">
                <Pill.Text>review</Pill.Text>
              </Pill>
              state.
            </p>
          </Callout>
          {form.formState.errors.execution_concurrency && (
            <Callout type="error">
              Execution concurrency must be at least 1
            </Callout>
          )}
          {form.formState.errors.rate_limit && (
            <Callout type="error">Rate limit must be at least 1</Callout>
          )}
        </div>
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="create-schedule"
            disabled={isSubmitting}
            htmlType="submit"
            loading={isSubmitting}
            variant="primary"
          >
            Save
          </Button>
        }
      />
    </form>
  );
};

export const JobContent: React.FC = () => {
  const { orgId, flow, workspace } = useFlowContext();
  const { job_id } = useParamsDecode<JobPageParamsT>();
  const { data: job, isLoading, isError } = useJob(workspace.base_url!, job_id);
  const {
    data: modalAction,
    isOpen,
    openModal,
    closeModal,
    afterLeave,
  } = useModal<"duplicate" | "update">();
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  if (isError) {
    return (
      <div className="flex w-full items-center justify-center">
        <EmptyState
          action={
            <Link to={getUrlToJobsPage(orgId, workspace.id, flow.id)}>
              <Button iconLeft={faArrowLeftSolid} variant="secondary">
                Go to Jobs
              </Button>
            </Link>
          }
          description="To see all jobs, please go to the jobs tab on a Decision Flow."
          headline="This Job was not found"
          icon={faBolt}
        />
      </div>
    );
  }

  return (
    <FloatingWindowsProvider>
      <div className="flex h-full grow flex-col justify-stretch divide-y divide-gray-200 bg-gray-100">
        <JobHeader
          flow={flow}
          job={job}
          onDelete={openDeleteModal}
          onDuplicate={() => openModal("duplicate")}
          onEdit={() => openModal("update")}
        />
        <Subheader isLoading={isLoading} job={job} />
        <div className="mx-auto flex min-h-0 w-full max-w-[1800px] p-5">
          {job && (
            <WhitePane>
              <RunsTable job={job} />
            </WhitePane>
          )}
        </div>
        <ManageJobModal
          action={modalAction ?? "update"}
          afterLeave={afterLeave}
          job={job}
          open={isOpen}
          onClose={closeModal}
        />
        <DeleteJobModal
          isOpen={isDeleteModalOpen}
          job={job}
          onClose={closeDeleteModal}
        />
      </div>
    </FloatingWindowsProvider>
  );
};

const JobMenu: React.FC<{
  job: Job;
  onEdit: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
}> = ({ job, onEdit, onDuplicate, onDelete }) => {
  const { jobs: jobCapabilities } = useCapabilities();

  const { afterLeave, isOpen, onConfirm, openModal, closeModal } =
    useRunJobHandler(job);

  if (
    !(
      jobCapabilities.canCreate ||
      jobCapabilities.canDelete ||
      jobCapabilities.canEdit ||
      jobCapabilities.canRunSandbox
    )
  )
    return null;
  return (
    <>
      <EllipsisOptionsDropdown
        buttonClassName="text-gray-500"
        buttonDataLoc="job-additional-actions"
        elements={(
          [
            {
              key: "Edit Job details",
              icon: faPen,
              action: onEdit,
              disabled:
                job.status === "active" &&
                "You cannot edit an active Job. To edit the Job details, please deactivate the Job first.",
            },
            job.status === "active" &&
              jobCapabilities.canRunSandbox && {
                key: "Run in Sandbox",
                icon: faBolt,
                action: openModal,
              },
            {
              key: "Duplicate Job",
              icon: faClone,
              action: onDuplicate,
            },
            {
              key: "Delete Job",
              icon: faTrashAlt,
              action: onDelete,
            },
          ] satisfies (OptionsDropdownElement | Boolean)[]
        ).filter(Boolean as any as ExcludesFalse)}
      />
      <RunJobModal
        afterLeave={afterLeave}
        closeModal={closeModal}
        env={DecisionEnvironment.SANDBOX}
        isOpen={isOpen}
        job={job}
        onConfirm={onConfirm}
      />
    </>
  );
};
