import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import {
  useCreateWorkspace,
  useUpdateWorkspace,
  useWorkspaceWithSettings,
} from "src/api/queries";
import { useModal } from "src/design-system/Modal";
import { toastActions } from "src/design-system/Toast/utils";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { ShorteningRetentionPolicyModal } from "src/layout/WorkspaceFormModal/ShorteningRetentionPolicyModal";
import {
  WorkspaceSettingsModal,
  CreateWorkspaceForm,
} from "src/layout/WorkspaceFormModal/WorkspaceSettingsModal";
import { retentionPeriodToMinutes } from "src/layout/WorkspaceFormModal/utils";
import { getUrlToWsDashboard } from "src/router/urls";
import { isConflictError } from "src/utils/predicates";

type WorkspaceFormModalProps = {
  isOpen: boolean;
  onClose: () => void;
  orgId: string;
} & (
  | { variant: "Edit"; wsId: string }
  | { variant: "Create"; wsId?: undefined }
);

export const WorkspaceFormModal: React.FC<WorkspaceFormModalProps> = ({
  orgId,
  ...props
}) => {
  const {
    isOpen: isShorteningRetentionPolicyModalOpen,
    openModal: openShorteningRetentionPolicyModal,
    closeModal: closeShorteningRetentionPolicyModal,
    afterLeave: afterLeaveShorteningRetentionPolicyModal,
    data: shorteningRetentionPolicyModalData,
  } = useModal<CreateWorkspaceForm>();

  const createWorkspace = useCreateWorkspace();
  const updateWorkspace = useUpdateWorkspace();
  const currentWorkspace = useWorkspaceWithSettings(props.wsId);
  const navigate = useNavigate();

  const onSubmit = async (data: CreateWorkspaceForm) => {
    const { name, slug, region, flow_services_version, settings } = data;

    try {
      if (props.variant === "Create" && orgId) {
        const createdWorkspace = await createWorkspace.mutateAsync({
          name: name,
          slug: slug,
          organizationId: orgId,
          awsRegion: region,
        });

        if (createdWorkspace) {
          navigate(
            getUrlToWsDashboard({
              orgId: createdWorkspace.organization_id,
              wsId: createdWorkspace.id,
            }),
          );
        }
        props.onClose();
      } else if (props.variant === "Edit" && currentWorkspace.data) {
        // Check for data retention policy shortening
        if (settings?.data_retention && !isShorteningRetentionPolicyModalOpen) {
          tracker.emit(
            trackingEvents.setDataRetention({
              organization_id: orgId,
            }),
          );
          const originalRetentionPeriod = retentionPeriodToMinutes(
            currentWorkspace.data.settings.data_retention,
          );
          const newRetentionPeriod = retentionPeriodToMinutes(
            settings.data_retention,
          );

          if (newRetentionPeriod < originalRetentionPeriod) {
            // Cancel the form submission and open the shortening policy modal
            openShorteningRetentionPolicyModal(data);
            return;
          }
        }
        await updateWorkspace.mutateAsync({
          id: currentWorkspace.data.id,
          name,
          settings,
          flow_services_version,
        });
        props.onClose();
      }
    } catch (e) {
      if (isConflictError(e)) {
        toastActions.failure({
          title: "Error submitting workspace configuration",
          description: (e as AxiosError<any>).response?.data.detail,
          duration: Infinity,
        });
      } else if (!(axios.isAxiosError(e) && e.response?.status === 422)) {
        toastActions.failure({
          title: "Error submitting workspace configuration",
          description: "Please try again later.",
        });
      } else {
        throw e;
      }
    }
  };

  return (
    <>
      <WorkspaceSettingsModal
        {...props}
        isOpen={props.isOpen && !isShorteningRetentionPolicyModalOpen}
        workspace={currentWorkspace.data}
        onSubmit={onSubmit}
      />
      <ShorteningRetentionPolicyModal
        afterLeave={afterLeaveShorteningRetentionPolicyModal}
        dataRetention={
          shorteningRetentionPolicyModalData?.settings?.data_retention
        }
        isOpen={props.isOpen && isShorteningRetentionPolicyModalOpen}
        onClose={closeShorteningRetentionPolicyModal}
        onConfirm={() => onSubmit(shorteningRetentionPolicyModalData!)}
      />
    </>
  );
};
