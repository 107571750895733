import { faEmptySet } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { Listbox } from "@headlessui/react";
import { AnimatePresence, m } from "framer-motion";
import Fuse from "fuse.js";
import { times } from "lodash";
import { useRef, useState } from "react";
import { usePopper } from "react-popper";

import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Skeleton } from "src/base-components/Skeleton";
import { EmptyState } from "src/design-system/EmptyState";
import { useFuseSearch } from "src/utils/useFuseSearch";

type Option<V> = {
  key: string;
  value: V;
  disabled?: boolean;
};

type SearchableSelectProps<V = string> = {
  selected?: Nullable<string>;
  onChange: (key: string, value: V) => void;
  placeholder?: string;
  buttonRenderer?: (args: ButtonProps<V>) => React.ReactNode;
  itemRenderer?: (args: ItemProps<V>) => React.ReactNode;
  skeletonRenderer?: () => React.ReactNode;
  searchPlaceholder?: string;
  isLoading?: boolean;
  emptyState?: React.ReactNode;
  options: Option<V>[];
  dataLoc?: string;
  searchOptions?: Fuse.IFuseOptions<Option<V>>;
};

export const SearchableSelect = <V,>({
  options,
  selected,
  onChange,
  placeholder = "",
  buttonRenderer = defaultButtonRenderer,
  itemRenderer = defaultItemRenderer,
  skeletonRenderer = defaultSkeletonRenderer,
  searchPlaceholder = "Search...",
  isLoading,
  emptyState = DEFAULT_EMPTY_STATE,
  dataLoc,
  searchOptions = {},
}: SearchableSelectProps<V>) => {
  const [query, setQuery] = useState("");

  const referenceElement = useRef<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement.current,
    popperElement,
    {
      strategy: "fixed",
      placement: "bottom-start",
      modifiers: [{ name: "offset", options: { offset: [0, 8] } }],
    },
  );

  const search = useFuseSearch(options, {
    ...searchOptions,
    keys: searchOptions.keys
      ? searchOptions.keys.map((key) => `value.${key}`)
      : ["value"],
  });

  const results = search(query);
  const selectedValue =
    options.find((option) => option.key === selected) ?? null;

  return (
    <Listbox
      value={selectedValue}
      onChange={(option) => {
        if (!option) return;

        onChange?.(option.key, option.value);
      }}
    >
      {({ open }) => (
        <>
          <Listbox.Button
            ref={referenceElement}
            className="flex"
            data-loc={dataLoc}
          >
            {buttonRenderer({
              value: selectedValue?.value,
              key: selectedValue?.key,
              placeholder,
              isOpen: open,
            })}
          </Listbox.Button>
          <AnimatePresence initial={false}>
            {open && (
              <div
                ref={setPopperElement}
                style={popperStyles.popper}
                {...popperAttributes.popper}
                className="fixed z-50"
              >
                <Listbox.Options
                  animate="visible"
                  as={m.div}
                  className="min-h-[288px] w-70 rounded-lg bg-white py-2 shadow-lg"
                  data-loc={`${dataLoc}-options`}
                  exit="hidden"
                  initial="hidden"
                  transition={{
                    type: "tween",
                    ease: "easeOut",
                    duration: 0.15,
                  }}
                  variants={{
                    visible: {
                      opacity: 1,
                      scale: 1,
                    },
                    hidden: {
                      opacity: 0,
                      scale: 0.95,
                    },
                  }}
                  static
                  onAnimationComplete={(variant: "visible" | "hidden") =>
                    variant === "hidden" && setQuery("")
                  }
                >
                  <div className="px-4 py-2">
                    <Input
                      ref={(node) => node?.focus()} //Focus on first render
                      disabled={isLoading}
                      placeholder={searchPlaceholder}
                      prefixIcon={{ icon: faSearch }}
                      value={query}
                      fullWidth
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </div>
                  <div className="decideScrollbar max-h-60 overflow-y-auto">
                    {isLoading ? (
                      times(6, skeletonRenderer)
                    ) : results.length > 0 ? (
                      <ul>
                        {results.map((item, index) => (
                          <Listbox.Option
                            key={item.key}
                            className="cursor-pointer px-4 py-2 hover:bg-gray-50"
                            disabled={item.disabled}
                            value={item}
                          >
                            {itemRenderer({
                              key: item.key,
                              value: item.value,
                              index,
                            })}
                          </Listbox.Option>
                        ))}
                      </ul>
                    ) : (
                      emptyState
                    )}
                  </div>
                </Listbox.Options>
              </div>
            )}
          </AnimatePresence>
        </>
      )}
    </Listbox>
  );
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function assertValue(value: unknown): asserts value is string {
  if (typeof value !== "string" && value !== undefined) {
    throw new Error(
      "Looks like you're using non-string value. You need to specify custom `button` and `item` renderers.",
    );
  }
}

type ButtonProps<V> = {
  key: string | undefined;
  value: V | undefined;
  placeholder: string;
  isOpen: boolean;
};

const defaultButtonRenderer = <V,>({ value, placeholder }: ButtonProps<V>) => {
  assertValue(value);

  return (
    <div className="flex items-center gap-x-1 text-gray-800 font-inter-normal-12px">
      {value ?? placeholder}
      <Icon color="text-gray-500" icon={faChevronDown} size="3xs" />
    </div>
  );
};

type ItemProps<V> = {
  index: number;
  key: string;
  value: V;
};

const defaultItemRenderer = <V,>({ value }: ItemProps<V>) => {
  assertValue(value);

  return <span className="text-gray-800 font-inter-normal-13px">{value}</span>;
};

const defaultSkeletonRenderer = () => (
  <div className="flex items-center gap-x-2 px-4 py-2 font-inter-normal-13px">
    <Skeleton />
  </div>
);

const DEFAULT_EMPTY_STATE = (
  <EmptyState
    description="Change your search query to try again"
    headline="No items found"
    icon={faEmptySet}
  />
);
