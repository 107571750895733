import React, { useState } from "react";
import { useTimeout } from "usehooks-ts";

import { Pill } from "src/base-components/Pill";
import { BeMappedNode } from "src/constants/NodeDataTypes";
import { NodePill } from "src/copilot/NodePill";

type Props = {
  node: BeMappedNode | null;
  delay: number;
};

export const NodeMention = ({ node, delay }: Props) => {
  const [render, setRender] = useState(delay ? false : true);

  useTimeout(() => setRender(true), 1000 * delay);

  return (
    render && (
      <span className="mx-1 inline-flex">
        {node ? (
          <NodePill node={node} />
        ) : (
          <Pill size="sm" variant="gray" fullWidth>
            <Pill.Text>Node not found</Pill.Text>
          </Pill>
        )}
      </span>
    )
  );
};
