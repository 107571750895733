import { useCallback, useEffect, useRef } from "react";
import { create } from "zustand";

import { NodeTypes } from "src/clients/flow-api";
import { NodeSuggestionType } from "src/copilot/NodeSuggestion";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useGraphStore } from "src/store/StoreProvider";

const COPILOT_NODES = [
  NodeTypes.TRANSFORM,
  NodeTypes.ASSIGNMENT_NODE,
  NodeTypes.RULE_2,
] as readonly NodeTypes[];

type Args = {
  nodeId: string;
  suggestion: NodeSuggestionType;
  completionId: string;
  conversationId: string;
};

type NodeSuggestionState = {
  suggestion: NodeSuggestionType | null;
};

type NodeSuggestionActions = {
  setSuggestion: (suggestion: NodeSuggestionType | null) => void;
};

type NodeSuggestionStore = ZustandStore<
  NodeSuggestionState,
  NodeSuggestionActions
>;

const useNodeSuggestionStore = create<NodeSuggestionStore>((set) => ({
  suggestion: null,
  actions: {
    setSuggestion: (suggestion) => set({ suggestion }),
  },
}));

export const useNodeSuggestionActions = () =>
  useNodeSuggestionStore((state) => state.actions);

export const useNodeSuggestion = () =>
  useNodeSuggestionStore((state) => state.suggestion);

export const useFormApplyNodeSuggestion = <T>(
  nodeId: string,
  applyFn: (meta: T) => void,
) => {
  const nodeSuggestion = useNodeSuggestion();
  const { setSuggestion } = useNodeSuggestionActions();

  const applyFnRef = useRef(applyFn);

  useEffect(() => {
    applyFnRef.current = applyFn;
  }, [applyFn]);

  useEffect(() => {
    if (!nodeSuggestion || !nodeId || nodeId !== nodeSuggestion.nodeId) {
      return;
    }

    applyFnRef.current(nodeSuggestion.meta as T);

    setSuggestion(null);
  }, [nodeSuggestion, nodeId, setSuggestion]);
};

export const useHandleApplyNodeSuggestion = () => {
  const { setSuggestion } = useNodeSuggestionActions();
  const { updateNode } = useGraphStore();
  const { orgId, version } = useAuthoringContext();

  return useCallback(
    ({ nodeId, suggestion, completionId, conversationId }: Args) => {
      if (COPILOT_NODES.includes(suggestion.type)) {
        // Update the name
        updateNode({
          nodeId,
          newName: suggestion.name,
        });

        // set suggestion to store so the node editor can apply it
        setSuggestion(suggestion);

        tracker.emit(
          trackingEvents.copilotApplySuggestion({
            organization_id: orgId,
            flow_version_id: version.id,
            etag: version.etag!,
            conversation_id: conversationId,
            completion_id: completionId,
          }),
        );
      }
    },
    [version, orgId, updateNode, setSuggestion],
  );
};
