import { faUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { faCircle, faLink } from "@fortawesome/pro-solid-svg-icons";
import { twJoin } from "tailwind-merge";

import { FlowT, FlowVersionStatusT } from "src/api/flowTypes";
import { ExternalLink } from "src/base-components/ExternalLink";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { FlowDbShallowVersionNoPolicy } from "src/clients/flow-api";
import { Tooltip } from "src/design-system/Tooltip";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { getBaseUrl, getFlowVersionsUrl } from "src/router/urls";
import { versionIsInReview } from "src/utils/flowVersion";
import { pluralize } from "src/utils/stringUtils";

type FlowCardProps = {
  flow: FlowT;
  onClick: () => void;
};

const RelatedFlowsInfo: React.FC<{
  flows: FlowDbShallowVersionNoPolicy[];
  isParent: boolean;
}> = ({ flows, isParent }) => {
  const [firstFlow, ...restFlows] = flows;

  return (
    <>
      <hr className="my-4 border-gray-100" />
      <div className="flex gap-x-2">
        <Icon
          color={isParent ? "text-gray-400" : "text-gray-500"}
          icon={faLink}
          size="sm"
        />
        <p
          className={twJoin(
            "line-clamp-2 font-inter-normal-12px",
            isParent ? "text-gray-400" : "text-gray-600",
          )}
        >
          {isParent ? "Calling " : "Called by "}
          <span className={isParent ? "text-gray-500" : "text-gray-800"}>
            {firstFlow.name}
          </span>
          {restFlows.length > 0 &&
            ` +${pluralize(restFlows.length, "other Decision Flow")}`}
        </p>
      </div>
    </>
  );
};

export const FlowCard: React.FC<FlowCardProps> = ({ flow, onClick }) => {
  const { workspace } = useAuthoringContext();
  const { publishedCount, inReviewCount, draftCount } = {
    publishedCount: flow.versions.filter(
      (v) => v.status === FlowVersionStatusT.PUBLISHED,
    ).length,
    inReviewCount: flow.versions.filter((v) => versionIsInReview(v)).length,
    draftCount: flow.versions.filter(
      (v) => v.status === FlowVersionStatusT.DRAFT && !versionIsInReview(v),
    ).length,
  };

  const hasChildFlows = flow.child_flows && flow.child_flows.length > 0;
  const flowUrl =
    getBaseUrl() +
    getFlowVersionsUrl(workspace.organization_id, workspace.id, flow.id);

  return (
    <li
      className={twJoin(
        "group rounded-lg border p-4",
        hasChildFlows ? "border-gray-50 bg-gray-50" : "border-gray-200",
        !hasChildFlows && "cursor-pointer",
      )}
      data-loc={`child-flow-card-${flow.id}`}
      onClick={hasChildFlows ? undefined : onClick}
    >
      <h4
        className={twJoin(
          "mb-2 flex justify-between gap-x-1 font-inter-semibold-13px",
          hasChildFlows ? "text-gray-500" : "text-gray-800",
        )}
      >
        {flow.name}
        <Tooltip placement="bottom-end" title="Open in new tab">
          <ExternalLink
            className="opacity-0 transition group-hover:opacity-100"
            href={flowUrl}
            onClick={(e) => e.stopPropagation()}
          >
            <Icon color="text-gray-500" icon={faUpRightFromSquare} size="xs" />
          </ExternalLink>
        </Tooltip>
      </h4>
      <Tooltip
        body="You can only call Decision Flows that don't use other Decision Flows on their graph."
        disabled={!hasChildFlows}
        placement="bottom"
        title="This Decision Flow cannot be called"
        asChild
      >
        <div>
          <p
            className={twJoin(
              "mb-4 line-clamp-3 font-inter-normal-12px",
              hasChildFlows ? "text-gray-400" : "text-gray-500",
            )}
          >
            {flow.meta.description || "No description"}
          </p>
          <div className="flex flex-wrap gap-1">
            {publishedCount > 0 && (
              <Pill variant="green">
                <Pill.Icon icon={faCircle} size="sm" />
                <Pill.Text>{publishedCount} Published</Pill.Text>
              </Pill>
            )}
            {inReviewCount > 0 && (
              <Pill variant="indigo-light">
                <Pill.Text>{draftCount} In review</Pill.Text>
              </Pill>
            )}
            {draftCount > 0 && (
              <Pill variant="yellow">
                <Pill.Text>{pluralize(draftCount, "Draft")}</Pill.Text>
              </Pill>
            )}
          </div>
        </div>
      </Tooltip>
      {flow.child_flows &&
        flow.parent_flows &&
        (flow.child_flows.length > 0 || flow.parent_flows.length > 0) && (
          <RelatedFlowsInfo
            flows={
              flow.child_flows.length > 0 ? flow.child_flows : flow.parent_flows
            }
            isParent={flow.child_flows.length > 0}
          />
        )}
    </li>
  );
};
