import { useFormContext, Controller } from "react-hook-form";

import { RedshiftConnectionAuthMethod } from "src/api/connectApi/types";
import { FormItem } from "src/base-components/FormItem";
import { Switch } from "src/base-components/Switch";
import {
  RedshiftConnectionConfigInputsT,
  Environment,
} from "src/connections/types";
import { Tooltip } from "src/design-system/Tooltip";

const SSLHelpText: React.FC = () => {
  return (
    <p>
      When enabled, you can choose to verify the database server's CA
      certificate and host name. For AWS IAM authentication, this is required.
    </p>
  );
};

type PropsT = {
  environment: Environment;
  authMethod: RedshiftConnectionAuthMethod;
};

export const RedshiftConnectionSslConfigFields: React.FC<PropsT> = ({
  environment,
  authMethod,
}) => {
  const { control } = useFormContext<RedshiftConnectionConfigInputsT>();

  const hasSslEnabledKey = `${environment}Config.hasSslEnabled` as const;

  return (
    <div className="mb-6 last:mb-0">
      <FormItem
        className="flex flex-row items-center justify-between"
        description="Verify the database server's CA certificate and host name"
        gap="xxs"
        helpTooltipBody={<SSLHelpText />}
        label="Enable SSL/TLS"
      >
        <Controller
          control={control}
          name={hasSslEnabledKey}
          render={(props) =>
            authMethod === "aws_iam" ? (
              <span className="ml-1 mt-1 inline-block">
                <Tooltip
                  body="AWS IAM authentication requires SSL/TLS to be enabled"
                  placement="top"
                >
                  <Switch
                    disabled={true}
                    enabled={true}
                    onChange={() => {
                      props.field.onChange(!props.field.value);
                    }}
                  />
                </Tooltip>
              </span>
            ) : (
              <Switch
                enabled={props.field.value}
                onChange={() => {
                  props.field.onChange(!props.field.value);
                }}
              />
            )
          }
        />
      </FormItem>
    </div>
  );
};
