import {
  faArrowRight,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { UseQueryResult } from "@tanstack/react-query";
import { Controller, useFormContext } from "react-hook-form";

import { FlowT, FlowVersionFlowChild, FlowVersionT } from "src/api/flowTypes";
import { useFlow } from "src/api/queries";
import { FieldErrorsT } from "src/api/types";
import { Card } from "src/base-components/Card";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { Pill } from "src/base-components/Pill";
import { useSchemas } from "src/datasets/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { DataMap } from "src/parentFlowNodes/DataMap";
import { MissingSchema } from "src/parentFlowNodes/MissingSchema";
import { SchemaField } from "src/parentFlowNodes/SchemaField";
import { SCHEMA_MAPPING_ARROW_WIDTH } from "src/parentFlowNodes/SchemaProperty";
import { SchemaError } from "src/parentFlowNodes/flowNode/types";
import { TruncatedPill } from "src/parentFlowNodes/loopNode/AdvancedSettings";
import { ChildFlowNotAvailable } from "src/parentFlowNodes/loopNode/ChildNotAvailable";
import { LoopNodeForm } from "src/parentFlowNodes/loopNode/LoopNodeEditor";
import { defaultOutputListName } from "src/parentFlowNodes/loopNode/constants";
import {
  buildSchemaTabUrl,
  queryErrorLikelyBecauseChildFlowGotDeleted,
} from "src/parentFlowNodes/utils";
import { ErrorContent } from "src/router/ErrorPage";
import { SchemaOptions } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";

type LoadedProps = {
  childFlowName: string;
  childFlowId: string;
  childFlowSlug: string;
  childVersionUpToDate: FlowVersionFlowChild;
  childVersionRevision: FlowVersionT;
  immutable: boolean;
  fieldErrors: FieldErrorsT | undefined;
  fieldMappingErrors: Record<string, SchemaError>;
};

const LoadedView: React.FC<LoadedProps> = ({
  childVersionUpToDate,
  childFlowName,
  childFlowId,
  childFlowSlug,
  immutable,
  fieldErrors,
  fieldMappingErrors,
  childVersionRevision,
}) => {
  const { orgId, workspace } = useAuthoringContext();
  const childSchemas = useSchemas(
    immutable ? childVersionRevision : childVersionUpToDate,
  );
  const { watch, control } = useFormContext<LoopNodeForm>();
  const isStale = watch("is_stale") || false;
  const outputDestinationListExpression = watch(
    "output_destination_list_expression",
  );

  const tooltipContent = (
    <>
      <p className="mb-2 text-gray-300 font-inter-normal-12px">
        <Pill size="sm" variant="dark-gray">
          item
        </Pill>{" "}
        represents a single element in the list you loop over
      </p>
      <p className="text-gray-300 font-inter-normal-12px">
        <Pill size="sm" variant="dark-gray">
          index
        </Pill>{" "}
        indicates the numerical position of this element, starting with 0
      </p>
    </>
  );

  return (
    <>
      <div className="mb-4 text-gray-500 font-inter-normal-12px">
        Map the data from this Decision Flow to the inputs and outputs of the
        Child Flow{" "}
        <span className="text-gray-800">
          {childFlowName}, {childVersionUpToDate.name}{" "}
        </span>
        <br />
        You can refer to{" "}
        <Tooltip body={tooltipContent} placement="top">
          <Pill size="sm" variant="green">
            item
          </Pill>
        </Tooltip>{" "}
        and{" "}
        <Tooltip body={tooltipContent} placement="top">
          <Pill size="sm" variant="green">
            index
          </Pill>
        </Tooltip>{" "}
        for mapping
        <Tooltip body={tooltipContent} placement="top">
          <Icon color="text-gray-500" icon={faQuestionCircle} size="2xs" />
        </Tooltip>
      </div>
      <DataMap
        childFlowId={childFlowId}
        childFlowName={childFlowName}
        childFlowSlug={childFlowSlug}
        childVersionId={childVersionUpToDate.id}
        fieldMappingErrors={fieldMappingErrors}
        hasError={Object.entries(fieldMappingErrors).length > 0}
        isNodeStale={isStale}
        readonly={immutable}
        schema={childSchemas.input}
        title="Inputs"
        isLoopNode
      />
      <p className="mb-0.5 mt-3 text-gray-800 font-inter-medium-12px">
        Outputs
      </p>
      <div className="mb-2 text-gray-500 font-inter-normal-12px">
        For each loop iteration, all outputs of the Child Flow{" "}
        <span className="text-gray-800">
          {childFlowName}, {childVersionUpToDate.name}{" "}
        </span>{" "}
        will be aggregated to a dictionary and appended to the list{" "}
        <TruncatedPill
          text={`data.${
            outputDestinationListExpression.value || defaultOutputListName
          }`}
        />
      </div>
      <Card variant={childSchemas.output ? "default" : "inverted"}>
        <Card.Content>
          {!childSchemas.output ? (
            <MissingSchema
              childFlowId={childFlowId}
              childVersionId={childVersionUpToDate.id}
              flowName={childFlowName}
              isOutput={true}
              warn={isStale}
            />
          ) : (
            <>
              <div className="flex w-full gap-x-3">
                <div className="w-1/2">
                  <p className="mb-3 text-gray-500 font-inter-normal-12px">
                    {childFlowName}, {childVersionUpToDate.name}
                  </p>
                </div>
                <div className={SCHEMA_MAPPING_ARROW_WIDTH} />
                <div className="w-1/2">
                  <p className="mb-3 text-gray-500 font-inter-normal-12px">
                    Output location in this Decision Flow
                  </p>
                </div>
              </div>
              <div className="mb-1 flex w-full gap-x-3">
                <div className="w-1/2">
                  <div className="flex w-full flex-col gap-y-2">
                    {childSchemas.output.properties.map((field) => (
                      <SchemaField
                        key={field.fieldName}
                        dataLoc={`child-flow-version-output-${field.fieldName}`}
                        name={field.fieldName}
                        nullable={field.type[1] === "null"}
                        required={field.required}
                        schemaTabUrl={buildSchemaTabUrl(
                          orgId,
                          workspace.id,
                          childFlowId,
                          childVersionUpToDate.id,
                          SchemaOptions.Output,
                        )}
                        sensitive={field.sensitive}
                        type={field.type[0] || "string"}
                      />
                    ))}
                  </div>
                </div>
                <div
                  className={
                    SCHEMA_MAPPING_ARROW_WIDTH +
                    " border-l border-gray-500 pt-1.5"
                  }
                >
                  <Icon color="text-gray-500" icon={faArrowRight} size="xs" />
                </div>
                <div className="w-1/2">
                  <Controller
                    control={control}
                    name="output_destination_list_expression.value"
                    render={({ field: controlledField }) => (
                      <AutocompleteCodeInput
                        dataLoc="output-destination-list-expression"
                        disabled={immutable}
                        error={
                          fieldErrors?.[outputDestinationListExpression.id]
                        }
                        placeholder={defaultOutputListName}
                        prefix="data."
                        value={controlledField.value}
                        onChange={controlledField.onChange}
                      />
                    )}
                  />
                </div>
              </div>
            </>
          )}
        </Card.Content>
      </Card>
    </>
  );
};

type InputOutputMappingsProps = {
  immutable: boolean;
  childFlowQuery: ReturnType<typeof useFlow>;
  childVersionRevisionQuery: UseQueryResult<FlowVersionT, Error>;
  fieldErrors: FieldErrorsT | undefined;
  fieldMappingErrors: Record<string, SchemaError>;
  setFlowSelectionOpen: (open: boolean) => void;
};

export const InputOutputMappings: React.FC<InputOutputMappingsProps> = ({
  childVersionRevisionQuery,
  childFlowQuery,
  fieldErrors,
  fieldMappingErrors,
  immutable,
  setFlowSelectionOpen,
}) => {
  const { watch } = useFormContext<LoopNodeForm>();
  const childVersionId = watch("child_flow_version_id");
  return (
    <LoadingView
      queryResult={[childFlowQuery, childVersionRevisionQuery]}
      renderErrored={(message) => {
        if (queryErrorLikelyBecauseChildFlowGotDeleted(message))
          return (
            <ChildFlowNotAvailable
              handleSelectDecisionFlow={() => setFlowSelectionOpen(true)}
              immutable={immutable}
            />
          );
        else return <ErrorContent message={message} />;
      }}
      renderUpdated={([childFlow, childVersionRevision]: [
        FlowT,
        FlowVersionT,
      ]) => {
        const childVersionUpToDate = childFlow.versions.find(
          (version) => version.id === childVersionId,
        );

        if (!childVersionUpToDate)
          return (
            <ChildFlowNotAvailable
              handleSelectDecisionFlow={() => setFlowSelectionOpen(true)}
              immutable={immutable}
            />
          );
        return (
          <LoadedView
            childFlowId={childFlow.id}
            childFlowName={childFlow.name}
            childFlowSlug={childFlow.slug}
            childVersionRevision={childVersionRevision}
            childVersionUpToDate={childVersionUpToDate}
            fieldErrors={fieldErrors}
            fieldMappingErrors={fieldMappingErrors}
            immutable={immutable}
          />
        );
      }}
    />
  );
};
