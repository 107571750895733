import { ConfirmationModal } from "src/base-components/ConfirmationModal";

export const OverwriteConfirmationModal: React.FC<{
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}> = ({ onCancel, onConfirm, isOpen }) => (
  <ConfirmationModal
    cancelationButtonText="No, keep current code"
    confirmationButtonText="Yes, overwrite code"
    open={isOpen}
    title="Overwrite model import code?"
    onClose={onCancel}
    onConfirm={onConfirm}
  >
    <div className="text-gray-500 font-inter-normal-12px">
      Are you sure you want to overwrite the code for this node?
      <br /> This action cannot be undone.
    </div>
  </ConfirmationModal>
);
