import { faMicrochip } from "@fortawesome/pro-regular-svg-icons";
import { Root } from "@radix-ui/react-accordion";
import { omit, pick } from "lodash";
import React, { useState, ReactNode, useCallback } from "react";

import { FlowT } from "src/api/flowTypes";
import { ErrorBaseInfo } from "src/api/types";
import {
  EditorAccordionItem as AccordionItem,
  accordionRootClassName,
} from "src/base-components/EditorAccordionItem";
import { Icon } from "src/base-components/Icon";
import { useDiffViewContext } from "src/changeHistory/DiffViewModal/DiffViewContext";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { MLNodeDataT, MLNode } from "src/constants/NodeDataTypes";
import { Callout } from "src/design-system/Callout";
import { toastActions } from "src/design-system/Toast/utils";
import { DataContainer } from "src/mlNode/DataContainer";
import { MetadataField } from "src/mlNode/MetadataField";
import { ModelDropzone } from "src/mlNode/ModelDropzone";
import { ModelFile } from "src/mlNode/ModelFile";
import { OverwriteConfirmationModal } from "src/mlNode/OverwriteConfirmationModal";
import { Tensor } from "src/mlNode/Tensor";
import { useUploadModel } from "src/mlNode/queries";
import { CodeNodeEditor } from "src/nodeEditor/CodeNodeEditor";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";

const ContentContainer: React.FC<{ children: ReactNode; error?: any }> = ({
  children,
  error,
}) => (
  <div className="flex h-full flex-col">
    {error && (
      <div className="mb-4">
        <Callout type="error">{error.error}</Callout>
      </div>
    )}
    {children}
  </div>
);

type PropsT = {
  selectedNode: MLNode;
  flow: FlowT;
  workspace: WorkspaceDataplane;
  displayedError?: ErrorBaseInfo;
} & NodeEditorBaseProps<MLNodeDataT>;

export const MLNodeEditor: React.FC<PropsT> = ({
  selectedNode,
  flow,
  workspace,
  immutable,
  displayedError,
  isReactive,
  onUpdate,
}) => {
  const { renderedInDiffView } = useDiffViewContext();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const updateNode = useCallback(
    (data: Partial<MLNodeDataT>) => {
      onUpdate({ newData: data });
    },
    [onUpdate],
  );
  // What if no baseUrl yet?
  // I suppose this is possible only when WS provisioning happens

  const uploadModel = useUploadModel({
    baseUrl: workspace.base_url,
    flowSlug: flow.slug,
    onMutate: (modelData) => {
      updateNode({ model_filename: modelData.file.name });
      // Keep old file name in context object
      return { oldFileName: selectedNode.data?.model_filename };
    },
    onSuccess: (data) => {
      if (selectedNode.data?.model_id) {
        updateNode(omit(data.metadata, ["src", "model_filename"]));
        setIsOpenModal(true);
      } else {
        updateNode(omit(data.metadata, ["model_filename"]));
      }
    },
    onError: (context) => {
      // Rollback to the old file name
      updateNode({ model_filename: context?.oldFileName });
      toastActions.failure({ title: "An error happened" });
    },
  });

  const handleFileSelect = (file: File) => {
    if (!workspace.base_url) return;

    uploadModel.onFileSelect(file);

    toastActions.default({ title: "Model uploading started" });
  };

  if (selectedNode.data.model_id === "") {
    if (!immutable) {
      return (
        <ContentContainer error={uploadModel.error}>
          <ModelDropzone
            isUploading={uploadModel.isLoading}
            workspace={workspace}
            onFileSelect={handleFileSelect}
          />
        </ContentContainer>
      );
    } else {
      return (
        <div className="relative flex h-full grow flex-col items-center justify-center rounded-xl border border-gray-200 bg-gray-50">
          <div className="flex items-center justify-center rounded-full border-2 border-indigo-200 p-1.5">
            <Icon color="text-indigo-500" icon={faMicrochip} size="2xl" />
          </div>
          <p className="mt-5 select-none text-gray-800 font-inter-semibold-13px">
            No Model File Uploaded
          </p>
        </div>
      );
    }
  }

  const { inputs, outputs, custom_metadata, ...nodedata } = selectedNode.data;
  const metadata = {
    ...pick(nodedata, [
      "description",
      "graph_name",
      "producer_name",
      "version",
    ]),
    ...custom_metadata,
  };
  return (
    <ContentContainer error={uploadModel.error}>
      <ModelFile
        isUploading={uploadModel.isLoading}
        modelName={selectedNode.data.model_filename}
        workspace={workspace}
        onFileSelect={handleFileSelect}
      />
      <Root
        className={accordionRootClassName}
        defaultValue={
          renderedInDiffView
            ? ["metadata", "inputs", "outputs", "code"]
            : ["metadata"]
        }
        type="multiple"
      >
        <AccordionItem
          disabled={renderedInDiffView}
          title="Model metadata"
          value="metadata"
        >
          <DataContainer>
            {Object.entries(metadata).map(([name, value], index) => (
              <MetadataField
                key={name}
                dataLoc={`metadata-${index}`}
                name={name}
                value={value}
              />
            ))}
          </DataContainer>
        </AccordionItem>
        <AccordionItem
          disabled={renderedInDiffView}
          title="Model inputs"
          value="inputs"
        >
          <DataContainer>
            {inputs?.map((tensor, index) => (
              <Tensor
                key={tensor.name}
                dataLoc={`inputs-${index}`}
                {...tensor}
              />
            ))}
          </DataContainer>
        </AccordionItem>
        <AccordionItem
          disabled={renderedInDiffView}
          title="Model outputs"
          value="outputs"
        >
          <DataContainer>
            {outputs?.map((tensor, index) => (
              <Tensor
                key={tensor.name}
                dataLoc={`outputs-${index}`}
                {...tensor}
              />
            ))}
          </DataContainer>
        </AccordionItem>
        <AccordionItem disabled={renderedInDiffView} title="Code" value="code">
          <div className="h-128 pb-6">
            <CodeNodeEditor
              displayedError={displayedError}
              immutable={immutable}
              isReactive={isReactive}
              language="python"
              src={selectedNode.data?.src}
              onChange={(value) => updateNode({ src: value })}
            />
          </div>
        </AccordionItem>
      </Root>
      <OverwriteConfirmationModal
        isOpen={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        onConfirm={() => {
          updateNode({ src: uploadModel.data?.metadata.src });
          setIsOpenModal(false);
        }}
      />
    </ContentContainer>
  );
};
