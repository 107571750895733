import { RefObject } from "react";
import { createStore } from "zustand";
import { immer } from "zustand/middleware/immer";

import { GenericObjectT } from "src/api/flowTypes";
import {
  ComputePositionFn,
  ComputePositionOptions,
  curryComputePosition,
} from "src/base-components/FloatingWindow/positioning";

const MIN_ORDER = 100;

export type FloatingWindowType = "detailed-view" | "chart" | "copilot";
export type FloatingWindowProps = {
  id: string;
  computePosition: ComputePositionFn;
  data: GenericObjectT;
};

type FloatingWindowOptions = {
  id: string;
  target: RefObject<HTMLDivElement> | undefined;
  positionOptions?: ComputePositionOptions;
  type: FloatingWindowType;
};

type FloatingWindow<D = GenericObjectT> = {
  id: string;
  computePosition: ComputePositionFn;
  type: FloatingWindowType;
  isPinned: boolean;
  data: D;
  order: number;
};

type FloatingWindowsState = {
  windows: Record<string, FloatingWindow>;
};

type FloatingWindowsActions = {
  open: (window: FloatingWindowOptions, data?: GenericObjectT) => void;
  close: (id: string) => void;
  setPin: (id: string, isPinned: boolean) => void;
  moveUp: (id: string) => void;
};

type FloatingWindowsStore = ZustandStore<
  FloatingWindowsState,
  FloatingWindowsActions
>;

export const createFloatingWindowsStore = () => {
  return createStore(
    immer<FloatingWindowsStore>((set) => ({
      windows: {},
      actions: {
        open: ({ target, positionOptions = {}, ...window }, data = {}) => {
          return set((state) => {
            state.windows[window.id] = {
              ...window,
              isPinned: false,
              computePosition: curryComputePosition(target, positionOptions),
              data,
              order: getHighestOrder(state) + 1,
            };
          });
        },
        close: (id) =>
          set((state) => {
            delete state.windows[id];
          }),
        setPin: (id, isPinned) =>
          set((state) => {
            if (state.windows[id]) {
              state.windows[id].isPinned = isPinned;
            }
          }),
        moveUp: (id) =>
          set((state) => {
            if (state.windows[id]) {
              const highestOrder = getHighestOrder(state);
              if (state.windows[id].order < highestOrder) {
                state.windows[id].order = highestOrder + 1;
              }
            }
          }),
      },
    })),
  );
};

const getHighestOrder = (state: FloatingWindowsState) => {
  return Math.max(
    MIN_ORDER,
    ...Object.values(state.windows).map((window) => window.order),
  );
};
