import React from "react";
import { twMerge } from "tailwind-merge";

import { BetaPill } from "src/base-components/BetaPill";
import { Tooltip } from "src/design-system/Tooltip";

export type DisabledDetails =
  | { disabled?: false; disabledInfo?: never }
  | { disabled: true; disabledInfo: { title: string; body?: string } };

type PropsT = {
  title: string;
  description: string;
  onClick: () => void;
  dataLoc?: string;
  nodeIcon: React.ReactNode;
  isBeta?: boolean;
} & DisabledDetails;

export const AddNodeCard: React.FC<PropsT> = ({
  title,
  description,
  onClick,
  dataLoc,
  nodeIcon,
  disabled = false,
  disabledInfo,
  isBeta,
}) => {
  return (
    <Tooltip
      body={disabledInfo?.body}
      disabled={!disabled}
      placement="top"
      title={disabledInfo?.title}
      asChild
    >
      <div
        className={twMerge(
          "my-4 h-full w-full rounded-lg px-2 py-2 transition-all",
          !disabled &&
            "border border-gray-200 hover:cursor-pointer hover:border-indigo-400",
          disabled && "cursor-not-allowed bg-gray-50",
        )}
        data-loc={dataLoc}
        onClick={onClick}
      >
        <div className="flex justify-between">
          <div className="flex items-center">
            <div className="h-10 w-10">{nodeIcon}</div>
            <div className="ml-2.5 flex flex-col justify-center">
              <div
                className={twMerge(
                  "font-inter-semibold-13px",
                  !disabled && "text-gray-800",
                  disabled && "text-gray-500",
                )}
                data-loc={`${dataLoc}-name`}
              >
                {title}
              </div>
              <div
                className={twMerge(
                  "font-inter-normal-12px",
                  !disabled && "text-gray-500",
                  disabled && "text-gray-400",
                )}
              >
                {description}
              </div>
            </div>
          </div>
          {isBeta && (
            <div className="ml-auto self-start">
              <BetaPill tooltipPlacement="bottom" />
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  );
};
