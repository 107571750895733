import { Popover, PopoverButton, PopoverPanel } from "@headlessui2/react";
import { AnimatePresence, m } from "framer-motion";
import React from "react";
import { twMerge } from "tailwind-merge";

import { HeadlessUIPlacement } from "src/utils/headlessui";

type Placement =
  | "top"
  | "top-end"
  | "top-start"
  | "bottom"
  | "bottom-end"
  | "bottom-start"
  | "right"
  | "right-end"
  | "right-start"
  | "left-end"
  | "left-start";

const getPlacement = (placement: Placement): HeadlessUIPlacement => {
  return placement.replace("-", " ") as HeadlessUIPlacement;
};

type PropsT = {
  button: React.ReactNode | ((props: { open: boolean }) => React.ReactElement);
  children: React.ReactNode;
  className?: string;
  dataLoc?: string;
  // Can be deleted after using Floating Windows
  isOpen?: boolean;
  placement?: Placement;
  offsetX?: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export const CustomPopover: React.FC<PropsT> & {
  Button: typeof PopoverButton;
} = ({
  children,
  className,
  button,
  dataLoc,
  isOpen,
  placement = "bottom-end",
  offsetX = 10,
  onMouseEnter,
  onMouseLeave,
}) => {
  const controlled = isOpen !== undefined;

  return (
    <Popover
      className="h-full"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <PopoverButton as="span" data-loc={`${dataLoc}-button`} tabIndex={-1}>
        {button}
      </PopoverButton>
      <AnimatePresence>
        {((controlled && isOpen) || !controlled) && (
          <PopoverPanel
            anchor={{
              to: getPlacement(placement),
              padding: 8,
              gap: offsetX,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: { duration: 0.2 },
            }}
            as={m.div}
            className={twMerge(
              "decideScrollbar z-50 rounded-lg border border-gray-200 bg-white shadow-xl",
              className,
            )}
            data-loc={dataLoc}
            exit={{
              opacity: 0,
              scale: 0.95,
              transition: { duration: 0.1 },
            }}
            initial={{ opacity: 0, scale: 0.95 }}
            static={controlled}
          >
            {children}
          </PopoverPanel>
        )}
      </AnimatePresence>
    </Popover>
  );
};

CustomPopover.Button = PopoverButton;
