import { faCheck, faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { getUnixTime, parseISO } from "date-fns";
import { orderBy, take } from "lodash";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { FlowT, FlowVersionFlowChild } from "src/api/flowTypes";
import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { useAuthoringContext } from "src/router/routerContextHooks";
import {
  AuthorPageParamsT,
  getFlowVersionsUrl,
  getUrlToAuthoringPage,
} from "src/router/urls";
import {
  getActiveVersions,
  VersionStatusDotIndicator,
} from "src/utils/flowVersion";
import { useParamsDecode } from "src/utils/useParamsDecode";

const VersionDisplay: React.FC<{
  version: FlowVersionFlowChild;
}> = ({ version }) => (
  <div className="flex items-center">
    <VersionStatusDotIndicator size="4xs" version={version} />
    <div className="text-gray-800 font-inter-medium-13px">{version.name}</div>
  </div>
);

type VersionPickerProps = {
  flow: FlowT;
};

const takeFiveVersions = (
  versions: FlowVersionFlowChild[],
  currentVersion: FlowVersionFlowChild | undefined,
): FlowVersionFlowChild[] => {
  const ordered = orderBy(
    getActiveVersions(versions),
    (version) => getUnixTime(parseISO(version.updated_at)),
    "desc",
  );

  const topFive = take(ordered, 5);

  if (
    currentVersion &&
    !topFive.some((version) => version.id === currentVersion.id)
  ) {
    // If no current version in the array, return the top 4 and the current version
    return [...take(topFive, 4), currentVersion];
  }

  return topFive;
};

export const VersionPicker: React.FC<VersionPickerProps> = () => {
  const navigate = useNavigate();
  const { orgId, wsId, flow_id, version_id } =
    useParamsDecode<AuthorPageParamsT>();
  const { flow, version: currentVersion } = useAuthoringContext();

  const versions = takeFiveVersions(flow.versions, currentVersion);

  return (
    <FixedPositionedDropdown
      buttonClassName="rounded-full border-none"
      dataLoc="current-version-picker"
      elements={versions.map((version) => ({
        key: version.id,
        value: version,
      }))}
      placement="bottomLeft"
      renderButtonValue={(version) => (
        <Pill
          dataLoc="current-version-picker-button"
          size="lg"
          variant="outlined-white"
        >
          {version && <VersionStatusDotIndicator version={version} />}
          <Pill.Text>
            {version ? version.name : "No version selected"}
          </Pill.Text>
          <Pill.Icon icon={faChevronDown} size="base" />
        </Pill>
      )}
      renderFooter={() => {
        if (flow.versions.length < 6) return;

        return (
          <div className="flex min-w-[18em] items-center justify-between gap-x-1 px-4 py-3.5">
            <Link
              className="text-indigo-500"
              to={getFlowVersionsUrl(orgId, wsId, flow_id)}
            >
              See all versions
            </Link>
          </div>
        );
      }}
      renderValue={({ value: version, selected }) => (
        <div className="flex min-w-[18em] items-center justify-between gap-x-1 px-4 py-3.5">
          <VersionDisplay version={version} />
          {selected && (
            <Icon color="text-indigo-500" icon={faCheck} size="sm" />
          )}
        </div>
      )}
      selected={version_id}
      onSelect={(versionId) =>
        navigate(getUrlToAuthoringPage(orgId, wsId, flow_id, versionId))
      }
    />
  );
};
