import assert from "assert";
import { AxiosError } from "axios";
import { isEmpty, reduce } from "lodash";
import { validate as validateUUID } from "uuid";

import { GraphTransform } from "../utils/GraphUtils";
import { flowVersionDbToFlowVersionT } from "src/api";
import { versionsApi } from "src/api/endpoints";
import { FlowVersionStatusT, FlowVersionT } from "src/api/flowTypes";
import { EdgeUpsertT, GroupUpsertT, NodeUpsertT } from "src/api/flowTypes";
import {
  ArchiveFlowVersionT,
  PublishFlowVersionT,
  SchemaUpdateT,
  flowVersionKeys,
} from "src/api/flowVersionQueries";
import {
  EdgeDb,
  FlowVersionDb,
  FlowVersionMeta,
  NodeDb,
} from "src/clients/flow-api";
import { FlowVersionUpdate, ResourceLockUpsert } from "src/clients/flow-api";
import { GraphMergePatch } from "src/clients/flow-api";
import { GroupNode } from "src/constants/NodeDataTypes";
import { CURRENT_SESSION_ID } from "src/constants/Session";
import { queryClient } from "src/queryClient";
import { logger } from "src/utils/logger";
import { sequentialExecutionWrapper } from "src/utils/sequentialExecution";
import { wrapWithErrorHandler } from "src/utils/toastError";

const RETRYABLE_NETWORK_ERRORS: string[] = [
  AxiosError.ECONNABORTED,
  AxiosError.ERR_NETWORK,
  AxiosError.ETIMEDOUT,
];

export const DEFAULT_VERSION = "00000000";

export const getLastSeenVersion = (flowVersionId: string): string => {
  const previousResponse: FlowVersionT | undefined = queryClient.getQueryData(
    flowVersionKeys.detail(flowVersionId),
  );

  if (
    previousResponse &&
    previousResponse.etag &&
    !Number.isNaN(parseInt(previousResponse.etag))
  ) {
    return previousResponse.etag;
  } else {
    return DEFAULT_VERSION;
  }
};

const assertUpdatedMetaIdsAreValid = (updateMeta: FlowVersionMeta) => {
  if (updateMeta.archived_by_id !== undefined) {
    assert(
      validateUUID(updateMeta.archived_by_id),
      "archived_by_id must be a valid UUID",
    );
  }
  if (updateMeta.published_by_id !== undefined) {
    assert(
      validateUUID(updateMeta.published_by_id),
      "published_by_id must be a valid UUID",
    );
  }
  if (updateMeta.created_by_id !== undefined) {
    assert(
      validateUUID(updateMeta.created_by_id),
      "created_by_id must be a valid UUID",
    );
  }
  if (updateMeta.updated_by_id !== undefined) {
    assert(
      validateUUID(updateMeta.updated_by_id),
      "updated_by_id must be a valid UUID",
    );
  }
};

// Important: Only change this object in a backwards compatible way, i.e. only add new entries with a new number and leave existing ones untouched
export enum Operation {
  /**
   * Please do not write this, this is only returned from flow-api if there is no Operation on a change
   */
  UNKNOWN = 0,
  CREATE_NODE = 1,
  EDIT_NODE = 2,
  DELETE_NODE = 3,
  DELETE_NODES = 4,
  CREATE_BRANCH = 5,
  DELETE_BRANCH = 6,
  CREATE_GROUP = 7,
  RENAME_GROUP = 8,
  UNGROUP_GROUP = 9,
  CUT_GROUP = 20,
  DELETE_GROUP_WITH_NODES = 21,
  CREATE_SPLIT_NODE = 10,
  DELETE_SPLIT_NODE = 11,
  PASTE_NODE = 12,
  PASTE_NODES = 13,
  CUT_NODE = 14,
  CUT_NODES = 15,
  EDIT_PARAMETERS = 16,
  EDIT_SCHEMA = 17,
  PUBLISH = 18,
  VERSION_METADATA_EDITED = 19,
  VERSION_DUPLICATED = 22,
  VERSION_CREATED = 23,
  VERSION_RESTORED = 24,
  RENAME_NODE = 25,
  RENAME_BRANCH = 26,
  ARCHIVE = 27,
  VERSION_IMPORTED = 28, // Please do not write this, this should only be written from the copy-flows script
  CREATE_COMMENT = 29,
}

/**
 * This method has to be used for all updates of flowVersions. Throws an outdated view error on 412 responses.
 */
export const updateFlowVersion = wrapWithErrorHandler(
  sequentialExecutionWrapper(
    async (
      flowVersionId: string,
      update: FlowVersionUpdate & {
        operation: Operation; // operation is optional on the backend, but we always want to send it when modifying something from the frontend
      },
      enableTrafficPolicies = false,
    ): Promise<FlowVersionT> => {
      if (update.meta !== undefined) {
        assertUpdatedMetaIdsAreValid(update.meta);
      }

      let response = undefined;
      let retries = 0;
      while (response === undefined) {
        try {
          response =
            await versionsApi.updateFlowVersionApiV1FlowVersionsIdPatch(
              flowVersionId,
              update,
              enableTrafficPolicies,
              CURRENT_SESSION_ID,
              getLastSeenVersion(flowVersionId),
            );
        } catch (err) {
          const error: AxiosError = err as AxiosError;
          if (
            error.isAxiosError &&
            RETRYABLE_NETWORK_ERRORS.includes(error.code ?? "")
          ) {
            // retry network errors now without losing this request's place in the queue
            logger.warn("Retrying failed network request");
            const delay = 1000 * Math.pow(1.4, retries++);
            await new Promise((resolve) => setTimeout(resolve, delay));
          } else if (error.isAxiosError && error.response?.status === 412) {
            // 412 indicates the operation was issued against a stale view of the server state
            const latestVersion = flowVersionDbToFlowVersionT(
              error.response.data as FlowVersionDb,
            );
            notifyUI(latestVersion);
            logger.warn("Retrying operation after rebase");
          } else {
            // For the unexpected throw
            throw err;
          }
        }
      }

      // Inject the response into our main hook cache
      const flowVersion: FlowVersionT = flowVersionDbToFlowVersionT(
        response.data,
      );
      notifyUI(flowVersion);
      return flowVersion;
    },
  ),
);

export const publishFlowVersion = async (
  publishFlowVersion: PublishFlowVersionT,
  enableTrafficPolicies: boolean,
) =>
  updateFlowVersion(
    publishFlowVersion.version.id,
    {
      name: publishFlowVersion.name,
      status: FlowVersionStatusT.PUBLISHED,
      meta: {
        ...publishFlowVersion.version.meta,
        release_note: publishFlowVersion.release_note,
        published_at: publishFlowVersion.published_at,
        published_by_id: publishFlowVersion.published_by_id,
      },
      operation: Operation.PUBLISH,
    },
    enableTrafficPolicies,
  );

export const archiveFlowVersion = async (
  archiveFlowVersion: ArchiveFlowVersionT,
) =>
  updateFlowVersion(archiveFlowVersion.version.id, {
    status: FlowVersionStatusT.ARCHIVED,
    meta: {
      ...archiveFlowVersion.version.meta,
      archived_at: archiveFlowVersion.archived_at,
      archived_by_id: archiveFlowVersion.archived_by_id,
    },
    operation: Operation.ARCHIVE,
  });

export const updateSchema = async (
  flowVersionId: string,
  schemaUpdate: SchemaUpdateT,
) =>
  updateFlowVersion(flowVersionId, {
    // When a schema is empty we want to send it as null, since empty schemas and undefined/null schemas are handled differently in the runner
    [schemaUpdate.type === "input" ? "input_schema" : "output_schema"]: isEmpty(
      schemaUpdate.schema.properties,
    )
      ? null
      : schemaUpdate.schema,
    operation: Operation.EDIT_SCHEMA,
  });

export const notifyUI = (version: FlowVersionT): void => {
  // Update cache only if the incoming version is newer than what we have cached
  // We do this to enforce monotonic updates only. When the server is under load
  // some requests may be delayed, which can an old poll might be received
  // after a newer one. We use etag to check our cache has the latest known state
  // only
  if (
    version.etag === undefined ||
    version.etag > getLastSeenVersion(version.id)
  ) {
    queryClient.setQueryData(flowVersionKeys.detail(version.id), version);
  }
};

//For graph store
export type FlowVersionGraphUpdate = {
  flowVersionId: string;
  nodes: NodeUpsertT;
  edges: EdgeUpsertT;
  groups: GroupUpsertT;
  operation: Operation;
};

export const updateFlowVersionGraph = async ({
  operation,
  ...update
}: FlowVersionGraphUpdate): Promise<FlowVersionT> => {
  const patch: GraphMergePatch = {};
  if (!isEmpty(update.nodes)) {
    patch.nodes = reduce(
      update.nodes,
      (acc, node, nodeId) => {
        return {
          ...acc,
          [nodeId]: (node
            ? GraphTransform.nodeReverseMapper(node)
            : null) as NodeDb,
        };
      },
      {} as Record<string, NodeDb>,
    );
  }

  if (!isEmpty(update.edges)) {
    patch.edges = reduce(
      update.edges,
      (acc, edge, edgeId) => {
        return {
          ...acc,
          [edgeId]: (edge
            ? GraphTransform.edgesReverseMapper(edge)
            : null) as EdgeDb,
        };
      },
      {} as Record<string, EdgeDb>,
    );
  }

  if (!isEmpty(update.groups)) {
    patch.node_groups = reduce(
      update.groups,
      (acc, group, groupId) => {
        return {
          ...acc,
          [groupId]: group
            ? GraphTransform.groupReverseMapper(group as GroupNode)
            : null,
        };
      },
      {},
    );
  }
  return await updateFlowVersion(update.flowVersionId, {
    graph: patch,
    operation,
  });
};

export type UpsertResourceLockArgs = {
  flowVersionId: string;
  resourceLockPatch: {
    [id: string]: ResourceLockUpsert | null;
  };
};

export const upsertResourceLock = async ({
  flowVersionId,
  resourceLockPatch,
}: UpsertResourceLockArgs) =>
  updateFlowVersion(flowVersionId, {
    //@ts-ignore Typing wrong, we can set null! Fixed with new pydantic version
    locks: resourceLockPatch,
  });
