import { isObject } from "lodash";
import { useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";

import { CellContent } from "./types";
import { GenericObjectT } from "src/api/flowTypes";
import { Pill } from "src/base-components/Pill";
import { ObjectDetailPane } from "src/dataTable/ObjectDetailPane";
import { FloatingWindow } from "src/datasets/DatasetTable/FloatingWindow";
import { copyTextToClipboard } from "src/utils/clipboard";
import { stringifiedObjectSpeakPython } from "src/utils/speakPython";
import { SerializedJSON } from "src/utils/stringUtils";

export type FloatingWindowPlacement = "top-start" | "top-end" | "auto";

type PropsT<T> = {
  cell: CellContent<T>;
  placement?: FloatingWindowPlacement;
  className: string;
  columnTitle?: string;
  suffix?: React.ReactNode;
  value: SerializedJSON | object;
};

export const JSONCell = <T extends GenericObjectT>({
  cell,
  placement = "auto",
  className,
  columnTitle,
  suffix,
  value,
}: PropsT<T>) => {
  const [hovering, setHovering] = useState(false);

  const stringValue = useMemo(() => {
    return isObject(value) ? JSON.stringify(value) : value;
  }, [value]);

  const title = (
    <div className="flex flex-row gap-x-1">
      <Pill size="sm" variant="gray">
        <Pill.Text>
          #
          {"index" in cell.row.original
            ? cell.row.original.index + 1
            : cell.row.index + 1}
        </Pill.Text>
      </Pill>
      <Pill size="sm" variant="gray">
        <Pill.Text>{columnTitle ?? cell.column.id}</Pill.Text>
      </Pill>
    </div>
  );

  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <FloatingWindow
        button={
          <div
            className={twMerge(
              "flex cursor-default flex-row items-center justify-between gap-x-0.5 pl-2",
              hovering && "bg-indigo-100",
              className,
            )}
          >
            <div className="flex-1 truncate">
              {stringifiedObjectSpeakPython(stringValue)}
            </div>
            {suffix}
          </div>
        }
        draggable={false}
        isOpen={hovering}
        maximizable={false}
        offset={[0, 0]}
        pinnable={false}
        placement={placement}
        title={title}
        onClose={() => setHovering(false)}
        onCopy={() => copyTextToClipboard(stringValue)}
      >
        <ObjectDetailPane
          innerDimensionClass="h-full"
          jsonObject={JSON.parse(stringValue) as GenericObjectT}
        />
      </FloatingWindow>
    </div>
  );
};
