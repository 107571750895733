import {
  ConnectionResourceTemplate,
  makeConnectionTemplate,
} from "src/connections/types";

const configKeys: ConnectionResourceTemplate["configKeys"] = [
  {
    key: "config_key",
    name: "Config key",
    type: "text",
    required: false,
  },
];

export const CrsTemplate = makeConnectionTemplate({
  provider: "crs",
  secrets: [
    { key: "username", name: "Username", required: true },
    { key: "password", name: "Password", required: true },
  ],
  resources: {
    crs_equifax_credit_report: {
      name: "Equifax Credit Report",
      configKeys: [
        {
          key: "config_key",
          name: "Config key",
          type: "text",
          required: false,
          hint: "To submit a soft pull, enter either 'efx-prequal-vantage4' or 'efx-prequal-fico9', depending on your desired score",
        },
      ],
    },
    experian_business_profile_report: {
      name: "Experian Business Profile Report",
      configKeys,
    },
    experian_business_search: {
      name: "Experian Business Search",
      configKeys: [],
    },
    experian_credit_report: {
      name: "Experian Credit Report",
      configKeys,
    },
    experian_intelliscore_business_report: {
      name: "Experian Intelliscore Business Report",
      configKeys: [],
    },
    lexisnexis_bridger_search: {
      name: "LexisNexis Bridger Search",
      configKeys,
    },
    transunion_credit_report: {
      name: "Transunion Credit Report",
      configKeys,
    },
    transunion_imv_basic: {
      name: "TruValidate IMV Basic",
      configKeys,
    },
  },
});
