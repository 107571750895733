import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCode,
  faMicrochip,
  faShuffle,
  faSplit,
  faTable,
  faEquals,
  faCalculator,
  faDiagramNext,
  faCircleUser,
} from "@fortawesome/pro-regular-svg-icons";
import { faRotateRight } from "@fortawesome/pro-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { Edge } from "reactflow";
import { twJoin, twMerge } from "tailwind-merge";

import { ResourceIcon } from "../connections/ResourceIcon";
import { ProviderT, ResourceT } from "src/api/connectApi/types";
import { Icon } from "src/base-components/Icon";
import { ProviderIcon } from "src/connections/config/Icon";
import {
  CustomConnectionNodeDataT,
  IntegrationNodeDataT,
  BeMappedNode,
  GroupNode,
  InboundWebhookConnectionNodeDataT,
  DatabaseConnectionNodeDataT,
  ManifestConnectionNodeDataT,
} from "src/constants/NodeDataTypes";
import {
  SimpleCreatableNodeTypeT,
  SIMPLE_CREATABLE_NODE_TYPES,
  NODE_TYPE,
  ConnectNodeTypeT,
} from "src/constants/NodeTypes";
import { getCreatableNodes } from "src/nodeAdding/PasteNodesCard";
import { useGraphStore } from "src/store/StoreProvider";
import {
  getEdgesInSubgraph,
  orderNodesAndEdgesForLayout,
} from "src/utils/GraphUtils";
import { assertUnreachable } from "src/utils/typeUtils";

type NodeIconSize = "lg" | "md" | "sm";

type BasicNodeIconPropsT = {
  icon: IconProp;
  backgroundClassName: string;
  disabled?: boolean;
  size?: NodeIconSize;
  colorClassName?: string;
};

export const BasicNodeIcon: React.FC<BasicNodeIconPropsT> = ({
  icon,
  backgroundClassName,
  disabled = false,
  colorClassName = "text-gray-800",
  size = "lg",
}) => (
  <div
    className={twJoin(
      "flex h-full w-full flex-col items-center justify-center",
      size === "lg" && "rounded-lg",
      size === "md" && "rounded-md",
      size === "sm" && "rounded",
      backgroundClassName,
    )}
  >
    <Icon
      color={disabled ? "text-gray-500" : colorClassName}
      icon={icon}
      size={size === "sm" ? "2xs" : "xl"}
    />
  </div>
);

const getCreatableChildrenOrdered = (
  childrenNodeIDs: string[],
  allNodes: BeMappedNode[],
  allEdges: Edge[],
) => {
  const childrenNodes = childrenNodeIDs
    .map((id) => allNodes.find((n) => n.id === id))
    .filter((n) => n !== undefined) as BeMappedNode[];
  const edgesInChildren = getEdgesInSubgraph(allEdges, childrenNodes);
  const [nodes, _] = orderNodesAndEdgesForLayout(
    childrenNodes,
    edgesInChildren,
  );

  return getCreatableNodes(nodes);
};

type GroupIconProps = { size?: "lg" | "sm" } & (
  | { group: GroupNode | undefined }
  | { groupId: string }
);

export const GroupIcon = observer(
  ({ size = "lg", ...params }: GroupIconProps) => {
    const { nodesArray, groups, edgesArray } = useGraphStore();
    const groupNode =
      "group" in params ? params.group : groups.get(params.groupId);
    if (!groupNode) {
      return <></>;
    }

    const groupChildren = getCreatableChildrenOrdered(
      groupNode.data.childrenIDs,
      nodesArray,
      edgesArray,
    );

    const getChildrenClassName = (index: number): string => {
      const nodeType = groupChildren[index].type;
      const nodeBackground = nodeType
        ? getNodeTypeBackground(nodeType as NODE_TYPE, false, true)
        : "";
      return twJoin("grow", nodeBackground);
    };

    return (
      <div
        className={twJoin(
          "flex flex-row gap-0.5 overflow-hidden",
          size === "lg" && "h-10 w-10 rounded-lg",
          size === "sm" && "h-4.5 w-4.5 rounded-sm",
        )}
      >
        <div className="flex grow flex-col gap-0.5">
          {groupChildren.length >= 1 && (
            <div className={getChildrenClassName(0)} />
          )}
          {groupChildren.length >= 4 && (
            <div className={getChildrenClassName(3)} />
          )}
        </div>
        {groupChildren.length >= 2 && (
          <div className="flex grow flex-col gap-0.5">
            <div className={getChildrenClassName(1)} />
            {groupChildren.length >= 3 && (
              <div className={getChildrenClassName(2)} />
            )}
          </div>
        )}
      </div>
    );
  },
);

type NodeIconPropsT = { size?: NodeIconSize } & (
  | { nodeType: SimpleCreatableNodeTypeT; disabled?: boolean }
  | {
      nodeType: ConnectNodeTypeT;
      provider: ProviderT | string;
      resource?: ResourceT | string;
    }
  | { nodeType: NODE_TYPE.GROUP_NODE; groupId: string }
  | { nodeType: NODE_TYPE.REVIEW_CONNECTION_NODE }
  | {
      nodeType: NODE_TYPE.MANIFEST_CONNECTION_NODE;
      provider: string;
    }
  | {
      nodeType: ConnectNodeTypeT;
      provider: ProviderT | string;
      mediaKey: string | null;
    }
);

export const NodeIcon: React.FC<NodeIconPropsT> = (params) => {
  const backgroundColor = getNodeTypeBackground(params.nodeType);
  switch (params.nodeType) {
    case NODE_TYPE.RULE_NODE_V2: {
      return (
        <BasicNodeIcon
          backgroundClassName={backgroundColor}
          disabled={params.disabled ?? false}
          icon={faShuffle}
          size={params.size}
        />
      );
    }
    case NODE_TYPE.SPLIT_NODE_V2: {
      return (
        <BasicNodeIcon
          backgroundClassName={backgroundColor}
          disabled={params.disabled ?? false}
          icon={faSplit}
          size={params.size}
        />
      );
    }
    case NODE_TYPE.CODE_NODE: {
      return (
        <BasicNodeIcon
          backgroundClassName={backgroundColor}
          disabled={params.disabled ?? false}
          icon={faCode}
          size={params.size}
        />
      );
    }
    case NODE_TYPE.ML_NODE: {
      return (
        <BasicNodeIcon
          backgroundClassName={backgroundColor}
          disabled={params.disabled ?? false}
          icon={faMicrochip}
          size={params.size}
        />
      );
    }
    case NODE_TYPE.MANIFEST_CONNECTION_NODE: {
      // Mapping the UI icon sizes to the provider icon sizes.
      const iconSize =
        params.size === "sm" ? "md" : params.size === "lg" ? "xxl" : "xl";
      return <ProviderIcon provider={params.provider} size={iconSize} />;
    }
    case NODE_TYPE.CUSTOM_CONNECTION_NODE:
    case NODE_TYPE.WEBHOOK_CONNECTION_NODE:
    case NODE_TYPE.SQL_DATABASE_CONNECTION_NODE:
    case NODE_TYPE.INTEGRATION_NODE: {
      // Mapping the UI icon sizes to the provider icon sizes.
      const iconSize =
        params.size === "sm" ? "md" : params.size === "lg" ? "xxl" : "xl";
      // TODO: handling of cases is duck typed, should be refactored.
      if ("mediaKey" in params) {
        return (
          <ProviderIcon
            mediaKey={params.mediaKey}
            provider={params.provider}
            size={iconSize}
          />
        );
      }
      if (!params.resource) {
        return <ProviderIcon provider={params.provider} size={iconSize} />;
      } else {
        return (
          <ResourceIcon
            provider={params.provider}
            resource={params.resource}
            size={iconSize}
          />
        );
      }
    }
    case NODE_TYPE.DECISION_TABLE_NODE: {
      return (
        <BasicNodeIcon
          backgroundClassName={backgroundColor}
          disabled={params.disabled ?? false}
          icon={faTable}
          size={params.size}
        />
      );
    }
    case NODE_TYPE.ASSIGNMENT_NODE: {
      return (
        <BasicNodeIcon
          backgroundClassName={backgroundColor}
          disabled={params.disabled ?? false}
          icon={faEquals}
          size={params.size}
        />
      );
    }
    case NODE_TYPE.SCORECARD_NODE: {
      return (
        <BasicNodeIcon
          backgroundClassName={backgroundColor}
          disabled={params.disabled ?? false}
          icon={faCalculator}
          size={params.size}
        />
      );
    }
    case NODE_TYPE.GROUP_NODE: {
      return <GroupIcon groupId={params.groupId} />;
    }
    case NODE_TYPE.FLOW_NODE: {
      return (
        <BasicNodeIcon
          backgroundClassName={backgroundColor}
          disabled={params.disabled ?? false}
          icon={faDiagramNext}
          size={params.size}
        />
      );
    }
    case NODE_TYPE.REVIEW_CONNECTION_NODE: {
      return (
        <BasicNodeIcon
          backgroundClassName={backgroundColor}
          icon={faCircleUser}
          size={params.size}
        />
      );
    }
    case NODE_TYPE.LOOP_NODE: {
      return (
        <BasicNodeIcon
          backgroundClassName={backgroundColor}
          disabled={params.disabled ?? false}
          icon={faRotateRight}
          size={params.size}
        />
      );
    }
    default:
      assertUnreachable(params);
  }
  return <></>;
};

const getNodeTypeBackground = (
  nodeType: NODE_TYPE,
  disabled: boolean = false,
  forGroupIcon: boolean = false,
): string => {
  switch (nodeType) {
    case NODE_TYPE.RULE_NODE_V2: {
      return "bg-green-electric";
    }
    case NODE_TYPE.SPLIT_NODE_V2: {
      return "bg-indigo-200";
    }
    case NODE_TYPE.CODE_NODE: {
      return "bg-yellow-200";
    }
    case NODE_TYPE.ML_NODE: {
      return "bg-sky-200";
    }
    case NODE_TYPE.DECISION_TABLE_NODE: {
      return "bg-orange-200";
    }
    case NODE_TYPE.ASSIGNMENT_NODE: {
      return "bg-pink-200";
    }
    case NODE_TYPE.SCORECARD_NODE: {
      return "bg-green-200";
    }
    case NODE_TYPE.LOOP_NODE:
    case NODE_TYPE.FLOW_NODE: {
      return forGroupIcon
        ? "bg-gray-200"
        : twMerge(
            "border bg-white",
            !disabled && "border-gray-200",
            disabled && "border-gray-100",
          );
    }
    case NODE_TYPE.REVIEW_CONNECTION_NODE: {
      return "bg-indigo-100";
    }
    case NODE_TYPE.INPUT_NODE:
    case NODE_TYPE.OUTPUT_NODE:
    case NODE_TYPE.CUSTOM_CONNECTION_NODE:
    case NODE_TYPE.WEBHOOK_CONNECTION_NODE:
    case NODE_TYPE.INTEGRATION_NODE:
    case NODE_TYPE.MANIFEST_CONNECTION_NODE:
    case NODE_TYPE.SQL_DATABASE_CONNECTION_NODE:
    case NODE_TYPE.SPLIT_BRANCH_NODE_V2:
    case NODE_TYPE.SPLIT_MERGE_NODE:
    case NODE_TYPE.GROUP_NODE:
    case NODE_TYPE.GROUP_PRE_SEPARATOR_NODE:
    case NODE_TYPE.GROUP_POST_SEPARATOR_NODE:
      return "bg-gray-200";
    default:
      assertUnreachable(nodeType);
  }
  return "";
};

export const getNodeIconFromNode = (
  node: BeMappedNode,
  size?: NodeIconSize,
) => {
  if (node.type === undefined) return;
  if (node.type === NODE_TYPE.INTEGRATION_NODE) {
    const nodeData = node.data as IntegrationNodeDataT;
    return (
      <NodeIcon
        nodeType={node.type}
        provider={nodeData.providerResource.provider}
        resource={nodeData.providerResource.resource}
        size={size}
      />
    );
  }
  if (node.type === NODE_TYPE.MANIFEST_CONNECTION_NODE) {
    const nodeData = node.data as ManifestConnectionNodeDataT;
    return (
      <NodeIcon
        nodeType={node.type}
        provider={nodeData.providerResource.provider}
        size={size}
      />
    );
  }

  if (node.type === NODE_TYPE.WEBHOOK_CONNECTION_NODE) {
    const nodeData = node.data as InboundWebhookConnectionNodeDataT;
    return (
      <NodeIcon
        mediaKey={nodeData?.mediaKey}
        nodeType={node.type}
        provider={nodeData.providerResource.provider}
        resource={nodeData.providerResource.resource}
        size={size}
      />
    );
  }
  if (node.type === NODE_TYPE.SQL_DATABASE_CONNECTION_NODE) {
    const nodeData = node.data as DatabaseConnectionNodeDataT;
    return (
      <NodeIcon
        nodeType={node.type}
        provider={nodeData.providerResource.provider}
        resource={nodeData.providerResource.resource}
        size={size}
      />
    );
  }
  if (node.type === NODE_TYPE.CUSTOM_CONNECTION_NODE) {
    const nodeData = node.data as CustomConnectionNodeDataT;
    return (
      <NodeIcon
        mediaKey={nodeData?.mediaKey}
        nodeType={node.type}
        provider={nodeData.providerResource.provider}
        resource={nodeData.providerResource.resource}
        size={size}
      />
    );
  }
  if (
    (SIMPLE_CREATABLE_NODE_TYPES as readonly string[]).includes(node.type) ||
    node.type === NODE_TYPE.REVIEW_CONNECTION_NODE
  ) {
    return (
      <NodeIcon nodeType={node.type as SimpleCreatableNodeTypeT} size={size} />
    );
  }
  return <></>;
};
