import {
  OrganizationUser,
  WorkspaceSimpleRoleType,
} from "src/clients/taktile-api";

export const getUserName = (
  user: Pick<OrganizationUser, "full_name" | "username" | "email">,
) => user.full_name ?? user.username ?? user.email;

export const canUserBeAssignee = (
  workspaceId: string,
  user?: OrganizationUser,
) => {
  const wsRole = user?.workspaces_roles?.find(
    (workspaceRole) => workspaceRole.workspace_id === workspaceId,
  )?.role;

  return (
    wsRole &&
    [
      WorkspaceSimpleRoleType.ADMIN,
      WorkspaceSimpleRoleType.EDITOR,
      WorkspaceSimpleRoleType.REVIEWER,
    ].includes(wsRole)
  );
};
