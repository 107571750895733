import { faEdit, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { m } from "framer-motion";
import React from "react";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/design-system/Tooltip";
import { useCapabilities } from "src/hooks/useCapabilities";
import { JobDestination } from "src/jobs/types";
import { stopPropagationWrapper } from "src/utils/functions";

type Props = {
  onDelete?: () => void;
  onEdit?: () => void;
  destination: JobDestination;
};

export const DestinationActions: React.FC<Props> = ({
  destination,
  onDelete,
  onEdit,
}) => {
  const { jobs } = useCapabilities();

  return (
    <m.div
      className="flex gap-x-2"
      exit={{ opacity: 0, transition: { duration: 0.08 } }}
      transition={{ type: "tween", ease: "easeOut" }}
      layout
    >
      {onEdit && (
        <Tooltip placement="top" title="Edit destination" asChild>
          <Icon
            color="text-gray-500 hover:text-gray-700"
            dataLoc={`edit-${destination.name}`}
            icon={faEdit}
            size="xs"
            onClick={stopPropagationWrapper(onEdit)}
          />
        </Tooltip>
      )}
      {onDelete && jobs.canDeleteDestination && (
        <Tooltip placement="top" title="Delete destination" asChild>
          <Icon
            color="text-gray-500 hover:text-gray-700"
            dataLoc={`delete-${destination.name}`}
            icon={faTrashCan}
            size="xs"
            onClick={stopPropagationWrapper(onDelete)}
          />
        </Tooltip>
      )}
    </m.div>
  );
};
