import React from "react";

import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { BeMappedNode } from "src/constants/NodeDataTypes";

export type ConfirmationModalPropsT = {
  open: boolean;
  onClose: () => void;
  onConfirmDelete: () => void;
  branchToDeleteName: string;
  nodesToDelete: BeMappedNode[];
};

export const SplitBranchDeletionModal: React.FC<ConfirmationModalPropsT> = ({
  open,
  onClose,
  branchToDeleteName,
  nodesToDelete,
  onConfirmDelete,
}) => {
  const renderDeleteMessage = () => (
    <div className="text-gray-500 font-inter-normal-12px">
      Are you sure you want to delete this branch? This cannot be undone.{" "}
      {nodesDownstream.length !== 0 &&
        "The following nodes down stream will also be deleted:"}
    </div>
  );

  const renderNodeToDeleteMessage = () => (
    <div className="my-2">
      {nodesDownstream.map((node: BeMappedNode) => (
        <div
          key={node.id}
          className="ml-2 text-gray-500 font-inter-medium-12px"
        >
          • {node.data?.label}
        </div>
      ))}
    </div>
  );

  const nodesDownstream = nodesToDelete.filter(
    (node: BeMappedNode) => node.data.label !== branchToDeleteName,
  );

  return (
    <ConfirmationModal
      open={open}
      title="Delete branch?"
      onClose={onClose}
      onConfirm={onConfirmDelete}
    >
      {nodesToDelete.filter(
        (node: BeMappedNode) => node.data.label !== branchToDeleteName,
      ).length !== 0 && (
        <>
          {renderDeleteMessage()}
          {renderNodeToDeleteMessage()}
        </>
      )}
    </ConfirmationModal>
  );
};
