import { ProviderT } from "src/api/connectApi/types";
import { BKR_CONFIG } from "src/connections/connectionConfigs/bkr";
import { CREDITREFORM_CONFIG } from "src/connections/connectionConfigs/creditreform";
import { CRS_CONFIG } from "src/connections/connectionConfigs/crs";
import { EQUIFAX_CANADA_CONFIG } from "src/connections/connectionConfigs/equifaxCanada";
import { EXPERIAN_COLOMBIA_CONFIG } from "src/connections/connectionConfigs/experianColombia";
import { EXPERIAN_GERMANY_CONFIG } from "src/connections/connectionConfigs/experianGermany";
import { PLAID_CONFIG } from "src/connections/connectionConfigs/plaid";
import { SARDINE_CONFIG } from "src/connections/connectionConfigs/sardine";
import { SENTILINK_CONFIG } from "src/connections/connectionConfigs/sentiLink";
import { SOCURE_CONFIG } from "src/connections/connectionConfigs/socure";
import { THOMSON_REUTERS_CONFIG } from "src/connections/connectionConfigs/thomsonReuters";
import { TRANSUNION_CONFIG } from "src/connections/connectionConfigs/transUnion";

export const CONNECTIONS_MAP = {
  bkr: BKR_CONFIG,
  creditreform: CREDITREFORM_CONFIG,
  crs: CRS_CONFIG,
  experian_colombia: EXPERIAN_COLOMBIA_CONFIG,
  experian_germany: EXPERIAN_GERMANY_CONFIG,
  plaid: PLAID_CONFIG,
  sardine: SARDINE_CONFIG,
  sentilink: SENTILINK_CONFIG,
  socure: SOCURE_CONFIG,
  thomson_reuters: THOMSON_REUTERS_CONFIG,
  trans_union: TRANSUNION_CONFIG,
  equifax_canada: EQUIFAX_CANADA_CONFIG,
} as const;

export type ConnectionMap = typeof CONNECTIONS_MAP;

export const providerInConfigMap = (
  provider: ProviderT | string,
): provider is keyof ConnectionMap => provider in CONNECTIONS_MAP;
