import {
  useMotionValue,
  animate as motionAnimate,
  useTransform,
  m,
} from "framer-motion";
import { useEffect } from "react";

import { normalizeAnimationSpeed } from "src/copilot/utils";

type TextContentProps = {
  content: string;
  delay: number;
  duration?: number;
  animate?: boolean;
};

export const CopilotTextContent: React.FC<TextContentProps> = ({
  content,
  delay = 0,
  duration = normalizeAnimationSpeed(content.length),
  animate = false,
}) => {
  const count = useMotionValue(0);
  useEffect(() => {
    if (animate) {
      const controls = motionAnimate(count, content.length, {
        duration,
        delay,
        ease: "linear",
      });
      return controls.stop;
    }
  }, [content.length, count, delay, duration, animate]);

  const rounded = useTransform(count, (latest) => Math.round(latest));
  const displayText = useTransform(rounded, (latest) =>
    content.slice(0, latest),
  );

  return (
    <p className="inline whitespace-pre-line text-gray-800 font-inter-normal-13px">
      <m.span>{animate ? displayText : content}</m.span>
    </p>
  );
};
