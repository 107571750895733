import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  InputMappingGroupT,
  InputMappingT,
  IntegrationResourceT,
} from "src/integrationNode/types";

const getDefaultExperianUSPhone = (): InputMappingGroupT => ({
  getDefaultElements: () => ({
    number: {
      id: uuidV4(),
      type: "text",
      displayName: "Phone number",
      assignedTo: "",
      rules: {
        required: true,
      },
      hint: "7-10 digit phone number",
    },
    type: {
      id: uuidV4(),
      type: "text",
      displayName: "Phone type",
      assignedTo: "",
      rules: {
        required: false,
      },
      hint: "One of: 'R' for Residential, 'F' for Fax, 'B' for Business, 'C' for Cellular, 'I' for Institution, 'T' for Pay Phone",
    },
  }),
  displayName: "Phone",
});

const getDefaultExperianUSSecondaryId = (): InputMappingGroupT => ({
  getDefaultElements: () => ({
    id_type: {
      id: uuidV4(),
      type: "text",
      displayName: "ID type",
      assignedTo: "",
      rules: {
        required: true,
      },
      hint: "One of: 'militaryId', 'passport', 'residentAlienId', 'stateId', 'studentId', 'consularId', 'cedulaId', 'otherForeignPrimaryId' (case sensitive)",
    },
    id_value: {
      id: uuidV4(),
      type: "text",
      displayName: "ID number",
      assignedTo: "",
      rules: {
        required: true,
      },
    },
    region: {
      id: uuidV4(),
      type: "text",
      displayName: "Region/State",
      assignedTo: "",
      rules: {
        required: false,
      },
      hint: "Region/state of secondary ID",
    },
    country: {
      id: uuidV4(),
      type: "text",
      displayName: "Country",
      assignedTo: "",
      rules: {
        required: false,
      },
      hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g., "US" for USA)`,
    },
    expiration: {
      id: uuidV4(),
      type: "text",
      displayName: "Expiration date",
      assignedTo: "",
      rules: {
        required: false,
      },
      hint: "Expiration date of secondary ID. Must adhere to ISO format 'YYYY-MM-DD'",
    },
  }),
  displayName: "Secondary ID",
});

const getDefaultExperianUSDriversLicense = (): InputMappingGroupT => ({
  getDefaultElements: () => ({
    number: {
      id: uuidV4(),
      type: "text",
      displayName: "License number",
      assignedTo: "",
      rules: {
        required: true,
      },
      hint: "2-21 character drivers license number",
    },
    state: {
      id: uuidV4(),
      type: "text",
      displayName: "State",
      assignedTo: "",
      rules: {
        required: true,
      },
      hint: "A two-letter code for the US state or territory of the address",
    },
  }),
  displayName: "Drivers License",
  rules: {
    required: false,
  },
});

const getDefaultExperianUSEmployment = (): InputMappingGroupT => ({
  getDefaultElements: () => {
    const addressElements = getDefaultExperianUSAddress();
    let employerAddress: { [key: string]: any } = {};
    for (const key in addressElements) {
      employerAddress[`employer_address_${key}`] = addressElements[key];
    }
    return {
      employer_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Employer name",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: "1-75 character employer name",
      },
      ...employerAddress,
    };
  },
  displayName: "Employer",
});

const getDefaultExperianUSClarityData = (): InputMappingGroupT => ({
  getDefaultElements: () => ({
    account_id: {
      id: uuidV4(),
      type: "text",
      displayName: "Clarity account ID",
      assignedTo: "",
      rules: {
        required: true,
      },
    },
    location_id: {
      id: uuidV4(),
      type: "text",
      displayName: "Clarity location ID",
      assignedTo: "",
      rules: {
        required: false,
      },
    },
    control_file_name: {
      id: uuidV4(),
      type: "text",
      displayName: "Clarity control file name",
      assignedTo: "",
      rules: {
        required: false,
      },
    },
    control_file_version: {
      id: uuidV4(),
      type: "text",
      displayName: "Clarity control file version",
      assignedTo: "",
      rules: {
        required: false,
      },
    },
  }),
  displayName: "Clarity Data",
});

const getDefaultExperianUSAscendGoData = (): InputMappingGroupT => ({
  getDefaultElements: () => ({
    solution_id: {
      id: uuidV4(),
      type: "text",
      displayName: "AscendGo solution ID",
      assignedTo: "",
      rules: {
        required: false,
      },
      hint: "1-11 character AscendGo solution ID",
    },
  }),
  displayName: "Ascend Go Data",
});

const getDefaultExperianUSAddress = (): Record<string, InputMappingT> => ({
  line_1: {
    id: uuidV4(),
    type: "text",
    displayName: "Address line 1",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: "1-25 characters",
  },
  line_2: {
    id: uuidV4(),
    type: "text",
    displayName: "Address line 2",
    assignedTo: "",
    rules: {
      required: false,
    },
    hint: "1-25 characters",
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: {
      required: false,
    },
    hint: "1-25 characters",
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    rules: {
      required: false,
    },
    hint: "A two-letter code for the US state or territory of the address",
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: "5-9 character postal code",
  },
  country: {
    id: uuidV4(),
    type: "text",
    displayName: "Country",
    assignedTo: "",
    rules: {
      required: false,
    },
    hint: "Two-letter country code according to ISO 3166-1 alpha-2 (e.g., 'DE' for Germany)",
  },
});

const getDefaultExperianUSCustomOption = (): Record<string, InputMappingT> => ({
  option_id: {
    id: uuidV4(),
    type: "text",
    displayName: "Option ID",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: "1-25 characters",
  },
});

const getDefaultExperianUSRiskModel = (): Record<string, InputMappingT> => ({
  model_indicator: {
    id: uuidV4(),
    type: "text",
    displayName: "Risk model indicator",
    assignedTo: "",
    rules: {
      required: false,
    },
    hint: "E.g., 'F9' for FICO 9, or 'V4' for Vantage 4",
  },
});

const getDefaultExperianUSSummary = (): Record<string, InputMappingT> => ({
  summary_type: {
    id: uuidV4(),
    type: "text",
    displayName: "Summary type",
    assignedTo: "",
    rules: {
      required: false,
    },
    hint: "One of: 'Profile Summary', 'Auto Summary', 'Fraud Shield Summary'",
  },
});

export const getDefaultExperianUSCreditReport = (): IntegrationResourceT => ({
  providerResource: {
    provider: "experian_us",
    resource: "experian_us_credit_report",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      first_name: {
        id: uuidV4(),
        type: "text",
        displayName: "First name",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: "If first name contains two names, connect the names with a hyphen (e.g., Tommy-Lee). Must be at least 1 character",
      },
      middle_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Middle name",
        assignedTo: "",
        rules: {
          required: false,
        },
      },
      last_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Last name",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: "If last name contains two names, connect the names with a hyphen (e.g., Smith-Jones). Must be at least 2 characters.",
      },
      generation_code: {
        id: uuidV4(),
        type: "text",
        displayName: "Generation code",
        assignedTo: "",
        rules: {
          required: false,
        },
        hint: "One of: Jr, Sr, II, III, IV, V, VI, VII, VIII, IX",
      },
      prefix: {
        id: uuidV4(),
        type: "text",
        displayName: "Prefix",
        assignedTo: "",
        rules: {
          required: false,
        },
        hint: "One of: Mr, Mrs, Miss",
      },
      date_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Date of birth",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: "Must adhere to either the ISO format 'YYYY-MM-DD', or 'YYYY'",
      },
      social_security_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Social security number",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: "The individual's 9-digit social security number, formatted as either a string of integers (e.g., “123456789”), or a string of integers and hyphens (e.g., “123-45-6789”)",
      },
      employer_id_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Employer ID number",
        assignedTo: "",
        rules: {
          required: false,
        },
        hint: "The employer's 9-digit employer identification number, formatted as either a string of integers (e.g., “123456789”), or a string of integers and hyphens (e.g., “12-3456789”)",
      },
      tax_id_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Tax ID number (ITIN)",
        assignedTo: "",
        rules: {
          required: false,
        },
        hint: "The individual's 9-digit tax identification number (ITIN), formatted as either a string of integers (e.g., “123456789”), or a string of integers and hyphens (e.g., “123-45-6789”)",
      },
      email: {
        id: uuidV4(),
        type: "text",
        displayName: "Email",
        assignedTo: "",
        rules: {
          required: false,
        },
        hint: "1-128 characters",
      },
    },
    grouped: {
      current_address: {
        getDefaultElements: getDefaultExperianUSAddress,
        elements: getDefaultExperianUSAddress(),
        displayName: "Current Address",
        rules: {
          required: true,
        },
      },
      drivers_license: getDefaultExperianUSDriversLicense(),
      secondary_id: getDefaultExperianUSSecondaryId(),
      phone: getDefaultExperianUSPhone(),
      prev_address: {
        getDefaultElements: getDefaultExperianUSAddress,
        displayName: "Previous Address",
      },
      employment: getDefaultExperianUSEmployment(),
      ascend_go_data: getDefaultExperianUSAscendGoData(),
      clarity_data: getDefaultExperianUSClarityData(),
    },
    lists: {
      custom_option_list: {
        displayName: "Custom Option",
        getDefaultElement: getDefaultExperianUSCustomOption,
        elements: [],
        rules: {
          required: false,
          minLength: 0,
        },
      },
      risk_models: {
        displayName: "Risk Model",
        getDefaultElement: getDefaultExperianUSRiskModel,
        elements: [],
        rules: {
          required: false,
          minLength: 0,
        },
      },
      summaries: {
        displayName: "Summary",
        getDefaultElement: getDefaultExperianUSSummary,
        elements: [],
        rules: {
          required: false,
          minLength: 0,
        },
      },
    },
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      fico_8_score: {
        ...getNamedOutputMapping("FICO 8 score"),
        hint: `The FICO Score is a credit score that is widely used by lenders to assess a person's creditworthiness. The score ranges from 300 to 850 and is based on higher scores indicating better credit health.
        The main difference between FICO 8 and FICO 9 is how they treat medical debt collections and rent payments. In FICO 9, medical collections have less impact, and rent payments are taken into account, helping with thin-file credit assessment.
        Note that it depends on your agreement with Experian which of the scores will be part of the response.`,
      },
      fico_9_score: {
        ...getNamedOutputMapping("FICO 9 score"),
        hint: `The FICO Score is a credit score that is widely used by lenders to assess a person's creditworthiness. The score ranges from 300 to 850 and is based on higher scores indicating better credit health.
        The main difference between FICO 8 and FICO 9 is how they treat medical debt collections and rent payments. In FICO 9, medical collections have less impact, and rent payments are taken into account, helping with thin-file credit assessment.
        Note that it depends on your agreement with Experian which of the scores will be part of the response.`,
      },
      vantage_4_score: {
        ...getNamedOutputMapping("Vantage 4 score"),
        hint: `VantageScore 4.0 is a credit score that ranges from 300 to 850 and is used to assess a person's creditworthiness. A higher VantageScore indicates a better credit history, with a score above 700 generally considered good, and scores over 800 excellent.`,
      },
      clear_early_risk_score: {
        ...getNamedOutputMapping("Clear early risk score"),
        hint: `The Clear Early Risk Score assesses financial risk for consumers who lack traditional credit histories. The score ranges from 300 to 850, with higher scores indicating lower risk.`,
      },
      number_of_tradelines: {
        ...getNamedOutputMapping("Number of tradelines"),
        hint: `Only available when the profile summary is requested.`,
      },
      number_of_inquiries: {
        ...getNamedOutputMapping("Number of inquiries"),
        hint: `The number of times a person's credit report has been accessed by potential lenders or creditors. Only available when the profile summary is requested.`,
      },
      custom_attributes: {
        ...getNamedOutputMapping("Custom attributes"),
        hint: `An object of commonly used custom attributes. This object does not contain all possible custom attributes.`,
      },
    },
  },
  config: defaultResourceConfig,
  groupedGroupsAndLists: true,
});
