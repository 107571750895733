import BKRIcon from "src/assets/BKRIcon.svg?react";
import { getDefaultBKRCKI } from "src/connections/connectionConfigs/bkr/bkrResources";
import { BKRTemplate } from "src/connections/connectionConfigs/bkr/bkrTemplate";
import { ConnectionConfig } from "src/connections/connectionConfigs/types";

export const BKR_CONFIG: ConnectionConfig<"bkr"> = {
  icon: BKRIcon,
  description:
    "Provider of consumer credit and loan information in the Netherlands",
  template: BKRTemplate,
  name: "BKR",
  resources: {
    bkr_cki: {
      externalLink:
        "https://www.notion.so/taktile/BKR-4527e266b9c646539c7126ea7f8f25f2",
      integrationResource: getDefaultBKRCKI,
    },
  },
};
