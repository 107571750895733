import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  InputMappingListT,
  InputMappingT,
  IntegrationResourceT,
} from "src/integrationNode/types";

const ADDRESS_TYPES = [
  { key: `"PRIMARY"`, value: "primary" },
  { key: `"SECONDARY"`, value: "secondary" },
];

const getDefaultBoniversumAddress = (): { [key: string]: InputMappingT } => ({
  address_type: {
    id: uuidV4(),
    type: "dropDown",
    displayName: "Type",
    elements: ADDRESS_TYPES,
    assignedTo: `"PRIMARY"`,
    rules: {
      required: true,
    },
  },
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  house_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Number",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  affix: {
    id: uuidV4(),
    type: "text",
    displayName: "Affix",
    assignedTo: "",
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
});

const getDefaultBoniversumAddressList = (): InputMappingListT => ({
  displayName: "address",
  getDefaultElement: () => getDefaultBoniversumAddress(),
  elements: [getDefaultBoniversumAddress()],
});

export const getDefaultBoniversumConsumerReport = (): IntegrationResourceT => ({
  providerResource: { provider: "boniversum", resource: "consumer_report" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      customer_reference: {
        id: uuidV4(),
        type: "text",
        displayName: "Customer reference",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: `Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.`,
      },
      consent_given: {
        id: uuidV4(),
        type: "text",
        displayName: "Consent given",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: `True/False. Under Article 7 of the General Data Protection Regulation (GDPR), providers must verify that the individual for which the scoring request is being made has given their consent to storing the data in the request payload.`,
      },
      first_name: {
        id: uuidV4(),
        type: "text",
        displayName: "First name",
        assignedTo: "",
        rules: {
          required: true,
        },
      },
      maiden_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Maiden name",
        assignedTo: "",
      },
      middle_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Middle name",
        assignedTo: "",
      },
      last_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Last name",
        assignedTo: "",
        rules: {
          required: true,
        },
      },
      date_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Date of birth",
        assignedTo: "",
        hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20".`,
      },
      gender: {
        id: uuidV4(),
        type: "text",
        displayName: "Gender",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: `Options are: “MALE”, “FEMALE”, “NON_BINARY”, “UNKNOWN”`,
      },
    },
    grouped: {},
    lists: {
      addresses: getDefaultBoniversumAddressList(),
    },
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      verita_score: {
        ...getNamedOutputMapping("VERITA score"),
        hint: `The VERITA score is Boniversum's most important credit scoring product. It estimates a consumer's probability of payment default. When no negative features exist, the score ranges from 1 to 1000, with scores < 56 indicating a probability of default less than 5%. A score of 124 indicates a 17.8% chance of default. See our docs for more information.`,
      },
      verita_score_range: {
        ...getNamedOutputMapping("VERITA score range"),
        hint: `The VERITA range is meant to make interpreting the VERITA score easier. It ranges from 1 to 9, with 1 being the best (less than 0.7% probability of default in the next twelve months). A score of 6 means less than 5% chance.`,
      },
      traffic_light_score: {
        ...getNamedOutputMapping("Traffic light score"),
        hint: `The traffic light score is just a very coarse “bucketing” on top of the score - certain score ranges are mapped to a traffic light color (”green”, “yellow”, or “red”). 1 = Green (<5% PD), 2 = Yellow (between 5% and 17.8%), 3 = red (>17.8%)`,
      },
    },
  },
  config: defaultResourceConfig,
});
