import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { DecisionEnvironment } from "src/api/types";
import { DecisionHistoryKeys } from "src/router/SearchParams";

export const useDecisionEnvSearchParam = () => {
  const [params, setParams] = useSearchParams();
  const decisionEnv = params.get(
    DecisionHistoryKeys.Environment,
  ) as DecisionEnvironment | null;
  const setDecisionEnv = useCallback(
    (env: DecisionEnvironment) => {
      setParams((prev) => {
        prev.set(DecisionHistoryKeys.Environment, env);
        return prev;
      });
    },
    [setParams],
  );
  return [decisionEnv, setDecisionEnv] as const;
};
