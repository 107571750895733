/* tslint:disable */
/* eslint-disable */
/**
 * SAMPLE_PROJECT_NAME
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * A rule branch.
 * @export
 * @interface AssignRuleBranch
 */
export interface AssignRuleBranch {
    /**
     * 
     * @type {string}
     * @memberof AssignRuleBranch
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssignRuleBranch
     */
    'junction': AssignRuleBranchJunctionEnum;
    /**
     * 
     * @type {Array<AssignRuleClause>}
     * @memberof AssignRuleBranch
     */
    'clauses': Array<AssignRuleClause>;
    /**
     * 
     * @type {AssignStrategy}
     * @memberof AssignRuleBranch
     */
    'assign_strategy': AssignStrategy;
}

/**
    * @export
    * @enum {string}
    */
export enum AssignRuleBranchJunctionEnum {
    AND = 'and',
    OR = 'or'
}

/**
 * A rule node condition
 * @export
 * @interface AssignRuleClause
 */
export interface AssignRuleClause {
    /**
     * 
     * @type {string}
     * @memberof AssignRuleClause
     */
    'id_left': string;
    /**
     * 
     * @type {string}
     * @memberof AssignRuleClause
     */
    'id_right': string;
    /**
     * 
     * @type {string}
     * @memberof AssignRuleClause
     */
    'left': string;
    /**
     * 
     * @type {string}
     * @memberof AssignRuleClause
     */
    'right': string;
    /**
     * 
     * @type {Operators}
     * @memberof AssignRuleClause
     */
    'operator': Operators;
}


/**
 * 
 * @export
 * @interface AssignStrategy
 */
export interface AssignStrategy {
    /**
     * 
     * @type {any}
     * @memberof AssignStrategy
     */
    'type': AssignStrategyTypeEnum;
    /**
     * 
     * @type {any}
     * @memberof AssignStrategy
     */
    'assignees': any;
}

/**
    * @export
    * @enum {string}
    */
export enum AssignStrategyTypeEnum {
    ANY = 'ANY',
    GROUP = 'GROUP',
    REVIEWER_ONLY = 'REVIEWER_ONLY'
}

/**
 * Randomly assign to users with admin, editor, or reviewer roles.
 * @export
 * @interface AssignStrategyAny
 */
export interface AssignStrategyAny {
    /**
     * 
     * @type {string}
     * @memberof AssignStrategyAny
     */
    'type': AssignStrategyAnyTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AssignStrategyAnyTypeEnum {
    ANY = 'ANY'
}

/**
 * Randomly assign to users in a group. Special Cases:     Empty - Set to unassigned     Single Element - Assign to one user
 * @export
 * @interface AssignStrategyGroup
 */
export interface AssignStrategyGroup {
    /**
     * 
     * @type {string}
     * @memberof AssignStrategyGroup
     */
    'type': AssignStrategyGroupTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignStrategyGroup
     */
    'assignees': Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum AssignStrategyGroupTypeEnum {
    GROUP = 'GROUP'
}

/**
 * Randomly assign to users with reviewer roles.
 * @export
 * @interface AssignStrategyReviewerOnly
 */
export interface AssignStrategyReviewerOnly {
    /**
     * 
     * @type {string}
     * @memberof AssignStrategyReviewerOnly
     */
    'type': AssignStrategyReviewerOnlyTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AssignStrategyReviewerOnlyTypeEnum {
    REVIEWER_ONLY = 'REVIEWER_ONLY'
}

/**
 * Assign to a user based on a rule. The rule is processed in Runner and used in Exporter/Connect.
 * @export
 * @interface AssignStrategyRule
 */
export interface AssignStrategyRule {
    /**
     * 
     * @type {string}
     * @memberof AssignStrategyRule
     */
    'type': AssignStrategyRuleTypeEnum;
    /**
     * 
     * @type {Array<AssignRuleBranch>}
     * @memberof AssignStrategyRule
     */
    'branches': Array<AssignRuleBranch>;
    /**
     * 
     * @type {DefaultAssignStrategy}
     * @memberof AssignStrategyRule
     */
    'default_assign_strategy': DefaultAssignStrategy;
}

/**
    * @export
    * @enum {string}
    */
export enum AssignStrategyRuleTypeEnum {
    RULE = 'RULE'
}

/**
 * 
 * @export
 * @interface AssignmentNodeMeta
 */
export interface AssignmentNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof AssignmentNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssignmentNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {Array<Effect>}
     * @memberof AssignmentNodeMeta
     */
    'default_effects': Array<Effect>;
}
/**
 * 
 * @export
 * @interface AttributeFilter
 */
export interface AttributeFilter {
    /**
     * 
     * @type {Array<EventAttributeEnv>}
     * @memberof AttributeFilter
     */
    'decision_env'?: Array<EventAttributeEnv>;
    /**
     * 
     * @type {Array<AttributeFilterStatusInner>}
     * @memberof AttributeFilter
     */
    'status'?: Array<AttributeFilterStatusInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AttributeFilter
     */
    'flow_id'?: Array<string>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof AttributeFilter
     */
    'is_async'?: Array<boolean>;
}
/**
 * 
 * @export
 * @interface AttributeFilterStatusInner
 */
export interface AttributeFilterStatusInner {
}
/**
 * 
 * @export
 * @interface BaseInputField
 */
export interface BaseInputField {
    /**
     * 
     * @type {string}
     * @memberof BaseInputField
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BaseInputField
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface BranchNodeMeta
 */
export interface BranchNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof BranchNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BranchNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BranchNodeMeta
     */
    'src': string;
    /**
     * 
     * @type {boolean}
     * @memberof BranchNodeMeta
     */
    'is_split_branch_node': boolean;
}
/**
 * 
 * @export
 * @interface BreakLoop
 */
export interface BreakLoop {
    /**
     * 
     * @type {Junctions}
     * @memberof BreakLoop
     */
    'junction': Junctions;
    /**
     * 
     * @type {Array<Clause>}
     * @memberof BreakLoop
     */
    'clauses': Array<Clause>;
    /**
     * 
     * @type {string}
     * @memberof BreakLoop
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof BreakLoop
     */
    'enabled': boolean;
}


/**
 * 
 * @export
 * @interface ChangeLogDb
 */
export interface ChangeLogDb {
    /**
     * 
     * @type {string}
     * @memberof ChangeLogDb
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogDb
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogDb
     */
    'flow_version_id': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogDb
     */
    'etag': string;
    /**
     * 
     * @type {FlowVersionUpdate}
     * @memberof ChangeLogDb
     */
    'diff'?: FlowVersionUpdate;
    /**
     * 
     * @type {FlowVersionV2}
     * @memberof ChangeLogDb
     */
    'checkpoint'?: FlowVersionV2;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogDb
     */
    'authored_by': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogDb
     */
    'previous_change_etag'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogDb
     */
    'previous_change_flow_version_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogDb
     */
    'previous_change_flow_id'?: string;
    /**
     * 
     * @type {CommentDb}
     * @memberof ChangeLogDb
     */
    'comment'?: CommentDb;
}
/**
 * 
 * @export
 * @interface Chart
 */
export interface Chart {
    /**
     * 
     * @type {ChartResourceType}
     * @memberof Chart
     */
    'resource_type'?: ChartResourceType;
    /**
     * 
     * @type {string}
     * @memberof Chart
     */
    'resource_id': string;
    /**
     * 
     * @type {number}
     * @memberof Chart
     */
    'order_index'?: number;
    /**
     * 
     * @type {string}
     * @memberof Chart
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Chart
     */
    'description'?: string;
    /**
     * 
     * @type {ChartMark}
     * @memberof Chart
     */
    'mark': ChartMark;
    /**
     * 
     * @type {Dimensions}
     * @memberof Chart
     */
    'dimensions': Dimensions;
    /**
     * 
     * @type {string}
     * @memberof Chart
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof Chart
     */
    'updated_by': string;
    /**
     * 
     * @type {string}
     * @memberof Chart
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Chart
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof Chart
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Chart
     */
    'version': ChartVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof Chart
     */
    'etag': string;
}

/**
    * @export
    * @enum {string}
    */
export enum ChartVersionEnum {
    NUMBER_1 = 1
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ChartAggregator {
    COUNT = 'count',
    SUM = 'sum',
    MEAN = 'mean',
    MEDIAN = 'median',
    MIN = 'min',
    MAX = 'max'
}


/**
 * 
 * @export
 * @interface ChartCreateRequest
 */
export interface ChartCreateRequest {
    /**
     * 
     * @type {ChartResourceType}
     * @memberof ChartCreateRequest
     */
    'resource_type'?: ChartResourceType;
    /**
     * 
     * @type {string}
     * @memberof ChartCreateRequest
     */
    'resource_id': string;
    /**
     * 
     * @type {number}
     * @memberof ChartCreateRequest
     */
    'order_index'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChartCreateRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ChartCreateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {ChartMark}
     * @memberof ChartCreateRequest
     */
    'mark': ChartMark;
    /**
     * 
     * @type {Dimensions}
     * @memberof ChartCreateRequest
     */
    'dimensions': Dimensions;
}


/**
 * 
 * @export
 * @interface ChartDimension
 */
export interface ChartDimension {
    /**
     * 
     * @type {string}
     * @memberof ChartDimension
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChartDimension
     */
    'reduce'?: ChartDimensionReduceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ChartDimension
     */
    'exclude_rows_without_outcome'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ChartDimensionReduceEnum {
    AUTO = 'auto',
    COUNT = 'count',
    DISTINCT = 'distinct',
    SUM = 'sum',
    MIN = 'min',
    MAX = 'max',
    MEAN = 'mean',
    MEDIAN = 'median',
    MODE = 'mode',
    PROPORTION = 'proportion',
    PROPORTION_FACET = 'proportion-facet'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ChartFormat {
    NUMBER = 'number',
    CURRENCY = 'currency',
    PERCENT = 'percent'
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ChartMark {
    AUTO = 'auto',
    BAR = 'bar',
    LINE = 'line',
    AREA = 'area',
    DOT = 'dot',
    RULE = 'rule',
    SUMMARY = 'summary'
}


/**
 * 
 * @export
 * @interface ChartPatch
 */
export interface ChartPatch {
    /**
     * 
     * @type {string}
     * @memberof ChartPatch
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChartPatch
     */
    'description'?: string;
    /**
     * 
     * @type {ChartMark}
     * @memberof ChartPatch
     */
    'mark'?: ChartMark;
    /**
     * 
     * @type {Dimensions}
     * @memberof ChartPatch
     */
    'dimensions'?: Dimensions;
}


/**
 * 
 * @export
 * @interface ChartReorder
 */
export interface ChartReorder {
    /**
     * 
     * @type {ChartResourceType}
     * @memberof ChartReorder
     */
    'resource_type': ChartResourceType;
    /**
     * 
     * @type {string}
     * @memberof ChartReorder
     */
    'resource_id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChartReorder
     */
    'new_order': Array<string>;
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ChartResourceType {
    FLOW = 'flow',
    NODE = 'node'
}


/**
 * 
 * @export
 * @interface Clause
 */
export interface Clause {
    /**
     * 
     * @type {string}
     * @memberof Clause
     */
    'id_left': string;
    /**
     * 
     * @type {string}
     * @memberof Clause
     */
    'id_right': string;
    /**
     * 
     * @type {string}
     * @memberof Clause
     */
    'left': string;
    /**
     * 
     * @type {string}
     * @memberof Clause
     */
    'right': string;
    /**
     * 
     * @type {Operators}
     * @memberof Clause
     */
    'operator': Operators;
}


/**
 * 
 * @export
 * @interface CodeNodeMeta
 */
export interface CodeNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof CodeNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeNodeMeta
     */
    'src': string;
}
/**
 * 
 * @export
 * @interface CommentCreate
 */
export interface CommentCreate {
    /**
     * 
     * @type {string}
     * @memberof CommentCreate
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CommentCreate
     */
    'flow_version': string;
    /**
     * 
     * @type {CommentResourceType}
     * @memberof CommentCreate
     */
    'resource_type'?: CommentResourceType;
    /**
     * 
     * @type {string}
     * @memberof CommentCreate
     */
    'resource_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentCreate
     */
    'parent'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentCreate
     */
    'review_id'?: string;
    /**
     * 
     * @type {ReviewCommentStatus}
     * @memberof CommentCreate
     */
    'reviewer_status'?: ReviewCommentStatus;
    /**
     * 
     * @type {string}
     * @memberof CommentCreate
     */
    'flow_version_etag'?: string;
}


/**
 * 
 * @export
 * @interface CommentDb
 */
export interface CommentDb {
    /**
     * 
     * @type {string}
     * @memberof CommentDb
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof CommentDb
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof CommentDb
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CommentDb
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CommentDb
     */
    'author': string;
    /**
     * 
     * @type {CommentResourceType}
     * @memberof CommentDb
     */
    'resource_type'?: CommentResourceType;
    /**
     * 
     * @type {string}
     * @memberof CommentDb
     */
    'resource_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDb
     */
    'parent'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDb
     */
    'replied_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommentDb
     */
    'reply_count': number;
    /**
     * 
     * @type {string}
     * @memberof CommentDb
     */
    'resolved_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDb
     */
    'resolved_by'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommentDb
     */
    'resolved': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommentDb
     */
    'review_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDb
     */
    'flow_version_etag'?: string;
    /**
     * 
     * @type {ReviewCommentStatus}
     * @memberof CommentDb
     */
    'reviewer_status'?: ReviewCommentStatus;
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum CommentResourceType {
    FLOW_VERSION = 'flow-version',
    NODE = 'node',
    REVIEW = 'review'
}


/**
 * 
 * @export
 * @interface CommentSummary
 */
export interface CommentSummary {
    /**
     * 
     * @type {Array<NodeCommentSummary>}
     * @memberof CommentSummary
     */
    'nodes_comment_summary': Array<NodeCommentSummary>;
    /**
     * 
     * @type {number}
     * @memberof CommentSummary
     */
    'flow_version_count': number;
    /**
     * 
     * @type {number}
     * @memberof CommentSummary
     */
    'review_comment_count': number;
}
/**
 * 
 * @export
 * @interface CommentUpdate
 */
export interface CommentUpdate {
    /**
     * 
     * @type {string}
     * @memberof CommentUpdate
     */
    'content'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommentUpdate
     */
    'resolved'?: boolean;
}
/**
 * Basic connect node information used to list connect nodes on flow version without exposing details of the flow logic. Can be made available with checking `workspace` resource permission, Instead of the `workspace_data` resource permission.
 * @export
 * @interface ConnectNodeBasic
 */
export interface ConnectNodeBasic {
    /**
     * 
     * @type {string}
     * @memberof ConnectNodeBasic
     */
    'id': string;
    /**
     * 
     * @type {NodeTypes}
     * @memberof ConnectNodeBasic
     */
    'type': NodeTypes;
    /**
     * 
     * @type {string}
     * @memberof ConnectNodeBasic
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectNodeBasic
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectNodeBasic
     */
    'resource': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectNodeBasic
     */
    'connection_id': string;
}


/**
 * 
 * @export
 * @interface CopyFlowResponse
 */
export interface CopyFlowResponse {
    /**
     * 
     * @type {FlowDb}
     * @memberof CopyFlowResponse
     */
    'flow': FlowDb;
    /**
     * 
     * @type {Array<string>}
     * @memberof CopyFlowResponse
     */
    'flow_versions_ids': Array<string>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof CopyFlowResponse
     */
    'datasets_to_copy': { [key: string]: Array<string>; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CopyFlowResponse
     */
    'remapped_child_flow_ids': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface CustomConnectionBody
 */
export interface CustomConnectionBody {
    /**
     * 
     * @type {string}
     * @memberof CustomConnectionBody
     */
    'id': string;
    /**
     * 
     * @type {Value}
     * @memberof CustomConnectionBody
     */
    'value': Value;
}
/**
 * 
 * @export
 * @interface CustomConnectionNodeMeta
 */
export interface CustomConnectionNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof CustomConnectionNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomConnectionNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof CustomConnectionNodeMeta
     */
    'provider_resource': object;
    /**
     * 
     * @type {string}
     * @memberof CustomConnectionNodeMeta
     */
    'connection_id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomConnectionNodeMeta
     */
    'resource_config_id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomConnectionNodeMeta
     */
    'media_key'?: string;
    /**
     * 
     * @type {object}
     * @memberof CustomConnectionNodeMeta
     */
    'config': object;
    /**
     * 
     * @type {string}
     * @memberof CustomConnectionNodeMeta
     */
    'verb': string;
    /**
     * 
     * @type {InputAttribute}
     * @memberof CustomConnectionNodeMeta
     */
    'path': InputAttribute;
    /**
     * 
     * @type {Array<KeyValuePair>}
     * @memberof CustomConnectionNodeMeta
     */
    'params': Array<KeyValuePair>;
    /**
     * 
     * @type {Array<KeyValuePair>}
     * @memberof CustomConnectionNodeMeta
     */
    'headers': Array<KeyValuePair>;
    /**
     * 
     * @type {CustomConnectionBody}
     * @memberof CustomConnectionNodeMeta
     */
    'body': CustomConnectionBody;
    /**
     * 
     * @type {object}
     * @memberof CustomConnectionNodeMeta
     */
    'response': object;
}
/**
 * 
 * @export
 * @interface Data
 */
export interface Data {
    /**
     * 
     * @type {Meta}
     * @memberof Data
     */
    'meta'?: Meta;
    /**
     * 
     * @type {any}
     * @memberof Data
     */
    'name': any;
    /**
     * 
     * @type {FlowVersionStatus}
     * @memberof Data
     */
    'status'?: FlowVersionStatus;
    /**
     * 
     * @type {any}
     * @memberof Data
     */
    'include_comments'?: any;
    /**
     * 
     * @type {any}
     * @memberof Data
     */
    'flow_id': any;
}


/**
 * 
 * @export
 * @interface DataRetentionTimedelta
 */
export interface DataRetentionTimedelta {
    /**
     * 
     * @type {number}
     * @memberof DataRetentionTimedelta
     */
    'value': number;
    /**
     * 
     * @type {DataRetentionUnit}
     * @memberof DataRetentionTimedelta
     */
    'unit': DataRetentionUnit;
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum DataRetentionUnit {
    DAYS = 'days',
    MINUTES = 'minutes',
    HOURS = 'hours',
    WEEKS = 'weeks',
    MONTHS = 'months',
    YEARS = 'years'
}


/**
 * 
 * @export
 * @interface DatabaseConnectionNodeMeta
 */
export interface DatabaseConnectionNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof DatabaseConnectionNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseConnectionNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof DatabaseConnectionNodeMeta
     */
    'provider_resource': object;
    /**
     * 
     * @type {string}
     * @memberof DatabaseConnectionNodeMeta
     */
    'connection_id': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseConnectionNodeMeta
     */
    'resource_config_id': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseConnectionNodeMeta
     */
    'media_key'?: string;
    /**
     * 
     * @type {object}
     * @memberof DatabaseConnectionNodeMeta
     */
    'config': object;
    /**
     * 
     * @type {Array<DatabaseVariable>}
     * @memberof DatabaseConnectionNodeMeta
     */
    'variables': Array<DatabaseVariable>;
    /**
     * 
     * @type {InputAttribute}
     * @memberof DatabaseConnectionNodeMeta
     */
    'query': InputAttribute;
    /**
     * 
     * @type {object}
     * @memberof DatabaseConnectionNodeMeta
     */
    'response': object;
}
/**
 * 
 * @export
 * @interface DatabaseVariable
 */
export interface DatabaseVariable {
    /**
     * 
     * @type {string}
     * @memberof DatabaseVariable
     */
    'id_key': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseVariable
     */
    'id_expression': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseVariable
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseVariable
     */
    'expression': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseVariable
     */
    'id_type': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseVariable
     */
    'type_'?: string;
}
/**
 * 
 * @export
 * @interface DatasetsDuplicate
 */
export interface DatasetsDuplicate {
    /**
     * 
     * @type {string}
     * @memberof DatasetsDuplicate
     */
    'target_flow_id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetsDuplicate
     */
    'dataset_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DecisionTableCase
 */
export interface DecisionTableCase {
    /**
     * 
     * @type {string}
     * @memberof DecisionTableCase
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DecisionTableCase
     */
    'name': string;
    /**
     * 
     * @type {{ [key: string]: DecisionTablePredicate; }}
     * @memberof DecisionTableCase
     */
    'predicates': { [key: string]: DecisionTablePredicate; };
    /**
     * 
     * @type {{ [key: string]: DecisionTableEffect; }}
     * @memberof DecisionTableCase
     */
    'effects': { [key: string]: DecisionTableEffect; };
}
/**
 * 
 * @export
 * @interface DecisionTableEffect
 */
export interface DecisionTableEffect {
    /**
     * 
     * @type {string}
     * @memberof DecisionTableEffect
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DecisionTableEffect
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface DecisionTableField
 */
export interface DecisionTableField {
    /**
     * 
     * @type {string}
     * @memberof DecisionTableField
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DecisionTableField
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface DecisionTableNodeMeta
 */
export interface DecisionTableNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof DecisionTableNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DecisionTableNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {Array<DecisionTableField>}
     * @memberof DecisionTableNodeMeta
     */
    'predicate_fields': Array<DecisionTableField>;
    /**
     * 
     * @type {Array<DecisionTableField>}
     * @memberof DecisionTableNodeMeta
     */
    'effect_fields': Array<DecisionTableField>;
    /**
     * 
     * @type {Array<DecisionTableCase>}
     * @memberof DecisionTableNodeMeta
     */
    'cases': Array<DecisionTableCase>;
    /**
     * 
     * @type {{ [key: string]: DecisionTableEffect; }}
     * @memberof DecisionTableNodeMeta
     */
    'fallback': { [key: string]: DecisionTableEffect; };
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum DecisionTableOperators {
    LT = 'lt',
    LE = 'le',
    EQ = 'eq',
    NE = 'ne',
    GE = 'ge',
    GT = 'gt',
    IS_IN = 'is_in',
    NOT_IS_IN = 'not_is_in',
    IS_NONE = 'is_none',
    IS_NOT_NONE = 'is_not_none',
    IS_ANY = 'is_any',
    CONTAINS = 'contains',
    DOES_NOT_CONTAIN = 'does_not_contain',
    MATCHES_REGEX = 'matches_regex'
}


/**
 * 
 * @export
 * @interface DecisionTablePredicate
 */
export interface DecisionTablePredicate {
    /**
     * 
     * @type {string}
     * @memberof DecisionTablePredicate
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DecisionTablePredicate
     */
    'value': string;
    /**
     * 
     * @type {DecisionTableOperators}
     * @memberof DecisionTablePredicate
     */
    'operator': DecisionTableOperators;
}


/**
 * 
 * @export
 * @interface DefaultAssignStrategy
 */
export interface DefaultAssignStrategy {
    /**
     * 
     * @type {any}
     * @memberof DefaultAssignStrategy
     */
    'type': DefaultAssignStrategyTypeEnum;
    /**
     * 
     * @type {any}
     * @memberof DefaultAssignStrategy
     */
    'assignees': any;
}

/**
    * @export
    * @enum {string}
    */
export enum DefaultAssignStrategyTypeEnum {
    ANY = 'ANY',
    GROUP = 'GROUP',
    REVIEWER_ONLY = 'REVIEWER_ONLY'
}

/**
 * 
 * @export
 * @interface Dimensions
 */
export interface Dimensions {
    /**
     * 
     * @type {ChartDimension}
     * @memberof Dimensions
     */
    'x': ChartDimension;
    /**
     * 
     * @type {ChartDimension}
     * @memberof Dimensions
     */
    'y': ChartDimension;
    /**
     * 
     * @type {ChartDimension}
     * @memberof Dimensions
     */
    'color': ChartDimension;
    /**
     * 
     * @type {ChartDimension}
     * @memberof Dimensions
     */
    'size': ChartDimension;
    /**
     * 
     * @type {ChartDimension}
     * @memberof Dimensions
     */
    'fx': ChartDimension;
    /**
     * 
     * @type {ChartDimension}
     * @memberof Dimensions
     */
    'fy': ChartDimension;
    /**
     * 
     * @type {any}
     * @memberof Dimensions
     */
    'field'?: any;
    /**
     * 
     * @type {ChartAggregator}
     * @memberof Dimensions
     */
    'aggregator': ChartAggregator;
    /**
     * 
     * @type {ChartFormat}
     * @memberof Dimensions
     */
    'format': ChartFormat;
}


/**
 * 
 * @export
 * @interface EdgeDb
 */
export interface EdgeDb {
    /**
     * 
     * @type {EdgeMeta}
     * @memberof EdgeDb
     */
    'meta'?: EdgeMeta;
    /**
     * 
     * @type {string}
     * @memberof EdgeDb
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EdgeDb
     */
    'start_node_id': string;
    /**
     * 
     * @type {string}
     * @memberof EdgeDb
     */
    'end_node_id': string;
}
/**
 * 
 * @export
 * @interface EdgeMeta
 */
export interface EdgeMeta {
    /**
     * 
     * @type {boolean}
     * @memberof EdgeMeta
     */
    'is_split_edge'?: boolean;
}
/**
 * 
 * @export
 * @interface EdgeUpsert
 */
export interface EdgeUpsert {
    /**
     * 
     * @type {EdgeMeta}
     * @memberof EdgeUpsert
     */
    'meta'?: EdgeMeta;
    /**
     * 
     * @type {string}
     * @memberof EdgeUpsert
     */
    'start_node_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EdgeUpsert
     */
    'end_node_id'?: string;
}
/**
 * 
 * @export
 * @interface Effect
 */
export interface Effect {
    /**
     * 
     * @type {string}
     * @memberof Effect
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Effect
     */
    'target': string;
    /**
     * 
     * @type {string}
     * @memberof Effect
     */
    'value': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum EventAttributeEnv {
    SANDBOX = 'sandbox',
    LIVE = 'live'
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum EventAttributeStatus {
    SUCCESS = 'success',
    ERROR = 'error',
    PENDING = 'pending'
}


/**
 * 
 * @export
 * @interface EventFilter
 */
export interface EventFilter {
    /**
     * 
     * @type {EventType}
     * @memberof EventFilter
     */
    'type': EventType;
    /**
     * 
     * @type {AttributeFilter}
     * @memberof EventFilter
     */
    'condition': AttributeFilter;
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum EventType {
    DECISION_OUTCOME = 'DecisionOutcome',
    MANUAL_REVIEW_CASE_CREATION = 'ManualReviewCaseCreation',
    JOB_RUN_EXECUTION = 'JobRunExecution'
}


/**
 * 
 * @export
 * @interface FlowCreatePopulated
 */
export interface FlowCreatePopulated {
    /**
     * 
     * @type {FlowMeta}
     * @memberof FlowCreatePopulated
     */
    'meta'?: FlowMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowCreatePopulated
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowCreatePopulated
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof FlowCreatePopulated
     */
    'workspace_id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowCreatePopulated
     */
    'flow_folder_id'?: string;
    /**
     * 
     * @type {FlowReviewConfiguration}
     * @memberof FlowCreatePopulated
     */
    'review_configuration'?: FlowReviewConfiguration;
    /**
     * 
     * @type {string}
     * @memberof FlowCreatePopulated
     */
    'initial_version_name'?: string;
    /**
     * 
     * @type {object}
     * @memberof FlowCreatePopulated
     */
    'initial_version_meta'?: object;
}
/**
 * 
 * @export
 * @interface FlowDb
 */
export interface FlowDb {
    /**
     * 
     * @type {string}
     * @memberof FlowDb
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDb
     */
    'updated_at': string;
    /**
     * 
     * @type {FlowMeta}
     * @memberof FlowDb
     */
    'meta'?: FlowMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowDb
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDb
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDb
     */
    'workspace_id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDb
     */
    'flow_folder_id'?: string;
    /**
     * 
     * @type {FlowReviewConfiguration}
     * @memberof FlowDb
     */
    'review_configuration'?: FlowReviewConfiguration;
    /**
     * 
     * @type {string}
     * @memberof FlowDb
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDb
     */
    'default_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowDb
     */
    'default_sandbox_version'?: string;
    /**
     * 
     * @type {FlowTrafficPolicy}
     * @memberof FlowDb
     */
    'active_traffic_policy'?: FlowTrafficPolicy;
    /**
     * 
     * @type {Array<FlowVersionDb>}
     * @memberof FlowDb
     */
    'versions'?: Array<FlowVersionDb>;
    /**
     * 
     * @type {Array<FlowTrafficPolicy>}
     * @memberof FlowDb
     */
    'traffic_policies'?: Array<FlowTrafficPolicy>;
}
/**
 * Response model for flow with active traffic policy. Consumed by router lambda to fetch only the necessary information for routing.
 * @export
 * @interface FlowDbActivePolicySummary
 */
export interface FlowDbActivePolicySummary {
    /**
     * 
     * @type {string}
     * @memberof FlowDbActivePolicySummary
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbActivePolicySummary
     */
    'updated_at': string;
    /**
     * 
     * @type {FlowMeta}
     * @memberof FlowDbActivePolicySummary
     */
    'meta'?: FlowMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowDbActivePolicySummary
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbActivePolicySummary
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbActivePolicySummary
     */
    'workspace_id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbActivePolicySummary
     */
    'flow_folder_id'?: string;
    /**
     * 
     * @type {FlowReviewConfiguration}
     * @memberof FlowDbActivePolicySummary
     */
    'review_configuration'?: FlowReviewConfiguration;
    /**
     * 
     * @type {string}
     * @memberof FlowDbActivePolicySummary
     */
    'id': string;
    /**
     * 
     * @type {Array<FlowVersionDbShallow>}
     * @memberof FlowDbActivePolicySummary
     */
    'versions'?: Array<FlowVersionDbShallow>;
    /**
     * 
     * @type {FlowTrafficPolicy}
     * @memberof FlowDbActivePolicySummary
     */
    'active_traffic_policy': FlowTrafficPolicy;
}
/**
 * 
 * @export
 * @interface FlowDbDataplane
 */
export interface FlowDbDataplane {
    /**
     * 
     * @type {string}
     * @memberof FlowDbDataplane
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbDataplane
     */
    'updated_at': string;
    /**
     * 
     * @type {FlowMeta}
     * @memberof FlowDbDataplane
     */
    'meta'?: FlowMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowDbDataplane
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbDataplane
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbDataplane
     */
    'workspace_id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbDataplane
     */
    'flow_folder_id'?: string;
    /**
     * 
     * @type {FlowReviewConfiguration}
     * @memberof FlowDbDataplane
     */
    'review_configuration'?: FlowReviewConfiguration;
    /**
     * 
     * @type {string}
     * @memberof FlowDbDataplane
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbDataplane
     */
    'default_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbDataplane
     */
    'default_sandbox_version'?: string;
    /**
     * 
     * @type {Array<FlowVersionDbDataplane>}
     * @memberof FlowDbDataplane
     */
    'versions'?: Array<FlowVersionDbDataplane>;
    /**
     * 
     * @type {FlowTrafficPolicy}
     * @memberof FlowDbDataplane
     */
    'active_traffic_policy'?: FlowTrafficPolicy;
}
/**
 * 
 * @export
 * @interface FlowDbShallow
 */
export interface FlowDbShallow {
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallow
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallow
     */
    'updated_at': string;
    /**
     * 
     * @type {FlowMeta}
     * @memberof FlowDbShallow
     */
    'meta'?: FlowMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallow
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallow
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallow
     */
    'workspace_id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallow
     */
    'flow_folder_id'?: string;
    /**
     * 
     * @type {FlowReviewConfiguration}
     * @memberof FlowDbShallow
     */
    'review_configuration'?: FlowReviewConfiguration;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallow
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallow
     */
    'default_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallow
     */
    'default_sandbox_version'?: string;
    /**
     * 
     * @type {Array<FlowVersionDbShallow>}
     * @memberof FlowDbShallow
     */
    'versions'?: Array<FlowVersionDbShallow>;
    /**
     * 
     * @type {Array<FlowTrafficPolicy>}
     * @memberof FlowDbShallow
     */
    'traffic_policies'?: Array<FlowTrafficPolicy>;
    /**
     * 
     * @type {FlowTrafficPolicy}
     * @memberof FlowDbShallow
     */
    'active_traffic_policy'?: FlowTrafficPolicy;
    /**
     * 
     * @type {Array<FlowDbShallowVersionNoPolicy>}
     * @memberof FlowDbShallow
     */
    'child_flows'?: Array<FlowDbShallowVersionNoPolicy>;
    /**
     * 
     * @type {Array<FlowDbShallowVersionNoPolicy>}
     * @memberof FlowDbShallow
     */
    'parent_flows'?: Array<FlowDbShallowVersionNoPolicy>;
}
/**
 * 
 * @export
 * @interface FlowDbShallowNoTrafficPolicies
 */
export interface FlowDbShallowNoTrafficPolicies {
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowNoTrafficPolicies
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowNoTrafficPolicies
     */
    'updated_at': string;
    /**
     * 
     * @type {FlowMeta}
     * @memberof FlowDbShallowNoTrafficPolicies
     */
    'meta'?: FlowMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowNoTrafficPolicies
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowNoTrafficPolicies
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowNoTrafficPolicies
     */
    'workspace_id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowNoTrafficPolicies
     */
    'flow_folder_id'?: string;
    /**
     * 
     * @type {FlowReviewConfiguration}
     * @memberof FlowDbShallowNoTrafficPolicies
     */
    'review_configuration'?: FlowReviewConfiguration;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowNoTrafficPolicies
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowNoTrafficPolicies
     */
    'default_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowNoTrafficPolicies
     */
    'default_sandbox_version'?: string;
    /**
     * 
     * @type {Array<FlowVersionDbShallow>}
     * @memberof FlowDbShallowNoTrafficPolicies
     */
    'versions'?: Array<FlowVersionDbShallow>;
    /**
     * 
     * @type {Array<FlowDbShallowVersionNoPolicy>}
     * @memberof FlowDbShallowNoTrafficPolicies
     */
    'child_flows'?: Array<FlowDbShallowVersionNoPolicy>;
    /**
     * 
     * @type {Array<FlowDbShallowVersionNoPolicy>}
     * @memberof FlowDbShallowNoTrafficPolicies
     */
    'parent_flows'?: Array<FlowDbShallowVersionNoPolicy>;
}
/**
 * 
 * @export
 * @interface FlowDbShallowSansLinkedFlows
 */
export interface FlowDbShallowSansLinkedFlows {
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'updated_at': string;
    /**
     * 
     * @type {FlowMeta}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'meta'?: FlowMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'workspace_id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'flow_folder_id'?: string;
    /**
     * 
     * @type {FlowReviewConfiguration}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'review_configuration': FlowReviewConfiguration;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'default_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'default_sandbox_version'?: string;
    /**
     * 
     * @type {Array<FlowVersionDbShallowChildren>}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'versions'?: Array<FlowVersionDbShallowChildren>;
    /**
     * 
     * @type {Array<FlowTrafficPolicy>}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'traffic_policies'?: Array<FlowTrafficPolicy>;
    /**
     * 
     * @type {FlowTrafficPolicy}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'active_traffic_policy'?: FlowTrafficPolicy;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'ui_default_decision_fields_exclude': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'ui_default_inputs_include': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'ui_default_outputs_include': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowDbShallowSansLinkedFlows
     */
    'ui_default_outcomes_include': Array<string>;
}
/**
 * 
 * @export
 * @interface FlowDbShallowVersionNoPolicy
 */
export interface FlowDbShallowVersionNoPolicy {
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowVersionNoPolicy
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowVersionNoPolicy
     */
    'updated_at': string;
    /**
     * 
     * @type {FlowMeta}
     * @memberof FlowDbShallowVersionNoPolicy
     */
    'meta'?: FlowMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowVersionNoPolicy
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowVersionNoPolicy
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowVersionNoPolicy
     */
    'workspace_id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowVersionNoPolicy
     */
    'flow_folder_id'?: string;
    /**
     * 
     * @type {FlowReviewConfiguration}
     * @memberof FlowDbShallowVersionNoPolicy
     */
    'review_configuration'?: FlowReviewConfiguration;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowVersionNoPolicy
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowVersionNoPolicy
     */
    'default_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowDbShallowVersionNoPolicy
     */
    'default_sandbox_version'?: string;
    /**
     * 
     * @type {Array<FlowVersionDbShallow>}
     * @memberof FlowDbShallowVersionNoPolicy
     */
    'versions'?: Array<FlowVersionDbShallow>;
}
/**
 * 
 * @export
 * @interface FlowDuplicate
 */
export interface FlowDuplicate {
    /**
     * 
     * @type {string}
     * @memberof FlowDuplicate
     */
    'new_name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDuplicate
     */
    'new_slug': string;
    /**
     * 
     * @type {string}
     * @memberof FlowDuplicate
     */
    'target_workspace_id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowDuplicate
     */
    'flow_version_ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FlowDuplicate
     */
    'flow_folder_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FlowDuplicate
     */
    'copy_comments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FlowDuplicate
     */
    'list_datasets'?: boolean;
    /**
     * 
     * @type {{ [key: string]: FlowNaming; }}
     * @memberof FlowDuplicate
     */
    'child_flow_namings'?: { [key: string]: FlowNaming; };
    /**
     * 
     * @type {string}
     * @memberof FlowDuplicate
     */
    'created_by_avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowDuplicate
     */
    'created_by'?: string;
}
/**
 * 
 * @export
 * @interface FlowFolderCreate
 */
export interface FlowFolderCreate {
    /**
     * 
     * @type {string}
     * @memberof FlowFolderCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowFolderCreate
     */
    'workspace_id': string;
}
/**
 * 
 * @export
 * @interface FlowFolderDb
 */
export interface FlowFolderDb {
    /**
     * 
     * @type {string}
     * @memberof FlowFolderDb
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowFolderDb
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowFolderDb
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowFolderDb
     */
    'workspace_id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowFolderDb
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface FlowFolderListItem
 */
export interface FlowFolderListItem {
    /**
     * 
     * @type {string}
     * @memberof FlowFolderListItem
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowFolderListItem
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowFolderListItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowFolderListItem
     */
    'workspace_id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowFolderListItem
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof FlowFolderListItem
     */
    'flow_count': number;
}
/**
 * 
 * @export
 * @interface FlowFolderListResponse
 */
export interface FlowFolderListResponse {
    /**
     * 
     * @type {Array<FlowFolderListItem>}
     * @memberof FlowFolderListResponse
     */
    'folders': Array<FlowFolderListItem>;
    /**
     * 
     * @type {number}
     * @memberof FlowFolderListResponse
     */
    'flows_outside_folders_count': number;
}
/**
 * 
 * @export
 * @interface FlowFolderUpdate
 */
export interface FlowFolderUpdate {
    /**
     * 
     * @type {string}
     * @memberof FlowFolderUpdate
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FlowLoopMeta
 */
export interface FlowLoopMeta {
    /**
     * 
     * @type {string}
     * @memberof FlowLoopMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowLoopMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowLoopMeta
     */
    'child_flow_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowLoopMeta
     */
    'child_flow_slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowLoopMeta
     */
    'child_flow_version_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowLoopMeta
     */
    'child_flow_version_known_etag'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FlowLoopMeta
     */
    'is_stale'?: boolean;
    /**
     * 
     * @type {{ [key: string]: FlowMapping; }}
     * @memberof FlowLoopMeta
     */
    'input_mappings': { [key: string]: FlowMapping; };
    /**
     * 
     * @type {BaseInputField}
     * @memberof FlowLoopMeta
     */
    'target_list_expression': BaseInputField;
    /**
     * 
     * @type {BaseInputField}
     * @memberof FlowLoopMeta
     */
    'output_destination_list_expression': BaseInputField;
    /**
     * 
     * @type {BreakLoop}
     * @memberof FlowLoopMeta
     */
    'break_condition': BreakLoop;
}
/**
 * 
 * @export
 * @interface FlowMapping
 */
export interface FlowMapping {
    /**
     * 
     * @type {string}
     * @memberof FlowMapping
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowMapping
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof FlowMapping
     */
    'destination': string;
}
/**
 * 
 * @export
 * @interface FlowMeta
 */
export interface FlowMeta {
    /**
     * 
     * @type {string}
     * @memberof FlowMeta
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowMeta
     */
    'updated_by_id'?: string;
}
/**
 * 
 * @export
 * @interface FlowNaming
 */
export interface FlowNaming {
    /**
     * 
     * @type {string}
     * @memberof FlowNaming
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowNaming
     */
    'slug': string;
}
/**
 * 
 * @export
 * @interface FlowNodeMeta
 */
export interface FlowNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof FlowNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowNodeMeta
     */
    'child_flow_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowNodeMeta
     */
    'child_flow_slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowNodeMeta
     */
    'child_flow_version_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowNodeMeta
     */
    'child_flow_version_known_etag'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FlowNodeMeta
     */
    'is_stale'?: boolean;
    /**
     * 
     * @type {{ [key: string]: FlowMapping; }}
     * @memberof FlowNodeMeta
     */
    'input_mappings': { [key: string]: FlowMapping; };
    /**
     * 
     * @type {{ [key: string]: FlowMapping; }}
     * @memberof FlowNodeMeta
     */
    'output_mappings': { [key: string]: FlowMapping; };
}
/**
 * 
 * @export
 * @interface FlowReviewConfiguration
 */
export interface FlowReviewConfiguration {
    /**
     * 
     * @type {boolean}
     * @memberof FlowReviewConfiguration
     */
    'requires_review'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FlowReviewConfiguration
     */
    'require_all_reviewers'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowReviewConfiguration
     */
    'default_reviewer_list'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FlowTrafficPolicy
 */
export interface FlowTrafficPolicy {
    /**
     * 
     * @type {string}
     * @memberof FlowTrafficPolicy
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowTrafficPolicy
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowTrafficPolicy
     */
    'created_by_user_id': string;
    /**
     * 
     * @type {ProbabilisticPolicy}
     * @memberof FlowTrafficPolicy
     */
    'policy': ProbabilisticPolicy;
}
/**
 * 
 * @export
 * @interface FlowTrafficPolicyProbabilisticVersionEntry
 */
export interface FlowTrafficPolicyProbabilisticVersionEntry {
    /**
     * 
     * @type {string}
     * @memberof FlowTrafficPolicyProbabilisticVersionEntry
     */
    'flow_version_id': string;
    /**
     * 
     * @type {number}
     * @memberof FlowTrafficPolicyProbabilisticVersionEntry
     */
    'percentage': number;
}
/**
 * 
 * @export
 * @interface FlowUpdate
 */
export interface FlowUpdate {
    /**
     * 
     * @type {FlowMeta}
     * @memberof FlowUpdate
     */
    'meta'?: FlowMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowUpdate
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowUpdate
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowUpdate
     */
    'workspace_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowUpdate
     */
    'flow_folder_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowUpdate
     */
    'default_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowUpdate
     */
    'default_sandbox_version'?: string;
    /**
     * 
     * @type {FlowReviewConfiguration}
     * @memberof FlowUpdate
     */
    'review_configuration'?: FlowReviewConfiguration;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowUpdate
     */
    'ui_default_decision_fields_exclude'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowUpdate
     */
    'ui_default_inputs_include'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowUpdate
     */
    'ui_default_outputs_include'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowUpdate
     */
    'ui_default_outcomes_include'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FlowVersionCreate
 */
export interface FlowVersionCreate {
    /**
     * 
     * @type {FlowVersionMeta}
     * @memberof FlowVersionCreate
     */
    'meta'?: FlowVersionMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionCreate
     */
    'etag'?: string;
    /**
     * 
     * @type {object}
     * @memberof FlowVersionCreate
     */
    'input_schema'?: object;
    /**
     * 
     * @type {object}
     * @memberof FlowVersionCreate
     */
    'output_schema'?: object;
    /**
     * 
     * @type {Array<Parameter>}
     * @memberof FlowVersionCreate
     */
    'parameters'?: Array<Parameter>;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionCreate
     */
    'flow_id': string;
    /**
     * 
     * @type {FlowVersionStatus}
     * @memberof FlowVersionCreate
     */
    'status'?: FlowVersionStatus;
}


/**
 * 
 * @export
 * @interface FlowVersionDb
 */
export interface FlowVersionDb {
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDb
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDb
     */
    'updated_at': string;
    /**
     * 
     * @type {FlowVersionMeta}
     * @memberof FlowVersionDb
     */
    'meta'?: FlowVersionMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDb
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDb
     */
    'name': string;
    /**
     * 
     * @type {FlowVersionStatus}
     * @memberof FlowVersionDb
     */
    'status': FlowVersionStatus;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDb
     */
    'graph_id'?: string;
    /**
     * 
     * @type {GraphDb}
     * @memberof FlowVersionDb
     */
    'graph'?: GraphDb;
    /**
     * 
     * @type {Array<ResourceLock>}
     * @memberof FlowVersionDb
     */
    'locks'?: Array<ResourceLock>;
    /**
     * 
     * @type {Array<FlowVersionDbShallowWithSchemas>}
     * @memberof FlowVersionDb
     */
    'child_versions'?: Array<FlowVersionDbShallowWithSchemas>;
    /**
     * 
     * @type {Array<FlowVersionDbShallowWithSchemas>}
     * @memberof FlowVersionDb
     */
    'parent_versions'?: Array<FlowVersionDbShallowWithSchemas>;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDb
     */
    'etag'?: string;
    /**
     * 
     * @type {object}
     * @memberof FlowVersionDb
     */
    'input_schema'?: object;
    /**
     * 
     * @type {object}
     * @memberof FlowVersionDb
     */
    'output_schema'?: object;
    /**
     * 
     * @type {Array<Parameter>}
     * @memberof FlowVersionDb
     */
    'parameters'?: Array<Parameter>;
}


/**
 * 
 * @export
 * @interface FlowVersionDbDataplane
 */
export interface FlowVersionDbDataplane {
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbDataplane
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbDataplane
     */
    'updated_at': string;
    /**
     * 
     * @type {FlowVersionMeta}
     * @memberof FlowVersionDbDataplane
     */
    'meta'?: FlowVersionMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbDataplane
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbDataplane
     */
    'name': string;
    /**
     * 
     * @type {FlowVersionStatus}
     * @memberof FlowVersionDbDataplane
     */
    'status': FlowVersionStatus;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbDataplane
     */
    'api_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbDataplane
     */
    'etag'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbDataplane
     */
    'graph_id'?: string;
    /**
     * 
     * @type {GraphDbDataplane}
     * @memberof FlowVersionDbDataplane
     */
    'graph'?: GraphDbDataplane;
    /**
     * 
     * @type {Array<FlowVersionDbDataplane>}
     * @memberof FlowVersionDbDataplane
     */
    'child_versions'?: Array<FlowVersionDbDataplane>;
    /**
     * 
     * @type {object}
     * @memberof FlowVersionDbDataplane
     */
    'input_schema'?: object;
    /**
     * 
     * @type {object}
     * @memberof FlowVersionDbDataplane
     */
    'output_schema'?: object;
    /**
     * 
     * @type {Array<Parameter>}
     * @memberof FlowVersionDbDataplane
     */
    'parameters'?: Array<Parameter>;
}


/**
 * 
 * @export
 * @interface FlowVersionDbShallow
 */
export interface FlowVersionDbShallow {
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallow
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallow
     */
    'updated_at': string;
    /**
     * 
     * @type {FlowVersionMeta}
     * @memberof FlowVersionDbShallow
     */
    'meta'?: FlowVersionMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallow
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallow
     */
    'name': string;
    /**
     * 
     * @type {FlowVersionStatus}
     * @memberof FlowVersionDbShallow
     */
    'status': FlowVersionStatus;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallow
     */
    'api_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallow
     */
    'etag'?: string;
    /**
     * 
     * @type {ReviewShallow}
     * @memberof FlowVersionDbShallow
     */
    'review'?: ReviewShallow;
}


/**
 * 
 * @export
 * @interface FlowVersionDbShallowChildren
 */
export interface FlowVersionDbShallowChildren {
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallowChildren
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallowChildren
     */
    'updated_at': string;
    /**
     * 
     * @type {FlowVersionMeta}
     * @memberof FlowVersionDbShallowChildren
     */
    'meta'?: FlowVersionMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallowChildren
     */
    'etag'?: string;
    /**
     * 
     * @type {object}
     * @memberof FlowVersionDbShallowChildren
     */
    'input_schema'?: object;
    /**
     * 
     * @type {object}
     * @memberof FlowVersionDbShallowChildren
     */
    'output_schema'?: object;
    /**
     * 
     * @type {Array<Parameter>}
     * @memberof FlowVersionDbShallowChildren
     */
    'parameters'?: Array<Parameter>;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallowChildren
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallowChildren
     */
    'name': string;
    /**
     * 
     * @type {FlowVersionStatus}
     * @memberof FlowVersionDbShallowChildren
     */
    'status': FlowVersionStatus;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallowChildren
     */
    'api_version'?: string;
    /**
     * 
     * @type {Array<FlowDbShallow>}
     * @memberof FlowVersionDbShallowChildren
     */
    'child_flows'?: Array<FlowDbShallow>;
    /**
     * 
     * @type {Array<FlowDbShallow>}
     * @memberof FlowVersionDbShallowChildren
     */
    'parent_flows'?: Array<FlowDbShallow>;
    /**
     * 
     * @type {ReviewShallow}
     * @memberof FlowVersionDbShallowChildren
     */
    'review'?: ReviewShallow;
}


/**
 * 
 * @export
 * @interface FlowVersionDbShallowWithSchemas
 */
export interface FlowVersionDbShallowWithSchemas {
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallowWithSchemas
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallowWithSchemas
     */
    'updated_at': string;
    /**
     * 
     * @type {FlowVersionMeta}
     * @memberof FlowVersionDbShallowWithSchemas
     */
    'meta'?: FlowVersionMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallowWithSchemas
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallowWithSchemas
     */
    'name': string;
    /**
     * 
     * @type {FlowVersionStatus}
     * @memberof FlowVersionDbShallowWithSchemas
     */
    'status': FlowVersionStatus;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallowWithSchemas
     */
    'api_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDbShallowWithSchemas
     */
    'etag'?: string;
    /**
     * 
     * @type {ReviewShallow}
     * @memberof FlowVersionDbShallowWithSchemas
     */
    'review'?: ReviewShallow;
    /**
     * 
     * @type {object}
     * @memberof FlowVersionDbShallowWithSchemas
     */
    'input_schema'?: object;
    /**
     * 
     * @type {object}
     * @memberof FlowVersionDbShallowWithSchemas
     */
    'output_schema'?: object;
}


/**
 * 
 * @export
 * @interface FlowVersionDuplicate
 */
export interface FlowVersionDuplicate {
    /**
     * 
     * @type {Meta}
     * @memberof FlowVersionDuplicate
     */
    'meta'?: Meta;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDuplicate
     */
    'name': string;
    /**
     * 
     * @type {FlowVersionStatus}
     * @memberof FlowVersionDuplicate
     */
    'status'?: FlowVersionStatus;
    /**
     * 
     * @type {boolean}
     * @memberof FlowVersionDuplicate
     */
    'include_comments'?: boolean;
}


/**
 * 
 * @export
 * @interface FlowVersionDuplicateNewFlow
 */
export interface FlowVersionDuplicateNewFlow {
    /**
     * 
     * @type {Meta}
     * @memberof FlowVersionDuplicateNewFlow
     */
    'meta'?: Meta;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDuplicateNewFlow
     */
    'name': string;
    /**
     * 
     * @type {FlowVersionStatus}
     * @memberof FlowVersionDuplicateNewFlow
     */
    'status'?: FlowVersionStatus;
    /**
     * 
     * @type {boolean}
     * @memberof FlowVersionDuplicateNewFlow
     */
    'include_comments'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionDuplicateNewFlow
     */
    'flow_id': string;
}


/**
 * 
 * @export
 * @interface FlowVersionMeta
 */
export interface FlowVersionMeta {
    /**
     * 
     * @type {string}
     * @memberof FlowVersionMeta
     */
    'created_by_avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionMeta
     */
    'published_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionMeta
     */
    'published_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionMeta
     */
    'published_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionMeta
     */
    'published_by_avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionMeta
     */
    'release_note'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionMeta
     */
    'created_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionMeta
     */
    'archived_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionMeta
     */
    'archived_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionMeta
     */
    'archived_by_avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionMeta
     */
    'archived_at'?: string;
}
/**
 * States of a flow version status
 * @export
 * @enum {string}
 */

export enum FlowVersionStatus {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
    ARCHIVED = 'ARCHIVED'
}


/**
 * 
 * @export
 * @interface FlowVersionUpdate
 */
export interface FlowVersionUpdate {
    /**
     * 
     * @type {FlowVersionMeta}
     * @memberof FlowVersionUpdate
     */
    'meta'?: FlowVersionMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionUpdate
     */
    'etag'?: string;
    /**
     * 
     * @type {object}
     * @memberof FlowVersionUpdate
     */
    'input_schema'?: object;
    /**
     * 
     * @type {object}
     * @memberof FlowVersionUpdate
     */
    'output_schema'?: object;
    /**
     * 
     * @type {Array<Parameter>}
     * @memberof FlowVersionUpdate
     */
    'parameters'?: Array<Parameter>;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionUpdate
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionUpdate
     */
    'graph_id'?: string;
    /**
     * 
     * @type {GraphMergePatch}
     * @memberof FlowVersionUpdate
     */
    'graph'?: GraphMergePatch;
    /**
     * 
     * @type {FlowVersionStatus}
     * @memberof FlowVersionUpdate
     */
    'status'?: FlowVersionStatus;
    /**
     * 
     * @type {{ [key: string]: ResourceLockUpsert; }}
     * @memberof FlowVersionUpdate
     */
    'locks'?: { [key: string]: ResourceLockUpsert; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FlowVersionUpdate
     */
    'deleted_nodes_names'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FlowVersionUpdate
     */
    'deleted_nodegroups_names'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof FlowVersionUpdate
     */
    'operation'?: number;
}


/**
 * 
 * @export
 * @interface FlowVersionV2
 */
export interface FlowVersionV2 {
    /**
     * 
     * @type {string}
     * @memberof FlowVersionV2
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionV2
     */
    'updated_at': string;
    /**
     * 
     * @type {FlowVersionMeta}
     * @memberof FlowVersionV2
     */
    'meta'?: FlowVersionMeta;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionV2
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionV2
     */
    'name': string;
    /**
     * 
     * @type {FlowVersionStatus}
     * @memberof FlowVersionV2
     */
    'status': FlowVersionStatus;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionV2
     */
    'api_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionV2
     */
    'etag': string;
    /**
     * 
     * @type {ReviewShallow}
     * @memberof FlowVersionV2
     */
    'review'?: ReviewShallow;
    /**
     * 
     * @type {string}
     * @memberof FlowVersionV2
     */
    'graph_id'?: string;
    /**
     * 
     * @type {Array<Parameter>}
     * @memberof FlowVersionV2
     */
    'parameters': Array<Parameter>;
    /**
     * 
     * @type {GraphV2}
     * @memberof FlowVersionV2
     */
    'graph': GraphV2;
    /**
     * 
     * @type {object}
     * @memberof FlowVersionV2
     */
    'input_schema'?: object;
    /**
     * 
     * @type {object}
     * @memberof FlowVersionV2
     */
    'output_schema'?: object;
    /**
     * 
     * @type {Array<ResourceLock>}
     * @memberof FlowVersionV2
     */
    'locks': Array<ResourceLock>;
    /**
     * 
     * @type {number}
     * @memberof FlowVersionV2
     */
    'operation'?: number;
}


/**
 * 
 * @export
 * @interface GraphDb
 */
export interface GraphDb {
    /**
     * 
     * @type {string}
     * @memberof GraphDb
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof GraphDb
     */
    'updated_at': string;
    /**
     * 
     * @type {GraphMeta}
     * @memberof GraphDb
     */
    'meta'?: GraphMeta;
    /**
     * 
     * @type {string}
     * @memberof GraphDb
     */
    'id': string;
    /**
     * 
     * @type {Array<NodeDb>}
     * @memberof GraphDb
     */
    'nodes'?: Array<NodeDb>;
    /**
     * 
     * @type {Array<EdgeDb>}
     * @memberof GraphDb
     */
    'edges'?: Array<EdgeDb>;
    /**
     * 
     * @type {Array<NodeGroupDb>}
     * @memberof GraphDb
     */
    'node_groups'?: Array<NodeGroupDb>;
}
/**
 * 
 * @export
 * @interface GraphDbDataplane
 */
export interface GraphDbDataplane {
    /**
     * 
     * @type {string}
     * @memberof GraphDbDataplane
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof GraphDbDataplane
     */
    'updated_at': string;
    /**
     * 
     * @type {GraphMeta}
     * @memberof GraphDbDataplane
     */
    'meta'?: GraphMeta;
    /**
     * 
     * @type {string}
     * @memberof GraphDbDataplane
     */
    'id': string;
    /**
     * 
     * @type {Array<NodeDb>}
     * @memberof GraphDbDataplane
     */
    'nodes'?: Array<NodeDb>;
    /**
     * 
     * @type {Array<EdgeDb>}
     * @memberof GraphDbDataplane
     */
    'edges'?: Array<EdgeDb>;
}
/**
 * 
 * @export
 * @interface GraphMergePatch
 */
export interface GraphMergePatch {
    /**
     * 
     * @type {{ [key: string]: NodeUpsert; }}
     * @memberof GraphMergePatch
     */
    'nodes'?: { [key: string]: NodeUpsert; };
    /**
     * 
     * @type {{ [key: string]: EdgeUpsert; }}
     * @memberof GraphMergePatch
     */
    'edges'?: { [key: string]: EdgeUpsert; };
    /**
     * 
     * @type {{ [key: string]: NodeGroupUpsert; }}
     * @memberof GraphMergePatch
     */
    'node_groups'?: { [key: string]: NodeGroupUpsert; };
}
/**
 * 
 * @export
 * @interface GraphMeta
 */
export interface GraphMeta {
    /**
     * 
     * @type {string}
     * @memberof GraphMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GraphMeta
     */
    'updated_by_id'?: string;
}
/**
 * 
 * @export
 * @interface GraphV2
 */
export interface GraphV2 {
    /**
     * 
     * @type {{ [key: string]: NodeDb; }}
     * @memberof GraphV2
     */
    'nodes': { [key: string]: NodeDb; };
    /**
     * 
     * @type {{ [key: string]: EdgeDb; }}
     * @memberof GraphV2
     */
    'edges': { [key: string]: EdgeDb; };
    /**
     * 
     * @type {{ [key: string]: NodeGroupDb; }}
     * @memberof GraphV2
     */
    'node_groups': { [key: string]: NodeGroupDb; };
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface InputAttribute
 */
export interface InputAttribute {
    /**
     * 
     * @type {string}
     * @memberof InputAttribute
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InputAttribute
     */
    'expression': string;
}
/**
 * 
 * @export
 * @interface IntegrationNodeMeta
 */
export interface IntegrationNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof IntegrationNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof IntegrationNodeMeta
     */
    'provider_resource': object;
    /**
     * 
     * @type {string}
     * @memberof IntegrationNodeMeta
     */
    'connection_id': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationNodeMeta
     */
    'resource_config_id': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationNodeMeta
     */
    'media_key'?: string;
    /**
     * 
     * @type {object}
     * @memberof IntegrationNodeMeta
     */
    'config': object;
    /**
     * 
     * @type {object}
     * @memberof IntegrationNodeMeta
     */
    'input': object;
    /**
     * 
     * @type {object}
     * @memberof IntegrationNodeMeta
     */
    'output': object;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum JobRunEventAttributeStatus {
    COMPLETED = 'completed',
    FAILED = 'failed'
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum Junctions {
    AND = 'and',
    OR = 'or'
}


/**
 * 
 * @export
 * @interface KeyValuePair
 */
export interface KeyValuePair {
    /**
     * 
     * @type {string}
     * @memberof KeyValuePair
     */
    'id_key': string;
    /**
     * 
     * @type {string}
     * @memberof KeyValuePair
     */
    'id_value': string;
    /**
     * 
     * @type {string}
     * @memberof KeyValuePair
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof KeyValuePair
     */
    'value': string;
}
/**
 * Body value for the key_values input.
 * @export
 * @interface KeyValuesBodyField
 */
export interface KeyValuesBodyField {
    /**
     * 
     * @type {string}
     * @memberof KeyValuesBodyField
     */
    'selected': KeyValuesBodyFieldSelectedEnum;
    /**
     * 
     * @type {Array<KeyValuePair>}
     * @memberof KeyValuesBodyField
     */
    'key_values': Array<KeyValuePair>;
}

/**
    * @export
    * @enum {string}
    */
export enum KeyValuesBodyFieldSelectedEnum {
    KEY_VALUES = 'key_values'
}

/**
 * 
 * @export
 * @interface MLNodeMeta
 */
export interface MLNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof MLNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MLNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MLNodeMeta
     */
    'src': string;
    /**
     * 
     * @type {string}
     * @memberof MLNodeMeta
     */
    'model_filename': string;
    /**
     * 
     * @type {string}
     * @memberof MLNodeMeta
     */
    'model_id': string;
    /**
     * 
     * @type {string}
     * @memberof MLNodeMeta
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof MLNodeMeta
     */
    'graph_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MLNodeMeta
     */
    'producer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MLNodeMeta
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof MLNodeMeta
     */
    'author'?: string;
    /**
     * 
     * @type {string}
     * @memberof MLNodeMeta
     */
    'training_data'?: string;
    /**
     * 
     * @type {Array<MLNodeTensor>}
     * @memberof MLNodeMeta
     */
    'inputs'?: Array<MLNodeTensor>;
    /**
     * 
     * @type {Array<MLNodeTensor>}
     * @memberof MLNodeMeta
     */
    'outputs'?: Array<MLNodeTensor>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MLNodeMeta
     */
    'custom_metadata'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface MLNodeTensor
 */
export interface MLNodeTensor {
    /**
     * 
     * @type {string}
     * @memberof MLNodeTensor
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MLNodeTensor
     */
    'shape': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof MLNodeTensor
     */
    'dtype': string;
}
/**
 * 
 * @export
 * @interface ManifestConnectionNodeMeta
 */
export interface ManifestConnectionNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof ManifestConnectionNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManifestConnectionNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {ManifestProviderResource}
     * @memberof ManifestConnectionNodeMeta
     */
    'provider_resource': ManifestProviderResource;
    /**
     * 
     * @type {string}
     * @memberof ManifestConnectionNodeMeta
     */
    'connection_id': string;
    /**
     * 
     * @type {string}
     * @memberof ManifestConnectionNodeMeta
     */
    'resource_config_id': string;
    /**
     * 
     * @type {string}
     * @memberof ManifestConnectionNodeMeta
     */
    'media_key'?: string;
    /**
     * 
     * @type {object}
     * @memberof ManifestConnectionNodeMeta
     */
    'config': object;
    /**
     * 
     * @type {object}
     * @memberof ManifestConnectionNodeMeta
     */
    'input': object;
    /**
     * 
     * @type {object}
     * @memberof ManifestConnectionNodeMeta
     */
    'output': object;
}
/**
 * 
 * @export
 * @interface ManifestProviderResource
 */
export interface ManifestProviderResource {
    /**
     * 
     * @type {string}
     * @memberof ManifestProviderResource
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof ManifestProviderResource
     */
    'resource': string;
    /**
     * 
     * @type {string}
     * @memberof ManifestProviderResource
     */
    'manifest_version': string;
}
/**
 * 
 * @export
 * @interface ManualReviewDescription
 */
export interface ManualReviewDescription {
    /**
     * 
     * @type {string}
     * @memberof ManualReviewDescription
     */
    'type': ManualReviewDescriptionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ManualReviewDescription
     */
    'id_value': string;
    /**
     * 
     * @type {string}
     * @memberof ManualReviewDescription
     */
    'value': string;
}

/**
    * @export
    * @enum {string}
    */
export enum ManualReviewDescriptionTypeEnum {
    DESCRIPTION = 'description'
}

/**
 * 
 * @export
 * @interface ManualReviewDivider
 */
export interface ManualReviewDivider {
    /**
     * 
     * @type {string}
     * @memberof ManualReviewDivider
     */
    'type': ManualReviewDividerTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ManualReviewDividerTypeEnum {
    DIVIDER = 'divider'
}

/**
 * 
 * @export
 * @interface ManualReviewField
 */
export interface ManualReviewField {
    /**
     * 
     * @type {string}
     * @memberof ManualReviewField
     */
    'type': ManualReviewFieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ManualReviewField
     */
    'id_readable_name': string;
    /**
     * 
     * @type {string}
     * @memberof ManualReviewField
     */
    'id_value': string;
    /**
     * 
     * @type {string}
     * @memberof ManualReviewField
     */
    'readable_name': string;
    /**
     * 
     * @type {string}
     * @memberof ManualReviewField
     */
    'value': string;
}

/**
    * @export
    * @enum {string}
    */
export enum ManualReviewFieldTypeEnum {
    FIELD = 'field'
}

/**
 * Connect Manual Review Node Meta.
 * @export
 * @interface ManualReviewNodeMeta
 */
export interface ManualReviewNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof ManualReviewNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManualReviewNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof ManualReviewNodeMeta
     */
    'provider_resource': object;
    /**
     * 
     * @type {string}
     * @memberof ManualReviewNodeMeta
     */
    'connection_id': string;
    /**
     * 
     * @type {string}
     * @memberof ManualReviewNodeMeta
     */
    'resource_config_id': string;
    /**
     * 
     * @type {Array<ManualReviewNodeMetaHighlightsInner>}
     * @memberof ManualReviewNodeMeta
     */
    'highlights': Array<ManualReviewNodeMetaHighlightsInner>;
    /**
     * 
     * @type {ManualReviewResponseForm}
     * @memberof ManualReviewNodeMeta
     */
    'response_form': ManualReviewResponseForm;
    /**
     * 
     * @type {ManualReviewReviewerAssignStrategy}
     * @memberof ManualReviewNodeMeta
     */
    'reviewer_assign_strategy'?: ManualReviewReviewerAssignStrategy;
    /**
     * 
     * @type {ReviewerAssignStrategyV2}
     * @memberof ManualReviewNodeMeta
     */
    'reviewer_assign_strategy_v2'?: ReviewerAssignStrategyV2;
    /**
     * 
     * @type {string}
     * @memberof ManualReviewNodeMeta
     */
    'response_metadata_key'?: string;
}


/**
 * @type ManualReviewNodeMetaHighlightsInner
 * @export
 */
export type ManualReviewNodeMetaHighlightsInner = { type: 'description' } & ManualReviewDescription | { type: 'divider' } & ManualReviewDivider | { type: 'field' } & ManualReviewField;

/**
 * 
 * @export
 * @interface ManualReviewResponseForm
 */
export interface ManualReviewResponseForm {
    /**
     * 
     * @type {string}
     * @memberof ManualReviewResponseForm
     */
    'description': string;
    /**
     * 
     * @type {Array<ManualReviewResponseFormField>}
     * @memberof ManualReviewResponseForm
     */
    'fields'?: Array<ManualReviewResponseFormField>;
}
/**
 * 
 * @export
 * @interface ManualReviewResponseFormField
 */
export interface ManualReviewResponseFormField {
    /**
     * 
     * @type {string}
     * @memberof ManualReviewResponseFormField
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ManualReviewResponseFormField
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManualReviewResponseFormField
     */
    'type': ManualReviewResponseFormFieldTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ManualReviewResponseFormField
     */
    'required': boolean;
    /**
     * 
     * @type {string}
     * @memberof ManualReviewResponseFormField
     */
    'name': string;
    /**
     * 
     * @type {Array<ManualReviewResponseFormFieldEnumInner>}
     * @memberof ManualReviewResponseFormField
     */
    'enum'?: Array<ManualReviewResponseFormFieldEnumInner>;
}

/**
    * @export
    * @enum {string}
    */
export enum ManualReviewResponseFormFieldTypeEnum {
    STRING = 'string',
    NUMBER = 'number',
    INTEGER = 'integer',
    BOOLEAN = 'boolean',
    ENUM = 'enum'
}

/**
 * 
 * @export
 * @interface ManualReviewResponseFormFieldEnumInner
 */
export interface ManualReviewResponseFormFieldEnumInner {
}
/**
 * For backwards compatibility.
 * @export
 * @enum {string}
 */

export enum ManualReviewReviewerAssignStrategy {
    ANY = 'ANY',
    REVIEWER_ONLY = 'REVIEWER_ONLY'
}


/**
 * 
 * @export
 * @interface MergeNodeMeta
 */
export interface MergeNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof MergeNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MergeNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MergeNodeMeta
     */
    'src': string;
    /**
     * 
     * @type {boolean}
     * @memberof MergeNodeMeta
     */
    'is_split_merge_node': boolean;
}
/**
 * 
 * @export
 * @interface Meta
 */
export interface Meta {
}
/**
 * 
 * @export
 * @interface Meta1
 */
export interface Meta1 {
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'created_by_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'updated_by_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'src': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'is_split_merge_node': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'is_split_branch_node': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'branches': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'default_edge_id': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'default_effects': any;
    /**
     * 
     * @type {DecisionTableField}
     * @memberof Meta1
     */
    'assign_field': DecisionTableField;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'extract_scores': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'equal_weights': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'factors': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'child_flow_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'child_flow_slug'?: any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'child_flow_version_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'child_flow_version_known_etag'?: any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'is_stale'?: any;
    /**
     * 
     * @type {{ [key: string]: FlowMapping; }}
     * @memberof Meta1
     */
    'input_mappings': { [key: string]: FlowMapping; };
    /**
     * 
     * @type {BaseInputField}
     * @memberof Meta1
     */
    'target_list_expression': BaseInputField;
    /**
     * 
     * @type {BaseInputField}
     * @memberof Meta1
     */
    'output_destination_list_expression': BaseInputField;
    /**
     * 
     * @type {BreakLoop}
     * @memberof Meta1
     */
    'break_condition': BreakLoop;
    /**
     * 
     * @type {{ [key: string]: FlowMapping; }}
     * @memberof Meta1
     */
    'output_mappings': { [key: string]: FlowMapping; };
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'predicate_fields': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'effect_fields': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'cases': any;
    /**
     * 
     * @type {{ [key: string]: DecisionTableEffect; }}
     * @memberof Meta1
     */
    'fallback': { [key: string]: DecisionTableEffect; };
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'model_filename': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'model_id': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'description'?: any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'graph_name'?: any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'producer_name'?: any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'version'?: any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'author'?: any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'training_data'?: any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'inputs'?: any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'outputs'?: any;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Meta1
     */
    'custom_metadata'?: { [key: string]: any; };
    /**
     * 
     * @type {object}
     * @memberof Meta1
     */
    'provider_resource': object;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'connection_id': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'resource_config_id': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'media_key'?: any;
    /**
     * 
     * @type {object}
     * @memberof Meta1
     */
    'config': object;
    /**
     * 
     * @type {object}
     * @memberof Meta1
     */
    'input': object;
    /**
     * 
     * @type {object}
     * @memberof Meta1
     */
    'output': object;
    /**
     * 
     * @type {InputAttribute}
     * @memberof Meta1
     */
    'correlation_id': InputAttribute;
    /**
     * 
     * @type {object}
     * @memberof Meta1
     */
    'response': object;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'variables': any;
    /**
     * 
     * @type {InputAttribute}
     * @memberof Meta1
     */
    'query': InputAttribute;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'verb': any;
    /**
     * 
     * @type {InputAttribute}
     * @memberof Meta1
     */
    'path': InputAttribute;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'params': any;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'headers': any;
    /**
     * 
     * @type {CustomConnectionBody}
     * @memberof Meta1
     */
    'body': CustomConnectionBody;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'highlights': any;
    /**
     * 
     * @type {ManualReviewResponseForm}
     * @memberof Meta1
     */
    'response_form': ManualReviewResponseForm;
    /**
     * 
     * @type {ManualReviewReviewerAssignStrategy}
     * @memberof Meta1
     */
    'reviewer_assign_strategy'?: ManualReviewReviewerAssignStrategy;
    /**
     * 
     * @type {ReviewerAssignStrategyV2}
     * @memberof Meta1
     */
    'reviewer_assign_strategy_v2'?: ReviewerAssignStrategyV2;
    /**
     * 
     * @type {any}
     * @memberof Meta1
     */
    'response_metadata_key'?: any;
}


/**
 * 
 * @export
 * @interface NodeCommentSummary
 */
export interface NodeCommentSummary {
    /**
     * 
     * @type {string}
     * @memberof NodeCommentSummary
     */
    'node_id': string;
    /**
     * 
     * @type {number}
     * @memberof NodeCommentSummary
     */
    'comments': number;
}
/**
 * 
 * @export
 * @interface NodeDb
 */
export interface NodeDb {
    /**
     * 
     * @type {Meta1}
     * @memberof NodeDb
     */
    'meta'?: Meta1;
    /**
     * 
     * @type {string}
     * @memberof NodeDb
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NodeDb
     */
    'id': string;
    /**
     * 
     * @type {NodeTypes}
     * @memberof NodeDb
     */
    'type': NodeTypes;
}


/**
 * 
 * @export
 * @interface NodeGroupDb
 */
export interface NodeGroupDb {
    /**
     * 
     * @type {Meta}
     * @memberof NodeGroupDb
     */
    'meta'?: Meta;
    /**
     * 
     * @type {string}
     * @memberof NodeGroupDb
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NodeGroupDb
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NodeGroupDb
     */
    'node_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface NodeGroupUpsert
 */
export interface NodeGroupUpsert {
    /**
     * 
     * @type {Meta}
     * @memberof NodeGroupUpsert
     */
    'meta'?: Meta;
    /**
     * 
     * @type {string}
     * @memberof NodeGroupUpsert
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeGroupUpsert
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NodeGroupUpsert
     */
    'node_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface NodeMetaBase
 */
export interface NodeMetaBase {
    /**
     * 
     * @type {string}
     * @memberof NodeMetaBase
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeMetaBase
     */
    'updated_by_id'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum NodeTypes {
    INPUT = 'input',
    OUTPUT = 'output',
    TRANSFORM = 'transform',
    RULE_2 = 'rule_2',
    SPLIT_2 = 'split_2',
    DATA_INTEGRATION = 'data_integration',
    ML = 'ml',
    MANIFEST_CONNECTION_NODE = 'manifest_connection_node',
    CUSTOM_CONNECTION_NODE = 'custom_connection_node',
    WEBHOOK_CONNECTION_NODE = 'webhook_connection_node',
    SQL_DATABASE_CONNECTION_NODE = 'sql_database_connection_node',
    ASSIGNMENT_NODE = 'assignment_node',
    DECISION_TABLE = 'decision_table',
    SCORECARD = 'scorecard',
    FLOW = 'flow',
    FLOW_LOOP = 'flow_loop',
    REVIEW_CONNECTION_NODE = 'review_connection_node'
}


/**
 * 
 * @export
 * @interface NodeUpsert
 */
export interface NodeUpsert {
    /**
     * 
     * @type {Meta1}
     * @memberof NodeUpsert
     */
    'meta'?: Meta1;
    /**
     * 
     * @type {string}
     * @memberof NodeUpsert
     */
    'name'?: string;
    /**
     * 
     * @type {NodeTypes}
     * @memberof NodeUpsert
     */
    'type'?: NodeTypes;
}


/**
 * 
 * @export
 * @interface ObservableChartDimensions
 */
export interface ObservableChartDimensions {
    /**
     * 
     * @type {ChartDimension}
     * @memberof ObservableChartDimensions
     */
    'x': ChartDimension;
    /**
     * 
     * @type {ChartDimension}
     * @memberof ObservableChartDimensions
     */
    'y': ChartDimension;
    /**
     * 
     * @type {ChartDimension}
     * @memberof ObservableChartDimensions
     */
    'color': ChartDimension;
    /**
     * 
     * @type {ChartDimension}
     * @memberof ObservableChartDimensions
     */
    'size': ChartDimension;
    /**
     * 
     * @type {ChartDimension}
     * @memberof ObservableChartDimensions
     */
    'fx': ChartDimension;
    /**
     * 
     * @type {ChartDimension}
     * @memberof ObservableChartDimensions
     */
    'fy': ChartDimension;
}
/**
 * 
 * @export
 * @interface OperatorCallbackPayload
 */
export interface OperatorCallbackPayload {
    /**
     * 
     * @type {string}
     * @memberof OperatorCallbackPayload
     */
    'request_id': string;
    /**
     * 
     * @type {string}
     * @memberof OperatorCallbackPayload
     */
    'status': string;
    /**
     * 
     * @type {OperatorOutput}
     * @memberof OperatorCallbackPayload
     */
    'output'?: OperatorOutput;
}
/**
 * 
 * @export
 * @interface OperatorOutput
 */
export interface OperatorOutput {
    /**
     * 
     * @type {string}
     * @memberof OperatorOutput
     */
    'base_url': string;
    /**
     * 
     * @type {string}
     * @memberof OperatorOutput
     */
    'sample_data_bucket': string;
    /**
     * 
     * @type {string}
     * @memberof OperatorOutput
     */
    'kms_key_arn': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum Operators {
    LT = 'lt',
    LE = 'le',
    EQ = 'eq',
    NE = 'ne',
    GE = 'ge',
    GT = 'gt',
    IS_IN = 'is_in',
    NOT_IS_IN = 'not_is_in',
    IS_NONE = 'is_none',
    IS_NOT_NONE = 'is_not_none',
    CONTAINS = 'contains',
    DOES_NOT_CONTAIN = 'does_not_contain',
    MATCHES_REGEX = 'matches_regex'
}


/**
 * 
 * @export
 * @interface OutcomeType
 */
export interface OutcomeType {
    /**
     * 
     * @type {string}
     * @memberof OutcomeType
     */
    'flow_id': string;
    /**
     * 
     * @type {string}
     * @memberof OutcomeType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OutcomeType
     */
    'key': string;
    /**
     * 
     * @type {object}
     * @memberof OutcomeType
     */
    'payload_schema': object;
    /**
     * 
     * @type {string}
     * @memberof OutcomeType
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OutcomeType
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof OutcomeType
     */
    'updated_by': string;
    /**
     * 
     * @type {string}
     * @memberof OutcomeType
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof OutcomeType
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof OutcomeType
     */
    'status'?: OutcomeTypeStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof OutcomeType
     */
    'count'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum OutcomeTypeStatusEnum {
    ACTIVE = 'active',
    DELETING = 'deleting'
}

/**
 * 
 * @export
 * @interface OutcomeTypeCreate
 */
export interface OutcomeTypeCreate {
    /**
     * 
     * @type {string}
     * @memberof OutcomeTypeCreate
     */
    'flow_id': string;
    /**
     * 
     * @type {string}
     * @memberof OutcomeTypeCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OutcomeTypeCreate
     */
    'key': string;
    /**
     * 
     * @type {object}
     * @memberof OutcomeTypeCreate
     */
    'payload_schema': object;
}
/**
 * 
 * @export
 * @interface OutcomeTypePatch
 */
export interface OutcomeTypePatch {
    /**
     * 
     * @type {string}
     * @memberof OutcomeTypePatch
     */
    'name'?: string;
    /**
     * 
     * @type {object}
     * @memberof OutcomeTypePatch
     */
    'payload_schema'?: object;
}
/**
 * 
 * @export
 * @interface Parameter
 */
export interface Parameter {
    /**
     * 
     * @type {string}
     * @memberof Parameter
     */
    'name': string;
    /**
     * 
     * @type {any}
     * @memberof Parameter
     */
    'value'?: any;
    /**
     * 
     * @type {string}
     * @memberof Parameter
     */
    'expr'?: string;
    /**
     * 
     * @type {string}
     * @memberof Parameter
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ProbabilisticPolicy
 */
export interface ProbabilisticPolicy {
    /**
     * 
     * @type {string}
     * @memberof ProbabilisticPolicy
     */
    'type': ProbabilisticPolicyTypeEnum;
    /**
     * 
     * @type {Array<FlowTrafficPolicyProbabilisticVersionEntry>}
     * @memberof ProbabilisticPolicy
     */
    'flow_versions': Array<FlowTrafficPolicyProbabilisticVersionEntry>;
}

/**
    * @export
    * @enum {string}
    */
export enum ProbabilisticPolicyTypeEnum {
    PROBABILISTIC = 'probabilistic'
}

/**
 * Body value for the raw input.
 * @export
 * @interface RawBodyField
 */
export interface RawBodyField {
    /**
     * 
     * @type {string}
     * @memberof RawBodyField
     */
    'selected': RawBodyFieldSelectedEnum;
    /**
     * 
     * @type {string}
     * @memberof RawBodyField
     */
    'raw': string;
}

/**
    * @export
    * @enum {string}
    */
export enum RawBodyFieldSelectedEnum {
    RAW = 'raw'
}

/**
 * 
 * @export
 * @interface ResourceLock
 */
export interface ResourceLock {
    /**
     * 
     * @type {string}
     * @memberof ResourceLock
     */
    'owner': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceLock
     */
    'session': string;
    /**
     * 
     * @type {ResourceType}
     * @memberof ResourceLock
     */
    'resource_type': ResourceType;
    /**
     * 
     * @type {string}
     * @memberof ResourceLock
     */
    'resource_id': string;
    /**
     * 
     * @type {number}
     * @memberof ResourceLock
     */
    'duration_secs': number;
    /**
     * 
     * @type {string}
     * @memberof ResourceLock
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceLock
     */
    'id': string;
}


/**
 * 
 * @export
 * @interface ResourceLockUpsert
 */
export interface ResourceLockUpsert {
    /**
     * 
     * @type {string}
     * @memberof ResourceLockUpsert
     */
    'owner': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceLockUpsert
     */
    'session': string;
    /**
     * 
     * @type {ResourceType}
     * @memberof ResourceLockUpsert
     */
    'resource_type': ResourceType;
    /**
     * 
     * @type {string}
     * @memberof ResourceLockUpsert
     */
    'resource_id': string;
    /**
     * 
     * @type {number}
     * @memberof ResourceLockUpsert
     */
    'duration_secs': number;
    /**
     * 
     * @type {string}
     * @memberof ResourceLockUpsert
     */
    'timestamp'?: string;
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ResourceType {
    FLOW = 'flow',
    FLOW_VERSION = 'flow_version',
    PARAMETER = 'parameter',
    NODE = 'node',
    SCHEMA = 'schema'
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ReviewCommentStatus {
    APPROVED = 'approved',
    REQUESTED_CHANGES = 'requested_changes',
    REVIEW_REREQUESTED = 'review_rerequested',
    COMMENTED = 'commented',
    CANCELLED = 'cancelled'
}


/**
 * 
 * @export
 * @interface ReviewDb
 */
export interface ReviewDb {
    /**
     * 
     * @type {string}
     * @memberof ReviewDb
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReviewDb
     */
    'flow_version_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReviewDb
     */
    'etag': string;
    /**
     * 
     * @type {string}
     * @memberof ReviewDb
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ReviewDb
     */
    'requestee_id': string;
    /**
     * 
     * @type {ReviewStatus}
     * @memberof ReviewDb
     */
    'review_status': ReviewStatus;
    /**
     * 
     * @type {string}
     * @memberof ReviewDb
     */
    'request_status_changed_at': string;
    /**
     * 
     * @type {{ [key: string]: ReviewReviewerDb; }}
     * @memberof ReviewDb
     */
    'reviewers_status': { [key: string]: ReviewReviewerDb; };
    /**
     * 
     * @type {string}
     * @memberof ReviewDb
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ReviewDb
     */
    'opened_at_flow_version_etag': string;
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ReviewRequestStatus {
    OPEN = 'open',
    CANCELLED = 'cancelled'
}


/**
 * 
 * @export
 * @interface ReviewReviewerDb
 */
export interface ReviewReviewerDb {
    /**
     * 
     * @type {string}
     * @memberof ReviewReviewerDb
     */
    'reviewer_id': string;
    /**
     * 
     * @type {ReviewerReviewStatus}
     * @memberof ReviewReviewerDb
     */
    'reviewer_status': ReviewerReviewStatus;
    /**
     * 
     * @type {string}
     * @memberof ReviewReviewerDb
     */
    'reviewer_status_changed_at': string;
}


/**
 * 
 * @export
 * @interface ReviewReviewerUpdate
 */
export interface ReviewReviewerUpdate {
    /**
     * 
     * @type {string}
     * @memberof ReviewReviewerUpdate
     */
    'reviewer_id'?: string;
    /**
     * 
     * @type {ReviewerReviewStatus}
     * @memberof ReviewReviewerUpdate
     */
    'reviewer_status': ReviewerReviewStatus;
}


/**
 * 
 * @export
 * @interface ReviewShallow
 */
export interface ReviewShallow {
    /**
     * 
     * @type {ReviewRequestStatus}
     * @memberof ReviewShallow
     */
    'request_status': ReviewRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof ReviewShallow
     */
    'requestee_id': string;
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ReviewStatus {
    OPEN = 'open',
    CANCELLED = 'cancelled',
    APPROVED = 'approved'
}


/**
 * 
 * @export
 * @interface ReviewUpsert
 */
export interface ReviewUpsert {
    /**
     * 
     * @type {string}
     * @memberof ReviewUpsert
     */
    'flow_version_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewUpsert
     */
    'flow_version_etag': string;
    /**
     * 
     * @type {string}
     * @memberof ReviewUpsert
     */
    'description'?: string;
    /**
     * 
     * @type {ReviewRequestStatus}
     * @memberof ReviewUpsert
     */
    'review_status'?: ReviewRequestStatus;
    /**
     * 
     * @type {{ [key: string]: ReviewReviewerUpdate; }}
     * @memberof ReviewUpsert
     */
    'reviewers_status'?: { [key: string]: ReviewReviewerUpdate; };
}


/**
 * @type ReviewerAssignStrategyV2
 * @export
 */
export type ReviewerAssignStrategyV2 = { type: 'ANY' } & AssignStrategyAny | { type: 'GROUP' } & AssignStrategyGroup | { type: 'REVIEWER_ONLY' } & AssignStrategyReviewerOnly | { type: 'RULE' } & AssignStrategyRule;

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ReviewerReviewStatus {
    PENDING = 'pending',
    APPROVED = 'approved',
    REQUESTED_CHANGES = 'requested_changes',
    COMMENTED = 'commented'
}


/**
 * 
 * @export
 * @interface RuleBranch
 */
export interface RuleBranch {
    /**
     * 
     * @type {string}
     * @memberof RuleBranch
     */
    'id': string;
    /**
     * 
     * @type {Junctions}
     * @memberof RuleBranch
     */
    'junction': Junctions;
    /**
     * 
     * @type {Array<Clause>}
     * @memberof RuleBranch
     */
    'clauses': Array<Clause>;
    /**
     * 
     * @type {Array<Effect>}
     * @memberof RuleBranch
     */
    'effects': Array<Effect>;
}


/**
 * 
 * @export
 * @interface RuleNodeMeta
 */
export interface RuleNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof RuleNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {Array<Effect>}
     * @memberof RuleNodeMeta
     */
    'default_effects': Array<Effect>;
    /**
     * 
     * @type {Array<RuleBranch>}
     * @memberof RuleNodeMeta
     */
    'branches': Array<RuleBranch>;
}
/**
 * 
 * @export
 * @interface ScoreCardNodeMeta
 */
export interface ScoreCardNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof ScoreCardNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScoreCardNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {DecisionTableField}
     * @memberof ScoreCardNodeMeta
     */
    'assign_field': DecisionTableField;
    /**
     * 
     * @type {boolean}
     * @memberof ScoreCardNodeMeta
     */
    'extract_scores': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScoreCardNodeMeta
     */
    'equal_weights': boolean;
    /**
     * 
     * @type {Array<ScorecardFactor>}
     * @memberof ScoreCardNodeMeta
     */
    'factors': Array<ScorecardFactor>;
}
/**
 * 
 * @export
 * @interface ScorecardCase
 */
export interface ScorecardCase {
    /**
     * 
     * @type {string}
     * @memberof ScorecardCase
     */
    'id': string;
    /**
     * 
     * @type {DecisionTablePredicate}
     * @memberof ScorecardCase
     */
    'predicate': DecisionTablePredicate;
    /**
     * 
     * @type {DecisionTableEffect}
     * @memberof ScorecardCase
     */
    'effect': DecisionTableEffect;
}
/**
 * 
 * @export
 * @interface ScorecardFactor
 */
export interface ScorecardFactor {
    /**
     * 
     * @type {string}
     * @memberof ScorecardFactor
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ScorecardFactor
     */
    'name': string;
    /**
     * 
     * @type {DecisionTableField}
     * @memberof ScorecardFactor
     */
    'input_field': DecisionTableField;
    /**
     * 
     * @type {DecisionTableField}
     * @memberof ScorecardFactor
     */
    'output_field': DecisionTableField;
    /**
     * 
     * @type {DecisionTableField}
     * @memberof ScorecardFactor
     */
    'weight': DecisionTableField;
    /**
     * 
     * @type {DecisionTableEffect}
     * @memberof ScorecardFactor
     */
    'fallback': DecisionTableEffect;
    /**
     * 
     * @type {Array<ScorecardCase>}
     * @memberof ScorecardFactor
     */
    'cases': Array<ScorecardCase>;
}
/**
 * 
 * @export
 * @interface SplitBranch
 */
export interface SplitBranch {
    /**
     * 
     * @type {Junctions}
     * @memberof SplitBranch
     */
    'junction': Junctions;
    /**
     * 
     * @type {Array<Clause>}
     * @memberof SplitBranch
     */
    'clauses': Array<Clause>;
    /**
     * 
     * @type {string}
     * @memberof SplitBranch
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SplitBranch
     */
    'edge_id': string;
}


/**
 * 
 * @export
 * @interface SplitNodeMeta
 */
export interface SplitNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof SplitNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SplitNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {Array<SplitBranch>}
     * @memberof SplitNodeMeta
     */
    'branches': Array<SplitBranch>;
    /**
     * 
     * @type {string}
     * @memberof SplitNodeMeta
     */
    'default_edge_id': string;
}
/**
 * 
 * @export
 * @interface SummaryChartDimensions
 */
export interface SummaryChartDimensions {
    /**
     * 
     * @type {string}
     * @memberof SummaryChartDimensions
     */
    'field'?: string;
    /**
     * 
     * @type {ChartAggregator}
     * @memberof SummaryChartDimensions
     */
    'aggregator': ChartAggregator;
    /**
     * 
     * @type {ChartFormat}
     * @memberof SummaryChartDimensions
     */
    'format': ChartFormat;
}


/**
 * 
 * @export
 * @interface TrafficPolicyCreateService
 */
export interface TrafficPolicyCreateService {
    /**
     * 
     * @type {string}
     * @memberof TrafficPolicyCreateService
     */
    'flow_id': string;
    /**
     * 
     * @type {ProbabilisticPolicy}
     * @memberof TrafficPolicyCreateService
     */
    'policy': ProbabilisticPolicy;
}
/**
 * 
 * @export
 * @interface TrafficPolicyInDB
 */
export interface TrafficPolicyInDB {
    /**
     * 
     * @type {string}
     * @memberof TrafficPolicyInDB
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TrafficPolicyInDB
     */
    'flow_id': string;
    /**
     * 
     * @type {string}
     * @memberof TrafficPolicyInDB
     */
    'name': string;
    /**
     * 
     * @type {ProbabilisticPolicy}
     * @memberof TrafficPolicyInDB
     */
    'policy'?: ProbabilisticPolicy;
    /**
     * 
     * @type {string}
     * @memberof TrafficPolicyInDB
     */
    'created_by_user_id': string;
    /**
     * 
     * @type {string}
     * @memberof TrafficPolicyInDB
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof TrafficPolicyInDB
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<TrafficPolicyWindowInDB>}
     * @memberof TrafficPolicyInDB
     */
    'traffic_policy_windows'?: Array<TrafficPolicyWindowInDB>;
}
/**
 * 
 * @export
 * @interface TrafficPolicyWindowInDB
 */
export interface TrafficPolicyWindowInDB {
    /**
     * 
     * @type {string}
     * @memberof TrafficPolicyWindowInDB
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TrafficPolicyWindowInDB
     */
    'traffic_policy_id': string;
    /**
     * 
     * @type {string}
     * @memberof TrafficPolicyWindowInDB
     */
    'starts_at': string;
    /**
     * 
     * @type {string}
     * @memberof TrafficPolicyWindowInDB
     */
    'ends_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrafficPolicyWindowInDB
     */
    'created_by_user_id': string;
    /**
     * 
     * @type {string}
     * @memberof TrafficPolicyWindowInDB
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof TrafficPolicyWindowInDB
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface TypingInfo
 */
export interface TypingInfo {
    /**
     * 
     * @type {string}
     * @memberof TypingInfo
     */
    'etag'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypingInfo
     */
    'type'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof TypingInfo
     */
    'examples'?: Array<any>;
    /**
     * 
     * @type {{ [key: string]: TypingInfo; }}
     * @memberof TypingInfo
     */
    'properties'?: { [key: string]: TypingInfo; };
    /**
     * 
     * @type {TypingInfo}
     * @memberof TypingInfo
     */
    'items'?: TypingInfo;
    /**
     * 
     * @type {string}
     * @memberof TypingInfo
     */
    'node_id'?: string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}
/**
 * @type Value
 * @export
 */
export type Value = { selected: 'key_values' } & KeyValuesBodyField | { selected: 'raw' } & RawBodyField;

/**
 * 
 * @export
 * @interface Webhook
 */
export interface Webhook {
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Webhook
     */
    'webhook_template_version'?: number;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'workspace_id': string;
    /**
     * 
     * @type {ResourceType}
     * @memberof Webhook
     */
    'resource_type': ResourceType;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'resource_id': string;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'connection_id': string;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'resource_config_id': string;
    /**
     * 
     * @type {Array<EventFilter>}
     * @memberof Webhook
     */
    'pattern': Array<EventFilter>;
    /**
     * 
     * @type {boolean}
     * @memberof Webhook
     */
    'active': boolean;
}


/**
 * 
 * @export
 * @interface WebhookConnectionNodeMeta
 */
export interface WebhookConnectionNodeMeta {
    /**
     * 
     * @type {string}
     * @memberof WebhookConnectionNodeMeta
     */
    'created_by_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConnectionNodeMeta
     */
    'updated_by_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof WebhookConnectionNodeMeta
     */
    'provider_resource': object;
    /**
     * 
     * @type {string}
     * @memberof WebhookConnectionNodeMeta
     */
    'connection_id': string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConnectionNodeMeta
     */
    'resource_config_id': string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConnectionNodeMeta
     */
    'media_key'?: string;
    /**
     * 
     * @type {object}
     * @memberof WebhookConnectionNodeMeta
     */
    'config': object;
    /**
     * 
     * @type {InputAttribute}
     * @memberof WebhookConnectionNodeMeta
     */
    'correlation_id': InputAttribute;
    /**
     * 
     * @type {object}
     * @memberof WebhookConnectionNodeMeta
     */
    'response': object;
}
/**
 * 
 * @export
 * @interface WebhookUpdate
 */
export interface WebhookUpdate {
    /**
     * 
     * @type {number}
     * @memberof WebhookUpdate
     */
    'webhook_template_version'?: number;
}
/**
 * 
 * @export
 * @interface WorkspaceConfig
 */
export interface WorkspaceConfig {
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceConfig
     */
    'disable_on_call_alarms'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceConfig
     */
    'disable_bug_alarms'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceConfig
     */
    'enable_lambda_insights'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'provisioned_concurrency_router'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'provisioned_concurrency_runner'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'provisioned_concurrency_connect'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'provisioned_concurrency_exporter'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'archiver_sqs_consumption_maximum_concurrency'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'throttling_burst_limit_router_global'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'throttling_rate_limit_router_global'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'throttling_burst_limit_router_sandbox'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'throttling_rate_limit_router_sandbox'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'throttling_burst_limit_router_run'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'throttling_rate_limit_router_run'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'throttling_burst_limit_connect_global'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'throttling_rate_limit_connect_global'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'throttling_burst_limit_exporter_global'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'throttling_rate_limit_exporter_global'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'memory_sticher_mb'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'memory_migrator_mb'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'memory_connect_mb'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'memory_exporter_mb'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'memory_runner_mb'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'memory_archiver_mb'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'memory_router_mb'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'timeout_sticher_s'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'timeout_migrator_s'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'timeout_connect_s'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'timeout_exporter_s'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'timeout_runner_s'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'timeout_archiver_s'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'timeout_router_s'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'aurora_min_capacity_acu'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'aurora_max_capocity_acu'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConfig
     */
    'ips_whitelist'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConfig
     */
    'ips_whitelist_sandbox'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConfig
     */
    'ips_whitelist_live'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WorkspaceConfig
     */
    'extra_tags'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConfig
     */
    'slack_error_webhook'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConfig
     */
    'slack_error_format'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceConfig
     */
    'enable_analytics_egress'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'ml_model_max_size_bytes'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConfig
     */
    'snapshot_ARN_aurora'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConfig
     */
    'aurora_generation_sufix'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'total_custom_connection_retry_count'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceConfig
     */
    'use_shared_aurora_in_dev'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfig
     */
    'profile_level'?: number;
}
/**
 * 
 * @export
 * @interface WorkspaceConfigUpdate
 */
export interface WorkspaceConfigUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceConfigUpdate
     */
    'disable_on_call_alarms'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceConfigUpdate
     */
    'disable_bug_alarms'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceConfigUpdate
     */
    'enable_lambda_insights'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'provisioned_concurrency_router'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'provisioned_concurrency_runner'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'provisioned_concurrency_connect'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'provisioned_concurrency_exporter'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'archiver_sqs_consumption_maximum_concurrency'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'throttling_burst_limit_router_global'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'throttling_rate_limit_router_global'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'throttling_burst_limit_router_sandbox'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'throttling_rate_limit_router_sandbox'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'throttling_burst_limit_router_run'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'throttling_rate_limit_router_run'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'throttling_burst_limit_connect_global'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'throttling_rate_limit_connect_global'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'throttling_burst_limit_exporter_global'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'throttling_rate_limit_exporter_global'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'memory_sticher_mb'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'memory_migrator_mb'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'memory_connect_mb'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'memory_exporter_mb'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'memory_runner_mb'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'memory_archiver_mb'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'memory_router_mb'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'timeout_sticher_s'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'timeout_migrator_s'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'timeout_connect_s'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'timeout_exporter_s'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'timeout_runner_s'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'timeout_archiver_s'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'timeout_router_s'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'aurora_min_capacity_acu'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'aurora_max_capocity_acu'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConfigUpdate
     */
    'ips_whitelist'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConfigUpdate
     */
    'ips_whitelist_sandbox'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConfigUpdate
     */
    'ips_whitelist_live'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WorkspaceConfigUpdate
     */
    'extra_tags'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConfigUpdate
     */
    'slack_error_webhook'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConfigUpdate
     */
    'slack_error_format'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceConfigUpdate
     */
    'enable_analytics_egress'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'ml_model_max_size_bytes'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConfigUpdate
     */
    'snapshot_ARN_aurora'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConfigUpdate
     */
    'aurora_generation_sufix'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'total_custom_connection_retry_count'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceConfigUpdate
     */
    'use_shared_aurora_in_dev'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceConfigUpdate
     */
    'profile_level'?: number;
}
/**
 * 
 * @export
 * @interface WorkspaceCreate
 */
export interface WorkspaceCreate {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceCreate
     */
    'name': string;
    /**
     * 
     * @type {WorkspaceStatus}
     * @memberof WorkspaceCreate
     */
    'status'?: WorkspaceStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceCreate
     */
    'flow_services_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceCreate
     */
    'base_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceCreate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceCreate
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceCreate
     */
    'aws_region'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceCreate
     */
    'organization_id': string;
    /**
     * 
     * @type {WorkspaceConfig}
     * @memberof WorkspaceCreate
     */
    'config'?: WorkspaceConfig;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceCreate
     */
    'sample_data_s3_bucket'?: string;
}


/**
 * 
 * @export
 * @interface WorkspaceDataplane
 */
export interface WorkspaceDataplane {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDataplane
     */
    'name': string;
    /**
     * 
     * @type {WorkspaceStatus}
     * @memberof WorkspaceDataplane
     */
    'status': WorkspaceStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDataplane
     */
    'flow_services_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDataplane
     */
    'base_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDataplane
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDataplane
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDataplane
     */
    'sample_data_s3_bucket'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDataplane
     */
    'sample_data_kms_key_arn'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDataplane
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDataplane
     */
    'aws_region': string;
    /**
     * 
     * @type {WorkspaceConfig}
     * @memberof WorkspaceDataplane
     */
    'config'?: WorkspaceConfig;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDataplane
     */
    'state_suffix'?: string;
    /**
     * 
     * @type {WorkspaceSettings}
     * @memberof WorkspaceDataplane
     */
    'settings'?: WorkspaceSettings;
}


/**
 * 
 * @export
 * @interface WorkspaceInfo
 */
export interface WorkspaceInfo {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceInfo
     */
    'flow_services_version': string;
}
/**
 * 
 * @export
 * @interface WorkspacePatch
 */
export interface WorkspacePatch {
    /**
     * 
     * @type {string}
     * @memberof WorkspacePatch
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacePatch
     */
    'flow_services_version'?: string;
    /**
     * 
     * @type {WorkspaceSettingsCreateApi}
     * @memberof WorkspacePatch
     */
    'settings'?: WorkspaceSettingsCreateApi;
    /**
     * 
     * @type {WorkspaceConfigUpdate}
     * @memberof WorkspacePatch
     */
    'config'?: WorkspaceConfigUpdate;
}
/**
 * 
 * @export
 * @interface WorkspaceSettings
 */
export interface WorkspaceSettings {
    /**
     * 
     * @type {DataRetentionTimedelta}
     * @memberof WorkspaceSettings
     */
    'data_retention': DataRetentionTimedelta;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceSettings
     */
    'created_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceSettings
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface WorkspaceSettingsCreateApi
 */
export interface WorkspaceSettingsCreateApi {
    /**
     * 
     * @type {DataRetentionTimedelta}
     * @memberof WorkspaceSettingsCreateApi
     */
    'data_retention': DataRetentionTimedelta;
}
/**
 * States of a workflow status. Keep in sync with decide operator worker
 * @export
 * @enum {string}
 */

export enum WorkspaceStatus {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE'
}



/**
 * CallbackApi - axios parameter creator
 * @export
 */
export const CallbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * TODO: Use propper auth here. Even better, use a queue to communicate.  Currently you have to know the flow UUID to be able to call this endpoint.
         * @summary Operator Callback
         * @param {OperatorCallbackPayload} operatorCallbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorCallbackApiV1CallbackPost: async (operatorCallbackPayload: OperatorCallbackPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'operatorCallbackPayload' is not null or undefined
            assertParamExists('operatorCallbackApiV1CallbackPost', 'operatorCallbackPayload', operatorCallbackPayload)
            const localVarPath = `/api/v1/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operatorCallbackPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CallbackApi - functional programming interface
 * @export
 */
export const CallbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CallbackApiAxiosParamCreator(configuration)
    return {
        /**
         * TODO: Use propper auth here. Even better, use a queue to communicate.  Currently you have to know the flow UUID to be able to call this endpoint.
         * @summary Operator Callback
         * @param {OperatorCallbackPayload} operatorCallbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async operatorCallbackApiV1CallbackPost(operatorCallbackPayload: OperatorCallbackPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.operatorCallbackApiV1CallbackPost(operatorCallbackPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CallbackApi.operatorCallbackApiV1CallbackPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CallbackApi - factory interface
 * @export
 */
export const CallbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CallbackApiFp(configuration)
    return {
        /**
         * TODO: Use propper auth here. Even better, use a queue to communicate.  Currently you have to know the flow UUID to be able to call this endpoint.
         * @summary Operator Callback
         * @param {OperatorCallbackPayload} operatorCallbackPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatorCallbackApiV1CallbackPost(operatorCallbackPayload: OperatorCallbackPayload, options?: any): AxiosPromise<any> {
            return localVarFp.operatorCallbackApiV1CallbackPost(operatorCallbackPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CallbackApi - object-oriented interface
 * @export
 * @class CallbackApi
 * @extends {BaseAPI}
 */
export class CallbackApi extends BaseAPI {
    /**
     * TODO: Use propper auth here. Even better, use a queue to communicate.  Currently you have to know the flow UUID to be able to call this endpoint.
     * @summary Operator Callback
     * @param {OperatorCallbackPayload} operatorCallbackPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallbackApi
     */
    public operatorCallbackApiV1CallbackPost(operatorCallbackPayload: OperatorCallbackPayload, options?: RawAxiosRequestConfig) {
        return CallbackApiFp(this.configuration).operatorCallbackApiV1CallbackPost(operatorCallbackPayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChartsApi - axios parameter creator
 * @export
 */
export const ChartsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Chart
         * @param {ChartCreateRequest} chartCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChartApiV1ChartsPost: async (chartCreateRequest: ChartCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chartCreateRequest' is not null or undefined
            assertParamExists('createChartApiV1ChartsPost', 'chartCreateRequest', chartCreateRequest)
            const localVarPath = `/api/v1/charts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chartCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Chart By Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChartByIdApiV1ChartsIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteChartByIdApiV1ChartsIdDelete', 'id', id)
            const localVarPath = `/api/v1/charts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Chart
         * @param {string} chartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartApiV1ChartsChartIdGet: async (chartId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chartId' is not null or undefined
            assertParamExists('getChartApiV1ChartsChartIdGet', 'chartId', chartId)
            const localVarPath = `/api/v1/charts/{chart_id}`
                .replace(`{${"chart_id"}}`, encodeURIComponent(String(chartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Charts
         * @param {ChartResourceType} resourceType 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartsApiV1ChartsGet: async (resourceType: ChartResourceType, resourceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('getChartsApiV1ChartsGet', 'resourceType', resourceType)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getChartsApiV1ChartsGet', 'resourceId', resourceId)
            const localVarPath = `/api/v1/charts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (resourceType !== undefined) {
                localVarQueryParameter['resource_type'] = resourceType;
            }

            if (resourceId !== undefined) {
                localVarQueryParameter['resource_id'] = resourceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all node charts for a given flow version - Used to do eager loading of charts in the router - Can be used by FE to get all charts for a given flow version
         * @summary Get Nodes Charts By Flow Version Id
         * @param {string} flowVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodesChartsByFlowVersionIdApiV1ChartsFlowVersionsFlowVersionIdChartsGet: async (flowVersionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowVersionId' is not null or undefined
            assertParamExists('getNodesChartsByFlowVersionIdApiV1ChartsFlowVersionsFlowVersionIdChartsGet', 'flowVersionId', flowVersionId)
            const localVarPath = `/api/v1/charts/flow_versions/{flow_version_id}/charts`
                .replace(`{${"flow_version_id"}}`, encodeURIComponent(String(flowVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Chart By Id
         * @param {string} id 
         * @param {ChartPatch} chartPatch 
         * @param {string} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchChartByIdApiV1ChartsIdPatch: async (id: string, chartPatch: ChartPatch, ifMatch?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchChartByIdApiV1ChartsIdPatch', 'id', id)
            // verify required parameter 'chartPatch' is not null or undefined
            assertParamExists('patchChartByIdApiV1ChartsIdPatch', 'chartPatch', chartPatch)
            const localVarPath = `/api/v1/charts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['if-match'] = String(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chartPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reorder Charts
         * @param {ChartReorder} chartReorder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderChartsApiV1ChartsOrderPatch: async (chartReorder: ChartReorder, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chartReorder' is not null or undefined
            assertParamExists('reorderChartsApiV1ChartsOrderPatch', 'chartReorder', chartReorder)
            const localVarPath = `/api/v1/charts/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chartReorder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChartsApi - functional programming interface
 * @export
 */
export const ChartsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChartsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Chart
         * @param {ChartCreateRequest} chartCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChartApiV1ChartsPost(chartCreateRequest: ChartCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Chart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChartApiV1ChartsPost(chartCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChartsApi.createChartApiV1ChartsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Chart By Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChartByIdApiV1ChartsIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteChartByIdApiV1ChartsIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChartsApi.deleteChartByIdApiV1ChartsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Chart
         * @param {string} chartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartApiV1ChartsChartIdGet(chartId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Chart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartApiV1ChartsChartIdGet(chartId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChartsApi.getChartApiV1ChartsChartIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Charts
         * @param {ChartResourceType} resourceType 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartsApiV1ChartsGet(resourceType: ChartResourceType, resourceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Chart>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartsApiV1ChartsGet(resourceType, resourceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChartsApi.getChartsApiV1ChartsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all node charts for a given flow version - Used to do eager loading of charts in the router - Can be used by FE to get all charts for a given flow version
         * @summary Get Nodes Charts By Flow Version Id
         * @param {string} flowVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodesChartsByFlowVersionIdApiV1ChartsFlowVersionsFlowVersionIdChartsGet(flowVersionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Chart>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodesChartsByFlowVersionIdApiV1ChartsFlowVersionsFlowVersionIdChartsGet(flowVersionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChartsApi.getNodesChartsByFlowVersionIdApiV1ChartsFlowVersionsFlowVersionIdChartsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Patch Chart By Id
         * @param {string} id 
         * @param {ChartPatch} chartPatch 
         * @param {string} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchChartByIdApiV1ChartsIdPatch(id: string, chartPatch: ChartPatch, ifMatch?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Chart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchChartByIdApiV1ChartsIdPatch(id, chartPatch, ifMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChartsApi.patchChartByIdApiV1ChartsIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reorder Charts
         * @param {ChartReorder} chartReorder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reorderChartsApiV1ChartsOrderPatch(chartReorder: ChartReorder, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reorderChartsApiV1ChartsOrderPatch(chartReorder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChartsApi.reorderChartsApiV1ChartsOrderPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChartsApi - factory interface
 * @export
 */
export const ChartsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChartsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Chart
         * @param {ChartCreateRequest} chartCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChartApiV1ChartsPost(chartCreateRequest: ChartCreateRequest, options?: any): AxiosPromise<Chart> {
            return localVarFp.createChartApiV1ChartsPost(chartCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Chart By Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChartByIdApiV1ChartsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteChartByIdApiV1ChartsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Chart
         * @param {string} chartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartApiV1ChartsChartIdGet(chartId: string, options?: any): AxiosPromise<Chart> {
            return localVarFp.getChartApiV1ChartsChartIdGet(chartId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Charts
         * @param {ChartResourceType} resourceType 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartsApiV1ChartsGet(resourceType: ChartResourceType, resourceId: string, options?: any): AxiosPromise<Array<Chart>> {
            return localVarFp.getChartsApiV1ChartsGet(resourceType, resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all node charts for a given flow version - Used to do eager loading of charts in the router - Can be used by FE to get all charts for a given flow version
         * @summary Get Nodes Charts By Flow Version Id
         * @param {string} flowVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodesChartsByFlowVersionIdApiV1ChartsFlowVersionsFlowVersionIdChartsGet(flowVersionId: string, options?: any): AxiosPromise<Array<Chart>> {
            return localVarFp.getNodesChartsByFlowVersionIdApiV1ChartsFlowVersionsFlowVersionIdChartsGet(flowVersionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Chart By Id
         * @param {string} id 
         * @param {ChartPatch} chartPatch 
         * @param {string} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchChartByIdApiV1ChartsIdPatch(id: string, chartPatch: ChartPatch, ifMatch?: string, options?: any): AxiosPromise<Chart> {
            return localVarFp.patchChartByIdApiV1ChartsIdPatch(id, chartPatch, ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reorder Charts
         * @param {ChartReorder} chartReorder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderChartsApiV1ChartsOrderPatch(chartReorder: ChartReorder, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.reorderChartsApiV1ChartsOrderPatch(chartReorder, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChartsApi - object-oriented interface
 * @export
 * @class ChartsApi
 * @extends {BaseAPI}
 */
export class ChartsApi extends BaseAPI {
    /**
     * 
     * @summary Create Chart
     * @param {ChartCreateRequest} chartCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApi
     */
    public createChartApiV1ChartsPost(chartCreateRequest: ChartCreateRequest, options?: RawAxiosRequestConfig) {
        return ChartsApiFp(this.configuration).createChartApiV1ChartsPost(chartCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Chart By Id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApi
     */
    public deleteChartByIdApiV1ChartsIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return ChartsApiFp(this.configuration).deleteChartByIdApiV1ChartsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Chart
     * @param {string} chartId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApi
     */
    public getChartApiV1ChartsChartIdGet(chartId: string, options?: RawAxiosRequestConfig) {
        return ChartsApiFp(this.configuration).getChartApiV1ChartsChartIdGet(chartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Charts
     * @param {ChartResourceType} resourceType 
     * @param {string} resourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApi
     */
    public getChartsApiV1ChartsGet(resourceType: ChartResourceType, resourceId: string, options?: RawAxiosRequestConfig) {
        return ChartsApiFp(this.configuration).getChartsApiV1ChartsGet(resourceType, resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all node charts for a given flow version - Used to do eager loading of charts in the router - Can be used by FE to get all charts for a given flow version
     * @summary Get Nodes Charts By Flow Version Id
     * @param {string} flowVersionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApi
     */
    public getNodesChartsByFlowVersionIdApiV1ChartsFlowVersionsFlowVersionIdChartsGet(flowVersionId: string, options?: RawAxiosRequestConfig) {
        return ChartsApiFp(this.configuration).getNodesChartsByFlowVersionIdApiV1ChartsFlowVersionsFlowVersionIdChartsGet(flowVersionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Chart By Id
     * @param {string} id 
     * @param {ChartPatch} chartPatch 
     * @param {string} [ifMatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApi
     */
    public patchChartByIdApiV1ChartsIdPatch(id: string, chartPatch: ChartPatch, ifMatch?: string, options?: RawAxiosRequestConfig) {
        return ChartsApiFp(this.configuration).patchChartByIdApiV1ChartsIdPatch(id, chartPatch, ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reorder Charts
     * @param {ChartReorder} chartReorder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApi
     */
    public reorderChartsApiV1ChartsOrderPatch(chartReorder: ChartReorder, options?: RawAxiosRequestConfig) {
        return ChartsApiFp(this.configuration).reorderChartsApiV1ChartsOrderPatch(chartReorder, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommentsApi - axios parameter creator
 * @export
 */
export const CommentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Comment
         * @param {string} commentId 
         * @param {CommentCreate} commentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentApiV1CommentsCommentIdPut: async (commentId: string, commentCreate: CommentCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('createCommentApiV1CommentsCommentIdPut', 'commentId', commentId)
            // verify required parameter 'commentCreate' is not null or undefined
            assertParamExists('createCommentApiV1CommentsCommentIdPut', 'commentCreate', commentCreate)
            const localVarPath = `/api/v1/comments/{comment_id}`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Comment
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentApiV1CommentsCommentIdDelete: async (commentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteCommentApiV1CommentsCommentIdDelete', 'commentId', commentId)
            const localVarPath = `/api/v1/comments/{comment_id}`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query comments for a flow version. It does not include replies.
         * @summary Get Comments
         * @param {string} flowVersionId 
         * @param {CommentResourceType} [resourceType] 
         * @param {string} [resourceId] 
         * @param {string} [createdBefore] 
         * @param {boolean} [resolved] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsApiV1CommentsGet: async (flowVersionId: string, resourceType?: CommentResourceType, resourceId?: string, createdBefore?: string, resolved?: boolean, search?: string, skip?: number, limit?: number, ifNoneMatch?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowVersionId' is not null or undefined
            assertParamExists('getCommentsApiV1CommentsGet', 'flowVersionId', flowVersionId)
            const localVarPath = `/api/v1/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (flowVersionId !== undefined) {
                localVarQueryParameter['flow_version_id'] = flowVersionId;
            }

            if (resourceType !== undefined) {
                localVarQueryParameter['resource_type'] = resourceType;
            }

            if (resourceId !== undefined) {
                localVarQueryParameter['resource_id'] = resourceId;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['created_before'] = (createdBefore as any instanceof Date) ?
                    (createdBefore as any).toISOString() :
                    createdBefore;
            }

            if (resolved !== undefined) {
                localVarQueryParameter['resolved'] = resolved;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (ifNoneMatch != null) {
                localVarHeaderParameter['if-none-match'] = String(ifNoneMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Comments Summary
         * @param {string} flowVersionId 
         * @param {string} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsSummaryApiV1CommentsSummaryFlowVersionIdGet: async (flowVersionId: string, ifNoneMatch?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowVersionId' is not null or undefined
            assertParamExists('getCommentsSummaryApiV1CommentsSummaryFlowVersionIdGet', 'flowVersionId', flowVersionId)
            const localVarPath = `/api/v1/comments/summary/{flow_version_id}`
                .replace(`{${"flow_version_id"}}`, encodeURIComponent(String(flowVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (ifNoneMatch != null) {
                localVarHeaderParameter['if-none-match'] = String(ifNoneMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Replies
         * @param {string} commentId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepliesApiV1CommentsCommentIdRepliesGet: async (commentId: string, skip?: number, limit?: number, ifNoneMatch?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getRepliesApiV1CommentsCommentIdRepliesGet', 'commentId', commentId)
            const localVarPath = `/api/v1/comments/{comment_id}/replies`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (ifNoneMatch != null) {
                localVarHeaderParameter['if-none-match'] = String(ifNoneMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Comment
         * @param {string} commentId 
         * @param {CommentUpdate} commentUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommentApiV1CommentsCommentIdPatch: async (commentId: string, commentUpdate: CommentUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('updateCommentApiV1CommentsCommentIdPatch', 'commentId', commentId)
            // verify required parameter 'commentUpdate' is not null or undefined
            assertParamExists('updateCommentApiV1CommentsCommentIdPatch', 'commentUpdate', commentUpdate)
            const localVarPath = `/api/v1/comments/{comment_id}`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentsApi - functional programming interface
 * @export
 */
export const CommentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Comment
         * @param {string} commentId 
         * @param {CommentCreate} commentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentApiV1CommentsCommentIdPut(commentId: string, commentCreate: CommentCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentDb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentApiV1CommentsCommentIdPut(commentId, commentCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.createCommentApiV1CommentsCommentIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Comment
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommentApiV1CommentsCommentIdDelete(commentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommentApiV1CommentsCommentIdDelete(commentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.deleteCommentApiV1CommentsCommentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query comments for a flow version. It does not include replies.
         * @summary Get Comments
         * @param {string} flowVersionId 
         * @param {CommentResourceType} [resourceType] 
         * @param {string} [resourceId] 
         * @param {string} [createdBefore] 
         * @param {boolean} [resolved] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsApiV1CommentsGet(flowVersionId: string, resourceType?: CommentResourceType, resourceId?: string, createdBefore?: string, resolved?: boolean, search?: string, skip?: number, limit?: number, ifNoneMatch?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommentDb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsApiV1CommentsGet(flowVersionId, resourceType, resourceId, createdBefore, resolved, search, skip, limit, ifNoneMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.getCommentsApiV1CommentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Comments Summary
         * @param {string} flowVersionId 
         * @param {string} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsSummaryApiV1CommentsSummaryFlowVersionIdGet(flowVersionId: string, ifNoneMatch?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsSummaryApiV1CommentsSummaryFlowVersionIdGet(flowVersionId, ifNoneMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.getCommentsSummaryApiV1CommentsSummaryFlowVersionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Replies
         * @param {string} commentId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRepliesApiV1CommentsCommentIdRepliesGet(commentId: string, skip?: number, limit?: number, ifNoneMatch?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommentDb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRepliesApiV1CommentsCommentIdRepliesGet(commentId, skip, limit, ifNoneMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.getRepliesApiV1CommentsCommentIdRepliesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Comment
         * @param {string} commentId 
         * @param {CommentUpdate} commentUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommentApiV1CommentsCommentIdPatch(commentId: string, commentUpdate: CommentUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentDb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommentApiV1CommentsCommentIdPatch(commentId, commentUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.updateCommentApiV1CommentsCommentIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommentsApi - factory interface
 * @export
 */
export const CommentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Comment
         * @param {string} commentId 
         * @param {CommentCreate} commentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentApiV1CommentsCommentIdPut(commentId: string, commentCreate: CommentCreate, options?: any): AxiosPromise<CommentDb> {
            return localVarFp.createCommentApiV1CommentsCommentIdPut(commentId, commentCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Comment
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentApiV1CommentsCommentIdDelete(commentId: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteCommentApiV1CommentsCommentIdDelete(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Query comments for a flow version. It does not include replies.
         * @summary Get Comments
         * @param {string} flowVersionId 
         * @param {CommentResourceType} [resourceType] 
         * @param {string} [resourceId] 
         * @param {string} [createdBefore] 
         * @param {boolean} [resolved] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsApiV1CommentsGet(flowVersionId: string, resourceType?: CommentResourceType, resourceId?: string, createdBefore?: string, resolved?: boolean, search?: string, skip?: number, limit?: number, ifNoneMatch?: string, options?: any): AxiosPromise<Array<CommentDb>> {
            return localVarFp.getCommentsApiV1CommentsGet(flowVersionId, resourceType, resourceId, createdBefore, resolved, search, skip, limit, ifNoneMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Comments Summary
         * @param {string} flowVersionId 
         * @param {string} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsSummaryApiV1CommentsSummaryFlowVersionIdGet(flowVersionId: string, ifNoneMatch?: string, options?: any): AxiosPromise<CommentSummary> {
            return localVarFp.getCommentsSummaryApiV1CommentsSummaryFlowVersionIdGet(flowVersionId, ifNoneMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Replies
         * @param {string} commentId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepliesApiV1CommentsCommentIdRepliesGet(commentId: string, skip?: number, limit?: number, ifNoneMatch?: string, options?: any): AxiosPromise<Array<CommentDb>> {
            return localVarFp.getRepliesApiV1CommentsCommentIdRepliesGet(commentId, skip, limit, ifNoneMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Comment
         * @param {string} commentId 
         * @param {CommentUpdate} commentUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommentApiV1CommentsCommentIdPatch(commentId: string, commentUpdate: CommentUpdate, options?: any): AxiosPromise<CommentDb> {
            return localVarFp.updateCommentApiV1CommentsCommentIdPatch(commentId, commentUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentsApi - object-oriented interface
 * @export
 * @class CommentsApi
 * @extends {BaseAPI}
 */
export class CommentsApi extends BaseAPI {
    /**
     * 
     * @summary Create Comment
     * @param {string} commentId 
     * @param {CommentCreate} commentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public createCommentApiV1CommentsCommentIdPut(commentId: string, commentCreate: CommentCreate, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).createCommentApiV1CommentsCommentIdPut(commentId, commentCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Comment
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public deleteCommentApiV1CommentsCommentIdDelete(commentId: string, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).deleteCommentApiV1CommentsCommentIdDelete(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query comments for a flow version. It does not include replies.
     * @summary Get Comments
     * @param {string} flowVersionId 
     * @param {CommentResourceType} [resourceType] 
     * @param {string} [resourceId] 
     * @param {string} [createdBefore] 
     * @param {boolean} [resolved] 
     * @param {string} [search] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [ifNoneMatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public getCommentsApiV1CommentsGet(flowVersionId: string, resourceType?: CommentResourceType, resourceId?: string, createdBefore?: string, resolved?: boolean, search?: string, skip?: number, limit?: number, ifNoneMatch?: string, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).getCommentsApiV1CommentsGet(flowVersionId, resourceType, resourceId, createdBefore, resolved, search, skip, limit, ifNoneMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Comments Summary
     * @param {string} flowVersionId 
     * @param {string} [ifNoneMatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public getCommentsSummaryApiV1CommentsSummaryFlowVersionIdGet(flowVersionId: string, ifNoneMatch?: string, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).getCommentsSummaryApiV1CommentsSummaryFlowVersionIdGet(flowVersionId, ifNoneMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Replies
     * @param {string} commentId 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [ifNoneMatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public getRepliesApiV1CommentsCommentIdRepliesGet(commentId: string, skip?: number, limit?: number, ifNoneMatch?: string, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).getRepliesApiV1CommentsCommentIdRepliesGet(commentId, skip, limit, ifNoneMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Comment
     * @param {string} commentId 
     * @param {CommentUpdate} commentUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public updateCommentApiV1CommentsCommentIdPatch(commentId: string, commentUpdate: CommentUpdate, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).updateCommentApiV1CommentsCommentIdPatch(commentId, commentUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Healthz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthzHealthzGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthz`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Info
         * @param {boolean} [includeAlembicRevision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoInfoGet: async (includeAlembicRevision?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeAlembicRevision !== undefined) {
                localVarQueryParameter['include_alembic_revision'] = includeAlembicRevision;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Healthz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthzHealthzGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthzHealthzGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.healthzHealthzGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Info
         * @param {boolean} [includeAlembicRevision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoInfoGet(includeAlembicRevision?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.infoInfoGet(includeAlembicRevision, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.infoInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Healthz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthzHealthzGet(options?: any): AxiosPromise<void> {
            return localVarFp.healthzHealthzGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Info
         * @param {boolean} [includeAlembicRevision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoInfoGet(includeAlembicRevision?: boolean, options?: any): AxiosPromise<any> {
            return localVarFp.infoInfoGet(includeAlembicRevision, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Healthz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthzHealthzGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthzHealthzGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Info
     * @param {boolean} [includeAlembicRevision] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public infoInfoGet(includeAlembicRevision?: boolean, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).infoInfoGet(includeAlembicRevision, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FlowVersionsApi - axios parameter creator
 * @export
 */
export const FlowVersionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Flow Version
         * @param {FlowVersionCreate} flowVersionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlowVersionApiV1FlowVersionsPost: async (flowVersionCreate: FlowVersionCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowVersionCreate' is not null or undefined
            assertParamExists('createFlowVersionApiV1FlowVersionsPost', 'flowVersionCreate', flowVersionCreate)
            const localVarPath = `/api/v1/flow_versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(flowVersionCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Flow Version
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFlowVersionApiV1FlowVersionsIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFlowVersionApiV1FlowVersionsIdDelete', 'id', id)
            const localVarPath = `/api/v1/flow_versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Duplicate
         * @param {string} id 
         * @param {Data} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateApiV1FlowVersionsIdDuplicatePost: async (id: string, data: Data, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('duplicateApiV1FlowVersionsIdDuplicatePost', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('duplicateApiV1FlowVersionsIdDuplicatePost', 'data', data)
            const localVarPath = `/api/v1/flow_versions/{id}/duplicate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Flow Of Flow Version
         * @param {string} id 
         * @param {any} [ifMatch] 
         * @param {any} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowOfFlowVersionApiV1FlowVersionsIdFlowGet: async (id: string, ifMatch?: any, ifNoneMatch?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFlowOfFlowVersionApiV1FlowVersionsIdFlowGet', 'id', id)
            const localVarPath = `/api/v1/flow_versions/{id}/flow`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['if-match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }

            if (ifNoneMatch != null) {
                localVarHeaderParameter['if-none-match'] = typeof ifNoneMatch === 'string'
                    ? ifNoneMatch
                    : JSON.stringify(ifNoneMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Flow Version
         * @param {string} id 
         * @param {any} [ifMatch] 
         * @param {any} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowVersionApiV1FlowVersionsIdGet: async (id: string, ifMatch?: any, ifNoneMatch?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFlowVersionApiV1FlowVersionsIdGet', 'id', id)
            const localVarPath = `/api/v1/flow_versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['if-match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }

            if (ifNoneMatch != null) {
                localVarHeaderParameter['if-none-match'] = typeof ifNoneMatch === 'string'
                    ? ifNoneMatch
                    : JSON.stringify(ifNoneMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Flow Version Changes
         * @param {string} flowVersionId 
         * @param {string} [afterEtag] 
         * @param {string} [beforeEtag] 
         * @param {number} [skip] 
         * @param {number} [maxLimit] 
         * @param {number} [aggregationTime] 
         * @param {boolean} [includeLogic] 
         * @param {boolean} [includeReviewComments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowVersionChangesApiV2FlowVersionsFlowVersionIdChangesGet: async (flowVersionId: string, afterEtag?: string, beforeEtag?: string, skip?: number, maxLimit?: number, aggregationTime?: number, includeLogic?: boolean, includeReviewComments?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowVersionId' is not null or undefined
            assertParamExists('getFlowVersionChangesApiV2FlowVersionsFlowVersionIdChangesGet', 'flowVersionId', flowVersionId)
            const localVarPath = `/api/v2/flow_versions/{flow_version_id}/changes`
                .replace(`{${"flow_version_id"}}`, encodeURIComponent(String(flowVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (afterEtag !== undefined) {
                localVarQueryParameter['after_etag'] = afterEtag;
            }

            if (beforeEtag !== undefined) {
                localVarQueryParameter['before_etag'] = beforeEtag;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (maxLimit !== undefined) {
                localVarQueryParameter['max_limit'] = maxLimit;
            }

            if (aggregationTime !== undefined) {
                localVarQueryParameter['aggregation_time'] = aggregationTime;
            }

            if (includeLogic !== undefined) {
                localVarQueryParameter['include_logic'] = includeLogic;
            }

            if (includeReviewComments !== undefined) {
                localVarQueryParameter['include_review_comments'] = includeReviewComments;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Flow Version Mockable Nodes
         * @param {string} flowVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowVersionMockableNodesApiV2FlowVersionsFlowVersionIdMockableNodesGet: async (flowVersionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowVersionId' is not null or undefined
            assertParamExists('getFlowVersionMockableNodesApiV2FlowVersionsFlowVersionIdMockableNodesGet', 'flowVersionId', flowVersionId)
            const localVarPath = `/api/v2/flow_versions/{flow_version_id}/mockable_nodes`
                .replace(`{${"flow_version_id"}}`, encodeURIComponent(String(flowVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Flow Version Revision
         * @param {string} flowVersionId 
         * @param {string} etag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowVersionRevisionApiV2FlowVersionsFlowVersionIdRevisionEtagGet: async (flowVersionId: string, etag: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowVersionId' is not null or undefined
            assertParamExists('getFlowVersionRevisionApiV2FlowVersionsFlowVersionIdRevisionEtagGet', 'flowVersionId', flowVersionId)
            // verify required parameter 'etag' is not null or undefined
            assertParamExists('getFlowVersionRevisionApiV2FlowVersionsFlowVersionIdRevisionEtagGet', 'etag', etag)
            const localVarPath = `/api/v2/flow_versions/{flow_version_id}/revision/{etag}`
                .replace(`{${"flow_version_id"}}`, encodeURIComponent(String(flowVersionId)))
                .replace(`{${"etag"}}`, encodeURIComponent(String(etag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get revision connect nodes. Used on the exporter to get the connect nodes of a specific revision, without exposing more than necessary information to the reviewer. This allows us to check only the workspace resource permission.
         * @summary Get Flow Version Revision Connect Nodes
         * @param {string} flowVersionId 
         * @param {string} etag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowVersionRevisionConnectNodesApiV2FlowVersionsFlowVersionIdRevisionEtagGraphConnectNodesGet: async (flowVersionId: string, etag: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowVersionId' is not null or undefined
            assertParamExists('getFlowVersionRevisionConnectNodesApiV2FlowVersionsFlowVersionIdRevisionEtagGraphConnectNodesGet', 'flowVersionId', flowVersionId)
            // verify required parameter 'etag' is not null or undefined
            assertParamExists('getFlowVersionRevisionConnectNodesApiV2FlowVersionsFlowVersionIdRevisionEtagGraphConnectNodesGet', 'etag', etag)
            const localVarPath = `/api/v2/flow_versions/{flow_version_id}/revision/{etag}/graph/connect_nodes`
                .replace(`{${"flow_version_id"}}`, encodeURIComponent(String(flowVersionId)))
                .replace(`{${"etag"}}`, encodeURIComponent(String(etag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Flow Version V2
         * @param {string} id 
         * @param {any} [ifMatch] 
         * @param {any} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowVersionV2ApiV2FlowVersionsIdGet: async (id: string, ifMatch?: any, ifNoneMatch?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFlowVersionV2ApiV2FlowVersionsIdGet', 'id', id)
            const localVarPath = `/api/v2/flow_versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['if-match'] = typeof ifMatch === 'string'
                    ? ifMatch
                    : JSON.stringify(ifMatch);
            }

            if (ifNoneMatch != null) {
                localVarHeaderParameter['if-none-match'] = typeof ifNoneMatch === 'string'
                    ? ifNoneMatch
                    : JSON.stringify(ifNoneMatch);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Flow Version
         * @param {string} id 
         * @param {string} byEtag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFlowVersionApiV2FlowVersionsIdPut: async (id: string, byEtag: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putFlowVersionApiV2FlowVersionsIdPut', 'id', id)
            // verify required parameter 'byEtag' is not null or undefined
            assertParamExists('putFlowVersionApiV2FlowVersionsIdPut', 'byEtag', byEtag)
            const localVarPath = `/api/v2/flow_versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (byEtag !== undefined) {
                localVarQueryParameter['by_etag'] = byEtag;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a flow version Supports deep partial updates of the inner graph resource using a JSON MERGE PATCH see https://bit.ly/3mOv0ac
         * @summary Update Flow Version
         * @param {string} id 
         * @param {FlowVersionUpdate} flowVersionUpdate 
         * @param {boolean} [enableTrafficPolicies] 
         * @param {string} [session] 
         * @param {string} [ifMatch] 
         * @param {string} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlowVersionApiV1FlowVersionsIdPatch: async (id: string, flowVersionUpdate: FlowVersionUpdate, enableTrafficPolicies?: boolean, session?: string, ifMatch?: string, ifNoneMatch?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFlowVersionApiV1FlowVersionsIdPatch', 'id', id)
            // verify required parameter 'flowVersionUpdate' is not null or undefined
            assertParamExists('updateFlowVersionApiV1FlowVersionsIdPatch', 'flowVersionUpdate', flowVersionUpdate)
            const localVarPath = `/api/v1/flow_versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (enableTrafficPolicies !== undefined) {
                localVarQueryParameter['enable_traffic_policies'] = enableTrafficPolicies;
            }

            if (session != null) {
                localVarHeaderParameter['session'] = String(session);
            }

            if (ifMatch != null) {
                localVarHeaderParameter['if-match'] = String(ifMatch);
            }

            if (ifNoneMatch != null) {
                localVarHeaderParameter['if-none-match'] = String(ifNoneMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(flowVersionUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlowVersionsApi - functional programming interface
 * @export
 */
export const FlowVersionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlowVersionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Flow Version
         * @param {FlowVersionCreate} flowVersionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFlowVersionApiV1FlowVersionsPost(flowVersionCreate: FlowVersionCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowVersionDb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFlowVersionApiV1FlowVersionsPost(flowVersionCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowVersionsApi.createFlowVersionApiV1FlowVersionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Flow Version
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFlowVersionApiV1FlowVersionsIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFlowVersionApiV1FlowVersionsIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowVersionsApi.deleteFlowVersionApiV1FlowVersionsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Duplicate
         * @param {string} id 
         * @param {Data} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateApiV1FlowVersionsIdDuplicatePost(id: string, data: Data, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowVersionDb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateApiV1FlowVersionsIdDuplicatePost(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowVersionsApi.duplicateApiV1FlowVersionsIdDuplicatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Flow Of Flow Version
         * @param {string} id 
         * @param {any} [ifMatch] 
         * @param {any} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlowOfFlowVersionApiV1FlowVersionsIdFlowGet(id: string, ifMatch?: any, ifNoneMatch?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowDbShallow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlowOfFlowVersionApiV1FlowVersionsIdFlowGet(id, ifMatch, ifNoneMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowVersionsApi.getFlowOfFlowVersionApiV1FlowVersionsIdFlowGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Flow Version
         * @param {string} id 
         * @param {any} [ifMatch] 
         * @param {any} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlowVersionApiV1FlowVersionsIdGet(id: string, ifMatch?: any, ifNoneMatch?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowVersionDb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlowVersionApiV1FlowVersionsIdGet(id, ifMatch, ifNoneMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowVersionsApi.getFlowVersionApiV1FlowVersionsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Flow Version Changes
         * @param {string} flowVersionId 
         * @param {string} [afterEtag] 
         * @param {string} [beforeEtag] 
         * @param {number} [skip] 
         * @param {number} [maxLimit] 
         * @param {number} [aggregationTime] 
         * @param {boolean} [includeLogic] 
         * @param {boolean} [includeReviewComments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlowVersionChangesApiV2FlowVersionsFlowVersionIdChangesGet(flowVersionId: string, afterEtag?: string, beforeEtag?: string, skip?: number, maxLimit?: number, aggregationTime?: number, includeLogic?: boolean, includeReviewComments?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChangeLogDb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlowVersionChangesApiV2FlowVersionsFlowVersionIdChangesGet(flowVersionId, afterEtag, beforeEtag, skip, maxLimit, aggregationTime, includeLogic, includeReviewComments, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowVersionsApi.getFlowVersionChangesApiV2FlowVersionsFlowVersionIdChangesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Flow Version Mockable Nodes
         * @param {string} flowVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlowVersionMockableNodesApiV2FlowVersionsFlowVersionIdMockableNodesGet(flowVersionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NodeDb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlowVersionMockableNodesApiV2FlowVersionsFlowVersionIdMockableNodesGet(flowVersionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowVersionsApi.getFlowVersionMockableNodesApiV2FlowVersionsFlowVersionIdMockableNodesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Flow Version Revision
         * @param {string} flowVersionId 
         * @param {string} etag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlowVersionRevisionApiV2FlowVersionsFlowVersionIdRevisionEtagGet(flowVersionId: string, etag: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowVersionV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlowVersionRevisionApiV2FlowVersionsFlowVersionIdRevisionEtagGet(flowVersionId, etag, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowVersionsApi.getFlowVersionRevisionApiV2FlowVersionsFlowVersionIdRevisionEtagGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get revision connect nodes. Used on the exporter to get the connect nodes of a specific revision, without exposing more than necessary information to the reviewer. This allows us to check only the workspace resource permission.
         * @summary Get Flow Version Revision Connect Nodes
         * @param {string} flowVersionId 
         * @param {string} etag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlowVersionRevisionConnectNodesApiV2FlowVersionsFlowVersionIdRevisionEtagGraphConnectNodesGet(flowVersionId: string, etag: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConnectNodeBasic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlowVersionRevisionConnectNodesApiV2FlowVersionsFlowVersionIdRevisionEtagGraphConnectNodesGet(flowVersionId, etag, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowVersionsApi.getFlowVersionRevisionConnectNodesApiV2FlowVersionsFlowVersionIdRevisionEtagGraphConnectNodesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Flow Version V2
         * @param {string} id 
         * @param {any} [ifMatch] 
         * @param {any} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlowVersionV2ApiV2FlowVersionsIdGet(id: string, ifMatch?: any, ifNoneMatch?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowVersionV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlowVersionV2ApiV2FlowVersionsIdGet(id, ifMatch, ifNoneMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowVersionsApi.getFlowVersionV2ApiV2FlowVersionsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Put Flow Version
         * @param {string} id 
         * @param {string} byEtag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFlowVersionApiV2FlowVersionsIdPut(id: string, byEtag: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowVersionV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFlowVersionApiV2FlowVersionsIdPut(id, byEtag, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowVersionsApi.putFlowVersionApiV2FlowVersionsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a flow version Supports deep partial updates of the inner graph resource using a JSON MERGE PATCH see https://bit.ly/3mOv0ac
         * @summary Update Flow Version
         * @param {string} id 
         * @param {FlowVersionUpdate} flowVersionUpdate 
         * @param {boolean} [enableTrafficPolicies] 
         * @param {string} [session] 
         * @param {string} [ifMatch] 
         * @param {string} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFlowVersionApiV1FlowVersionsIdPatch(id: string, flowVersionUpdate: FlowVersionUpdate, enableTrafficPolicies?: boolean, session?: string, ifMatch?: string, ifNoneMatch?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowVersionDb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFlowVersionApiV1FlowVersionsIdPatch(id, flowVersionUpdate, enableTrafficPolicies, session, ifMatch, ifNoneMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowVersionsApi.updateFlowVersionApiV1FlowVersionsIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FlowVersionsApi - factory interface
 * @export
 */
export const FlowVersionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlowVersionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Flow Version
         * @param {FlowVersionCreate} flowVersionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlowVersionApiV1FlowVersionsPost(flowVersionCreate: FlowVersionCreate, options?: any): AxiosPromise<FlowVersionDb> {
            return localVarFp.createFlowVersionApiV1FlowVersionsPost(flowVersionCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Flow Version
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFlowVersionApiV1FlowVersionsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFlowVersionApiV1FlowVersionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Duplicate
         * @param {string} id 
         * @param {Data} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateApiV1FlowVersionsIdDuplicatePost(id: string, data: Data, options?: any): AxiosPromise<FlowVersionDb> {
            return localVarFp.duplicateApiV1FlowVersionsIdDuplicatePost(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Flow Of Flow Version
         * @param {string} id 
         * @param {any} [ifMatch] 
         * @param {any} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowOfFlowVersionApiV1FlowVersionsIdFlowGet(id: string, ifMatch?: any, ifNoneMatch?: any, options?: any): AxiosPromise<FlowDbShallow> {
            return localVarFp.getFlowOfFlowVersionApiV1FlowVersionsIdFlowGet(id, ifMatch, ifNoneMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Flow Version
         * @param {string} id 
         * @param {any} [ifMatch] 
         * @param {any} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowVersionApiV1FlowVersionsIdGet(id: string, ifMatch?: any, ifNoneMatch?: any, options?: any): AxiosPromise<FlowVersionDb> {
            return localVarFp.getFlowVersionApiV1FlowVersionsIdGet(id, ifMatch, ifNoneMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Flow Version Changes
         * @param {string} flowVersionId 
         * @param {string} [afterEtag] 
         * @param {string} [beforeEtag] 
         * @param {number} [skip] 
         * @param {number} [maxLimit] 
         * @param {number} [aggregationTime] 
         * @param {boolean} [includeLogic] 
         * @param {boolean} [includeReviewComments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowVersionChangesApiV2FlowVersionsFlowVersionIdChangesGet(flowVersionId: string, afterEtag?: string, beforeEtag?: string, skip?: number, maxLimit?: number, aggregationTime?: number, includeLogic?: boolean, includeReviewComments?: boolean, options?: any): AxiosPromise<Array<ChangeLogDb>> {
            return localVarFp.getFlowVersionChangesApiV2FlowVersionsFlowVersionIdChangesGet(flowVersionId, afterEtag, beforeEtag, skip, maxLimit, aggregationTime, includeLogic, includeReviewComments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Flow Version Mockable Nodes
         * @param {string} flowVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowVersionMockableNodesApiV2FlowVersionsFlowVersionIdMockableNodesGet(flowVersionId: string, options?: any): AxiosPromise<Array<NodeDb>> {
            return localVarFp.getFlowVersionMockableNodesApiV2FlowVersionsFlowVersionIdMockableNodesGet(flowVersionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Flow Version Revision
         * @param {string} flowVersionId 
         * @param {string} etag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowVersionRevisionApiV2FlowVersionsFlowVersionIdRevisionEtagGet(flowVersionId: string, etag: string, options?: any): AxiosPromise<FlowVersionV2> {
            return localVarFp.getFlowVersionRevisionApiV2FlowVersionsFlowVersionIdRevisionEtagGet(flowVersionId, etag, options).then((request) => request(axios, basePath));
        },
        /**
         * Get revision connect nodes. Used on the exporter to get the connect nodes of a specific revision, without exposing more than necessary information to the reviewer. This allows us to check only the workspace resource permission.
         * @summary Get Flow Version Revision Connect Nodes
         * @param {string} flowVersionId 
         * @param {string} etag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowVersionRevisionConnectNodesApiV2FlowVersionsFlowVersionIdRevisionEtagGraphConnectNodesGet(flowVersionId: string, etag: string, options?: any): AxiosPromise<Array<ConnectNodeBasic>> {
            return localVarFp.getFlowVersionRevisionConnectNodesApiV2FlowVersionsFlowVersionIdRevisionEtagGraphConnectNodesGet(flowVersionId, etag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Flow Version V2
         * @param {string} id 
         * @param {any} [ifMatch] 
         * @param {any} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowVersionV2ApiV2FlowVersionsIdGet(id: string, ifMatch?: any, ifNoneMatch?: any, options?: any): AxiosPromise<FlowVersionV2> {
            return localVarFp.getFlowVersionV2ApiV2FlowVersionsIdGet(id, ifMatch, ifNoneMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Flow Version
         * @param {string} id 
         * @param {string} byEtag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFlowVersionApiV2FlowVersionsIdPut(id: string, byEtag: string, options?: any): AxiosPromise<FlowVersionV2> {
            return localVarFp.putFlowVersionApiV2FlowVersionsIdPut(id, byEtag, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a flow version Supports deep partial updates of the inner graph resource using a JSON MERGE PATCH see https://bit.ly/3mOv0ac
         * @summary Update Flow Version
         * @param {string} id 
         * @param {FlowVersionUpdate} flowVersionUpdate 
         * @param {boolean} [enableTrafficPolicies] 
         * @param {string} [session] 
         * @param {string} [ifMatch] 
         * @param {string} [ifNoneMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlowVersionApiV1FlowVersionsIdPatch(id: string, flowVersionUpdate: FlowVersionUpdate, enableTrafficPolicies?: boolean, session?: string, ifMatch?: string, ifNoneMatch?: string, options?: any): AxiosPromise<FlowVersionDb> {
            return localVarFp.updateFlowVersionApiV1FlowVersionsIdPatch(id, flowVersionUpdate, enableTrafficPolicies, session, ifMatch, ifNoneMatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlowVersionsApi - object-oriented interface
 * @export
 * @class FlowVersionsApi
 * @extends {BaseAPI}
 */
export class FlowVersionsApi extends BaseAPI {
    /**
     * 
     * @summary Create Flow Version
     * @param {FlowVersionCreate} flowVersionCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowVersionsApi
     */
    public createFlowVersionApiV1FlowVersionsPost(flowVersionCreate: FlowVersionCreate, options?: RawAxiosRequestConfig) {
        return FlowVersionsApiFp(this.configuration).createFlowVersionApiV1FlowVersionsPost(flowVersionCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Flow Version
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowVersionsApi
     */
    public deleteFlowVersionApiV1FlowVersionsIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return FlowVersionsApiFp(this.configuration).deleteFlowVersionApiV1FlowVersionsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Duplicate
     * @param {string} id 
     * @param {Data} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowVersionsApi
     */
    public duplicateApiV1FlowVersionsIdDuplicatePost(id: string, data: Data, options?: RawAxiosRequestConfig) {
        return FlowVersionsApiFp(this.configuration).duplicateApiV1FlowVersionsIdDuplicatePost(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Flow Of Flow Version
     * @param {string} id 
     * @param {any} [ifMatch] 
     * @param {any} [ifNoneMatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowVersionsApi
     */
    public getFlowOfFlowVersionApiV1FlowVersionsIdFlowGet(id: string, ifMatch?: any, ifNoneMatch?: any, options?: RawAxiosRequestConfig) {
        return FlowVersionsApiFp(this.configuration).getFlowOfFlowVersionApiV1FlowVersionsIdFlowGet(id, ifMatch, ifNoneMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Flow Version
     * @param {string} id 
     * @param {any} [ifMatch] 
     * @param {any} [ifNoneMatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowVersionsApi
     */
    public getFlowVersionApiV1FlowVersionsIdGet(id: string, ifMatch?: any, ifNoneMatch?: any, options?: RawAxiosRequestConfig) {
        return FlowVersionsApiFp(this.configuration).getFlowVersionApiV1FlowVersionsIdGet(id, ifMatch, ifNoneMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Flow Version Changes
     * @param {string} flowVersionId 
     * @param {string} [afterEtag] 
     * @param {string} [beforeEtag] 
     * @param {number} [skip] 
     * @param {number} [maxLimit] 
     * @param {number} [aggregationTime] 
     * @param {boolean} [includeLogic] 
     * @param {boolean} [includeReviewComments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowVersionsApi
     */
    public getFlowVersionChangesApiV2FlowVersionsFlowVersionIdChangesGet(flowVersionId: string, afterEtag?: string, beforeEtag?: string, skip?: number, maxLimit?: number, aggregationTime?: number, includeLogic?: boolean, includeReviewComments?: boolean, options?: RawAxiosRequestConfig) {
        return FlowVersionsApiFp(this.configuration).getFlowVersionChangesApiV2FlowVersionsFlowVersionIdChangesGet(flowVersionId, afterEtag, beforeEtag, skip, maxLimit, aggregationTime, includeLogic, includeReviewComments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Flow Version Mockable Nodes
     * @param {string} flowVersionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowVersionsApi
     */
    public getFlowVersionMockableNodesApiV2FlowVersionsFlowVersionIdMockableNodesGet(flowVersionId: string, options?: RawAxiosRequestConfig) {
        return FlowVersionsApiFp(this.configuration).getFlowVersionMockableNodesApiV2FlowVersionsFlowVersionIdMockableNodesGet(flowVersionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Flow Version Revision
     * @param {string} flowVersionId 
     * @param {string} etag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowVersionsApi
     */
    public getFlowVersionRevisionApiV2FlowVersionsFlowVersionIdRevisionEtagGet(flowVersionId: string, etag: string, options?: RawAxiosRequestConfig) {
        return FlowVersionsApiFp(this.configuration).getFlowVersionRevisionApiV2FlowVersionsFlowVersionIdRevisionEtagGet(flowVersionId, etag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get revision connect nodes. Used on the exporter to get the connect nodes of a specific revision, without exposing more than necessary information to the reviewer. This allows us to check only the workspace resource permission.
     * @summary Get Flow Version Revision Connect Nodes
     * @param {string} flowVersionId 
     * @param {string} etag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowVersionsApi
     */
    public getFlowVersionRevisionConnectNodesApiV2FlowVersionsFlowVersionIdRevisionEtagGraphConnectNodesGet(flowVersionId: string, etag: string, options?: RawAxiosRequestConfig) {
        return FlowVersionsApiFp(this.configuration).getFlowVersionRevisionConnectNodesApiV2FlowVersionsFlowVersionIdRevisionEtagGraphConnectNodesGet(flowVersionId, etag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Flow Version V2
     * @param {string} id 
     * @param {any} [ifMatch] 
     * @param {any} [ifNoneMatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowVersionsApi
     */
    public getFlowVersionV2ApiV2FlowVersionsIdGet(id: string, ifMatch?: any, ifNoneMatch?: any, options?: RawAxiosRequestConfig) {
        return FlowVersionsApiFp(this.configuration).getFlowVersionV2ApiV2FlowVersionsIdGet(id, ifMatch, ifNoneMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Flow Version
     * @param {string} id 
     * @param {string} byEtag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowVersionsApi
     */
    public putFlowVersionApiV2FlowVersionsIdPut(id: string, byEtag: string, options?: RawAxiosRequestConfig) {
        return FlowVersionsApiFp(this.configuration).putFlowVersionApiV2FlowVersionsIdPut(id, byEtag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a flow version Supports deep partial updates of the inner graph resource using a JSON MERGE PATCH see https://bit.ly/3mOv0ac
     * @summary Update Flow Version
     * @param {string} id 
     * @param {FlowVersionUpdate} flowVersionUpdate 
     * @param {boolean} [enableTrafficPolicies] 
     * @param {string} [session] 
     * @param {string} [ifMatch] 
     * @param {string} [ifNoneMatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowVersionsApi
     */
    public updateFlowVersionApiV1FlowVersionsIdPatch(id: string, flowVersionUpdate: FlowVersionUpdate, enableTrafficPolicies?: boolean, session?: string, ifMatch?: string, ifNoneMatch?: string, options?: RawAxiosRequestConfig) {
        return FlowVersionsApiFp(this.configuration).updateFlowVersionApiV1FlowVersionsIdPatch(id, flowVersionUpdate, enableTrafficPolicies, session, ifMatch, ifNoneMatch, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FlowsApi - axios parameter creator
 * @export
 */
export const FlowsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Flow
         * @param {FlowCreatePopulated} flowCreatePopulated 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlowApiV1FlowsPost: async (flowCreatePopulated: FlowCreatePopulated, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowCreatePopulated' is not null or undefined
            assertParamExists('createFlowApiV1FlowsPost', 'flowCreatePopulated', flowCreatePopulated)
            const localVarPath = `/api/v1/flows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(flowCreatePopulated, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Flow
         * @param {string} flowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFlowApiV1FlowsFlowIdDelete: async (flowId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('deleteFlowApiV1FlowsFlowIdDelete', 'flowId', flowId)
            const localVarPath = `/api/v1/flows/{flow_id}`
                .replace(`{${"flow_id"}}`, encodeURIComponent(String(flowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Default Version Flow Docs
         * @param {string} flowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultVersionFlowDocsApiV1FlowsFlowIdDocsGet: async (flowId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('getDefaultVersionFlowDocsApiV1FlowsFlowIdDocsGet', 'flowId', flowId)
            const localVarPath = `/api/v1/flows/{flow_id}/docs`
                .replace(`{${"flow_id"}}`, encodeURIComponent(String(flowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the active traffic policy summary for a flow. Only includes the information necessary for routing based on traffic policies.
         * @summary Get Flow Active Policy Summary
         * @param {string} workspaceId 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowActivePolicySummaryApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugActiveTrafficPolicySummaryGet: async (workspaceId: string, slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getFlowActivePolicySummaryApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugActiveTrafficPolicySummaryGet', 'workspaceId', workspaceId)
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getFlowActivePolicySummaryApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugActiveTrafficPolicySummaryGet', 'slug', slug)
            const localVarPath = `/api/v1/flows/workspace_id/{workspace_id}/slug/{slug}/active_traffic_policy_summary`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowApiV1FlowsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFlowApiV1FlowsIdGet', 'id', id)
            const localVarPath = `/api/v1/flows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Flow By Slug
         * @param {string} workspaceId 
         * @param {string} slug 
         * @param {string} [versionNameFilter] 
         * @param {string} [versionIdFilter] 
         * @param {boolean} [defaultVersionFilter] 
         * @param {boolean} [defaultSandboxVersionFilter] 
         * @param {string} [minFlowVersionEtagFilter] 
         * @param {boolean} [shallow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowBySlugApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugGet: async (workspaceId: string, slug: string, versionNameFilter?: string, versionIdFilter?: string, defaultVersionFilter?: boolean, defaultSandboxVersionFilter?: boolean, minFlowVersionEtagFilter?: string, shallow?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getFlowBySlugApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugGet', 'workspaceId', workspaceId)
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getFlowBySlugApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugGet', 'slug', slug)
            const localVarPath = `/api/v1/flows/workspace_id/{workspace_id}/slug/{slug}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (versionNameFilter !== undefined) {
                localVarQueryParameter['version_name_filter'] = versionNameFilter;
            }

            if (versionIdFilter !== undefined) {
                localVarQueryParameter['version_id_filter'] = versionIdFilter;
            }

            if (defaultVersionFilter !== undefined) {
                localVarQueryParameter['default_version_filter'] = defaultVersionFilter;
            }

            if (defaultSandboxVersionFilter !== undefined) {
                localVarQueryParameter['default_sandbox_version_filter'] = defaultSandboxVersionFilter;
            }

            if (minFlowVersionEtagFilter !== undefined) {
                localVarQueryParameter['min_flow_version_etag_filter'] = minFlowVersionEtagFilter;
            }

            if (shallow !== undefined) {
                localVarQueryParameter['shallow'] = shallow;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of flows.
         * @summary Get Flows
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [workspaceId] 
         * @param {string} [flowFolderId] 
         * @param {GetFlowsApiV1FlowsGetOrderByEnum} [orderBy] 
         * @param {boolean} [includeInternalFlows] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowsApiV1FlowsGet: async (skip?: number, limit?: number, workspaceId?: string, flowFolderId?: string, orderBy?: GetFlowsApiV1FlowsGetOrderByEnum, includeInternalFlows?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/flows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspace_id'] = workspaceId;
            }

            if (flowFolderId !== undefined) {
                localVarQueryParameter['flow_folder_id'] = flowFolderId;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (includeInternalFlows !== undefined) {
                localVarQueryParameter['include_internal_flows'] = includeInternalFlows;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Published Flows Using Resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedFlowsUsingResourceApiV1FlowsPublishedUsingConnectResourceWorkspaceWorkspaceIdResourceResourceIdGet: async (workspaceId: string, resourceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getPublishedFlowsUsingResourceApiV1FlowsPublishedUsingConnectResourceWorkspaceWorkspaceIdResourceResourceIdGet', 'workspaceId', workspaceId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getPublishedFlowsUsingResourceApiV1FlowsPublishedUsingConnectResourceWorkspaceWorkspaceIdResourceResourceIdGet', 'resourceId', resourceId)
            const localVarPath = `/api/v1/flows/published_using_connect_resource/workspace/{workspace_id}/resource/{resource_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Versioned Flow Docs
         * @param {string} flowId 
         * @param {string} versionId 
         * @param {boolean} [includeOutcomes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionedFlowDocsApiV1FlowsFlowIdVersionsVersionIdDocsGet: async (flowId: string, versionId: string, includeOutcomes?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('getVersionedFlowDocsApiV1FlowsFlowIdVersionsVersionIdDocsGet', 'flowId', flowId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('getVersionedFlowDocsApiV1FlowsFlowIdVersionsVersionIdDocsGet', 'versionId', versionId)
            const localVarPath = `/api/v1/flows/{flow_id}/versions/{version_id}/docs`
                .replace(`{${"flow_id"}}`, encodeURIComponent(String(flowId)))
                .replace(`{${"version_id"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (includeOutcomes !== undefined) {
                localVarQueryParameter['include_outcomes'] = includeOutcomes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Copy Flow
         * @param {string} flowId 
         * @param {FlowDuplicate} flowDuplicate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCopyFlowApiV1FlowsFlowIdCopyPost: async (flowId: string, flowDuplicate: FlowDuplicate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('postCopyFlowApiV1FlowsFlowIdCopyPost', 'flowId', flowId)
            // verify required parameter 'flowDuplicate' is not null or undefined
            assertParamExists('postCopyFlowApiV1FlowsFlowIdCopyPost', 'flowDuplicate', flowDuplicate)
            const localVarPath = `/api/v1/flows/{flow_id}/copy`
                .replace(`{${"flow_id"}}`, encodeURIComponent(String(flowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(flowDuplicate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Copy Flow Sample Data
         * @param {string} flowId 
         * @param {DatasetsDuplicate} datasetsDuplicate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCopyFlowSampleDataApiV1FlowsFlowIdCopySampleDataPost: async (flowId: string, datasetsDuplicate: DatasetsDuplicate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('postCopyFlowSampleDataApiV1FlowsFlowIdCopySampleDataPost', 'flowId', flowId)
            // verify required parameter 'datasetsDuplicate' is not null or undefined
            assertParamExists('postCopyFlowSampleDataApiV1FlowsFlowIdCopySampleDataPost', 'datasetsDuplicate', datasetsDuplicate)
            const localVarPath = `/api/v1/flows/{flow_id}/copy/sample_data`
                .replace(`{${"flow_id"}}`, encodeURIComponent(String(flowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetsDuplicate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Flow
         * @param {string} id 
         * @param {FlowUpdate} flowUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlowApiV1FlowsIdPatch: async (id: string, flowUpdate: FlowUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFlowApiV1FlowsIdPatch', 'id', id)
            // verify required parameter 'flowUpdate' is not null or undefined
            assertParamExists('updateFlowApiV1FlowsIdPatch', 'flowUpdate', flowUpdate)
            const localVarPath = `/api/v1/flows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(flowUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlowsApi - functional programming interface
 * @export
 */
export const FlowsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlowsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Flow
         * @param {FlowCreatePopulated} flowCreatePopulated 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFlowApiV1FlowsPost(flowCreatePopulated: FlowCreatePopulated, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowDb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFlowApiV1FlowsPost(flowCreatePopulated, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowsApi.createFlowApiV1FlowsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Flow
         * @param {string} flowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFlowApiV1FlowsFlowIdDelete(flowId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFlowApiV1FlowsFlowIdDelete(flowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowsApi.deleteFlowApiV1FlowsFlowIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Default Version Flow Docs
         * @param {string} flowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultVersionFlowDocsApiV1FlowsFlowIdDocsGet(flowId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultVersionFlowDocsApiV1FlowsFlowIdDocsGet(flowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowsApi.getDefaultVersionFlowDocsApiV1FlowsFlowIdDocsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the active traffic policy summary for a flow. Only includes the information necessary for routing based on traffic policies.
         * @summary Get Flow Active Policy Summary
         * @param {string} workspaceId 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlowActivePolicySummaryApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugActiveTrafficPolicySummaryGet(workspaceId: string, slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowDbActivePolicySummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlowActivePolicySummaryApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugActiveTrafficPolicySummaryGet(workspaceId, slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowsApi.getFlowActivePolicySummaryApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugActiveTrafficPolicySummaryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlowApiV1FlowsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowDbShallowSansLinkedFlows>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlowApiV1FlowsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowsApi.getFlowApiV1FlowsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Flow By Slug
         * @param {string} workspaceId 
         * @param {string} slug 
         * @param {string} [versionNameFilter] 
         * @param {string} [versionIdFilter] 
         * @param {boolean} [defaultVersionFilter] 
         * @param {boolean} [defaultSandboxVersionFilter] 
         * @param {string} [minFlowVersionEtagFilter] 
         * @param {boolean} [shallow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlowBySlugApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugGet(workspaceId: string, slug: string, versionNameFilter?: string, versionIdFilter?: string, defaultVersionFilter?: boolean, defaultSandboxVersionFilter?: boolean, minFlowVersionEtagFilter?: string, shallow?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowDbDataplane>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlowBySlugApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugGet(workspaceId, slug, versionNameFilter, versionIdFilter, defaultVersionFilter, defaultSandboxVersionFilter, minFlowVersionEtagFilter, shallow, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowsApi.getFlowBySlugApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a list of flows.
         * @summary Get Flows
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [workspaceId] 
         * @param {string} [flowFolderId] 
         * @param {GetFlowsApiV1FlowsGetOrderByEnum} [orderBy] 
         * @param {boolean} [includeInternalFlows] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlowsApiV1FlowsGet(skip?: number, limit?: number, workspaceId?: string, flowFolderId?: string, orderBy?: GetFlowsApiV1FlowsGetOrderByEnum, includeInternalFlows?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FlowDbShallowNoTrafficPolicies>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlowsApiV1FlowsGet(skip, limit, workspaceId, flowFolderId, orderBy, includeInternalFlows, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowsApi.getFlowsApiV1FlowsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Published Flows Using Resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedFlowsUsingResourceApiV1FlowsPublishedUsingConnectResourceWorkspaceWorkspaceIdResourceResourceIdGet(workspaceId: string, resourceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FlowDbShallow>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishedFlowsUsingResourceApiV1FlowsPublishedUsingConnectResourceWorkspaceWorkspaceIdResourceResourceIdGet(workspaceId, resourceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowsApi.getPublishedFlowsUsingResourceApiV1FlowsPublishedUsingConnectResourceWorkspaceWorkspaceIdResourceResourceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Versioned Flow Docs
         * @param {string} flowId 
         * @param {string} versionId 
         * @param {boolean} [includeOutcomes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersionedFlowDocsApiV1FlowsFlowIdVersionsVersionIdDocsGet(flowId: string, versionId: string, includeOutcomes?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersionedFlowDocsApiV1FlowsFlowIdVersionsVersionIdDocsGet(flowId, versionId, includeOutcomes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowsApi.getVersionedFlowDocsApiV1FlowsFlowIdVersionsVersionIdDocsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Post Copy Flow
         * @param {string} flowId 
         * @param {FlowDuplicate} flowDuplicate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCopyFlowApiV1FlowsFlowIdCopyPost(flowId: string, flowDuplicate: FlowDuplicate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CopyFlowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCopyFlowApiV1FlowsFlowIdCopyPost(flowId, flowDuplicate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowsApi.postCopyFlowApiV1FlowsFlowIdCopyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Post Copy Flow Sample Data
         * @param {string} flowId 
         * @param {DatasetsDuplicate} datasetsDuplicate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCopyFlowSampleDataApiV1FlowsFlowIdCopySampleDataPost(flowId: string, datasetsDuplicate: DatasetsDuplicate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCopyFlowSampleDataApiV1FlowsFlowIdCopySampleDataPost(flowId, datasetsDuplicate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowsApi.postCopyFlowSampleDataApiV1FlowsFlowIdCopySampleDataPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Flow
         * @param {string} id 
         * @param {FlowUpdate} flowUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFlowApiV1FlowsIdPatch(id: string, flowUpdate: FlowUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFlowApiV1FlowsIdPatch(id, flowUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FlowsApi.updateFlowApiV1FlowsIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FlowsApi - factory interface
 * @export
 */
export const FlowsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlowsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Flow
         * @param {FlowCreatePopulated} flowCreatePopulated 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlowApiV1FlowsPost(flowCreatePopulated: FlowCreatePopulated, options?: any): AxiosPromise<FlowDb> {
            return localVarFp.createFlowApiV1FlowsPost(flowCreatePopulated, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Flow
         * @param {string} flowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFlowApiV1FlowsFlowIdDelete(flowId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFlowApiV1FlowsFlowIdDelete(flowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Default Version Flow Docs
         * @param {string} flowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultVersionFlowDocsApiV1FlowsFlowIdDocsGet(flowId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getDefaultVersionFlowDocsApiV1FlowsFlowIdDocsGet(flowId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the active traffic policy summary for a flow. Only includes the information necessary for routing based on traffic policies.
         * @summary Get Flow Active Policy Summary
         * @param {string} workspaceId 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowActivePolicySummaryApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugActiveTrafficPolicySummaryGet(workspaceId: string, slug: string, options?: any): AxiosPromise<FlowDbActivePolicySummary> {
            return localVarFp.getFlowActivePolicySummaryApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugActiveTrafficPolicySummaryGet(workspaceId, slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Flow
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowApiV1FlowsIdGet(id: string, options?: any): AxiosPromise<FlowDbShallowSansLinkedFlows> {
            return localVarFp.getFlowApiV1FlowsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Flow By Slug
         * @param {string} workspaceId 
         * @param {string} slug 
         * @param {string} [versionNameFilter] 
         * @param {string} [versionIdFilter] 
         * @param {boolean} [defaultVersionFilter] 
         * @param {boolean} [defaultSandboxVersionFilter] 
         * @param {string} [minFlowVersionEtagFilter] 
         * @param {boolean} [shallow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowBySlugApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugGet(workspaceId: string, slug: string, versionNameFilter?: string, versionIdFilter?: string, defaultVersionFilter?: boolean, defaultSandboxVersionFilter?: boolean, minFlowVersionEtagFilter?: string, shallow?: boolean, options?: any): AxiosPromise<FlowDbDataplane> {
            return localVarFp.getFlowBySlugApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugGet(workspaceId, slug, versionNameFilter, versionIdFilter, defaultVersionFilter, defaultSandboxVersionFilter, minFlowVersionEtagFilter, shallow, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of flows.
         * @summary Get Flows
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [workspaceId] 
         * @param {string} [flowFolderId] 
         * @param {GetFlowsApiV1FlowsGetOrderByEnum} [orderBy] 
         * @param {boolean} [includeInternalFlows] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowsApiV1FlowsGet(skip?: number, limit?: number, workspaceId?: string, flowFolderId?: string, orderBy?: GetFlowsApiV1FlowsGetOrderByEnum, includeInternalFlows?: boolean, options?: any): AxiosPromise<Array<FlowDbShallowNoTrafficPolicies>> {
            return localVarFp.getFlowsApiV1FlowsGet(skip, limit, workspaceId, flowFolderId, orderBy, includeInternalFlows, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Published Flows Using Resource
         * @param {string} workspaceId 
         * @param {string} resourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedFlowsUsingResourceApiV1FlowsPublishedUsingConnectResourceWorkspaceWorkspaceIdResourceResourceIdGet(workspaceId: string, resourceId: string, options?: any): AxiosPromise<Array<FlowDbShallow>> {
            return localVarFp.getPublishedFlowsUsingResourceApiV1FlowsPublishedUsingConnectResourceWorkspaceWorkspaceIdResourceResourceIdGet(workspaceId, resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Versioned Flow Docs
         * @param {string} flowId 
         * @param {string} versionId 
         * @param {boolean} [includeOutcomes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionedFlowDocsApiV1FlowsFlowIdVersionsVersionIdDocsGet(flowId: string, versionId: string, includeOutcomes?: boolean, options?: any): AxiosPromise<any> {
            return localVarFp.getVersionedFlowDocsApiV1FlowsFlowIdVersionsVersionIdDocsGet(flowId, versionId, includeOutcomes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Copy Flow
         * @param {string} flowId 
         * @param {FlowDuplicate} flowDuplicate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCopyFlowApiV1FlowsFlowIdCopyPost(flowId: string, flowDuplicate: FlowDuplicate, options?: any): AxiosPromise<CopyFlowResponse> {
            return localVarFp.postCopyFlowApiV1FlowsFlowIdCopyPost(flowId, flowDuplicate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Copy Flow Sample Data
         * @param {string} flowId 
         * @param {DatasetsDuplicate} datasetsDuplicate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCopyFlowSampleDataApiV1FlowsFlowIdCopySampleDataPost(flowId: string, datasetsDuplicate: DatasetsDuplicate, options?: any): AxiosPromise<any> {
            return localVarFp.postCopyFlowSampleDataApiV1FlowsFlowIdCopySampleDataPost(flowId, datasetsDuplicate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Flow
         * @param {string} id 
         * @param {FlowUpdate} flowUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlowApiV1FlowsIdPatch(id: string, flowUpdate: FlowUpdate, options?: any): AxiosPromise<any> {
            return localVarFp.updateFlowApiV1FlowsIdPatch(id, flowUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlowsApi - object-oriented interface
 * @export
 * @class FlowsApi
 * @extends {BaseAPI}
 */
export class FlowsApi extends BaseAPI {
    /**
     * 
     * @summary Create Flow
     * @param {FlowCreatePopulated} flowCreatePopulated 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public createFlowApiV1FlowsPost(flowCreatePopulated: FlowCreatePopulated, options?: RawAxiosRequestConfig) {
        return FlowsApiFp(this.configuration).createFlowApiV1FlowsPost(flowCreatePopulated, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Flow
     * @param {string} flowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public deleteFlowApiV1FlowsFlowIdDelete(flowId: string, options?: RawAxiosRequestConfig) {
        return FlowsApiFp(this.configuration).deleteFlowApiV1FlowsFlowIdDelete(flowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Default Version Flow Docs
     * @param {string} flowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public getDefaultVersionFlowDocsApiV1FlowsFlowIdDocsGet(flowId: string, options?: RawAxiosRequestConfig) {
        return FlowsApiFp(this.configuration).getDefaultVersionFlowDocsApiV1FlowsFlowIdDocsGet(flowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the active traffic policy summary for a flow. Only includes the information necessary for routing based on traffic policies.
     * @summary Get Flow Active Policy Summary
     * @param {string} workspaceId 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public getFlowActivePolicySummaryApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugActiveTrafficPolicySummaryGet(workspaceId: string, slug: string, options?: RawAxiosRequestConfig) {
        return FlowsApiFp(this.configuration).getFlowActivePolicySummaryApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugActiveTrafficPolicySummaryGet(workspaceId, slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Flow
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public getFlowApiV1FlowsIdGet(id: string, options?: RawAxiosRequestConfig) {
        return FlowsApiFp(this.configuration).getFlowApiV1FlowsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Flow By Slug
     * @param {string} workspaceId 
     * @param {string} slug 
     * @param {string} [versionNameFilter] 
     * @param {string} [versionIdFilter] 
     * @param {boolean} [defaultVersionFilter] 
     * @param {boolean} [defaultSandboxVersionFilter] 
     * @param {string} [minFlowVersionEtagFilter] 
     * @param {boolean} [shallow] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public getFlowBySlugApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugGet(workspaceId: string, slug: string, versionNameFilter?: string, versionIdFilter?: string, defaultVersionFilter?: boolean, defaultSandboxVersionFilter?: boolean, minFlowVersionEtagFilter?: string, shallow?: boolean, options?: RawAxiosRequestConfig) {
        return FlowsApiFp(this.configuration).getFlowBySlugApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugGet(workspaceId, slug, versionNameFilter, versionIdFilter, defaultVersionFilter, defaultSandboxVersionFilter, minFlowVersionEtagFilter, shallow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of flows.
     * @summary Get Flows
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [workspaceId] 
     * @param {string} [flowFolderId] 
     * @param {GetFlowsApiV1FlowsGetOrderByEnum} [orderBy] 
     * @param {boolean} [includeInternalFlows] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public getFlowsApiV1FlowsGet(skip?: number, limit?: number, workspaceId?: string, flowFolderId?: string, orderBy?: GetFlowsApiV1FlowsGetOrderByEnum, includeInternalFlows?: boolean, options?: RawAxiosRequestConfig) {
        return FlowsApiFp(this.configuration).getFlowsApiV1FlowsGet(skip, limit, workspaceId, flowFolderId, orderBy, includeInternalFlows, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Published Flows Using Resource
     * @param {string} workspaceId 
     * @param {string} resourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public getPublishedFlowsUsingResourceApiV1FlowsPublishedUsingConnectResourceWorkspaceWorkspaceIdResourceResourceIdGet(workspaceId: string, resourceId: string, options?: RawAxiosRequestConfig) {
        return FlowsApiFp(this.configuration).getPublishedFlowsUsingResourceApiV1FlowsPublishedUsingConnectResourceWorkspaceWorkspaceIdResourceResourceIdGet(workspaceId, resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Versioned Flow Docs
     * @param {string} flowId 
     * @param {string} versionId 
     * @param {boolean} [includeOutcomes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public getVersionedFlowDocsApiV1FlowsFlowIdVersionsVersionIdDocsGet(flowId: string, versionId: string, includeOutcomes?: boolean, options?: RawAxiosRequestConfig) {
        return FlowsApiFp(this.configuration).getVersionedFlowDocsApiV1FlowsFlowIdVersionsVersionIdDocsGet(flowId, versionId, includeOutcomes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Copy Flow
     * @param {string} flowId 
     * @param {FlowDuplicate} flowDuplicate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public postCopyFlowApiV1FlowsFlowIdCopyPost(flowId: string, flowDuplicate: FlowDuplicate, options?: RawAxiosRequestConfig) {
        return FlowsApiFp(this.configuration).postCopyFlowApiV1FlowsFlowIdCopyPost(flowId, flowDuplicate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Copy Flow Sample Data
     * @param {string} flowId 
     * @param {DatasetsDuplicate} datasetsDuplicate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public postCopyFlowSampleDataApiV1FlowsFlowIdCopySampleDataPost(flowId: string, datasetsDuplicate: DatasetsDuplicate, options?: RawAxiosRequestConfig) {
        return FlowsApiFp(this.configuration).postCopyFlowSampleDataApiV1FlowsFlowIdCopySampleDataPost(flowId, datasetsDuplicate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Flow
     * @param {string} id 
     * @param {FlowUpdate} flowUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public updateFlowApiV1FlowsIdPatch(id: string, flowUpdate: FlowUpdate, options?: RawAxiosRequestConfig) {
        return FlowsApiFp(this.configuration).updateFlowApiV1FlowsIdPatch(id, flowUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum GetFlowsApiV1FlowsGetOrderByEnum {
    UPDATED_AT_TRAVERSED = 'updated_at_traversed',
    NAME = 'name'
}


/**
 * FoldersApi - axios parameter creator
 * @export
 */
export const FoldersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Folder
         * @param {FlowFolderCreate} flowFolderCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolderApiV1FoldersPost: async (flowFolderCreate: FlowFolderCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowFolderCreate' is not null or undefined
            assertParamExists('createFolderApiV1FoldersPost', 'flowFolderCreate', flowFolderCreate)
            const localVarPath = `/api/v1/folders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(flowFolderCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Folder
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolderApiV1FoldersFolderIdDelete: async (folderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('deleteFolderApiV1FoldersFolderIdDelete', 'folderId', folderId)
            const localVarPath = `/api/v1/folders/{folder_id}`
                .replace(`{${"folder_id"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query folders for a workspace.
         * @summary Get Folders
         * @param {string} workspaceId 
         * @param {boolean} [includeInternalFlowsInCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoldersApiV1FoldersWorkspaceIdGet: async (workspaceId: string, includeInternalFlowsInCount?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getFoldersApiV1FoldersWorkspaceIdGet', 'workspaceId', workspaceId)
            const localVarPath = `/api/v1/folders/{workspace_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (includeInternalFlowsInCount !== undefined) {
                localVarQueryParameter['include_internal_flows_in_count'] = includeInternalFlowsInCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Folder
         * @param {string} folderId 
         * @param {FlowFolderUpdate} flowFolderUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolderApiV1FoldersFolderIdPatch: async (folderId: string, flowFolderUpdate: FlowFolderUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('updateFolderApiV1FoldersFolderIdPatch', 'folderId', folderId)
            // verify required parameter 'flowFolderUpdate' is not null or undefined
            assertParamExists('updateFolderApiV1FoldersFolderIdPatch', 'flowFolderUpdate', flowFolderUpdate)
            const localVarPath = `/api/v1/folders/{folder_id}`
                .replace(`{${"folder_id"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(flowFolderUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FoldersApi - functional programming interface
 * @export
 */
export const FoldersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FoldersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Folder
         * @param {FlowFolderCreate} flowFolderCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFolderApiV1FoldersPost(flowFolderCreate: FlowFolderCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowFolderDb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFolderApiV1FoldersPost(flowFolderCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoldersApi.createFolderApiV1FoldersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Folder
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFolderApiV1FoldersFolderIdDelete(folderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFolderApiV1FoldersFolderIdDelete(folderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoldersApi.deleteFolderApiV1FoldersFolderIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query folders for a workspace.
         * @summary Get Folders
         * @param {string} workspaceId 
         * @param {boolean} [includeInternalFlowsInCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFoldersApiV1FoldersWorkspaceIdGet(workspaceId: string, includeInternalFlowsInCount?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowFolderListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFoldersApiV1FoldersWorkspaceIdGet(workspaceId, includeInternalFlowsInCount, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoldersApi.getFoldersApiV1FoldersWorkspaceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Folder
         * @param {string} folderId 
         * @param {FlowFolderUpdate} flowFolderUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFolderApiV1FoldersFolderIdPatch(folderId: string, flowFolderUpdate: FlowFolderUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowFolderDb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFolderApiV1FoldersFolderIdPatch(folderId, flowFolderUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoldersApi.updateFolderApiV1FoldersFolderIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FoldersApi - factory interface
 * @export
 */
export const FoldersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FoldersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Folder
         * @param {FlowFolderCreate} flowFolderCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolderApiV1FoldersPost(flowFolderCreate: FlowFolderCreate, options?: any): AxiosPromise<FlowFolderDb> {
            return localVarFp.createFolderApiV1FoldersPost(flowFolderCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Folder
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolderApiV1FoldersFolderIdDelete(folderId: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteFolderApiV1FoldersFolderIdDelete(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Query folders for a workspace.
         * @summary Get Folders
         * @param {string} workspaceId 
         * @param {boolean} [includeInternalFlowsInCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoldersApiV1FoldersWorkspaceIdGet(workspaceId: string, includeInternalFlowsInCount?: boolean, options?: any): AxiosPromise<FlowFolderListResponse> {
            return localVarFp.getFoldersApiV1FoldersWorkspaceIdGet(workspaceId, includeInternalFlowsInCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Folder
         * @param {string} folderId 
         * @param {FlowFolderUpdate} flowFolderUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolderApiV1FoldersFolderIdPatch(folderId: string, flowFolderUpdate: FlowFolderUpdate, options?: any): AxiosPromise<FlowFolderDb> {
            return localVarFp.updateFolderApiV1FoldersFolderIdPatch(folderId, flowFolderUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FoldersApi - object-oriented interface
 * @export
 * @class FoldersApi
 * @extends {BaseAPI}
 */
export class FoldersApi extends BaseAPI {
    /**
     * 
     * @summary Create Folder
     * @param {FlowFolderCreate} flowFolderCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public createFolderApiV1FoldersPost(flowFolderCreate: FlowFolderCreate, options?: RawAxiosRequestConfig) {
        return FoldersApiFp(this.configuration).createFolderApiV1FoldersPost(flowFolderCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Folder
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public deleteFolderApiV1FoldersFolderIdDelete(folderId: string, options?: RawAxiosRequestConfig) {
        return FoldersApiFp(this.configuration).deleteFolderApiV1FoldersFolderIdDelete(folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query folders for a workspace.
     * @summary Get Folders
     * @param {string} workspaceId 
     * @param {boolean} [includeInternalFlowsInCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public getFoldersApiV1FoldersWorkspaceIdGet(workspaceId: string, includeInternalFlowsInCount?: boolean, options?: RawAxiosRequestConfig) {
        return FoldersApiFp(this.configuration).getFoldersApiV1FoldersWorkspaceIdGet(workspaceId, includeInternalFlowsInCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Folder
     * @param {string} folderId 
     * @param {FlowFolderUpdate} flowFolderUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public updateFolderApiV1FoldersFolderIdPatch(folderId: string, flowFolderUpdate: FlowFolderUpdate, options?: RawAxiosRequestConfig) {
        return FoldersApiFp(this.configuration).updateFolderApiV1FoldersFolderIdPatch(folderId, flowFolderUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Keepalive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keepaliveApiV2KeepaliveGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/keepalive/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Keepalive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keepaliveApiV2KeepaliveGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keepaliveApiV2KeepaliveGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobsApi.keepaliveApiV2KeepaliveGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobsApiFp(configuration)
    return {
        /**
         * 
         * @summary Keepalive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keepaliveApiV2KeepaliveGet(options?: any): AxiosPromise<any> {
            return localVarFp.keepaliveApiV2KeepaliveGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
    /**
     * 
     * @summary Keepalive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public keepaliveApiV2KeepaliveGet(options?: RawAxiosRequestConfig) {
        return JobsApiFp(this.configuration).keepaliveApiV2KeepaliveGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OutcomeTypesApi - axios parameter creator
 * @export
 */
export const OutcomeTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clear Outcome Type Reports
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearOutcomeTypeReportsApiV1OutcomeTypesIdClearReportsDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clearOutcomeTypeReportsApiV1OutcomeTypesIdClearReportsDelete', 'id', id)
            const localVarPath = `/api/v1/outcome_types/{id}/clear_reports`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Outcome Type
         * @param {OutcomeTypeCreate} outcomeTypeCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOutcomeTypeApiV1OutcomeTypesPost: async (outcomeTypeCreate: OutcomeTypeCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'outcomeTypeCreate' is not null or undefined
            assertParamExists('createOutcomeTypeApiV1OutcomeTypesPost', 'outcomeTypeCreate', outcomeTypeCreate)
            const localVarPath = `/api/v1/outcome_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(outcomeTypeCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Outcome Types For Flow
         * @param {string} flowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutcomeTypesForFlowApiV1OutcomeTypesGet: async (flowId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('getOutcomeTypesForFlowApiV1OutcomeTypesGet', 'flowId', flowId)
            const localVarPath = `/api/v1/outcome_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (flowId !== undefined) {
                localVarQueryParameter['flow_id'] = flowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Outcome Type
         * @param {string} id 
         * @param {OutcomeTypePatch} outcomeTypePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOutcomeTypeApiV1OutcomeTypesIdPatch: async (id: string, outcomeTypePatch: OutcomeTypePatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchOutcomeTypeApiV1OutcomeTypesIdPatch', 'id', id)
            // verify required parameter 'outcomeTypePatch' is not null or undefined
            assertParamExists('patchOutcomeTypeApiV1OutcomeTypesIdPatch', 'outcomeTypePatch', outcomeTypePatch)
            const localVarPath = `/api/v1/outcome_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(outcomeTypePatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start Outcome Type Deletion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startOutcomeTypeDeletionApiV1OutcomeTypesIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('startOutcomeTypeDeletionApiV1OutcomeTypesIdDelete', 'id', id)
            const localVarPath = `/api/v1/outcome_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutcomeTypesApi - functional programming interface
 * @export
 */
export const OutcomeTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OutcomeTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Clear Outcome Type Reports
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearOutcomeTypeReportsApiV1OutcomeTypesIdClearReportsDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearOutcomeTypeReportsApiV1OutcomeTypesIdClearReportsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OutcomeTypesApi.clearOutcomeTypeReportsApiV1OutcomeTypesIdClearReportsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Outcome Type
         * @param {OutcomeTypeCreate} outcomeTypeCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOutcomeTypeApiV1OutcomeTypesPost(outcomeTypeCreate: OutcomeTypeCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutcomeType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOutcomeTypeApiV1OutcomeTypesPost(outcomeTypeCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OutcomeTypesApi.createOutcomeTypeApiV1OutcomeTypesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Outcome Types For Flow
         * @param {string} flowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutcomeTypesForFlowApiV1OutcomeTypesGet(flowId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutcomeType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOutcomeTypesForFlowApiV1OutcomeTypesGet(flowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OutcomeTypesApi.getOutcomeTypesForFlowApiV1OutcomeTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Patch Outcome Type
         * @param {string} id 
         * @param {OutcomeTypePatch} outcomeTypePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOutcomeTypeApiV1OutcomeTypesIdPatch(id: string, outcomeTypePatch: OutcomeTypePatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutcomeType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOutcomeTypeApiV1OutcomeTypesIdPatch(id, outcomeTypePatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OutcomeTypesApi.patchOutcomeTypeApiV1OutcomeTypesIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Start Outcome Type Deletion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startOutcomeTypeDeletionApiV1OutcomeTypesIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startOutcomeTypeDeletionApiV1OutcomeTypesIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OutcomeTypesApi.startOutcomeTypeDeletionApiV1OutcomeTypesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OutcomeTypesApi - factory interface
 * @export
 */
export const OutcomeTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OutcomeTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary Clear Outcome Type Reports
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearOutcomeTypeReportsApiV1OutcomeTypesIdClearReportsDelete(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.clearOutcomeTypeReportsApiV1OutcomeTypesIdClearReportsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Outcome Type
         * @param {OutcomeTypeCreate} outcomeTypeCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOutcomeTypeApiV1OutcomeTypesPost(outcomeTypeCreate: OutcomeTypeCreate, options?: any): AxiosPromise<OutcomeType> {
            return localVarFp.createOutcomeTypeApiV1OutcomeTypesPost(outcomeTypeCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Outcome Types For Flow
         * @param {string} flowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutcomeTypesForFlowApiV1OutcomeTypesGet(flowId: string, options?: any): AxiosPromise<Array<OutcomeType>> {
            return localVarFp.getOutcomeTypesForFlowApiV1OutcomeTypesGet(flowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Outcome Type
         * @param {string} id 
         * @param {OutcomeTypePatch} outcomeTypePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOutcomeTypeApiV1OutcomeTypesIdPatch(id: string, outcomeTypePatch: OutcomeTypePatch, options?: any): AxiosPromise<OutcomeType> {
            return localVarFp.patchOutcomeTypeApiV1OutcomeTypesIdPatch(id, outcomeTypePatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start Outcome Type Deletion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startOutcomeTypeDeletionApiV1OutcomeTypesIdDelete(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.startOutcomeTypeDeletionApiV1OutcomeTypesIdDelete(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutcomeTypesApi - object-oriented interface
 * @export
 * @class OutcomeTypesApi
 * @extends {BaseAPI}
 */
export class OutcomeTypesApi extends BaseAPI {
    /**
     * 
     * @summary Clear Outcome Type Reports
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutcomeTypesApi
     */
    public clearOutcomeTypeReportsApiV1OutcomeTypesIdClearReportsDelete(id: string, options?: RawAxiosRequestConfig) {
        return OutcomeTypesApiFp(this.configuration).clearOutcomeTypeReportsApiV1OutcomeTypesIdClearReportsDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Outcome Type
     * @param {OutcomeTypeCreate} outcomeTypeCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutcomeTypesApi
     */
    public createOutcomeTypeApiV1OutcomeTypesPost(outcomeTypeCreate: OutcomeTypeCreate, options?: RawAxiosRequestConfig) {
        return OutcomeTypesApiFp(this.configuration).createOutcomeTypeApiV1OutcomeTypesPost(outcomeTypeCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Outcome Types For Flow
     * @param {string} flowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutcomeTypesApi
     */
    public getOutcomeTypesForFlowApiV1OutcomeTypesGet(flowId: string, options?: RawAxiosRequestConfig) {
        return OutcomeTypesApiFp(this.configuration).getOutcomeTypesForFlowApiV1OutcomeTypesGet(flowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Outcome Type
     * @param {string} id 
     * @param {OutcomeTypePatch} outcomeTypePatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutcomeTypesApi
     */
    public patchOutcomeTypeApiV1OutcomeTypesIdPatch(id: string, outcomeTypePatch: OutcomeTypePatch, options?: RawAxiosRequestConfig) {
        return OutcomeTypesApiFp(this.configuration).patchOutcomeTypeApiV1OutcomeTypesIdPatch(id, outcomeTypePatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start Outcome Type Deletion
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutcomeTypesApi
     */
    public startOutcomeTypeDeletionApiV1OutcomeTypesIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return OutcomeTypesApiFp(this.configuration).startOutcomeTypeDeletionApiV1OutcomeTypesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReviewsApi - axios parameter creator
 * @export
 */
export const ReviewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Review
         * @param {string} flowVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewApiV1ReviewsGet: async (flowVersionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowVersionId' is not null or undefined
            assertParamExists('getReviewApiV1ReviewsGet', 'flowVersionId', flowVersionId)
            const localVarPath = `/api/v1/reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (flowVersionId !== undefined) {
                localVarQueryParameter['flow_version_id'] = flowVersionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Reviews
         * @param {string} reviewId 
         * @param {ReviewUpsert} reviewUpsert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchReviewsApiV1ReviewsReviewIdPatch: async (reviewId: string, reviewUpsert: ReviewUpsert, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewId' is not null or undefined
            assertParamExists('patchReviewsApiV1ReviewsReviewIdPatch', 'reviewId', reviewId)
            // verify required parameter 'reviewUpsert' is not null or undefined
            assertParamExists('patchReviewsApiV1ReviewsReviewIdPatch', 'reviewUpsert', reviewUpsert)
            const localVarPath = `/api/v1/reviews/{review_id}`
                .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewUpsert, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReviewsApi - functional programming interface
 * @export
 */
export const ReviewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReviewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Review
         * @param {string} flowVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewApiV1ReviewsGet(flowVersionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewDb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewApiV1ReviewsGet(flowVersionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewsApi.getReviewApiV1ReviewsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Patch Reviews
         * @param {string} reviewId 
         * @param {ReviewUpsert} reviewUpsert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchReviewsApiV1ReviewsReviewIdPatch(reviewId: string, reviewUpsert: ReviewUpsert, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewDb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchReviewsApiV1ReviewsReviewIdPatch(reviewId, reviewUpsert, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewsApi.patchReviewsApiV1ReviewsReviewIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReviewsApi - factory interface
 * @export
 */
export const ReviewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReviewsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Review
         * @param {string} flowVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewApiV1ReviewsGet(flowVersionId: string, options?: any): AxiosPromise<ReviewDb> {
            return localVarFp.getReviewApiV1ReviewsGet(flowVersionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Reviews
         * @param {string} reviewId 
         * @param {ReviewUpsert} reviewUpsert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchReviewsApiV1ReviewsReviewIdPatch(reviewId: string, reviewUpsert: ReviewUpsert, options?: any): AxiosPromise<ReviewDb> {
            return localVarFp.patchReviewsApiV1ReviewsReviewIdPatch(reviewId, reviewUpsert, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReviewsApi - object-oriented interface
 * @export
 * @class ReviewsApi
 * @extends {BaseAPI}
 */
export class ReviewsApi extends BaseAPI {
    /**
     * 
     * @summary Get Review
     * @param {string} flowVersionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    public getReviewApiV1ReviewsGet(flowVersionId: string, options?: RawAxiosRequestConfig) {
        return ReviewsApiFp(this.configuration).getReviewApiV1ReviewsGet(flowVersionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Reviews
     * @param {string} reviewId 
     * @param {ReviewUpsert} reviewUpsert 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewsApi
     */
    public patchReviewsApiV1ReviewsReviewIdPatch(reviewId: string, reviewUpsert: ReviewUpsert, options?: RawAxiosRequestConfig) {
        return ReviewsApiFp(this.configuration).patchReviewsApiV1ReviewsReviewIdPatch(reviewId, reviewUpsert, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TrafficPoliciesApi - axios parameter creator
 * @export
 */
export const TrafficPoliciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate Traffic Policy
         * @param {string} trafficPolicyId 
         * @param {string} flowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdActivatePost: async (trafficPolicyId: string, flowId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trafficPolicyId' is not null or undefined
            assertParamExists('activateTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdActivatePost', 'trafficPolicyId', trafficPolicyId)
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('activateTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdActivatePost', 'flowId', flowId)
            const localVarPath = `/api/v1/traffic_policies/{traffic_policy_id}/activate`
                .replace(`{${"traffic_policy_id"}}`, encodeURIComponent(String(trafficPolicyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (flowId !== undefined) {
                localVarQueryParameter['flow_id'] = flowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Traffic Policy
         * @param {TrafficPolicyCreateService} trafficPolicyCreateService 
         * @param {boolean} [activate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrafficPolicyApiV1TrafficPoliciesPost: async (trafficPolicyCreateService: TrafficPolicyCreateService, activate?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trafficPolicyCreateService' is not null or undefined
            assertParamExists('createTrafficPolicyApiV1TrafficPoliciesPost', 'trafficPolicyCreateService', trafficPolicyCreateService)
            const localVarPath = `/api/v1/traffic_policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (activate !== undefined) {
                localVarQueryParameter['activate'] = activate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trafficPolicyCreateService, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Traffic Policy
         * @param {string} trafficPolicyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdGet: async (trafficPolicyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trafficPolicyId' is not null or undefined
            assertParamExists('getTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdGet', 'trafficPolicyId', trafficPolicyId)
            const localVarPath = `/api/v1/traffic_policies/{traffic_policy_id}`
                .replace(`{${"traffic_policy_id"}}`, encodeURIComponent(String(trafficPolicyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Flow Traffic Policies
         * @param {string} flowId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFlowTrafficPoliciesApiV1TrafficPoliciesGet: async (flowId: string, skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('listFlowTrafficPoliciesApiV1TrafficPoliciesGet', 'flowId', flowId)
            const localVarPath = `/api/v1/traffic_policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (flowId !== undefined) {
                localVarQueryParameter['flow_id'] = flowId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrafficPoliciesApi - functional programming interface
 * @export
 */
export const TrafficPoliciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrafficPoliciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate Traffic Policy
         * @param {string} trafficPolicyId 
         * @param {string} flowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdActivatePost(trafficPolicyId: string, flowId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrafficPolicyInDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdActivatePost(trafficPolicyId, flowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrafficPoliciesApi.activateTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdActivatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Traffic Policy
         * @param {TrafficPolicyCreateService} trafficPolicyCreateService 
         * @param {boolean} [activate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTrafficPolicyApiV1TrafficPoliciesPost(trafficPolicyCreateService: TrafficPolicyCreateService, activate?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrafficPolicyInDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTrafficPolicyApiV1TrafficPoliciesPost(trafficPolicyCreateService, activate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrafficPoliciesApi.createTrafficPolicyApiV1TrafficPoliciesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Traffic Policy
         * @param {string} trafficPolicyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdGet(trafficPolicyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrafficPolicyInDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdGet(trafficPolicyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrafficPoliciesApi.getTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Flow Traffic Policies
         * @param {string} flowId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFlowTrafficPoliciesApiV1TrafficPoliciesGet(flowId: string, skip?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrafficPolicyInDB>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFlowTrafficPoliciesApiV1TrafficPoliciesGet(flowId, skip, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrafficPoliciesApi.listFlowTrafficPoliciesApiV1TrafficPoliciesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TrafficPoliciesApi - factory interface
 * @export
 */
export const TrafficPoliciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrafficPoliciesApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate Traffic Policy
         * @param {string} trafficPolicyId 
         * @param {string} flowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdActivatePost(trafficPolicyId: string, flowId: string, options?: any): AxiosPromise<TrafficPolicyInDB> {
            return localVarFp.activateTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdActivatePost(trafficPolicyId, flowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Traffic Policy
         * @param {TrafficPolicyCreateService} trafficPolicyCreateService 
         * @param {boolean} [activate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrafficPolicyApiV1TrafficPoliciesPost(trafficPolicyCreateService: TrafficPolicyCreateService, activate?: boolean, options?: any): AxiosPromise<TrafficPolicyInDB> {
            return localVarFp.createTrafficPolicyApiV1TrafficPoliciesPost(trafficPolicyCreateService, activate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Traffic Policy
         * @param {string} trafficPolicyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdGet(trafficPolicyId: string, options?: any): AxiosPromise<TrafficPolicyInDB> {
            return localVarFp.getTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdGet(trafficPolicyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Flow Traffic Policies
         * @param {string} flowId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFlowTrafficPoliciesApiV1TrafficPoliciesGet(flowId: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<TrafficPolicyInDB>> {
            return localVarFp.listFlowTrafficPoliciesApiV1TrafficPoliciesGet(flowId, skip, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrafficPoliciesApi - object-oriented interface
 * @export
 * @class TrafficPoliciesApi
 * @extends {BaseAPI}
 */
export class TrafficPoliciesApi extends BaseAPI {
    /**
     * 
     * @summary Activate Traffic Policy
     * @param {string} trafficPolicyId 
     * @param {string} flowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrafficPoliciesApi
     */
    public activateTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdActivatePost(trafficPolicyId: string, flowId: string, options?: RawAxiosRequestConfig) {
        return TrafficPoliciesApiFp(this.configuration).activateTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdActivatePost(trafficPolicyId, flowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Traffic Policy
     * @param {TrafficPolicyCreateService} trafficPolicyCreateService 
     * @param {boolean} [activate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrafficPoliciesApi
     */
    public createTrafficPolicyApiV1TrafficPoliciesPost(trafficPolicyCreateService: TrafficPolicyCreateService, activate?: boolean, options?: RawAxiosRequestConfig) {
        return TrafficPoliciesApiFp(this.configuration).createTrafficPolicyApiV1TrafficPoliciesPost(trafficPolicyCreateService, activate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Traffic Policy
     * @param {string} trafficPolicyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrafficPoliciesApi
     */
    public getTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdGet(trafficPolicyId: string, options?: RawAxiosRequestConfig) {
        return TrafficPoliciesApiFp(this.configuration).getTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdGet(trafficPolicyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Flow Traffic Policies
     * @param {string} flowId 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrafficPoliciesApi
     */
    public listFlowTrafficPoliciesApiV1TrafficPoliciesGet(flowId: string, skip?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return TrafficPoliciesApiFp(this.configuration).listFlowTrafficPoliciesApiV1TrafficPoliciesGet(flowId, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TypingApi - axios parameter creator
 * @export
 */
export const TypingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Node Types
         * @param {string} flowVersionId 
         * @param {string} nodeId 
         * @param {boolean} [isLoopNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeTypesApiV1TypesFlowVersionsFlowVersionIdNodesNodeIdGet: async (flowVersionId: string, nodeId: string, isLoopNode?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowVersionId' is not null or undefined
            assertParamExists('getNodeTypesApiV1TypesFlowVersionsFlowVersionIdNodesNodeIdGet', 'flowVersionId', flowVersionId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getNodeTypesApiV1TypesFlowVersionsFlowVersionIdNodesNodeIdGet', 'nodeId', nodeId)
            const localVarPath = `/api/v1/types/flow_versions/{flow_version_id}/nodes/{node_id}`
                .replace(`{${"flow_version_id"}}`, encodeURIComponent(String(flowVersionId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (isLoopNode !== undefined) {
                localVarQueryParameter['is_loop_node'] = isLoopNode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TypingApi - functional programming interface
 * @export
 */
export const TypingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TypingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Node Types
         * @param {string} flowVersionId 
         * @param {string} nodeId 
         * @param {boolean} [isLoopNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodeTypesApiV1TypesFlowVersionsFlowVersionIdNodesNodeIdGet(flowVersionId: string, nodeId: string, isLoopNode?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypingInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodeTypesApiV1TypesFlowVersionsFlowVersionIdNodesNodeIdGet(flowVersionId, nodeId, isLoopNode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypingApi.getNodeTypesApiV1TypesFlowVersionsFlowVersionIdNodesNodeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TypingApi - factory interface
 * @export
 */
export const TypingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TypingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Node Types
         * @param {string} flowVersionId 
         * @param {string} nodeId 
         * @param {boolean} [isLoopNode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeTypesApiV1TypesFlowVersionsFlowVersionIdNodesNodeIdGet(flowVersionId: string, nodeId: string, isLoopNode?: boolean, options?: any): AxiosPromise<TypingInfo> {
            return localVarFp.getNodeTypesApiV1TypesFlowVersionsFlowVersionIdNodesNodeIdGet(flowVersionId, nodeId, isLoopNode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TypingApi - object-oriented interface
 * @export
 * @class TypingApi
 * @extends {BaseAPI}
 */
export class TypingApi extends BaseAPI {
    /**
     * 
     * @summary Get Node Types
     * @param {string} flowVersionId 
     * @param {string} nodeId 
     * @param {boolean} [isLoopNode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypingApi
     */
    public getNodeTypesApiV1TypesFlowVersionsFlowVersionIdNodesNodeIdGet(flowVersionId: string, nodeId: string, isLoopNode?: boolean, options?: RawAxiosRequestConfig) {
        return TypingApiFp(this.configuration).getNodeTypesApiV1TypesFlowVersionsFlowVersionIdNodesNodeIdGet(flowVersionId, nodeId, isLoopNode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Webhooks
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebhooksApiV1WebhooksKeyDelete: async (key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteWebhooksApiV1WebhooksKeyDelete', 'key', key)
            const localVarPath = `/api/v1/webhooks/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Webhooks
         * @param {ResourceType} [resourceType] 
         * @param {string} [resourceId] 
         * @param {string} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWebhooksApiV1WebhooksGet: async (resourceType?: ResourceType, resourceId?: string, workspaceId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/webhooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (resourceType !== undefined) {
                localVarQueryParameter['resource_type'] = resourceType;
            }

            if (resourceId !== undefined) {
                localVarQueryParameter['resource_id'] = resourceId;
            }

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspace_id'] = workspaceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Webhook
         * @param {string} key 
         * @param {Webhook} webhook 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWebhookApiV1WebhooksKeyPut: async (key: string, webhook: Webhook, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('setWebhookApiV1WebhooksKeyPut', 'key', key)
            // verify required parameter 'webhook' is not null or undefined
            assertParamExists('setWebhookApiV1WebhooksKeyPut', 'webhook', webhook)
            const localVarPath = `/api/v1/webhooks/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhook, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Webhooks
         * @param {string} key 
         * @param {WebhookUpdate} webhookUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebhooksApiV1WebhooksKeyPatch: async (key: string, webhookUpdate: WebhookUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('updateWebhooksApiV1WebhooksKeyPatch', 'key', key)
            // verify required parameter 'webhookUpdate' is not null or undefined
            assertParamExists('updateWebhooksApiV1WebhooksKeyPatch', 'webhookUpdate', webhookUpdate)
            const localVarPath = `/api/v1/webhooks/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhookUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Webhooks
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWebhooksApiV1WebhooksKeyDelete(key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWebhooksApiV1WebhooksKeyDelete(key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.deleteWebhooksApiV1WebhooksKeyDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Webhooks
         * @param {ResourceType} [resourceType] 
         * @param {string} [resourceId] 
         * @param {string} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWebhooksApiV1WebhooksGet(resourceType?: ResourceType, resourceId?: string, workspaceId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Webhook>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWebhooksApiV1WebhooksGet(resourceType, resourceId, workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.listWebhooksApiV1WebhooksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Set Webhook
         * @param {string} key 
         * @param {Webhook} webhook 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setWebhookApiV1WebhooksKeyPut(key: string, webhook: Webhook, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setWebhookApiV1WebhooksKeyPut(key, webhook, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.setWebhookApiV1WebhooksKeyPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Webhooks
         * @param {string} key 
         * @param {WebhookUpdate} webhookUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWebhooksApiV1WebhooksKeyPatch(key: string, webhookUpdate: WebhookUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWebhooksApiV1WebhooksKeyPatch(key, webhookUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.updateWebhooksApiV1WebhooksKeyPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Webhooks
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebhooksApiV1WebhooksKeyDelete(key: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWebhooksApiV1WebhooksKeyDelete(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Webhooks
         * @param {ResourceType} [resourceType] 
         * @param {string} [resourceId] 
         * @param {string} [workspaceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWebhooksApiV1WebhooksGet(resourceType?: ResourceType, resourceId?: string, workspaceId?: string, options?: any): AxiosPromise<Array<Webhook>> {
            return localVarFp.listWebhooksApiV1WebhooksGet(resourceType, resourceId, workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Webhook
         * @param {string} key 
         * @param {Webhook} webhook 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWebhookApiV1WebhooksKeyPut(key: string, webhook: Webhook, options?: any): AxiosPromise<Webhook> {
            return localVarFp.setWebhookApiV1WebhooksKeyPut(key, webhook, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Webhooks
         * @param {string} key 
         * @param {WebhookUpdate} webhookUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebhooksApiV1WebhooksKeyPatch(key: string, webhookUpdate: WebhookUpdate, options?: any): AxiosPromise<Webhook> {
            return localVarFp.updateWebhooksApiV1WebhooksKeyPatch(key, webhookUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * 
     * @summary Delete Webhooks
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public deleteWebhooksApiV1WebhooksKeyDelete(key: string, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).deleteWebhooksApiV1WebhooksKeyDelete(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Webhooks
     * @param {ResourceType} [resourceType] 
     * @param {string} [resourceId] 
     * @param {string} [workspaceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public listWebhooksApiV1WebhooksGet(resourceType?: ResourceType, resourceId?: string, workspaceId?: string, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).listWebhooksApiV1WebhooksGet(resourceType, resourceId, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Webhook
     * @param {string} key 
     * @param {Webhook} webhook 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public setWebhookApiV1WebhooksKeyPut(key: string, webhook: Webhook, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).setWebhookApiV1WebhooksKeyPut(key, webhook, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Webhooks
     * @param {string} key 
     * @param {WebhookUpdate} webhookUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public updateWebhooksApiV1WebhooksKeyPatch(key: string, webhookUpdate: WebhookUpdate, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).updateWebhooksApiV1WebhooksKeyPatch(key, webhookUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WorkspacesApi - axios parameter creator
 * @export
 */
export const WorkspacesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Workspace
         * @param {WorkspaceCreate} workspaceCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspaceApiV1WorkspacesPost: async (workspaceCreate: WorkspaceCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceCreate' is not null or undefined
            assertParamExists('createWorkspaceApiV1WorkspacesPost', 'workspaceCreate', workspaceCreate)
            const localVarPath = `/api/v1/workspaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Workspace
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspaceApiV1WorkspacesWorkspaceIdDelete: async (workspaceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteWorkspaceApiV1WorkspacesWorkspaceIdDelete', 'workspaceId', workspaceId)
            const localVarPath = `/api/v1/workspaces/{workspace_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Media Url
         * @param {string} filePath 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaUrlApiV1WorkspacesWorkspaceIdMediaFilePathGet: async (filePath: string, workspaceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filePath' is not null or undefined
            assertParamExists('getMediaUrlApiV1WorkspacesWorkspaceIdMediaFilePathGet', 'filePath', filePath)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getMediaUrlApiV1WorkspacesWorkspaceIdMediaFilePathGet', 'workspaceId', workspaceId)
            const localVarPath = `/api/v1/workspaces/{workspace_id}/media/{file_path}`
                .replace(`{${"file_path"}}`, encodeURIComponent(String(filePath)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Workspace
         * @param {string} workspaceId 
         * @param {boolean} [includeSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceApiV1WorkspacesWorkspaceIdGet: async (workspaceId: string, includeSettings?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceApiV1WorkspacesWorkspaceIdGet', 'workspaceId', workspaceId)
            const localVarPath = `/api/v1/workspaces/{workspace_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (includeSettings !== undefined) {
                localVarQueryParameter['include_settings'] = includeSettings;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Workspaces
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [includeSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesApiV1WorkspacesGet: async (skip?: number, limit?: number, includeSettings?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workspaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (includeSettings !== undefined) {
                localVarQueryParameter['include_settings'] = includeSettings;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoApiV1WorkspacesInfoGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workspaces/_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invalidate Workspace Cache
         * @param {string} workspaceId 
         * @param {string} realm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateWorkspaceCacheApiV1WorkspacesWorkspaceIdInvalidateCacheRealmPost: async (workspaceId: string, realm: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('invalidateWorkspaceCacheApiV1WorkspacesWorkspaceIdInvalidateCacheRealmPost', 'workspaceId', workspaceId)
            // verify required parameter 'realm' is not null or undefined
            assertParamExists('invalidateWorkspaceCacheApiV1WorkspacesWorkspaceIdInvalidateCacheRealmPost', 'realm', realm)
            const localVarPath = `/api/v1/workspaces/{workspace_id}/_invalidate_cache/{realm}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"realm"}}`, encodeURIComponent(String(realm)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invalidate Workspace Cache Key
         * @param {string} workspaceId 
         * @param {string} realm 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateWorkspaceCacheKeyApiV1WorkspacesWorkspaceIdInvalidateCacheRealmKeyPost: async (workspaceId: string, realm: string, key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('invalidateWorkspaceCacheKeyApiV1WorkspacesWorkspaceIdInvalidateCacheRealmKeyPost', 'workspaceId', workspaceId)
            // verify required parameter 'realm' is not null or undefined
            assertParamExists('invalidateWorkspaceCacheKeyApiV1WorkspacesWorkspaceIdInvalidateCacheRealmKeyPost', 'realm', realm)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('invalidateWorkspaceCacheKeyApiV1WorkspacesWorkspaceIdInvalidateCacheRealmKeyPost', 'key', key)
            const localVarPath = `/api/v1/workspaces/{workspace_id}/_invalidate_cache/{realm}/{key}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Workspace
         * @param {string} workspaceId 
         * @param {WorkspacePatch} workspacePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceApiV1WorkspacesWorkspaceIdPatch: async (workspaceId: string, workspacePatch: WorkspacePatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateWorkspaceApiV1WorkspacesWorkspaceIdPatch', 'workspaceId', workspaceId)
            // verify required parameter 'workspacePatch' is not null or undefined
            assertParamExists('updateWorkspaceApiV1WorkspacesWorkspaceIdPatch', 'workspacePatch', workspacePatch)
            const localVarPath = `/api/v1/workspaces/{workspace_id}`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacePatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspacesApi - functional programming interface
 * @export
 */
export const WorkspacesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspacesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Workspace
         * @param {WorkspaceCreate} workspaceCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkspaceApiV1WorkspacesPost(workspaceCreate: WorkspaceCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDataplane>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkspaceApiV1WorkspacesPost(workspaceCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.createWorkspaceApiV1WorkspacesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Workspace
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkspaceApiV1WorkspacesWorkspaceIdDelete(workspaceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkspaceApiV1WorkspacesWorkspaceIdDelete(workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.deleteWorkspaceApiV1WorkspacesWorkspaceIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Media Url
         * @param {string} filePath 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaUrlApiV1WorkspacesWorkspaceIdMediaFilePathGet(filePath: string, workspaceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMediaUrlApiV1WorkspacesWorkspaceIdMediaFilePathGet(filePath, workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.getMediaUrlApiV1WorkspacesWorkspaceIdMediaFilePathGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Workspace
         * @param {string} workspaceId 
         * @param {boolean} [includeSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceApiV1WorkspacesWorkspaceIdGet(workspaceId: string, includeSettings?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDataplane>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceApiV1WorkspacesWorkspaceIdGet(workspaceId, includeSettings, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.getWorkspaceApiV1WorkspacesWorkspaceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Workspaces
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [includeSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspacesApiV1WorkspacesGet(skip?: number, limit?: number, includeSettings?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkspaceDataplane>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspacesApiV1WorkspacesGet(skip, limit, includeSettings, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.getWorkspacesApiV1WorkspacesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoApiV1WorkspacesInfoGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.infoApiV1WorkspacesInfoGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.infoApiV1WorkspacesInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Invalidate Workspace Cache
         * @param {string} workspaceId 
         * @param {string} realm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invalidateWorkspaceCacheApiV1WorkspacesWorkspaceIdInvalidateCacheRealmPost(workspaceId: string, realm: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invalidateWorkspaceCacheApiV1WorkspacesWorkspaceIdInvalidateCacheRealmPost(workspaceId, realm, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.invalidateWorkspaceCacheApiV1WorkspacesWorkspaceIdInvalidateCacheRealmPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Invalidate Workspace Cache Key
         * @param {string} workspaceId 
         * @param {string} realm 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invalidateWorkspaceCacheKeyApiV1WorkspacesWorkspaceIdInvalidateCacheRealmKeyPost(workspaceId: string, realm: string, key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invalidateWorkspaceCacheKeyApiV1WorkspacesWorkspaceIdInvalidateCacheRealmKeyPost(workspaceId, realm, key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.invalidateWorkspaceCacheKeyApiV1WorkspacesWorkspaceIdInvalidateCacheRealmKeyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Workspace
         * @param {string} workspaceId 
         * @param {WorkspacePatch} workspacePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkspaceApiV1WorkspacesWorkspaceIdPatch(workspaceId: string, workspacePatch: WorkspacePatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDataplane>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkspaceApiV1WorkspacesWorkspaceIdPatch(workspaceId, workspacePatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkspacesApi.updateWorkspaceApiV1WorkspacesWorkspaceIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkspacesApi - factory interface
 * @export
 */
export const WorkspacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspacesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Workspace
         * @param {WorkspaceCreate} workspaceCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspaceApiV1WorkspacesPost(workspaceCreate: WorkspaceCreate, options?: any): AxiosPromise<WorkspaceDataplane> {
            return localVarFp.createWorkspaceApiV1WorkspacesPost(workspaceCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Workspace
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspaceApiV1WorkspacesWorkspaceIdDelete(workspaceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkspaceApiV1WorkspacesWorkspaceIdDelete(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Media Url
         * @param {string} filePath 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaUrlApiV1WorkspacesWorkspaceIdMediaFilePathGet(filePath: string, workspaceId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getMediaUrlApiV1WorkspacesWorkspaceIdMediaFilePathGet(filePath, workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Workspace
         * @param {string} workspaceId 
         * @param {boolean} [includeSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceApiV1WorkspacesWorkspaceIdGet(workspaceId: string, includeSettings?: boolean, options?: any): AxiosPromise<WorkspaceDataplane> {
            return localVarFp.getWorkspaceApiV1WorkspacesWorkspaceIdGet(workspaceId, includeSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Workspaces
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [includeSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesApiV1WorkspacesGet(skip?: number, limit?: number, includeSettings?: boolean, options?: any): AxiosPromise<Array<WorkspaceDataplane>> {
            return localVarFp.getWorkspacesApiV1WorkspacesGet(skip, limit, includeSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoApiV1WorkspacesInfoGet(options?: any): AxiosPromise<WorkspaceInfo> {
            return localVarFp.infoApiV1WorkspacesInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invalidate Workspace Cache
         * @param {string} workspaceId 
         * @param {string} realm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateWorkspaceCacheApiV1WorkspacesWorkspaceIdInvalidateCacheRealmPost(workspaceId: string, realm: string, options?: any): AxiosPromise<any> {
            return localVarFp.invalidateWorkspaceCacheApiV1WorkspacesWorkspaceIdInvalidateCacheRealmPost(workspaceId, realm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invalidate Workspace Cache Key
         * @param {string} workspaceId 
         * @param {string} realm 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateWorkspaceCacheKeyApiV1WorkspacesWorkspaceIdInvalidateCacheRealmKeyPost(workspaceId: string, realm: string, key: string, options?: any): AxiosPromise<any> {
            return localVarFp.invalidateWorkspaceCacheKeyApiV1WorkspacesWorkspaceIdInvalidateCacheRealmKeyPost(workspaceId, realm, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Workspace
         * @param {string} workspaceId 
         * @param {WorkspacePatch} workspacePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceApiV1WorkspacesWorkspaceIdPatch(workspaceId: string, workspacePatch: WorkspacePatch, options?: any): AxiosPromise<WorkspaceDataplane> {
            return localVarFp.updateWorkspaceApiV1WorkspacesWorkspaceIdPatch(workspaceId, workspacePatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspacesApi - object-oriented interface
 * @export
 * @class WorkspacesApi
 * @extends {BaseAPI}
 */
export class WorkspacesApi extends BaseAPI {
    /**
     * 
     * @summary Create Workspace
     * @param {WorkspaceCreate} workspaceCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public createWorkspaceApiV1WorkspacesPost(workspaceCreate: WorkspaceCreate, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).createWorkspaceApiV1WorkspacesPost(workspaceCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Workspace
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public deleteWorkspaceApiV1WorkspacesWorkspaceIdDelete(workspaceId: string, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).deleteWorkspaceApiV1WorkspacesWorkspaceIdDelete(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Media Url
     * @param {string} filePath 
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getMediaUrlApiV1WorkspacesWorkspaceIdMediaFilePathGet(filePath: string, workspaceId: string, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getMediaUrlApiV1WorkspacesWorkspaceIdMediaFilePathGet(filePath, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Workspace
     * @param {string} workspaceId 
     * @param {boolean} [includeSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkspaceApiV1WorkspacesWorkspaceIdGet(workspaceId: string, includeSettings?: boolean, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getWorkspaceApiV1WorkspacesWorkspaceIdGet(workspaceId, includeSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Workspaces
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {boolean} [includeSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkspacesApiV1WorkspacesGet(skip?: number, limit?: number, includeSettings?: boolean, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getWorkspacesApiV1WorkspacesGet(skip, limit, includeSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public infoApiV1WorkspacesInfoGet(options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).infoApiV1WorkspacesInfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invalidate Workspace Cache
     * @param {string} workspaceId 
     * @param {string} realm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public invalidateWorkspaceCacheApiV1WorkspacesWorkspaceIdInvalidateCacheRealmPost(workspaceId: string, realm: string, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).invalidateWorkspaceCacheApiV1WorkspacesWorkspaceIdInvalidateCacheRealmPost(workspaceId, realm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invalidate Workspace Cache Key
     * @param {string} workspaceId 
     * @param {string} realm 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public invalidateWorkspaceCacheKeyApiV1WorkspacesWorkspaceIdInvalidateCacheRealmKeyPost(workspaceId: string, realm: string, key: string, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).invalidateWorkspaceCacheKeyApiV1WorkspacesWorkspaceIdInvalidateCacheRealmKeyPost(workspaceId, realm, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Workspace
     * @param {string} workspaceId 
     * @param {WorkspacePatch} workspacePatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public updateWorkspaceApiV1WorkspacesWorkspaceIdPatch(workspaceId: string, workspacePatch: WorkspacePatch, options?: RawAxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).updateWorkspaceApiV1WorkspacesWorkspaceIdPatch(workspaceId, workspacePatch, options).then((request) => request(this.axios, this.basePath));
    }
}



