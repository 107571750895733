import { faEdit } from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/design-system/Tooltip";

type EditSecretButtonProps = {
  secretName: string;
  secretKey: string;
  toggleEditable: (key: string) => void;
};

export const EditSecretButton: React.FC<EditSecretButtonProps> = ({
  secretName,
  secretKey,
  toggleEditable,
}) => (
  <Tooltip placement="right" title={`Edit ${secretName.toLowerCase()}`} asChild>
    <div className="ml-2 flex">
      <Icon
        color="text-gray-500"
        dataLoc={`edit-provider-secret-${secretKey}`}
        icon={faEdit}
        size="xs"
        onClick={() => toggleEditable(secretKey)}
      />
    </div>
  </Tooltip>
);
