import {
  faArrowUpRight,
  faCircleInfo,
  faClone,
  faCodeCompare,
} from "@fortawesome/pro-regular-svg-icons";
import { useRef, useState } from "react";
import { useTimeout } from "usehooks-ts";
import { v4 } from "uuid";

import { AssignmentNodeEditor } from "src/assignmentNode/AssignmentNodeEditor";
import { Button } from "src/base-components/Button";
import { CodeEditor } from "src/base-components/CodeInput/CodeEditor";
import { EditorBoundsProvider } from "src/base-components/CodeInput/useEditorBounds";
import { Icon } from "src/base-components/Icon";
import { NodeDb, NodeTypes } from "src/clients/flow-api";
import {
  AssignmentNode,
  BeMappedNode,
  RuleNodeV2,
} from "src/constants/NodeDataTypes";
import { CopilotTextContent } from "src/copilot/CopilotTextContent";
import { InspectChangesModal } from "src/copilot/InspectChangesModal";
import { NodePill } from "src/copilot/NodePill";
import { useModal } from "src/design-system/Modal";
import { toastActions } from "src/design-system/Toast/utils";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { RuleV2Editor } from "src/ruleNodeV2Editor/RuleNodeV2Editor";
import { ErrorBoundary } from "src/utils/ErrorBoundary";
import { GraphTransform, SubGraphClipboard } from "src/utils/GraphUtils";
import { writeGraphToClipboard } from "src/utils/decideClipboard";

export type NodeSuggestionType = {
  nodeId: string;
  name: string;
  type: NodeDb["type"];
  meta: object;
};

type Props = {
  nodeSuggestion: NodeSuggestionType;
  originalNode: BeMappedNode;
  isPublished: boolean;
  delay?: number;
  onApply: (suggestion: NodeSuggestionType) => void;
};

const PublishedInfoBanner: React.FC = () => {
  return (
    <div className="mt-2 rounded-lg border border-gray-200 bg-gray-50">
      <div className="flex px-3 py-2">
        <Icon color="text-gray-500" icon={faCircleInfo} size="xs" />
        <span className="ml-1 text-gray-600 font-inter-medium-12px">
          You cannot edit a published flow — but you can copy this AI suggestion
          to a new draft version to test it.
        </span>
      </div>
    </div>
  );
};

const SuggestionContent: React.FC<{ suggestion: NodeSuggestionType }> = ({
  suggestion,
}) => {
  if (suggestion.type === NodeTypes.TRANSFORM && "src" in suggestion.meta) {
    return (
      <CodeEditor
        language="python"
        value={suggestion.meta.src as string}
        readOnly
      />
    );
  }

  if (suggestion.type === NodeTypes.ASSIGNMENT_NODE) {
    const node = GraphTransform.nodeMapper({
      ...suggestion,
      id: v4(),
    });
    return (
      <AssignmentNodeEditor
        displayedError={undefined}
        hideActionButtons={true}
        isReactive={false}
        selectedNode={node as AssignmentNode}
        immutable
        onUpdate={() => {}}
      />
    );
  }

  if (suggestion.type === NodeTypes.RULE_2) {
    const node = GraphTransform.nodeMapper({
      ...suggestion,
      id: v4(),
    });

    return (
      <RuleV2Editor
        displayedError={undefined}
        hideActionButtons={true}
        isReactive={false}
        selectedNode={node as RuleNodeV2}
        immutable
        onUpdate={() => {}}
      />
    );
  }

  throw new Error("Not implemented");
};

export const NodeSuggestion: React.FC<Props> = ({
  originalNode,
  nodeSuggestion,
  delay = 0,
  onApply,
  isPublished,
}) => {
  const { workspace } = useWorkspaceContext();
  const { isOpen, closeModal, openModal } = useModal();
  const containerRef = useRef<HTMLDivElement>(null);
  const [render, setRender] = useState(delay ? false : true);

  useTimeout(() => setRender(true), 1000 * delay);

  const nodeForPill = {
    ...originalNode,
    data: {
      ...originalNode.data,
      label: nodeSuggestion.name,
    },
  };

  const maxX = containerRef.current
    ? containerRef.current.getBoundingClientRect().x +
      containerRef.current.clientWidth
    : null;

  const handleCopy = async () => {
    const node = GraphTransform.nodeMapper({
      ...nodeSuggestion,
      id: nodeSuggestion.nodeId,
    });

    const newClipboard: SubGraphClipboard = {
      nodes: [node],
      edges: [],
      groups: [],
      workspaceId: workspace.id,
    };

    const success = await writeGraphToClipboard(newClipboard);

    if (success) {
      toastActions.success({ title: "1 node copied" });
    } else {
      toastActions.failure({
        title: "Copy to clipboard failed",
        description: "See the documentation for more information",
      });
    }
  };

  return (
    render && (
      <div
        ref={containerRef}
        className="my-4 rounded-lg border border-gray-200 p-2"
      >
        <ErrorBoundary
          fallbackErrorContent={
            <CopilotTextContent
              content="Unable to load node suggestion"
              delay={0}
            />
          }
        >
          <div className="w-40 pb-2">
            <NodePill node={nodeForPill} />
          </div>

          <div className="min-h-[200px] border-y border-y-gray-100 py-2">
            <EditorBoundsProvider maxX={maxX}>
              <SuggestionContent suggestion={nodeSuggestion} />
            </EditorBoundsProvider>
            {isPublished && <PublishedInfoBanner />}
          </div>

          <div className="flex justify-between pt-2">
            <Button
              iconLeft={faCodeCompare}
              size="sm"
              variant="secondary"
              onClick={openModal}
            >
              Inspect changes
            </Button>
            {isPublished ? (
              <Button
                iconLeft={faClone}
                size="sm"
                variant="secondary"
                onClick={handleCopy}
              >
                Copy Node
              </Button>
            ) : (
              <Button
                dataLoc="copilot-apply-suggestion"
                iconLeft={faArrowUpRight}
                size="sm"
                variant="secondary"
                onClick={() => onApply(nodeSuggestion)}
              >
                Apply changes
              </Button>
            )}
          </div>
          <InspectChangesModal
            isOpen={isOpen}
            nodeSuggestion={nodeSuggestion}
            originalNode={originalNode}
            onClose={closeModal}
          />
        </ErrorBoundary>
      </div>
    )
  );
};
