import { ApiInstance } from "src/api/ApiInstance";

export const metricsApi = new ApiInstance({
  pathPrefix: "/metrics/api/v1",
});

export const datasetsApi = new ApiInstance({
  pathPrefix: "/datasets/api/v1",
});

export const decisionHistoryApiRoute = new ApiInstance({
  pathPrefix: "/history/api/v2",
});

export const decisionHistoryV3ApiRoute = new ApiInstance({
  pathPrefix: "/history/api/v3",
});

export const reviewApi = new ApiInstance({
  pathPrefix: "/review/api/v1",
});

export const datasetJobsApi = new ApiInstance({
  pathPrefix: "/dataset_jobs/api/v1",
});

export const jobsApi = new ApiInstance({
  pathPrefix: "/jobs/api/v1",
});

export const jobsApiV2 = new ApiInstance({
  pathPrefix: "/jobs/api/v2",
});

export const s3BucketDatasetApi = new ApiInstance({
  pathPrefix: "/s3_bucket_datasets/api/v1",
});

export const outcomesApi = new ApiInstance({
  pathPrefix: "/outcomes/api/v1",
});
