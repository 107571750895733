import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import { InputMappingT, IntegrationResourceT } from "src/integrationNode/types";

const addressElements = (): { [key: string]: InputMappingT } => ({
  country: {
    id: uuidV4(),
    type: "text",
    displayName: "Country",
    assignedTo: "",
    rules: {
      required: true,
    },
    hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g, "DE" for Germany).`,
  },
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
  },
  house_number: {
    id: uuidV4(),
    type: "text",
    displayName: "House number",
    assignedTo: "",
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
  },
  province: {
    id: uuidV4(),
    type: "text",
    displayName: "Province",
    assignedTo: "",
    hint: `The state/region/county listed in the company's address (e.g., "CA" for California).`,
  },
});

export const getDefaultCreditsafeCompanySearch = (): IntegrationResourceT => ({
  providerResource: { provider: "creditsafe", resource: "company_search" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      customer_reference: {
        id: uuidV4(),
        type: "text",
        displayName: "Customer reference",
        assignedTo: "",
        hint: `Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.`,
      },
      confidence_threshold: {
        id: uuidV4(),
        type: "text",
        displayName: "Confidence threshold",
        assignedTo: "",
        hint: `Only companies with a match confidence score strictly higher than this threshold will be returned by Creditsafe. Confidence scores generally range between 900 and 999 (the higher the better). Moreover, there are two special scores: 899 (good company name but nothing else) and 898 (reasonable company name and some address data). Threshold defaults to 900.`,
      },
      company_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Company name",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: `For company names, Creditsafe uses fuzzy match rules to support flexible spelling, sequence, punctuation, etc. This maximizes the chance of a match.`,
      },
      registration_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Registration number",
        assignedTo: "",
        hint: `The company identifier associated with its Domestic Filing Agency (e.g., SIREN/SIRET for France, Companies House CRN for the UK).`,
      },
      vat_number: {
        id: uuidV4(),
        type: "text",
        displayName: "VAT number",
        assignedTo: "",
        hint: `A unique number used in many countries to identify a taxable business.`,
      },
      phone_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Phone number",
        assignedTo: "",
      },
      website_url: {
        id: uuidV4(),
        type: "text",
        displayName: "Website URL",
        assignedTo: "",
      },
    },
    grouped: {
      address: {
        getDefaultElements: addressElements,
        elements: addressElements(),
        rules: {
          required: true,
        },
      },
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      most_confident_connect_id: {
        ...getNamedOutputMapping("Most confident Connect ID"),
        hint: `The Connect ID is a unique string used to identify a business. While the Company Search returns all businesses that match the search parameters provided, this insight singles out the Connect ID of the most likely match. Use this id as an input to the Company Credit Report Node.`,
      },
      highest_confidence_score: {
        ...getNamedOutputMapping("Highest confidence score"),
        hint: `This code tells you how confident Creditsafe is that the top result is in fact the company being searched for. Confidence scores generally range between 900 and 999 (the higher the better). Moreover, there are two special scores: 899 (good company name but nothing else) and 898 (reasonable company name and some address data). For a better understanding, please check our docs.`,
      },
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultCreditsafeCompanyCreditReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "creditsafe",
      resource: "company_credit_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        connect_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Connect ID",
          assignedTo: "",
          rules: {
            required: true,
          },
          hint: `Every company in the Creditsafe database has a unique identifier. Obtain this ID by using a "Company identification" Node. Each Connect ID starts with the ISO code of the company's country (e.g., GB-0-07941364).`,
        },
        customer_reference: {
          id: uuidV4(),
          type: "text",
          displayName: "Customer reference",
          assignedTo: "",
          hint: `Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.`,
        },
      },
      grouped: {},
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(
          CommonOutputMappingKey.PROVIDER_REQUEST_ID,
          CommonOutputMappingKey.RETRIEVAL_DATETIME,
        ),
        credit_score: {
          ...getNamedOutputMapping("Credit score"),
          hint: `A country-specific credit score between 1 and 100, where 100 indicates the lowest risk. Since the scores differ between geographies, we recommend you use the "international credit score" instead, which is a normalized, easy-to-use version of the credit score.`,
        },
        international_credit_score: {
          ...getNamedOutputMapping("International credit score"),
          hint: `This score maps all of the country-specific credit scores to a simple set of risk categories. This makes sure you can use the score information without having to worry about geographic subtleties. Possible values: "A" (very low risk), "B" (low risk), "C" (moderate risk), "D" (high risk), "E" (not scored).`,
        },
        credit_limit: {
          ...getNamedOutputMapping("Credit limit"),
          hint: `Creditsafe's assessment of the company's credit limit, given as a floating point number. The currency is provided as a "credit limit currency" insight.`,
        },
        credit_limit_currency: {
          ...getNamedOutputMapping("Credit limit currency"),
          hint: `The currency in which the "Credit limit" insight is given (e.g., "EUR", "USD", "GBP")`,
        },
        pdf_report: {
          ...getNamedOutputMapping("PDF report"),
          hint: `The Base64-encoded PDF report of the company's credit information, if enabled in the Connection configuration`,
        },
      },
    },
    config: defaultResourceConfig,
  });
