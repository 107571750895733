import { faCube } from "@fortawesome/pro-regular-svg-icons";

import { EmptyState } from "src/design-system/EmptyState";
import {
  NoDecisionFlowSelected,
  NoDecisionFlowSelectedProps,
} from "src/parentFlowNodes/loopNode/NoDecisionFlowSelected";

export const ChildFlowNotAvailable: React.FC<NoDecisionFlowSelectedProps> = (
  props,
) => {
  return props.immutable ? (
    <EmptyState
      description="Called Decision Flow version has been deleted."
      headline="Version does not exist"
      icon={faCube}
    />
  ) : (
    <NoDecisionFlowSelected {...props} />
  );
};
