import React from "react";

import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { usePublishFlowVersion } from "src/api/flowVersionQueries";
import { TextConfirmationModal } from "src/base-components/TextConfirmationModal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { useUserData } from "src/store/AuthStore";
import { logger } from "src/utils/logger";

type RepublishVersionModalProps = {
  open: boolean;
  onClose: () => void;
  flow: FlowT;
  flowVersion: FlowVersionT | undefined;
  afterLeave?: () => void;
  onRepublish: () => void;
};

export const RepublishVersionModal: React.FC<RepublishVersionModalProps> = ({
  open,
  onClose,
  flow,
  flowVersion,
  afterLeave,
  onRepublish,
}) => {
  const { mutateAsync: publishVersion } = usePublishFlowVersion();
  const { signed_in_user_id } = useUserData();

  const republishFlowVersionHandler = async () => {
    if (!flowVersion) return;
    try {
      await publishVersion({
        publishArgs: {
          version: flowVersion,
          name: flowVersion.name,
          release_note: flowVersion.meta.release_note ?? "",
          published_by_id: signed_in_user_id ?? "",
          published_at: new Date(Date.now()).toISOString(),
        },
        enableTrafficPolicies: false,
      });
      toastActions.success({
        title: "Version republished successfully",
        description: `Version ${flowVersion.name} of ${flow.name} was published successfully.`,
      });
      onClose();
      onRepublish();
    } catch (error) {
      toastActions.failure({
        title: "Version republishing failed",
        description: TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(`Error when republishing flow: ${error}`);
    }
  };

  return (
    <>
      <TextConfirmationModal
        afterLeave={afterLeave}
        challengeText={flowVersion?.name}
        confirmButtonText="Republish version"
        description="Are you sure you want to publish this Decision Flow version again? API calls to this flow version will start working again."
        label="Please enter the version name to confirm"
        open={open}
        title={`Republish "${flowVersion?.name}" version`}
        variant="default"
        onClose={onClose}
        onConfirm={republishFlowVersionHandler}
      />
    </>
  );
};
