import { faEdit, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { MouseEventHandler } from "react";

import { Icon } from "src/base-components/Icon";
import { RawCell } from "src/dataTable/RawCell";
import { Tooltip } from "src/design-system/Tooltip";

type PropsT = {
  onEditClick: MouseEventHandler;
  onDeleteClick: MouseEventHandler;
};

export const ApiKeyActions: React.FC<PropsT> = ({
  onEditClick,
  onDeleteClick,
}) => {
  const actions = (
    <div className="flex items-center justify-end gap-x-2">
      <Tooltip placement="top" title="Rename" asChild>
        <Icon
          color="text-gray-500 hover:text-gray-700"
          icon={faEdit}
          size="xs"
          onClick={onEditClick}
        />
      </Tooltip>
      <Tooltip placement="top" title="Delete" asChild>
        <Icon
          color="text-gray-500 hover:text-gray-700"
          icon={faTrashCan}
          size="xs"
          onClick={onDeleteClick}
        />
      </Tooltip>
    </div>
  );

  return (
    <RawCell
      cell={actions}
      classNameOverrides="invisible flex items-right justify-end group-hover:visible max-w-0"
    />
  );
};
