import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import React, { useMemo } from "react";

import { useWorkspaceUsers } from "src/api/taktile/queries";
import { Icon } from "src/base-components/Icon";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import { CommentDb } from "src/clients/flow-api";
import { Tooltip } from "src/design-system/Tooltip";
import { AuthorPageParamsT } from "src/router/urls";
import { useCurrentUserId } from "src/store/AuthStore";
import { dateFromNow } from "src/utils/datetime";
import { useParamsDecode } from "src/utils/useParamsDecode";

type Props = {
  comment: CommentDb;
  disabled: boolean;
  editOnly?: boolean;
  onDelete: () => void;
  onEdit: () => void;
  onResolve?: () => void;
  onUnresolve?: () => void;
};

export const CommentActions: React.FC<Props> = ({
  comment,
  disabled,
  editOnly = false,
  onDelete,
  onEdit,
  onResolve,
  onUnresolve,
}) => {
  const { orgId, wsId } = useParamsDecode<AuthorPageParamsT>();
  const workspaceUsers = useWorkspaceUsers(orgId, wsId);

  const resolvedBy = useMemo(() => {
    if (!comment.resolved_by || !workspaceUsers.data) return null;
    const user = workspaceUsers.data.find((u) => u.id === comment.resolved_by);
    return user?.full_name || user?.username || null;
  }, [workspaceUsers.data, comment.resolved_by]);

  const userId = useCurrentUserId();
  const isAuthor = userId === comment.author;

  const resolvedByText = resolvedBy ? ` by ${resolvedBy}` : "";
  const options = [
    comment.resolved && !editOnly && { key: "Unresolve", action: onUnresolve },
    isAuthor && { key: "Edit", action: onEdit },
    isAuthor && !editOnly && { key: "Delete", action: onDelete },
  ].filter(Boolean) as { key: string; action: VoidFunction }[];

  return (
    <div
      className="flex flex-row items-center gap-x-1.5"
      data-loc="comment-actions"
    >
      {comment.resolved ? (
        <Tooltip
          placement="top"
          title={`${dateFromNow(comment.resolved_at!)}${resolvedByText}`}
          asChild
        >
          <div className="flex items-center">
            <Icon color="text-green-400" icon={faCheck} />
            <p className="text-green-600 font-inter-medium-12px">Resolved</p>
          </div>
        </Tooltip>
      ) : (
        onResolve &&
        !editOnly && (
          <Tooltip placement="top" title="Resolve" asChild>
            <Icon
              color="text-gray-500"
              dataLoc="resolve-comment"
              disabled={disabled}
              icon={faCheck}
              size="sm"
              onClick={onResolve}
            />
          </Tooltip>
        )
      )}
      {options.length > 0 && (
        <EllipsisOptionsDropdown
          buttonClassName="text-gray-500"
          buttonDataLoc="actions-menu"
          elements={options}
          iconSize="xs"
        />
      )}
    </div>
  );
};
