/* tslint:disable */
/* eslint-disable */
/**
 * History v3
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Data
 */
export interface Data {
}
/**
 * 
 * @export
 * @interface DatasetJobGenericError
 */
export interface DatasetJobGenericError {
    /**
     * 
     * @type {string}
     * @memberof DatasetJobGenericError
     */
    'message': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum DecideStatus {
    SUCCESS = 'success',
    ERROR = 'error',
    PENDING = 'pending',
    CANCELED = 'canceled'
}


/**
 * 
 * @export
 * @interface ExternalResource
 */
export interface ExternalResource {
    /**
     * 
     * @type {string}
     * @memberof ExternalResource
     */
    'node_id': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalResource
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalResource
     */
    'resource': string;
    /**
     * 
     * @type {Data}
     * @memberof ExternalResource
     */
    'data': Data;
    /**
     * 
     * @type {NodeTracing}
     * @memberof ExternalResource
     */
    'tracing': NodeTracing;
}
/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'property': string;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'operator': FilterOperatorEnum;
    /**
     * 
     * @type {any}
     * @memberof Filter
     */
    'value'?: any;
    /**
     * 
     * @type {Set<object>}
     * @memberof Filter
     */
    'values': Set<object>;
}

/**
    * @export
    * @enum {string}
    */
export enum FilterOperatorEnum {
    IN = 'in',
    NOT_IN = 'not_in'
}

/**
 * 
 * @export
 * @interface GetDecisionsOutcomeFilter
 */
export interface GetDecisionsOutcomeFilter {
    /**
     * 
     * @type {string}
     * @memberof GetDecisionsOutcomeFilter
     */
    'outcome_type_key': string;
    /**
     * 
     * @type {Filter}
     * @memberof GetDecisionsOutcomeFilter
     */
    'filter': Filter;
}
/**
 * Base is used for both listing and creating exports. Does not include page_size.
 * @export
 * @interface GetDecisionsParamsBase
 */
export interface GetDecisionsParamsBase {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDecisionsParamsBase
     */
    'flow_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDecisionsParamsBase
     */
    'flow_version_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDecisionsParamsBase
     */
    'statuses'?: Array<GetDecisionsParamsBaseStatusesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDecisionsParamsBase
     */
    'status_codes'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDecisionsParamsBase
     */
    'job_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDecisionsParamsBase
     */
    'job_run_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDecisionsParamsBase
     */
    'parent_decision_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDecisionsParamsBase
     */
    'parent_flow_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDecisionsParamsBase
     */
    'parent_flow_version_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDecisionsParamsBase
     */
    'environments'?: Array<GetDecisionsParamsBaseEnvironmentsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDecisionsParamsBase
     */
    'origin_types'?: Array<GetDecisionsParamsBaseOriginTypesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDecisionsParamsBase
     */
    'entity_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetDecisionsParamsBase
     */
    'start_start_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDecisionsParamsBase
     */
    'end_start_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDecisionsParamsBase
     */
    'starting_after'?: string;
    /**
     * 
     * @type {Array<GetDecisionsOutcomeFilter>}
     * @memberof GetDecisionsParamsBase
     */
    'outcome_filters'?: Array<GetDecisionsOutcomeFilter>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetDecisionsParamsBaseStatusesEnum {
    SUCCESSFUL = 'successful',
    PENDING = 'pending',
    FAILED = 'failed'
}
/**
    * @export
    * @enum {string}
    */
export enum GetDecisionsParamsBaseEnvironmentsEnum {
    LIVE = 'live',
    SANDBOX = 'sandbox'
}
/**
    * @export
    * @enum {string}
    */
export enum GetDecisionsParamsBaseOriginTypesEnum {
    API_CALL = 'api_call',
    JOB = 'job',
    FLOW = 'flow'
}

/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface HistoricalDecisionExportCreate
 */
export interface HistoricalDecisionExportCreate {
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionExportCreate
     */
    'format'?: HistoricalDecisionExportCreateFormatEnum;
    /**
     * 
     * @type {GetDecisionsParamsBase}
     * @memberof HistoricalDecisionExportCreate
     */
    'params': GetDecisionsParamsBase;
}

/**
    * @export
    * @enum {string}
    */
export enum HistoricalDecisionExportCreateFormatEnum {
    JSON = 'json',
    NDJSON = 'ndjson'
}

/**
 * Internal storage schema for historical decision export v3
 * @export
 * @interface HistoricalDecisionExportResponse
 */
export interface HistoricalDecisionExportResponse {
    /**
     * 
     * @type {HistoryExportV3ResultLocation}
     * @memberof HistoricalDecisionExportResponse
     */
    'location'?: HistoryExportV3ResultLocation;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionExportResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionExportResponse
     */
    'format'?: HistoricalDecisionExportResponseFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionExportResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionExportResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {GetDecisionsParamsBase}
     * @memberof HistoricalDecisionExportResponse
     */
    'params': GetDecisionsParamsBase;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionExportResponse
     */
    'status': HistoricalDecisionExportResponseStatusEnum;
    /**
     * 
     * @type {DatasetJobGenericError}
     * @memberof HistoricalDecisionExportResponse
     */
    'error'?: DatasetJobGenericError;
}

/**
    * @export
    * @enum {string}
    */
export enum HistoricalDecisionExportResponseFormatEnum {
    JSON = 'json',
    NDJSON = 'ndjson'
}
/**
    * @export
    * @enum {string}
    */
export enum HistoricalDecisionExportResponseStatusEnum {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}

/**
 * 
 * @export
 * @interface HistoricalDecisionV3
 */
export interface HistoricalDecisionV3 {
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3
     */
    'entity_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3
     */
    'status_code': string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3
     */
    'environment': string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3
     */
    'end_time': string | null;
    /**
     * 
     * @type {HistoricalDecisionV3Flow}
     * @memberof HistoricalDecisionV3
     */
    'flow': HistoricalDecisionV3Flow;
    /**
     * 
     * @type {HistoricalDecisionV3Origin}
     * @memberof HistoricalDecisionV3
     */
    'origin': HistoricalDecisionV3Origin;
    /**
     * 
     * @type {object}
     * @memberof HistoricalDecisionV3
     */
    'input': object;
    /**
     * 
     * @type {object}
     * @memberof HistoricalDecisionV3
     */
    'output': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof HistoricalDecisionV3
     */
    'child_decisions': Array<string>;
}
/**
 * 
 * @export
 * @interface HistoricalDecisionV3Flow
 */
export interface HistoricalDecisionV3Flow {
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3Flow
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3Flow
     */
    'slug'?: string;
    /**
     * 
     * @type {HistoricalDecisionV3FlowVersion}
     * @memberof HistoricalDecisionV3Flow
     */
    'version'?: HistoricalDecisionV3FlowVersion;
}
/**
 * 
 * @export
 * @interface HistoricalDecisionV3FlowVersion
 */
export interface HistoricalDecisionV3FlowVersion {
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3FlowVersion
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3FlowVersion
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3FlowVersion
     */
    'etag'?: string;
}
/**
 * 
 * @export
 * @interface HistoricalDecisionV3Origin
 */
export interface HistoricalDecisionV3Origin {
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3Origin
     */
    'type': HistoricalDecisionV3OriginTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3Origin
     */
    'parent_decision_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3Origin
     */
    'parent_flow_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3Origin
     */
    'parent_flow_version_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3Origin
     */
    'job_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionV3Origin
     */
    'job_run_id'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum HistoricalDecisionV3OriginTypeEnum {
    API_CALL = 'api_call',
    JOB = 'job',
    FLOW = 'flow'
}

/**
 * 
 * @export
 * @interface HistoricalDecisionsResponseV3
 */
export interface HistoricalDecisionsResponseV3 {
    /**
     * 
     * @type {Array<HistoricalDecisionV3>}
     * @memberof HistoricalDecisionsResponseV3
     */
    'decisions': Array<HistoricalDecisionV3>;
    /**
     * 
     * @type {string}
     * @memberof HistoricalDecisionsResponseV3
     */
    'next_page_token'?: string;
}
/**
 * 
 * @export
 * @interface HistoricalFlow
 */
export interface HistoricalFlow {
    /**
     * 
     * @type {string}
     * @memberof HistoricalFlow
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalFlow
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalFlow
     */
    'version_id': string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalFlow
     */
    'version_name': string;
}
/**
 * 
 * @export
 * @interface HistoricalRequest
 */
export interface HistoricalRequest {
    /**
     * 
     * @type {object}
     * @memberof HistoricalRequest
     */
    'data': object;
    /**
     * 
     * @type {HistoricalRequestMetadata}
     * @memberof HistoricalRequest
     */
    'metadata': HistoricalRequestMetadata;
}
/**
 * 
 * @export
 * @interface HistoricalRequestMetadata
 */
export interface HistoricalRequestMetadata {
    /**
     * 
     * @type {string}
     * @memberof HistoricalRequestMetadata
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalRequestMetadata
     */
    'entity_id'?: string;
}
/**
 * 
 * @export
 * @interface HistoricalResponse
 */
export interface HistoricalResponse {
    /**
     * 
     * @type {object}
     * @memberof HistoricalResponse
     */
    'data': object;
    /**
     * 
     * @type {HistoricalResponseMetadata}
     * @memberof HistoricalResponse
     */
    'metadata': HistoricalResponseMetadata;
    /**
     * 
     * @type {DecideStatus}
     * @memberof HistoricalResponse
     */
    'status'?: DecideStatus;
}


/**
 * 
 * @export
 * @interface HistoricalResponseError
 */
export interface HistoricalResponseError {
    /**
     * 
     * @type {object}
     * @memberof HistoricalResponseError
     */
    'detail': object;
    /**
     * 
     * @type {HistoricalResponseMetadata}
     * @memberof HistoricalResponseError
     */
    'metadata'?: HistoricalResponseMetadata;
}
/**
 * 
 * @export
 * @interface HistoricalResponseMetadata
 */
export interface HistoricalResponseMetadata {
    /**
     * 
     * @type {string}
     * @memberof HistoricalResponseMetadata
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalResponseMetadata
     */
    'entity_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalResponseMetadata
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalResponseMetadata
     */
    'decision_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalResponseMetadata
     */
    'job_run_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalResponseMetadata
     */
    'job_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalResponseMetadata
     */
    'traffic_policy'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalResponseMetadata
     */
    'environment'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalResponseMetadata
     */
    'decision_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalResponseMetadata
     */
    'flow_slug'?: string;
}
/**
 * 
 * @export
 * @interface HistoryExportV3ResultLocation
 */
export interface HistoryExportV3ResultLocation {
    /**
     * 
     * @type {string}
     * @memberof HistoryExportV3ResultLocation
     */
    'type': HistoryExportV3ResultLocationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof HistoryExportV3ResultLocation
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof HistoryExportV3ResultLocation
     */
    'expires_at': string;
}

/**
    * @export
    * @enum {string}
    */
export enum HistoryExportV3ResultLocationTypeEnum {
    S3 = 's3'
}

/**
 * 
 * @export
 * @interface LocationInner
 */
export interface LocationInner {
}
/**
 * 
 * @export
 * @interface MultiOutcomeFilter
 */
export interface MultiOutcomeFilter {
    /**
     * 
     * @type {string}
     * @memberof MultiOutcomeFilter
     */
    'property': string;
    /**
     * 
     * @type {string}
     * @memberof MultiOutcomeFilter
     */
    'operator': MultiOutcomeFilterOperatorEnum;
    /**
     * 
     * @type {Set<any>}
     * @memberof MultiOutcomeFilter
     */
    'values': Set<any>;
}

/**
    * @export
    * @enum {string}
    */
export enum MultiOutcomeFilterOperatorEnum {
    IN = 'in',
    NOT_IN = 'not_in'
}

/**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'title'?: string;
    /**
     * 
     * @type {object}
     * @memberof Node
     */
    'data': object;
    /**
     * 
     * @type {NodeTracing}
     * @memberof Node
     */
    'tracing': NodeTracing;
}
/**
 * 
 * @export
 * @interface NodeResultsV2
 */
export interface NodeResultsV2 {
    /**
     * 
     * @type {{ [key: string]: Node; }}
     * @memberof NodeResultsV2
     */
    'nodes': { [key: string]: Node; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof NodeResultsV2
     */
    'node_execution_metadata': { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface NodeTracing
 */
export interface NodeTracing {
    /**
     * 
     * @type {string}
     * @memberof NodeTracing
     */
    'start_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeTracing
     */
    'end_time'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NodeTracing
     */
    'error': boolean;
}
/**
 * 
 * @export
 * @interface SingleOutcomeFilter
 */
export interface SingleOutcomeFilter {
    /**
     * 
     * @type {string}
     * @memberof SingleOutcomeFilter
     */
    'property': string;
    /**
     * 
     * @type {string}
     * @memberof SingleOutcomeFilter
     */
    'operator': SingleOutcomeFilterOperatorEnum;
    /**
     * 
     * @type {any}
     * @memberof SingleOutcomeFilter
     */
    'value'?: any;
}

/**
    * @export
    * @enum {string}
    */
export enum SingleOutcomeFilterOperatorEnum {
    DOUBLE_EQUAL = '==',
    NOT_EQUAL = '!=',
    GREATER_THAN = '>',
    GREATER_THAN_OR_EQUAL_TO = '>=',
    LESS_THAN = '<',
    LESS_THAN_OR_EQUAL_TO = '<='
}

/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<LocationInner>}
     * @memberof ValidationError
     */
    'loc': Array<LocationInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}

/**
 * DecisionsApi - axios parameter creator
 * @export
 */
export const DecisionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Expire Decision
         * @param {string} decisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireDecisionDecisionsDecisionIdDelete: async (decisionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'decisionId' is not null or undefined
            assertParamExists('expireDecisionDecisionsDecisionIdDelete', 'decisionId', decisionId)
            const localVarPath = `/decisions/{decision_id}`
                .replace(`{${"decision_id"}}`, encodeURIComponent(String(decisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Decision
         * @param {string} decisionId 
         * @param {GetDecisionDecisionsDecisionIdGetViewEnum} [view] 
         * @param {string} [$fields] 
         * @param {string} [acceptEncoding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDecisionDecisionsDecisionIdGet: async (decisionId: string, view?: GetDecisionDecisionsDecisionIdGetViewEnum, $fields?: string, acceptEncoding?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'decisionId' is not null or undefined
            assertParamExists('getDecisionDecisionsDecisionIdGet', 'decisionId', decisionId)
            const localVarPath = `/decisions/{decision_id}`
                .replace(`{${"decision_id"}}`, encodeURIComponent(String(decisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (view !== undefined) {
                localVarQueryParameter['view'] = view;
            }

            if ($fields !== undefined) {
                localVarQueryParameter['$fields'] = $fields;
            }

            if (acceptEncoding != null) {
                localVarHeaderParameter['accept-encoding'] = String(acceptEncoding);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Decisions
         * @param {ListDecisionsDecisionsGetViewEnum} [view] 
         * @param {string} [startStartTime] 
         * @param {string} [endStartTime] 
         * @param {string} [startingAfter] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {string} [$fields] 
         * @param {string} [flowVersionIds] 
         * @param {string} [statuses] 
         * @param {string} [statusCodes] 
         * @param {string} [jobIds] 
         * @param {string} [jobRunIds] 
         * @param {string} [parentDecisionIds] 
         * @param {string} [parentFlowIds] 
         * @param {string} [parentFlowVersionIds] 
         * @param {string} [environments] 
         * @param {ListDecisionsDecisionsGetOriginTypesEnum} [originTypes] 
         * @param {string} [entityIds] 
         * @param {string} [flowIds] 
         * @param {string} [outcomeFilters] 
         * @param {string} [acceptEncoding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDecisionsDecisionsGet: async (view?: ListDecisionsDecisionsGetViewEnum, startStartTime?: string, endStartTime?: string, startingAfter?: string, pageToken?: string, pageSize?: number, $fields?: string, flowVersionIds?: string, statuses?: string, statusCodes?: string, jobIds?: string, jobRunIds?: string, parentDecisionIds?: string, parentFlowIds?: string, parentFlowVersionIds?: string, environments?: string, originTypes?: ListDecisionsDecisionsGetOriginTypesEnum, entityIds?: string, flowIds?: string, outcomeFilters?: string, acceptEncoding?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/decisions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (view !== undefined) {
                localVarQueryParameter['view'] = view;
            }

            if (startStartTime !== undefined) {
                localVarQueryParameter['start_start_time'] = (startStartTime as any instanceof Date) ?
                    (startStartTime as any).toISOString() :
                    startStartTime;
            }

            if (endStartTime !== undefined) {
                localVarQueryParameter['end_start_time'] = (endStartTime as any instanceof Date) ?
                    (endStartTime as any).toISOString() :
                    endStartTime;
            }

            if (startingAfter !== undefined) {
                localVarQueryParameter['starting_after'] = startingAfter;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if ($fields !== undefined) {
                localVarQueryParameter['$fields'] = $fields;
            }

            if (flowVersionIds !== undefined) {
                localVarQueryParameter['flow_version_ids'] = flowVersionIds;
            }

            if (statuses !== undefined) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (statusCodes !== undefined) {
                localVarQueryParameter['status_codes'] = statusCodes;
            }

            if (jobIds !== undefined) {
                localVarQueryParameter['job_ids'] = jobIds;
            }

            if (jobRunIds !== undefined) {
                localVarQueryParameter['job_run_ids'] = jobRunIds;
            }

            if (parentDecisionIds !== undefined) {
                localVarQueryParameter['parent_decision_ids'] = parentDecisionIds;
            }

            if (parentFlowIds !== undefined) {
                localVarQueryParameter['parent_flow_ids'] = parentFlowIds;
            }

            if (parentFlowVersionIds !== undefined) {
                localVarQueryParameter['parent_flow_version_ids'] = parentFlowVersionIds;
            }

            if (environments !== undefined) {
                localVarQueryParameter['environments'] = environments;
            }

            if (originTypes !== undefined) {
                localVarQueryParameter['origin_types'] = originTypes;
            }

            if (entityIds !== undefined) {
                localVarQueryParameter['entity_ids'] = entityIds;
            }

            if (flowIds !== undefined) {
                localVarQueryParameter['flow_ids'] = flowIds;
            }

            if (outcomeFilters !== undefined) {
                localVarQueryParameter['outcome_filters'] = outcomeFilters;
            }

            if (acceptEncoding != null) {
                localVarHeaderParameter['accept-encoding'] = String(acceptEncoding);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DecisionsApi - functional programming interface
 * @export
 */
export const DecisionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DecisionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Expire Decision
         * @param {string} decisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expireDecisionDecisionsDecisionIdDelete(decisionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expireDecisionDecisionsDecisionIdDelete(decisionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DecisionsApi.expireDecisionDecisionsDecisionIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Decision
         * @param {string} decisionId 
         * @param {GetDecisionDecisionsDecisionIdGetViewEnum} [view] 
         * @param {string} [$fields] 
         * @param {string} [acceptEncoding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDecisionDecisionsDecisionIdGet(decisionId: string, view?: GetDecisionDecisionsDecisionIdGetViewEnum, $fields?: string, acceptEncoding?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricalDecisionV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDecisionDecisionsDecisionIdGet(decisionId, view, $fields, acceptEncoding, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DecisionsApi.getDecisionDecisionsDecisionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Decisions
         * @param {ListDecisionsDecisionsGetViewEnum} [view] 
         * @param {string} [startStartTime] 
         * @param {string} [endStartTime] 
         * @param {string} [startingAfter] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {string} [$fields] 
         * @param {string} [flowVersionIds] 
         * @param {string} [statuses] 
         * @param {string} [statusCodes] 
         * @param {string} [jobIds] 
         * @param {string} [jobRunIds] 
         * @param {string} [parentDecisionIds] 
         * @param {string} [parentFlowIds] 
         * @param {string} [parentFlowVersionIds] 
         * @param {string} [environments] 
         * @param {ListDecisionsDecisionsGetOriginTypesEnum} [originTypes] 
         * @param {string} [entityIds] 
         * @param {string} [flowIds] 
         * @param {string} [outcomeFilters] 
         * @param {string} [acceptEncoding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDecisionsDecisionsGet(view?: ListDecisionsDecisionsGetViewEnum, startStartTime?: string, endStartTime?: string, startingAfter?: string, pageToken?: string, pageSize?: number, $fields?: string, flowVersionIds?: string, statuses?: string, statusCodes?: string, jobIds?: string, jobRunIds?: string, parentDecisionIds?: string, parentFlowIds?: string, parentFlowVersionIds?: string, environments?: string, originTypes?: ListDecisionsDecisionsGetOriginTypesEnum, entityIds?: string, flowIds?: string, outcomeFilters?: string, acceptEncoding?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricalDecisionsResponseV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDecisionsDecisionsGet(view, startStartTime, endStartTime, startingAfter, pageToken, pageSize, $fields, flowVersionIds, statuses, statusCodes, jobIds, jobRunIds, parentDecisionIds, parentFlowIds, parentFlowVersionIds, environments, originTypes, entityIds, flowIds, outcomeFilters, acceptEncoding, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DecisionsApi.listDecisionsDecisionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DecisionsApi - factory interface
 * @export
 */
export const DecisionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DecisionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Expire Decision
         * @param {DecisionsApiExpireDecisionDecisionsDecisionIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireDecisionDecisionsDecisionIdDelete(requestParameters: DecisionsApiExpireDecisionDecisionsDecisionIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.expireDecisionDecisionsDecisionIdDelete(requestParameters.decisionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Decision
         * @param {DecisionsApiGetDecisionDecisionsDecisionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDecisionDecisionsDecisionIdGet(requestParameters: DecisionsApiGetDecisionDecisionsDecisionIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<HistoricalDecisionV3> {
            return localVarFp.getDecisionDecisionsDecisionIdGet(requestParameters.decisionId, requestParameters.view, requestParameters.$fields, requestParameters.acceptEncoding, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Decisions
         * @param {DecisionsApiListDecisionsDecisionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDecisionsDecisionsGet(requestParameters: DecisionsApiListDecisionsDecisionsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<HistoricalDecisionsResponseV3> {
            return localVarFp.listDecisionsDecisionsGet(requestParameters.view, requestParameters.startStartTime, requestParameters.endStartTime, requestParameters.startingAfter, requestParameters.pageToken, requestParameters.pageSize, requestParameters.$fields, requestParameters.flowVersionIds, requestParameters.statuses, requestParameters.statusCodes, requestParameters.jobIds, requestParameters.jobRunIds, requestParameters.parentDecisionIds, requestParameters.parentFlowIds, requestParameters.parentFlowVersionIds, requestParameters.environments, requestParameters.originTypes, requestParameters.entityIds, requestParameters.flowIds, requestParameters.outcomeFilters, requestParameters.acceptEncoding, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for expireDecisionDecisionsDecisionIdDelete operation in DecisionsApi.
 * @export
 * @interface DecisionsApiExpireDecisionDecisionsDecisionIdDeleteRequest
 */
export interface DecisionsApiExpireDecisionDecisionsDecisionIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DecisionsApiExpireDecisionDecisionsDecisionIdDelete
     */
    readonly decisionId: string
}

/**
 * Request parameters for getDecisionDecisionsDecisionIdGet operation in DecisionsApi.
 * @export
 * @interface DecisionsApiGetDecisionDecisionsDecisionIdGetRequest
 */
export interface DecisionsApiGetDecisionDecisionsDecisionIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DecisionsApiGetDecisionDecisionsDecisionIdGet
     */
    readonly decisionId: string

    /**
     * 
     * @type {'basic' | 'integration_durations' | 'internal'}
     * @memberof DecisionsApiGetDecisionDecisionsDecisionIdGet
     */
    readonly view?: GetDecisionDecisionsDecisionIdGetViewEnum

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiGetDecisionDecisionsDecisionIdGet
     */
    readonly $fields?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiGetDecisionDecisionsDecisionIdGet
     */
    readonly acceptEncoding?: string
}

/**
 * Request parameters for listDecisionsDecisionsGet operation in DecisionsApi.
 * @export
 * @interface DecisionsApiListDecisionsDecisionsGetRequest
 */
export interface DecisionsApiListDecisionsDecisionsGetRequest {
    /**
     * 
     * @type {'basic' | 'internal'}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly view?: ListDecisionsDecisionsGetViewEnum

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly startStartTime?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly endStartTime?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly startingAfter?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly pageToken?: string

    /**
     * 
     * @type {number}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly $fields?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly flowVersionIds?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly statuses?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly statusCodes?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly jobIds?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly jobRunIds?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly parentDecisionIds?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly parentFlowIds?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly parentFlowVersionIds?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly environments?: string

    /**
     * 
     * @type {'api_call' | 'job' | 'flow'}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly originTypes?: ListDecisionsDecisionsGetOriginTypesEnum

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly entityIds?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly flowIds?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly outcomeFilters?: string

    /**
     * 
     * @type {string}
     * @memberof DecisionsApiListDecisionsDecisionsGet
     */
    readonly acceptEncoding?: string
}

/**
 * DecisionsApi - object-oriented interface
 * @export
 * @class DecisionsApi
 * @extends {BaseAPI}
 */
export class DecisionsApi extends BaseAPI {
    /**
     * 
     * @summary Expire Decision
     * @param {DecisionsApiExpireDecisionDecisionsDecisionIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DecisionsApi
     */
    public expireDecisionDecisionsDecisionIdDelete(requestParameters: DecisionsApiExpireDecisionDecisionsDecisionIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DecisionsApiFp(this.configuration).expireDecisionDecisionsDecisionIdDelete(requestParameters.decisionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Decision
     * @param {DecisionsApiGetDecisionDecisionsDecisionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DecisionsApi
     */
    public getDecisionDecisionsDecisionIdGet(requestParameters: DecisionsApiGetDecisionDecisionsDecisionIdGetRequest, options?: RawAxiosRequestConfig) {
        return DecisionsApiFp(this.configuration).getDecisionDecisionsDecisionIdGet(requestParameters.decisionId, requestParameters.view, requestParameters.$fields, requestParameters.acceptEncoding, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Decisions
     * @param {DecisionsApiListDecisionsDecisionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DecisionsApi
     */
    public listDecisionsDecisionsGet(requestParameters: DecisionsApiListDecisionsDecisionsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DecisionsApiFp(this.configuration).listDecisionsDecisionsGet(requestParameters.view, requestParameters.startStartTime, requestParameters.endStartTime, requestParameters.startingAfter, requestParameters.pageToken, requestParameters.pageSize, requestParameters.$fields, requestParameters.flowVersionIds, requestParameters.statuses, requestParameters.statusCodes, requestParameters.jobIds, requestParameters.jobRunIds, requestParameters.parentDecisionIds, requestParameters.parentFlowIds, requestParameters.parentFlowVersionIds, requestParameters.environments, requestParameters.originTypes, requestParameters.entityIds, requestParameters.flowIds, requestParameters.outcomeFilters, requestParameters.acceptEncoding, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum GetDecisionDecisionsDecisionIdGetViewEnum {
    BASIC = 'basic',
    INTEGRATION_DURATIONS = 'integration_durations',
    INTERNAL = 'internal'
}
/**
  * @export
  * @enum {string}
  */
export enum ListDecisionsDecisionsGetViewEnum {
    BASIC = 'basic',
    INTERNAL = 'internal'
}
/**
  * @export
  * @enum {string}
  */
export enum ListDecisionsDecisionsGetOriginTypesEnum {
    API_CALL = 'api_call',
    JOB = 'job',
    FLOW = 'flow'
}


/**
 * ExportsApi - axios parameter creator
 * @export
 */
export const ExportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export Decisions
         * @param {HistoricalDecisionExportCreate} historicalDecisionExportCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDecisionsDecisionsExportsPost: async (historicalDecisionExportCreate: HistoricalDecisionExportCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'historicalDecisionExportCreate' is not null or undefined
            assertParamExists('exportDecisionsDecisionsExportsPost', 'historicalDecisionExportCreate', historicalDecisionExportCreate)
            const localVarPath = `/decisions/exports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(historicalDecisionExportCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Export
         * @param {string} exportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportDecisionsExportsExportIdGet: async (exportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportId' is not null or undefined
            assertParamExists('getExportDecisionsExportsExportIdGet', 'exportId', exportId)
            const localVarPath = `/decisions/exports/{export_id}`
                .replace(`{${"export_id"}}`, encodeURIComponent(String(exportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Exports
         * @param {string} [startCreatedAt] 
         * @param {string} [endCreatedAt] 
         * @param {string} [startingAfter] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {string} [createdByIds] 
         * @param {string} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExportsDecisionsExportsGet: async (startCreatedAt?: string, endCreatedAt?: string, startingAfter?: string, pageToken?: string, pageSize?: number, createdByIds?: string, statuses?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/decisions/exports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (startCreatedAt !== undefined) {
                localVarQueryParameter['start_created_at'] = (startCreatedAt as any instanceof Date) ?
                    (startCreatedAt as any).toISOString() :
                    startCreatedAt;
            }

            if (endCreatedAt !== undefined) {
                localVarQueryParameter['end_created_at'] = (endCreatedAt as any instanceof Date) ?
                    (endCreatedAt as any).toISOString() :
                    endCreatedAt;
            }

            if (startingAfter !== undefined) {
                localVarQueryParameter['starting_after'] = startingAfter;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (createdByIds !== undefined) {
                localVarQueryParameter['created_by_ids'] = createdByIds;
            }

            if (statuses !== undefined) {
                localVarQueryParameter['statuses'] = statuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportsApi - functional programming interface
 * @export
 */
export const ExportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Export Decisions
         * @param {HistoricalDecisionExportCreate} historicalDecisionExportCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportDecisionsDecisionsExportsPost(historicalDecisionExportCreate: HistoricalDecisionExportCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricalDecisionExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportDecisionsDecisionsExportsPost(historicalDecisionExportCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExportsApi.exportDecisionsDecisionsExportsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Export
         * @param {string} exportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExportDecisionsExportsExportIdGet(exportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricalDecisionExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExportDecisionsExportsExportIdGet(exportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExportsApi.getExportDecisionsExportsExportIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Exports
         * @param {string} [startCreatedAt] 
         * @param {string} [endCreatedAt] 
         * @param {string} [startingAfter] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {string} [createdByIds] 
         * @param {string} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listExportsDecisionsExportsGet(startCreatedAt?: string, endCreatedAt?: string, startingAfter?: string, pageToken?: string, pageSize?: number, createdByIds?: string, statuses?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listExportsDecisionsExportsGet(startCreatedAt, endCreatedAt, startingAfter, pageToken, pageSize, createdByIds, statuses, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExportsApi.listExportsDecisionsExportsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExportsApi - factory interface
 * @export
 */
export const ExportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Export Decisions
         * @param {ExportsApiExportDecisionsDecisionsExportsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDecisionsDecisionsExportsPost(requestParameters: ExportsApiExportDecisionsDecisionsExportsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<HistoricalDecisionExportResponse> {
            return localVarFp.exportDecisionsDecisionsExportsPost(requestParameters.historicalDecisionExportCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Export
         * @param {ExportsApiGetExportDecisionsExportsExportIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportDecisionsExportsExportIdGet(requestParameters: ExportsApiGetExportDecisionsExportsExportIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<HistoricalDecisionExportResponse> {
            return localVarFp.getExportDecisionsExportsExportIdGet(requestParameters.exportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Exports
         * @param {ExportsApiListExportsDecisionsExportsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExportsDecisionsExportsGet(requestParameters: ExportsApiListExportsDecisionsExportsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.listExportsDecisionsExportsGet(requestParameters.startCreatedAt, requestParameters.endCreatedAt, requestParameters.startingAfter, requestParameters.pageToken, requestParameters.pageSize, requestParameters.createdByIds, requestParameters.statuses, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for exportDecisionsDecisionsExportsPost operation in ExportsApi.
 * @export
 * @interface ExportsApiExportDecisionsDecisionsExportsPostRequest
 */
export interface ExportsApiExportDecisionsDecisionsExportsPostRequest {
    /**
     * 
     * @type {HistoricalDecisionExportCreate}
     * @memberof ExportsApiExportDecisionsDecisionsExportsPost
     */
    readonly historicalDecisionExportCreate: HistoricalDecisionExportCreate
}

/**
 * Request parameters for getExportDecisionsExportsExportIdGet operation in ExportsApi.
 * @export
 * @interface ExportsApiGetExportDecisionsExportsExportIdGetRequest
 */
export interface ExportsApiGetExportDecisionsExportsExportIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExportsApiGetExportDecisionsExportsExportIdGet
     */
    readonly exportId: string
}

/**
 * Request parameters for listExportsDecisionsExportsGet operation in ExportsApi.
 * @export
 * @interface ExportsApiListExportsDecisionsExportsGetRequest
 */
export interface ExportsApiListExportsDecisionsExportsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExportsApiListExportsDecisionsExportsGet
     */
    readonly startCreatedAt?: string

    /**
     * 
     * @type {string}
     * @memberof ExportsApiListExportsDecisionsExportsGet
     */
    readonly endCreatedAt?: string

    /**
     * 
     * @type {string}
     * @memberof ExportsApiListExportsDecisionsExportsGet
     */
    readonly startingAfter?: string

    /**
     * 
     * @type {string}
     * @memberof ExportsApiListExportsDecisionsExportsGet
     */
    readonly pageToken?: string

    /**
     * 
     * @type {number}
     * @memberof ExportsApiListExportsDecisionsExportsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ExportsApiListExportsDecisionsExportsGet
     */
    readonly createdByIds?: string

    /**
     * 
     * @type {string}
     * @memberof ExportsApiListExportsDecisionsExportsGet
     */
    readonly statuses?: string
}

/**
 * ExportsApi - object-oriented interface
 * @export
 * @class ExportsApi
 * @extends {BaseAPI}
 */
export class ExportsApi extends BaseAPI {
    /**
     * 
     * @summary Export Decisions
     * @param {ExportsApiExportDecisionsDecisionsExportsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportsApi
     */
    public exportDecisionsDecisionsExportsPost(requestParameters: ExportsApiExportDecisionsDecisionsExportsPostRequest, options?: RawAxiosRequestConfig) {
        return ExportsApiFp(this.configuration).exportDecisionsDecisionsExportsPost(requestParameters.historicalDecisionExportCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Export
     * @param {ExportsApiGetExportDecisionsExportsExportIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportsApi
     */
    public getExportDecisionsExportsExportIdGet(requestParameters: ExportsApiGetExportDecisionsExportsExportIdGetRequest, options?: RawAxiosRequestConfig) {
        return ExportsApiFp(this.configuration).getExportDecisionsExportsExportIdGet(requestParameters.exportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Exports
     * @param {ExportsApiListExportsDecisionsExportsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportsApi
     */
    public listExportsDecisionsExportsGet(requestParameters: ExportsApiListExportsDecisionsExportsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return ExportsApiFp(this.configuration).listExportsDecisionsExportsGet(requestParameters.startCreatedAt, requestParameters.endCreatedAt, requestParameters.startingAfter, requestParameters.pageToken, requestParameters.pageSize, requestParameters.createdByIds, requestParameters.statuses, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NodeResultsApi - axios parameter creator
 * @export
 */
export const NodeResultsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Node Result
         * @param {string} decisionId 
         * @param {string} nodeId 
         * @param {string} [$fields] 
         * @param {string} [acceptEncoding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeResultDecisionsDecisionIdNodeResultsNodeIdGet: async (decisionId: string, nodeId: string, $fields?: string, acceptEncoding?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'decisionId' is not null or undefined
            assertParamExists('getNodeResultDecisionsDecisionIdNodeResultsNodeIdGet', 'decisionId', decisionId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('getNodeResultDecisionsDecisionIdNodeResultsNodeIdGet', 'nodeId', nodeId)
            const localVarPath = `/decisions/{decision_id}/node_results/{node_id}`
                .replace(`{${"decision_id"}}`, encodeURIComponent(String(decisionId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if ($fields !== undefined) {
                localVarQueryParameter['$fields'] = $fields;
            }

            if (acceptEncoding != null) {
                localVarHeaderParameter['accept-encoding'] = String(acceptEncoding);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Node Results
         * @param {string} decisionId 
         * @param {string} [startingAfter] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {string} [$fields] 
         * @param {string} [types] 
         * @param {string} [names] 
         * @param {string} [acceptEncoding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNodeResultsDecisionsDecisionIdNodeResultsGet: async (decisionId: string, startingAfter?: string, pageToken?: string, pageSize?: number, $fields?: string, types?: string, names?: string, acceptEncoding?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'decisionId' is not null or undefined
            assertParamExists('listNodeResultsDecisionsDecisionIdNodeResultsGet', 'decisionId', decisionId)
            const localVarPath = `/decisions/{decision_id}/node_results`
                .replace(`{${"decision_id"}}`, encodeURIComponent(String(decisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (startingAfter !== undefined) {
                localVarQueryParameter['starting_after'] = startingAfter;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if ($fields !== undefined) {
                localVarQueryParameter['$fields'] = $fields;
            }

            if (types !== undefined) {
                localVarQueryParameter['types'] = types;
            }

            if (names !== undefined) {
                localVarQueryParameter['names'] = names;
            }

            if (acceptEncoding != null) {
                localVarHeaderParameter['accept-encoding'] = String(acceptEncoding);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeResultsApi - functional programming interface
 * @export
 */
export const NodeResultsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodeResultsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Node Result
         * @param {string} decisionId 
         * @param {string} nodeId 
         * @param {string} [$fields] 
         * @param {string} [acceptEncoding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodeResultDecisionsDecisionIdNodeResultsNodeIdGet(decisionId: string, nodeId: string, $fields?: string, acceptEncoding?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodeResultDecisionsDecisionIdNodeResultsNodeIdGet(decisionId, nodeId, $fields, acceptEncoding, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeResultsApi.getNodeResultDecisionsDecisionIdNodeResultsNodeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Node Results
         * @param {string} decisionId 
         * @param {string} [startingAfter] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {string} [$fields] 
         * @param {string} [types] 
         * @param {string} [names] 
         * @param {string} [acceptEncoding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNodeResultsDecisionsDecisionIdNodeResultsGet(decisionId: string, startingAfter?: string, pageToken?: string, pageSize?: number, $fields?: string, types?: string, names?: string, acceptEncoding?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNodeResultsDecisionsDecisionIdNodeResultsGet(decisionId, startingAfter, pageToken, pageSize, $fields, types, names, acceptEncoding, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeResultsApi.listNodeResultsDecisionsDecisionIdNodeResultsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NodeResultsApi - factory interface
 * @export
 */
export const NodeResultsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodeResultsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Node Result
         * @param {NodeResultsApiGetNodeResultDecisionsDecisionIdNodeResultsNodeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeResultDecisionsDecisionIdNodeResultsNodeIdGet(requestParameters: NodeResultsApiGetNodeResultDecisionsDecisionIdNodeResultsNodeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getNodeResultDecisionsDecisionIdNodeResultsNodeIdGet(requestParameters.decisionId, requestParameters.nodeId, requestParameters.$fields, requestParameters.acceptEncoding, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Node Results
         * @param {NodeResultsApiListNodeResultsDecisionsDecisionIdNodeResultsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNodeResultsDecisionsDecisionIdNodeResultsGet(requestParameters: NodeResultsApiListNodeResultsDecisionsDecisionIdNodeResultsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.listNodeResultsDecisionsDecisionIdNodeResultsGet(requestParameters.decisionId, requestParameters.startingAfter, requestParameters.pageToken, requestParameters.pageSize, requestParameters.$fields, requestParameters.types, requestParameters.names, requestParameters.acceptEncoding, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getNodeResultDecisionsDecisionIdNodeResultsNodeIdGet operation in NodeResultsApi.
 * @export
 * @interface NodeResultsApiGetNodeResultDecisionsDecisionIdNodeResultsNodeIdGetRequest
 */
export interface NodeResultsApiGetNodeResultDecisionsDecisionIdNodeResultsNodeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof NodeResultsApiGetNodeResultDecisionsDecisionIdNodeResultsNodeIdGet
     */
    readonly decisionId: string

    /**
     * 
     * @type {string}
     * @memberof NodeResultsApiGetNodeResultDecisionsDecisionIdNodeResultsNodeIdGet
     */
    readonly nodeId: string

    /**
     * 
     * @type {string}
     * @memberof NodeResultsApiGetNodeResultDecisionsDecisionIdNodeResultsNodeIdGet
     */
    readonly $fields?: string

    /**
     * 
     * @type {string}
     * @memberof NodeResultsApiGetNodeResultDecisionsDecisionIdNodeResultsNodeIdGet
     */
    readonly acceptEncoding?: string
}

/**
 * Request parameters for listNodeResultsDecisionsDecisionIdNodeResultsGet operation in NodeResultsApi.
 * @export
 * @interface NodeResultsApiListNodeResultsDecisionsDecisionIdNodeResultsGetRequest
 */
export interface NodeResultsApiListNodeResultsDecisionsDecisionIdNodeResultsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof NodeResultsApiListNodeResultsDecisionsDecisionIdNodeResultsGet
     */
    readonly decisionId: string

    /**
     * 
     * @type {string}
     * @memberof NodeResultsApiListNodeResultsDecisionsDecisionIdNodeResultsGet
     */
    readonly startingAfter?: string

    /**
     * 
     * @type {string}
     * @memberof NodeResultsApiListNodeResultsDecisionsDecisionIdNodeResultsGet
     */
    readonly pageToken?: string

    /**
     * 
     * @type {number}
     * @memberof NodeResultsApiListNodeResultsDecisionsDecisionIdNodeResultsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof NodeResultsApiListNodeResultsDecisionsDecisionIdNodeResultsGet
     */
    readonly $fields?: string

    /**
     * 
     * @type {string}
     * @memberof NodeResultsApiListNodeResultsDecisionsDecisionIdNodeResultsGet
     */
    readonly types?: string

    /**
     * 
     * @type {string}
     * @memberof NodeResultsApiListNodeResultsDecisionsDecisionIdNodeResultsGet
     */
    readonly names?: string

    /**
     * 
     * @type {string}
     * @memberof NodeResultsApiListNodeResultsDecisionsDecisionIdNodeResultsGet
     */
    readonly acceptEncoding?: string
}

/**
 * NodeResultsApi - object-oriented interface
 * @export
 * @class NodeResultsApi
 * @extends {BaseAPI}
 */
export class NodeResultsApi extends BaseAPI {
    /**
     * 
     * @summary Get Node Result
     * @param {NodeResultsApiGetNodeResultDecisionsDecisionIdNodeResultsNodeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeResultsApi
     */
    public getNodeResultDecisionsDecisionIdNodeResultsNodeIdGet(requestParameters: NodeResultsApiGetNodeResultDecisionsDecisionIdNodeResultsNodeIdGetRequest, options?: RawAxiosRequestConfig) {
        return NodeResultsApiFp(this.configuration).getNodeResultDecisionsDecisionIdNodeResultsNodeIdGet(requestParameters.decisionId, requestParameters.nodeId, requestParameters.$fields, requestParameters.acceptEncoding, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Node Results
     * @param {NodeResultsApiListNodeResultsDecisionsDecisionIdNodeResultsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeResultsApi
     */
    public listNodeResultsDecisionsDecisionIdNodeResultsGet(requestParameters: NodeResultsApiListNodeResultsDecisionsDecisionIdNodeResultsGetRequest, options?: RawAxiosRequestConfig) {
        return NodeResultsApiFp(this.configuration).listNodeResultsDecisionsDecisionIdNodeResultsGet(requestParameters.decisionId, requestParameters.startingAfter, requestParameters.pageToken, requestParameters.pageSize, requestParameters.$fields, requestParameters.types, requestParameters.names, requestParameters.acceptEncoding, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OutcomeReportsApi - axios parameter creator
 * @export
 */
export const OutcomeReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List Outcome Reports
         * @param {string} decisionId 
         * @param {string} [startingAfter] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {string} [$fields] 
         * @param {string} [keys] 
         * @param {string} [acceptEncoding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOutcomeReportsDecisionsDecisionIdOutcomeReportsGet: async (decisionId: string, startingAfter?: string, pageToken?: string, pageSize?: number, $fields?: string, keys?: string, acceptEncoding?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'decisionId' is not null or undefined
            assertParamExists('listOutcomeReportsDecisionsDecisionIdOutcomeReportsGet', 'decisionId', decisionId)
            const localVarPath = `/decisions/{decision_id}/outcome_reports`
                .replace(`{${"decision_id"}}`, encodeURIComponent(String(decisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (startingAfter !== undefined) {
                localVarQueryParameter['starting_after'] = startingAfter;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if ($fields !== undefined) {
                localVarQueryParameter['$fields'] = $fields;
            }

            if (keys !== undefined) {
                localVarQueryParameter['keys'] = keys;
            }

            if (acceptEncoding != null) {
                localVarHeaderParameter['accept-encoding'] = String(acceptEncoding);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutcomeReportsApi - functional programming interface
 * @export
 */
export const OutcomeReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OutcomeReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List Outcome Reports
         * @param {string} decisionId 
         * @param {string} [startingAfter] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {string} [$fields] 
         * @param {string} [keys] 
         * @param {string} [acceptEncoding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOutcomeReportsDecisionsDecisionIdOutcomeReportsGet(decisionId: string, startingAfter?: string, pageToken?: string, pageSize?: number, $fields?: string, keys?: string, acceptEncoding?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOutcomeReportsDecisionsDecisionIdOutcomeReportsGet(decisionId, startingAfter, pageToken, pageSize, $fields, keys, acceptEncoding, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OutcomeReportsApi.listOutcomeReportsDecisionsDecisionIdOutcomeReportsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OutcomeReportsApi - factory interface
 * @export
 */
export const OutcomeReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OutcomeReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary List Outcome Reports
         * @param {OutcomeReportsApiListOutcomeReportsDecisionsDecisionIdOutcomeReportsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOutcomeReportsDecisionsDecisionIdOutcomeReportsGet(requestParameters: OutcomeReportsApiListOutcomeReportsDecisionsDecisionIdOutcomeReportsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.listOutcomeReportsDecisionsDecisionIdOutcomeReportsGet(requestParameters.decisionId, requestParameters.startingAfter, requestParameters.pageToken, requestParameters.pageSize, requestParameters.$fields, requestParameters.keys, requestParameters.acceptEncoding, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for listOutcomeReportsDecisionsDecisionIdOutcomeReportsGet operation in OutcomeReportsApi.
 * @export
 * @interface OutcomeReportsApiListOutcomeReportsDecisionsDecisionIdOutcomeReportsGetRequest
 */
export interface OutcomeReportsApiListOutcomeReportsDecisionsDecisionIdOutcomeReportsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OutcomeReportsApiListOutcomeReportsDecisionsDecisionIdOutcomeReportsGet
     */
    readonly decisionId: string

    /**
     * 
     * @type {string}
     * @memberof OutcomeReportsApiListOutcomeReportsDecisionsDecisionIdOutcomeReportsGet
     */
    readonly startingAfter?: string

    /**
     * 
     * @type {string}
     * @memberof OutcomeReportsApiListOutcomeReportsDecisionsDecisionIdOutcomeReportsGet
     */
    readonly pageToken?: string

    /**
     * 
     * @type {number}
     * @memberof OutcomeReportsApiListOutcomeReportsDecisionsDecisionIdOutcomeReportsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof OutcomeReportsApiListOutcomeReportsDecisionsDecisionIdOutcomeReportsGet
     */
    readonly $fields?: string

    /**
     * 
     * @type {string}
     * @memberof OutcomeReportsApiListOutcomeReportsDecisionsDecisionIdOutcomeReportsGet
     */
    readonly keys?: string

    /**
     * 
     * @type {string}
     * @memberof OutcomeReportsApiListOutcomeReportsDecisionsDecisionIdOutcomeReportsGet
     */
    readonly acceptEncoding?: string
}

/**
 * OutcomeReportsApi - object-oriented interface
 * @export
 * @class OutcomeReportsApi
 * @extends {BaseAPI}
 */
export class OutcomeReportsApi extends BaseAPI {
    /**
     * 
     * @summary List Outcome Reports
     * @param {OutcomeReportsApiListOutcomeReportsDecisionsDecisionIdOutcomeReportsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutcomeReportsApi
     */
    public listOutcomeReportsDecisionsDecisionIdOutcomeReportsGet(requestParameters: OutcomeReportsApiListOutcomeReportsDecisionsDecisionIdOutcomeReportsGetRequest, options?: RawAxiosRequestConfig) {
        return OutcomeReportsApiFp(this.configuration).listOutcomeReportsDecisionsDecisionIdOutcomeReportsGet(requestParameters.decisionId, requestParameters.startingAfter, requestParameters.pageToken, requestParameters.pageSize, requestParameters.$fields, requestParameters.keys, requestParameters.acceptEncoding, options).then((request) => request(this.axios, this.basePath));
    }
}



