import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { AxiosError } from "axios";
import { useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";

import {
  useCreateConnection,
  useEditConnection,
} from "src/api/connectApi/queries";
import { ConnectionT } from "src/api/connectApi/types";
import {
  taktileInternalPrefix,
  validateNotStartsWithInternalPrefix,
} from "src/api/constants";
import { WorkspaceWithSettings } from "src/api/types";
import { Button } from "src/base-components/Button";
import { Divider } from "src/base-components/Divider";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { Select } from "src/base-components/Select";
import { DataRetentionFields } from "src/connections/config/DataRetentionFields";
import { EnableNonProdConfigsField } from "src/connections/config/EnableNonProdConfigsField";
import { InboundWebhookConnectionEnvironmentConfig } from "src/connections/config/inboundWebhook/InboundWebhookConnectionEnvironmentConfig";
import { InboundWebhookResourceConfigFieldset } from "src/connections/config/inboundWebhook/InboundWebhookResourceConfigFieldset";
import {
  InboundWebhookURLField,
  WebhookURLsT,
} from "src/connections/config/inboundWebhook/InboundWebhookURLField";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { EnvironmentTabs } from "src/connections/config/shared/EnvironmentTabs";
import { TestInvocationToggle } from "src/connections/config/shared/TestInvocationToggle";
import { errorProps } from "src/connections/config/shared/errorProps";
import {
  getInboundWebhookConnectionInputsDefaultValues,
  convertBEConnectionToInboundWebhookConnectionConfigInputs,
  getInboundWebhookResourceConfigDefaultValues,
  convertInboundWebhookConnectionConfigInputsToBEConnection,
} from "src/connections/model/inboundWebhook";
import {
  ConnectionCreationFieldError,
  InboundWebhookConnectionConfigInputsT,
  InboundWebhookValidationErrorResponseT,
  InboundWebhookValidationErrorT,
} from "src/connections/types";
import { Callout } from "src/design-system/Callout";
import { Modal } from "src/design-system/Modal";
import { toastActions } from "src/design-system/Toast/utils";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";

type InboundWebhookConnectionConfigFormPropsT = {
  onClose: (webhookURLs: WebhookURLsT | null) => void;
  workspace: WorkspaceWithSettings;
  connection?: ConnectionT;
};

/**
 * Removes the `__root__` part of the loc field from the Connect API error
 * when it's returned as part of the error location.
 *
 * Required because of the issue in FastAPI patch we have for handling
 * V1 and V2 pydantic models in the same API.
 *
 * @param loc location array or null from the Connect API error
 * @returns cleaned location field
 */
export const getCompatLoc = (loc: (string | number)[]): (string | number)[] => {
  if (loc.length < 2) return loc;

  if (typeof loc[1] === "string" && loc[1] === "__root__") {
    // remove the `__root__` part of the loc
    // b/c of the issue in FastAPI that adds the
    // `__root__` to the loc
    return loc.filter((_, i) => i !== 1);
  }
  return loc;
};

export const InboundWebhookConnectionConfigForm: React.FC<
  InboundWebhookConnectionConfigFormPropsT
> = ({ connection, workspace, onClose }) => {
  let defaultValues: Partial<InboundWebhookConnectionConfigInputsT>;
  if (connection) {
    defaultValues =
      convertBEConnectionToInboundWebhookConnectionConfigInputs(connection);
  } else {
    defaultValues = getInboundWebhookConnectionInputsDefaultValues();
  }

  const [activationFieldErrors, setActivationFieldErrors] = useState<
    Record<number, ConnectionCreationFieldError[]>
  >({});

  const formMethods = useForm<InboundWebhookConnectionConfigInputsT>({
    defaultValues,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting: formIsSubmitting },
    watch,
    getValues,
    setValue,
    setError,
    control,
  } = formMethods;

  const resourceConfigs = watch("resourceConfigs");
  const enableNonProdConfigs = watch("enableNonProdConfigs");

  const productionWebhookURL = getValues("productionConfig.webhookURL");
  const sandboxWebhookURL = getValues("sandboxConfig.webhookURL");
  const handleResourceRemoval = (resourceIdToRemove: string) => {
    setValue(
      "resourceConfigs",
      resourceConfigs.filter((resource) => resource.id !== resourceIdToRemove),
    );
  };

  const handleAddResource = () => {
    const newResource = getInboundWebhookResourceConfigDefaultValues();
    setValue("resourceConfigs", [...resourceConfigs, newResource]);
  };

  const createConnectionMutation = useCreateConnection(workspace.base_url);
  const editConnectionMutation = useEditConnection(
    workspace.base_url,
    connection?.id,
  );

  const filterResourceConfigErrors = (
    validationErrors: InboundWebhookValidationErrorT[],
    resourceIndex: number,
  ) => {
    return validationErrors.filter((error) => {
      const loc = getCompatLoc(error.loc);
      // Check if the error location indicates a resource config error
      return (
        loc.length > 3 &&
        loc[1] === "resource_configs" &&
        loc[2] === resourceIndex
      );
    });
  };

  const findResourceFieldError = (
    resourceFieldErrors: InboundWebhookValidationErrorT[],
    fieldName: string,
  ) => {
    const pathSegments = fieldName.split(".");
    return resourceFieldErrors.find((error) => {
      const loc = getCompatLoc(error.loc);
      const locComponents = loc.slice(3); // Get relevant parts of 'loc'
      if (locComponents.length < pathSegments.length) {
        return false;
      }
      // Check if the error location contains the requested field name
      return pathSegments.every(
        (field, index) => locComponents[index] === field,
      );
    });
  };

  const handleErrors = (
    err: any,
    resourceConfigs: InboundWebhookConnectionConfigInputsT["resourceConfigs"],
  ) => {
    if (!(err instanceof AxiosError) || err.response?.status !== 422) {
      return;
    }

    const castedError = err.response
      ?.data as InboundWebhookValidationErrorResponseT;
    if (!castedError.detail) {
      return;
    }

    setError("root.serverError", {
      type: "server",
      message:
        "Webhook creation failed. Please check that all fields are correct.",
    });

    const beToFeMapping: Record<
      string,
      keyof InboundWebhookConnectionConfigInputsT["resourceConfigs"][number]
    > = {
      name: "name",
      "configuration.correlation_id_path": "correlationIDPath",
      "configuration.activation": "activation",
    };

    let parsedActivationFieldErrors: Record<
      number,
      ConnectionCreationFieldError[]
    > = {};

    resourceConfigs.forEach((_, index) => {
      const resourceConfigErrors = filterResourceConfigErrors(
        castedError.detail || [],
        index,
      );

      Object.entries(beToFeMapping).forEach(([beField, feField]) => {
        const validationError = findResourceFieldError(
          resourceConfigErrors,
          beField,
        );
        if (validationError) {
          setError(`resourceConfigs.${index}.${feField}`, {
            type: "server",
            message: validationError.msg,
          });
        }
      });

      const activationValidationError = findResourceFieldError(
        resourceConfigErrors,
        "configuration.activation",
      );

      parsedActivationFieldErrors[index] =
        activationValidationError?.ctx?.field_errors || [];
    });

    setActivationFieldErrors(parsedActivationFieldErrors);
  };

  const onSubmit = async (data: InboundWebhookConnectionConfigInputsT) => {
    setActivationFieldErrors({});

    const payload =
      convertInboundWebhookConnectionConfigInputsToBEConnection(data);

    try {
      let upsertedConnection: ConnectionT;

      if (connection) {
        upsertedConnection = await editConnectionMutation.mutateAsync({
          id: connection.id,
          payload,
        });
      } else {
        upsertedConnection =
          await createConnectionMutation.mutateAsync(payload);
      }

      const action = connection ? "updated" : "created";
      toastActions.success({
        title: `Connection ${action}`,
        description: `Connection ${data.name} has been ${action}.`,
        withSidebar: true,
      });

      // We need to display the webhook URLs for newly created connections
      const productionWebhookURL =
        upsertedConnection.configuration.webhook_url || "";
      const sandboxWebhookURL =
        upsertedConnection.non_prod_env_configs?.["sandbox"]?.webhook_url ||
        null;
      onClose(
        !connection
          ? {
              productionWebhookURL,
              sandboxWebhookURL,
            }
          : null,
      );
    } catch (err) {
      toastActions.failure({
        title: "Error",
        description: "We couldn't save your connection",
        withSidebar: true,
      });
      handleErrors(err, data.resourceConfigs);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        data-loc="webhook-connection-config-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal.Content>
          {errors.root?.serverError && (
            <div className="mb-6">
              <Callout type="error">{errors.root.serverError.message}</Callout>
            </div>
          )}
          {connection && productionWebhookURL && (
            <div className="mb-6 border-b border-gray-200 pb-6">
              <InboundWebhookURLField
                productionWebhookURL={productionWebhookURL}
                // Making sure the second URL is hidden when environments
                // are disabled
                sandboxWebhookURL={
                  enableNonProdConfigs ? sandboxWebhookURL : null
                }
              />
            </div>
          )}

          <FormItem key="name" gap="sm" label="Webhook name" isRequired>
            {Boolean(errors.name) && (
              <ErrorHint>{errors.name?.message}</ErrorHint>
            )}
            <Input
              data-loc="webhook-connection-name"
              {...errorProps(isFieldErrored(errors, "name"))}
              {...register("name", {
                required: "Webhook name is required",
                validate: validateNotStartsWithInternalPrefix(
                  `Webhook name cannot start with ${taktileInternalPrefix}`,
                ),
              })}
              placeholder="Enter Webhook Name"
              fullWidth
            />
          </FormItem>
          {isFeatureFlagEnabled(FEATURE_FLAGS.powertools) && (
            <FormItem
              key="customSuccessStatusCode"
              gap="sm"
              label="Custom success status code"
            >
              <Controller
                control={control}
                name="customSuccessStatusCode"
                render={({ field: { value, onChange } }) => (
                  <Select
                    options={[
                      {
                        key: "200",
                        value: 200,
                      },
                      {
                        key: "202",
                        value: 202,
                      },
                    ]}
                    placement="bottom"
                    value={String(value)}
                    onChange={onChange}
                  />
                )}
              />
            </FormItem>
          )}
          <EnableNonProdConfigsField<InboundWebhookConnectionConfigInputsT>
            dataLoc="webhook-connection-env-aware-switch"
            subtitle="This allows you to set up a separate webhook endpoint for testing in sandbox"
            title="Use separate credentials for sandbox Inbound Webhook requests"
          />
          {enableNonProdConfigs ? (
            <>
              <EnvironmentTabs
                prodContent={
                  <InboundWebhookConnectionEnvironmentConfig
                    connection={connection}
                    environment="production"
                    labelsPrefix="Production"
                  />
                }
                prodError={Boolean(errors.productionConfig)}
                sandboxContent={
                  <InboundWebhookConnectionEnvironmentConfig
                    connection={connection}
                    environment="sandbox"
                    labelsPrefix="Sandbox"
                  />
                }
                sandboxError={Boolean(errors.sandboxConfig)}
              />
              <Divider spacing="my-6" />
            </>
          ) : (
            <InboundWebhookConnectionEnvironmentConfig
              connection={connection}
              environment="production"
              labelsPrefix=""
            />
          )}
          {resourceConfigs.map((resourceConfig, i) => {
            return (
              <InboundWebhookResourceConfigFieldset
                key={resourceConfig.id}
                activationFieldErrors={activationFieldErrors?.[i] || []}
                canBeDeleted={resourceConfigs.length > 1}
                handleResourceRemoval={handleResourceRemoval}
                id={resourceConfig.id}
                index={i}
              />
            );
          })}
          <div>
            <Button
              iconLeft={faPlus}
              size="sm"
              variant="secondary"
              onClick={handleAddResource}
            >
              Add resource
            </Button>
          </div>

          <div className="mb-8 mt-6">
            <TestInvocationToggle<InboundWebhookConnectionConfigInputsT>
              control={control}
              enableNonProdConfigs={enableNonProdConfigs}
              type="webhook"
            />
          </div>
          <Divider spacing="my-6" />
          <DataRetentionFields workspace={workspace} />
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="webhook-connection-save"
              disabled={formIsSubmitting}
              htmlType="submit"
              loading={formIsSubmitting}
              size="base"
              variant="primary"
            >
              Save
            </Button>
          }
        />
      </form>
    </FormProvider>
  );
};
