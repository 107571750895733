import { makeConnectionTemplate } from "src/connections/types";

export const SentiLinkTemplate = makeConnectionTemplate({
  provider: "sentilink",
  secrets: [
    { key: "account_id", name: "Account ID", required: true },
    { key: "token", name: "Token", required: true },
  ],
  resources: {
    sentilink_synthetic_fraud_score: {
      name: "Synthetic Fraud Score",
      configKeys: [],
    },
    sentilink_identity_theft_score: {
      name: "Identity Theft Score",
      configKeys: [],
    },
    sentilink_custom_score_products: {
      name: "Custom Score Products",
      hint: "Custom score products allows you to bundle score products into a single request",
      configKeys: [
        {
          key: "products",
          required: true,
          name: "Products",
          type: "multiple_selection",
          elements: [
            {
              key: "sentilink_synthetic_score",
              value: "Synthetic Fraud Score",
            },
            { key: "sentilink_id_theft_score", value: "Identity Theft Score" },
          ],
        },
      ],
    },
    sentilink_facets: {
      name: "Facets",
      configKeys: [
        {
          key: "products",
          required: true,
          name: "Products",
          type: "multiple_selection",
          elements: [
            { key: "all_attributes", value: "All attributes" },
            { key: "applicant_history", value: "Applicant history" },
            { key: "network_intelligence", value: "Network intelligence" },
            { key: "phone_intelligence", value: "Phone intelligence" },
            { key: "email_intelligence", value: "Email intelligence" },
            { key: "fpd_lending", value: "FPD lending" },
          ],
        },
      ],
    },
    sentilink_id_complete: {
      name: "ID Complete",
      configKeys: [],
    },
    sentilink_insights_report: {
      name: "Insights Report",
      configKeys: [],
    },
  },
});
