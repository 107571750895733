import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  arrayMove,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useInterval } from "@react-hooks-library/core";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import {
  type ColumnDef,
  ColumnMeta,
  Row,
  RowData,
  TableMeta,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { AssertionError } from "assert";
import { AxiosError } from "axios";
import { produce } from "immer";
import { maxBy, range, set } from "lodash";
import {
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useVirtual } from "react-virtual";
import { twJoin } from "tailwind-merge";

import { ExporterDatasetsEndpoint } from "src/api/endpoints";
import { EnumOptionsBET, FlowVersionT } from "src/api/flowTypes";
import {
  Dataset,
  DatasetColumn,
  DatasetColumnGroups,
  DatasetPage,
  DatasetRow,
  DatasetRowDataGroup,
  DatasetRowDataGroupToColumnGroupMap,
  DesiredType,
} from "src/api/types";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { AddAdditionalFieldModal } from "src/datasets/DatasetTable/AddAdditionalFieldModal";
import { EditableCell } from "src/datasets/DatasetTable/EditableCell";
import { EmptyState } from "src/datasets/DatasetTable/EmptyState";
import {
  RowSkeleton,
  RowsSkeleton,
} from "src/datasets/DatasetTable/RowsSkeleton";
import { SampleIntegrationResponseCell } from "src/datasets/DatasetTable/SampleIntegrationResponseCell";
import { ReadonlyCell } from "src/datasets/DatasetTable/cells";
import {
  INDEX_COLUMN_GROUP_ID,
  INDEX_COLUMN_ID,
  useColumnAddHandler,
  useDatasetColumns,
} from "src/datasets/DatasetTable/hooks";
import {
  useDatasetEditTableActions,
  useHoveredColumnActions,
  useIsRowSelected,
} from "src/datasets/DatasetTable/stores";
import {
  DatasetContext,
  JSONValue,
  VersionSchemas,
} from "src/datasets/DatasetTable/types";
import { useCustomScrollReceiver } from "src/datasets/DatasetTable/useCustomScroll";
import { useKeyboardNavigation } from "src/datasets/DatasetTable/useKeyboardNavigation";
import {
  CellId,
  SUB_COLUMN_SEPARATOR,
  isRowEmpty,
} from "src/datasets/DatasetTable/utils";
import {
  DeleteColumnPayload,
  RenameColumnPayload,
  useDeleteColumn,
  useFillColumn,
  usePatchDataset,
  usePatchRow,
  usePutColumns,
  useRenameColumn,
} from "src/datasets/api/queries";
import {
  DatasetIntegrationNode,
  DatasetIssue,
  DatasetIssues,
  isDatasetEmpty,
} from "src/datasets/utils";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { useOutcomeTypes } from "src/outcomes/queries";
import { useFlowContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";

const PAGE_SIZE = 150;
const POLL_INTERVAL_MS = 8000;

export type RowUpdateFunction = (
  row: Row<DatasetRow>,
  columnId: string,
  value: JSONValue | undefined,
) => void;

export type DatasetEditTableRef = {
  scrollToBottom: () => void;
  openAddAdditionalColumnModal: () => void;
};

declare module "@tanstack/react-table" {
  // eslint-disable-next-line unused-imports/no-unused-vars
  interface TableMeta<TData extends RowData> {
    readonly?: boolean;
    onRowUpdate?: RowUpdateFunction;
    scrollToBottom?: () => void;
  }
}

declare module "@tanstack/table-core" {
  // eslint-disable-next-line unused-imports/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    customId?: string;
    archetype?: {
      name: string;
      desiredType: DesiredType;
      nullable: boolean;
      required: boolean;
      enum?: EnumOptionsBET | null;
      matchingIntegrationNode?: DatasetIntegrationNode;
      matchingParentIntegrationNode?: DatasetIntegrationNode;
      columnIssue?: DatasetIssue;
      disabled?: boolean;
    };
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function assertArchetypeMetadata(
  archetypeMeta: ColumnMeta<RowData, unknown>["archetype"],
): asserts archetypeMeta {
  if (!archetypeMeta) {
    throw new AssertionError({ message: "Archetype metadata must exist" });
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function assertOnRowUpdate(
  onRowUpdate: TableMeta<RowData>["onRowUpdate"],
): asserts onRowUpdate {
  if (!onRowUpdate) {
    throw new AssertionError({
      message: "onRowUpdate must exist for the editable table",
    });
  }
}

type DatasetEditTableProps = {
  dataset: Dataset;
  schemas: VersionSchemas;
  readonly?: boolean;
  issues?: DatasetIssues;
  version?: FlowVersionT;
  context?: DatasetContext;
};

const defaultColumn: Partial<ColumnDef<DatasetRow>> = {
  cell: ({ getValue, table, row, column, cell }) => {
    const cellValue = getValue() as JSONValue | undefined;

    if (table.options.meta?.readonly) {
      return (
        <ReadonlyCell
          withPopup={["any", "object", "array"].includes(
            column.columnDef.meta?.archetype?.desiredType ?? "",
          )}
        >
          {JSON.stringify(cellValue)}
        </ReadonlyCell>
      );
    }

    assertArchetypeMetadata(column.columnDef.meta?.archetype);
    assertOnRowUpdate(table.options.meta?.onRowUpdate);

    const { onRowUpdate } = table.options.meta;
    const { archetype: archetypeMeta } = column.columnDef.meta;

    const showSampleIntegrationResponseCell =
      archetypeMeta.matchingIntegrationNode && !cellValue;

    if (
      showSampleIntegrationResponseCell &&
      archetypeMeta.matchingIntegrationNode
    ) {
      return (
        <SampleIntegrationResponseCell
          cellId={cell.id as CellId}
          column={column}
          disabled={!!archetypeMeta.disabled}
          integrationNode={archetypeMeta.matchingIntegrationNode}
          row={row}
          onChange={onRowUpdate}
        />
      );
    } else {
      return (
        <EditableCell
          cellId={cell.id as CellId}
          column={column}
          columnIssue={archetypeMeta.columnIssue}
          desiredType={archetypeMeta.desiredType}
          disabled={!!archetypeMeta.disabled}
          enumValues={archetypeMeta.enum}
          integrationNode={archetypeMeta.matchingIntegrationNode}
          nullable={archetypeMeta.nullable}
          required={archetypeMeta.required}
          row={row}
          value={cellValue}
          onChange={onRowUpdate}
        />
      );
    }
  },
  minSize: 200,
  maxSize: 400,
};

const buildColumnOrderKey = (columns: DatasetColumn[]) =>
  columns.map((col) => col.name).join(",");

const extractRowGroups = (
  over: NonNullable<DragEndEvent["over"]>,
  active: DragEndEvent["active"],
) => {
  const overGroup = over.id.toString().split(".")[0] as DatasetRowDataGroup;
  const activeGroup = active.id.toString().split(".")[0] as DatasetRowDataGroup;

  const overColumn = over.id.toString().replace(`${overGroup}.`, "");
  const activeColumn = active.id.toString().replace(`${activeGroup}.`, "");

  const overSubGroup =
    overColumn.split(SUB_COLUMN_SEPARATOR).length > 1
      ? overColumn.split(SUB_COLUMN_SEPARATOR)[0]
      : null;
  const activeSubGroup =
    activeColumn.split(SUB_COLUMN_SEPARATOR).length > 1
      ? activeColumn.split(SUB_COLUMN_SEPARATOR)[0]
      : null;

  return { overGroup, activeGroup, overSubGroup, activeSubGroup };
};

// We need this because issues is passed to useDatasetColumns
// and it expects it to be stable
const ISSUES_DEFAULT_VALUE = {};

export const DatasetEditTable = forwardRef<
  DatasetEditTableRef,
  DatasetEditTableProps
>(
  (
    {
      dataset,
      schemas,
      readonly = false,
      issues = ISSUES_DEFAULT_VALUE,
      version,
      context = "authoring",
    },
    componentRef,
  ) => {
    const { workspace, flow } = useFlowContext();
    const { setColumnId: setHoveredColumnId } = useHoveredColumnActions();
    const { editCell, selectCell, reset } = useDatasetEditTableActions();

    const [showAddAdditionalFieldModal, setShowAddAdditionalFieldModal] =
      useState(false);

    const patchRow = usePatchRow(dataset.id, flow.id, workspace?.base_url);
    const [columnDeleteConfirmPayload, setColumnDeleteConfirmPayload] =
      useState<DeleteColumnPayload[] | null>(null);
    const { columnAddHandler, subMocksColumnAddHandler } = useColumnAddHandler({
      dataset,
      flowId: flow.id,
      baseUrl: workspace?.base_url,
      schemas,
    });
    const deleteColumn = useDeleteColumn(
      dataset.id,
      flow.id,
      workspace?.base_url,
    );
    const renameColumn = useRenameColumn(
      dataset.id,
      flow.id,
      workspace?.base_url,
    );
    const putColumn = usePutColumns(dataset.id, flow.id, workspace?.base_url);
    const fillColumn = useFillColumn(dataset.id, flow.id, workspace?.base_url);
    const patchDataset = usePatchDataset(flow.id, workspace?.base_url);
    const queryClient = useQueryClient();
    const rowUpdateHandler: RowUpdateFunction = async (
      row,
      columnId,
      value,
    ) => {
      try {
        const newRowValue = produce(row.original, (state) => {
          set(state, columnId, value);
        });
        // immer doesn't change original reference if no actual changes were made
        if (newRowValue !== row.original) {
          await patchRow.mutateAsync(newRowValue);
        }
      } catch (e) {
        toastActions.failure({
          title: "Failed to update row",
          description: TAKTILE_TEAM_NOTIFIED,
        });
        logger.error(e);
      }
    };
    const columnDeleteConfirmHandler = useCallback(
      async (payloads: DeleteColumnPayload[]) => {
        setColumnDeleteConfirmPayload(null);
        try {
          for (const p of payloads) {
            await deleteColumn.mutateAsync(p);
          }
        } catch (e) {
          toastActions.failure({
            title: `Failed to delete the column${
              payloads.length > 1 ? "s" : ""
            } "${payloads.map((p) => p.name).join(", ")}"`,
            description: TAKTILE_TEAM_NOTIFIED,
          });
          logger.error(e);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [deleteColumn.mutateAsync],
    );

    const subMocksColumnDeleteHandler = useCallback(
      async (subMockColumnName: string | null, mockColumnName: string) => {
        try {
          const mockColumn = dataset.mock_columns.find(
            (col) => col.name === mockColumnName,
          );
          if (!mockColumn || !mockColumn.use_subflow_mocks) return;

          const childColumns = dataset.mock_columns
            .filter((col) =>
              col.name.startsWith(`${mockColumnName}${SUB_COLUMN_SEPARATOR}`),
            )
            .map(({ name }) => name);

          const columnsToDelete =
            subMockColumnName === null ? childColumns : [subMockColumnName];

          await putColumn.mutateAsync([
            {
              desiredType: mockColumn.desired_type,
              group: "mock_columns",
              name: mockColumnName,
              hasSubflowMocks: childColumns.length > columnsToDelete.length,
            },
          ]);

          for (const childColumn of columnsToDelete) {
            await deleteColumn.mutateAsync({
              group: "mock_columns",
              name: childColumn,
            });
          }
        } catch (e) {
          toastActions.failure({
            title: `Failed to delete "${
              subMockColumnName ? subMockColumnName : "all"
            } submock"`,
            description: TAKTILE_TEAM_NOTIFIED,
          });
          logger.error(e);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [putColumn.mutateAsync, dataset.mock_columns],
    );

    const columnsDeleteHandler = useCallback(
      (payload: DeleteColumnPayload[]) =>
        setColumnDeleteConfirmPayload(payload),
      [],
    );

    const columnRenameHandler = useCallback(
      async (payload: RenameColumnPayload) => {
        const newNameIsEmpty = !payload.newName;
        if (newNameIsEmpty) return;

        if (payload.newName === payload.oldName) return;

        const newNameAlreadyExists = dataset[payload.group].find(
          (c) => c.name === payload.newName,
        );

        if (newNameAlreadyExists) {
          toastActions.failure({
            title: `${payload.newName} already exists`,
            description: "Please use a different column name",
          });
          return;
        }

        try {
          await renameColumn.mutateAsync(payload);
        } catch (e) {
          toastActions.failure({
            title: `Failed to rename the column "${payload.oldName}"`,
            description: TAKTILE_TEAM_NOTIFIED,
          });
          logger.error(e);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [dataset, renameColumn.mutateAsync],
    );

    const columnTypeChangeHandler = useCallback(
      async (
        name: string,
        group: DatasetColumnGroups,
        desiredType: DesiredType,
      ) => {
        const putColumnPayload = {
          name,
          group,
          desiredType,
          hasSubflowMocks: false,
        };
        try {
          await putColumn.mutateAsync([putColumnPayload]);
        } catch (e) {
          toastActions.failure({
            title: `Failed to change the type of the column "${name}"`,
            description: TAKTILE_TEAM_NOTIFIED,
          });
          logger.error(e);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [putColumn.mutateAsync],
    );

    const fillColumnHandler = useCallback(
      async (
        name: string,
        group: DatasetColumnGroups,
        fillValue: JSONValue,
      ) => {
        try {
          await fillColumn.mutateAsync({ name, group, fillValue });
        } catch (e) {
          toastActions.failure({
            title: `Failed to fill the column "${name}"`,
            description: TAKTILE_TEAM_NOTIFIED,
          });
          logger.error(e);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [fillColumn.mutateAsync],
    );

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const {
      data: datasetPages,
      isFetching,
      isLoading,
      hasNextPage,
      refetch,
      fetchNextPage,
    } = useInfiniteQuery<DatasetPage>(
      ["datasetRows", dataset.id],
      async ({ pageParam = 0 }) => {
        const data = await ExporterDatasetsEndpoint.getDataset(
          workspace.base_url!,
          dataset.id,
          { from: pageParam, size: PAGE_SIZE },
        );

        const { dataset: freshDataset, ...rest } = data;

        const datasetsQueryKey = ["datasets", workspace.base_url, flow.id];

        const oldDatasets =
          queryClient.getQueryData<Dataset[]>(datasetsQueryKey)!;

        const datasetIndex = oldDatasets.findIndex((d) => d.id === dataset.id);
        const mustUpdateDatasets =
          datasetIndex !== -1 &&
          oldDatasets[datasetIndex].etag !== freshDataset.etag;

        if (mustUpdateDatasets) {
          const updatedDatasets = produce(oldDatasets, (draft) => {
            draft[datasetIndex] = freshDataset;
            draft.sort(
              (a, b) =>
                new Date(b.updated_at).valueOf() -
                new Date(a.updated_at).valueOf(),
            );
          });

          queryClient.setQueryData<Dataset[]>(
            datasetsQueryKey,
            updatedDatasets,
          )!;
        }

        return {
          ...rest,
          fetchedAt: Date.now(),
        };
      },
      {
        getNextPageParam: (lastPage, allPages) => {
          const lastUpdatedPage = maxBy(allPages, "fetchedAt");
          if (!lastUpdatedPage) return undefined;

          if (lastPage.from_ + lastPage.size < lastUpdatedPage.total) {
            return lastPage.from_ + PAGE_SIZE;
          }
        },
      },
    );
    const datasetRows = useMemo(
      () =>
        datasetPages ? datasetPages.pages.flatMap((page) => page.rows) : [],
      [datasetPages],
    );
    const totalRowsInDataset = useMemo(
      () => maxBy(datasetPages?.pages, "fetchedAt")?.total ?? 0,
      [datasetPages],
    );

    const addAdditionalColumnHandler = useCallback(
      () => setShowAddAdditionalFieldModal(true),
      [],
    );

    const { data: outcomeTypes } = useOutcomeTypes({
      flowId: flow.id,
    });

    const { columns } = useDatasetColumns({
      context: context,
      dataset,
      schemas,
      version,
      outcomeTypes,
      onColumnsDelete: columnsDeleteHandler,
      onColumnRename: useCallback(
        (group, oldName, newName, desiredType, hasSubflowMocks) =>
          columnRenameHandler({
            group,
            oldName,
            newName,
            desiredType,
            hasSubflowMocks,
          }),
        [columnRenameHandler],
      ),
      onColumnAdd: columnAddHandler,
      onAddSubMocks: subMocksColumnAddHandler,
      onDeleteSubMocks: subMocksColumnDeleteHandler,
      onColumnTypeChange: columnTypeChangeHandler,
      issues,
      onColumnFill: fillColumnHandler,
      onAddAdditionalColumn: addAdditionalColumnHandler,
    });

    const scrollToBottom = () => {
      tableContainerRef.current?.scrollTo({
        top: tableContainerRef.current.scrollHeight,
        behavior: "instant",
      });
    };

    const table = useReactTable({
      data: datasetRows,
      columns,
      defaultColumn,
      getCoreRowModel: getCoreRowModel(),
      columnResizeMode: "onChange",
      meta: {
        readonly,
        onRowUpdate: rowUpdateHandler,
        scrollToBottom,
      },
    });

    const ref = useRef<HTMLTableElement>(null);

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: totalRowsInDataset,
      overscan: 10,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    useImperativeHandle(componentRef, () => ({
      scrollToBottom,
      openAddAdditionalColumnModal: addAdditionalColumnHandler,
    }));

    const paddingTop =
      virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom =
      virtualRows.length > 0
        ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
        : 0;

    const calcVisiblePages = (
      startIndex: number,
      endIndex: number,
    ): number[] => {
      const startPage = Math.floor(startIndex / PAGE_SIZE);
      const endPage = Math.floor(endIndex / PAGE_SIZE);

      return range(startPage, endPage + 1);
    };

    useEffect(() => {
      const notFetchedRows = virtualRows.filter((row) => !rows[row.index]);
      if (notFetchedRows.length && !isFetching && hasNextPage) {
        fetchNextPage();
      }
    }, [fetchNextPage, isFetching, rows, virtualRows, hasNextPage]);

    const visiblePages = virtualRows.length
      ? calcVisiblePages(
          virtualRows[0].index,
          virtualRows[virtualRows.length - 1].index,
        )
      : [];

    const refetchVisiblePages = useCallback(() => {
      if (document.visibilityState === "visible") {
        refetch({
          refetchPage: (_, index) => visiblePages.includes(index),
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch, visiblePages.join()]);

    useInterval(refetchVisiblePages, POLL_INTERVAL_MS);

    const columnIds = table
      .getAllFlatColumns()
      .filter((c) => c.id.includes("."))
      .map((c) => c.id);

    const onDragEnd = async ({ over, active }: DragEndEvent) => {
      if (!over) return;

      const activeIndex = columnIds.findIndex((id) => id === active.id);
      const overIndex = columnIds.findIndex((id) => id === over.id);
      const { activeGroup, activeSubGroup, overGroup, overSubGroup } =
        extractRowGroups(over, active);
      const isValidColumnMove =
        activeGroup === overGroup && activeSubGroup === overSubGroup;

      if (!isValidColumnMove) {
        return;
      }

      const newColumnIdsArray = arrayMove(columnIds, activeIndex, overIndex);

      const newColumnIdsArrayFilteredByGroup = newColumnIdsArray
        .filter((id) => id.startsWith(activeGroup))
        .map((id) => id.replace(`${activeGroup}.`, ""));

      const columnGroup = DatasetRowDataGroupToColumnGroupMap[activeGroup];

      const currentColumnsOfGroup = dataset[columnGroup];

      const updatedColumnsOfGroup: DatasetColumn[] =
        newColumnIdsArrayFilteredByGroup.map((name) => {
          const column: DatasetColumn = currentColumnsOfGroup.find(
            (c) => c.name === name,
          )!;

          return column;
        });

      const orderHasChanged =
        buildColumnOrderKey(currentColumnsOfGroup) !==
        buildColumnOrderKey(updatedColumnsOfGroup);

      if (orderHasChanged) {
        try {
          await patchDataset.mutateAsync({
            id: dataset.id,
            etag: dataset.etag,
            patch: { [columnGroup]: updatedColumnsOfGroup },
          });
        } catch (err) {
          if (err instanceof AxiosError && err.response?.status === 412) {
            logger.warn(
              `Race condition detected when trying to reorder columns`,
            );
          } else {
            toastActions.failure({
              title: `Failed to reorder columns`,
              description: TAKTILE_TEAM_NOTIFIED,
            });
            logger.error(err);
          }
        }
      }
    };

    useCustomScrollReceiver(tableContainerRef);

    const maxHeaderDepth =
      Math.max(
        ...table
          .getHeaderGroups()
          .flatMap((group) => group.headers.map((header) => header.depth)),
        0,
      ) + 1;

    return (
      <>
        <div
          ref={tableContainerRef}
          className={twJoin(
            "h-full",
            !isDatasetEmpty(dataset) && "decideScrollbar overflow-auto",
          )}
        >
          {!isDatasetEmpty(dataset) ? (
            <table
              ref={ref}
              className="min-w-full border-collapse border-spacing-0"
              style={{
                width: table.getCenterTotalSize(),
              }}
              onMouseLeave={() => setHoveredColumnId(null)}
            >
              <thead className="sticky top-0 z-10 bg-white">
                <DndContext
                  accessibility={{
                    container: tableContainerRef.current
                      ? tableContainerRef.current
                      : undefined,
                  }}
                  collisionDetection={closestCenter}
                  onDragEnd={onDragEnd}
                >
                  <SortableContext
                    disabled={readonly}
                    items={columnIds}
                    strategy={horizontalListSortingStrategy}
                  >
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr
                        key={headerGroup.id}
                        onClick={() => headerGroup.id === "0" && reset()}
                      >
                        {headerGroup.headers.map((header) => {
                          const isLeafHeader =
                            header.depth - maxHeaderDepth === -1;
                          const stickToTop =
                            header.id !== INDEX_COLUMN_ID &&
                            isLeafHeader &&
                            maxHeaderDepth > 3 &&
                            header.column.depth === 1;

                          if (header.isPlaceholder) {
                            return (
                              <th
                                key={header.id}
                                className={twJoin(
                                  "relative border border-y-0 border-gray-100 p-0",
                                  header.id.includes(INDEX_COLUMN_ID) &&
                                    "bg-gray-50",
                                  !header.subHeaders.length && "min-w-[8rem]",
                                )}
                                colSpan={header.colSpan}
                              />
                            );
                          }

                          return (
                            <th
                              key={header.id}
                              className={twJoin(
                                "relative border border-gray-100 p-0 text-left",
                                "first:border-l-0 last:border-l-0",
                                stickToTop && "-translate-y-full",
                                header.depth === 2 && maxHeaderDepth > 3
                                  ? "border-y-transparent"
                                  : "border-y-0",
                                [
                                  INDEX_COLUMN_ID,
                                  INDEX_COLUMN_GROUP_ID,
                                ].includes(header.column.id) &&
                                  "sticky left-0 z-20 bg-gray-50",
                              )}
                              colSpan={header.colSpan}
                              style={{
                                width: !header.subHeaders.length
                                  ? header.getSize()
                                  : undefined,
                                maxWidth: !header.subHeaders.length
                                  ? header.column.getSize()
                                  : undefined,
                                minWidth: !header.subHeaders.length
                                  ? header.column.getSize()
                                  : undefined,
                              }}
                              onMouseDownCapture={() => {
                                !readonly &&
                                  (header.column.columnDef.meta?.archetype
                                    ?.disabled
                                    ? selectCell(
                                        `${header.depth - maxHeaderDepth}_${
                                          header.column.columnDef.meta
                                            .customId ?? header.id
                                        }`,
                                      )
                                    : editCell(
                                        `${header.depth - maxHeaderDepth}_${
                                          header.column.columnDef.meta
                                            ?.customId ?? header.id
                                        }`,
                                      ));
                              }}
                              onMouseOver={() =>
                                !readonly && setHoveredColumnId(header.id)
                              }
                            >
                              {isLeafHeader ? (
                                <SortableWrapper
                                  disabled={readonly}
                                  id={header.id}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                </SortableWrapper>
                              ) : (
                                flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )
                              )}
                              {!readonly &&
                                !header.subHeaders.length &&
                                header.column.getCanResize() && (
                                  <div
                                    className={twJoin(
                                      "transform-colors absolute -bottom-px -top-px right-0 w-1.5 translate-x-1/2",
                                      "cursor-col-resize select-none bg-indigo-500 opacity-0 duration-150 hover:opacity-100",
                                      header.column.getIsResizing() &&
                                        "opacity-100",
                                    )}
                                    onMouseDown={header.getResizeHandler()}
                                    onTouchStart={header.getResizeHandler()}
                                  />
                                )}
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </SortableContext>
                </DndContext>
              </thead>
              <tbody>
                {paddingTop > 0 && (
                  <tr>
                    <td style={{ height: `${paddingTop}px` }} />
                  </tr>
                )}
                {virtualRows.map((virtualRow) => {
                  const row = rows[virtualRow.index];
                  if (row) {
                    return (
                      <RowWrapper
                        key={row.id}
                        ref={virtualRow.measureRef}
                        index={row.index}
                        isEmpty={!readonly && isRowEmpty(row.original)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className={twJoin(
                              "h-8 border border-gray-100 p-0 group-[.is-empty]:border-yellow-100",
                              "first:border-l-0 last:border-l-0",
                              INDEX_COLUMN_ID === cell.column.id &&
                                "sticky left-0 bg-gray-50",
                            )}
                            data-loc={`dataset-table-cell-${cell.column.id}`}
                            style={{
                              width: cell.column.getSize(),
                              maxWidth: cell.column.getSize(),
                              minWidth: cell.column.getSize(),
                            }}
                            onClickCapture={() =>
                              !readonly &&
                              INDEX_COLUMN_ID !== cell.column.id &&
                              selectCell(cell.id as CellId)
                            }
                            onDoubleClickCapture={() =>
                              !readonly &&
                              INDEX_COLUMN_ID !== cell.column.id &&
                              !cell.column.columnDef.meta?.archetype
                                ?.disabled &&
                              editCell(cell.id as CellId)
                            }
                            onMouseEnter={() =>
                              !readonly && setHoveredColumnId(cell.column.id)
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        ))}
                      </RowWrapper>
                    );
                  } else {
                    return (
                      <RowSkeleton
                        key={virtualRow.index}
                        ref={virtualRow.measureRef}
                        nCol={columnIds.length}
                      />
                    );
                  }
                })}

                {isLoading && (
                  <RowsSkeleton
                    heightToFill={
                      tableContainerRef.current
                        ? tableContainerRef.current.clientHeight - 64
                        : 0
                    }
                    nCol={columnIds.length}
                  />
                )}
                {paddingBottom > 0 && (
                  <tr>
                    <td style={{ height: `${paddingBottom}px` }} />
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <EmptyState
              context={context}
              version={version}
              onAddAdditionalColumn={addAdditionalColumnHandler}
              onAddColumn={columnAddHandler}
            />
          )}
        </div>
        <ConfirmationModal
          confirmationButtonText="Yes, delete"
          open={!!columnDeleteConfirmPayload}
          title="Delete field"
          variant="danger"
          onClose={() => setColumnDeleteConfirmPayload(null)}
          onConfirm={() =>
            columnDeleteConfirmHandler(columnDeleteConfirmPayload!)
          }
        >
          <div className="text-gray-500 font-inter-normal-12px">
            Are you sure you want to delete this field from dataset? This action
            cannot be undone.
          </div>
        </ConfirmationModal>
        <AddAdditionalFieldModal
          open={showAddAdditionalFieldModal}
          onAddColumn={columnAddHandler}
          onClose={() => {
            setShowAddAdditionalFieldModal(false);
          }}
        />
        {!readonly && (
          <KeyboardNavigationController
            columns={table.getAllLeafColumns()}
            rowsCount={datasetRows.length}
            tableRef={ref}
          />
        )}
      </>
    );
  },
);

const SortableWrapper: React.FC<{
  children: ReactNode;
  id: string;
  disabled: boolean;
}> = ({ children, id, disabled }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    // The component renders a couple of times with outdated columns
    // after calling mutate at onDragEnd. This is more noticeable
    // if animateLayoutChanges is enabled
    animateLayoutChanges: () => false,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className={twJoin("relative", isDragging && "z-10 shadow-xl")}
      style={style}
    >
      <div
        {...attributes}
        {...listeners}
        ref={setActivatorNodeRef}
        className={twJoin(
          "absolute left-1.5 top-2 h-3 w-3",
          isDragging
            ? "cursor-grabbing"
            : disabled
              ? "cursor-default"
              : "cursor-grab",
        )}
      />
      {children}
    </div>
  );
};

const KeyboardNavigationController: React.FC<{
  tableRef: React.RefObject<HTMLTableElement>;
  rowsCount: number;
  columns: ColumnDef<DatasetRow>[];
}> = ({ tableRef, rowsCount, columns }) => {
  useKeyboardNavigation(tableRef, {
    rowsLength: rowsCount,
    columns,
  });
  return null;
};

type RowWrapperProps = {
  children: ReactNode;
  index: number;
  isEmpty: boolean;
};

const RowWrapper = forwardRef<HTMLTableRowElement, RowWrapperProps>(
  ({ children, index, isEmpty }, ref) => {
    const isRowSelected = useIsRowSelected(index);
    return (
      <tr
        ref={ref}
        className={twJoin(
          "group",
          isEmpty && "is-empty bg-yellow-50",
          isRowSelected &&
            "border border-indigo-600 bg-indigo-50 outline outline-1 outline-indigo-600",
        )}
        data-loc="dataset-table-row"
      >
        {children}
      </tr>
    );
  },
);
