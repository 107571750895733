import { Pill, PillVariants } from "src/base-components/Pill";
import { NodeDiffView } from "src/changeHistory/DiffViewModal/NodeDiffView";
import { BeMappedNode } from "src/constants/NodeDataTypes";
import { NodeSuggestionType } from "src/copilot/NodeSuggestion";
import { Modal } from "src/design-system/Modal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  originalNode: BeMappedNode;
  nodeSuggestion: NodeSuggestionType;
};

type MetaInfoProps = {
  text: string;
  variant: PillVariants;
};

const MetaInfo: React.FC<MetaInfoProps> = ({ text, variant }) => (
  <div className="flex w-1/2 items-center gap-x-1">
    <Pill size="base" variant={variant}>
      <Pill.Text>{text}</Pill.Text>
    </Pill>
  </div>
);

export const InspectChangesModal: React.FC<Props> = ({
  isOpen,
  originalNode,
  nodeSuggestion,
  onClose,
}) => {
  const suggestedNode: BeMappedNode = {
    ...originalNode,
    data: {
      ...originalNode.data,
      label: nodeSuggestion.name,
      ...nodeSuggestion.meta,
    },
  };

  return (
    <Modal open={isOpen} size="lg" onClose={onClose}>
      <Modal.Header>Inspect changes</Modal.Header>
      <Modal.Content>
        <div className="flex gap-x-6 pb-5">
          <MetaInfo text="Before" variant="red" />
          <MetaInfo text="After" variant="green" />
        </div>
        <NodeDiffView changedNode={suggestedNode} originalNode={originalNode} />
      </Modal.Content>
    </Modal>
  );
};
