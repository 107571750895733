import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { FlowVersionFlowChild } from "src/api/flowTypes";
import { useDeleteFlowVersion } from "src/api/flowVersionQueries";
import { TextConfirmationModal } from "src/base-components/TextConfirmationModal";
import { toastActions } from "src/design-system/Toast/utils";
import { RelatedFlowList } from "src/flow/modals/RelatedFlowList";
import { groupRelatedFlows } from "src/flow/modals/utils";
import { useFlowContext } from "src/router/routerContextHooks";
import { FlowPageParamsT, getFlowVersionsUrl } from "src/router/urls";
import { logger } from "src/utils/logger";
import { useParamsDecode } from "src/utils/useParamsDecode";

type PropsT = {
  isOpen: boolean;
  version?: FlowVersionFlowChild;
  onClose: () => void;
  afterLeave?: () => void;
};

export const DeleteVersionModal: React.FC<PropsT> = ({
  version,
  onClose,
  isOpen,
  afterLeave,
}) => {
  const { workspace } = useFlowContext();
  const { mutateAsync: deleteVersion } = useDeleteFlowVersion();
  const navigate = useNavigate();
  const params = useParamsDecode<FlowPageParamsT>();

  const [parentFlowIds, groupedParentFlows] = useMemo(
    () => groupRelatedFlows(version?.parent_flows ?? []),
    [version?.parent_flows],
  );

  const onModalConfirm = async () => {
    if (version) {
      try {
        await deleteVersion(version.id);
        toastActions.success({ title: "Successfully deleted version" });
      } catch {
        logger.error("Error while trying to delete flow version");
      }
    }
    onClose();
    navigate(getFlowVersionsUrl(params.orgId, params.wsId, params.flow_id));
  };

  const AdditionalContent: React.FC = () => (
    <>
      <div className="text-gray-500 font-inter-normal-13px">
        {parentFlowIds.length > 0 && (
          <p className="pb-8">
            Deleting this version will break the Connection with the Decision
            Flow versions listed below.
          </p>
        )}
      </div>
      <RelatedFlowList
        groupedRelatedFlows={groupedParentFlows}
        workspace={workspace}
      />
    </>
  );

  return (
    <TextConfirmationModal
      additionalContent={<AdditionalContent />}
      afterLeave={afterLeave}
      challengeText={version?.name}
      confirmButtonText="Delete"
      description="Are you sure you want to delete this version? This action cannot be
          undone."
      label="Please enter version name to confirm."
      open={isOpen}
      title={`Delete version ${version?.name}`}
      variant="danger"
      onClose={onClose}
      onConfirm={onModalConfirm}
    />
  );
};
