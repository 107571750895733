import { DecisionEnvironment } from "src/api/types";
import { Button } from "src/base-components/Button";
import { Tooltip } from "src/design-system/Tooltip";
import { useCapabilities } from "src/hooks/useCapabilities";
import { RunJobModal } from "src/jobs/common/RunJobModal";
import { useRunJobHandler, useUsedVersions } from "src/jobs/common/hooks";
import { Job } from "src/jobs/types";

export const RunButton: React.FC<{
  job: Job;
}> = ({ job }) => {
  const {
    isJobRunnable,
    afterLeave,
    isOpen,
    onConfirm,
    openModal,
    closeModal,
  } = useRunJobHandler(job);
  const { jobs } = useCapabilities();

  const showRunLiveButton = job.status === "active" && jobs.canRunLive;
  const showRunSandboxButton = job.status === "inactive" && jobs.canRunSandbox;

  const usedVersions = useUsedVersions(job);

  return (
    <>
      {showRunLiveButton && (
        <Button
          dataLoc="run-job-live-button"
          disabled={!isJobRunnable}
          size="sm"
          variant="secondary"
          onClick={openModal}
        >
          Run Job
        </Button>
      )}
      {showRunSandboxButton && (
        <Tooltip
          body={`You must add ${[
            usedVersions.length === 0 && "at least one Flow version",
            !job.active_source && "a source",
          ]
            .filter(Boolean)
            .join(" and ")} to run the Job in Sandbox`}
          disabled={isJobRunnable}
          placement="bottom"
          asChild
        >
          <Button
            dataLoc="run-job-sandbox-button"
            disabled={!isJobRunnable}
            size="sm"
            variant="secondary"
            onClick={openModal}
          >
            Run in Sandbox
          </Button>
        </Tooltip>
      )}
      <RunJobModal
        afterLeave={afterLeave}
        closeModal={closeModal}
        env={
          showRunLiveButton
            ? DecisionEnvironment.LIVE
            : DecisionEnvironment.SANDBOX
        }
        isOpen={isOpen}
        job={job}
        onConfirm={onConfirm}
      />
    </>
  );
};
