import { faMessageLines } from "@fortawesome/pro-solid-svg-icons";

import { Icon } from "src/base-components/Icon";
import { MAX_DISPLAY_COUNT } from "src/utils/constants";
import { getMaximumDisplayedCount } from "src/utils/stringUtils";

type Props = {
  count: number;
};

export const CommentCount: React.FC<Props> = ({ count }) => {
  const displayCount = getMaximumDisplayedCount(count, MAX_DISPLAY_COUNT);
  return (
    <div className="flex h-4 flex-row items-center rounded bg-yellow-100 px-1">
      <Icon color="text-yellow-400" icon={faMessageLines} size="3xs" />
      <p className="text-yellow-700 font-inter-normal-12px">{displayCount}</p>
    </div>
  );
};
