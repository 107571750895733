import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons";

import { SchemaTypesT } from "src/api/flowTypes";
import { Icon } from "src/base-components/Icon";
import { SCHEMA_TYPE_ICONS } from "src/base-components/TypeIcons";
import { Tooltip } from "src/design-system/Tooltip";

type Props = {
  dataLoc?: string;
  name: string;
  type: SchemaTypesT;
  sensitive: boolean;
  nullable: boolean;
  required: boolean;
  schemaTabUrl: string;
  tooltipBody?: string;
};

export const SchemaField: React.FC<Props> = ({
  dataLoc,
  name,
  type,
  sensitive,
  nullable,
  required,
  schemaTabUrl,
  tooltipBody = "Modify the input and output fields on the Decision Flow canvas.",
}) => (
  <Tooltip
    body={tooltipBody}
    footerAction={{
      text: "Open Schema",
      onClick: () => window.open(schemaTabUrl, "_blank"),
    }}
    placement="right"
    title="This field is not editable"
    asChild
  >
    <div
      className="flex h-8 w-full flex-1 flex-grow items-center gap-x-1 rounded-lg border border-gray-200 bg-gray-50 py-1 pl-3 pr-2 text-gray-500"
      data-loc={dataLoc}
    >
      {type && (
        <Tooltip placement="bottom" title={type} asChild>
          <Icon
            dataLoc={dataLoc && `${dataLoc}-type`}
            icon={SCHEMA_TYPE_ICONS[type]}
            size="xs"
          />
        </Tooltip>
      )}

      <span
        className="truncate font-inter-normal-12px"
        data-loc={dataLoc && `${dataLoc}-name`}
      >
        {name}
      </span>

      {required && (
        <span className="text-indigo-400 font-inter-medium-12px">*</span>
      )}
      <div className="ml-auto flex items-center gap-x-1">
        {nullable && (
          <Tooltip placement="bottom-end" title="Nullable" asChild>
            <span className="font-fira-code text-gray-500">null</span>
          </Tooltip>
        )}
        {sensitive && (
          <Tooltip placement="bottom-end" title="Contains PII" asChild>
            <Icon color="text-gray-500" icon={faEyeSlash} size="xs" />
          </Tooltip>
        )}
      </div>
    </div>
  </Tooltip>
);
