import axios from "axios";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { FlowT } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { Modal } from "src/design-system/Modal";
import {
  FolderDropdownSelection,
  SelectFolderDropdown,
} from "src/flowsOverview/v2/SelectFolderDropdown";
import { FOLDER_NAME_NOT_AVAILABLE_MESSAGE } from "src/flowsOverview/v2/folderModals/EditFolderModal";
import { useFolders } from "src/flowsOverview/v2/folderQueries";
import { wrapWithAxiosResponseErrorHandler } from "src/utils/toastError";

type Props = {
  open: boolean;
  workspaceId: string;
  flow?: FlowT;
  onClose: () => void;
  onConfirm: (data: Form) => Promise<void>;
  onAddFolder: () => void;
};

type Form = {
  folderSelection: FolderDropdownSelection;
};

export const MoveFlowModal: React.FC<Props> = ({
  open,
  workspaceId,
  flow,
  onClose,
  onConfirm,
}) => {
  const folders = useFolders({ workspaceId }).data?.folders;
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    reset,
  } = useForm<Form>({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    // Everytime the Modal is reopened with we need to reset the form
    if (open) {
      reset({
        folderSelection: {
          mode: "selectFolder",
          folderId: flow?.flow_folder_id,
        },
      });
    }
  }, [reset, open, flow?.flow_folder_id]);

  const onModalConfirm = async (data: Form) => {
    await onConfirm(data);
    onClose();
  };

  const onSubmit = handleSubmit(
    wrapWithAxiosResponseErrorHandler(async (data: Form) => {
      try {
        await onModalConfirm(data);
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 409) {
          setError(
            "folderSelection",
            { type: "notAvailable" },
            { shouldFocus: true },
          );
        } else {
          throw e;
        }
      }
    }),
  );

  const currentFolder = folders?.find(
    (folder) => folder.id === flow?.flow_folder_id,
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header description="Select a folder to move your Decision Flow">{`Move ${flow?.name} ${
        currentFolder !== undefined ? `from ${currentFolder.name}` : ""
      }`}</Modal.Header>
      <form onSubmit={onSubmit}>
        <Modal.Content>
          <div className="mb-1">
            <p className="text-gray-800 font-inter-semibold-13px">
              Folder name <RequiredAsterisk />
            </p>
            {errors.folderSelection && (
              <ErrorHint margin="none">
                {FOLDER_NAME_NOT_AVAILABLE_MESSAGE}
              </ErrorHint>
            )}
          </div>
          <Controller
            control={control}
            name="folderSelection"
            render={(fieldProps) => {
              return (
                <SelectFolderDropdown
                  folders={folders || []}
                  selected={fieldProps.field.value}
                  onSelect={(selection) => {
                    if (
                      selection.mode === "selectFolder" &&
                      fieldProps.field.value?.mode === "selectFolder" &&
                      fieldProps.field.value?.folderId === selection.folderId
                    ) {
                      fieldProps.field.onChange({
                        mode: "selectFolder",
                        folderId: null,
                      });
                    } else {
                      fieldProps.field.onChange(selection);
                    }
                  }}
                />
              );
            }}
          />
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="move-flow-save"
              disabled={!isEmpty(errors)}
              htmlType="submit"
              loading={isSubmitting}
              variant="primary"
            >
              Move
            </Button>
          }
        />
      </form>
    </Modal>
  );
};
