import {
  CompletionContext,
  acceptCompletion,
  autocompletion,
  completionKeymap,
  completionStatus,
} from "@codemirror/autocomplete";
import {
  Extension,
  keymap,
  Prec,
  tooltips,
  ViewPlugin,
  ViewUpdate,
} from "@uiw/react-codemirror";
import { useMemo } from "react";
import { createRoot } from "react-dom/client";

import {
  NoAutocompleteDataStatus,
  OutdatedAutocompleteDataStatus,
} from "src/base-components/CodeInput/Autocomplete/AutocompleteStatusContainer";
import {
  startCompletions,
  getCompletionSource,
} from "src/base-components/CodeInput/Autocomplete/getCompletionSource";
import { isDataPrefixed } from "src/base-components/CodeInput/CodeInput";
import { isFieldInLoopIteration } from "src/base-components/CodeInput/EditorCodeInput";
import { Pill } from "src/base-components/Pill";
import { getIconFromPythonType } from "src/base-components/TypeIcons";
import {
  getCompletionData,
  NoCompletionDataAvailable,
  useNodeCompletionData,
} from "src/nodeEditor/api/queries";

const getTypePill: React.FC<{ type: string }> = ({ type }) => {
  const typeIcon = getIconFromPythonType(type);
  return (
    <Pill dataLoc="type-pill" size="sm" variant="gray">
      <div className="flex flex-row items-center gap-0.5">
        {typeIcon && <Pill.Icon icon={typeIcon} padding={false} />}
        <Pill.Text fontType="code">{type}</Pill.Text>
      </div>
    </Pill>
  );
};

const HIDDEN_AUTOCOMPLETION_SUGGESTION_ID = "hidden" as const;
const HIDDEN_AUTOCOMPLETION_SUGGESTION_CLASSNAME =
  "hidden-completion-mrP2X8" as const;

const buildAutocompleteExtension = (
  versionId: string,
  nodeId: string | undefined,
): Extension => {
  const getTypingInfo = () => {
    if (nodeId === undefined) return undefined;
    const completionData = getCompletionData(versionId, nodeId);

    if (
      completionData !== undefined &&
      completionData !== NoCompletionDataAvailable
    ) {
      return completionData;
    }
  };

  const customCompletionKeymap = [
    ...completionKeymap,
    { key: "Tab", run: acceptCompletion },
  ];
  const completionKeymapExtension = Prec.highest(
    keymap.of(customCompletionKeymap),
  );

  return [
    // This is needed to make the autocompletion work on the fullscreen node editors as well
    tooltips({
      position: "fixed",
      parent: document.body,
    }),
    completionKeymapExtension,
    autocompletion({
      // We provide a custom keymap
      defaultKeymap: false,
      icons: false,
      override: [
        // The completion source that provides the actual completions
        (context: CompletionContext) => {
          return getCompletionSource(
            getTypingInfo,
            !!context.state.field(isDataPrefixed, false),
            !!context.state.field(isFieldInLoopIteration, false),
          )(context);
        },
        // The completion source that provides the information that the flow needs to be run first for autocompletion to work
        (context: CompletionContext) => {
          if (nodeId) {
            const typingInfo = getCompletionData(versionId, nodeId);
            const fieldIsEmpty = context.matchBefore(/^$/);
            // Show this information if we are sure that there is NoDataAvaiable and the field is empty or if the user explicitly requested the completion
            if (
              typingInfo === NoCompletionDataAvailable &&
              (fieldIsEmpty || context.explicit)
            ) {
              // Return a static completion result using the hidden completion id which will be used to open the tooltip but then be hidden via css
              return {
                from: context.pos,
                options: [
                  {
                    label: HIDDEN_AUTOCOMPLETION_SUGGESTION_ID,
                    detail: HIDDEN_AUTOCOMPLETION_SUGGESTION_ID,
                    apply: () => {},
                  },
                ],
              };
            }
          }
          return null;
        },
      ],
      optionClass: (completion) => {
        // Hide the completion if it is the no autocomplete data completion
        if (
          completion.label === HIDDEN_AUTOCOMPLETION_SUGGESTION_ID &&
          completion.detail === HIDDEN_AUTOCOMPLETION_SUGGESTION_ID
        ) {
          return `!h-0 invisible ${HIDDEN_AUTOCOMPLETION_SUGGESTION_CLASSNAME}`;
        }

        // We want to color special keywords differently
        const isKeywordCompletion = [...startCompletions].some(
          (base) => completion.label === base.label,
        );

        if (isKeywordCompletion) {
          return "!text-indigo-600";
        }
        return "!text-green-600";
      },
      addToOptions: [
        {
          render: (completion) => {
            if (!completion.type) return null;
            const typePill = getTypePill({ type: completion.type });
            const container = document.createElement("div");
            // Hack to avoid losing focus on code editor,
            // since suggestions are outside of table container
            container.addEventListener("click", (e) => e.preventDefault());
            document.body.appendChild(container);
            const root = createRoot(container);
            root.render(typePill);
            return container;
          },
          // Behind the completion name (pos 50)
          position: 60,
        },
      ],
    }),
  ];
};

export const useAutocompleteExtension = ({
  versionId,
  selectedNodeId,
  isLoopNode,
}: {
  versionId: string;
  selectedNodeId?: string;
  isLoopNode?: boolean;
}) => {
  // Fetch the autocompletion data to make use of it in completion extension
  useNodeCompletionData(versionId, selectedNodeId, isLoopNode);
  return useMemo(() => {
    const defaultCompletion = buildAutocompleteExtension(
      versionId,
      selectedNodeId,
    );
    return {
      defaultCompletion,
    };
  }, [versionId, selectedNodeId]);
};

export const showUpdatedSuggestionsPlugin = ViewPlugin.define(() => {
  return {
    update: (viewUpdate: ViewUpdate) => {
      // If a completion is opened append the outdated information to the completion list in the dom
      if (
        !completionStatus(viewUpdate.startState) &&
        completionStatus(viewUpdate.state)
      ) {
        const popupFindingInterval = setInterval(() => {
          const completionPopup = document.querySelector(
            ".cm-tooltip-autocomplete.cm-tooltip",
          );

          if (completionPopup) {
            clearInterval(popupFindingInterval);
            completionPopup.appendChild(
              document.querySelector(
                `.${HIDDEN_AUTOCOMPLETION_SUGGESTION_CLASSNAME}`,
              )
                ? NoAutocompleteDataStatus
                : OutdatedAutocompleteDataStatus,
            );
          }
        }, 20);
      }
    },
  };
});
