import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import { Root } from "@radix-ui/react-accordion";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { twMerge } from "tailwind-merge";

import {
  DataRow,
  ErrorBaseInfo,
  FieldErrorsT,
  NodeExecutionMetadata,
} from "src/api/types";
import { EditorAccordionItem as AccordionItem } from "src/base-components/EditorAccordionItem";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { TableComp as Table } from "src/base-components/Table";
import { DatabaseConnectionNodeDataT } from "src/constants/NodeDataTypes";
import { HeaderCell } from "src/dataTable/HeaderCell";
import { RawCell } from "src/dataTable/RawCell";
import { getAccessorFunction } from "src/dataTable/TableUtils";
import { CellContent } from "src/dataTable/types";
import { JSONValue } from "src/datasets/DatasetTable/types";
import { Callout } from "src/design-system/Callout";
import { Tooltip } from "src/design-system/Tooltip";
import { CodeNodeEditor } from "src/nodeEditor/CodeNodeEditor";
import { convertFieldErrorsBeToFe } from "src/utils/FieldErrorUtils";

export type RequestStatus = "success" | "error";
export type ResultStatus = "success" | "error" | "notAvailable";

type VariableT = {
  id_name: string;
  name: string;
  id_value: string;
  value?: JSONValue;
};

type ResultRow = Record<string, unknown>;
export type QueryResultT = ResultRow[] | string | null;

export const TabLabel: React.FC<{ label: string; isErrored?: boolean }> = ({
  label,
  isErrored,
}): JSX.Element => (
  <div className="flex gap-1.5">
    {label}
    {isErrored && (
      <Pill size="sm" variant="red">
        <Pill.Icon icon={faWarning} />
      </Pill>
    )}
  </div>
);

export const Header: React.FC<{ header?: string }> = ({ header }) => (
  <HeaderCell classNameOverrides="max-w-none py-1.5 pl-1.5 border-r border-gray-100 bg-gray-50">
    {header}
  </HeaderCell>
);

type CellProps<T> = {
  cell: CellContent<T>;
  classNameOverrides?: string;
  error?: string;
  switchToEditor?: () => void;
};

export const Cell = <T,>({
  cell,
  classNameOverrides,
  error,
  switchToEditor,
}: CellProps<T>) => {
  const baseClasses = "max-w-none py-1.5 pl-1.5 pr-2";

  if (error) {
    return (
      <RawCell<T>
        cell={cell}
        classNameOverrides={twMerge(
          baseClasses,
          "border border-red-400 bg-red-50 text-red-500",
          classNameOverrides,
        )}
        suffix={
          <Tooltip
            footerAction={
              switchToEditor
                ? {
                    text: "Debug",
                    onClick: switchToEditor,
                  }
                : undefined
            }
            placement="top"
            title={error}
            asChild
          >
            <Icon icon={faWarning} size="2xs" />
          </Tooltip>
        }
      />
    );
  }

  return (
    <RawCell<T>
      cell={cell}
      classNameOverrides={twMerge(
        baseClasses,
        "border-r border-gray-100",
        classNameOverrides,
      )}
    />
  );
};

const getVariablesColumns = (
  fieldErrors: FieldErrorsT | undefined,
  switchToEditor: () => void,
): ColumnDef<VariableT, React.ReactNode>[] => [
  {
    id: "name",
    header: () => <Header header="Name" />,
    accessorFn: (row) => `$${row.name}`,
    size: 120,
    maxSize: 120,
    cell: ({ cell }) => (
      <Cell
        cell={cell}
        classNameOverrides="text-orange-600"
        error={fieldErrors?.[cell.row.original.id_name]}
        switchToEditor={switchToEditor}
      />
    ),
  },
  {
    id: "value",
    header: () => <Header header="Value" />,
    accessorFn: getAccessorFunction("value"),
    cell: ({ cell }) => (
      <Cell
        cell={cell}
        error={fieldErrors?.[cell.row.original.id_value]}
        switchToEditor={switchToEditor}
      />
    ),
  },
];

export const QueryConfigTab: React.FC<{
  variables: VariableT[];
  sqlQuery: string;
  fieldErrors?: FieldErrorsT;
  switchToEditor: () => void;
  isFetching?: boolean;
}> = ({ variables, sqlQuery, fieldErrors, switchToEditor, isFetching }) => {
  return (
    <Root defaultValue={["variables", "query"]} type="multiple">
      <AccordionItem
        className="pl-5 pr-0 pt-2"
        headerClassName="pl-0 pr-4 h-5"
        iconSize="3xs"
        title="Variables"
        value="variables"
      >
        <div className="flex flex-row justify-center">
          <Table
            columns={getVariablesColumns(fieldErrors, switchToEditor)}
            data={variables}
            dataLoc="debug-modal-variables-table"
            frameClassName="w-full rounded-md border border-gray-100"
            isLoading={isFetching}
            loadingRowsCount={3}
          />
        </div>
      </AccordionItem>
      <AccordionItem
        className="pl-5 pr-0 pt-2"
        // mt-4 to ensure the same spacing in open and collapsed state
        headerClassName="pl-0 pr-4 h-5 mt-4"
        iconSize="3xs"
        title="SQL query"
        value="query"
      >
        <div className="h-56">
          <CodeNodeEditor
            displayedError={undefined}
            immutable={true}
            isReactive={false}
            language="sql"
            placeholder="Loading query..."
            src={sqlQuery}
            onChange={() => {}}
          />
        </div>
      </AccordionItem>
    </Root>
  );
};

const getResultColumns = (
  result: ResultRow[],
): ColumnDef<ResultRow, React.ReactNode>[] => {
  const columns = result.reduce((acc, row) => {
    Object.keys(row).forEach((key) => {
      if (!acc.includes(key)) {
        acc.push(key);
      }
    });
    return acc;
  }, [] as string[]) as string[];

  return columns.map((key) => ({
    id: key,
    header: () => <Header header={key} />,
    size: 120,
    maxSize: 120,
    accessorFn: getAccessorFunction(key),
    cell: ({ cell }) => <Cell cell={cell} />,
  }));
};

export const ExecutionResultTab: React.FC<{
  status: ResultStatus;
  result: QueryResultT;
  legacy?: boolean;
}> = ({ result, status, legacy }) => {
  if (status === "notAvailable") {
    return (
      <Callout type="neutral">
        SQL query was not executed because of runtime errors
      </Callout>
    );
  }
  if (status === "error") {
    if (typeof result === "string") {
      return (
        <Callout type="error">
          <span className="whitespace-pre-wrap break-all">{result}</span>
        </Callout>
      );
    } else {
      // This shouldn't happen b/c `result` is always string when
      // error happens
      return <Callout type="error">SQL query execution failed</Callout>;
    }
  }

  // Query executed successfully

  if (result === null) {
    // Query didn't return results, most probably
    // an INSERT/UPDATE query
    return (
      <Callout type="neutral">
        Executed SQL query did not return results
      </Callout>
    );
  }

  // Query errored, but the error was ignored b/c of `allow_client_errors` or `allow_server_errors`
  if (typeof result === "string") {
    return (
      <Callout type="neutral">
        <span className="whitespace-pre-wrap break-all">{result}</span>
      </Callout>
    );
  }

  if (result.length === 0) {
    return <Callout type="neutral">SQL query returned 0 results</Callout>;
  }

  return (
    <Table
      columns={getResultColumns(result)}
      data={result}
      dataLoc="debug-modal-results-table"
      frameClassName={twMerge(
        "w-full rounded-md border border-gray-100",
        legacy ? "max-h-[28rem]" : "max-h-full",
      )}
    />
  );
};

export const getQueryResult = (
  data: DataRow,
  dataFields: string[],
): QueryResultT => {
  const dataFieldValues = dataFields.map((field) => data[field]);
  // DBC node sets the query result as one of the `data` object fields. The name
  // of the field is controlled by the user, so we need to find it among the fields that
  // were added by the node.
  let queryResult: QueryResultT =
    dataFieldValues.find(
      (value) => value && typeof value === "object" && "data" in value,
    )?.data?.result ?? null;

  if (!queryResult) {
    // When `allow_client_errors` or `allow_server_errors` is set to `true`
    // in the DBC node, the flow execution won't be stopped and error won't be
    // explicitly reported. Instead, the error will be stored in the `detail[0].msg`
    // field of the node execution result
    queryResult =
      dataFieldValues.find(
        (value) => value && typeof value === "object" && "detail" in value,
      )?.detail?.[0]?.msg ?? null;
  }
  return queryResult;
};

export const getEvaluatedVariables = (
  nodeID: string,
  nodeVariables: DatabaseConnectionNodeDataT["variables"],
  nodeExecutionMetadata: NodeExecutionMetadata | undefined,
) => {
  const executionVariables: {
    [key: string]: { value: JSONValue; type_: string | null | undefined };
  } = nodeExecutionMetadata?.[nodeID]?.variables ?? {};

  /* For all variables defined in the Node get the corresponding values from the execution results */
  return nodeVariables.map((variableDefition) => ({
    id_name: variableDefition.id_key,
    name: variableDefition.key,
    id_value: variableDefition.id_expression,
    value:
      variableDefition.key in executionVariables
        ? executionVariables[variableDefition.key].value
        : undefined,
  }));
};

export const getFieldErrors = (error: ErrorBaseInfo | undefined) => {
  return error?.field_errors && error.field_errors.length > 0
    ? convertFieldErrorsBeToFe(error.field_errors)
    : undefined;
};
