import { faCube } from "@fortawesome/pro-regular-svg-icons";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOptions,
} from "@headlessui2/react";
import React, { useState } from "react";
import { twJoin } from "tailwind-merge";

import { AwsRegionTypeT, AWS_REGIONS } from "src/constants/AwsRegions";
import { EmptyState } from "src/design-system/EmptyState";
import { RegionComboBoxItem } from "src/layout/WorkspaceFormModal/RegionComboBoxItem";
import { RegionDisplay } from "src/layout/WorkspaceFormModal/RegionDisplay";
import { useFuseSearch } from "src/utils/useFuseSearch";

type RegionComboBoxProps = {
  value: AwsRegionTypeT;
  onChange: (region: AwsRegionTypeT) => void;
  disabled: boolean;
};

export const RegionComboBox: React.FC<RegionComboBoxProps> = ({
  value: propValue,
  onChange,
  disabled,
}) => {
  const [query, setQuery] = useState("");

  const search = useFuseSearch(AWS_REGIONS, {
    keys: ["identifier", "city", "region"],
  });

  const filteredRegions = search(query);

  const handleChange = (value: AwsRegionTypeT) => {
    if (value) {
      onChange(value);
      setQuery("");
    }
  };

  return (
    <Combobox
      by="identifier"
      disabled={disabled}
      value={propValue}
      immediate
      onChange={handleChange}
    >
      <div
        className={twJoin(
          "flex h-8 w-full rounded-lg border border-gray-200",
          disabled ? "bg-gray-50" : "bg-white focus-within:border-indigo-400",
          "relative overflow-hidden",
        )}
      >
        <ComboboxInput
          className={twJoin(
            "rounded-lg px-3 py-1 font-inter-normal-12px focus:outline-none",
            "w-full data-[open]:block",
            "absolute inset-0",
          )}
          placeholder="Search..."
          value={query}
          onChange={(event) => setQuery(event.target.value)}
        />
        <ComboboxButton
          className={twJoin(
            "pointer-events-none absolute inset-0 h-full w-full data-[open]:hidden",
            disabled ? "bg-gray-50" : "bg-white",
          )}
        >
          {({ value }) => <RegionDisplay awsRegion={value} padded />}
        </ComboboxButton>
      </div>
      <ComboboxOptions
        anchor={{
          to: "bottom",
          padding: 10,
          gap: 10,
        }}
        className={twJoin(
          "decideScrollbar h-56 w-[var(--input-width)] overflow-y-auto rounded-lg bg-white py-2 shadow-lg",
          "transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0",
        )}
        modal={false}
        transition
      >
        {filteredRegions.length === 0 && query !== "" ? (
          <EmptyState
            description="Please try again."
            headline="No matching region found."
            icon={faCube}
          />
        ) : (
          filteredRegions.map((r) => (
            <RegionComboBoxItem key={r.identifier} region={r} />
          ))
        )}
      </ComboboxOptions>
    </Combobox>
  );
};
