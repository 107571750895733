import { limitShift, offset, shift } from "@floating-ui/dom";
import { Popover } from "@headlessui2/react";
import { observer } from "mobx-react-lite";
import { RefObject, useCallback, useEffect, useState } from "react";

import { ChartRenderer, EditChartIcon } from "src/analytics/AnalyticsChart";
import {
  useComparativeAnalyticsData,
  useNodeAnalyticsData,
} from "src/analytics/api";
import { useCharts } from "src/analytics/queries";
import { isObservableChart } from "src/analytics/utils";
import { Divider } from "src/base-components/Divider";
import { useFloatingWindowsActions } from "src/base-components/FloatingWindow/hooks";
import { FloatingWindowProps } from "src/base-components/FloatingWindow/store";
import { Pill } from "src/base-components/Pill";
import { ChartResourceType } from "src/clients/flow-api";
import { getNodeIconFromNode } from "src/constants/NodeIcons";
import {
  FloatingWindowInternal,
  useFloatingWindowPosition,
} from "src/datasets/DatasetTable/FloatingWindow";
import { useGraphStore } from "src/store/StoreProvider";

export const FloatingChart: React.FC<FloatingWindowProps> = observer(
  ({
    id,
    computePosition,
    data: { resourceId, resourceType, id: chartId },
  }) => {
    const { nodes } = useGraphStore();
    const { close: closeWindow } = useFloatingWindowsActions();
    const [isResizing, setIsResizing] = useState(false);

    const { position, windowRef } = useFloatingWindowPosition(computePosition);
    const { data: charts, isFetched } = useCharts(resourceType, resourceId);

    const chart = charts?.find((chart) => chart.id === chartId);

    const {
      data: analyticsData,
      isLoading,
      isFetching,
    } = useAnalyticsData({
      resourceId,
      resourceType,
    });

    const isSuccess = analyticsData?.type === "success";
    const isPlotted = isSuccess && analyticsData.data.length > 0;

    useEffect(() => {
      // Close the window if the chart is not found
      // For example, was deleted
      if (isFetched && !chart) {
        closeWindow(id);
      }
    }, [chart, closeWindow, id, isFetched]);

    const isNodeChart = resourceType === ChartResourceType.NODE;
    const node = isNodeChart ? nodes.get(resourceId) : undefined;

    if (!chart || (isNodeChart && !node)) {
      return null;
    }

    const nodePill = isNodeChart
      ? node && (
          <>
            <Pill size="sm" variant="gray">
              <div className="-ml-px h-4 w-4">
                {getNodeIconFromNode(node, "sm")}
              </div>
              <Pill.Text>{node?.data.label}</Pill.Text>
            </Pill>
            <div className="ml-1">
              <Divider height="h-4" orientation="vertical" />
            </div>
          </>
        )
      : null;

    const chartingData =
      analyticsData?.type === "success" ? analyticsData.data : [];
    const chartingKeys =
      analyticsData?.type === "success" ? analyticsData?.keys : [];

    const isSummaryChart = !isObservableChart(chart);

    return (
      <Popover>
        <FloatingWindowInternal
          ref={windowRef}
          dataLoc="floating-chart"
          draggable={true}
          height={isSummaryChart ? "h-32" : undefined}
          id={id}
          isPinned={false}
          maximizable={false}
          resizeable={!isSummaryChart}
          style={{
            inset: "0px auto auto 0px",
            transform: position,
          }}
          title={
            <span className="truncate text-gray-800 font-inter-semibold-13px">
              {chart?.title}
            </span>
          }
          titleRight={
            <div className="flex items-center gap-x-1">
              {nodePill}
              <EditChartIcon
                chart={chart}
                chartingData={chartingData}
                chartingKeys={chartingKeys}
                dataLoc="floating-chart"
              />
            </div>
          }
          width={isSummaryChart ? "w-92" : undefined}
          onClose={() => closeWindow(id)}
          onResizeEnd={() => {
            if (isPlotted) {
              setIsResizing(false);
            }
          }}
          onResizeStart={() => {
            if (isPlotted) {
              setIsResizing(true);
            }
          }}
        >
          <div className="h-full p-2">
            <ChartRenderer
              chart={chart}
              data={isPlotted ? analyticsData.data : []}
              isLoading={(isLoading && isFetching) || isResizing}
              isMultiversion={Boolean(analyticsData?.isMultiversion)}
              keys={isSuccess ? analyticsData.keys : []}
              fitContainer
            />
          </div>
        </FloatingWindowInternal>
      </Popover>
    );
  },
);

export const usePopoutChart = () => {
  const { open: openWindow } = useFloatingWindowsActions();

  return useCallback(
    (
      chartInfo: {
        id: string;
        resourceId: string;
        resourceType: ChartResourceType;
      },
      target?: RefObject<HTMLDivElement>,
    ) => {
      openWindow(
        {
          id: chartInfo.id,
          type: "chart",
          target,
          positionOptions: {
            placement: "right",
            middleware: [
              offset({
                mainAxis: -100,
                crossAxis: 80,
              }),
              shift({
                padding: 10,
                crossAxis: true,
                limiter: limitShift(),
              }),
            ],
          },
        },
        chartInfo,
      );
    },
    [openWindow],
  );
};

const useAnalyticsData = ({
  resourceId,
  resourceType,
}: {
  resourceId: string;
  resourceType: ChartResourceType;
}) => {
  const isFlowChart = resourceType === ChartResourceType.FLOW;
  const flowAnalyticsData = useComparativeAnalyticsData({
    enabled: isFlowChart,
  });

  const nodeAnalyticsData = useNodeAnalyticsData(resourceId, {
    enabled: !isFlowChart,
  });

  return isFlowChart ? flowAnalyticsData : nodeAnalyticsData;
};
