import { ConnectionT } from "src/api/connectApi/types";
import { ManifestFormType } from "src/connections/config/manifest/types";

export const convertBEConnectionToManifestConnectionInput = (
  connection: ConnectionT,
): ManifestFormType => {
  if (!connection.manifest_version) {
    throw new Error(`Invalid connection type: ${connection.provider}`);
  }

  const inputs = connection.environment_configurations || {
    production: {},
  };

  return {
    name: connection.name,
    data_retention: connection.data_retention,
    resource_configs: connection.resource_configs,
    inputs,
    is_sandbox: connection.is_sandbox,
  };
};
