import React, { useCallback, useEffect, useMemo } from "react";
import { useOnClickOutside } from "usehooks-ts";

import { useColumnOrder } from "src/base-components/EditorTable/hooks/useColumnOrder";
import {
  useKeyboardNav,
  NavKey,
} from "src/base-components/EditorTable/hooks/useKeyboardNav";
import {
  CellId,
  useTableNavActions,
  useTableNavStore,
} from "src/base-components/EditorTable/stores";
import {
  EditorColumnGroup,
  RowBase,
} from "src/base-components/EditorTable/types";
import { coordinateToKey } from "src/base-components/EditorTable/utils";

export const HEADER_ROW_ID = "HEADER-ROW" as const;

export const parseCellId = (cellId: CellId): [string, string] => {
  const [rowId, colId] = cellId.split(/_(.*)/s, 2);
  return [rowId, colId];
};

type Props<T extends RowBase<T>> = {
  data: T[];
  columnGroups: EditorColumnGroup<T>[];
  tableContainerRef: React.RefObject<HTMLDivElement>;
  hiddenHeaders: boolean;
};

export const useSelectionHandler = <T extends RowBase<T>>({
  tableContainerRef,
  data,
  columnGroups,
  hiddenHeaders,
}: Props<T>) => {
  const childToParentRowId = useMemo(
    () =>
      data.reduce(
        (acc, row) => {
          const subRows = row.subRows || [];
          const children = Object.fromEntries(
            subRows.map((subRow) => [subRow.id, row.id]),
          );

          return {
            ...acc,
            ...children,
          };
        },
        {} as Record<string, string>,
      ),
    [data],
  );

  const editingCell = useTableNavStore((state) => state.editingCell);
  const selectedCell = useTableNavStore((state) => state.selectedCell);
  const { setSelectedCell, setEditingCell, setHiddenHeaders } =
    useTableNavActions();

  useEffect(() => {
    setHiddenHeaders(hiddenHeaders);
  }, [hiddenHeaders, setHiddenHeaders]);

  const parentRowOrder = useMemo(() => data.map((row) => row.id), [data]);
  const rowOrder = useMemo(
    () =>
      data.flatMap((row) => [
        row.id,
        ...(row.subRows ? row.subRows.map((subRow) => subRow.id) : []),
      ]),
    [data],
  );
  const colOrder = useColumnOrder(columnGroups);
  const commonColumns = useMemo(
    () =>
      columnGroups.flatMap((group) =>
        group.columns
          .filter((col) => Boolean(col.isCommon))
          .map((column) => String(column.key)),
      ),
    [columnGroups],
  );

  const nonExistentCellsIds = useMemo(() => {
    const subRows = data.flatMap(
      (row) => row.subRows?.map((subRow) => subRow.id) || [],
    );

    return commonColumns.flatMap((col) =>
      subRows.map((row) => coordinateToKey(row, col)),
    );
  }, [data, commonColumns]);

  const moveRow = useCallback(
    (cellId: CellId, diff: 1 | -1) => {
      const [row, col] = parseCellId(cellId);
      if (row === HEADER_ROW_ID) {
        const newRow = diff === -1 ? HEADER_ROW_ID : rowOrder[0];
        setSelectedCell(coordinateToKey(newRow, col));
        return;
      }

      const newRow = ((currentRowOrder) => {
        const rowIndex = currentRowOrder.indexOf(`${row}`);
        const testIndex = rowIndex + diff;
        if (testIndex >= currentRowOrder.length) {
          return currentRowOrder[currentRowOrder.length - 1];
        }

        if (testIndex < 0) {
          return HEADER_ROW_ID;
        }

        return currentRowOrder[testIndex];
      })(commonColumns.includes(col) ? parentRowOrder : rowOrder);

      setSelectedCell(coordinateToKey(newRow, col));
    },
    [setSelectedCell, rowOrder, commonColumns, parentRowOrder],
  );

  const moveCol = useCallback(
    (cellId: CellId, diff: 1 | -1) => {
      const [row, col] = parseCellId(cellId);
      const colIndex = colOrder.indexOf(col);
      const newCol = (() => {
        const testIndex = colIndex + diff;
        if (testIndex >= colOrder.length) {
          return colOrder[colOrder.length - 1];
        }

        if (testIndex < 0) {
          return colOrder[0];
        }

        return colOrder[testIndex];
      })();

      const newRow = (() => {
        if (row === HEADER_ROW_ID) {
          return HEADER_ROW_ID;
        }

        const newId = coordinateToKey(row, newCol);
        return nonExistentCellsIds.includes(newId)
          ? childToParentRowId[row]
          : row;
      })();

      setSelectedCell(coordinateToKey(newRow, newCol));
    },
    [setSelectedCell, colOrder, nonExistentCellsIds, childToParentRowId],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent, dir: Nullable<NavKey>) => {
      if (!selectedCell) {
        return;
      }

      // mode: editing
      if (editingCell) {
        switch (dir) {
          case NavKey.ESC:
            event.preventDefault();
            setEditingCell(null);
            setSelectedCell(null);
            break;

          case NavKey.TAB:
            event.preventDefault();
            moveCol(editingCell, event.shiftKey ? -1 : 1);
            break;

          case NavKey.ENTER:
            event.preventDefault();
            moveRow(editingCell, 1);
            break;
        }

        return;
      }

      // mode: not editing and modifier key pressed without any nav key
      if (
        (event.ctrlKey || event.metaKey || event.altKey || event.shiftKey) &&
        dir === null
      ) {
        return;
      }

      // mode: selected and not editing and a charater key is pressed (`dir` is null)
      if (!dir) {
        setEditingCell(selectedCell);
        return;
      }

      // mode: selected cell and not editing and nav key is pressed
      switch (dir) {
        case NavKey.ESC:
          event.preventDefault();
          setSelectedCell(null);
          break;

        case NavKey.TAB:
          event.preventDefault();
          moveCol(selectedCell, event.shiftKey ? -1 : 1);
          break;

        case NavKey.ENTER:
          event.preventDefault();
          setEditingCell(selectedCell);
          break;

        case NavKey.UP:
        case NavKey.DOWN:
          event.preventDefault();
          moveRow(selectedCell, dir === NavKey.UP ? -1 : 1);
          break;

        case NavKey.LEFT:
        case NavKey.RIGHT:
          event.preventDefault();
          moveCol(selectedCell, dir === NavKey.LEFT ? -1 : 1);
          break;
      }
    },
    [
      setSelectedCell,
      selectedCell,
      editingCell,
      setEditingCell,
      moveRow,
      moveCol,
    ],
  );

  useKeyboardNav({
    ref: tableContainerRef,
    callback: handleKeyDown,
  });

  const clearSelection = useCallback(
    (event: MouseEvent | TouchEvent | FocusEvent) => {
      /**
       * Works in sync with autocomplete code editor input
       * (look for container.addEventListener("click", ...) in useAutocompleteExtension.tsx),
       * event.defaultPrevented is true when the user clicks on the autocomplete suggestions
       * */
      if (event.defaultPrevented) {
        return;
      }

      setEditingCell(null);
    },
    [setEditingCell],
  );

  useOnClickOutside(tableContainerRef, clearSelection);
};
