import {
  ENVIRONMENT_MISSMATCH_IS_PROD,
  ENVIRONMENT_MISSMATCH_IS_SANDBOX,
  SingleDecisionQueryErrorReason,
  NO_DECISION_FOUND,
  FLOW_MISSMATCH,
  VERSION_MISSMATCH,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { assertUnreachable } from "src/utils/typeUtils";

export const getHistoryEmptyStateWording = (
  reason: SingleDecisionQueryErrorReason | "emptyData",
): { headline: string; description: string } => {
  switch (reason) {
    case ENVIRONMENT_MISSMATCH_IS_PROD:
      return {
        headline: "Environment missmatch",
        description: "The decision you searched for is a live decision",
      };
    case ENVIRONMENT_MISSMATCH_IS_SANDBOX:
      return {
        headline: "Environment missmatch",
        description: "The decision you searched for is a sandbox decision",
      };
    case FLOW_MISSMATCH:
    case VERSION_MISSMATCH:
    case NO_DECISION_FOUND:
      return {
        headline: "No decision found",
        description: "Make sure your input is correct or search a different ID",
      };
    case "emptyData":
      return {
        headline: "No results found",
        description:
          "Try adjusting the search or filter to find what you are looking for",
      };
    default:
      return assertUnreachable(reason);
  }
};
