import React from "react";
import { useForm } from "react-hook-form";
import { validate as isValidUUID } from "uuid";

import {
  useCreateConnection,
  useEditConnection,
} from "src/api/connectApi/queries";
import { ConnectionT } from "src/api/connectApi/types";
import { WorkspaceWithSettings } from "src/api/types";
import { Button } from "src/base-components/Button";
import { Divider } from "src/base-components/Divider";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { SecretField } from "src/connections/config/shared/SecretField";
import { env } from "src/connections/model/retool";
import {
  getDefaultRetoolConnectionConfigInputs,
  retoolConnectionConfigInputsToBEConnection,
  retoolConnectionToRetoolConnectionConfigInputs,
} from "src/connections/model/retool";
import { RetoolConnectionConfigInputsT } from "src/connections/types";
import { isValidURL } from "src/connections/urls";
import { Modal } from "src/design-system/Modal";
import { toastActions } from "src/design-system/Toast/utils";

type PropsT = {
  onClose: () => void;
  connection?: ConnectionT;
  workspace: WorkspaceWithSettings;
};

export const RetoolConnectionConfigForm: React.FC<PropsT> = ({
  onClose,
  connection,
  workspace,
}) => {
  const defaultValues: Partial<RetoolConnectionConfigInputsT> = connection
    ? retoolConnectionToRetoolConnectionConfigInputs(connection)
    : getDefaultRetoolConnectionConfigInputs();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting: formIsSubmitting },
  } = useForm<RetoolConnectionConfigInputsT>({
    defaultValues,
  });
  const createConnectionMutation = useCreateConnection(workspace.base_url);
  const editConnectionMutation = useEditConnection(
    workspace.base_url,
    connection?.id,
  );
  // To keep the scope of the implementation small, we only support production
  // environment for now but we can easily extend it to support other environments
  // if needed.
  const envConfigKey = `${env}Config`;
  const envErrors = errors[envConfigKey];
  const secretKey = `${envConfigKey}.apiKey`;

  const secretRecord = watch(secretKey);
  const enableSecret = () => {
    setValue(secretKey, { ...secretRecord, secret: false, value: null });
  };

  const onSubmit = async (data: RetoolConnectionConfigInputsT) => {
    const payload = retoolConnectionConfigInputsToBEConnection(
      data,
      connection,
    );
    try {
      if (connection) {
        await editConnectionMutation.mutateAsync({
          id: connection.id,
          payload,
        });
      } else {
        await createConnectionMutation.mutateAsync(payload);
      }
      const action = connection ? "updated" : "created";
      toastActions.success({
        title: `Connection ${action}`,
        description: `Connection ${data.name} has been ${action}.`,
        withSidebar: true,
      });
      onClose();
    } catch (e) {
      toastActions.failure({
        title: "Error",
        description: "We couldn't save your connection",
        withSidebar: true,
      });
    }
  };

  return (
    <form
      data-loc="retool-connection-config-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Modal.Content>
        <FormItem gap="sm" label="Connection name" isRequired>
          {Boolean(errors.name) && (
            <ErrorHint>{errors.name?.message}</ErrorHint>
          )}
          <Input
            data-loc="retool-connection-name"
            placeholder="e.g. Underwriting UI"
            {...register("name", {
              required: "Connection name is required",
            })}
            fullWidth
          />
        </FormItem>
        <Divider spacing="my-4" />
        <FormItem
          description="Find this URL when viewing the app in Retool"
          gap="sm"
          label="Base URL"
          isRequired
        >
          {Boolean(envErrors?.url) && (
            <ErrorHint>{envErrors?.url?.message}</ErrorHint>
          )}
          <Input
            data-loc="retool-base_url"
            placeholder="e.g. https://retool.taktile.com"
            {...register(`${env}Config.url`, {
              required: "Base URL is required",
              validate: {
                isValid: (value) => {
                  if (!isValidURL(value)) {
                    return "Invalid URL";
                  }
                  const url = new URL(value);
                  if (!["http:", "https:"].includes(url.protocol)) {
                    return "Invalid URL scheme. The base URL should start with http:// or https://.";
                  }
                  if (url.hostname.endsWith("retool.com")) {
                    return "Invalid URL hostname. The base URL should point to a custom domain.";
                  }
                  if (url.pathname !== "/") {
                    return "Invalid URL path. The base URL should not include a path.";
                  }
                  return true;
                },
              },
            })}
            fullWidth
          />
        </FormItem>

        <SecretField<RetoolConnectionConfigInputsT>
          dataLoc="retool-api_key"
          description="Find this API key in the Retool settings under Organization -> Retool API"
          enableSecret={enableSecret}
          isErrored={Boolean(errors[envConfigKey]?.apiKey?.value)}
          isSecret={Boolean(secretRecord.secret)}
          placeholder="e.g. retool_01hdj6chst0y4dwy4bx98pge6x"
          register={register}
          secretKey={`${secretKey}.value`}
          secretName="API Key"
          isRequired
        />

        <FormItem
          description="Get this from the URL when viewing the app in Retool (retool.com/apps/[App UUID]/...)"
          gap="sm"
          label="App UUID"
          isRequired
        >
          {Boolean(envErrors?.appUUID) && (
            <ErrorHint>{envErrors?.appUUID?.message}</ErrorHint>
          )}
          <Input
            data-loc="retool-app_uuid"
            placeholder="e.g. 123e4567-e89b-12d3-a456-426614174000"
            {...register(`${env}Config.appUUID`, {
              required: "App UUID is required",
              validate: {
                isValid: (value) =>
                  isValidUUID(value) || "Invalid App UUID format",
              },
            })}
            fullWidth
          />
        </FormItem>
        <FormItem
          description="Find this ID in the Retool settings under Organization -> Permissions"
          gap="sm"
          label="Group ID"
          isRequired
        >
          {Boolean(envErrors?.groupID) && (
            <ErrorHint>{envErrors?.groupID?.message}</ErrorHint>
          )}
          <Input
            data-loc="retool-group_id"
            placeholder="e.g. 1234"
            {...register(`${env}Config.groupID`, {
              required: "Group ID is required",
              validate: {
                isValid: (value) => {
                  if (
                    isNaN(Number(value)) ||
                    parseInt(value, 10).toString() !== value
                  ) {
                    return "Group ID must be a valid number";
                  }
                  return true;
                },
              },
            })}
            fullWidth
          />
        </FormItem>
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="retool-connection-save"
            disabled={formIsSubmitting}
            htmlType="submit"
            loading={formIsSubmitting}
            variant="primary"
          >
            Save
          </Button>
        }
      />
    </form>
  );
};
