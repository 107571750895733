import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  InputMappingGroupT,
  IntegrationResourceT,
} from "src/integrationNode/types";

const EXCLUSION_CRITERIA_TYPES = [
  { key: "ExcludeNonHeadQuarters", value: "not a headquarter" },
  { key: "ExcludeNonMarketable", value: "not marketable" },
  { key: "ExcludeOutofBusiness", value: "out of business" },
  { key: "ExcludeUndeliverable", value: "undeliverable" },
  { key: "ExcludeUnreachable", value: "unreachable" },
];

const getDefaultDnbAddress = (): InputMappingGroupT => ({
  getDefaultElements: () => ({
    street: {
      id: uuidV4(),
      type: "text",
      displayName: "Street",
      assignedTo: "",
      hint: `Up to 70 characters of the entity's street address. This value is required for "Name and Address" match.`,
    },
    country: {
      id: uuidV4(),
      type: "text",
      displayName: "Country",
      assignedTo: "",
      hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g, "DE" for Germany). This parameter is required for "Name and Address", "Registration Number" and "Telephone Number" match.`,
    },
    postal_code: {
      id: uuidV4(),
      type: "text",
      displayName: "Postal code",
      assignedTo: "",
      hint: `Up to 10 characters of the identifier used by the local country's postal authority to identify where the address is located. This value is required for "Postal Code" match.`,
    },
    city: {
      id: uuidV4(),
      type: "text",
      displayName: "City",
      assignedTo: "",
      hint: `1 to 50 characters that identifies the city, town, township, village, borough, etc. where the entity is located.`,
    },
    region: {
      id: uuidV4(),
      type: "text",
      displayName: "Region",
      assignedTo: "",
      hint: `The name of the locally governed area that forms part of a centrally governed nation to identify where the address is located.

      Note: As a guiding principle this is a geographic area which could theoretically exist as a separate nation. In the U.S. this would be a state. In the UK this would be one of the home nations.
      
      If country is US or CA, the region must be 2 characters.
      For other countries, it can be up to 64 characters.`,
    },
  }),
});

const getDefaultDnbPhone = (): InputMappingGroupT => ({
  getDefaultElements: () => ({
    number: {
      id: uuidV4(),
      type: "text",
      displayName: "Number",
      assignedTo: "",
      required: true,
    },
    dialling_code: {
      id: uuidV4(),
      type: "text",
      displayName: "Dialling code",
      assignedTo: "",
      hint: `A prefix to the phone number that connects to a geographical or logical location.`,
    },
  }),
});

export const getDefaultDnbIdentityResolution = (): IntegrationResourceT => ({
  providerResource: {
    provider: "dnb",
    resource: "identity_resolution",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      customer_reference: {
        id: uuidV4(),
        type: "text",
        displayName: "Customer reference",
        assignedTo: "",
        hint: `Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.`,
      },
      duns_number: {
        id: uuidV4(),
        type: "text",
        displayName: "D-U-N-S number",
        assignedTo: "",
        hint: `A 9-character numeric string identifying the entity by its Dun & Bradstreet D-U-N-S number. You can obtain a company's D-U-N-S number by using an Identity Resolution Node before requesting a report. Refer to our docs for more information.`,
      },
      registration_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Registration number",
        assignedTo: "",
        hint: `The number assigned by an external body or by Dun & Bradstreet that either uniquely identifies or helps to identify an organization.`,
      },
      registration_number_type: {
        id: uuidV4(),
        type: "text",
        displayName: "Registration number type",
        assignedTo: "",
        hint: `A unique code assigned by Dun & Bradstreet to identify the type of registration number. Refer to our docs for a list of possible values. Note that this is only required for inquiries on the following:
        * 33461 Office of Scottish Charity Regulator Charity Number (GB)
        * 33462 Charity Commission of Northern Ireland Charity Number (GB)
        * 33463 Charity Commission for England & Wales Charity Number (GB)
        * 33482 Financial Conduct Authority Reference Number (GB)`,
      },
      company_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Company name",
        assignedTo: "",
        hint: `Up to 240 characters used to find the entity by its primary name, tradestyle names, or former names.`,
      },
      website_url: {
        id: uuidV4(),
        type: "text",
        displayName: "Website URL",
        assignedTo: "",
      },
      email: {
        id: uuidV4(),
        type: "text",
        displayName: "Email",
        assignedTo: "",
      },
      confidence_threshold: {
        id: uuidV4(),
        type: "text",
        displayName: "Confidence threshold",
        assignedTo: "",
        hint: `The lowest confidence level for entities returned in the response. Valid values range from 1 to 10. If not specified, the default of 4 is used.`,
      },
    },
    grouped: {
      address: getDefaultDnbAddress(),
      phone: getDefaultDnbPhone(),
    },
    lists: {},
    multiselectors: {
      exclusion_criteria: {
        id: uuidV4(),
        displayName: "Exclude matches that are:",
        options: EXCLUSION_CRITERIA_TYPES,
        selected: [],
      },
    },
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      best_match_duns_number: {
        ...getNamedOutputMapping("Best match D-U-N-S number"),
        hint: `The D-U-N-S number (D&B's internal company identifier) of the match candidate with the highest confidence code. Use this string ID to request D&B credit reports further down the Decision Flow.`,
      },
      best_match_confidence_code: {
        ...getNamedOutputMapping("Best match confidence code"),
        hint: `The highest confidence code found among the match candidates. Values range between 1 and 10, where 10 means the highest possible confidence.`,
      },
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultDnbCreditReport = (customConfig: {
  isCustom: boolean;
}): IntegrationResourceT => ({
  providerResource: {
    provider: "dnb",
    resource: customConfig.isCustom ? "custom_report" : "credit_report",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      customer_reference: {
        id: uuidV4(),
        type: "text",
        displayName: "Customer reference",
        assignedTo: "",
        hint: `Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.`,
      },
      duns_number: {
        id: uuidV4(),
        type: "text",
        displayName: "D-U-N-S number",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: `A 9-character numeric string identifying the entity by its Dun & Bradstreet D-U-N-S number. You can obtain a company's D-U-N-S number by using an Identity Resolution Node before requesting a report. Refer to our docs for more information.`,
      },
      trade_up: {
        id: uuidV4(),
        type: "text",
        displayName: "Trade-up",
        assignedTo: "",
        hint: `Controls if the D-U-N-S number of the headquarter (for parameter value “hq”) or domestic headquarter (for parameter value “domhq”) is returned if the requested D-U-N-S number belongs to a branch. If the requested D-U-N-S number does not belong to a branch, it will be returned regardless of the parameter value. If the parameter is omitted, the requested D-U-N-S number is returned even if it belongs to a branch.`,
      },
    },
    grouped: {},
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: customConfig.isCustom
      ? {}
      : {
          ...getCommonOutputMappings(
            CommonOutputMappingKey.PROVIDER_REQUEST_ID,
            CommonOutputMappingKey.RETRIEVAL_DATETIME,
          ),
          failure_score: {
            ...getNamedOutputMapping("Failure score"),
            hint: `An integer value between 0 and 5 that expresses the likelihood of failure-of-payment risk by specifying in which risk group the entity belongs.`,
          },
          failure_score_national_percentile: {
            ...getNamedOutputMapping("Failure score national percentile"),
            hint: `A number that identifies where the entity's score falls relative to the scores of other entities. For example, if the percentile here is 10, it means that this entity's score is the same as or better than 10% of the entities in Dun & Bradstreet's information base, but not as good as 90% of the entities.`,
          },
          failure_score_risk_incidence: {
            ...getNamedOutputMapping("Failure score risk incidence"),
            hint: `The likelihood, expressed as a percentage, that failure of payment will occur for the entity. This percentage is determined based on which range the entity's score falls within a pre-determined set of score ranges. The ranges and percentages are determined through analysis of historical events.`,
          },
          delinquency_score: {
            ...getNamedOutputMapping("Delinquency score"),
            hint: `An integer value between 0 and 5 that expresses the likelihood of payment delinquency risk by specifying in which risk group the entity belongs.`,
          },
          delinquency_score_national_percentile: {
            ...getNamedOutputMapping("Delinquency score national percentile"),
            hint: `A number that identifies where the entity's score falls relative to the scores of other entities. For example, if the percentile here is 10, it means that this entity's score is the same as or better than 10% of the entities in Dun & Bradstreet's information base, but not as good as 90% of the entities.`,
          },
          delinquency_score_risk_incidence: {
            ...getNamedOutputMapping("Delinquency score risk incidence"),
            hint: `The likelihood, expressed as a percentage, that payment delinquency will occur for the entity. This percentage is determined based on which range the entity's score falls within a pre-determined set of score ranges. The ranges and percentages are determined through analysis of historical events.`,
          },
          financial_condition: {
            ...getNamedOutputMapping("Financial condition"),
            hint: `A unique code assigned by Dun & Bradstreet denoting the assessment of the overall financial condition of a subject. Refer to our docs for more information.`,
          },
          standard_rating: {
            ...getNamedOutputMapping("Standard rating"),
            hint: `The Dun & Bradstreet Rating is a system that measures a firm's size and composite credit appraisal, based upon information from a company's interim or fiscal balance sheet and an overall evaluation of the firm's creditworthiness. Refer to our docs for more information.`,
          },
          max_recommended_credit_limit: {
            ...getNamedOutputMapping("Max recommended credit limit"),
            hint: `The greatest amount of credit that should be extended to this entity. This amount may be determined, as an example, by matching an entity's profile (e.g., industry, employee size, credit risk) against credit benchmarks that have been created by Dun & Bradstreet using the same criteria.`,
          },
          credit_limit_currency: {
            ...getNamedOutputMapping("Credit limit currency"),
            hint: `The three-letter currency code, defined in the ISO 4217 scheme identifying the type of money in which the "max recommended credit limit" insight is expressed (e.g., USD, CAD, GBP, EUR).`,
          },
        },
  },
  config: defaultResourceConfig,
});
