import Fuse from "fuse.js";
import { useCallback, useMemo } from "react";

type BaseItem<V> =
  | V
  | (V & {
      pattern?: RegExp;
    });

export const useFuseSearch = <V>(
  items: BaseItem<V>[],
  options: Fuse.IFuseOptions<V>,
) => {
  const fuse = useMemo(() => {
    const itemsWithoutPattern = items.filter(
      (item) =>
        !item ||
        typeof item !== "object" ||
        !("pattern" in item) ||
        !item.pattern,
    );
    return new Fuse<V>(itemsWithoutPattern, options);
  }, [items, options]);

  return useCallback(
    (query: string): V[] => {
      if (query) {
        const filteredItems = fuse.search(query).map(({ item }) => item);
        const patternMatchedItems = items.filter(
          (item) =>
            item &&
            typeof item === "object" &&
            "pattern" in item &&
            item.pattern?.test(query),
        );

        return [...filteredItems, ...patternMatchedItems];
      } else {
        return items;
      }
    },
    [fuse, items],
  );
};
