import {
  ComputePositionConfig,
  ComputePositionReturn,
  computePosition,
} from "@floating-ui/dom";
import { RefObject } from "react";

import { logger } from "src/utils/logger";

export type ComputePositionOptions = Partial<ComputePositionConfig>;

export type ComputePositionFn = (
  floating: HTMLElement,
  options?: ComputePositionOptions,
) => Promise<ComputePositionReturn | null>;

export const curryComputePosition =
  (
    target: RefObject<HTMLElement> | undefined,
    positionOptions?: ComputePositionOptions,
  ): ComputePositionFn =>
  (floating, options) => {
    if (!target?.current) {
      logger.warn("No target element to compute position for");
      return Promise.resolve(null);
    }

    return computePosition(target.current, floating, {
      ...positionOptions,
      ...options,
    });
  };
