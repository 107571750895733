import { parseISO } from "date-fns";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { ReviewCaseStatusPending } from "src/api/types";
import { ReviewQueueFilters } from "src/manualReview/types";

export const DEFAULT_FILTERS: ReviewQueueFilters = {
  entityOrDecisionId: undefined,
  dateRange: undefined,
  environment: "prod",
  order: "decision_start_time_desc",
  flowVersionNames: undefined,
  assignees: undefined,
  statuses: undefined,
  nodeNames: undefined,
} as const;

const validate = <I = string | string[], O = string | undefined>(
  value: Nullable<I>,
  defaultValue: O,
  formatter: (value: I) => O = (value) => value as unknown as O,
): O => {
  if (!value) {
    return defaultValue;
  }

  if (Array.isArray(value)) {
    if (!value.length) {
      return defaultValue;
    }

    return formatter(value);
  }

  return formatter(value);
};

const convertSearchParamsToFilters = (
  searchParams: URLSearchParams,
): ReviewQueueFilters => {
  const flowVersionNames = searchParams.getAll("flowVersionNames");
  const assignees = searchParams.getAll("assignees");
  const statuses = searchParams.getAll("statuses");
  const nodeNames = searchParams.getAll("nodeNames");
  return {
    environment: validate(
      searchParams.get("environment"),
      DEFAULT_FILTERS.environment,
    ),
    order: validate(searchParams.get("order"), DEFAULT_FILTERS.order),
    entityOrDecisionId: validate(
      searchParams.get("entityOrDecisionId"),
      DEFAULT_FILTERS.entityOrDecisionId,
    ),
    dateRange: {
      from: validate(
        searchParams.get("from"),
        DEFAULT_FILTERS.dateRange?.from,
        parseISO,
      ),
      to: validate(
        searchParams.get("to"),
        DEFAULT_FILTERS.dateRange?.to,
        parseISO,
      ),
    },
    flowVersionNames: validate(
      flowVersionNames,
      DEFAULT_FILTERS.flowVersionNames,
    ),
    assignees: validate(assignees, DEFAULT_FILTERS.assignees),
    statuses: validate(
      statuses,
      DEFAULT_FILTERS.statuses,
    ) as ReviewCaseStatusPending[],
    nodeNames: validate(nodeNames, DEFAULT_FILTERS.nodeNames),
  };
};

const convertFiltersToSearchParams = (
  filters: Partial<ReviewQueueFilters>,
  searchParams: URLSearchParams,
): URLSearchParams => {
  const keys = Object.keys(filters) as (keyof ReviewQueueFilters)[];

  keys.forEach((key) => {
    if (key === "dateRange") {
      const value = filters[key];
      const dateRangeKeys = ["from", "to"] as const;

      dateRangeKeys.forEach((valueKey) => {
        const dateRangeValue = value?.[valueKey];
        if (dateRangeValue) {
          searchParams.set(valueKey, dateRangeValue.toISOString());
        } else {
          searchParams.delete(valueKey);
        }
      });

      return;
    }

    // If filter is undefined or it has default value
    // remove it from search params
    if (filters[key] === undefined || filters[key] === DEFAULT_FILTERS[key]) {
      searchParams.delete(key);
      return;
    }

    const value = filters[key];
    if (Array.isArray(value)) {
      searchParams.delete(key);
      value.forEach((val) => searchParams.append(key, val));
      return;
    }

    searchParams.set(key, value as string);
  });

  return searchParams;
};

export const useReviewQueueFilters = () => {
  const { search } = useLocation();
  const [lastUsedFiltersString, setLastUsedFiltersString] =
    useLocalStorage<string>("review-queue-filters", "");
  let [searchParams, setSearchParams] = useSearchParams(
    search.length ? search : lastUsedFiltersString,
  );

  // We use useEffect on the first render instead of initial value of useSearchParams
  // because we want to search params appear in browser address bar
  useEffect(() => {
    if (search === "" && lastUsedFiltersString) {
      setFilter(
        convertSearchParamsToFilters(
          new URLSearchParams(lastUsedFiltersString),
        ),
      );
    } else {
      setLastUsedFiltersString(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filters = useMemo(
    () => convertSearchParamsToFilters(searchParams),
    [searchParams],
  );

  const setFilter = useCallback(
    (filters: Partial<ReviewQueueFilters>) => {
      setLastUsedFiltersString((lastUsedFilters) =>
        convertFiltersToSearchParams(
          filters,
          new URLSearchParams(lastUsedFilters),
        ).toString(),
      );
      setSearchParams((searchParams) =>
        convertFiltersToSearchParams(filters, searchParams),
      );
    },
    [setLastUsedFiltersString, setSearchParams],
  );

  return [filters, setFilter] as const;
};
