import { v4 as uuidV4 } from "uuid";

import { ResourceTypesForIntegrationProvider } from "src/api/connectApi/types";
import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import { getCommonOutputMappings } from "src/integrationNode/integrationResources/common";
import {
  InputMappingGroupT,
  InputMappingT,
  IntegrationResourceT,
} from "src/integrationNode/types";

type SentiLinkResource = ResourceTypesForIntegrationProvider<"sentilink">;

const getDefaultAddressFields = (
  sentilinkResource: SentiLinkResource,
): { [key: string]: InputMappingT } => ({
  street1: {
    id: uuidV4(),
    type: "text",
    displayName: "Street address",
    assignedTo: "",
    rules: {
      required:
        sentilinkResource !== "sentilink_facets" &&
        sentilinkResource !== "sentilink_id_complete",
    },
  },
  street2: {
    id: uuidV4(),
    type: "text",
    displayName: "Street address line 2",
    assignedTo: "",
  },
  country: {
    id: uuidV4(),
    type: "text",
    displayName: "Country",
    assignedTo: "",
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: {
      required: sentilinkResource !== "sentilink_facets",
    },
  },
  state: {
    id: uuidV4(),
    type: "text",
    displayName: "State",
    assignedTo: "",
    hint: `A two-letter code for the U.S. state or territory of the address (e.g., "CA" for California)`,
    rules: {
      required: sentilinkResource !== "sentilink_facets",
    },
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal Code",
    assignedTo: "",
    rules: {
      required:
        sentilinkResource !== "sentilink_facets" &&
        sentilinkResource !== "sentilink_id_complete",
    },
  },
});

const getDefaultAddress = (
  sentilinkResource: SentiLinkResource,
): InputMappingGroupT => ({
  displayName: "Address",
  getDefaultElements: () => getDefaultAddressFields(sentilinkResource),
  elements: getDefaultAddressFields(sentilinkResource),
  rules: {
    required: sentilinkResource !== "sentilink_facets",
  },
});

const ungroupedScoreInputs = (): { [key: string]: InputMappingT } => ({
  application_id: {
    id: uuidV4(),
    type: "text",
    displayName: "Application ID",
    assignedTo: "",
    hint: `Any inputted string can be used to look up specific requests in the SentiLink dashboard and to track SentiLink requests. If omitted, Taktile will generate a random Application ID.`,
  },
  first_name: {
    id: uuidV4(),
    type: "text",
    displayName: "First name",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  last_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Last name",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  date_of_birth: {
    id: uuidV4(),
    type: "text",
    displayName: "Date of birth",
    assignedTo: "",
    hint: `Date of birth, formatted according to ISO: 'YYYY-MM-DD'`,
    rules: {
      required: true,
    },
  },
  email: {
    id: uuidV4(),
    type: "text",
    displayName: "Email",
    assignedTo: "",
  },
  ip_address: {
    id: uuidV4(),
    type: "text",
    displayName: "IP address",
    assignedTo: "",
    hint: `IPv4 or IPv6 address associated with the application`,
  },
});

export const getDefaultSentiLinkCustomScoreProducts =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "sentilink",
      resource: "sentilink_custom_score_products",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        ...ungroupedScoreInputs(),
        ssn: {
          id: uuidV4(),
          type: "text",
          displayName: "Social security number",
          assignedTo: "",
          hint: `The individual's 9-digit social security number, formatted as either a string of integers (e.g., “123456789”), or a string of integers and hyphens (e.g., “123-45-6789”). This will be required if Synthetic Fraud Score is requested.`,
        },
        phone_number: {
          id: uuidV4(),
          type: "text",
          displayName: "Phone number",
          assignedTo: "",
          hint: `The individual's phone number as a string of integers (e.g., “1234567890”). This will be required if Identity Theft Score is requested.`,
        },
      },
      grouped: {
        address: getDefaultAddress("sentilink_synthetic_fraud_score"),
      },
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(),
        synthetic_fraud_score: {
          ...getNamedOutputMapping("Synthetic Fraud Score"),
          hint: "A machine learning score that predicts the risk that an identity is synthetic. It identifies when the name, date of birth, and SSN on an application do not correspond to a real person. This score will only be available if the Synthetic Fraud Score product is selected during resource configuration.",
        },
        identity_theft_score: {
          ...getNamedOutputMapping("Identity Theft Score"),
          hint: "A machine learning score that predicts the risk that an identity is stolen. This score will only be available if the Identity Theft Score product is selected during resource configuration.",
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultSentiLinkSyntheticFraudScore =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "sentilink",
      resource: "sentilink_synthetic_fraud_score",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        ...ungroupedScoreInputs(),
        ssn: {
          id: uuidV4(),
          type: "text",
          displayName: "Social security number",
          assignedTo: "",
          rules: {
            required: true,
          },
          hint: `The individual's 9-digit social security number, formatted as either a string of integers (e.g., “123456789”), or a string of integers and hyphens (e.g., “123-45-6789”)`,
        },
        phone_number: {
          id: uuidV4(),
          type: "text",
          displayName: "Phone number",
          assignedTo: "",
        },
      },
      grouped: {
        address: getDefaultAddress("sentilink_synthetic_fraud_score"),
      },
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(),
        synthetic_fraud_score: {
          ...getNamedOutputMapping("Synthetic Fraud Score"),
          hint: "A machine learning score that predicts the risk that an identity is synthetic. It identifies when the name, date of birth, and SSN on an application do not correspond to a real person.",
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultSentiLinkIdentityTheftScore =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "sentilink",
      resource: "sentilink_identity_theft_score",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        ...ungroupedScoreInputs(),
        ssn: {
          id: uuidV4(),
          type: "text",
          displayName: "Social security number",
          assignedTo: "",
          hint: `The individual's 9-digit social security number, formatted as either a string of integers (e.g., “123456789”), or a string of integers and hyphens (e.g., “123-45-6789”)`,
        },
        phone_number: {
          id: uuidV4(),
          type: "text",
          displayName: "Phone number",
          assignedTo: "",
          rules: {
            required: true,
          },
        },
      },
      grouped: {
        address: getDefaultAddress("sentilink_identity_theft_score"),
      },
      lists: {},
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(),
        identity_theft_score: {
          ...getNamedOutputMapping("Identity Theft Score"),
          hint: "A machine learning score that predicts the risk that an identity is stolen",
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultSentiLinkFacets = (): IntegrationResourceT => ({
  providerResource: {
    provider: "sentilink",
    resource: "sentilink_facets",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      application_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Application ID",
        assignedTo: "",
        hint: `Any inputted string can be used to look up specific requests in the SentiLink dashboard and to track SentiLink requests. If omitted, Taktile will generate a random Application ID.`,
      },
      first_name: {
        id: uuidV4(),
        type: "text",
        displayName: "First name",
        assignedTo: "",
      },
      last_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Last name",
        assignedTo: "",
      },
      date_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Date of birth",
        assignedTo: "",
        hint: `Date of birth, formatted according to ISO: 'YYYY-MM-DD'`,
      },
      ssn: {
        id: uuidV4(),
        type: "text",
        displayName: "Social security number",
        assignedTo: "",
        hint: `The individual's 9-digit social security number, formatted as either a string of integers (e.g., “123456789”), or a string of integers and hyphens (e.g., “123-45-6789”)`,
      },
      phone_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Phone number",
        assignedTo: "",
      },
      email: {
        id: uuidV4(),
        type: "text",
        displayName: "Email",
        assignedTo: "",
      },
      ip_address: {
        id: uuidV4(),
        type: "text",
        displayName: "IP address",
        assignedTo: "",
        hint: `IPv4 or IPv6 address associated with the application`,
      },
    },
    grouped: {
      address: getDefaultAddress("sentilink_facets"),
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(),
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultSentiLinkIDComplete = (): IntegrationResourceT => ({
  providerResource: {
    provider: "sentilink",
    resource: "sentilink_id_complete",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      application_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Application ID",
        assignedTo: "",
        hint: `Any inputted string can be used to look up specific requests in the SentiLink dashboard and to track SentiLink requests. If omitted, Taktile will generate a random Application ID.`,
      },
      first_name: {
        id: uuidV4(),
        type: "text",
        displayName: "First name",
        assignedTo: "",
        rules: {
          required: true,
        },
      },
      middle_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Middle name",
        assignedTo: "",
      },
      last_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Last name",
        assignedTo: "",
        rules: {
          required: true,
        },
      },
      date_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Date of birth",
        assignedTo: "",
        hint: `Date of birth, formatted according to ISO: 'YYYY-MM-DD'`,
      },
      ssn: {
        id: uuidV4(),
        type: "text",
        displayName: "Social security number",
        assignedTo: "",
        hint: `The individual's 9-digit social security number, formatted as either a string of integers (e.g., “123456789”), or a string of integers and hyphens (e.g., “123-45-6789”)`,
      },
      phone_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Phone number",
        assignedTo: "",
      },
      email: {
        id: uuidV4(),
        type: "text",
        displayName: "Email",
        assignedTo: "",
      },
      ip_address: {
        id: uuidV4(),
        type: "text",
        displayName: "IP address",
        assignedTo: "",
        hint: `IPv4 or IPv6 address associated with the application`,
      },
    },
    grouped: {
      address: getDefaultAddress("sentilink_id_complete"),
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(),
      updated_fields: {
        ...getNamedOutputMapping("Updated fields"),
        hint: `The list of fields that were successfully updated. These updated fields will be part of the 'application' object in the response.`,
      },
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultSentiLinkInsightsReport = (): IntegrationResourceT => ({
  providerResource: {
    provider: "sentilink",
    resource: "sentilink_insights_report",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      application_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Application ID",
        assignedTo: "",
        hint: `Any inputted string can be used to look up specific requests in the SentiLink dashboard and to track SentiLink requests. If omitted, Taktile will generate a random Application ID.`,
      },
      first_name: {
        id: uuidV4(),
        type: "text",
        displayName: "First name",
        assignedTo: "",
        rules: {
          required: true,
        },
      },
      last_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Last name",
        assignedTo: "",
        rules: {
          required: true,
        },
      },
      date_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Date of birth",
        assignedTo: "",
        hint: `Date of birth, formatted according to ISO: 'YYYY-MM-DD'`,
        rules: {
          required: true,
        },
      },
      ssn: {
        id: uuidV4(),
        type: "text",
        displayName: "Social security number",
        assignedTo: "",
        rules: {
          required: true,
        },
        hint: `The individual's 9-digit social security number, formatted as either a string of integers (e.g., “123456789”), or a string of integers and hyphens (e.g., “123-45-6789”)`,
      },
      phone_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Phone number",
        assignedTo: "",
      },
      email: {
        id: uuidV4(),
        type: "text",
        displayName: "Email",
        assignedTo: "",
      },
      ip_address: {
        id: uuidV4(),
        type: "text",
        displayName: "IP address",
        assignedTo: "",
        hint: `IPv4 or IPv6 address associated with the application`,
      },
    },
    grouped: {
      address: getDefaultAddress("sentilink_insights_report"),
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(),
    },
  },
  config: defaultResourceConfig,
});
