import axios from "axios";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Input } from "src/base-components/Input";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { FlowFolderListItem } from "src/clients/flow-api";
import { Modal } from "src/design-system/Modal";
import { logger } from "src/utils/logger";
import { wrapWithAxiosResponseErrorHandler } from "src/utils/toastError";

export type ConfirmEditFolderModalParams = {
  folderId: string;
  name: string;
};

type Props = {
  open: boolean;
  folder?: FlowFolderListItem;
  mode: "edit" | "create";
  afterLeave?: () => void;
  onClose: () => void;
  onConfirm: (folderName: string) => Promise<void>;
};

type Form = {
  name: string;
};

export const FOLDER_NAME_NOT_AVAILABLE_MESSAGE =
  "Folder name already in use" as const;

export const EditFolderModal: React.FC<Props> = ({
  open,
  folder,
  mode,
  onClose,
  onConfirm,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
    setFocus,
  } = useForm<Form>({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    // Everytime the Modal is reopened with a new flow we need to reset the form to set the new default value
    if (open) {
      if (mode === "edit") {
        reset({
          name: folder?.name ?? "",
        });
      } else {
        reset({
          name: "",
        });
      }
      // Needs to happen with a timeout because it doesn't work right after reset
      // See Rules section on: https://react-hook-form.com/docs/useform/setfocus
      setTimeout(() => setFocus("name", { shouldSelect: true }), 0);
    }
  }, [reset, setFocus, open, mode, folder?.name]);

  const onModalConfirm = async (name: string) => {
    await onConfirm(name);
    onClose();
  };

  const onSubmit = handleSubmit(
    wrapWithAxiosResponseErrorHandler(async (data: Form) => {
      try {
        await onModalConfirm(data.name);
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 409) {
          setError("name", { type: "notAvailable" }, { shouldFocus: true });
        } else {
          logger.error(e);
        }
      }
    }),
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        {mode === "edit" ? "Rename folder" : "Create folder"}
      </Modal.Header>
      <form onSubmit={onSubmit}>
        <Modal.Content>
          <div className="mb-2">
            <p className="text-gray-800 font-inter-semibold-13px">
              Folder name <RequiredAsterisk />
            </p>
            {errors.name?.type === "notAvailable" && (
              <ErrorHint margin="none">
                {FOLDER_NAME_NOT_AVAILABLE_MESSAGE}
              </ErrorHint>
            )}
          </div>
          <Input
            data-loc="folder-name-input"
            errored={errors.name !== undefined}
            placeholder="Enter folder name"
            type="text"
            fullWidth
            {...register("name", { required: true })}
          />
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="folder-modal-confirm"
              disabled={!isEmpty(errors)}
              htmlType="submit"
              loading={isSubmitting}
              variant="primary"
            >
              {mode === "edit" ? "Save Changes" : "Create"}
            </Button>
          }
        />
      </form>
    </Modal>
  );
};
