import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";

const textStyles = "text-xs font-mono";
const textColor = "text-gray-800";

type MonospacedInputProps = React.HTMLProps<HTMLInputElement> & {
  containerClassName?: string;
  inputDataLoc?: string;
  prefix?: React.ReactNode;
  errored?: boolean;
  children?: React.ReactNode;
  errorIconOnError?: boolean;
  codeColors?: boolean;
  formProps?: UseFormRegisterReturn;
};

export const MonospacedInput = React.forwardRef<
  HTMLInputElement,
  MonospacedInputProps
>(
  (
    {
      containerClassName,
      className,
      inputDataLoc,
      prefix,
      errored,
      children,
      errorIconOnError,
      autoComplete = "off",
      codeColors = false,
      formProps,
      ...props
    },
    ref,
  ) => {
    const containerStyles = twJoin(
      "relative flex h-8 items-center rounded-lg border border-gray-200 bg-white",
      "focus-within:border-indigo-600 focus-within:ring-2 focus-within:ring-indigo-600/25",
      containerClassName,
    );

    const prefixStyles = twJoin(
      "flex h-full items-center py-1 pl-3 pr-2",
      "bg-gray-100",
      "border-r border-gray-200",
      "rounded-l-lg",
      codeColors ? "text-indigo-600" : textColor,
      textStyles,
    );

    const inputStyles = twJoin(
      "h-full flex-grow py-1 pl-2",
      "rounded-r-lg",
      "focus:outline-none focus:ring-0",
      "placeholder-gray-500",
      errored && errorIconOnError ? "pr-7" : "pr-2",
      codeColors && !prefix && "text-indigo-600",
      codeColors && prefix && "text-green-700",
      !codeColors && textColor,
      !prefix && "rounded-l-lg",
      errored && "ring-1 ring-red-500",
      props.disabled ? "cursor-not-allowed bg-gray-100" : "bg-white",
      textStyles,
      className,
    );

    return (
      <div className={containerStyles}>
        {children}
        {prefix && <div className={prefixStyles}>{prefix}</div>}
        <input
          ref={ref}
          autoComplete={autoComplete}
          className={inputStyles}
          data-loc={inputDataLoc}
          {...props}
          {...formProps}
        />
        {errored && errorIconOnError && (
          <div className="absolute right-3">
            <Icon color="text-red-400" cursorType="text" icon={faWarning} />
          </div>
        )}
      </div>
    );
  },
);
