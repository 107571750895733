import {
  faArrowLeft,
  faBolt,
  faCheck,
  faChevronRight,
  faFilter,
} from "@fortawesome/pro-regular-svg-icons";
import { faFilter as faFilterSolid } from "@fortawesome/pro-solid-svg-icons";
import { Menu } from "@headlessui/react";
import { Placement } from "@popperjs/core";
import { format } from "date-fns";
import { AnimatePresence, m } from "framer-motion";
import React, { useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";

import { DevisionHistoryV3GetRequest } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { DecisionEnvironment } from "src/api/types";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { Spinner } from "src/base-components/Spinner";
import { FlowDbShallow } from "src/clients/flow-api";
import { ListDecisionsDecisionsGetOriginTypesEnum } from "src/clients/history-v3";
import { useRuns } from "src/jobs/api/queries";
import { Job } from "src/jobs/types";
import { useWorkspaceContext } from "src/router/routerContextHooks";

const filterKeysHandled = [
  "originTypes",
  "jobIds",
  "jobRunIds",
  "parentFlowIds",
  "parentFlowVersionIds",
] as const;
type OriginFilters = Pick<
  DevisionHistoryV3GetRequest,
  (typeof filterKeysHandled)[number]
>;

const resetBase: OriginFilters = {
  jobIds: undefined,
  jobRunIds: undefined,
  parentFlowIds: undefined,
  parentFlowVersionIds: undefined,
  originTypes: undefined,
};

type Props = {
  selected: OriginFilters;
  onSelect: (value: OriginFilters) => void;
  jobs: Job[];
  flows: FlowDbShallow[];
  placement: Placement;
  env: DecisionEnvironment;
};

type MenuMode =
  | { type: "main" }
  | { type: "job"; job: Job }
  | { type: "flow"; flow: FlowDbShallow };

export const OriginColumnFilter: React.FC<Props> = ({
  flows,
  jobs,
  onSelect,
  selected,
  placement,
  env,
}) => {
  const [menuMode, setMenuMode] = useState<MenuMode>({ type: "main" });

  const [menuRef, setMenuRef] = useState<Nullable<HTMLElement>>(null);
  const [itemsRef, setItemsRef] = useState<Nullable<HTMLDivElement>>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    menuRef,
    itemsRef,
    {
      strategy: "fixed",
      placement,
      modifiers: [{ name: "offset", options: { offset: [8, 4] } }],
    },
  );
  const isFiltering = !filterKeysHandled.every(
    (filterKey) => selected[filterKey] === undefined,
  );
  return (
    <Menu ref={setMenuRef} as="div">
      {({ open }) => (
        <>
          <Menu.Button>
            <Icon
              color={isFiltering ? "text-indigo-500" : "text-gray-500"}
              icon={isFiltering ? faFilterSolid : faFilter}
              size="2xs"
            />
          </Menu.Button>
          {createPortal(
            <AnimatePresence>
              {open && (
                <m.div
                  key="origin_column_filter"
                  ref={setItemsRef}
                  style={{
                    ...popperStyles.popper,
                    maxHeight: menuRef
                      ? `calc(100vh - ${menuRef.scrollHeight}px)`
                      : undefined,
                  }}
                  {...popperAttributes.popper}
                  animate={{ opacity: 1, transition: { duration: 0.2 } }}
                  className="z-50 w-[280px] overflow-y-auto rounded-lg bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none"
                  exit={{ opacity: 0, transition: { duration: 0.1 } }}
                  initial={{ opacity: 0 }}
                >
                  <Menu.Items
                    className="text-gray-800 font-inter-normal-13px"
                    static
                  >
                    {menuMode.type === "main" && (
                      <>
                        <div className="flex h-12 items-center justify-between px-4">
                          <p className="font-inter-semibold-13px">
                            Filter by origin
                          </p>
                          {selected && (
                            <button
                              className="text-indigo-500 font-inter-normal-13px"
                              onClick={() => onSelect(resetBase)}
                            >
                              Reset
                            </button>
                          )}
                        </div>
                        <Menu.Item
                          as="div"
                          className="flex h-12 cursor-pointer items-center justify-between px-4 hover:bg-gray-50"
                          onClick={() =>
                            onSelect({
                              ...resetBase,
                              originTypes:
                                selected?.originTypes ===
                                ListDecisionsDecisionsGetOriginTypesEnum.API_CALL
                                  ? undefined
                                  : ListDecisionsDecisionsGetOriginTypesEnum.API_CALL,
                            })
                          }
                        >
                          API Call
                          {selected?.originTypes ===
                            ListDecisionsDecisionsGetOriginTypesEnum.API_CALL && (
                            <Icon
                              color="text-indigo-500"
                              icon={faCheck}
                              size="xs"
                            />
                          )}
                        </Menu.Item>
                        {jobs.length !== 0 && (
                          <>
                            <Divider spacing="my-2" />
                            <div className="flex h-12 items-center pl-4 font-inter-medium-13px">
                              Jobs
                            </div>
                            {jobs.map((job) => (
                              <Menu.Item
                                as="div"
                                className="group flex h-12 cursor-pointer items-center justify-between px-4 hover:bg-gray-50"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setMenuMode({ type: "job", job });
                                }}
                              >
                                <div className="flex gap-x-2">
                                  <Icon
                                    color="text-gray-400"
                                    icon={faBolt}
                                    size="sm"
                                  />
                                  {job.name}
                                </div>
                                <div className="flex gap-x-2">
                                  {selected?.jobIds === job.id && (
                                    <Icon
                                      color="text-indigo-500"
                                      icon={faCheck}
                                      size="xs"
                                    />
                                  )}
                                  <div className="hidden group-hover:block">
                                    <Icon
                                      color="text-gray-500"
                                      icon={faChevronRight}
                                      size="xs"
                                    />
                                  </div>
                                </div>
                              </Menu.Item>
                            ))}
                          </>
                        )}
                        {flows.length !== 0 && (
                          <>
                            <Divider spacing="my-2" />
                            <div className="flex h-12 items-center pl-4 font-inter-medium-13px">
                              Decision Flow
                            </div>
                            {flows.map((flow) => (
                              <Menu.Item
                                as="div"
                                className="group flex h-12 cursor-pointer items-center justify-between px-4 hover:bg-gray-50"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setMenuMode({ type: "flow", flow });
                                }}
                              >
                                {flow.name}
                                <div className="flex gap-x-2">
                                  {selected?.parentFlowIds === flow.id && (
                                    <Icon
                                      color="text-indigo-500"
                                      icon={faCheck}
                                      size="xs"
                                    />
                                  )}
                                  <div className="hidden group-hover:block">
                                    <Icon
                                      color="text-gray-500"
                                      icon={faChevronRight}
                                      size="xs"
                                    />
                                  </div>
                                </div>
                              </Menu.Item>
                            ))}
                          </>
                        )}
                      </>
                    )}
                    {menuMode.type === "job" && (
                      <JobSection
                        env={env}
                        job={menuMode.job}
                        selected={selected}
                        onExit={() => setMenuMode({ type: "main" })}
                        onSelect={onSelect}
                      />
                    )}
                    {menuMode.type === "flow" && (
                      <FlowSection
                        flow={menuMode.flow}
                        selected={selected}
                        onExit={() => setMenuMode({ type: "main" })}
                        onSelect={onSelect}
                      />
                    )}
                  </Menu.Items>
                </m.div>
              )}
            </AnimatePresence>,
            document.body,
          )}
        </>
      )}
    </Menu>
  );
};

const JobSection: React.FC<{
  job: Job;
  selected: OriginFilters;
  onSelect: (value: OriginFilters) => void;
  onExit: () => void;
  env: DecisionEnvironment;
}> = ({ job, onExit, onSelect, env, selected }) => {
  const { workspace } = useWorkspaceContext();
  const runsQuery = useRuns(workspace.base_url!, job.id, {
    environment: env,
    status: undefined,
  });

  const fetchMoreOnCloseToBottom: React.UIEventHandler<HTMLDivElement> = (
    e,
  ) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
    if (
      scrollHeight - scrollTop - clientHeight < 200 &&
      !runsQuery.isFetchingNextPage &&
      runsQuery.hasNextPage
    ) {
      runsQuery.fetchNextPage();
    }
  };

  return (
    <>
      <div className="flex h-12 items-center pl-4 text-gray-800 font-inter-medium-13px">
        <Icon
          color="text-gray-500"
          icon={faArrowLeft}
          size="sm"
          onClick={onExit}
        />
        <span className="ml-2">{job.name}</span>
      </div>
      {(() => {
        if (runsQuery.isLoading) return <Spinner />;
        const runs = runsQuery.data?.pages.flatMap((page) => page);

        if (!runs?.length) {
          return (
            <p className="flex h-12 items-center pl-4 text-gray-500 font-inter-normal-12px">
              There are no runs for this job yet
            </p>
          );
        }
        return (
          <div
            className="max-h-[300px] overflow-y-auto"
            onScroll={fetchMoreOnCloseToBottom}
          >
            {runs.map((run) => {
              const isSelected = selected?.jobRunIds === run.id;
              return (
                <Menu.Item
                  key={run.id}
                  as="div"
                  className="group flex h-12 cursor-pointer items-center justify-between px-4 font-inter-normal-13px hover:bg-gray-50"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSelect({
                      ...resetBase,
                      jobIds: isSelected ? undefined : job.id,
                      jobRunIds: isSelected ? undefined : run.id,
                    });
                  }}
                >
                  {format(new Date(run.created_at), "d MMMM yyyy p")}
                  {isSelected && (
                    <Icon color="text-indigo-500" icon={faCheck} size="sm" />
                  )}
                </Menu.Item>
              );
            })}
          </div>
        );
      })()}
    </>
  );
};

const FlowSection: React.FC<{
  flow: FlowDbShallow;
  selected: OriginFilters;
  onSelect: (value: OriginFilters) => void;
  onExit: () => void;
}> = ({ onExit, onSelect, selected, flow }) => {
  return (
    <>
      <div className="flex h-12 items-center pl-4 text-gray-800 font-inter-medium-13px">
        <Icon
          color="text-gray-500"
          icon={faArrowLeft}
          size="sm"
          onClick={onExit}
        />
        <span className="ml-2">{flow.name}</span>
      </div>
      <div className="max-h-[300px] overflow-y-auto">
        {flow.versions?.map((version) => {
          const isSelected = selected?.parentFlowVersionIds === version.id;
          return (
            <Menu.Item
              key={version.id}
              as="div"
              className="group flex h-12 cursor-pointer items-center justify-between px-4 font-inter-normal-13px hover:bg-gray-50"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSelect({
                  ...resetBase,
                  parentFlowIds: isSelected ? undefined : flow.id,
                  parentFlowVersionIds: isSelected ? undefined : version.id,
                });
              }}
            >
              {version.name}
              {isSelected && (
                <Icon color="text-indigo-500" icon={faCheck} size="sm" />
              )}
            </Menu.Item>
          );
        })}
      </div>
    </>
  );
};
