import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { groupBy, keyBy, noop } from "lodash";
import { useCallback, useMemo } from "react";

import { ResourceSample } from "src/api/connectApi/types";
import { FlowT, FlowVersionFlowChild, FlowVersionT } from "src/api/flowTypes";
import { useFlowVersionMockableNodes } from "src/api/flowVersionQueries";
import {
  Dataset,
  DatasetColumnGroupToRowDataGroupMap,
  DatasetColumnGroups,
  DatasetRow,
  DesiredType,
  ExtendedDatasetColumnGroups,
} from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { DatasetMockableNodes } from "src/constants/NodeDataTypes";
import { RowIndexCell } from "src/datasets/DatasetTable/RowIndexCell";
import {
  GroupHeader,
  Header,
  NoMenuHeader,
  MockColumn,
  SubGroupHeader,
} from "src/datasets/DatasetTable/cells";
import {
  stringifySelection,
  useDatasetEditTableActions,
} from "src/datasets/DatasetTable/stores";
import {
  DatasetContext,
  JSONValue,
  VersionSchemas,
} from "src/datasets/DatasetTable/types";
import {
  MOCK_COLUMN_DISABLED_TOOLTIP,
  SUB_COLUMN_SEPARATOR,
  getAvailableColumns,
  getGroupColumns,
  getOutcomeDatasetColumns,
} from "src/datasets/DatasetTable/utils";
import {
  DeleteColumnPayload,
  PutColumnPayload,
  usePutColumns,
} from "src/datasets/api/queries";
import {
  DatasetIssue,
  DatasetIssues,
  DatasetIntegrationNode,
  isIntegrationNodeWithLiveConnection,
  buildUiSchemas,
  schemaTypeToDesiredType,
} from "src/datasets/utils";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { getMockableNode } from "src/jobs/jobUtils";
import { getSampleReportsForManualReviewNode } from "src/manualReview/utils";
import { useOutcomeTypes } from "src/outcomes/queries";
import { OutcomeType } from "src/outcomes/types";
import {
  getSampleReportsForFlowNode,
  getSampleReportsForLoopNode,
} from "src/parentFlowNodes/utils";
import { useFlowContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";
import {
  isCustomConnectionNode,
  isInboundWebhookConnectionNode,
  isManualReviewConnectionNode,
  isDatabaseConnectionNode,
  isFlowNode,
  isLoopNode,
  isParentNode,
} from "src/utils/predicates";

const columnHelper = createColumnHelper<DatasetRow>();

const getColumnSize = (name: string) => name.length * 9;

const getWarningTooltip = (
  issue: DatasetIssue | undefined,
  flow: FlowT,
  version: FlowVersionT,
) => {
  if (issue?.issueType === "type-mismatch") {
    return {
      title: `This column cannot be used for testing [${flow.name}, ${version.name}]. The column type is \`${issue.datasetType}\`, schema input type is \`${issue.schemaType}\``,
    };
  }
};

const useLocalSampleReports = (
  nodes: DatasetMockableNodes[],
  version?: FlowVersionFlowChild,
) => {
  return useMemo(() => {
    if (!version) {
      return {};
    }

    const schemasByNodeId = nodes.reduce(
      (acc, node) =>
        isParentNode(node)
          ? {
              ...acc,
              [node.id]: (() => {
                const childVersion = version.child_versions?.find(
                  (item) => item.id === node.data.child_flow_version_id,
                );
                if (childVersion) {
                  return buildUiSchemas(childVersion as FlowVersionT);
                }
                return { input: undefined, output: undefined };
              })(),
            }
          : acc,
      {} as Record<string, VersionSchemas>,
    );

    return nodes.reduce(
      (acc, node) => ({
        ...acc,
        [node.id]: (() => {
          if (isManualReviewConnectionNode(node)) {
            return getSampleReportsForManualReviewNode(node);
          }

          if (isFlowNode(node)) {
            return getSampleReportsForFlowNode(schemasByNodeId[node.id]);
          }

          if (isLoopNode(node)) {
            return getSampleReportsForLoopNode(schemasByNodeId[node.id]);
          }

          return null;
        })(),
      }),
      {} as Record<string, ResourceSample[] | null>,
    );
  }, [nodes, version?.child_versions]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useAvailableIntegrationNodes = (
  version?: FlowVersionFlowChild,
): DatasetIntegrationNode[] => {
  const mockableNodes = useMemo(
    () => (version ? getMockableNode(version) : []),
    [version],
  );
  const childFlowVersionIds = useMemo(
    () =>
      mockableNodes
        .map((node) =>
          isParentNode(node)
            ? {
                nodeId: node.id,
                flowVersionId: node.data.child_flow_version_id,
              }
            : null,
        )
        .filter((value) => value && value.flowVersionId) as {
        nodeId: string;
        flowVersionId: string;
      }[],
    [mockableNodes],
  );

  const childMockableNodes = useFlowVersionMockableNodes(childFlowVersionIds);
  const allMockableNodes = useMemo(
    () => [
      ...mockableNodes,
      ...(childMockableNodes ? Object.values(childMockableNodes).flat() : []),
    ],
    [mockableNodes, childMockableNodes],
  );
  const localSampleReports = useLocalSampleReports(allMockableNodes, version);

  return useMemo(() => {
    const buildConfig = (
      node: DatasetMockableNodes,
    ): DatasetIntegrationNode => {
      return {
        id: node.id,
        name: node.data.label,
        provider: isParentNode(node)
          ? node.type
          : node.data.providerResource.provider,
        resource: isParentNode(node)
          ? null
          : node.data.providerResource.resource,
        mediaKey:
          (isCustomConnectionNode(node) ||
            isInboundWebhookConnectionNode(node)) &&
          node.data.mediaKey
            ? node.data.mediaKey
            : null,
        testingConfig: isCustomConnectionNode(node)
          ? node.data.config.testing
          : null,
        environmentsConfig:
          isCustomConnectionNode(node) ||
          isDatabaseConnectionNode(node) ||
          isInboundWebhookConnectionNode(node)
            ? node.data.config.environments_config
            : null,
        localSampleReports: localSampleReports[node.id],
        mockableChildNodes: null,
      };
    };

    return mockableNodes.map((node) => {
      return {
        ...buildConfig(node),
        mockableChildNodes: isParentNode(node)
          ? (childMockableNodes?.[node.id]?.map((node) => buildConfig(node)) ??
            [])
          : null,
      };
    });
  }, [mockableNodes, childMockableNodes, localSampleReports]);
};

export const INDEX_COLUMN_ID = "3f40e3f6-e8e7-43a5-ad0d-8ed7604ab073";
export const INDEX_COLUMN_GROUP_ID = `row_index_group_${INDEX_COLUMN_ID}`;

export const useDatasetColumns = ({
  context,
  dataset,
  schemas,
  version,
  onColumnsDelete,
  onColumnRename,
  onColumnAdd,
  onAddSubMocks,
  onDeleteSubMocks,
  onColumnTypeChange,
  issues,
  onColumnFill,
  onAddAdditionalColumn,
  outcomeTypes,
}: {
  context: DatasetContext;
  dataset: Dataset;
  schemas: VersionSchemas;
  version?: FlowVersionT;
  outcomeTypes?: OutcomeType[];
  onColumnsDelete: (payloads: DeleteColumnPayload[]) => void;
  onColumnRename: (
    group: DatasetColumnGroups,
    oldName: string,
    newName: string,
    desiredType: DesiredType,
    hasSubflowMocks: boolean,
  ) => void;
  onColumnAdd: (name: string, group: DatasetColumnGroups) => void;
  onDeleteSubMocks: (
    subMockName: string | null,
    mockColumnName: string,
  ) => void;
  onAddSubMocks: (
    subMockNames: string[],
    mockColumnName: string,
    desiredType: Extract<DesiredType, "object" | "any">,
  ) => void;
  onColumnTypeChange: (
    name: string,
    group: DatasetColumnGroups,
    desiredType: DesiredType,
  ) => void;
  onColumnFill: (
    name: string,
    group: DatasetColumnGroups,
    value: JSONValue,
  ) => void;
  issues: DatasetIssues;
  onAddAdditionalColumn: () => void;
}) => {
  const { flow } = useFlowContext();
  const integrationNodes = useAvailableIntegrationNodes(version);
  const subflowMockColumns = useMemo(
    () =>
      dataset.mock_columns
        .filter((col) => col.use_subflow_mocks)
        .map((col) => col.name),
    [dataset.mock_columns],
  );

  const { input, auxiliary } = useMemo(
    () => getGroupColumns(dataset, schemas.input),
    [dataset, schemas],
  );

  const {
    availableInputColumns,
    availableMockColumns,
    availableOutputColumns,
    availableOutcomeColumns,
  } = useAvailableColumns(dataset, schemas, integrationNodes);

  const rowIndexCell: (props: CellContext<DatasetRow, unknown>) => any =
    useCallback(
      ({ row, table }) => (
        <RowIndexCell
          context={context}
          datasetId={dataset.id}
          index={row.index}
          readonly={!!table.options.meta?.readonly}
          row={row.original}
          scrollToBottom={table.options.meta?.scrollToBottom}
          subflowMockColumns={subflowMockColumns}
          versionName={version?.name}
        />
      ),
      [context, dataset.id, version?.name, subflowMockColumns],
    );

  return useMemo(() => {
    const isAuthoringDatasetContext = context === "authoring";

    const columns = [
      columnHelper.group({
        id: INDEX_COLUMN_GROUP_ID,
        header: () => <div className="h-8 w-full bg-gray-50" />,
        columns: [
          columnHelper.display({
            id: INDEX_COLUMN_ID,
            header: () => (
              <div className="flex h-8 w-full items-center justify-center" />
            ),
            cell: rowIndexCell,
            minSize: 28,
            maxSize: 28,
            enableResizing: false,
          }),
        ],
      }),
    ];
    const inputColumns = keyBy(dataset.input_columns, "name");
    const outputColumns = keyBy(dataset.output_columns, "name");
    const outcomeColumns = keyBy(dataset.outcome_columns, "name");
    const inputProperties = keyBy(schemas.input?.properties ?? [], "fieldName");
    const outputProperties = keyBy(
      schemas.output?.properties ?? [],
      "fieldName",
    );
    const outcomeProperties = getOutcomeDatasetColumns(outcomeTypes ?? []);

    const duplicatedIntegrationNodeNames = integrationNodes
      .filter((node) =>
        integrationNodes.some((n) => node.id !== n.id && node.name === n.name),
      )
      .map((node) => node.name);

    const outputSchemaIsEmpty = !schemas.output?.properties.length;

    const isColumnInTheOutputSchema = (name: string) => {
      const properties = schemas.output?.properties ?? [];

      return properties.map((p) => p.fieldName).includes(name);
    };

    if (input.length > 0) {
      columns.push(
        columnHelper.group({
          id: "input_data",
          header: () => <GroupHeader variant="gray">Input</GroupHeader>,
          columns: input.map((name) => {
            const columnIssue = issues[name];
            const id = `input_data.${name}`;
            return columnHelper.accessor(`input_data.${name}`, {
              id,
              header: ({ table }) => (
                <Header
                  cellId={`-1_${id}`}
                  context={context}
                  input={availableInputColumns}
                  mock={availableMockColumns}
                  outcome={availableOutcomeColumns}
                  output={availableOutputColumns}
                  readonly={!!table.options.meta?.readonly}
                  type={inputColumns[name].desired_type}
                  typeChange={{
                    selectedType: inputColumns[name].desired_type,
                    onChangeType: (selectedType) =>
                      onColumnTypeChange(name, "input_columns", selectedType),
                    compatibleType:
                      columnIssue?.issueType === "type-mismatch"
                        ? columnIssue.schemaType
                        : undefined,
                  }}
                  warning={
                    version
                      ? getWarningTooltip(issues[name], flow, version)
                      : undefined
                  }
                  onAddAdditionalColumn={onAddAdditionalColumn}
                  onColumnAdd={onColumnAdd}
                  onDelete={() =>
                    onColumnsDelete([{ name, group: "input_columns" }])
                  }
                  onRename={(newName: string) =>
                    onColumnRename(
                      "input_columns",
                      name,
                      newName,
                      inputColumns[name].desired_type,
                      false,
                    )
                  }
                >
                  {name}
                </Header>
              ),
              size: getColumnSize(name),
              meta: {
                archetype: {
                  name,
                  desiredType: inputColumns[name].desired_type,
                  nullable: inputProperties[name]?.type[1] === "null",
                  required: inputProperties[name]?.required ?? false,
                  enum:
                    inputProperties[name]?.enum?.map(
                      (enumItem) => enumItem.value,
                    ) ?? null,
                  columnIssue: issues[name],
                },
              },
            });
          }),
        }),
      );
    }

    if (isAuthoringDatasetContext && dataset.mock_columns.length > 0) {
      const mockColumns = keyBy(dataset.mock_columns, "name");
      const childColumns = dataset.mock_columns.filter((childColumn) =>
        dataset.mock_columns.find(
          (parentColumn) =>
            parentColumn.use_subflow_mocks &&
            childColumn.name.startsWith(
              `${parentColumn.name}${SUB_COLUMN_SEPARATOR}`,
            ),
        ),
      );
      const topLevelColumns = dataset.mock_columns.filter(
        (col) => !childColumns.includes(col),
      );

      const buildAccessor = (
        name: string,
        desiredType: DesiredType,
        prefix?: string,
      ) => {
        const parentIntegrationNode = prefix
          ? integrationNodes.find((node) => node.name === prefix)
          : undefined;
        const matchingIntegrationNode = (() => {
          if (prefix) {
            const parentIntegrationNode = integrationNodes.find(
              (node) => node.name === prefix,
            );
            const [_, nonPrefixedName] = name.split(SUB_COLUMN_SEPARATOR);
            return parentIntegrationNode?.mockableChildNodes?.find(
              (node) => node.name === nonPrefixedName,
            );
          } else {
            return integrationNodes.find((node) => node.name === name);
          }
        })();

        const qualifiedName = name;
        const id = `mock_data.${qualifiedName}`;
        return columnHelper.accessor((row) => row.mock_data?.[qualifiedName], {
          id,
          header: ({ table }) => {
            const columnDisabled =
              !table.options.meta?.readonly &&
              isIntegrationNodeWithLiveConnection(matchingIntegrationNode);
            return (
              <Header
                cellId={`-1_${id}`}
                context={context}
                disabled={columnDisabled}
                fillMockColumn={
                  matchingIntegrationNode
                    ? {
                        integrationNode: matchingIntegrationNode,
                        onFill: (value) => {
                          onColumnFill(qualifiedName, "mock_columns", value);
                        },
                      }
                    : undefined
                }
                input={availableInputColumns}
                mock={availableMockColumns}
                outcome={availableOutcomeColumns}
                output={availableOutputColumns}
                readonly={!!table.options.meta?.readonly}
                type={desiredType}
                warning={
                  columnDisabled
                    ? MOCK_COLUMN_DISABLED_TOOLTIP
                    : duplicatedIntegrationNodeNames.includes(name)
                      ? {
                          title: `More than one Integration Node is called "${name}". Nodes with the same name share mock data.`,
                        }
                      : undefined
                }
                onAddAdditionalColumn={onAddAdditionalColumn}
                onAddSubMocks={onAddSubMocks}
                onColumnAdd={onColumnAdd}
                onDelete={() => {
                  if (prefix) {
                    onDeleteSubMocks(name, prefix);
                  } else {
                    onColumnsDelete([
                      {
                        name: qualifiedName,
                        group: "mock_columns",
                      },
                    ]);
                  }
                }}
                onRename={(newName: string) => {
                  onColumnRename(
                    "mock_columns",
                    name,
                    prefix
                      ? `${prefix}${SUB_COLUMN_SEPARATOR}${newName}`
                      : newName,
                    mockColumns[name].desired_type,
                    mockColumns[name].use_subflow_mocks,
                  );
                }}
              >
                {prefix ? name.split(SUB_COLUMN_SEPARATOR)[1] : name}
              </Header>
            );
          },
          size: getColumnSize(qualifiedName),
          meta: {
            archetype: {
              name: qualifiedName,
              desiredType,
              nullable: false,
              required: false,
              matchingIntegrationNode,
              matchingParentIntegrationNode: parentIntegrationNode,
              disabled: isIntegrationNodeWithLiveConnection(
                matchingIntegrationNode,
              ),
            },
          },
        });
      };

      columns.push(
        columnHelper.group({
          id: "mock_data",
          header: () => (
            <GroupHeader variant="indigo">Mock external data</GroupHeader>
          ),
          columns: topLevelColumns.map((topLevelColumn) => {
            const ownChildColumns = childColumns.filter(
              (childColumn) =>
                childColumn.name.startsWith(topLevelColumn.name) &&
                childColumn.desired_type === topLevelColumn.desired_type,
            );
            if (
              ownChildColumns.length > 0 &&
              topLevelColumn.use_subflow_mocks
            ) {
              const parentColumn = topLevelColumn;
              const id =
                ownChildColumns.length > 0
                  ? `mock_data.${parentColumn.name}`
                  : `placeholder_mock_data-${parentColumn.name}`;
              return columnHelper.group({
                id,
                header: ({ table }) => {
                  const matchingIntegrationNode = integrationNodes.find(
                    (node) => node.name === parentColumn.name,
                  );
                  return (
                    <SubGroupHeader
                      cellId={`-2_mock_data.${parentColumn.name}`}
                      desiredType={parentColumn.desired_type}
                      input={availableInputColumns}
                      integrationNode={matchingIntegrationNode ?? null}
                      mock={availableMockColumns}
                      outcome={availableOutcomeColumns}
                      output={availableOutputColumns}
                      readonly={!!table.options.meta?.readonly}
                      warning={
                        duplicatedIntegrationNodeNames.includes(
                          parentColumn.name,
                        )
                          ? {
                              title: `More than one Integration Node is called "${parentColumn.name}". Nodes with the same name share mock data.`,
                            }
                          : undefined
                      }
                      onAddAdditionalColumn={onAddAdditionalColumn}
                      onColumnAdd={onColumnAdd}
                      onDelete={() =>
                        onColumnsDelete([
                          {
                            name: parentColumn.name,
                            group: "mock_columns",
                          },
                        ])
                      }
                      onDeleteSubMocks={() =>
                        onDeleteSubMocks(null, parentColumn.name)
                      }
                      onRename={(newName: string) => {
                        onColumnRename(
                          "mock_columns",
                          parentColumn.name,
                          newName,
                          parentColumn.desired_type,
                          true,
                        );
                      }}
                    >
                      {parentColumn.name}
                    </SubGroupHeader>
                  );
                },
                meta: {
                  customId: id,
                },
                columns:
                  ownChildColumns.length > 0
                    ? ownChildColumns.map((childColumn) =>
                        buildAccessor(
                          childColumn.name,
                          childColumn.desired_type,
                          parentColumn.name,
                        ),
                      )
                    : [
                        buildAccessor(
                          parentColumn.name,
                          parentColumn.desired_type,
                        ),
                      ],
              });
            } else {
              return buildAccessor(
                topLevelColumn.name,
                topLevelColumn.desired_type,
              );
            }
          }),
        }),
      );
    }

    if (isAuthoringDatasetContext && dataset.output_columns.length > 0) {
      columns.push(
        columnHelper.group({
          id: "output_data",
          header: () => (
            <GroupHeader variant="green">Expected Output</GroupHeader>
          ),
          columns: dataset.output_columns.map(({ name }) => {
            const columnIssue = issues[name];
            const id = `output_data.${name}`;
            return columnHelper.accessor(`output_data.${name}`, {
              id,
              header: ({ table }) => (
                <Header
                  cellId={`-1_${id}`}
                  context={context}
                  input={availableInputColumns}
                  mock={availableMockColumns}
                  outcome={availableOutcomeColumns}
                  output={availableOutputColumns}
                  readonly={!!table.options.meta?.readonly}
                  type={outputColumns[name].desired_type}
                  typeChange={{
                    selectedType: outputColumns[name].desired_type,
                    onChangeType: (selectedType) =>
                      onColumnTypeChange(name, "output_columns", selectedType),
                    compatibleType:
                      columnIssue?.issueType === "type-mismatch"
                        ? columnIssue.schemaType
                        : undefined,
                  }}
                  warning={
                    !outputSchemaIsEmpty && !isColumnInTheOutputSchema(name)
                      ? {
                          title: `"${name}" is absent from your output schema. It won't be included in the flow's output and will fail to match the expected output`,
                        }
                      : undefined
                  }
                  onAddAdditionalColumn={onAddAdditionalColumn}
                  onColumnAdd={onColumnAdd}
                  onDelete={() =>
                    onColumnsDelete([{ name, group: "output_columns" }])
                  }
                  onRename={(newName: string) =>
                    onColumnRename(
                      "output_columns",
                      name,
                      newName,
                      outputColumns[name].desired_type,
                      false,
                    )
                  }
                >
                  {name}
                </Header>
              ),
              size: getColumnSize(name),
              meta: {
                archetype: {
                  name,
                  desiredType: outputColumns[name].desired_type,
                  nullable: true,
                  required: false,
                  enum:
                    outputProperties[name]?.enum?.map(
                      (enumItem) => enumItem.value,
                    ) ?? null,
                  columnIssue: issues[name],
                },
              },
            });
          }),
        }),
      );
    }

    if (auxiliary.length > 0) {
      columns.push(
        columnHelper.group({
          id: "auxiliary_data",
          header: () => (
            <GroupHeader variant="dark-gray">
              Additional fields
              <Tooltip
                body={
                  isAuthoringDatasetContext
                    ? 'When an input column name does not match the name of any field in the input schema it becomes an "Additional field". This data is not available inside the flow but is viewable inside the Inspect Data table for each node.'
                    : 'When an input column name does not match the name of any field in the input schema of the job decision flow version, it becomes an "Additional field". This data is not available inside the flow.'
                }
                footerAction={
                  isAuthoringDatasetContext
                    ? {
                        text: "Read more",
                        onClick: () =>
                          window.open(
                            "https://docs.taktile.com/decision-design/datasets-and-testing#h_0183dff5cf-1",
                            "_blank",
                          ),
                      }
                    : undefined
                }
                placement="bottom"
                asChild
              >
                <Icon
                  color="text-gray-500 hover:text-gray-700"
                  icon={faInfoCircle}
                  size="xs"
                />
              </Tooltip>
            </GroupHeader>
          ),
          columns: auxiliary.map((name) => {
            const id = `input_data.${name}`;

            return columnHelper.accessor(`input_data.${name}`, {
              id,
              header: ({ table }) => (
                <Header
                  cellId={`-1_${id}`}
                  context={context}
                  input={availableInputColumns}
                  mock={availableMockColumns}
                  outcome={availableOutcomeColumns}
                  output={availableOutputColumns}
                  readonly={!!table.options.meta?.readonly}
                  type={inputColumns[name].desired_type}
                  typeChange={{
                    selectedType: inputColumns[name].desired_type,
                    onChangeType: (selectedType) =>
                      onColumnTypeChange(name, "input_columns", selectedType),
                  }}
                  onAddAdditionalColumn={onAddAdditionalColumn}
                  onColumnAdd={onColumnAdd}
                  onDelete={() =>
                    onColumnsDelete([{ name, group: "input_columns" }])
                  }
                  onRename={(newName: string) =>
                    onColumnRename(
                      "input_columns",
                      name,
                      newName,
                      inputColumns[name].desired_type,
                      false,
                    )
                  }
                >
                  {name}
                </Header>
              ),
              size: getColumnSize(name),
              meta: {
                archetype: {
                  name,
                  desiredType: inputColumns[name].desired_type,
                  nullable: true,
                  required: false,
                  columnIssue: issues[name],
                },
              },
            });
          }),
        }),
      );
    }

    if (
      isAuthoringDatasetContext &&
      (dataset.outcome_columns ?? []).length > 0
    ) {
      const outcomesByTypeKey = groupBy(
        outcomeColumns,
        (outcome) => outcome.name.split(SUB_COLUMN_SEPARATOR)[0],
      );
      columns.push(
        columnHelper.group({
          id: "outcome_data",
          header: () => <GroupHeader variant="green">Outcomes</GroupHeader>,
          columns: Object.entries(outcomesByTypeKey).map(
            ([name, outcomeColumns]) => {
              const id = `outcome_data.${name}`;

              return columnHelper.group({
                id,
                header: ({ table }) => (
                  <SubGroupHeader
                    cellId={`-2_${id}`}
                    input={availableInputColumns}
                    integrationNode={null}
                    mock={availableMockColumns}
                    outcome={availableOutcomeColumns}
                    output={availableOutputColumns}
                    readonly={!!table.options.meta?.readonly}
                    warning={
                      // If outcome types has no this key
                      !outcomeProperties.some(
                        (column) => column.outcomeKey === name,
                      )
                        ? {
                            title:
                              "This outcome key is not defined in your Flow Outcome types",
                            body: "This outcome key is not defined in your Flow Outcome types. Add it to your Flow's Outcome types or delete if you do not use it.",
                          }
                        : undefined
                    }
                    onAddAdditionalColumn={onAddAdditionalColumn}
                    onColumnAdd={onColumnAdd}
                    onDelete={() => {
                      onColumnsDelete(
                        outcomeColumns.map((outcomeColumn) => ({
                          name: outcomeColumn.name,
                          group: "outcome_columns",
                        })),
                      );
                    }}
                    onDeleteSubMocks={noop}
                  >
                    {name}
                  </SubGroupHeader>
                ),
                meta: {
                  customId: id,
                },
                columns: outcomeColumns.map((outcomeColumn) => {
                  const [_outcomeKey, outcomeField] =
                    outcomeColumn.name.split(SUB_COLUMN_SEPARATOR);

                  const outcomeProperty = outcomeProperties.find(
                    (property) => property.columnName === outcomeColumn.name,
                  )?.property;

                  return columnHelper.accessor(
                    `outcome_data.${outcomeColumn.name}`,
                    {
                      id: `outcome_data.${outcomeColumn.name}`,
                      header: () => {
                        return (
                          <NoMenuHeader
                            type={outcomeColumn.desired_type}
                            warning={
                              !outcomeProperty
                                ? {
                                    title: `"${outcomeField}" is absent from your outcome types`,
                                  }
                                : undefined
                            }
                          >
                            {outcomeField}
                          </NoMenuHeader>
                        );
                      },
                      size: getColumnSize(outcomeField),
                      meta: {
                        archetype: {
                          name: outcomeField,
                          desiredType: outcomeColumn.desired_type,
                          nullable: false,
                          required: false,
                          disabled: false,
                          enum: outcomeProperty?.enum?.map(
                            (enumItem) => enumItem.value,
                          ),
                        },
                      },
                    },
                  );
                }),
              });
            },
          ),
        }),
      );
    }

    return {
      columns,
      availableInputColumns,
      availableMockColumns,
      availableOutputColumns,
    };
  }, [
    context,
    rowIndexCell,
    dataset.input_columns,
    dataset.output_columns,
    dataset.outcome_columns,
    dataset.mock_columns,
    schemas.input?.properties,
    schemas.output?.properties,
    outcomeTypes,
    integrationNodes,
    input,
    auxiliary,
    availableInputColumns,
    availableMockColumns,
    availableOutputColumns,
    issues,
    version,
    flow,
    onAddAdditionalColumn,
    onColumnAdd,
    onColumnTypeChange,
    onColumnsDelete,
    onColumnRename,
    onAddSubMocks,
    onColumnFill,
    onDeleteSubMocks,
    availableOutcomeColumns,
  ]);
};

type UseColumnAddHandlerArgs = {
  dataset?: Dataset;
  flowId: string;
  baseUrl?: string;
  schemas: VersionSchemas;
};
export const useColumnAddHandler = ({
  dataset,
  flowId,
  baseUrl,
  schemas,
}: UseColumnAddHandlerArgs) => {
  const putColumns = usePutColumns(dataset?.id ?? "", flowId, baseUrl);
  const { selectCell } = useDatasetEditTableActions();
  const { data: outcomeTypes } = useOutcomeTypes({
    flowId,
  });

  const columnAddHandler = useCallback(
    async (
      name: string,
      group: ExtendedDatasetColumnGroups,
      desiredType?: DesiredType,
    ) => {
      let putColumnPayload: PutColumnPayload | PutColumnPayload[];
      if (group === "input_columns" || group === "output_columns") {
        const schemaKey = group === "input_columns" ? "input" : "output";
        const schemaProperty = schemas[schemaKey]?.properties.find(
          (property) => property.fieldName === name,
        );
        if (!schemaProperty) {
          logger.error("could not find column to add in the schema");
          return;
        }
        desiredType =
          desiredType ?? schemaTypeToDesiredType(schemaProperty.type[0]);
        putColumnPayload = { name, group, desiredType, hasSubflowMocks: false };
      } else if (group === "mock_columns") {
        putColumnPayload = {
          name,
          group,
          desiredType: desiredType ?? "object",
          hasSubflowMocks: false,
        };
      } else if (group === "additional_columns") {
        if (!desiredType) {
          logger.error("desiredType must be provided for additional columns");
          return;
        }
        // additional column is basically input column which is not in the schema
        putColumnPayload = {
          name,
          group: "input_columns",
          desiredType,
          hasSubflowMocks: false,
        };
      } else if (group === "outcome_columns") {
        if (!dataset) {
          logger.error("dataset not found");
          return;
        }

        const outcomeColumns = getOutcomeDatasetColumns(outcomeTypes ?? []);

        const columnsToAdd = outcomeColumns.filter(
          (col) =>
            col.outcomeKey === name &&
            !dataset?.outcome_columns.some((c) => c.name === col.columnName),
        );

        putColumnPayload = columnsToAdd.map((column) => {
          const desiredType = column.property.type[0] ?? "string";

          return {
            name: column.columnName,
            group,
            desiredType: desiredType as DesiredType,
            hasSubflowMocks: false,
          };
        });
      } else {
        throw Error("Invalid column group type provided");
      }
      try {
        await putColumns.mutateAsync(
          Array.isArray(putColumnPayload)
            ? putColumnPayload
            : [putColumnPayload],
        );

        const columnGroup =
          group === "additional_columns" ? "input_columns" : group;
        const prefix = DatasetColumnGroupToRowDataGroupMap[columnGroup];
        const cellIdToJumpTo = stringifySelection(-1, `${prefix}.${name}`);
        selectCell(cellIdToJumpTo);
      } catch (e) {
        toastActions.failure({
          title: `Failed to add column "${name}"`,
          description: TAKTILE_TEAM_NOTIFIED,
        });
        logger.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      putColumns.mutateAsync,
      schemas.input,
      outcomeTypes,
      dataset?.outcome_columns,
    ],
  );

  const subMocksColumnAddHandler = useCallback(
    async (
      subMockNames: string[],
      mockColumnName: string,
      desiredType: Extract<DesiredType, "object" | "any">,
    ) => {
      let putColumnPayload: PutColumnPayload = {
        name: mockColumnName,
        group: "mock_columns",
        desiredType: desiredType,
        hasSubflowMocks: subMockNames.length > 0,
      };

      const subMockColumns: PutColumnPayload[] = subMockNames.map((name) => ({
        name: `${mockColumnName}${SUB_COLUMN_SEPARATOR}${name}`,
        group: "mock_columns",
        desiredType,
        hasSubflowMocks: false,
      }));

      try {
        await putColumns.mutateAsync([putColumnPayload, ...subMockColumns]);
        const prefix = DatasetColumnGroupToRowDataGroupMap.mock_columns;
        const cellIdToJumpTo = stringifySelection(
          -1,
          `${prefix}${SUB_COLUMN_SEPARATOR}${mockColumnName}`,
        );
        selectCell(cellIdToJumpTo);
      } catch (e) {
        toastActions.failure({
          title: `Failed to add column "${mockColumnName}"`,
          description: TAKTILE_TEAM_NOTIFIED,
        });
        logger.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [putColumns.mutateAsync, schemas.input],
  );

  return {
    columnAddHandler,
    subMocksColumnAddHandler,
    isLoading: putColumns.isLoading,
  };
};

export const useAvailableColumns = (
  dataset: Dataset,
  schemas: VersionSchemas,
  integrationNodes: DatasetIntegrationNode[],
) => {
  const { flow } = useFlowContext();
  const { data: outcomeTypes } = useOutcomeTypes({
    flowId: flow.id,
  });
  const { inputAvailable, outputAvailable, mockAvailable, outcomeAvailable } =
    useMemo(
      () =>
        getAvailableColumns(
          dataset,
          schemas,
          integrationNodes,
          outcomeTypes ?? [],
        ),
      [dataset, integrationNodes, schemas, outcomeTypes],
    );

  const mockAvailableWithProvider: MockColumn[] = useMemo(() => {
    const integrationNodesByName = keyBy(integrationNodes, "name");
    const findMockableChildNode = (parentName: string, childName: string) => {
      return integrationNodesByName[parentName].mockableChildNodes?.find(
        (childNode) => childNode.name === childName,
      );
    };
    // Make this unique so a node name doesn't
    // appear more than once
    return mockAvailable.map((mockColumn) => {
      return {
        name: mockColumn.name,
        provider: integrationNodesByName[mockColumn.name]?.provider,
        mediaKey: integrationNodesByName[mockColumn.name]?.mediaKey,
        subMocks:
          mockColumn.subMocks?.map((subMockName) => ({
            name: subMockName,
            provider: findMockableChildNode(mockColumn.name, subMockName)
              ?.provider!,
            mediaKey: findMockableChildNode(mockColumn.name, subMockName)
              ?.mediaKey!,
          })) ?? null,
      };
    });
  }, [integrationNodes, mockAvailable]);

  return useMemo(() => {
    const availableInputColumns = {
      inputFields: inputAvailable,
      schemaIsEmpty: schemas.input?.properties.length === 0,
    };
    const availableMockColumns = {
      mockFields: mockAvailableWithProvider,
      integrationNodesExist: integrationNodes.length !== 0,
    };
    const availableOutputColumns = {
      outputFields: outputAvailable,
      schemaIsEmpty: schemas.output?.properties.length === 0,
    };
    const availableOutcomeColumns = {
      outcomeFields: outcomeAvailable,
      thereAreNoOutcomes: outcomeTypes?.length === 0,
    };

    return {
      availableInputColumns,
      availableMockColumns,
      availableOutputColumns,
      availableOutcomeColumns,
    };
  }, [
    inputAvailable,
    schemas.input?.properties.length,
    schemas.output?.properties.length,
    mockAvailableWithProvider,
    integrationNodes.length,
    outputAvailable,
    outcomeAvailable,
    outcomeTypes?.length,
  ]);
};
