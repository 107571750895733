import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";

import { FormItem } from "src/base-components/FormItem";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { errorProps } from "src/connections/config/shared/errorProps";
import { Tooltip } from "src/design-system/Tooltip";

type PropsT<T extends FieldValues> = {
  dataLoc?: string;
  secretName: string;
  helpText?: string;
  isRequired?: boolean;
  isErrored: boolean;
  isSecret: boolean;
  secretKey: Path<T>;
  register: UseFormRegister<T>;
  enableSecret: () => void;
  description?: string;
  placeholder?: string;
};

export const SecretField = <T extends FieldValues>({
  dataLoc,
  secretKey,
  secretName,
  helpText,
  isRequired,
  isErrored,
  isSecret,
  description,
  register,
  enableSecret,
  placeholder,
}: PropsT<T>) => {
  return (
    <FormItem
      description={description}
      gap="xs"
      helpClassName="mt-1"
      helpTooltip={helpText}
      isRequired={isRequired}
      label={secretName}
    >
      <div className="flex w-full items-center gap-x-2">
        <div className="flex-1">
          <Input
            data-loc={dataLoc}
            disabled={isSecret}
            {...errorProps(isErrored)}
            {...register(secretKey, {
              required: isRequired ? `${secretName} is required` : false,
            })}
            placeholder={placeholder || secretName}
            type="password"
            fullWidth
          />
        </div>
        {isSecret && (
          <Tooltip
            placement="right"
            title={`Edit ${secretName.toLowerCase()}`}
            asChild
          >
            <Icon
              color="text-gray-500"
              icon={faEdit}
              size="xs"
              onClick={enableSecret}
            />
          </Tooltip>
        )}
      </div>
    </FormItem>
  );
};
