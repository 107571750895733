export const EQUALITY_OPERATORS = {
  lt: { description: "Less than", code: "<" },
  le: { description: "Less than or equal to", code: "<=" },
  eq: { description: "Equal to", code: "==" },
  ne: { description: "Not equal to", code: "!=" },
  gt: { description: "Greater than", code: ">" },
  ge: { description: "Greater than or equal to", code: ">=" },
} as const;

export const OPERATORS = {
  ...EQUALITY_OPERATORS,
  is_in: { description: "Is in", code: "is in" },
  not_is_in: { description: "Is not in", code: "is not in" },
  is_none: { description: "Is None", code: "is None" },
  is_not_none: { description: "Is not None", code: "is not None" },
  contains: { description: "Contains", code: "contains" },
  does_not_contain: {
    description: "Does not contain",
    code: "does not contain",
  },
  matches_regex: { description: "Matches regex", code: "matches regex" },
} as const;

export const OPERATORS_WITH_IS_ANY = {
  ...OPERATORS,
  is_any: { description: "Is any", code: "is any" },
} as const;
