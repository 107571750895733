import { faLink } from "@fortawesome/pro-regular-svg-icons";

import { FlowT } from "src/api/flowTypes";
import { Pill } from "src/base-components/Pill";
import { pluralize } from "src/utils/stringUtils";

type PropsT = {
  parentFlows: FlowT["parent_flows"];
  childFlows: FlowT["child_flows"];
};

export const LinkedFlowsPill: React.FC<PropsT> = ({
  parentFlows,
  childFlows,
}) => {
  const getAdditionalLinksExtension = (linkedFlowsCount: number) => {
    if (linkedFlowsCount < 2) {
      return "";
    }
    return ` + ${pluralize(linkedFlowsCount - 1, "other Decision Flow")}`;
  };

  const pillText =
    parentFlows && parentFlows.length > 0
      ? `Called by ${parentFlows[0].name}${getAdditionalLinksExtension(parentFlows.length)}`
      : childFlows && childFlows.length > 0
        ? `Calling ${childFlows[0].name}${getAdditionalLinksExtension(childFlows.length)}`
        : undefined;

  return (
    pillText && (
      <Pill size="sm" variant="outlined-white">
        <div className="flex flex-row items-center pl-0.5">
          <Pill.Icon icon={faLink} padding={false} />
          <Pill.Text>{pillText}</Pill.Text>
        </div>
      </Pill>
    )
  );
};
