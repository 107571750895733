import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { FlowVersionT } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { buildUiSchemas } from "src/datasets/utils";
import { Modal } from "src/design-system/Modal";
import { FlowVersionList } from "src/nodeEditor/SelectFlowPane/FlowVersionList";
import { FlowNodeForm } from "src/parentFlowNodes/flowNode/types";
import { setupFlowNodeForm } from "src/parentFlowNodes/utils";

type Props = {
  flowId: string;
  versions: FlowVersionT[];
  selectedVersionId: string;
  open: boolean;
  onClose: () => void;
};

export const ChangeVersionModal: React.FC<Props> = ({
  flowId,
  versions,
  selectedVersionId: initialSelectedVersioId,
  open,
  onClose,
}) => {
  const { getValues, reset } = useFormContext<FlowNodeForm>();
  const [selectedVersionId, setSelectedVersionId] = React.useState<string>(
    initialSelectedVersioId,
  );

  useEffect(() => {
    setSelectedVersionId(initialSelectedVersioId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSelectedVersioId]);

  const handleSave = () => {
    onClose();
    const selectedVersion = versions.find(
      (version) => version.id === selectedVersionId,
    );
    if (selectedVersion) {
      const selectedSchemas = buildUiSchemas(selectedVersion);
      const [updatedFormValues] = setupFlowNodeForm(
        getValues(),
        selectedSchemas,
      );
      reset({
        ...updatedFormValues,
        child_flow_version_id: selectedVersion.id,
        child_flow_version_known_etag: selectedVersion.etag,
      });
    }
  };

  return (
    <Modal open={open} handleEditorLocks onClose={onClose}>
      <Modal.Header description="Select the version you want to call from this Decision Flow. Keep in mind that Input and Output fields may require remapping.">
        Change version
      </Modal.Header>
      <Modal.Content noScroll>
        <div className="mb-2 h-[489px]">
          <FlowVersionList
            flowId={flowId}
            flowVersions={versions}
            selectedVersionId={selectedVersionId}
            onSelect={(version) => setSelectedVersionId(version.id)}
          />
        </div>
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button dataLoc="confirm-child-version-button" onClick={handleSave}>
            Call version
          </Button>
        }
      />
    </Modal>
  );
};
