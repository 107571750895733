import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import { faFilter as faFilterSolid } from "@fortawesome/pro-solid-svg-icons";
import { endOfDay, startOfDay } from "date-fns";
import { isString } from "lodash";
import { useState } from "react";
import { SelectRangeEventHandler } from "react-day-picker";

import { SingleOutcomeFilter } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { Button } from "src/base-components/Button";
import {
  DateRange,
  DateRangePicker,
} from "src/base-components/DateRangePicker";
import { Icon } from "src/base-components/Icon";

export const DateRangeFilter: React.FC<{
  property: string;
  filters: SingleOutcomeFilter[];
  onChange: (filters: SingleOutcomeFilter["filter"][]) => void;
  onReset: () => void;
  isFiltering: boolean;
  dataLoc: string;
}> = ({ property, filters, onChange, onReset, isFiltering, dataLoc }) => {
  const from = filters.find((filter) => filter.filter.operator === ">=")?.filter
    .value;
  const to = filters.find((filter) => filter.filter.operator === "<=")?.filter
    .value;

  const [localValue, setLocalValue] = useState<DateRange | undefined>({
    from: from && isString(from) ? new Date(from) : undefined,
    to: to && isString(to) ? new Date(to) : undefined,
  });

  const handleChange: SelectRangeEventHandler = (value) => {
    setLocalValue({
      from: value?.from ? value.from : undefined,
      to: value?.to ? endOfDay(value.to) : undefined,
    });
  };

  const handleApply = () => {
    if (localValue?.from || localValue?.to) {
      const from = startOfDay((localValue.from ?? localValue.to) as Date);
      const to = endOfDay((localValue.to ?? localValue.from) as Date);

      onChange([
        {
          property,
          operator: ">=",
          value: from.toISOString(),
        },
        {
          property,
          operator: "<=",
          value: to.toISOString(),
        },
      ]);
    }
  };

  const isResetDisabled = from === undefined && to === undefined;
  const isApplyDisabled =
    from === localValue?.from?.toISOString() &&
    to === localValue?.to?.toISOString();

  return (
    <span>
      <DateRangePicker
        buttonRenderer={({ open }) => (
          <Icon
            color={
              open || isFiltering
                ? "text-indigo-500"
                : "text-gray-500 hover:text-gray-700"
            }
            cursorType="pointer"
            dataLoc={dataLoc}
            icon={open || isFiltering ? faFilterSolid : faFilter}
            size="2xs"
          />
        )}
        footerRenderer={() => (
          <div className="flex justify-between border-t border-gray-200 p-4">
            <Button
              dataLoc={`${dataLoc}-reset-button`}
              disabled={isResetDisabled}
              size="sm"
              variant="secondary"
              onClick={onReset}
            >
              Reset
            </Button>
            <Button
              dataLoc={`${dataLoc}-apply-button`}
              disabled={isApplyDisabled}
              size="sm"
              variant="primary"
              onClick={handleApply}
            >
              Apply Filter
            </Button>
          </div>
        )}
        value={localValue}
        resetable
        onChange={handleChange}
        onReset={onReset}
      />
    </span>
  );
};
