import React from "react";
import { twJoin } from "tailwind-merge";

import { AwsRegionTypeT } from "src/constants/AwsRegions";
import { codeToFlag } from "src/layout/WorkspaceFormModal/utils";

export type RegionDisplayPropsT = {
  awsRegion: AwsRegionTypeT;
  padded?: boolean;
};

export const RegionDisplay: React.FC<RegionDisplayPropsT> = ({
  awsRegion,
  padded = false,
}) => {
  const FlagIcon = codeToFlag[awsRegion.flag];
  return (
    <div
      className={twJoin(
        "flex w-full cursor-pointer flex-row items-center gap-x-2 font-medium font-inter-medium-13px",
        padded && "px-3 py-1",
      )}
      data-loc={awsRegion.identifier}
    >
      <FlagIcon className="h-4 w-4" />
      <span className="text-gray-800">{awsRegion.city}</span>
      <span className="text-gray-600">{awsRegion.identifier}</span>
    </div>
  );
};
