import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  InputMappingGroupT,
  InputMappingT,
  IntegrationResourceT,
} from "src/integrationNode/types";

const getDefaultBKRCKIAddress = (): InputMappingGroupT => ({
  getDefaultElements: () => ({
    street: {
      id: uuidV4(),
      type: "text",
      displayName: "Street name",
      hint: "The street name of the borrower's last known address. Dutch: Straat.",
      assignedTo: "",
      rules: {
        maxLength: 24,
      },
    },
    house_number: {
      id: uuidV4(),
      type: "text",
      displayName: "House number",
      assignedTo: "",
      hint: "An integer value for the numerical part of the house number. Dutch: Huisnummer.",
    },
    suffix: {
      id: uuidV4(),
      type: "text",
      displayName: "House number suffix",
      assignedTo: "",
      hint: "A string value for the alphabetical suffix of the house number. E.g. 'B' or 'IV'.",
    },
    city: {
      id: uuidV4(),
      type: "text",
      displayName: "City",
      hint: "Spelling in accordance with PostNL. Dutch: Plaats.",
      assignedTo: "",
      rules: {
        maxLength: 24,
      },
    },
    postal_code: {
      id: uuidV4(),
      type: "text",
      displayName: "Postal code",
      assignedTo: "",
      hint: "6 character (4 digits + 2 letters) postal code, optionally separated by a space, e.g. '1234AB' or '1234 AB'",
      rules: {
        minLength: 6,
        maxLength: 7,
      },
    },
    country: {
      id: uuidV4(),
      type: "text",
      displayName: "Country",
      assignedTo: "",
      hint: "The BKR country code. Please refer to BKR's landcodetabel for all available country codes. E.g. 'NL' for the Netherlands.",
      rules: {
        maxLength: 3,
      },
    },
  }),
  displayName: "Address",
});

const getDefaultBKRScorecardNumber = (): Record<string, InputMappingT> => ({
  number: {
    id: uuidV4(),
    type: "text",
    displayName: "Scorecard number",
    assignedTo: "",
    hint: "An string value corresponding to the desired scorecard code (e.g., '22')",
    rules: {
      required: true,
    },
  },
});

export const getDefaultBKRCKI = (): IntegrationResourceT => ({
  providerResource: {
    provider: "bkr",
    resource: "bkr_cki",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      initials: {
        id: uuidV4(),
        type: "text",
        displayName: "Initials",
        hint: "Dutch: Voorletters",
        assignedTo: "",
        rules: {
          maxLength: 5,
        },
      },
      first_name: {
        id: uuidV4(),
        type: "text",
        displayName: "First name",
        hint: "For a first name longer than 12 characters, use only the first 12 characters. Dutch: Voornaam.",
        assignedTo: "",
        rules: {
          maxLength: 12,
        },
      },
      prefix: {
        id: uuidV4(),
        type: "text",
        displayName: "Prefix",
        hint: "Dutch: Tussenvoegsel",
        assignedTo: "",
        rules: {
          maxLength: 8,
        },
      },
      last_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Last name",
        hint: "The individual's official birth name, as known in the population register. This field isn't strictly required, but it's highly recommended to include it. More information can be found in the general BKR manual. Dutch: Geboortenaam.",
        assignedTo: "",
        rules: {
          maxLength: 24,
        },
      },
      date_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Date of birth",
        hint: `The date of birth in format "YYYY-MM-DD". Dutch: Geboortedatum.`,
        assignedTo: "",
      },
      gender: {
        id: uuidV4(),
        type: "text",
        displayName: "Gender",
        hint: `One of: 'M' or 'V'. Dutch: Geslacht.`,
        assignedTo: "",
      },
    },
    grouped: {
      address: getDefaultBKRCKIAddress(),
    },
    lists: {
      scorecard_numbers: {
        displayName: "Score",
        getDefaultElement: getDefaultBKRScorecardNumber,
        elements: [],
      },
    },
  },
  output: {
    default: getDefaultOutputMapping(),
    // TODO INT-2837
    insights: {
      ...getCommonOutputMappings(CommonOutputMappingKey.RETRIEVAL_DATETIME),
      scorecard: {
        ...getNamedOutputMapping("Scorecard"),
        hint: "An object containing the full scorecard (ScoreKaarten) response",
      },
      search_result_code: {
        ...getNamedOutputMapping("Search result code"),
        hint: "A 2 character code that indicates the result of the search. See BKR's CKI Toetsing documentation for more details.",
      },
      number_of_contract_matches: {
        ...getNamedOutputMapping("Number of contract matches"),
        hint: "The number of contract matches found for the consumer",
      },
    },
  },
  config: defaultResourceConfig,
});
