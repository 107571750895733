import { useCallback, useState } from "react";

import {
  EqualityOperator,
  SingleOutcomeFilter,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { SchemaTypesT } from "src/api/flowTypes";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { ErrorHint } from "src/base-components/ErrorHint";
import { OperatorDropdown } from "src/base-components/OperatorDropdown";
import { EQUALITY_OPERATORS } from "src/constants/operators";
import { InputColumnFilter } from "src/flow/decisionHistory/InputColumnFilter";

const CUSTOM_STRING_OPERATORS = {
  eq: { description: "Equal to", code: "is" },
  ne: { description: "Not equal to", code: "is not" },
} as const;

const STRING_CODE_TO_OPERATOR_CODE = {
  is: "==",
  "is not": "!=",
} as const;

export const OutcomeFieldFilter: React.FC<{
  dataLoc?: string;
  type: SchemaTypesT;
  value?: string | number | boolean;
  operator?: EqualityOperator;
  filter: SingleOutcomeFilter | null;
  onReset: () => void;
  onChange: (
    operator: EqualityOperator,
    value?: string | number | boolean,
  ) => void;
}> = ({
  dataLoc,
  type,
  value,
  operator: _operator,
  filter,
  onReset,
  onChange,
}) => {
  const OPERATORS =
    type === "string" ? CUSTOM_STRING_OPERATORS : EQUALITY_OPERATORS;
  const [inputValue, setInputValue] = useState(value ? String(value) : "");
  const [operator, setOperator] = useState<keyof typeof OPERATORS>(
    (Object.entries(OPERATORS).find(
      ([_, operator]) => operator.code === _operator,
    )?.[0] ?? "eq") as keyof typeof OPERATORS,
  );

  const isNumberFilter = type === "number" || type === "integer";
  const isError =
    isNumberFilter && inputValue.length > 0 && isNaN(parseFloat(inputValue));

  const handleChange = useCallback(
    (operator: keyof typeof OPERATORS, value: string) => {
      const newOperatorCode = OPERATORS[operator].code;
      const newOperator =
        STRING_CODE_TO_OPERATOR_CODE[
          newOperatorCode as keyof typeof STRING_CODE_TO_OPERATOR_CODE
        ] ?? newOperatorCode;

      onChange(
        newOperator,
        value.length ? (isNumberFilter ? parseFloat(value) : value) : undefined,
      );
    },
    [OPERATORS, onChange, isNumberFilter],
  );

  return (
    <span>
      <InputColumnFilter
        dataLoc={dataLoc}
        description="Filter condition"
        isFiltering={Boolean(filter)}
        operator={operator}
        value={inputValue}
        onApply={() => handleChange(operator, inputValue)}
        onResetRequest={onReset}
      >
        <AutocompleteCodeInput
          dataLoc={`${dataLoc}-input`}
          error={isError}
          value={inputValue}
          onChange={(value) => setInputValue(value)}
        >
          <OperatorDropdown
            dataLoc={`${dataLoc}-operator`}
            options={Object.entries(OPERATORS).map(([key, value]) => ({
              key,
              value,
            }))}
            value={operator}
            onChange={(value) => {
              setOperator(value as keyof typeof OPERATORS);
            }}
          />
        </AutocompleteCodeInput>
        {isError && <ErrorHint>Invalid number: must be a number</ErrorHint>}
      </InputColumnFilter>
    </span>
  );
};
