import { faCircleNotch, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/design-system/Tooltip";

export const AddChartButton: React.FC<{
  size?: "sm";
  disabled: boolean;
  loading: boolean;
  onClick: () => void;
}> = ({ size, disabled: buttonDisabled, onClick, loading }) => (
  <Tooltip
    body={
      loading
        ? "Please wait for the chart data to load. This might take a few minutes."
        : "Initiate a test run to enable charting features and to derive insights from your data."
    }
    disabled={!buttonDisabled && !loading}
    placement="top"
    asChild
  >
    <div>
      <Button
        dataLoc="analytics-add-chart-button"
        disabled={!loading && buttonDisabled}
        iconLeft={!loading ? faPlus : undefined}
        size={size}
        variant="secondary"
        onClick={!loading ? onClick : undefined}
      >
        <span className="flex items-center gap-2">
          {loading && <Icon icon={faCircleNotch} size="2xs" spin />}
          {loading ? "Loading chart data..." : "Add chart"}
        </span>
      </Button>
    </div>
  </Tooltip>
);
