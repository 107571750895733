import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFormContext,
} from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { ReorderHandle } from "src/base-components/ReorderHandle";
import { SchemaEnumInput } from "src/schema/SchemaEnumInput";
import { EnumValueObject } from "src/schema/utils";

export type SchemaEnumErrorStatus = Merge<
  FieldError,
  (FieldErrorsImpl<EnumValueObject> | undefined)[]
>;

type PropsT = {
  onDelete: () => void;
  fieldIndex: number;
  readonly?: boolean;
  id: string;
  deletable: boolean;
  enumFormLocation: string;
  errorStatus?: SchemaEnumErrorStatus;
  withValidation: boolean;
};

export const isValidEnumOptionInput = (input: string): boolean => {
  // Check if input is a valid number
  if (!isNaN(Number(input))) {
    return true;
  }

  // Check for both double-quoted and single-quoted strings
  return isValidQuotedString(input);
};

const isValidQuotedString = (input: string): boolean => {
  if (input.startsWith('"') && input.endsWith('"')) {
    return (input.match(/"/g) || []).length === 2;
  } else if (input.startsWith("'") && input.endsWith("'")) {
    return (input.match(/'/g) || []).length === 2;
  }
  return false;
};

export const SchemaEnumRow: React.FC<PropsT> = ({
  readonly = false,
  onDelete,
  fieldIndex,
  id,
  deletable,
  errorStatus,
  enumFormLocation,
  withValidation,
}) => {
  const { register, getValues } = useFormContext();
  const { listeners, setNodeRef, transform, transition } = useSortable({
    id: id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const checkEnumOptionUnique = (name: string) => {
    const otherValues = getValues(enumFormLocation);
    if (!otherValues) {
      return false;
    }
    return otherValues.filter((p: string) => p === name).length < 2;
  };

  const isDeleteDisabled = readonly || !deletable;

  return (
    <li
      ref={setNodeRef}
      className="ml-3 flex flex-row items-center gap-x-2"
      data-loc={`schema-enum-row-${fieldIndex}`}
      style={style}
    >
      <ReorderHandle
        color="text-gray-500"
        immutable={readonly}
        listeners={listeners}
      />
      <SchemaEnumInput
        disabled={readonly}
        errorStatus={errorStatus?.at?.(fieldIndex)?.value}
        formProps={register(`${enumFormLocation}.${fieldIndex}.value`, {
          required: withValidation,
          ...(withValidation
            ? {
                validate: {
                  unique: checkEnumOptionUnique,
                  validValue: isValidEnumOptionInput,
                },
              }
            : {}),
        })}
      />
      <Icon
        color={twJoin(
          isDeleteDisabled ? "text-gray-300" : "text-gray-500",
          !isDeleteDisabled && "hover:text-red-600",
        )}
        dataLoc="enum-field-delete"
        disabled={isDeleteDisabled}
        icon={faTrashAlt}
        size="xs"
        onClick={onDelete}
      />
    </li>
  );
};
