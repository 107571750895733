import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import { faFilter as faFilterSolid } from "@fortawesome/pro-solid-svg-icons";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui2/react";
import { useState } from "react";
import { twJoin } from "tailwind-merge";

import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { OPERATORS } from "src/constants/operators";

type PropsT = {
  dataLoc?: string;
  isOpen?: boolean;
  description: string;
  children: React.ReactNode;
  isFiltering: boolean;
  onResetRequest: () => void;
  onApply: () => void;
  value: string;
  operator: keyof typeof OPERATORS;
};

export const InputColumnFilter = ({
  dataLoc,
  description,
  children,
  isFiltering,
  onResetRequest,
  onApply,
  value,
  operator,
}: PropsT) => {
  const [lastAppliedValue, setLastAppliedValue] = useState<string>(value);
  const [lastAppliedOperator, setLastAppliedOperator] =
    useState<string>(operator);

  const handleApply = () => {
    setLastAppliedValue(value);
    setLastAppliedOperator(operator);
    onApply();
  };

  const isApplyDisabled =
    value === lastAppliedValue && operator === lastAppliedOperator;
  const isResetDisabled = !isFiltering || value === "";

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <PopoverButton className="border-0 bg-transparent" data-loc={dataLoc}>
            <Icon
              color={
                open || isFiltering
                  ? "text-indigo-500"
                  : "text-gray-500 hover:text-gray-700"
              }
              icon={open || isFiltering ? faFilterSolid : faFilter}
              size="2xs"
            />
          </PopoverButton>

          {open && (
            <PopoverPanel
              anchor="bottom start"
              className={twJoin(
                "absolute w-[240px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5",
              )}
              data-loc={`${dataLoc}-dropdown`}
              static
            >
              <div className="divide-y divide-gray-200">
                <div className="flex w-[240px] flex-col justify-between p-4">
                  <h3 className="cursor-default items-center font-inter-semibold-13px">
                    {description}
                  </h3>
                  <div className="pt-2">{children}</div>
                </div>

                <div className="flex justify-between p-4">
                  <Button
                    disabled={isResetDisabled}
                    size="sm"
                    variant="secondary"
                    onClick={onResetRequest}
                  >
                    Reset
                  </Button>
                  <Button
                    dataLoc={`${dataLoc}-apply-button`}
                    disabled={isApplyDisabled}
                    size="sm"
                    variant="primary"
                    onClick={handleApply}
                  >
                    Apply Filter
                  </Button>
                </div>
              </div>
            </PopoverPanel>
          )}
        </>
      )}
    </Popover>
  );
};
