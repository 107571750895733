import {
  faChevronRight,
  faCog,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { isMacOs } from "react-device-detect";
import { Link, useLocation } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { useConnections } from "src/api/connectApi/queries";
import { useFlow, useWorkspace } from "src/api/queries";
import RetoolIcon from "src/assets/RetoolMenuIcon.svg?react";
import TaktileLogo from "src/assets/Taktile.svg?react";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/design-system/Tooltip";
import { useCapabilities } from "src/hooks/useCapabilities";
import { UserMenu } from "src/layout/UserMenuV2";
import { useOmniboxActions } from "src/omnibox/OmniboxStore";
import {
  DashboardPageParamsT,
  FlowPageParamsT,
  getUrlToRetoolPage,
  getUrlToSettingsPage,
  getUrlToWsDashboard,
} from "src/router/urls";
import { canAccessSettingsPage } from "src/settings/utils";
import { useParamsDecode } from "src/utils/useParamsDecode";

type BreadcrumbProps =
  | {
      label: string;
      href: string;
      dataLoc?: string;
      children?: never;
    }
  | {
      label?: never;
      href?: never;
      dataLoc?: never;
      children: React.ReactNode;
    };

type BreadcrumbType = React.FC<BreadcrumbProps>;

type Props = {
  breadcrumbs?: { key: string; breadcrumb: ReturnType<BreadcrumbType> }[];
  children?: React.ReactNode;
  border?: boolean;
};

type HeaderType = React.FC<Props> & {
  Breadcrumb: BreadcrumbType;
};

export const LayoutHeader: HeaderType = ({
  border = false,
  breadcrumbs,
  children,
}) => {
  const { wsId, orgId } = useParamsDecode<DashboardPageParamsT>();
  // We might not be on a flow page, so use a partial decode to get the flow_id
  const { flow_id } = useParamsDecode<Partial<FlowPageParamsT>>();

  const settingsPageUrl = getUrlToSettingsPage(orgId, wsId);
  const workspace = useWorkspace(wsId);
  const flow = useFlow(flow_id);
  const connections = useConnections(workspace.data?.base_url);
  const retoolConnection = connections.data?.find(
    (c) => c.provider === "retool",
  );
  const retoolPageUrl = getUrlToRetoolPage(orgId, wsId, retoolConnection?.id);
  const capabilities = useCapabilities();
  const location = useLocation();
  const isCurrentPageRetool =
    `${location.pathname}${location.search}` === `${retoolPageUrl}`;
  const { showOmnibox } = useOmniboxActions();

  return (
    <header
      className={twJoin(
        "box-border flex w-full items-center justify-between bg-white py-2 pl-4 pr-3",
        border ? "min-h-[53px] border-b border-b-gray-200" : "min-h-[52px]",
      )}
      data-loc="layout-header"
    >
      <div className="flex items-center gap-x-3">
        <Link
          to={getUrlToWsDashboard({
            orgId,
            wsId,
            folderId: flow.data?.flow_folder_id,
          })}
        >
          <TaktileLogo data-loc="header-taktile-logo" />
        </Link>
        {breadcrumbs && breadcrumbs.length > 0 && (
          <ul className="flex items-center gap-x-3" data-loc="breadcrumbs">
            {breadcrumbs.map(({ key, breadcrumb }) => (
              <React.Fragment key={key}>{breadcrumb}</React.Fragment>
            ))}
          </ul>
        )}
      </div>
      <div>
        {Boolean(children) ? (
          children
        ) : (
          <>
            <div className="flex items-center gap-x-4">
              {retoolPageUrl && (
                <Tooltip
                  placement="bottom-end"
                  title={retoolConnection?.name}
                  asChild
                >
                  <Link
                    className={
                      isCurrentPageRetool ? "text-indigo-500" : "text-gray-700"
                    }
                    to={retoolPageUrl}
                  >
                    <RetoolIcon />
                  </Link>
                </Tooltip>
              )}
              <Tooltip
                placement="bottom-end"
                title={
                  <>
                    <span className="mr-2">Search</span>
                    <Pill size="sm" variant="dark-gray">
                      <Pill.Text fontType="text">
                        {isMacOs ? "⌘" : "Ctrl"} K
                      </Pill.Text>
                    </Pill>
                  </>
                }
                asChild
              >
                <Icon
                  color="text-gray-500 hover:text-gray-700"
                  icon={faSearch}
                  size="xs"
                  onClick={showOmnibox}
                />
              </Tooltip>
              {canAccessSettingsPage(capabilities) && (
                <Tooltip placement="bottom-end" title="Settings" asChild>
                  <Link to={settingsPageUrl}>
                    <Icon
                      color="text-gray-500 hover:text-gray-7000"
                      dataLoc="header-settings-icon"
                      icon={faCog}
                      size="xs"
                    />
                  </Link>
                </Tooltip>
              )}
              <UserMenu />
            </div>
          </>
        )}
      </div>
    </header>
  );
};

LayoutHeader.Breadcrumb = ({ label, href, children, dataLoc }) => (
  <li className="flex items-center gap-x-3">
    <Icon color="text-gray-500" icon={faChevronRight} size="xs" />
    {href ? (
      <Link
        className="text-gray-800 font-inter-semibold-13px"
        data-loc={dataLoc}
        to={href}
      >
        {label}
      </Link>
    ) : (
      children
    )}
  </li>
);
