import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { ComboboxOption } from "@headlessui2/react";
import React from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { AwsRegionTypeT } from "src/constants/AwsRegions";
import { RegionDisplay } from "src/layout/WorkspaceFormModal/RegionDisplay";

type RegionComboBoxProps = {
  region: AwsRegionTypeT;
};

export const RegionComboBoxItem: React.FC<RegionComboBoxProps> = ({
  region,
}) => {
  return (
    <ComboboxOption as={React.Fragment} value={region}>
      {({ selected, active }) => (
        <li
          className={twJoin(
            "flex cursor-pointer justify-between gap-x-2 px-4 py-2",
            active && "bg-gray-50",
          )}
        >
          <RegionDisplay awsRegion={region} />
          {selected && (
            <Icon color="text-indigo-600" icon={faCheck} size="2xs" />
          )}
        </li>
      )}
    </ComboboxOption>
  );
};
