import { AnimatePresence } from "framer-motion";
import { useRef } from "react";
import ReactDOM from "react-dom";

import { FloatingChart } from "src/analytics/FloatingChart/FloatingChart";
import {
  FloatingWindowsContext,
  FloatingWindowsContextType,
} from "src/base-components/FloatingWindow/context";
import { useFloatingWindows } from "src/base-components/FloatingWindow/hooks";
import {
  createFloatingWindowsStore,
  FloatingWindowProps,
  FloatingWindowType,
} from "src/base-components/FloatingWindow/store";
import { CopilotWindow } from "src/copilot/CopilotWindow";
import { DetailedView } from "src/dataTable/DetailedView/DetailedView";

export const FloatingWindowsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const storeRef = useRef<FloatingWindowsContextType>();

  if (!storeRef.current) {
    storeRef.current = createFloatingWindowsStore();
  }

  return (
    <FloatingWindowsContext.Provider value={storeRef.current}>
      {children}
      <FloatingWindows />
    </FloatingWindowsContext.Provider>
  );
};

const FloatingWindowComponents: Record<
  FloatingWindowType,
  React.FC<FloatingWindowProps>
> = {
  "detailed-view": DetailedView,
  chart: FloatingChart,
  copilot: CopilotWindow,
} as const;

const FloatingWindows: React.FC = () => {
  const windows = useFloatingWindows();

  return ReactDOM.createPortal(
    <div className="fixed left-0 top-0 isolate z-40">
      <AnimatePresence initial={false}>
        {Object.entries(windows).map(([id, window]) => {
          const Component = FloatingWindowComponents[window.type];

          return (
            <Component
              key={id}
              computePosition={window.computePosition}
              data={window.data}
              id={id}
            />
          );
        })}
      </AnimatePresence>
    </div>,
    document.body,
  );
};
