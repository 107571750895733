import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { Button, ButtonVariant } from "src/base-components/Button";
import { HEADER_HEIGHT } from "src/layout/DetailHeader";

export type PropsT = {
  title?: React.ReactNode;
  buttonProps?: {
    title: React.ReactNode;
    variant?: ButtonVariant;
    icon?: IconDefinition;
    dataLoc?: string;
    onClick?: () => void;
  };
};

export const OverviewHeader: React.FC<PropsT> = ({ title, buttonProps }) => {
  return (
    <div
      className="flex w-full flex-row items-center justify-between bg-white px-8 py-5 text-gray-800 shadow-sm"
      style={{ height: HEADER_HEIGHT }}
    >
      <div className="h-3.5 text-gray-800 font-inter-semibold-14px">
        {title}
      </div>
      {buttonProps?.title && (
        <Button
          dataLoc={buttonProps.dataLoc}
          iconLeft={buttonProps.icon}
          size="base"
          variant={buttonProps.variant ?? "primary"}
          onClick={buttonProps?.onClick}
        >
          {buttonProps.title}
        </Button>
      )}
    </div>
  );
};
