import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faBolt,
  faBracketsCurly,
  faCheck,
  faChevronDown,
  faDiagramNext,
  faPlus,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { faRotateRight } from "@fortawesome/pro-solid-svg-icons";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuSeparator,
} from "@headlessui2/react";
import { uniqBy } from "lodash";
import React, { ReactNode, useState } from "react";
import { twJoin } from "tailwind-merge";

import { DatasetColumnGroups, DesiredType } from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import { Spinner } from "src/base-components/Spinner";
import { ProviderIcon } from "src/connections/config/Icon";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { type MockColumn } from "src/datasets/DatasetTable/cells";
import { DatasetContext, JSONValue } from "src/datasets/DatasetTable/types";
import {
  AvailableOutcomeColumns,
  CellId,
  MOCK_COLUMN_DISABLED_TOOLTIP,
  SUB_COLUMN_SEPARATOR,
  getDesiredType,
  isParentIntegrationNode,
  parseCellId,
} from "src/datasets/DatasetTable/utils";
import { useResourceSample } from "src/datasets/api/queries";
import { DESIRED_TYPE_ICONS, DatasetIntegrationNode } from "src/datasets/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { assertUnreachable } from "src/utils/typeUtils";

export enum ParentNodeMockMode {
  OverallNodeOutput = "overall-node-mock",
  IndividualExternalResponses = "individual-node-mock",
}

export const COLUMN_MENU_CLASS = "dataset-column-menu";

const ColumnMenuItem: React.FC<{
  id: string;
  children: ReactNode;
  disabledReason?: string;
  isHeader?: boolean;
  onClick: (
    key: string,
    close: () => void,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  dataLoc?: string;
}> = (props) => (
  <MenuItem disabled={!!props.disabledReason || props.isHeader}>
    {({ disabled, close }) => (
      <Tooltip
        disabled={!props.disabledReason}
        placement="left"
        title={props.disabledReason}
        asChild
      >
        <button
          className={twJoin(
            "w-full px-4 py-2.5 text-left font-medium font-inter-normal-13px",
            !!props.disabledReason || props.isHeader
              ? "cursor-default"
              : "cursor-pointer hover:bg-gray-50",
            !!props.disabledReason ? "text-gray-500" : "text-gray-800",
          )}
          data-loc={props.dataLoc}
          onClick={(event) =>
            !disabled ? props.onClick(props.id, close, event) : undefined
          }
        >
          {props.children}
        </button>
      </Tooltip>
    )}
  </MenuItem>
);

type Props = {
  visible: boolean;
  mock: {
    mockFields: MockColumn[];
    integrationNodesExist: boolean;
  };
  input: { inputFields: string[]; schemaIsEmpty: boolean };
  output: { outputFields: string[]; schemaIsEmpty: boolean };
  outcome: {
    outcomeFields: AvailableOutcomeColumns[];
    thereAreNoOutcomes: boolean;
  };
  onDelete?: () => void;
  onDeleteSubMocks?: () => void;
  onAdd: (
    key: string,
    group: DatasetColumnGroups,
    desiredType?: DesiredType,
  ) => void;
  onAddSubMocks?: (
    subMockNames: string[],
    mockColumnName: string,
    desiredType: Extract<DesiredType, "object" | "any">,
  ) => void;
  onAddAdditionalColumn: () => void;
  typeChange?: {
    selectedType: DesiredType;
    compatibleType?: DesiredType;
    onChangeType: (selectedType: DesiredType) => void;
  };
  fillMockColumn?: {
    onFill?: (value: JSONValue) => void;
    integrationNode: DatasetIntegrationNode;
  };
  columnDisabled?: boolean;
  button?: ReactNode;
  placement?: "bottom-start" | "bottom-end";
  buttonAs?: React.ElementType;
  context: DatasetContext;
  cellId?: CellId;
};

type MenuMode =
  | "main"
  | "add-fields-input"
  | "add-fields-mock"
  | "change-type"
  | "add-fields-output"
  | "add-fields-outcome"
  | "fill-mock-column";

const menuModeLabelMap: Record<Exclude<MenuMode, "main">, string> = {
  "add-fields-input": "Add input data field",
  "add-fields-mock": "Add mock external data field",
  "add-fields-output": "Add expected output field",
  "add-fields-outcome": "Add outcome fields",
  "change-type": "Change field type",
  "fill-mock-column": "Fill with sample report",
};

const divider = <MenuSeparator className="my-2.5 border-t border-gray-200" />;
const MenuHeader: React.FC<{ title: string }> = ({ title }) => (
  <div className="py-2 pl-5 font-inter-normal-12px">{title}</div>
);

export const ColumnMenu: React.FC<Props> = ({
  cellId,
  visible,
  input,
  output,
  mock,
  outcome,
  onDelete,
  onDeleteSubMocks,
  onAdd,
  onAddSubMocks,
  typeChange,
  fillMockColumn,
  columnDisabled,
  button = (
    <Icon
      color="text-gray-500"
      dataLoc="expand-column-menu-button"
      icon={faChevronDown}
      size="2xs"
    />
  ),
  onAddAdditionalColumn,
  buttonAs: as,
  context,
}) => {
  const [menuMode, setMenuMode] = useState<MenuMode>("main");
  const isAuthoringContext = context === "authoring";

  const typeItems = typeChange
    ? Object.entries(DESIRED_TYPE_ICONS).map(([name, icon]) => (
        <ColumnMenuItem
          key={name}
          dataLoc={`change-type-${name}`}
          id={name}
          onClick={(id) => typeChange.onChangeType(id as DesiredType)}
        >
          <div className="flex items-center">
            <Icon color="text-gray-500" icon={icon} size="xs" />
            <span className="px-2 capitalize">{name}</span>
            {typeChange.selectedType !== name &&
              typeChange.compatibleType === name && (
                <Pill size="base" variant="gray">
                  <Pill.Text>Schema type</Pill.Text>
                </Pill>
              )}
            {typeChange.selectedType === name && (
              <>
                <div className="ml-auto" />
                <Icon color="text-indigo-500" icon={faCheck} size="sm" />
              </>
            )}
          </div>
        </ColumnMenuItem>
      ))
    : [];

  const inputItems = input.inputFields.map((col) => (
    <ColumnMenuItem
      key={col}
      dataLoc={`add-fields-input-${col}`}
      id={col}
      onClick={(id, close) => {
        setMenuMode("main");
        onAdd(id, "input_columns");
        close();
      }}
    >
      {col}
    </ColumnMenuItem>
  ));

  const mockItems = mock.mockFields.map(
    ({ name, provider, mediaKey, subMocks }) => {
      const subMocksRendered = subMocks?.map((subMock) => (
        <ColumnMenuItem
          key={`${name}-${subMock.name}`}
          id={[name, subMock.name].join(SUB_COLUMN_SEPARATOR)}
          onClick={(id, close) => {
            onAdd(id, "mock_columns");
            setMenuMode("main");
            close();
          }}
        >
          <div className="flex items-center gap-x-2 pl-7.5">
            {subMock.provider !== NODE_TYPE.FLOW_NODE &&
              subMock.provider !== NODE_TYPE.LOOP_NODE && (
                <ProviderIcon
                  mediaKey={subMock.mediaKey}
                  provider={subMock.provider}
                />
              )}
            {subMock.name}
          </div>
        </ColumnMenuItem>
      ));

      return [
        <ColumnMenuItem
          key={name}
          dataLoc={`add-fields-mock-${name}`}
          id={name}
          isHeader={Boolean(subMocks)}
          onClick={(id, close) => {
            if (subMocks) {
              return;
            }
            setMenuMode("main");
            if (provider === NODE_TYPE.LOOP_NODE) {
              onAdd(id, "mock_columns", "any");
            } else {
              onAdd(id, "mock_columns");
            }
            close();
          }}
        >
          <div className="flex items-center gap-x-2">
            {provider === NODE_TYPE.FLOW_NODE ||
            provider === NODE_TYPE.LOOP_NODE ? (
              <div className="inline-flex h-6 w-6 items-center justify-center rounded border border-gray-200">
                <Icon
                  icon={
                    provider === NODE_TYPE.FLOW_NODE
                      ? faDiagramNext
                      : faRotateRight
                  }
                  padding={false}
                  size="2xs"
                />
              </div>
            ) : (
              <ProviderIcon mediaKey={mediaKey} provider={provider} />
            )}
            {name}
          </div>
        </ColumnMenuItem>,
        subMocksRendered,
      ];
    },
  );

  const expectedOutputItems = output.outputFields.map((col) => (
    <ColumnMenuItem
      key={col}
      id={col}
      onClick={(id, close) => {
        setMenuMode("main");
        onAdd(id, "output_columns");
        close();
      }}
    >
      {col}
    </ColumnMenuItem>
  ));

  const outcomeItems = uniqBy(outcome.outcomeFields, "outcomeKey").map(
    (outcomeField) => (
      <ColumnMenuItem
        key={outcomeField.outcomeKey}
        id={outcomeField.outcomeKey}
        onClick={(id, close) => {
          setMenuMode("main");
          onAdd(id, "outcome_columns");
          close();
        }}
      >
        {outcomeField.outcomeName}
      </ColumnMenuItem>
    ),
  );

  const MainItem = ({
    icon,
    name,
    disabledReason,
    dataLoc,
    ...props
  }: {
    icon: IconProp;
    name: string;
    disabledReason?: string;
    dataLoc?: string;
  } & (
    | { setMode: true; id: MenuMode }
    | { onClick: (key: string) => void; setMode?: false; id: string }
  )) => (
    <ColumnMenuItem
      dataLoc={dataLoc}
      disabledReason={disabledReason}
      id={props.id}
      onClick={
        props.setMode
          ? (mode, _, e) => {
              e.preventDefault();
              setMenuMode(mode as MenuMode);
            }
          : props.onClick
      }
    >
      <div className="flex items-center">
        <Icon color="text-gray-500" icon={icon} size="xs" />
        <span className="px-2">{name}</span>
      </div>
    </ColumnMenuItem>
  );

  const hasChildConnectionNodes =
    (fillMockColumn?.integrationNode.mockableChildNodes?.length ?? 0) > 0;

  const isFlowNode =
    fillMockColumn?.integrationNode &&
    isParentIntegrationNode(fillMockColumn?.integrationNode);

  const [parentNodeMockMode, setParentNodeMockMode] =
    useState<ParentNodeMockMode | null>(
      onAddSubMocks || onDeleteSubMocks
        ? onAddSubMocks
          ? ParentNodeMockMode.OverallNodeOutput
          : ParentNodeMockMode.IndividualExternalResponses
        : null,
    );

  const handleParentNodeMockModeChange = (value: string) => {
    setParentNodeMockMode(value as ParentNodeMockMode);

    if (!cellId || !fillMockColumn) {
      return;
    }
    const [, prefixedColumnName] = parseCellId(cellId);
    const columnName = prefixedColumnName.replace("mock_data.", "");

    if (value === ParentNodeMockMode.IndividualExternalResponses) {
      const subMockColumnNames =
        fillMockColumn.integrationNode.mockableChildNodes?.map(
          (childNode) => childNode.name,
        ) ?? [];

      onAddSubMocks?.(
        subMockColumnNames,
        columnName,
        getDesiredType(fillMockColumn.integrationNode),
      );
    } else {
      onDeleteSubMocks?.();
    }
  };

  const mainItems = (
    <>
      {isAuthoringContext && fillMockColumn && fillMockColumn.onFill && (
        <MainItem
          disabledReason={
            columnDisabled
              ? MOCK_COLUMN_DISABLED_TOOLTIP.title
              : parentNodeMockMode ===
                  ParentNodeMockMode.IndividualExternalResponses
                ? "Only accessed from child flow mocks"
                : undefined
          }
          icon={faBolt}
          id="fill-mock-column"
          name={menuModeLabelMap["fill-mock-column"]}
          setMode
        />
      )}
      {typeChange && (
        <MainItem
          dataLoc="change-type"
          icon={faBracketsCurly}
          id="change-type"
          name={menuModeLabelMap["change-type"]}
          setMode
        />
      )}
      {onDelete && (
        <MainItem
          icon={faTrashAlt}
          id="delete"
          name="Delete field"
          onClick={onDelete}
        />
      )}
      {isAuthoringContext &&
        isFlowNode &&
        parentNodeMockMode &&
        hasChildConnectionNodes && (
          <>
            {divider}
            <MenuHeader title="What should be mocked for this node?" />
            <div className="px-4 pb-2">
              <SimpleRadioGroup
                orientation="vertical"
                value={parentNodeMockMode}
                onValueChange={handleParentNodeMockModeChange}
              >
                <SimpleRadioGroup.Item
                  dataLoc="overall-mock-mode-radio"
                  label="Overall node output"
                  labelClassName="pl-2 w-full"
                  value={ParentNodeMockMode.OverallNodeOutput}
                />
                <SimpleRadioGroup.Item
                  dataLoc="individual-node-mock-mode-radio"
                  label="Individual external responses within the Child Flow"
                  labelClassName="pl-2 w-full"
                  value={ParentNodeMockMode.IndividualExternalResponses}
                />
              </SimpleRadioGroup>
            </div>
          </>
        )}
      {(fillMockColumn || typeChange || onDelete) && divider}
      <MenuHeader title="Add fields" />
      <MainItem
        dataLoc="add-fields-input"
        disabledReason={
          inputItems.length === 0
            ? input.schemaIsEmpty
              ? "The input schema has no fields yet"
              : "All the input schema fields are already present as columns in the table"
            : undefined
        }
        icon={faPlus}
        id="add-fields-input"
        name={menuModeLabelMap["add-fields-input"]}
        setMode
      />
      {isAuthoringContext && (
        <MainItem
          dataLoc="add-fields-mock"
          disabledReason={
            mockItems.length === 0
              ? mock.integrationNodesExist
                ? "All the Integration Nodes are already present as columns in the table"
                : "There are no Integration Nodes in this flow version"
              : undefined
          }
          icon={faPlus}
          id="add-fields-mock"
          name={menuModeLabelMap["add-fields-mock"]}
          setMode
        />
      )}
      {isAuthoringContext && (
        <MainItem
          disabledReason={
            expectedOutputItems.length === 0
              ? output.schemaIsEmpty
                ? "The output schema has no fields yet"
                : "All the output schema fields are already present as columns in the table"
              : undefined
          }
          icon={faPlus}
          id="add-fields-output"
          name={menuModeLabelMap["add-fields-output"]}
          setMode
        />
      )}
      {isAuthoringContext && (
        <MainItem
          disabledReason={
            outcome.outcomeFields.length === 0
              ? outcome.thereAreNoOutcomes
                ? "There are no outcome types in this Flow"
                : "All the outcome fields are already present as columns in the table"
              : undefined
          }
          icon={faPlus}
          id="add-fields-outcome"
          name={menuModeLabelMap["add-fields-outcome"]}
          setMode
        />
      )}
      <MainItem
        icon={faPlus}
        id="add-fields-aux"
        name="Add additional column"
        onClick={onAddAdditionalColumn}
      />
    </>
  );

  const renderItems = () => {
    switch (menuMode) {
      case "add-fields-input":
        return inputItems;
      case "add-fields-mock":
        return mockItems;
      case "add-fields-output":
        return expectedOutputItems;
      case "change-type":
        return typeItems;
      case "main":
        return mainItems;
      case "add-fields-outcome":
        return outcomeItems;
      case "fill-mock-column":
        return (
          fillMockColumn &&
          fillMockColumn.onFill && (
            <MockFillOptions
              {...fillMockColumn}
              onFill={(value, close) => {
                fillMockColumn.onFill?.(value);
                close();
                setMenuMode("main");
              }}
            />
          )
        );
      default:
        assertUnreachable(menuMode);
    }
  };

  const renderHeader = () => {
    if (menuMode !== "main") {
      const currentLabel = menuModeLabelMap[menuMode];
      return (
        <div className="flex gap-x-2 px-4 py-2.5 text-gray-800 font-inter-semibold-13px">
          <Icon
            color="text-gray-500"
            icon={faArrowLeft}
            size="xs"
            onClick={() => setMenuMode("main")}
          />
          <span>{currentLabel}</span>
        </div>
      );
    }
  };

  return (
    <Menu>
      <MenuButton
        as={as}
        className={twJoin(
          "data-[open]:visible",
          visible ? "visible" : "invisible",
        )}
        data-loc={`column-menu_${cellId}`}
      >
        {button}
      </MenuButton>
      <MenuItems
        anchor={{
          to: "bottom end",
          offset: 8,
          gap: 10,
          padding: 32,
        }}
        className={twJoin(
          "z-50 min-h-[16rem] w-70 overflow-y-auto rounded-lg bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none",
          "transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0",
          COLUMN_MENU_CLASS,
        )}
        data-loc="column-menu-panel"
        transition
      >
        {renderHeader()}
        {renderItems()}
      </MenuItems>
    </Menu>
  );
};

const MockFillOptions = ({
  onFill,
  integrationNode,
}: {
  integrationNode: DatasetIntegrationNode;
  onFill: (value: JSONValue, close: () => void) => void;
}) => {
  const { workspace } = useAuthoringContext();
  const resourceSample = useResourceSample({
    baseUrl: workspace.base_url!,
    integrationNode,
  });

  return resourceSample.data ? (
    <>
      {resourceSample.data.map((sample) => (
        <ColumnMenuItem
          key={sample.name}
          id={sample.name}
          onClick={(_, close) => onFill(sample.sample, close)}
        >
          {sample.name}
        </ColumnMenuItem>
      ))}
    </>
  ) : (
    <Spinner />
  );
};
