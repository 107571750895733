import React from "react";
import { useFormContext } from "react-hook-form";

import { ConnectionT } from "src/api/connectApi/types";
import { Divider } from "src/base-components/Divider";
import { DataRetentionConfig } from "src/baseConnectionNode/DataRetentionConfig";
import { EnvironmentDataSourceConfig } from "src/baseConnectionNode/EnvironmentDataSourceConfig";
import { InboundWebhookURLField } from "src/connections/config/inboundWebhook/InboundWebhookURLField";
import { Callout } from "src/design-system/Callout";
import { InboundWebhookConnectionForm } from "src/inboundWebhookNode/types";

type ConfigPanePropsT = {
  immutable: boolean;
  connection: ConnectionT;
};

export const ConfigPane: React.FC<ConfigPanePropsT> = ({
  connection,
  immutable,
}) => {
  const productionWebhookURL = connection.configuration.webhook_url || "";
  const sandboxWebhookURL =
    connection.non_prod_env_configs?.["sandbox"]?.webhook_url || "";

  const formProps = useFormContext<InboundWebhookConnectionForm>();

  return (
    <>
      <EnvironmentDataSourceConfig
        connection={connection}
        disabled={immutable}
        type="webhook"
      />
      <Divider spacing="my-4" />
      <DataRetentionConfig connection={connection} immutable={immutable} />
      {formProps.getValues("config.caching.active") && (
        <div className="mt-2">
          <Callout type="warning">
            Please ensure that caching is also enabled on any Custom Connection
            node this Webhook might depend on
          </Callout>
        </div>
      )}
      <Divider spacing="my-4" />
      <InboundWebhookURLField
        productionWebhookURL={productionWebhookURL}
        sandboxWebhookURL={sandboxWebhookURL}
      />
    </>
  );
};
