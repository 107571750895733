import { useMemo } from "react";

import { ConnectionT } from "src/api/connectApi/types";
import { ConnectionDataSourcesValues } from "src/baseConnectionNode/types";

export type DropdownElement = {
  key: string;
  value: ConnectionDataSourcesValues;
};

export type EnvironmentConfigSettings = {
  disabled: boolean;
  defaultOption?: string;
  tooltipTitle?: string;
  options: DropdownElement[];
};

export type EnvironmentConfigsT = {
  testRunSettings: EnvironmentConfigSettings;
  sandboxSettings: EnvironmentConfigSettings;
};

const MOCK_OPTION: DropdownElement = {
  key: ConnectionDataSourcesValues.mock_data,
  value: ConnectionDataSourcesValues.mock_data,
};

const EMPTY_RESPONSE_OPTION: DropdownElement = {
  key: ConnectionDataSourcesValues.empty_response,
  value: ConnectionDataSourcesValues.empty_response,
};

const SANDBOX_OPTION: DropdownElement = {
  key: ConnectionDataSourcesValues.sandbox,
  value: ConnectionDataSourcesValues.sandbox,
};

export const PRODUCTION_OPTION: DropdownElement = {
  key: ConnectionDataSourcesValues.production,
  value: ConnectionDataSourcesValues.production,
};

export const getTestRunSettings = (
  allowProductionCredentialsInTest: boolean,
  allowSandboxCredentials: boolean,
  type: "webhook" | "default",
) => {
  const envOptions = [MOCK_OPTION];
  let tooltipTitle: string | undefined = undefined;

  // We don't allow the real call options for "test run"
  // for webhook nodes, otherwise the "test run" execution
  // time is unbounded
  if (type !== "webhook") {
    tooltipTitle =
      "If you'd like to make live API calls during testing, you can enable it from the Connection configuration";

    if (allowSandboxCredentials) {
      envOptions.push(SANDBOX_OPTION);
      tooltipTitle = undefined;
    }

    if (allowProductionCredentialsInTest) {
      envOptions.push(PRODUCTION_OPTION);
      tooltipTitle = undefined;
    }
  } else {
    tooltipTitle =
      "For test runs, waiting for Inbound Webhook requests is not supported. If you'd like to test using live webhooks, you can use the /sandbox/decide endpoint.";
  }

  const isDisabled = envOptions.length === 1;
  return {
    options: envOptions,
    defaultOption: envOptions[0]["key"],
    disabled: isDisabled,
    tooltipTitle,
  };
};

export const getSandboxSettings = (
  allowProductionCredentialsInTest: boolean,
  allowSandboxCredentials: boolean,
) => {
  const envOptions = [MOCK_OPTION, EMPTY_RESPONSE_OPTION];
  let tooltipTitle: string | undefined =
    "For API calls to the /sandbox/decide endpoint, this Node will use mock data. If you'd like to make live API calls on the sandbox endpoint, you can enable it from the Connection configuration.";

  if (allowSandboxCredentials) {
    envOptions.push(SANDBOX_OPTION);
    tooltipTitle =
      "If you'd like to use production credentials while making /sandbox/decide API calls, you can enable it from the Connection configuration";
  }

  if (allowProductionCredentialsInTest) {
    envOptions.push(PRODUCTION_OPTION);
    tooltipTitle = undefined;
  }

  const isDisabled = envOptions.length === 1;
  return {
    options: envOptions,
    defaultOption: envOptions[0]["key"],
    disabled: isDisabled,
    tooltipTitle,
  };
};

export const useEnvironmentAvailableConfigs = (
  connection: ConnectionT,
  type: "webhook" | "default",
): EnvironmentConfigsT => {
  // This hook provides the necessary settings for mock and sandbox
  // depending on the connection configuration

  const { enable_non_prod_configs, configuration, non_prod_env_configs } =
    connection;
  const { allow_test_invocations: allowProductionCredentialsInTest = false } =
    configuration;
  const allowSandboxCredentials =
    enable_non_prod_configs && Boolean(non_prod_env_configs?.["sandbox"]);

  const testRunSettings: EnvironmentConfigSettings = useMemo(
    () =>
      getTestRunSettings(
        allowProductionCredentialsInTest,
        allowSandboxCredentials,
        type,
      ),
    [allowSandboxCredentials, allowProductionCredentialsInTest, type],
  );

  const sandboxSettings: EnvironmentConfigSettings = useMemo(
    () =>
      getSandboxSettings(
        allowProductionCredentialsInTest,
        allowSandboxCredentials,
      ),
    [allowSandboxCredentials, allowProductionCredentialsInTest],
  );

  return {
    testRunSettings,
    sandboxSettings,
  };
};
