import { useNodeResultData } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { NodeTestRunResult } from "src/api/types";
import { BeMappedNode } from "src/constants/NodeDataTypes";
import { usePaginatedNodeRunSuccessData } from "src/nodeEditor/api/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { useNodeRunState } from "src/store/runState/RunState";

// max size of object 256kb
const MAX_SIZE = 256 * 1024;

const dummyTestResult: NodeTestRunResult = {
  errors_count: 0,
  failure_count: 0,
  ignored_count: 0,
  success_count: 0,
  total_count: 0,
  expected_output_mismatch_count: 0,
  failure_pages_urls: [],
  ignored_pages_urls: [],
  success_pages_urls: [],
  success_match_pages_urls: [],
  success_mismatch_pages_urls: [],
  errors_pages_urls: [],
  aux_data_columns: [],
  data_columns: [],
  expected_output_columns: [],
  node_id: "dummy",
  test_run_id: "dummy",
  version_id: "",
};

export const useFirstDataSample = ({ node }: { node: BeMappedNode | null }) => {
  const { workspace } = useWorkspaceContext();
  const nodeRunState = useNodeRunState(node?.id ?? "");

  const testResult =
    nodeRunState && "testResult" in nodeRunState
      ? nodeRunState.testResult
      : dummyTestResult;
  const decisionId =
    nodeRunState && "decisionId" in nodeRunState ? nodeRunState.decisionId : "";

  const { data: decisionData } = useNodeResultData({
    nodeId: node?.id ?? "",
    baseUrl: workspace.base_url!,
    decisionId,
  });

  const { data: nodeData } = usePaginatedNodeRunSuccessData(
    testResult,
    workspace.base_url!,
    !(nodeRunState && "testResult" in nodeRunState),
  );

  const dataSample =
    decisionData ?? nodeData?.pages.at(0)?.data[0]?.data ?? null;
  const blob = new Blob([JSON.stringify(dataSample)]);

  return blob.size > MAX_SIZE ? null : dataSample;
};
