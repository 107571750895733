import React from "react";
import { twJoin } from "tailwind-merge";

import { Providers, ProviderT } from "src/api/connectApi/types";
import BigQueryIcon from "src/assets/BigQueryIcon.svg?react";
import BoniversumIcon from "src/assets/BoniversumIcon.svg?react";
import CodatIcon from "src/assets/CodatIcon.svg?react";
import CreditsafeIcon from "src/assets/CreditsafeIcon.svg?react";
import CrifIcon from "src/assets/CrifIcon.svg?react";
import CustomConnectionIcon from "src/assets/CustomConnectionIcon.svg?react";
import DataXIcon from "src/assets/DataXIcon.svg?react";
import DnbIcon from "src/assets/DnbIcon.svg?react";
import EquifaxIcon from "src/assets/EquifaxIcon.svg?react";
import ExperianIcon from "src/assets/ExperianIcon.svg?react";
import InboundWebhookIcon from "src/assets/InboundWebhookIcon.svg?react";
import MySQLIcon from "src/assets/MySQLIcon.svg?react";
import PostgresIcon from "src/assets/PostgresIcon.svg?react";
import RedshiftIcon from "src/assets/RedshiftIcon.svg?react";
import RetoolIcon from "src/assets/RetoolIcon.svg?react";
import SchufaIcon from "src/assets/SchufaIcon.svg?react";
import SnowflakeIcon from "src/assets/SnowflakeIcon.svg?react";
import { CustomIcon } from "src/connections/config/CustomIcon";
import { ManifestIcon } from "src/connections/config/manifest/ManifestIcon";
import {
  CONNECTIONS_MAP,
  providerInConfigMap,
} from "src/connections/connectionConfigs";
import { assertUnreachable } from "src/utils/typeUtils";

type PropsT = {
  provider: ProviderT | string;
  mediaKey?: string | null;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  manifestVersion?: string;
};

export const ProviderIcon: React.FC<PropsT> = ({
  provider,
  mediaKey,
  manifestVersion,
  size = "md",
}) => {
  const iconClasses = twJoin(
    size === "xs" && "h-3.5 w-3.5",
    size === "sm" && "h-5 w-5 rounded-[3px]",
    size === "md" && "h-6 w-6 rounded-[4px]",
    size === "lg" && "h-7 w-7 rounded-[6px]",
    size === "xl" && "h-10 w-10 rounded-lg",
    size === "xxl" && "h-12 w-12 rounded-lg",
  );

  // Handle manifest providers, this should read the icon from the Manifest
  //  returning the CC Icon for now
  // Converting the Providers array to string[] to please type checks
  if (!(Providers as readonly string[]).includes(provider)) {
    return (
      <ManifestIcon
        className={iconClasses}
        manifestVersion={manifestVersion}
        provider={provider}
      />
    );
  }

  const staticProvider = provider as ProviderT;
  if (providerInConfigMap(staticProvider)) {
    const Icon = CONNECTIONS_MAP[staticProvider].icon;
    return <Icon className={iconClasses} />;
  }

  switch (staticProvider) {
    case "schufa": {
      return <SchufaIcon className={iconClasses} />;
    }
    case "boniversum": {
      return <BoniversumIcon className={iconClasses} />;
    }
    case "codat": {
      return <CodatIcon className={iconClasses} />;
    }
    case "data_x": {
      return <DataXIcon className={iconClasses} />;
    }
    case "dnb": {
      return <DnbIcon className={iconClasses} />;
    }
    case "crif_b2c": {
      return <CrifIcon className={iconClasses} />;
    }
    case "crif_b2b": {
      return <CrifIcon className={iconClasses} />;
    }
    case "crif_high_mark": {
      return <CrifIcon className={iconClasses} />;
    }
    case "equifax_uk": {
      return <EquifaxIcon className={iconClasses} />;
    }
    case "equifax_us": {
      return <EquifaxIcon className={iconClasses} />;
    }
    case "equifax_us_commercial_risk": {
      return <EquifaxIcon className={iconClasses} />;
    }
    case "experian_us_b2b": {
      return <ExperianIcon className={iconClasses} />;
    }
    case "experian_us": {
      return <ExperianIcon className={iconClasses} />;
    }
    case "experian_us_clarity_services": {
      return <ExperianIcon className={iconClasses} />;
    }
    case "creditsafe": {
      return <CreditsafeIcon className={iconClasses} />;
    }
    case "webhook": {
      return <InboundWebhookIcon className={iconClasses} />;
    }
    case "postgresql": {
      return <PostgresIcon className={iconClasses} />;
    }
    case "bigquery": {
      return <BigQueryIcon className={iconClasses} />;
    }
    case "snowflake": {
      return <SnowflakeIcon className={iconClasses} />;
    }
    case "redshift": {
      return <RedshiftIcon className={iconClasses} />;
    }
    case "mysql": {
      return <MySQLIcon className={iconClasses} />;
    }
    case "retool": {
      return <RetoolIcon className={iconClasses} />;
    }
    case "custom": {
      return mediaKey ? (
        <div
          className={twJoin(
            "align-items-center justify-content-center inline-flex shrink-0 border border-gray-200",
            iconClasses,
          )}
        >
          {/**
           * We need to compute the round corner of the icon based on the size of the icon.
           * The inner round corner should be the same as the outer round corner minus the padding.
           */}
          <CustomIcon
            className={twJoin(
              "h-full w-full object-contain p-[10%]",
              size === "xs" && "",
              size === "sm" && "rounded-[calc(0.125rem-10%)]",
              size === "md" && "rounded-[calc(0.375rem-10%)]",
              size === "lg" && "rounded-[calc(0.5rem-10%)]",
              size === "xl" && "rounded-[calc(0.75rem-10%)]",
            )}
            mediaKey={mediaKey}
          />
        </div>
      ) : (
        <CustomConnectionIcon className={iconClasses} />
      );
    }
    default:
      assertUnreachable(staticProvider);
  }
};
