import React, { createContext, useContext } from "react";
import { twJoin, twMerge } from "tailwind-merge";

import { Icon, IconProps } from "src/base-components/Icon";

// Link to the figma file with the variants and sizes: https://www.figma.com/file/M6oWoeOynJBBK0xppg19h9/Decide-Redesign%3A-Deliverables?node-id=2406%3A10113&t=VPSpQ2WFCRjDtNU5-0

type PillIconProps = {
  size?: "xs" | "sm" | "base" | "md";
} & Pick<IconProps, "padding" | "spin" | "flip" | "color" | "icon">;

type ImageProps = {
  src: string;
  alt?: string;
};

type PillTextProps = {
  children: React.ReactNode;
  fontType?: "text" | "code";
};

type SubComponents = {
  Text: React.FC<PillTextProps>;
  Icon: React.FC<PillIconProps>;
  Image: React.FC<ImageProps>;
};

export type PillVariants =
  | "indigo-light"
  | "red"
  | "gray"
  | "yellow"
  | "green"
  | "white"
  | "indigo"
  | "orange"
  | "outlined-white"
  | "outlined-gray"
  | "dark-gray";

type PillProps = {
  onClick?: (event: React.MouseEvent) => void;
  dataLoc?: string;
  children?: React.ReactNode;
  variant?: PillVariants;
  size?: "sm" | "base" | "lg";
  fullWidth?: boolean;
  maxWidth?: boolean;
  hovered?: boolean;
} & Pick<React.HTMLAttributes<HTMLDivElement>, "tabIndex"> & {
    [key: `data-${string}`]: string | number;
  };

const PillContext = createContext<{
  variant: PillVariants;
}>({
  variant: "indigo-light",
});

export const Pill = React.forwardRef<HTMLDivElement, PillProps>(
  (
    {
      children,
      onClick,
      dataLoc,
      variant = "indigo-light",
      size = "base",
      fullWidth = false,
      maxWidth = false,
      hovered = false,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={twMerge(
          "inline-flex h-fit items-center whitespace-nowrap border",
          variant === "indigo-light" &&
            "border-indigo-50 bg-indigo-50 text-indigo-500",
          variant === "red" && "border-red-50 bg-red-50 text-red-500",
          variant === "gray" && "border-gray-100 bg-gray-100 text-gray-600",
          variant === "outlined-gray" &&
            "border-gray-300 bg-gray-100 text-gray-800",
          variant === "yellow" &&
            "border-yellow-50 bg-yellow-50 text-yellow-600",
          variant === "green" && "border-green-50 bg-green-50 text-green-700",
          variant === "outlined-white" &&
            "border-gray-200 bg-white text-gray-600",
          variant === "indigo" && "border-indigo-500 bg-indigo-500 text-white",
          variant === "white" && "border-white bg-white text-gray-600",
          variant === "dark-gray" && "border-gray-700 bg-gray-700 text-gray-50",
          variant === "orange" &&
            "border-orange-50 bg-orange-50 text-orange-600",

          size === "sm" && "rounded px-1",
          size === "base" && "rounded-full px-2 py-px",
          size === "lg" && "h-8 rounded-full px-3 py-1",

          onClick && "cursor-pointer",

          fullWidth && "w-full",
          maxWidth && "max-w-full",

          hovered && "bg-gray-50",
        )}
        data-loc={dataLoc}
        onClick={onClick}
        {...props}
      >
        <PillContext.Provider value={{ variant }}>
          {children}
        </PillContext.Provider>
      </div>
    );
  },
) as React.ForwardRefExoticComponent<PillProps> & SubComponents;

const PillIcon: React.FC<PillIconProps> = ({
  icon,
  size = "base",
  padding,
  spin,
  flip,
  color,
}) => {
  const { variant } = useContext(PillContext);
  return (
    <Icon
      color={
        color ??
        {
          "indigo-light": "text-indigo-400",
          red: "text-red-400",
          gray: "text-gray-500",
          white: "text-gray-400",
          yellow: "text-yellow-400",
          green: "text-green-400",
          indigo: "text-white",
          orange: "text-orange-600",
          "outlined-white": "text-gray-500",
          "outlined-gray": "text-gray-800",
          "dark-gray": "text-gray-50",
        }[variant]
      }
      flip={flip}
      icon={icon}
      padding={padding}
      size={
        size === "md"
          ? "xs"
          : size === "base"
            ? "2xs"
            : size === "sm"
              ? "3xs"
              : size === "xs"
                ? "4xs"
                : "2xs"
      }
      spin={spin}
    />
  );
};

const PillImage: React.FC<ImageProps> = ({ src, alt }) => {
  return (
    <img
      alt={alt ?? ""}
      className="aspect-square w-4.5 rounded-full"
      src={src}
    />
  );
};

const PillText: React.FC<PillTextProps> = ({ children, fontType = "text" }) => (
  <span
    className={twJoin(
      "min-w-0 truncate",
      fontType === "text" && "mx-1 font-inter-medium-12px",
      fontType === "code" && "font-fira-code",
    )}
  >
    {children}
  </span>
);
Pill.Text = PillText;
Pill.Icon = PillIcon;
Pill.Image = PillImage;
