import { ChartFormModal } from "src/analytics/ChartModal/ChartFormModal";
import { NodeAnalyticsData } from "src/analytics/api";
import { useCreateNodeChart, usePatchNodeChart } from "src/analytics/queries";
import { Chart, ChartState } from "src/analytics/types";
import { Pill } from "src/base-components/Pill";
import { toastActions } from "src/design-system/Toast/utils";
import { logger } from "src/utils/logger";
import { isPreconditionError } from "src/utils/predicates";
import { toastError } from "src/utils/toastError";

type NodeChartModalProps = {
  nodeId: string;
  nodeName: string;
  isOpen: boolean;
  onClose: () => void;
  analytics?: NodeAnalyticsData;
  chart?: Chart;
  afterLeave?: () => void;
};

export const NodeChartModal: React.FC<NodeChartModalProps> = ({
  nodeId,
  nodeName,
  isOpen,
  onClose,
  analytics,
  chart,
  afterLeave,
}) => {
  const isEditing = !!chart;
  const { mutateAsync: createChart } = useCreateNodeChart();
  const { mutateAsync: updateChart } = usePatchNodeChart(nodeId);

  const onSubmit = async (chartData: ChartState) => {
    try {
      if (isEditing) {
        await updateChart({
          chart: chartData,
          chartId: chart.id,
          etag: chart.etag,
        });
      } else {
        await createChart({
          ...chartData,
          resource_id: nodeId,
        });
      }
      onClose();
    } catch (e) {
      if (isPreconditionError(e)) {
        toastActions.failure({
          title: `Failed to save the chart`,
          description:
            "Someone made changes while you were editing. Please reload and try again.",
        });
        return;
      }
      logger.error(e);
      toastError(`Failed to ${isEditing ? "save" : "create"} chart`);
    }
  };

  const isSuccess = analytics?.type === "success";

  return (
    <ChartFormModal
      afterLeave={afterLeave}
      analytics={isSuccess ? analytics : undefined}
      chart={chart}
      headerPill={
        <Pill size="sm" variant="gray">
          <Pill.Text>Chart will be saved to "{nodeName}"</Pill.Text>
        </Pill>
      }
      isMultiversionTestRun={false}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
