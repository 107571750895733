import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

import { NodeRunStateV2 } from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { useDatasets } from "src/datasets/api/queries";
import { Callout } from "src/design-system/Callout";
import { InspectDataTimeoutPill } from "src/flowGraph/IntegrationLatency";
import { useOpenSchemaEditor } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useGraphStore } from "src/store/StoreProvider";
import { useNodeRunState } from "src/store/runState/RunState";
import { errorMessage } from "src/utils/stringUtils";
import { assertUnreachable } from "src/utils/typeUtils";

type RunInformationPillProps = {
  openSqlResponseTab?: () => void;
};

const isHistoricalDecisionTimeout = (nodeRunState: NodeRunStateV2): boolean => {
  return (
    nodeRunState.type === "historical-error" &&
    nodeRunState.error.status_code === 408
  );
};

export const RunInformationPill: React.FC<RunInformationPillProps> = observer(
  ({ openSqlResponseTab }) => {
    const { lastRunDatasetId, resultsOutdated, selectedNode } = useGraphStore();
    const openSchemaEditor = useOpenSchemaEditor();
    const { flow, workspace } = useAuthoringContext();
    const datasets = useDatasets({
      flowId: flow.id,
      baseUrl: workspace.base_url,
    });
    const lastRunDatasetName = datasets.data?.find(
      (dataset) => dataset.id === lastRunDatasetId,
    )?.name;

    const selectedNodeRunState = useNodeRunState(selectedNode?.id ?? "");

    const getRunInfo = (): {
      description: ReactNode;
      type: "error" | "info" | "warning";
      icon?: IconProp;
    } => {
      const testRunWithDataset = {
        description: (
          <span>
            Test run with the test data file
            <span className="font-inter-medium-12px">
              &nbsp;{lastRunDatasetName}
            </span>
          </span>
        ),
        type: "info",
      } as const;
      if (!selectedNode) return testRunWithDataset;

      if (resultsOutdated || selectedNodeRunState === undefined) {
        return {
          description: "Test run again to update data",
          type: "warning",
          icon: faInfoCircle,
        };
      }

      switch (selectedNodeRunState.type) {
        case "historical-error":
          if (isHistoricalDecisionTimeout(selectedNodeRunState)) {
            return {
              description: (
                <InspectDataTimeoutPill
                  durations={
                    selectedNodeRunState.error.integration_durations ?? []
                  }
                  errorMessage={errorMessage(selectedNodeRunState.error.msg)}
                />
              ),
              type: "error",
            };
          }
          return {
            description: errorMessage(selectedNodeRunState.error.msg),
            type: "error",
          };
        case "historical-data":
          return { description: "Historical decision", type: "info" };
        case "historical-pending":
          return { description: "This Node is pending", type: "info" };
        case "not-run":
          return { description: "This Node was not run", type: "info" };
        case "test-error":
          return {
            description: errorMessage(selectedNodeRunState.error.msg),
            type: "error",
          };
        case "test-run":
          return testRunWithDataset;
        default:
          assertUnreachable(selectedNodeRunState);
          return { description: "Unreachable default", type: "error" };
      }
    };

    const getRunAction = () => {
      if (selectedNodeRunState?.type === "historical-error") {
        if (
          selectedNode?.type === NODE_TYPE.INPUT_NODE ||
          selectedNode?.type === NODE_TYPE.OUTPUT_NODE
        ) {
          if (isHistoricalDecisionTimeout(selectedNodeRunState)) {
            // TODO INT-3450 update this link to more granular docs page
            return {
              text: "Learn more",
              onClick: () =>
                window.open(
                  "https://app.gitbook.com/o/-MAV6fOfzL4yCfPwf_FL/s/RzIL1HW3k0QUhgyIO90C/decision-design/decision-history#viewing-integration-latency",
                  "_blank",
                ),
            };
          }
          return {
            text: "Edit schema",
            onClick: () => openSchemaEditor(selectedNode),
          };
        } else if (
          selectedNode?.type === NODE_TYPE.SQL_DATABASE_CONNECTION_NODE &&
          openSqlResponseTab
        ) {
          return {
            text: "Debug",
            onClick: openSqlResponseTab,
          };
        }
      }
    };

    const info = getRunInfo();
    return (
      <Callout action={getRunAction()} type={info.type}>
        {info.description}
      </Callout>
    );
  },
);
