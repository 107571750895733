import { faBox, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { EmptyState } from "src/design-system/EmptyState";

export type NoDecisionFlowSelectedProps = {
  immutable: boolean;
  handleSelectDecisionFlow: () => void;
};

export const NoDecisionFlowSelected: React.FC<NoDecisionFlowSelectedProps> = ({
  immutable,
  handleSelectDecisionFlow,
}) => (
  <EmptyState
    actionButton={{
      text: "Select Decision Flow",
      icon: faPlus,
      onClick: handleSelectDecisionFlow,
      disabled: immutable,
    }}
    dataLoc="no-decision-flow"
    description="Select a Decision Flow you want to apply to complete mapping"
    headline="No Decision Flow selected"
    icon={faBox}
  />
);
