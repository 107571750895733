import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { ConnectionT } from "src/api/connectApi/types";
import { Divider } from "src/base-components/Divider";
import { Switch } from "src/base-components/Switch";
import { DataRetentionConfig } from "src/baseConnectionNode/DataRetentionConfig";
import { EnvironmentDataSourceConfig } from "src/baseConnectionNode/EnvironmentDataSourceConfig";
import { TimeoutInput } from "src/baseConnectionNode/TimeoutInput";
import { RetryConfiguration } from "src/customConnectionNode/editorComponents/RetryConfiguration";
import { CustomConnectionForm } from "src/customConnectionNode/types";
import { Tooltip } from "src/design-system/Tooltip";

type ConfigPanePropsT = {
  immutable: boolean;
  connection: ConnectionT;
};

export const ConfigPane: React.FC<ConfigPanePropsT> = ({
  immutable,
  connection,
}) => {
  const formProps = useFormContext<CustomConnectionForm>();
  const rawRequestIsDisabledOnConnection =
    connection.has_raw_requests_enabled_in_resource === false;
  const isTimeoutActive = formProps.getValues("config.timeout.active");

  return (
    <>
      <EnvironmentDataSourceConfig
        connection={connection}
        disabled={immutable}
      />
      <Divider spacing="my-6" />
      <DataRetentionConfig connection={connection} immutable={immutable} />
      <div className="mt-6 flex items-center justify-between">
        <div>
          <h3 className="text-gray-800 font-inter-medium-12px">
            Use custom request timeout
          </h3>
          <span className="text-gray-500 font-inter-normal-12px">
            Enter the maximum number of seconds before timing out the request to
            the provider
          </span>
        </div>
        <Controller
          name="config.timeout.active"
          render={(props) => (
            <Switch
              dataLoc="custom-connection-node-timeout-active"
              disabled={immutable}
              enabled={props.field.value}
              onChange={() => {
                props.field.onChange(!props.field.value);
              }}
            />
          )}
        />
      </div>
      {isTimeoutActive && (
        <div className="mb-2 flex w-full flex-row items-center justify-between rounded-lg bg-gray-50 px-5 py-4">
          <div className="mr-2 flex text-gray-700 font-inter-normal-12px">
            Time out after (seconds)
          </div>
          <TimeoutInput
            dataLoc="custom-connection-node-timeout-seconds"
            formProps={formProps.register("config.timeout.timeout_seconds")}
            immutable={immutable}
          />
        </div>
      )}
      <RetryConfiguration immutable={immutable} />
      <div className="mt-6 flex items-center justify-between">
        <div>
          <h3 className="text-gray-800 font-inter-medium-12px">
            Do not error with 4xx responses
          </h3>
          <span className="text-gray-500 font-inter-normal-12px">
            In case of 4xx responses, the flow will continue executing
          </span>
        </div>
        <Controller
          name="config.error.allow_4xx"
          render={(props) => (
            <Switch
              dataLoc="custom-connection-allow-4xx"
              disabled={immutable}
              enabled={props.field.value}
              onChange={() => {
                props.field.onChange(!props.field.value);
              }}
            />
          )}
        />
      </div>
      <div className="mt-6 flex items-center justify-between">
        <div>
          <h3 className="text-gray-800 font-inter-medium-12px">
            Do not error with 5xx responses
          </h3>
          <span className="text-gray-500 font-inter-normal-12px">
            In case of 5xx responses, the flow will continue executing
          </span>
        </div>
        <Controller
          name="config.error.allow_5xx"
          render={(props) => (
            <Switch
              dataLoc="custom-connection-allow-5xx"
              disabled={immutable}
              enabled={props.field.value}
              onChange={() => {
                props.field.onChange(!props.field.value);
              }}
            />
          )}
        />
      </div>
      <div className="mt-6 flex items-center justify-between">
        <div>
          <h3 className="text-gray-800 font-inter-medium-12px">
            Include raw request to provider
          </h3>
          <span className="text-gray-500 font-inter-normal-12px">
            Include headers, parameters, and body of the request to the provider
            in the Taktile API response
          </span>
        </div>
        <Controller
          name="config.raw_requests.has_raw_requests_enabled_in_node"
          render={(props) => (
            <Tooltip
              disabled={!rawRequestIsDisabledOnConnection}
              placement="top"
              title="This Connection does not have raw requests enabled. Contact your workspace admin to enable raw requests for this Connection."
            >
              <Switch
                dataLoc="custom-connection-node-raw-requests-enabled"
                disabled={immutable || rawRequestIsDisabledOnConnection}
                enabled={props.field.value && !rawRequestIsDisabledOnConnection}
                onChange={() => {
                  props.field.onChange(!props.field.value);
                }}
              />
            </Tooltip>
          )}
        />
      </div>
    </>
  );
};
