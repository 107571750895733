import {
  faCube,
  faDiagramNested,
  faHome,
} from "@fortawesome/pro-regular-svg-icons";
import { Outlet } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { DashboardContent } from "src/layout/DashboardContent";
import { LayoutHeader } from "src/layout/Header";
import { NavItem, NavSidebar } from "src/layout/NavSideBar";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import {
  DashboardPageParamsT,
  getUrlToWsDashboard,
  WsDashboardPageCode,
} from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

const MainSideBar = () => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const [collapsed, setCollapsed] = useLocalStorage(
    "mainSideBarNavigationCollapsed",
    false,
  );
  return (
    <NavSidebar collapsed={collapsed} setCollapsed={setCollapsed}>
      <NavItem
        icon={faHome}
        title="Dashboard"
        to={getUrlToWsDashboard({
          orgId,
          wsId,
          page: WsDashboardPageCode.Dashboard,
        })}
      />
      <NavItem
        icon={faDiagramNested}
        title="Decision Flows"
        to={getUrlToWsDashboard({
          orgId,
          wsId,
          page: WsDashboardPageCode.Flows,
        })}
      />
      <NavItem
        icon={faCube}
        title="Entities"
        to={getUrlToWsDashboard({
          orgId,
          wsId,
          page: WsDashboardPageCode.Entities,
        })}
      />
      <NavItem
        icon={faCube}
        title="Events"
        to={getUrlToWsDashboard({
          orgId,
          wsId,
          page: WsDashboardPageCode.Events,
        })}
      />
      <NavItem
        icon={faCube}
        title="Features"
        to={getUrlToWsDashboard({
          orgId,
          wsId,
          page: WsDashboardPageCode.Features,
        })}
      />
    </NavSidebar>
  );
};

export const MainPageDashboard: React.FC = () => (
  <DashboardContent
    Header={<LayoutHeader />}
    sidebar={
      isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) && <MainSideBar />
    }
  >
    <Outlet />
  </DashboardContent>
);
