import {
  faEdit,
  faPlugCircleBolt,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/design-system/Tooltip";

type PropsT = {
  onDelete: () => void;
  onEdit: () => void;
  onTest: () => void;
  testableStatus: "testable" | "untestable_lacks_probe_url" | "untestable";
  rowId: string;
};
export const ConnectionActions: React.FC<PropsT> = ({
  onDelete,
  onEdit,
  onTest,
  testableStatus,
  rowId,
}) => {
  const isTestable = testableStatus === "testable";

  const testTooltipTitle = isTestable
    ? "Test Connection"
    : testableStatus === "untestable_lacks_probe_url"
      ? "To test this Connection, add a probe URL"
      : "This Integration does not support testing";

  return (
    <>
      <Tooltip placement="top" title={testTooltipTitle} asChild>
        <div className="mx-1">
          <Icon
            color={
              isTestable
                ? "text-gray-500 hover:text-gray-700"
                : "text-gray-300 cursor-default"
            }
            disabled={!isTestable}
            icon={faPlugCircleBolt}
            size="xs"
            onClick={(event) => {
              event.stopPropagation();
              onTest();
            }}
          />
        </div>
      </Tooltip>
      <Tooltip placement="top" title="Edit Connection" asChild>
        <div className="mx-1">
          <Icon
            color="text-gray-500 hover:text-gray-700"
            data-loc={`edit-connection-button-${rowId}`}
            icon={faEdit}
            size="xs"
            onClick={(event) => {
              event.stopPropagation();
              onEdit();
            }}
          />
        </div>
      </Tooltip>
      <Tooltip placement="top" title="Delete Connection" asChild>
        <div className="ml-1">
          <Icon
            color="text-gray-500 hover:text-gray-700"
            data-loc={`delete-connection-button-${rowId}`}
            icon={faTrashCan}
            size="xs"
            onClick={(event) => {
              event.stopPropagation();
              onDelete();
            }}
          />
        </div>
      </Tooltip>
    </>
  );
};
