import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { keyBy } from "lodash";
import { useMemo } from "react";

import {
  ProviderResourceT,
  ResourceSample,
  ResourceT,
} from "src/api/connectApi/types";
import { FlowVersionT, SchemaTypesT } from "src/api/flowTypes";
import { Dataset, DatasetColumn, DesiredType } from "src/api/types";
import { ConnectionDataSourcesValues } from "src/baseConnectionNode/types";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { CustomConnectionConfig } from "src/customConnectionNode/types";
import { getOutcomeDatasetColumns } from "src/datasets/DatasetTable/utils";
import { useOutcomeTypes } from "src/outcomes/queries";
import { SchemaOptions } from "src/router/SearchParams";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useFlowContext } from "src/router/routerContextHooks";
import { SchemaConverter, SchemaUIT } from "src/schema/utils";
import { TYPE_ICONS } from "src/utils/constants";

export type DatasetIssue =
  | {
      issueType: "missing-column";
      schemaType: DesiredType;
    }
  | {
      issueType: "type-mismatch";
      schemaType: DesiredType;
      datasetType: DesiredType;
    }
  | { issueType: "extra-column"; datasetType: DesiredType };

export type DatasetIssues = Partial<Record<string, DatasetIssue>>;

export const schemaTypeToDesiredType = (
  schemaType: SchemaTypesT,
): DesiredType => {
  if (schemaType === "date-str") return "date";
  else if (schemaType === "datetime-str") return "datetime";
  else return schemaType;
};

export const findCompatibilityIssues = (
  dataset: Dataset,
  schema?: SchemaUIT,
): DatasetIssues => {
  const issues: DatasetIssues = {};

  if (!schema) return issues;

  const datasetColumnsByName = keyBy(dataset.input_columns, "name");

  // Check that all columns in the schema are present in the dataset with correct type
  schema.properties.forEach((prop) => {
    const datasetColumn: DatasetColumn | null =
      datasetColumnsByName[prop.fieldName] ?? null;
    const schemaColumnType = schemaTypeToDesiredType(prop.type[0]);

    if (!datasetColumn) {
      if (prop.required) {
        issues[prop.fieldName] = {
          issueType: "missing-column",
          schemaType: schemaColumnType,
        };
      }
    } else if (
      !COMPATIBILITY_TYPE_MAP[datasetColumn.desired_type].includes(
        schemaColumnType,
      )
    ) {
      issues[prop.fieldName] = {
        issueType: "type-mismatch",
        schemaType: schemaColumnType,
        datasetType: datasetColumn.desired_type,
      };
    }
  });

  // Check that all columns in the dataset are present in the schema
  dataset.input_columns.forEach((column) => {
    const schemaColumn = schema.properties.find(
      (prop) => prop.fieldName === column.name,
    );
    if (!schemaColumn) {
      issues[column.name] = {
        issueType: "extra-column",
        datasetType: column.desired_type,
      };
    }
  });

  return issues;
};

export const isDatasetCompatible = (
  dataset: Dataset,
  inputSchemas?: SchemaUIT,
): boolean => {
  const isStrictIncompatibilityEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.strictIncompatibilityCheck,
  );

  const datasetIssues = findCompatibilityIssues(dataset, inputSchemas);

  return !Object.values(datasetIssues).some(
    (issue) =>
      issue &&
      issue.issueType !== "extra-column" &&
      (isStrictIncompatibilityEnabled || issue.issueType !== "type-mismatch"),
  );
};

export const DESIRED_TYPE_ICONS: Record<DesiredType, IconProp> = TYPE_ICONS;

// This is read as "if the column type is `key` then it is compatible
// with the schema type if it is among `COMPATIBILITY_TYPE_MAP[key]` "
const COMPATIBILITY_TYPE_MAP: Record<DesiredType, DesiredType[]> = {
  string: ["any", "string"],
  boolean: ["any", "boolean"],
  number: ["any", "number"],
  integer: ["any", "number", "integer"],
  object: ["any", "object"],
  array: ["any", "array"],
  datetime: ["any", "string", "datetime"],
  date: ["any", "string", "date"],
  any: ["any"],
  enum: ["any", "enum"],
};

export const buildUiSchemas = (
  version: Pick<FlowVersionT, "input_schema" | "output_schema">,
) => ({
  input:
    version.input_schema && Object.keys(version.input_schema).length !== 0
      ? SchemaConverter.beToUI(version.input_schema, SchemaOptions.Input)
      : undefined,
  output:
    version.output_schema && Object.keys(version.output_schema).length !== 0
      ? SchemaConverter.beToUI(version.output_schema, SchemaOptions.Output)
      : undefined,
});

export const useSchemas = (
  version: Pick<FlowVersionT, "input_schema" | "output_schema"> | undefined,
) => {
  return useMemo(
    () =>
      version
        ? buildUiSchemas(version)
        : { input: undefined, output: undefined },
    [version],
  );
};

export const useInputColumnsFromSchema = (
  version: FlowVersionT | undefined,
): DatasetColumn[] => {
  const { input: inputSchema } = useSchemas(version);

  return (
    inputSchema?.properties.map((property) => ({
      name: property.fieldName,
      desired_type: schemaTypeToDesiredType(property.type[0]),
      use_subflow_mocks: false,
    })) ?? []
  );
};

export const useOutcomeColumnsFromOutcomeTypes = () => {
  const { flow } = useFlowContext();
  const { data: outcomeTypes } = useOutcomeTypes({
    flowId: flow.id,
  });

  return getOutcomeDatasetColumns(outcomeTypes ?? []).map((column) => ({
    name: column.columnName,
    desired_type: schemaTypeToDesiredType(column.property.type[0]),
    use_subflow_mocks: false,
  }));
};

export const separateFilenameExtension = (
  filename: string,
): { name: string; extension?: string } => {
  const RECOGNIZED_EXTENSIONS = ["json", "csv", "xlsx"];

  const filenameSegments = filename.split(".");

  if (filenameSegments.length < 2) {
    return { name: filename };
  }

  const possibleExtensionSegment = filenameSegments.at(-1)!;

  if (RECOGNIZED_EXTENSIONS.includes(possibleExtensionSegment.toLowerCase())) {
    return {
      name: filenameSegments.slice(0, -1).join("."),
      extension: possibleExtensionSegment,
    };
  } else {
    return { name: filename };
  }
};

export const isDatasetEmpty = (dataset: Dataset | undefined): boolean =>
  dataset?.input_columns.length === 0 &&
  dataset?.output_columns.length === 0 &&
  dataset?.mock_columns.length === 0 &&
  dataset?.outcome_columns.length === 0;

export const MOCK_EXTERNAL_DATA_DOCS_URL =
  "https://docs.taktile.com/decision-design/datasets-and-testing/editing-datasets-and-resolving-incompatibility#mock-external-data";

export type DatasetIntegrationNode = {
  id: string;
  name: string;
  provider:
    | ProviderResourceT["provider"]
    | NODE_TYPE.FLOW_NODE
    | NODE_TYPE.LOOP_NODE;
  resource: ResourceT | null;
  mediaKey: string | null;
  testingConfig: CustomConnectionConfig["testing"] | null;
  environmentsConfig: CustomConnectionConfig["environments_config"] | null;
  localSampleReports: ResourceSample[] | null;
  mockableChildNodes: DatasetIntegrationNode[] | null;
};

export const isIntegrationNodeWithLiveConnection = (
  node?: DatasetIntegrationNode,
): boolean => {
  if (node?.environmentsConfig) {
    // If environmentsConfig is present, this a CC Node with the new format
    const { authoring_mode_data_source } = node.environmentsConfig;

    return authoring_mode_data_source
      ? [
          ConnectionDataSourcesValues.production,
          ConnectionDataSourcesValues.sandbox,
        ].includes(authoring_mode_data_source)
      : false;
  }

  // For backwards compatibility on the old format of the CC node.
  return Boolean(node?.testingConfig?.use_fallback_live_connection);
};

export const DATASET_JOB_TIMEOUT =
  "We couldn't process this dataset under 15 minutes. Please try again.";
