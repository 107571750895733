import Quill from "quill";
import { createRoot } from "react-dom/client";

import { BeMappedNode } from "src/constants/NodeDataTypes";
import { NodePill } from "src/copilot/NodePill";

const Embed = Quill.import("blots/embed") as any;

const renderNodePill = (node: BeMappedNode) => {
  const container = document.createElement("span");
  container.style.display = "inline-flex";
  container.style.verticalAlign = "middle";
  const root = createRoot(container);
  root.render(<NodePill node={node} />);
  return container;
};

export class MentionBlot extends Embed {
  static blotName: string = "mention";
  static tagName: string = "span";
  static className: string = "inline-flex align-middle";

  static create(node: BeMappedNode) {
    const span = renderNodePill(node);
    span.contentEditable = "false";
    span.setAttribute("data-id", node.id);
    span.setAttribute("data-value", node.data.label);
    return span;
  }

  static value(node: HTMLElement) {
    return {
      id: node.getAttribute("data-id"),
      value: node.getAttribute("data-value"),
    };
  }
}
