import { useState } from "react";

import { useCreateRole, useDeleteRole, useUpdateUser } from "./queries";
import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { OrganizationInDB, User } from "src/clients/taktile-api";

type ChangeRole = {
  userId: string;
  userName: string;
  role: string;
  role_id?: string;
  add: boolean;
};

export const UserSettings: React.FC<{
  users: User[];
  orgs: OrganizationInDB[];
  wss: WorkspaceDataplane[];
}> = ({ users, orgs, wss }) => {
  const createRole = useCreateRole();
  const deleteRole = useDeleteRole();
  const updateUser = useUpdateUser();
  const [showServiceaccounts, setShowServiceaccounts] = useState(false);
  const [showSecretLinkUser, setShowSecretLinkUser] = useState(false);
  const [showWsServiceAccount, setShowWsServiceAccount] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const [showRoles, setShowRoles] = useState(true);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [changeRole, setChangeRole] = useState<ChangeRole | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [activationUser, setActivationUser] = useState<{
    activate: boolean;
    user: User;
  } | null>(null);

  return (
    <div className="px-1">
      <ConfirmationModal
        description={`Are you sure you want ${
          changeRole?.add ? "add" : "remove"
        } the role ${changeRole?.role} on ${changeRole?.userName}?`}
        open={showConfirmationModal}
        title="Modify Role"
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={async () => {
          if (changeRole) {
            if (changeRole.add) {
              await createRole.mutateAsync({
                user_id: changeRole.userId,
                arguments: {},
                name: changeRole.role,
              });
              setShowConfirmationModal(false);
            } else {
              await deleteRole.mutateAsync(changeRole.role_id!);
              setShowConfirmationModal(false);
            }
          }
        }}
      />
      <ConfirmationModal
        description={`Are you sure you want ${
          activationUser?.activate ? "activate" : "deactivate"
        } the user ${activationUser?.user.username}?`}
        open={showActivationModal}
        title="Modify User"
        onClose={() => setShowActivationModal(false)}
        onConfirm={async () => {
          if (activationUser) {
            let new_user = activationUser.user;
            new_user.is_active = activationUser.activate;
            await updateUser.mutateAsync(new_user);
          }
          setShowActivationModal(false);
        }}
      />
      <div className="mb-4 flex flex-row rounded-md border border-gray-200 bg-gray-200 bg-opacity-30 px-3 py-3">
        <div className="flex flex-col">
          <Label>Filter Email</Label>
          <div className="mr-8 mt-1 flex w-full flex-row items-center gap-x-4">
            <div className="flex flex-row items-center">
              <Checkbox
                checked={showServiceaccounts}
                className="mr-1"
                name="show_serviceaccounts"
                onChange={() => setShowServiceaccounts(!showServiceaccounts)}
              />
              <p className="inline-block text-gray-800 font-inter-semibold-13px">
                Show Serviceaccounts
              </p>
            </div>
            <div className="flex flex-row items-center">
              <Checkbox
                checked={showSecretLinkUser}
                className="mr-1"
                name="show_secret_link_user"
                onChange={() => setShowSecretLinkUser(!showSecretLinkUser)}
              />
              <p className="inline-block text-gray-800 font-inter-semibold-13px">
                Show Secret Link User
              </p>
            </div>
            <div className="flex flex-row items-center">
              <Checkbox
                checked={showWsServiceAccount}
                className="mr-1"
                name="show_ws_secret_link_user"
                onChange={() => setShowWsServiceAccount(!showWsServiceAccount)}
              />
              <p className="inline-block text-gray-800 font-inter-semibold-13px">
                Show WS Service Account
              </p>
            </div>
            <div className="flex flex-row items-center">
              <Checkbox
                checked={showInactive}
                className="mr-1"
                name="show_inactive"
                onChange={() => setShowInactive(!showInactive)}
              />
              <p className="inline-block text-gray-800 font-inter-semibold-13px">
                Show Inactive Users
              </p>
            </div>
            <div className="flex flex-row items-center">
              <Checkbox
                checked={showRoles}
                className="mr-1"
                name="show_roles"
                onChange={() => setShowRoles(!showRoles)}
              />
              <p className="inline-block text-gray-800 font-inter-semibold-13px">
                Show Roles
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center gap-x-4">
          <div className="items-left flex flex-col gap-y-0">
            <Label>Filter Email</Label>
            <Input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="items-left flex flex-col gap-y-0">
            <Label>Filter Name</Label>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="table-auto rounded-lg border border-gray-50 bg-white py-2 shadow-base">
        <div>
          <div className="leading-right sticky top-0 z-10 flex flex-row bg-white font-inter-medium-12px">
            <div className="w-[18%]">
              <p className="px-4 py-2">Username</p>
            </div>
            <div className="w-[22%]">
              <p className="px-4 py-2">Email</p>
            </div>
            <div className="w-[5%]">
              <p className="px-4 py-2">Active</p>
            </div>
            <div className="w-[5%]">
              <p className="px-4 py-2">Super User</p>
            </div>
            <div className="w-[5%]">
              <p className="px-4 py-2">Taktile Admin</p>
            </div>
            <div className="w-[10%]">
              <p className="px-4 py-2">FWD</p>
            </div>
            <div className="w-[5%]">
              <p className="px-4 py-2">FWD Viewer</p>
            </div>
            {showRoles && (
              <div className="w-[25%]">
                <p className="px-4 py-2">Roles</p>
              </div>
            )}
          </div>
        </div>
        <div>
          {users
            .filter(
              (u) =>
                showServiceaccounts ||
                !(
                  u.username?.startsWith("__service_account__") ||
                  u.username?.startsWith("__decide_service_account__")
                ),
            )
            .filter(
              (u) =>
                showSecretLinkUser ||
                !u.username?.startsWith("__taktile-secret-link-user__"),
            )
            .filter(
              (u) =>
                showWsServiceAccount ||
                !u.username?.startsWith("__ws_service_account__"),
            )
            .filter((u) => showInactive || u.is_active)
            .filter((u) => email === "" || u.email?.includes(email))
            .filter((u) => name === "" || u.username?.includes(name))
            .map((user) => (
              <div className="mx-2 flex w-full flex-row border-b border-gray-200">
                <div className="w-[18%] border-r border-gray-200 px-2 pb-1 pt-2">
                  <p className="text-wrap break-words font-inter-normal-12px">
                    {user.username}
                  </p>
                </div>
                <div className="w-[22%] border-r border-gray-200 px-2 pb-1 pt-2">
                  <p className="font-inter-normal-12px">{user.email}</p>
                </div>
                <div className="w-[5%] border-r border-gray-200 px-2 pb-1 pt-2">
                  <Checkbox
                    checked={user.is_active}
                    onChange={() => {
                      setShowActivationModal(true);
                      setActivationUser({
                        activate: !user.is_active,
                        user: user,
                      });
                    }}
                  />
                </div>
                <div className="w-[5%] border-r border-gray-200 px-2 pb-1 pt-2">
                  <Checkbox checked={user.is_superuser} disabled />
                </div>
                <div className="w-[5%] border-r border-gray-200 px-2 pb-1 pt-2">
                  <Checkbox
                    checked={
                      !!user.roles?.find((r) => r.name === "taktile_d_admin")
                    }
                    onChange={() => {
                      if (user.email?.endsWith("@taktile.com")) {
                        setShowConfirmationModal(true);
                        setChangeRole({
                          userName: user.username ?? "unknown",
                          userId: user.id,
                          role: "taktile_d_admin",
                          add: !user.roles?.find(
                            (r) => r.name === "taktile_d_admin",
                          ),
                        });
                      } else {
                        alert(
                          "Only users with taktile.com email can be made admin",
                        );
                      }
                    }}
                  />
                </div>
                <div className="w-[10%] border-r border-gray-200 px-2 pb-1 pt-2">
                  {user.roles
                    ?.filter((r) => r.name === "fwd_d")
                    .map((r) => (
                      <div>
                        <p className="font-inter-normal-12px">
                          {
                            orgs.find((o) => o.id === r.arguments["org_id"])
                              ?.name
                          }
                        </p>
                      </div>
                    ))}
                </div>
                <div className="w-[5%] border-r border-gray-200 px-2 pb-1 pt-2">
                  {user.roles
                    ?.filter((r) => r.name === "fwd_viewer")
                    .map((r) => (
                      <div>
                        <p className="font-inter-normal-12px">
                          {
                            orgs.find((o) => o.id === r.arguments["org_id"])
                              ?.name
                          }
                        </p>
                      </div>
                    ))}
                </div>
                {showRoles && (
                  <div className="w-[25%] px-2 pb-1 pt-2">
                    {user.roles?.map((r) => (
                      <div className="border-px m-2 flex flex-row justify-between rounded-md border border-gray-200 border-opacity-60 bg-gray-100 p-2 pl-2 pr-1">
                        <div>
                          <div>
                            <p className="max-w-[40px] font-inter-medium-13px">
                              {r.name}
                            </p>
                          </div>
                          <div>
                            {Object.entries(r.arguments ?? {})
                              .map(([k, v]) =>
                                k === "org_id"
                                  ? [
                                      "org",
                                      orgs.find((o) => o.id === v)?.name ??
                                        "N/A",
                                    ]
                                  : k === "ws_id"
                                    ? [
                                        "ws",
                                        wss.find((w) => w.id === v)?.name ??
                                          "N/A",
                                      ]
                                    : [k, v],
                              )
                              .map(([k, v]) => (
                                <div>
                                  <p className="!text-gray-600 font-inter-normal-12px">
                                    {k}: {v}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="px-2">
                          <Button
                            size="sm"
                            variant="secondary"
                            onClick={() => {
                              setShowConfirmationModal(true);
                              setChangeRole({
                                userName: user.username ?? "unknown",
                                userId: user.id,
                                role: r.name,
                                role_id: r.id,
                                add: false,
                              });
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
