import React, { useMemo } from "react";

import { FlowDbShallow } from "src/clients/flow-api";
import { Modal } from "src/design-system/Modal";
import { RelatedFlowList } from "src/flow/modals/RelatedFlowList";
import { groupRelatedFlows } from "src/flow/modals/utils";
import { useFlowContext } from "src/router/routerContextHooks";

export type Props = {
  open: boolean;
  onClose: () => void;
  afterLeave: () => void;
  title: string;
  subtitle: string;
  relatedFlows: FlowDbShallow[] | undefined;
};

export const RelatedFlowsModal: React.FC<Props> = ({
  open,
  onClose,
  afterLeave,
  title,
  subtitle,
  relatedFlows,
}) => {
  const { workspace } = useFlowContext();
  const [_, groupedChildFlows] = useMemo(
    () => groupRelatedFlows(relatedFlows ?? []),
    [relatedFlows],
  );

  return (
    <Modal afterLeave={afterLeave} open={open} onClose={onClose}>
      <Modal.Header description={subtitle}>{title}</Modal.Header>
      <Modal.Content>
        <RelatedFlowList
          groupedRelatedFlows={groupedChildFlows}
          workspace={workspace}
        />
      </Modal.Content>
      <Modal.Footer primaryButton={false} secondaryButton="Cancel" />
    </Modal>
  );
};
