import { faClock, faTimes } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";

import {
  DecisionHistoryRecordV2,
  useHistoryDecisionV2,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowT } from "src/api/flowTypes";
import { useFlow } from "src/api/queries";
import { FloatingWindowsProvider } from "src/base-components/FloatingWindow/FloatingWindowsProvider";
import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { Pill } from "src/base-components/Pill";
import { Tabs } from "src/base-components/Tabs";
import { HistoricalDecisionV3 } from "src/clients/history-v3";
import { DecisionFields } from "src/decisionsOverview/DecisionFields";
import { ErrorTabContent } from "src/decisionsOverview/ErrorTabContent";
import { OutcomesTabContent } from "src/decisionsOverview/OutcomesTabContent";
import { PendingOutputTabContent } from "src/decisionsOverview/PendingOutputTabContent";
import { TraceDecisionButton } from "src/decisionsOverview/TraceDecisionButton";
import { hasInputSchemaError } from "src/decisionsOverview/utils";
import { ExcludesFalse } from "src/flow/types";

type Props = {
  baseUrl: string;
  isOpen: boolean;
  decision: HistoricalDecisionV3 | null;
  onClose: () => void;
  widthClass: string;
};

const Header: React.FC<Pick<Props, "decision" | "onClose">> = ({
  decision,
  onClose,
}) => {
  const flowQuery = useFlow(decision?.flow.id);

  return (
    <div className="flex w-full items-center justify-between px-4">
      <h3 className="flex items-center gap-x-1 font-inter-semibold-13px">
        Decision ID
        <Pill dataLoc="decision-id-pill" size="sm" variant="gray" maxWidth>
          <Pill.Text fontType="code">{decision?.id ?? "-"}</Pill.Text>
        </Pill>
      </h3>
      <LoadingView
        queryResult={flowQuery}
        renderUpdated={(flow: FlowT) =>
          decision ? (
            <div className="ml-3">
              <TraceDecisionButton decision={decision} flow={flow} />
            </div>
          ) : (
            <></>
          )
        }
        renderUpdating={() => <></>}
      />
      <div className="flex gap-x-1.5 text-gray-500">
        <Icon
          dataLoc="webhook-logs-close"
          icon={faTimes}
          size="xs"
          onClick={onClose}
        />
      </div>
    </div>
  );
};

const TabContent: React.FC<{
  id: string;
  type: "request" | "response";
}> = ({ id, type }) => {
  return (
    <div className="px-4">
      <DecisionFields decisionId={id} type={type} />
    </div>
  );
};

export type DecisionRequest = { data: Record<string, any> };

export const DecisionsSidepane: React.FC<Props> = ({
  baseUrl,
  isOpen,
  decision,
  onClose,
  widthClass,
}) => {
  const [activeTab, setActiveTab] = useState<string>("output-data-tab");
  const { data: decisionV2Fetch } = useHistoryDecisionV2({
    baseUrl: baseUrl,
    decisionId: decision?.id!,
  });
  // reset active tab when decision changes
  useEffect(() => {
    setActiveTab("output-data-tab");
  }, [decision]);
  const decisionV2:
    | (DecisionHistoryRecordV2 & {
        is_error: boolean;
      })
    | undefined = decisionV2Fetch?.isQueryError
    ? undefined
    : decisionV2Fetch?.decision;
  const isPendingDecision = decision?.status_code === "202";
  const isInputSchemaError = decisionV2
    ? hasInputSchemaError(decisionV2)
    : false;
  const tabs = [
    {
      label: "Input",
      key: "input-data-tab",
      content:
        decisionV2?.is_error && isInputSchemaError ? (
          <ErrorTabContent decision={decisionV2} />
        ) : decisionV2 && decisionV2.request ? (
          <TabContent id={decisionV2.id} type="request" />
        ) : null,
    },
    !isInputSchemaError && {
      label: (
        <span className="flex items-center gap-x-1.5">
          Output
          {isPendingDecision && (
            <Icon color="text-gray-500" icon={faClock} size="xs" />
          )}
        </span>
      ),
      key: "output-data-tab",
      content:
        decision?.status_code === "200" && !decisionV2?.is_error ? (
          <TabContent id={decision.id} type="response" />
        ) : isPendingDecision && decisionV2 ? (
          <PendingOutputTabContent decision={decisionV2} />
        ) : decisionV2?.is_error ? (
          <ErrorTabContent decision={decisionV2} />
        ) : null,
    },
    {
      label: "Outcomes",
      key: "outcomes-data-tab",
      content: (
        <OutcomesTabContent
          decisionId={decisionV2?.id}
          flowId={decisionV2?.flow.id}
        />
      ),
    },
  ].filter(Boolean as unknown as ExcludesFalse);

  return (
    <FloatingWindowsProvider>
      <div
        className={twJoin(
          "right-0 top-[49px] ml-auto flex h-[calc(100vh-49px)] flex-col border-l border-gray-200 bg-white py-4 transition-opacity",
          widthClass,
          isOpen ? "fixed" : "hidden",
        )}
        data-loc="decision-sidepane"
      >
        <Header decision={decision} onClose={onClose} />
        <hr className="mx-4 mb-2 mt-4 border-gray-100" />
        <Tabs
          containerClassName="flex h-full flex-col overflow-hidden"
          panelsClassName="decideScrollbar flex-1 overflow-y-auto flex flex-col"
          selectedKey={isInputSchemaError ? "input-tab-data" : activeTab}
          tabListClassName="mx-4 border-b border-gray-100 mb-6 sticky bg-white"
          tabs={tabs}
          onChange={setActiveTab}
        />
      </div>
    </FloatingWindowsProvider>
  );
};
