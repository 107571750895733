import React from "react";

import { useOrganizationUser } from "src/api/taktile/queries";
import { LoadingView } from "src/base-components/LoadingView";
import { OrganizationUser } from "src/clients/taktile-api";
import { Avatar } from "src/design-system/Avatar";
import { AuthorPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type Props = {
  userId: string;
  withAvatar?: boolean;
};

export const UserNameInfo: React.FC<Props> = ({
  userId,
  withAvatar = false,
}) => {
  const { orgId } = useParamsDecode<AuthorPageParamsT>();
  const workspaceUsers = useOrganizationUser(orgId, userId);
  return (
    <LoadingView
      queryResult={workspaceUsers}
      renderErrored={() => <></>}
      renderUpdated={(user: OrganizationUser) => {
        return user ? (
          <div className="inline-flex flex-row items-center">
            {withAvatar && (
              <div className="mr-1.5">
                <Avatar size="lg" user={user} />
              </div>
            )}
            {user.username}
          </div>
        ) : (
          <></>
        );
      }}
      renderUpdating={() => <></>}
    />
  );
};
