import { faRefresh, faTimes } from "@fortawesome/pro-regular-svg-icons";
import React, { useCallback, useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useDebounceValue } from "usehooks-ts";

import { taktileInternalPrefix } from "src/api/constants";
import { useHistoryDecisionsV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { useFlowBySlug } from "src/api/queries";
import { DecisionEnvironment } from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { SearchBox } from "src/base-components/SearchBox";
import { Webhook } from "src/clients/flow-api";
import { useFlowContext } from "src/router/routerContextHooks";
import { DecisionHistoryTable } from "src/webhooks/HistorySidebar/DecisionHistoryTable";
import { OutgoingWebhookResponse } from "src/webhooks/queries";

export const WEBHOOK_FLOW_SLUG = `${taktileInternalPrefix}webhooks`;
export const WEBHOOK_FLOW_ENV = DecisionEnvironment.SANDBOX;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  webhook: Webhook | null;
};

export const HistorySidebar: React.FC<Props> = ({
  webhook,
  isOpen,
  onClose,
}) => {
  const { workspace } = useFlowContext();
  const [searchTerm, _setSearchTerm] = useState("");
  const [debouncedSearchTerm, _setDebouncedSearchTerm] = useDebounceValue(
    searchTerm,
    250,
  );
  const setSearchTerm = useCallback(
    (value: string) => {
      _setSearchTerm(value);
      _setDebouncedSearchTerm(value);
    },
    [_setSearchTerm, _setDebouncedSearchTerm],
  );
  const versionQuery = useFlowBySlug(workspace.id, WEBHOOK_FLOW_SLUG, {
    refetchOnMount: true,
    versionNameFilter: webhook?.key,
  });

  const webhookFlowVersion = versionQuery.data?.versions.find(
    (version) => webhook?.key === version.name,
  );

  const decisionHistory = useHistoryDecisionsV2<OutgoingWebhookResponse>({
    baseUrl: workspace.base_url!,
    flowSlug: WEBHOOK_FLOW_SLUG,
    include_request_data: true,
    include_response_data: true,
    filters: {
      environment: WEBHOOK_FLOW_ENV,
      fields: {
        flow_version_id: webhookFlowVersion?.id,
        entity_id: debouncedSearchTerm || null,
      },
    },
    enabled: webhookFlowVersion?.id !== undefined,
  });

  useEffect(() => {
    if (!isOpen) {
      setSearchTerm("");
    }
  }, [isOpen, setSearchTerm]);

  useEffect(() => {
    setSearchTerm("");
  }, [setSearchTerm, webhook]);

  return (
    <div
      className={twJoin(
        "right-0 top-[102px] ml-auto flex h-[calc(100vh-102px)] w-[509px] flex-col gap-y-4 border-l border-gray-200 bg-white py-4 transition-opacity",
        isOpen ? "fixed" : "hidden",
      )}
    >
      <div className="flex w-full items-center justify-between px-6">
        <h3 className="font-inter-semibold-16px">Logs</h3>
        <div className="flex gap-x-1.5 text-gray-500">
          <Icon
            dataLoc="webhook-logs-refresh"
            icon={faRefresh}
            size="sm"
            onClick={() => decisionHistory.refetch()}
          />
          <Icon
            dataLoc="webhook-logs-close"
            icon={faTimes}
            size="base"
            onClick={onClose}
          />
        </div>
      </div>
      <hr className="mx-6 border-gray-100" />
      <div className="px-6">
        <SearchBox
          dataLoc="webhook-logs-search"
          placeholder="Search by Decision ID"
          value={searchTerm}
          onChange={(value) => setSearchTerm(value)}
        />
      </div>
      <hr className="mx-6 border-gray-100" />
      {webhook && webhookFlowVersion && (
        <DecisionHistoryTable
          decisionHistory={decisionHistory}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          webhook={webhook}
        />
      )}
    </div>
  );
};
