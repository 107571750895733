import axios from "axios";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import slugify from "slugify";

import { FlowT } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Textarea } from "src/base-components/Textarea";
import { FlowReviewConfiguration } from "src/clients/flow-api";
import { WorkspaceSimpleRoleType } from "src/clients/taktile-api";
import { Modal } from "src/design-system/Modal";
import { NameInput } from "src/flowsOverview/FlowNameInput";
import { FlowReviewConfigEditor } from "src/flowsOverview/FlowReviewConfigEditor";
import { FlowReviewConfigPreview } from "src/flowsOverview/FlowReviewConfigPreview";
import { SlugInput } from "src/flowsOverview/FlowSlugInput";
import { DecisionFlowInputs } from "src/flowsOverview/types";
import {
  FolderDropdownSelection,
  SelectFolderDropdown,
} from "src/flowsOverview/v2/SelectFolderDropdown";
import { FOLDER_NAME_NOT_AVAILABLE_MESSAGE } from "src/flowsOverview/v2/folderModals/EditFolderModal";
import { useFolders } from "src/flowsOverview/v2/folderQueries";
import { useCapabilities } from "src/hooks/useCapabilities";
import {
  nameValidations,
  slugValidations,
} from "src/layout/WorkspaceFormModal/utils";
import { logger } from "src/utils/logger";

export type EditFlowModalOutputsT = {
  name: string;
  wsId: string;
  slug: string;
  description?: string;
  orgId: string;
  folderSelection: FolderDropdownSelection;
  reviewConfig?: FlowReviewConfiguration;
};

export type EditFlowModalPropsT = {
  open: boolean;
  onClose: () => void;
  onConfirm: (outputs: EditFlowModalOutputsT) => Promise<void>;
  onUnmount?: () => void;
  title: string;
  flow?: FlowT;
  workspaceId: string;
  organizationId: string;
  initialFolderId?: string;
  mode: "add" | "edit";
};

export const EditFlowModal: React.FC<EditFlowModalPropsT> = ({
  open,
  onClose,
  onConfirm,
  onUnmount,
  title,
  flow,
  mode,
  workspaceId,
  organizationId,
  initialFolderId,
}) => {
  const [submitError, setSubmitError] = useState<boolean>(false);
  const folders = useFolders({ workspaceId });
  const { workspaceRole } = useCapabilities();

  const {
    control,
    register,
    handleSubmit,
    formState,
    reset,
    setError,
    setValue,
    ...formMethods
  } = useForm<DecisionFlowInputs>({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    // Everytime the Modal is reopened with a new flow we need to reset the form to set the new default value
    if (open) {
      reset({
        name: flow?.name,
        description: flow?.meta?.description,
        slug: flow?.slug,
        selectFolder: {
          mode: "selectFolder",
          folderId: mode === "edit" ? flow?.flow_folder_id : initialFolderId,
        },
        folderName: undefined,
        reviewConfig: flow?.review_configuration ?? {
          requires_review: false,
          default_reviewer_list: [],
          require_all_reviewers: false,
        },
      });
    }
  }, [reset, flow, open, initialFolderId, mode]);

  useEffect(() => onUnmount, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onModalClose = () => {
    setSubmitError(false);
    onClose();
  };

  const onModalConfirm = async (
    name: string,
    slug: string,
    description: string | undefined,
    folderSelection: FolderDropdownSelection,
    reviewConfig: FlowReviewConfiguration,
  ) => {
    await onConfirm({
      name,
      wsId: workspaceId,
      slug,
      description,
      orgId: organizationId,
      folderSelection,
      reviewConfig,
    });
    onModalClose();
  };

  const onSubmit = handleSubmit(async (data: DecisionFlowInputs) => {
    try {
      await onModalConfirm(
        data.name,
        data.slug,
        data.description,
        data.selectFolder,
        data.reviewConfig,
      );
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.config?.url?.includes("/folders")) {
          setError(
            "selectFolder",
            { type: "notAvailable" },
            { shouldFocus: true },
          );
        } else if (e.config?.url?.includes("/flows")) {
          setError("slug", { type: "notAvailable" }, { shouldFocus: true });
        }
      } else {
        logger.error(e);
        setSubmitError(true);
      }
    }
  });

  return (
    <Modal open={open} size="sm" onClose={onModalClose}>
      <Modal.Header>{title}</Modal.Header>
      <form onSubmit={onSubmit}>
        <Modal.Content>
          <NameInput
            error={formState.errors.name}
            formProps={register("name", {
              required: true,
              validate: nameValidations,
              onChange: (e) => {
                if (mode === "add") {
                  setValue(
                    "slug",
                    slugify(e.target.value, { strict: true, lower: true }),
                    {
                      shouldValidate: true,
                    },
                  );
                }
              },
            })}
          />
          <SlugInput
            error={formState.errors.slug}
            formProps={register("slug", {
              required: mode === "add",
              disabled: mode === "edit",
              minLength: 4,
              maxLength: 20,
              validate: slugValidations,
            })}
          />
          <div className="mb-1 mt-3">
            <p className="text-gray-800 font-inter-semibold-13px">Folder</p>
            {formState.errors.selectFolder && (
              <ErrorHint margin="none">
                {FOLDER_NAME_NOT_AVAILABLE_MESSAGE}
              </ErrorHint>
            )}
          </div>
          <Controller
            control={control}
            name="selectFolder"
            render={({ field }) => (
              <SelectFolderDropdown
                folders={folders.data?.folders || []}
                selected={field.value || undefined}
                onSelect={(value) => {
                  if (
                    value.mode === "selectFolder" &&
                    field.value?.mode === "selectFolder" &&
                    field.value?.folderId === value.folderId
                  ) {
                    field.onChange(null);
                  } else {
                    field.onChange(value);
                  }
                }}
              />
            )}
          />
          <div className="mb-2 mt-3 text-gray-800 font-inter-semibold-13px">
            Description
          </div>
          <Textarea
            autoComplete="off"
            data-loc="add-description"
            placeholder="Enter description"
            {...register("description")}
          />
          <FormProvider
            {...formMethods}
            control={control}
            formState={formState}
            handleSubmit={handleSubmit}
            register={register}
            reset={reset}
            setError={setError}
            setValue={setValue}
          >
            {workspaceRole === WorkspaceSimpleRoleType.ADMIN && (
              <FlowReviewConfigEditor />
            )}
            {workspaceRole === WorkspaceSimpleRoleType.EDITOR &&
              flow?.review_configuration && (
                <FlowReviewConfigPreview
                  isEditingFlow={Boolean(flow)}
                  reviewConfig={flow.review_configuration}
                />
              )}
          </FormProvider>
          {submitError && (
            <ErrorHint className="mb-2 mr-2">
              Error submitting the data - please try again
            </ErrorHint>
          )}
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="add-flow-save"
              disabled={!isEmpty(formState.errors)}
              htmlType="submit"
              loading={formState.isSubmitting}
              variant="primary"
            >
              {mode === "add" ? "Create" : "Save"}
            </Button>
          }
          secondaryButton="Cancel"
        />
      </form>
    </Modal>
  );
};
