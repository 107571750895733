import { faTrashCan, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { map } from "lodash";
import React from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { FieldErrorsT } from "src/api/types";
import { Button } from "src/base-components/Button";
import { InputMappingRow } from "src/integrationNode/editorComponents/InputMappingRow";
import {
  FIELD_ARRAY_KEY_NAME,
  groupClassName,
} from "src/integrationNode/editorComponents/InputMappings";
import { InputMappingListT, InputMappingsT } from "src/integrationNode/types";

export type PropsT = {
  list: InputMappingListT;
  listName: string;
  formProps: UseFormReturn<InputMappingsT>;
  groupedGroupsAndLists?: boolean;
  runFieldErrors?: FieldErrorsT;
  immutable: boolean;
};

export const InputMappingList: React.FC<PropsT> = ({
  list,
  listName,
  formProps,
  groupedGroupsAndLists,
  runFieldErrors,
  immutable,
}) => {
  const { fields, append, remove } = useFieldArray({
    control: formProps.control,
    name: `lists.${listName}.elements`,
    keyName: FIELD_ARRAY_KEY_NAME,
  });
  const { rules, elements, displayName, getDefaultElement } = list;
  const shouldDisableDeletion =
    (rules?.required && elements.length <= 1) ||
    (!!rules?.minLength && elements.length <= rules.minLength);

  const shouldDisableAddition =
    !!rules?.maxLength && elements.length >= rules.maxLength;

  return (
    <>
      {fields.map((element, index) => (
        <div
          key={element.reactHookFormsKey}
          className={twMerge(
            groupClassName,
            "text-gray-700 font-inter-normal-12px",
          )}
        >
          <div className="mb-4 flex items-center justify-between border-b border-gray-200 pb-2">
            {`${displayName.toUpperCase()} ${index + 1}`}
            <Button
              disabled={immutable || shouldDisableDeletion}
              iconLeft={faTrashCan}
              size="sm"
              variant="secondary"
              onClick={() => remove(index)}
            >
              Delete {displayName.toLowerCase()}
            </Button>
          </div>
          {map(element, (inputMapping, beName) => {
            if (beName !== FIELD_ARRAY_KEY_NAME) {
              return (
                <InputMappingRow
                  key={beName}
                  beName={`${listName}.${beName}`}
                  formControl={formProps.control}
                  immutable={immutable}
                  inputMapping={inputMapping}
                  inputProps={formProps.register(
                    `lists.${listName}.elements.${index}.${beName}.assignedTo`,
                  )}
                  runFieldError={runFieldErrors?.[inputMapping.id]}
                />
              );
            }
            return null;
          })}
        </div>
      ))}
      {!groupedGroupsAndLists && (
        <span className="mb-4 mr-1 inline-block">
          <Button
            dataLoc={`add-list-${listName}`}
            disabled={immutable || shouldDisableAddition}
            iconLeft={faPlus}
            size="sm"
            variant="secondary"
            onClick={() => append(getDefaultElement())}
          >
            Add {displayName}
          </Button>
        </span>
      )}
    </>
  );
};
