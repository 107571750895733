export const OUTCOME_KEY_FILTERS = [
  {
    key: "exists",
    value: "Reported",
  },
  {
    key: "not_exists",
    value: "Not reported",
  },
];

export const BOOLEAN_FILTERS = [
  {
    key: "true",
    value: "True",
  },
  {
    key: "false",
    value: "False",
  },
];
