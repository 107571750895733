import { isKeyboardEvent } from "@dnd-kit/utilities";
import {
  faAsterisk,
  faBracketsCurly,
  faBracketsSquare,
  faChevronDown,
  faDiagramNext,
  faPlug,
} from "@fortawesome/pro-regular-svg-icons";
import { faRotateRight, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { Column, Row } from "@tanstack/react-table";
import { capitalize } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";
import { useEventListener } from "usehooks-ts";

import { EnumOptionsBET } from "src/api/flowTypes";
import { DatasetRow, DesiredType } from "src/api/types";
import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { PonEditor } from "src/base-components/PONEditor";
import { Pill } from "src/base-components/Pill";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { RowUpdateFunction } from "src/datasets/DatasetTable/DatasetEditTable";
import { FloatingWindow } from "src/datasets/DatasetTable/FloatingWindow";
import { getIcon } from "src/datasets/DatasetTable/cells";
import {
  useDatasetEditTableActions,
  useIsEditingCell,
  useIsSelectedCell,
} from "src/datasets/DatasetTable/stores";
import { JSONValue } from "src/datasets/DatasetTable/types";
import { useCustomScrollEmitter } from "src/datasets/DatasetTable/useCustomScroll";
import {
  CellId,
  SUB_COLUMN_SEPARATOR,
  isFlowIntegrationNode,
  isLoopIntegrationNode,
  jsonToPon,
  ponToJson,
  validateCellValue,
} from "src/datasets/DatasetTable/utils";
import {
  ValidationResult,
  validateAny,
} from "src/datasets/DatasetTable/validators";
import { DatasetIntegrationNode, DatasetIssue } from "src/datasets/utils";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { copyTextToClipboard } from "src/utils/clipboard";
import { logger } from "src/utils/logger";

const STICKY_HEADER_OFFSET = 65;

type EditableCellProps = {
  onChange: RowUpdateFunction;
  value: JSONValue | undefined;
  row: Row<DatasetRow>;
  column: Column<DatasetRow>;
  desiredType: DesiredType;
  columnIssue?: DatasetIssue;
  nullable: boolean;
  integrationNode?: DatasetIntegrationNode;
  required: boolean;
  disabled: boolean;
  cellId: CellId;
  enumValues?: EnumOptionsBET | null;
};

const forgivingStringify = (value: JSONValue | undefined) =>
  JSON.stringify(value) ?? "";

const renderColumnName = (name: string) => {
  if (name.includes(SUB_COLUMN_SEPARATOR)) {
    return name.split(SUB_COLUMN_SEPARATOR)[1];
  } else {
    return name;
  }
};

export const EditableCell: React.FC<EditableCellProps> = ({
  onChange,
  value: propValue,
  row,
  column,
  desiredType,
  columnIssue,
  nullable,
  required,
  integrationNode,
  disabled,
  cellId,
  enumValues,
}) => {
  const cellRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const selected = useIsSelectedCell(cellId);
  const editing = useIsEditingCell(cellId);

  const { reset, enableEditingMode } = useDatasetEditTableActions();

  const useJsonEditor = ["object", "array", "any"].includes(desiredType);

  const [value, setValue] = useState(() =>
    jsonToPon(forgivingStringify(propValue)),
  );
  const [error, setError] = useState<ValidationResult>(undefined);

  useEffect(() => {
    setValue(jsonToPon(forgivingStringify(propValue)));
  }, [propValue]);

  useEffect(() => {
    setError(
      validateCellValue(ponToJson(value), {
        type: desiredType,
        nullable,
        required,
        enumValues,
      }),
    );
    // We want to update the error state if the requirements
    // of the column change but we don't want to do it
    // every time value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desiredType, nullable, required]);

  useEffect(() => {
    if (selected && !editing && cellRef.current) {
      cellRef.current.focus();
      cellRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
        block: "nearest",
      });
    }
  }, [selected, editing]);

  useEffect(() => {
    if (editing && inputRef.current && !useJsonEditor) {
      const inputEl = inputRef.current;
      inputEl.focus();
    }
  }, [editing, useJsonEditor]);

  // Put the cursor between the quotes
  // when building a string
  useEffect(() => {
    if (editing && value === '""') {
      inputRef.current?.setSelectionRange(1, 1);
    }
  }, [editing, value]);

  /*
   * If the cell type is string we want to start a string
   * for the user on cell focus
   */
  useEffect(() => {
    if (desiredType === "string" && editing && value.trim() === "") {
      setValue('""');
    }
    // Deliberately miss value here so its only run on cell focus
    // and not if the user removes the entire content of the cell
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing, desiredType]);

  useEventListener("paste", (e) => {
    if (selected && !editing) {
      e.preventDefault();

      const newValue = e.clipboardData?.getData("text");

      if (newValue) {
        setValue(newValue);
        validateAndSave(newValue);
      }
    }
  });

  useEventListener("copy", (e) => {
    if (selected && !editing) {
      e.preventDefault();

      e.clipboardData?.setData("text", ponToJson(value));
    }
  });

  useEventListener("cut", (e) => {
    if (selected && !editing) {
      e.preventDefault();

      const valueToCopy = ponToJson(value);

      if (e.clipboardData) {
        e.clipboardData.setData("text", valueToCopy);

        setValue("");
        validateAndSave("");
      }
    }
  });

  useEventListener("keydown", (event) => {
    if (
      selected &&
      !editing &&
      event.key.length === 1 &&
      event.key.match(/[a-zA-Z0-9!@#$%^&*()+=._-]/) &&
      !event.shiftKey &&
      !event.ctrlKey &&
      !event.altKey &&
      !event.metaKey
    ) {
      enableEditingMode();
    }
  });

  const validateAndSave = useCallback(
    (ponValue: string) => {
      const jsonValue = ponToJson(ponValue);
      const error = validateCellValue(jsonValue, {
        type: desiredType,
        nullable,
        required,
        enumValues,
      });
      setError(error);

      try {
        if (jsonValue.trim() === "") {
          onChange(row, column.id, undefined);
        } else {
          onChange(row, column.id, JSON.parse(jsonValue));
          setValue(jsonToPon(jsonValue));
        }
      } catch (e) {
        setError({
          message: "Invalid JSON",
        });
        logger.log(e);
      }
    },
    [desiredType, nullable, required, enumValues, onChange, row, column.id],
  );

  const onCopy = async () => {
    try {
      const json = ponToJson(value);
      const isInvalid = validateAny(json);

      if (!isInvalid) {
        await copyTextToClipboard(json);
        toastActions.success({
          title: "Copied JSON to clipboard",
        });
      } else {
        toastActions.failure({
          title: "Failed to copy JSON to clipboard",
          description: "Value is not valid JSON",
        });
      }
    } catch (e) {
      logger.error(e);
      toastActions.failure({
        title: "Failed to copy JSON to clipboard",
      });
    }
  };

  const wrapWithPopoverIfUsesEditor = (children: JSX.Element) => {
    if (!useJsonEditor || disabled) {
      return children;
    } else {
      return (
        <FloatingWindow
          button={children}
          isOpen={editing}
          title={
            <>
              <div className="flex items-start gap-x-1.5">
                <Pill size="sm" variant="gray">
                  <Pill.Text>#{row.index + 1}</Pill.Text>
                </Pill>
                <Pill size="sm" variant="gray">
                  {integrationNode ? (
                    <Pill.Icon
                      icon={
                        isLoopIntegrationNode(integrationNode)
                          ? faRotateRight
                          : isFlowIntegrationNode(integrationNode)
                            ? faDiagramNext
                            : faPlug
                      }
                    />
                  ) : (
                    <Pill.Icon
                      icon={getIcon(
                        column.columnDef.meta?.archetype?.desiredType ?? "any",
                      )}
                    />
                  )}
                  <Pill.Text>
                    {renderColumnName(
                      column.columnDef.meta?.archetype?.name ?? "",
                    )}
                  </Pill.Text>
                </Pill>
              </div>
            </>
          }
          onClose={() => reset("editingCellId")}
          onCopy={onCopy}
        >
          <div className="flex h-full flex-1 flex-col gap-y-1.5">
            {integrationNode?.provider === NODE_TYPE.LOOP_NODE && (
              <p className="text-gray-500 font-inter-normal-12px">
                For each iteration in the loop, mock response below will be
                used.
              </p>
            )}
            <PonEditor
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
                validateAndSave(newValue);
              }}
            />
          </div>
        </FloatingWindow>
      );
    }
  };

  useCustomScrollEmitter(inputRef, cellRef, { editing });

  return wrapWithPopoverIfUsesEditor(
    <div
      ref={cellRef}
      className={twMerge(
        "h-full w-full max-w-full rounded-none text-gray-800 font-inter-normal-12px",
        "flex items-center justify-between gap-x-1.5 px-1.5",
        "group",
        columnIssue?.issueType === "type-mismatch" &&
          "outline outline-1 outline-yellow-100 group-[:not(.is-empty)]:bg-orange-50",
        disabled &&
          "text-gray-500 outline outline-1 outline-gray-100 group-[:not(.is-empty)]:bg-gray-50",
        error && value && "bg-red-50",
        "outline-0",
        selected && "bg-indigo-50 outline outline-1 outline-indigo-500",
        !editing && "select-none",
      )}
      data-loc="dataset-table-cell"
      style={{
        scrollMarginTop: `${STICKY_HEADER_OFFSET}px`,
        scrollMarginBottom: `1px`,
      }}
      tabIndex={-1}
    >
      {useJsonEditor ? (
        <JSONPill
          type={Array.isArray(propValue) ? "array" : typeof propValue}
          value={value}
        />
      ) : (
        <input
          ref={inputRef}
          className={twJoin(
            "h-full w-full bg-transparent py-1.5 outline-none",
            "placeholder:invisible placeholder:text-gray-500 group-[.is-empty]:placeholder:visible",
            (disabled || !editing) && "pointer-events-none select-none",
            !editing && "truncate",
          )}
          disabled={disabled}
          placeholder="Enter value"
          type="text"
          value={value}
          onBlur={() => {
            validateAndSave(value);
          }}
          onInput={(e) => {
            // If we come from an empty cell, we auto-close the quote
            if (
              (e.nativeEvent as InputEvent).data === '"' &&
              value.trim() === ""
            ) {
              setValue('""');
            } else {
              setValue(e.currentTarget.value);
            }
            setError(undefined);
          }}
          onSelectCapture={(e) => {
            // Can be different types of events
            // We're looking only for keyboard events and Enter key
            if (
              isKeyboardEvent(e.nativeEvent) &&
              e.nativeEvent.key === "Enter"
            ) {
              const inputEl = e.target as HTMLInputElement;
              const valueLength = inputEl.value.length;
              inputEl.setSelectionRange(valueLength, valueLength);
              inputEl.scrollLeft = inputEl.scrollWidth;
            }
          }}
        />
      )}
      {error && (
        <Tooltip
          body={error.description}
          footerAction={
            error.applyFix && {
              text: "Apply fix",
              onClick: () => {
                if (error.applyFix) {
                  const fixedValue = error.applyFix(value);
                  setValue(fixedValue);
                  validateAndSave(fixedValue);
                }
              },
            }
          }
          placement="right"
          title={error.message}
        >
          <Icon
            color="text-red-600"
            dataLoc="dataset-cell-error-icon"
            icon={faWarning}
            size="2xs"
          />
        </Tooltip>
      )}
      {!!enumValues?.length && (
        <EnumPicker
          cellIsSelected={selected}
          values={enumValues}
          onSelect={(value) => {
            setValue(value);
            validateAndSave(value);
          }}
        />
      )}
    </div>,
  );
};

const EnumPicker: React.FC<{
  values: EnumOptionsBET;
  onSelect: (value: string) => void;
  cellIsSelected: boolean;
}> = ({ values, onSelect, cellIsSelected }) => (
  <FixedPositionedDropdown
    buttonClassName="bg-transparent border-0 focus:ring-0 ring-0"
    className={twJoin("hidden group-hover:block", cellIsSelected && "block")}
    elements={values.map((value) => ({
      key: String(value),
      value: String(value),
    }))}
    itemsClassNames="overflow-y-auto max-h-80 decideScrollbar"
    renderButtonValue={() => (
      <Icon
        color="text-gray-500"
        dataLoc="dataset-enum-dropdown"
        icon={faChevronDown}
        size="2xs"
      />
    )}
    renderValue={(element) => (
      <div className="min-w-[160px] max-w-56 truncate px-4 py-2.5">
        {element.value}
      </div>
    )}
    onSelect={onSelect}
  />
);

const LARGE_CELL_VALUE_LENGTH = 1000;

const JSONPill: React.FC<{
  value: string;
  type: string;
}> = ({ value, type }) => {
  if (value.length === 0) {
    return null;
  }

  if (value.length > LARGE_CELL_VALUE_LENGTH) {
    return (
      <div className="min-w-0 flex-auto">
        <Pill size="sm" variant="gray" maxWidth>
          <Pill.Icon
            icon={
              type === "array"
                ? faBracketsSquare
                : type === "object"
                  ? faBracketsCurly
                  : faAsterisk
            }
          />
          <Pill.Text>{capitalize(type)}</Pill.Text>
        </Pill>
      </div>
    );
  }

  return (
    <div className="min-w-0 flex-auto">
      <Pill size="sm" variant="gray" maxWidth>
        <Pill.Text>{value.slice(0, 75)}</Pill.Text>
      </Pill>
    </div>
  );
};
