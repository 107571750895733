import { useUserOrganizations } from "src/api/queries";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useCurrentWorkspace } from "src/utils/useCurrentWorkspace";

export const usePowertools = () => {
  const userOrganizationsResult = useUserOrganizations();
  const isPartOfTaktileOrg = userOrganizationsResult.data?.some(
    (x) => x.name === "taktile-org",
  );

  return isPartOfTaktileOrg && isFeatureFlagEnabled(FEATURE_FLAGS.powertools);
};

export const useXrayTraceURL = (xrayTraceID: string | null) => {
  const workspaceData = useCurrentWorkspace();
  const AWSRegion = workspaceData.workspace?.aws_region;

  if (!AWSRegion || !xrayTraceID) {
    return null;
  }

  return `https://${AWSRegion}.console.aws.amazon.com/cloudwatch/home?region=${AWSRegion}#xray:traces/${xrayTraceID}`;
};
