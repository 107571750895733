import { Root } from "@radix-ui/react-accordion";

import { ManifestIntegrationProvider } from "src/api/connectApi/manifestTypes";
import { useConnection, useProviderManifest } from "src/api/connectApi/queries";
import { ConnectionT } from "src/api/connectApi/types";
import { useWorkspace } from "src/api/queries";
import { FieldErrorsT } from "src/api/types";
import {
  EditorAccordionItem as AccordionItem,
  accordionRootClassName,
} from "src/base-components/EditorAccordionItem";
import { LoadingView } from "src/base-components/LoadingView";
import { useDiffViewContext } from "src/changeHistory/DiffViewModal/DiffViewContext";
import {
  ManifestConnectionNode,
  ManifestConnectionNodeDataT,
} from "src/constants/NodeDataTypes";
import { ConfigPane } from "src/integrationNode/editorComponents/ConfigPane";
import { InputMappings } from "src/integrationNode/editorComponents/InputMappings";
import { OutputMappings } from "src/integrationNode/editorComponents/OutputMappings";
import {
  ConfigT,
  InputMappingsT,
  OutputMappingsT,
} from "src/integrationNode/types";
import { ManifestIntegrationResourceT } from "src/manifestConnectionNode/types";
import {
  fillInputMappingFromManifest,
  fillOutputMappingFromManifest,
} from "src/manifestConnectionNode/utils";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import { convertFieldErrorsBeToFe } from "src/utils/FieldErrorUtils";

type PropsT = {
  node: ManifestConnectionNode;
  connection: ConnectionT;
  manifest: ManifestIntegrationProvider;
} & NodeEditorBaseProps<ManifestConnectionNodeDataT>;

type WrapperPropsT = {
  node: ManifestConnectionNode;
  workspaceId: string;
} & NodeEditorBaseProps<ManifestConnectionNodeDataT>;

export const ManifestConnectionNodeEditor: React.FC<WrapperPropsT> = ({
  immutable,
  node,
  workspaceId,
  displayedError,
  isReactive,
  onUpdate,
}) => {
  const workspace = useWorkspace(workspaceId);
  const baseUrl = workspace.data?.base_url;
  const connectionData = useConnection({
    baseUrl: baseUrl,
    id: node.data.connectionId,
  });

  const providerManifest = useProviderManifest(
    baseUrl,
    connectionData.data?.provider,
    connectionData.data?.manifest_version,
  );

  return (
    <LoadingView
      queryResult={[connectionData, providerManifest]}
      renderUpdated={([connection, manifest]: [
        ConnectionT,
        ManifestIntegrationProvider,
      ]) => (
        <ManifestConnectionNodeEditorInner
          connection={connection}
          displayedError={displayedError}
          immutable={immutable}
          isReactive={isReactive}
          manifest={manifest}
          node={node}
          onUpdate={onUpdate}
        />
      )}
    />
  );
};

const ManifestConnectionNodeEditorInner: React.FC<PropsT> = ({
  node,
  connection,
  displayedError,
  immutable,
  isReactive,
  manifest,
  onUpdate,
}) => {
  const { renderedInDiffView } = useDiffViewContext();
  const updateMappingInput = (inputMapping: InputMappingsT) => {
    onUpdate({ newData: { input: inputMapping } });
  };

  const updateMappingOutput = (outputMapping: OutputMappingsT) => {
    onUpdate({
      newData: { output: outputMapping },
    });
  };

  const updateConfig = (updatedConfig: ConfigT) => {
    // This check gives a Typescript error when the config property is not present on integration nodes.
    if (node.data.config)
      onUpdate({
        newData: { config: updatedConfig },
      });
  };

  const runFieldErrors: FieldErrorsT | undefined = displayedError?.field_errors
    ? convertFieldErrorsBeToFe(displayedError.field_errors)
    : undefined;

  node.data.input = fillInputMappingFromManifest(
    manifest,
    node.data as ManifestIntegrationResourceT,
  );
  node.data.output = fillOutputMappingFromManifest(
    manifest,
    node.data as ManifestIntegrationResourceT,
  );

  return (
    <Root
      className={accordionRootClassName}
      defaultValue={["input", "output", "config"]}
      type="multiple"
    >
      <AccordionItem
        className="pb-3"
        disabled={renderedInDiffView}
        title="Configure API request"
        value="input"
      >
        <InputMappings
          immutable={immutable}
          integrationResource={node.data}
          isReactive={isReactive}
          runFieldErrors={runFieldErrors}
          updateInputMapping={updateMappingInput}
        />
      </AccordionItem>
      <AccordionItem
        className="pb-1"
        disabled={renderedInDiffView}
        title="Configure API response"
        value="output"
      >
        <OutputMappings
          immutable={immutable}
          integrationResource={node.data}
          isReactive={isReactive}
          runFieldErrors={runFieldErrors}
          updateMappingOutput={updateMappingOutput}
        />
      </AccordionItem>
      <AccordionItem
        className="pb-7"
        disabled={renderedInDiffView}
        title="Advanced settings"
        value="config"
      >
        <ConfigPane
          config={node.data.config}
          dataRetention={connection.data_retention}
          hasRawProviderRequestEnabled={false}
          immutable={immutable}
          isReactive={isReactive}
          manifest={manifest}
          providerResource={node.data.providerResource}
          showTenantIDField={false}
          updateConfig={updateConfig}
        />
      </AccordionItem>
    </Root>
  );
};
