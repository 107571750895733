import axios from "axios";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { FlowVersionT } from "src/api/flowTypes";
import { useEditFlowVersion } from "src/api/flowVersionQueries";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Input } from "src/base-components/Input";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { Textarea } from "src/base-components/Textarea";
import { Modal } from "src/design-system/Modal";
import { getNameErrorMessage, nameValidations } from "src/flow/modals/utils";
import { logger } from "src/utils/logger";

type DecisionFlowVersionInputsT = {
  name: string;
  description: string;
};

export type EditFlowVersionModalPropsT = {
  isOpen: boolean;
  onClose: () => void;
  afterLeave: () => void;
  version?: FlowVersionT;
};

export const EditFlowVersionModal: React.FC<EditFlowVersionModalPropsT> = ({
  isOpen,
  onClose,
  version,
  afterLeave,
}) => {
  const [submitError, setSubmitError] = useState<boolean>(false);
  const editVersion = useEditFlowVersion();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
  } = useForm<DecisionFlowVersionInputsT>({ mode: "onChange" });

  useEffect(() => {
    // Everytime the Modal is reopened with a new version we need to reset the form to set the new default value
    if (isOpen) {
      reset({
        name: version?.name,
        description: version?.meta?.release_note,
      });
    }
  }, [reset, version, isOpen]);

  const onModalConfirm = async (
    name: string | undefined,
    description: string | undefined,
  ) => {
    if (version) {
      await editVersion.mutateAsync({
        version,
        name,
        description,
      });
      onClose();
    }
  };

  const onFormConfirm = handleSubmit(
    async (data: DecisionFlowVersionInputsT) => {
      try {
        /**
         * Only update the fields that have changed so that the change history can interprete them correctly
         */
        await onModalConfirm(
          data.name !== version?.name ? data.name : undefined,
          data.description !== version?.meta?.release_note
            ? data.description
            : undefined,
        );
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 409) {
          setError("name", { type: "notAvailable" }, { shouldFocus: true });
        } else {
          logger.error(e);
          setSubmitError(true);
        }
      }
    },
  );

  return (
    <Modal afterLeave={afterLeave} open={isOpen} onClose={onClose}>
      <Modal.Header>Edit version</Modal.Header>
      <form onSubmit={onFormConfirm}>
        <Modal.Content>
          <div className="mb-5">
            <p className="mb-2 text-gray-800 font-inter-semibold-13px">
              Version name <RequiredAsterisk />
            </p>
            <Input
              data-loc="name-input"
              placeholder="Type version name"
              type="text"
              fullWidth
              {...register("name", {
                required: true,
                validate: nameValidations,
              })}
            />
            {errors.name && (
              <ErrorHint className="pt-2" margin="none">
                {getNameErrorMessage(errors.name.type)}
              </ErrorHint>
            )}
          </div>
          <p className="mb-2 text-gray-800 font-inter-semibold-13px">
            Version description
          </p>
          <Textarea
            autoComplete="off"
            data-loc="description-input"
            placeholder="Type version description"
            {...register("description")}
          />
          {submitError && (
            <ErrorHint className="py-2">
              Error submitting the data - please try again
            </ErrorHint>
          )}
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="save"
              disabled={!isEmpty(errors)}
              htmlType="submit"
              loading={isSubmitting}
              variant="primary"
            >
              Save draft version
            </Button>
          }
          secondaryButton="Cancel"
        />
      </form>
    </Modal>
  );
};
