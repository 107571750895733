import React from "react";

import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { toastActions } from "src/design-system/Toast/utils";

type PropsT = {
  deleteParameter: () => Promise<void>;
  onClose: () => void;
  isOpen: boolean;
  afterLeave?: () => void;
};

export const DeleteParameterModal: React.FC<PropsT> = ({
  deleteParameter,
  onClose,
  isOpen,
  afterLeave,
}) => {
  const onModalConfirm = async () => {
    try {
      await deleteParameter();
      toastActions.success({ title: "Successfully deleted Parameter" });
    } catch {
      toastActions.failure({ title: "Error deleting Parameter" });
    }
    onClose();
  };

  return (
    <ConfirmationModal
      afterLeave={afterLeave}
      confirmationButtonText="Delete"
      open={isOpen}
      title="Delete this Parameter?"
      onClose={onClose}
      onConfirm={onModalConfirm}
    >
      <div className="text-gray-500 font-inter-normal-12px">
        Are you sure you want to delete this Parameter? This action cannot be
        undone.
      </div>
    </ConfirmationModal>
  );
};
