import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { Controller, useFormContext, FieldError } from "react-hook-form";

import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Switch } from "src/base-components/Switch";
import { Textarea } from "src/base-components/Textarea";
import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import {
  mTLSCertificatePlaceholder,
  mTLSKeyPlaceholder,
} from "src/connections/config/database/shared/MTLSConfig";
import {
  AvailableEnvironmentPrefixes,
  ConnectionConfigInputsT,
} from "src/connections/types";
import { Tooltip } from "src/design-system/Tooltip";

type SSLFieldPropsT = {
  fieldError?: Partial<FieldError>;
  helpTitle: string;
  name:
    | `${AvailableEnvironmentPrefixes}sslConfig.ssl_certificate`
    | `${AvailableEnvironmentPrefixes}sslConfig.ssl_key`;
  displayName: string;
  placeholder: string;
};

type PropsT = {
  environmentPrefix: AvailableEnvironmentPrefixes;
};

const SSLField: React.FC<SSLFieldPropsT> = ({
  fieldError,
  displayName,
  placeholder,
  name,
  helpTitle,
}) => {
  const { register, setValue, watch } =
    useFormContext<ConnectionConfigInputsT>();

  const field = watch(name);

  return (
    <FormItem description={helpTitle} gap="xxs" label={displayName} isRequired>
      {Boolean(fieldError) && <ErrorHint>{fieldError?.message}</ErrorHint>}
      {field && field.secret && (
        <div className="flex w-full">
          <div className="flex-1">
            <Input
              data-loc={`provider-secret-${name}`}
              type="password"
              value={field.value || ""}
              disabled
              fullWidth
            />
          </div>
          <Tooltip
            placement="right"
            title={`Edit ${displayName.toLowerCase()}`}
          >
            <div className="ml-2">
              <Icon
                color="text-gray-500"
                icon={faEdit}
                size="xs"
                onClick={() => setValue(name, { secret: false, value: null })}
              />
            </div>
          </Tooltip>
        </div>
      )}
      {(!field || !field.secret) && (
        <Textarea
          errored={Boolean(fieldError)}
          placeholder={placeholder}
          resizeable
          {...register(`${name}.value`, {
            required: true,
          })}
        />
      )}
    </FormItem>
  );
};

const SSLHelpText: React.FC = () => {
  return (
    <>
      <p>
        When enabled, you are required to provide your client SSL certificate
        and client SSL key into the corresponding text input areas. Requests
        made to this Custom Connection will use these credentials for mTLS
        authentication.
        <br />
        <br />
        When disabled, requests made to this Connection will proceed using
        standard HTTPS verification, without requiring client SSL credentials.
      </p>
    </>
  );
};

export const SSLConfigFields: React.FC<PropsT> = ({ environmentPrefix }) => {
  const {
    formState: { errors: formErrors },
    control,
    watch,
  } = useFormContext<ConnectionConfigInputsT>();

  const errors =
    environmentPrefix === ""
      ? formErrors.sslConfig
      : formErrors.nonProdEnvConfigs?.sandbox?.sslConfig;
  const enableSSLFieldName = `${environmentPrefix}enableSSL` as const;
  const enableSSL = watch(enableSSLFieldName);

  return (
    <>
      <div className="mb-6 last:mb-0">
        <FormItem
          className="flex flex-row items-center justify-between"
          description="Require client-side authentication credentials"
          gap="xxs"
          helpTooltipBody={<SSLHelpText />}
          label="Enable mutual TLS"
        >
          <Controller
            control={control}
            name={enableSSLFieldName}
            render={(props) => (
              <Switch
                enabled={props.field.value}
                onChange={() => {
                  props.field.onChange(!props.field.value);
                }}
              />
            )}
          />
        </FormItem>
      </div>
      {enableSSL && (
        <FieldsetCard>
          <SSLField
            displayName="Client certificate"
            fieldError={errors?.ssl_certificate}
            helpTitle="The client certificate used for authentication"
            name={`${environmentPrefix}sslConfig.ssl_certificate`}
            placeholder={mTLSCertificatePlaceholder}
          />
          <SSLField
            displayName="Client key"
            fieldError={errors?.ssl_key}
            helpTitle="The client key used for authentication"
            name={`${environmentPrefix}sslConfig.ssl_key`}
            placeholder={mTLSKeyPlaceholder}
          />
        </FieldsetCard>
      )}
    </>
  );
};
