import { faWavePulse } from "@fortawesome/pro-regular-svg-icons";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { twJoin } from "tailwind-merge";

import {
  DevisionHistoryV3GetRequest,
  SingleDecisionQueryErrorReason,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowT } from "src/api/flowTypes";
import { DecisionEnvironment } from "src/api/types";
import { HistoricalDecisionV3 } from "src/clients/history-v3";
import { outcomeFieldsPrefix } from "src/decisionsOverview/useViewOptions";
import { transformOutcomeTypesToOptions } from "src/decisionsOverview/utils";
import { EmptyState } from "src/design-system/EmptyState";
import {
  ExtendedHistoricalDecisionV3,
  getDecisionHistoryColumns,
} from "src/flow/decisionHistory/Columns";
import { getHistoryEmptyStateWording } from "src/flow/decisionHistory/SharedEmptyStateWording";
import {
  FlowDecisionTableProps,
  TableComp as Table,
} from "src/flow/decisionHistory/Table";
import { useJobs } from "src/jobs/api/queries";
import { useLatestOutcomes, useOutcomeTypes } from "src/outcomes/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { FlowPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

const renderEmptyState = (
  singleDecisionError: SingleDecisionQueryErrorReason | undefined,
  emptyData: boolean,
) => {
  if (singleDecisionError || emptyData) {
    const wording = getHistoryEmptyStateWording(
      singleDecisionError ?? "emptyData",
    );
    return (
      <div className="py-16">
        <EmptyState
          dataLoc="decision-history-empty-state"
          icon={faWavePulse}
          {...wording}
        />
      </div>
    );
  }
};

type PropsT = {
  filterFields: DevisionHistoryV3GetRequest;
  changeFilterFields: (filters: DevisionHistoryV3GetRequest) => void;
  decisionHistory: HistoricalDecisionV3[] | undefined;
  canFetchNextPage: boolean;
  fetchNextPage: () => void;
  isFetching: boolean;
  singleDecisionError?: SingleDecisionQueryErrorReason;
  env: DecisionEnvironment;
  flow: FlowT;
  onDecisionClick: FlowDecisionTableProps["onDecisionClick"];
  includeEntityLinks?: boolean;
  includeInputOutput?: boolean;
  includeOutcomes?: boolean;
  tracingHoverBehavior?: boolean;
  selectedDecisionId?: string;
  columnVisibility?: Record<string, boolean>;
};

export const DecisionHistoryTable: React.FC<PropsT> = ({
  filterFields,
  changeFilterFields,
  decisionHistory,
  canFetchNextPage,
  fetchNextPage,
  isFetching,
  singleDecisionError,
  env,
  flow,
  onDecisionClick,
  includeEntityLinks = false,
  includeInputOutput = false,
  includeOutcomes = false,
  tracingHoverBehavior = false,
  selectedDecisionId,
  columnVisibility,
}) => {
  const { orgId, wsId } = useParamsDecode<FlowPageParamsT>();
  const { workspace } = useWorkspaceContext();
  const { data: jobs, isInitialLoading: jobsQueryIsInitialLoading } = useJobs(
    workspace.base_url!,
    flow.id,
  );
  const { data: outcomeTypes } = useOutcomeTypes({
    flowId: flow.id,
    enabled: includeOutcomes,
  });
  const outcomeOptions = useMemo(
    () =>
      includeOutcomes ? transformOutcomeTypesToOptions(outcomeTypes ?? []) : [],
    [outcomeTypes, includeOutcomes],
  );

  const extendedDecisionHistory = useDecisionHistoryWithOutcomes(
    decisionHistory,
    columnVisibility,
  );

  const emptyData = Boolean(decisionHistory && decisionHistory.length === 0);
  const columns = useMemo(
    () =>
      getDecisionHistoryColumns({
        orgId,
        wsId,
        flow,
        jobs: jobs ?? [],
        filterFields: filterFields,
        changeFilterFields,
        availableFlowVersions: flow.versions,
        env,
        includeEntityLinks,
        tracingHoverBehavior,
        includeInputOutput,
        outcomeOptions,
      }),
    [
      changeFilterFields,
      env,
      filterFields,
      flow,
      jobs,
      orgId,
      wsId,
      includeEntityLinks,
      includeInputOutput,
      tracingHoverBehavior,
      outcomeOptions,
    ],
  );
  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <div
        className={twJoin(
          !emptyData && !singleDecisionError && "min-h-0 flex-1",
        )}
      >
        <Table
          canFetchNextPage={canFetchNextPage}
          columnVisibility={columnVisibility}
          columns={columns}
          // Show the table only after we have fetched the jobs
          data={(!jobsQueryIsInitialLoading && extendedDecisionHistory) || []}
          dataLoc="decision-history-table"
          fetchNextPage={fetchNextPage}
          frameClassName={twJoin(emptyData ? "h-26" : "overflow-auto")}
          isFetching={isFetching || jobsQueryIsInitialLoading}
          selectedDecisionId={selectedDecisionId}
          onDecisionClick={onDecisionClick}
        />
      </div>
      {renderEmptyState(singleDecisionError, emptyData)}
    </div>
  );
};

const useDecisionHistoryWithOutcomes = (
  decisionHistory: HistoricalDecisionV3[] = [],
  columnVisibility: Record<string, boolean> = {},
): ExtendedHistoricalDecisionV3[] => {
  const latestOutcomes = useLatestOutcomes(
    decisionHistory.map((decision) => decision.id),
    {
      enabled: Object.entries(columnVisibility).some(
        ([column, visible]) =>
          column.startsWith(outcomeFieldsPrefix) && visible,
      ),
    },
  );

  const latestOutcomesData = latestOutcomes.map((outcome) => outcome.data);

  return useMemo(() => {
    const outcomes = latestOutcomesData.reduce<Record<string, unknown>>(
      (acc, response) => {
        if (response) {
          return Object.assign(acc, response);
        }
        return acc;
      },
      {},
    );

    if (isEmpty(outcomes)) {
      return decisionHistory;
    }

    return decisionHistory?.map((decision) => ({
      ...decision,
      outcome: outcomes[decision.id],
    }));
  }, [decisionHistory, latestOutcomesData]);
};
